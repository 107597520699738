<div class="webpart-card">
    <div class="webpart-cardbody">
        <div class="webpart-card-header" [ngClass]="isVisible ? 'webpart-card-closed' : 'webpart-card-open'">
            <div class="webpart-card-header-title">
                <span>{{this.ExternalReferencesProperties.Title}}</span>
            </div>
            <div class="webpart-card-header-action">
                <dx-button (onClick)="expandCollaspeComponent()"
                    [icon]="isVisible ? 'fa-regular fa-angle-up' : 'fa-regular fa-angle-down'"></dx-button>
            </div>
        </div>
        <div [hidden]="isVisible">
            <form method="post">
                <div class="form">
                    <div class="form-group-row">
                        <div class="field_group" *ngFor='let add of lstExternalReferences;'>
                            <div class="field_label">{{add.Key}}</div>
                            <div class="field_control">
                                <ng-container
                                    *ngIf="this.ExternalReferencesProperties.Mode.toLowerCase() === 'edit'; else viewMode">
                                    
                                    <dx-text-box [value]="add.Value"
                                        (onValueChanged)="add.Value = $event.value"></dx-text-box>
                                </ng-container>

                                <ng-template #viewMode>
                                    <strong>{{ add.Value }}</strong>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div class="form-btn-outer" *ngIf="this.ExternalReferencesProperties.Mode.toLowerCase() === 'edit'">
                        <dx-button text="Save" (onClick)="onSubmit($event)">
                        </dx-button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>