import { Injectable } from '@angular/core';
import { HttpHelperService } from '../httpHelper/http-helper.service'; 
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Guid } from 'guid-typescript';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})


export class BreadcrumbService {
  constructor(private httpClient: HttpClient, private httpHelper: HttpHelperService) { }  
  
  GetObjectRecordDetails(tokenId:string,RecordID:string,objectID:string): Promise<ObjectRecordDetails> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.apiUrl + 'api/ObjectRecord/GetObjectRecordDetails/'+ RecordID + '/' + objectID+'/' + tokenId)
        .subscribe((response) => {
          resolve(<ObjectRecordDetails>response);
        });
    });
  }

  
  
}

export class ObjectRecordDetails {
    ObjectDescription: string = '';
    ObjectRecordStatus: Guid ;
}
