import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})

export class AlertService {
  constructor(private translateService: TranslateService) {
  }

  async showAlertPopup(title: string, description: string, icon: any, confirmButtonText: string) {
    var isFontAwosomeIcon: boolean = false;
    if (icon.toLowerCase().includes("fa")) {
      isFontAwosomeIcon = true
    }
    var iconImg = `<img src='/assets/images/${icon.toLowerCase()}.png'>`;
    if (isFontAwosomeIcon) {
      iconImg = `<i class='${icon.toLowerCase()}'></i>`
    }
    await Swal.fire({
      title: title,
      text: description,
      iconHtml: iconImg,
      confirmButtonText: confirmButtonText,
      allowOutsideClick : false
    })
  }

  logoutUserPopup(title: string, description: string, icon: any, confirmButtonText: string) {
    return Swal.fire({
      title: title,
      text: description,
      iconHtml: icon,
      confirmButtonText: confirmButtonText,
      allowOutsideClick : false
    })
  }

  showMiniAlerts(message: string, icon: string, popupduration: number = 1500) {
    return Swal.fire({
      toast: true,
      position: "top-end",
      iconHtml: `<i class='${icon.toLowerCase()}'></i>`,
      title: message,
      showConfirmButton: false,
      timerProgressBar: true,
      timer: popupduration,
      customClass: { popup: "mini-alert" },
      allowOutsideClick : false
    });

  }

  showConfirmationAlert(title: string, description: string, icon: any, showCancelButton: boolean = true) {
    var isFontAwosomeIcon: boolean = false;
    if (icon.toLowerCase().includes("fa")) {
      isFontAwosomeIcon = true
    }
    var iconImg = `<img src='/assets/images/${icon.toLowerCase()}.png'>`;
    if (isFontAwosomeIcon) {
      iconImg = `<i class='${icon.toLowerCase()}'></i>`
    }
    var OkButtonText:string="";
    var CancelButtonText:string="";
    this.translateService.get('OkButtonText').subscribe((translated: string) => {
      OkButtonText = translated;
    });

    this.translateService.get('CancelButtonText').subscribe((translated: string) => {
      CancelButtonText = translated;
    });

    return Swal.fire({
      title: title,
      text: description,
      iconHtml: iconImg,
      confirmButtonText: OkButtonText,
      showCancelButton: showCancelButton,
      cancelButtonText: CancelButtonText,
      allowOutsideClick : false
    });
  }
}
