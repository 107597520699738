import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Guid } from 'guid-typescript';
import { EncryptionService } from 'src/app/encryption.service';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { ComponentInfo } from 'src/app/models/ComponentInfo';
import { ObjectRecordModel } from 'src/app/models/ObjectRecordModel';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { environment } from 'src/environments/environment';
import { NotificationViewComponent } from '../notification-view/notification-view.component';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RibbonControlComponent } from '../common/ribbon-control/ribbon-control.component';

@Component({
  selector: 'app-notification-recipient-document',
  templateUrl: './notification-recipient-document.component.html',
  styleUrls: ['./notification-recipient-document.component.scss']
})
export class NotificationRecipientDocumentComponent extends PageBaseService {

  ComponentObjectID: string;
  gridInfo1 = new ComponentInfo();

  recordID: string = Guid.EMPTY;
  relationshipRecordID: string = Guid.EMPTY;
  newID: string = Guid.EMPTY;
  parameterSet: Boolean = false;
  RelationshipID: string;
  jobID: string = Guid.EMPTY;
  notificationID:string;

  NotificationIdQuery :string;
  loggedinUserID: string = '';
  @ViewChild("notificationDetail", { static: true }) notificationDetail!: NotificationViewComponent;
  @ViewChild("NotificationDocumentRibbonControl", { static: false }) NotificationDocumentRibbonControl: RibbonControlComponent

  constructor(private eventQueue: LobasEventQueueService, private _router: Router, private auth: AuthService,private _enc: EncryptionService, private notificationService: NotificationService,private httpClient: HttpClient) {
    super(_router, _enc);
    this.ComponentObjectID = '7F103B80-4EB5-45F5-97FF-BFC67C04CA6B';
    this.loggedinUserID = this.auth.getGuidUserID() ?? '';
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }
  ngOnInit() {
    this.getAllQueryStrings();
  }
  getAllQueryStrings() {
    this.GetKeyValueFromQueryString('RecordID').then((recordID => {
      this.recordID = recordID;
      this.relationshipRecordID = Guid.EMPTY;
      this.newID = Guid.EMPTY;
      this.parameterSet = true;
      

      this.notificationDetail.BindDataWithRelatedRecords('90476D69-8F5E-4DA2-B5E8-962588D9FE94',this.recordID,'7F103B80-4EB5-45F5-97FF-BFC67C04CA6B')

      this.GetKeyValueFromQueryString('NRID').then((notificationId => {
        this.NotificationIdQuery = notificationId;
        if(this.NotificationIdQuery != "" && this.NotificationIdQuery != '' && this.NotificationIdQuery != null&& this.NotificationIdQuery != undefined ){
          this.notificationService.notificationUserAuthorised(this.NotificationIdQuery,this.loggedinUserID);
          this.notificationService.updateNotificationReadStatus(this.NotificationIdQuery).then((x)=>{
            this.NotificationDocumentRibbonControl.bindEnumerationTransitions();
          })
        }

     
    }));
    }));
  }

}
