import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxButtonComponent, DxPopupComponent } from 'devextreme-angular';
import { Guid } from 'guid-typescript';
import { EncryptionService } from 'src/app/encryption.service';
import { DocumentViewerComponentProperties } from 'src/app/models/DocumentViewerComponentProperties';
import { DocumentModel, DocumentViewerService, ImagedataSource } from 'src/app/services/documentViewer/document-viewer.service';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { EnumHelper } from 'src/app/util/enum-helper';
import { environment } from 'src/environments/environment';
import devices from 'devextreme/core/devices';

@Component({
  selector: 'app-document-assests-viewer-popup',
  templateUrl: './document-assests-viewer-popup.component.html',
  styleUrls: ['./document-assests-viewer-popup.component.scss']
})
export class DocumentAssestsViewerPopupComponent extends PageBaseService {
  @ViewChild("DocumentViewerPopup", { static: false }) DocumentViewerPopup: DxPopupComponent
  documentViewerProperties: DocumentViewerComponentProperties;
  isVisible: Boolean = true;
  isPopupFull: boolean = false;
  documentSRC: string = '';
  isDocViewer: boolean = false;
  isMovViewer: boolean = false;
  isImageSlider: boolean = false;
  isImage: boolean = false;
  imageObject: ImagedataSource[] = [];
  documentList: DocumentModel[];
  documentRecord: DocumentModel;
  @Input() instanceID: string;
  componentID: string = 'documentassetsviewer';
  IsMaximize: boolean = false;

  constructor(private localStorageService: LocalStorageService, private _router: Router, private _enc: EncryptionService, private docViewService: DocumentViewerService, private httpClient: HttpClient) {
    super(_router, _enc);
  }
  expandCollaspeComponent() {
    this.isVisible = !this.isVisible;
  }

  isPdf:boolean;
  isIOS:boolean;

  async BindDocument(recordID: string, action: string, fileName: string = '') {
    // this.pdfSrc = "https://test.statrack.co.uk/azstorage/branding/lobas4.0/8D5E743F-1ABC-DC68-CAD9-3120460DEC3D/images/SplashScreenImage.webp";
    // this.imageObject = [{
    //   image: 'https://test.statrack.co.uk/azstorage/branding/lobas4.0/8D5E743F-1ABC-DC68-CAD9-3120460DEC3D/images/paralax-background-image-desktop.png',
    //   thumbImage: 'https://test.statrack.co.uk/azstorage/branding/lobas4.0/8D5E743F-1ABC-DC68-CAD9-3120460DEC3D/images/paralax-background-image-desktop.png',
    //   alt: 'alt of image',
    //   title: 'title of image'
    // }, {
    //   image: 'https://test.statrack.co.uk/azstorage/branding/lobas4.0/8D5E743F-1ABC-DC68-CAD9-3120460DEC3D/images/SplashScreenImage.webp',
    //   thumbImage: 'https://test.statrack.co.uk/azstorage/branding/lobas4.0/8D5E743F-1ABC-DC68-CAD9-3120460DEC3D/images/SplashScreenImage.webp',
    //   alt: 'alt of image',
    //   title: 'title of image'
    // }
    // ];

    switch (action.toLowerCase()) {
      case "questionnaireresponsepdf":
        if (!this.IsNullorEmpty(fileName)) {
          var FileName = "UploadTemp/" + fileName.toString().replace(" ", "") + ".pdf";
          this.documentSRC = environment.azureStorageURL + "Temp/" + FileName + "?time=" + Date.now.toString();
          this.isImageSlider = false;
          this.isMovViewer =  false;
          this.isDocViewer = true;
          this.isImage = false;
          this.DocumentViewerPopup.visible = true;
        }
        break;
      case "viewdocument":
        var queryStringRecordID = await this.GetKeyValueFromQueryString("RecordID");
        if (!this.IsNullorEmpty(queryStringRecordID)) {
          this.documentList = await this.docViewService.GetRelatedDocument(queryStringRecordID, this.documentViewerProperties.RelationshipID);
          if (this.documentList != null) {
            var imgurl = '', thumbImageUrl = ''
            this.imageObject = []
            this.documentList.forEach(docObj => {
              if (docObj.RecordID.toLowerCase() == recordID.toLowerCase()) {
                this.documentRecord = docObj;
                imgurl = environment.azureStorageURL + "Documents/" + docObj.RecordID + "_" + docObj.Path;
                thumbImageUrl = environment.azureStorageURL + "Thumbnails/" + docObj.RecordID + "_" + this.GetFileNameWithoutExtension(docObj.Path) + "-S.jpg";
                this.imageObject.push({ image: imgurl, thumbImage: thumbImageUrl, title: docObj.Path, alt: docObj.Name });
              }
            });

            
            if (this.documentRecord != null && (this.documentRecord.Path.toLowerCase().includes("jpg") || this.documentRecord.Path.toLocaleLowerCase().includes("gif")
              || this.documentRecord.Path.toLowerCase().includes("png") || this.documentRecord.Path.toLowerCase().includes("jpeg"))) {
              if (this.documentList.length - 1 > 0) {
                this.documentList.forEach(docObj => {
                  if ((this.GetFileExtension(docObj.Path).toLowerCase() == "jpg" || this.GetFileExtension(docObj.Path).toLowerCase() == "jpeg" || this.GetFileExtension(docObj.Path).toLowerCase() == "png" || this.GetFileExtension(docObj.Path).toLowerCase() == "gif") && docObj.RecordID.toLowerCase() != recordID.toLowerCase()) {
                    imgurl = environment.azureStorageURL + "Documents/" + docObj.RecordID + "_" + docObj.Path;
                    thumbImageUrl = environment.azureStorageURL + "Thumbnails/" + docObj.RecordID + "_" + this.GetFileNameWithoutExtension(docObj.Path) + "-S.jpg";
                    this.imageObject.push({ image: imgurl, thumbImage: thumbImageUrl, title: docObj.Path, alt: docObj.Name });
                  }
                });
                this.isImageSlider = true;
                this.isDocViewer = false;
                this.isMovViewer =  false;
                this.isImage = false;
                this.DocumentViewerPopup.visible = true;
                //Increment here 
                await this.docViewService.SetDocumentReadCounter(recordID);
                return;
              }
            }
          }

        }
        if (this.documentRecord == null || this.documentRecord.RecordID == Guid.EMPTY.toString()) {
          this.documentRecord = await this.docViewService.GetDocumentRecord(recordID.toString())
        }
        if (this.documentRecord != null && this.documentRecord != undefined && this.documentRecord.RecordID != Guid.EMPTY.toString()) {
          this.documentSRC = environment.azureStorageURL + "Documents/" + recordID + "_" + this.documentRecord.Path;
          if ((this.GetFileExtension(this.documentRecord.Path).toLowerCase() == "jpg"
            || this.GetFileExtension(this.documentRecord.Path).toLowerCase() == "jpeg" ||
            this.GetFileExtension(this.documentRecord.Path).toLowerCase() == "png" ||
            this.GetFileExtension(this.documentRecord.Path).toLowerCase() == "gif"))
          {
            this.isImageSlider = false;
            this.isDocViewer = false;
            this.isMovViewer =  false;
            this.isImage = true;
          }
          else if (this.GetFileExtension(this.documentRecord.Path).toLowerCase() == "mov"){
            this.isImageSlider = false;
            this.isDocViewer = false;
            this.isImage = false;
            this.isMovViewer =  true;
          }
          else {
            this.isImageSlider = false;
            this.isDocViewer = true;
            this.isMovViewer =  false;
            this.isImage = false;
          }
          this.DocumentViewerPopup.visible = true;
          await this.docViewService.SetDocumentReadCounter(this.documentRecord.RecordID);
          this.documentList=null;
          this.documentRecord=null;
        }
        break;
    }

    this.isPdf = this.documentSRC.toLowerCase().endsWith(".pdf");
    this.isIOS =  devices.current().ios;
    if(this.isPdf && this.isIOS) {
      this.isDocViewer = false;
      this.isMovViewer =  false;
    }
  }
  async ngOnInit() {
    this.documentViewerProperties = <DocumentViewerComponentProperties>this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.documentAssestsViewerComponentProperties, [this.instanceID]);
    if (this.documentViewerProperties == null || this.documentViewerProperties == undefined) {
      this.documentViewerProperties = new DocumentViewerComponentProperties();
      this.GetComponentProperties();
    }
    this.isPopupFull = false
  }
  GetComponentProperties() {

    var ComponentUrl = "";
    ComponentUrl = "api/ComponentProperties/GetComponentPropertiesData?componentID=";
    this.httpClient.get(environment.apiUrl + ComponentUrl + this.componentID + "&instanceID=" + this.instanceID)
      .subscribe((response: any) => {
        this.setComponentProperties(response.Properties);
      });

  }

  setComponentProperties(componentProperties) {
    var docViewProperties = JSON.parse(componentProperties);
    docViewProperties.DocumentAssetsViewerProperties.forEach(componentProperty => {
      switch (componentProperty.key.toLowerCase()) {
        case "title":
          this.documentViewerProperties.Title = componentProperty.value;
          break;
        case "relationshipid":
          this.documentViewerProperties.RelationshipID = componentProperty.value;
          break;
        case "bindonrelationshiponly":
          this.documentViewerProperties.BindOnRelationshipOnly = componentProperty.value;
          break;
        case "allowedroles":
          this.documentViewerProperties.AllowedRoles = componentProperty.value.split(',').map(x => x.toLowerCase());;
          break;

      }
    });
    this.localStorageService.SetLocal(EnumHelper.LocalStorageKey.documentAssestsViewerComponentProperties, JSON.stringify(this.documentViewerProperties), [this.instanceID])
  }

  showDocumentAssetViewerPopup(popupVisibility: boolean, recordID: string, action: string, fileName: string = null) {

    this.BindDocument(recordID, action, fileName);

  }
  popup_hiding(){
      this.isPopupFull = false
      this.isImageSlider = false;
      this.isDocViewer = false;
      this.isImage = false;
      this.isMovViewer=false;
   
  }
  PopupCollapseExpand() {
    this.isPopupFull = !this.isPopupFull;
  }
}

