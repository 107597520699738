import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RelationshipRecordModel } from 'src/app/models/RelationshipRecordModel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TradeDetailsService {

  constructor(private httpClient: HttpClient) { }

  UnAssignWorkers(relationshipModel:RelationshipRecordModel){
    return this.httpClient.post(environment.apiUrl + 'api/RelationshipRecord/DeleteRelationshipRecord', JSON.stringify(relationshipModel),
    {
      headers: {
        'objectID': 'C14BBB2D-4C1F-43B5-A3FB-8333A163F76F'
      },
      responseType: "text"
    }
  )
  }
}
