import { Component ,ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { scales } from 'chart.js';
import { DxDataGridComponent, DxPopupComponent } from 'devextreme-angular';

@Component({
  selector: 'app-validation-popup',
  templateUrl: './validation-popup.component.html',
  styleUrls: ['./validation-popup.component.scss']
})
export class ValidationPopupComponent {

  @ViewChild("validationpopup", { static: false }) validationpopup: DxPopupComponent

  showPopup(validationMessages:string)
  {  
    this.validationpopup.contentTemplate=validationMessages;
    this.validationpopup.visible=true;
    
 }
}
