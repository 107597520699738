import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EncryptionService } from 'src/app/encryption.service';
import { ExtendedAttributesComponentProperties } from 'src/app/models/ExtendedAttributesComponentProperties';
import { CustomUIFieldModel } from 'src/app/models/CustomUIFieldModel';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { EnumHelper } from 'src/app/util/enum-helper';
import { DxFormComponent } from 'devextreme-angular';
import { ExtendedAttributesService } from 'src/app/services/extendedAttributes/extended-attributes.service';
import { DateTimeHelperService } from 'src/app/services/datetimehelper/date-time-helper.service';
import { ValidationPopupComponent } from '../validation-popup/validation-popup.component';
import { OfflineDataHelperService } from 'src/app/services/offlineDataHelper/offline-data-helper.service';

@Component({
  selector: 'app-extended-attributes',
  templateUrl: './extended-attributes.component.html',
  styleUrls: ['./extended-attributes.component.scss']
})
export class ExtendedAttributesComponent extends PageBaseService implements OnInit {
  @ViewChild('extendedAttributeForm') formComponent: DxFormComponent;
  @ViewChild('validationpopup') validationpopup: ValidationPopupComponent;
  @Input() recordID: string = '';
  @Input() instanceID: string;
  componentID: string = "ExtendedAttributes";
  isVisible: Boolean = false;
  formData: any;

  componentProperties: ExtendedAttributesComponentProperties;
  extendedAttributesObjectFields: CustomUIFieldModel[];
  statusMessage: string = '';
  constructor(private dateHelper: DateTimeHelperService, private localStorageService: LocalStorageService, private httpClient: HttpClient, private _router: Router,
    private _enc: EncryptionService, private translateService: TranslateService,private offlineDataHelper: OfflineDataHelperService, private extendedAttributesService: ExtendedAttributesService) {
    super(_router, _enc);

  }

  async ngOnInit() {
    this.componentProperties = <ExtendedAttributesComponentProperties>this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.ExtendedAttributesComponentProperties, [this.instanceID]) ?? new ExtendedAttributesComponentProperties();
    this.extendedAttributesObjectFields = <CustomUIFieldModel[]>this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.ExtendedAttributesObjectFields, [this.componentProperties.ObjectID]) ?? [];
    if (this.recordID.length>0) {
      if (this.componentProperties.ObjectID.length === 0 || !this.extendedAttributesObjectFields || this.extendedAttributesObjectFields.length == 0) {
        this.componentProperties = await this.extendedAttributesService.getComponentProperties(this.componentID, this.instanceID);
        this.getExtendedAttributesObjectFields();
        this.localStorageService.SetLocal(EnumHelper.LocalStorageKey.ExtendedAttributesComponentProperties, JSON.stringify(this.componentProperties), [this.instanceID])
      }
    } else {
      this.statusMessage = this.translateService.instant('RecordIDNotFound');
    }
  }

  ngAfterViewInit() {
    if(this.offlineDataHelper.isOnline){
         this.loadFormFields();
    }
  }

  async getExtendedAttributesObjectFields() {
    if (this.extendedAttributesObjectFields.length === 0) {
      this.extendedAttributesObjectFields = await this.extendedAttributesService.getObjectFields(this.componentProperties.ObjectID);
      this.localStorageService.SetLocal(EnumHelper.LocalStorageKey.ExtendedAttributesObjectFields, JSON.stringify(this.extendedAttributesObjectFields), [this.componentProperties.ObjectID])
      this.loadFormFields();
    } else {
      this.loadFormFields();
    }
  }

  loadFormFields() {
    if (this.componentProperties && this.extendedAttributesObjectFields &&
      this.componentProperties.ObjectID.length > 0 && this.extendedAttributesObjectFields.length > 0) {


      this.formComponent.instance.option({
        items: this.extendedAttributesService.getFormItems(this.componentProperties.FieldSettings.Items, this.extendedAttributesObjectFields, this.componentProperties.HideSaveButton)
      });

      this.extendedAttributesService.LoadFormData(this.componentProperties.ObjectID, this.recordID).then(result => {
        this.formData = result;
        Object.keys(this.formData).forEach(key => {
          if (Number(this.formData[key])) {
            this.formData[key] = Number.parseInt(this.formData[key]);
          }
          else if (this.formData[key].toLowerCase() == 'false' || this.formData[key].toLowerCase() == 'true') {
            switch (this.formData[key].toLowerCase()) {
              case "true":
                this.formData[key] = true;
                break;
              case "false":
                this.formData[key] = false;
                break;
            }
          }
        })
      });
    }
  }

  expandCollaspeComponent() {
    this.isVisible = !this.isVisible;
  }

  submitForm(showAlert: boolean) {
    if (this.isFormValid()) {
      this.extendedAttributesService.UpdateExtendedAttributeByObjectIDAndRecordID(this.recordID, this.componentProperties.ObjectID, this.formData, showAlert);
    }
  }

  isFormValid(): boolean {
    let validate = this.formComponent.instance.validate();
    if (!validate.isValid) {
      const brokenRules = validate.brokenRules;
      this.validationpopup.showPopup(brokenRules.map((rule: any) => {
        return "<p>" + rule.message + "</p>"
      }).join(""))
    }
    return validate.isValid;
  }

  onFormValidating(e: any) {

  }
}