<div class="customloader" id="customloader"></div>
<div class="login-background"><img src="{{brandingService.GetSplashScreen()}}" /></div>
<div class="login-container validation-container">
    <div class="card">
        <div class="card-body" *ngIf="!IsLinkExpired">
            <div class="logo-head ">
                <img class="login_logo" src="{{brandingService.GetDesktopLogo()}}" />
            </div>
            <a class="close-btn-login-cont" [routerLink]="['/login']"><span id="RegisterClose" runat="server"
                    class="close-btn text-left"><i class="far fa-times-circle"></i></span></a>
            <div class="text-right">
                <h1 i18n>Create a New Account</h1>
            </div>
            <div class="text-right">
                <h4 i18n>Complete the information below and click Create to create a new Account</h4>
            </div>
            <form method="post" (submit)="handleSubmit($event)">
                <div class="form">

                    <div class="field_group">
                        <div class="field_label required-label-asterisk">Username</div>
                        <div class="field_control">
                            <dx-text-box  [(value)]="userName" (onKeyDown)="onKeyDown($event)" [inputAttr]="{ 'aria-label': 'Username' }">
                                <dx-validator>
                                    <dxi-validation-rule [trim]="true" type="required"
                                        message="Username is required"></dxi-validation-rule>    
                                        <!-- <dxi-validation-rule  type="pattern" pattern="/^\s+|\s+$|\s+(?=\s)/g"
                                        message="Username must not contain any spaces"></dxi-validation-rule>                                        -->
                                </dx-validator>
                            </dx-text-box>
                            <p *ngIf="IsUserNameExists" class="NotificationResetPassword">{{UserNameExistsText}}</p>
                        </div>
                    </div>

                    <div class="field_group">
                        <div class="field_label required-label-asterisk">Password</div>
                        <div class="field_control">
                            <dx-text-box mode="password" [(value)]="password"
                                [inputAttr]="{ 'aria-label': 'Password' }">
                                <dx-validator>
                                    <dxi-validation-rule type="required"
                                        message="Password is required"></dxi-validation-rule>
                                    <dxi-validation-rule type="pattern"
                                        pattern="^(?! )(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&amp;*-.+ ])(?=.*?[0-9]).{6,}[^\s]$"
                                        message="Password must have at least 6 characters with 1 uppercase, 1 lowercase, 1 numeric and 1 special character eg: #?!@$%^&*-.+ Do not use space character at the start or end of your Password.">
                                    </dxi-validation-rule>
                                </dx-validator>
                            </dx-text-box>
                        </div>
                        <div class="paswd_str_mtr">
                            <password-strength-meter [password]="password"></password-strength-meter>
                        </div>
                    </div>

                    <div class="field_group">
                        <div class="field_label required-label-asterisk">Confirm Password</div>
                        <div class="field_control">
                            <dx-text-box mode="password" [(value)]="confirmPassword"
                                [inputAttr]="{ 'aria-label': 'Confirm Password' }">
                                <dx-validator>
                                    <dxi-validation-rule type="required"
                                        message="Confirm Password is required"></dxi-validation-rule>
                                    <dxi-validation-rule type="compare" [comparisonTarget]="passwordComparison"
                                        message="Password and Confirm New Password fields must match."></dxi-validation-rule>
                                </dx-validator>
                            </dx-text-box>
                        </div>
                        <div class="paswd_str_mtr">
                            <password-strength-meter [password]="confirmPassword"></password-strength-meter>
                        </div>
                    </div>

                    <div class="field_group">
                        <div class="field_label required-label-asterisk">Language</div>
                        <div class="field_control">
                            <dx-select-box displayExpr="Value" valueExpr="EnumerationId" [dataSource]="languageList"
                                [inputAttr]="{ 'aria-label': 'Language' }" [(value)]="languageID"
                                [searchMode]="searchModeOption" [searchExpr]="searchExprOption"
                                [searchTimeout]="searchTimeoutOption" [minSearchLength]="minSearchLengthOption"
                                [searchEnabled]="true" [showDataBeforeSearch]="showDataBeforeSearchOption">
                                <dx-validator>
                                    <dxi-validation-rule type="required"
                                        message="Language is required"></dxi-validation-rule>
                                </dx-validator>
                            </dx-select-box>
                        </div>
                    </div>

                    <div class="field_group">
                        <div class="field_label required-label-asterisk">Time Zone</div>
                        <div class="field_control">
                            <dx-select-box displayExpr="Value" valueExpr="EnumerationId" [dataSource]="timezoneList"
                                [inputAttr]="{ 'aria-label': 'EnumerationId' }" [(value)]="timezoneID"
                                [searchMode]="searchModeOption" [searchExpr]="searchExprOption"
                                [searchTimeout]="searchTimeoutOption" [minSearchLength]="minSearchLengthOption"
                                [searchEnabled]="true" [showDataBeforeSearch]="showDataBeforeSearchOption">
                                <dx-validator>
                                    <dxi-validation-rule type="required"
                                        message="Time Zone is required"></dxi-validation-rule>
                                </dx-validator>
                            </dx-select-box>
                        </div>
                    </div>

                    <div class="checkbox_field_group field_group">
                        <div class="field_control">
                            <dx-check-box [(value)]="isRobot" text="I'm not a robot"
                                [elementAttr]="{ 'aria-label': 'Is Robot' }">
                                <dx-validator>
                                    <dxi-validation-rule type="required"
                                        message="This field is required"></dxi-validation-rule>
                                </dx-validator>
                            </dx-check-box>
                        </div>
                    </div>
                    <div class="form_btn_groups">
                        <dx-button id="btnCancel" (onClick)="Cancel()" text="Cancel">
                        </dx-button>
                        <dx-button id="btnCreate" text="Create" [useSubmitBehavior]="true">
                        </dx-button>
                    </div>
                </div>
            </form>
        </div>
        <div class="card-body" *ngIf="IsLinkExpired">
            <div class="text-center">
                <!-- <div class="text-centre">
                    <h1 i18n style="margin-top:0"><strong>Alert</strong></h1>
                </div> -->
                <p>This link has already been used to register a user. Please contact support.</p>
                <a class="ret_to_login login_btns" [routerLink]="['/login']">Continue</a>
            </div>
        </div>
    </div>

</div>