<div id="dvAssetViewerPopup">
    <dx-popup (onHiding)="popup_hiding()" [(fullScreen)]="isPopupFull" #DocumentViewerPopup
        [wrapperAttr]="{id:'dvDocumentViewer',class: 'lobas-popup doc-vwr-popup' }" [showTitle]="true"
        title="{{ this.documentViewerProperties.Title| translate }}" [resizeEnabled]="false" [dragEnabled]="false"
        [hideOnOutsideClick]="false" [showCloseButton]="true">
        <dx-button class="flscrpop_btn" icon="fal fa-window-maximize" (onClick)="PopupCollapseExpand()">
        </dx-button>
        <div class="w-100" *ngIf="isDocViewer">
            <ngx-doc-viewer [viewerUrl]="documentSRC" [url]="documentSRC" viewer="url"></ngx-doc-viewer>
        </div>
        <div *ngIf="isPdf && isIOS" class="webpart-scroll">
            <div> 
              <strong>iOS Users:</strong> To close PDF, swipe from left.
              </div>
             <a href="{{documentSRC}}">Launch Document</a>  
         </div>
         <video width="400" controls Autoplay=autoplay *ngIf="isMovViewer">
            <source [src]="documentSRC" type="video/mp4">
          </video>
        <div *ngIf="isImageSlider" class="w-100">
            <swiper-container class="docviewer_swiper_main" thumbs-swiper=".docviewer_swiper_bottom" navigation="true">
                <swiper-slide *ngFor="let imgObj of imageObject">
                    <img src={{imgObj.image}} title={{imgObj.title}} />
                </swiper-slide>
            </swiper-container>

            <swiper-container class="docviewer_swiper_bottom" slides-per-view="4" free-mode="true"
                watch-slides-progress="true">
                <swiper-slide *ngFor="let imgObj of imageObject">
                    <img src={{imgObj.thumbImage}} title={{imgObj.title}} />
                </swiper-slide>
            </swiper-container>
        </div>
        <div class="single-image-viewer" *ngIf="isImage">
            <img src={{documentSRC}}/>
        </div>
    </dx-popup>
</div>