
<!-- ***************************This code has been generated using LOBAS Templates 4.0.0.0  --> 

<app-breadcrumb [ComponentObjectID]="componentObjectID"></app-breadcrumb>

<div class="row webpartrow list-control list-control-withbtn">
    <div class="col-lg-12">
        <div class="m-b-30">
            <app-grid  (gridCustomActions)="gridCustomActions($event)" [IsUnauthorizedPage]="false" instanceID="testerGrid"></app-grid>
        </div>        
    </div>
</div>