import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/encryption.service';
import { Guid } from 'guid-typescript';
import { DocumentAssestsViewerPopupComponent } from '../../common/document-assests-viewer-popup/document-assests-viewer-popup.component';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { JobShiftReportModel } from 'src/app/models/JobShiftReportModel';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EnumHelper } from 'src/app/util/enum-helper';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { GridComponent } from '../../common/grid/grid.component';
import { ObjectCommandModel, TransitionModel } from 'src/app/models/ObjectCommandModel';
import { Subscription, lastValueFrom } from 'rxjs';
import { isContainerVisible } from 'survey-core/typings/utils/utils';
import CustomStore from 'devextreme/data/custom_store';
import dxDataGrid from 'devextreme/ui/data_grid';
import { DxDataGridComponent } from 'devextreme-angular';
import { MapService } from 'src/app/services/map/map.service';
import { RibbonControlComponent } from '../../common/ribbon-control/ribbon-control.component';
import { NotificationService } from 'src/app/services/notification/notification.service';


@Component({
  selector: 'app-shift-details',
  templateUrl: './shift-details.component.html',
  styleUrls: ['./shift-details.component.scss']
})
export class ShiftDetailsComponent extends PageBaseService implements OnInit, OnDestroy {
  ComponentObjectID: string;
  parameterSet: Boolean = false;
  recordID: string = Guid.EMPTY;
  relationshipRecordID: string = Guid.EMPTY;
  newID: string = Guid.EMPTY;
  loggedinUserID: string = '';
  bigbuttonRedirectUrl: string;
  IsEditMode: boolean = true;
  isSetUrl: boolean = false;
  IsShiftReportStatusDidNotAttend: boolean = false;
  isVisible: boolean = false;
  isVisible2: boolean = false;
  isVisible3: boolean = false;
  isVisible4: boolean = false;
  isVisible5: boolean = false;
  isVisible6: boolean = false;
  isVisible7: boolean = false;
  jobId: string = '';
  NotificationId: string;
  ribbonSubscription: Subscription;
  performRibbonCommandOperation: Subscription;  

  @ViewChild("shiftReportTimeRecordingDetails", { static: true }) shiftReportTimeRecordingDetails: GridComponent
  @ViewChild("shiftReportTravelRecordingDetails", { static: true }) shiftReportTravelRecordingDetails: GridComponent
  @ViewChild("shiftReportRibbonControl", { static: false }) shiftReportRibbonControl: RibbonControlComponent

  constructor(protected mapService: MapService, private localStorageService: LocalStorageService, private httpClient: HttpClient, private alertService: AlertService, private auth: AuthService, private translateService: TranslateService, private eventQueue: LobasEventQueueService, private _router: Router, private _enc: EncryptionService,private notificationService: NotificationService) {
    super(_router, _enc);
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
    this.loggedinUserID = this.auth.getGuidUserID() ?? '';
    this.ComponentObjectID = 'E8548C49-1CC3-4D3F-8CE7-EAB74B1EE593';
    this.shiftDetailsPageURL().then((response) => {
      this.bigbuttonRedirectUrl = response;
      this.isSetUrl = true;
    });
  }

  async validateChildControls(): Promise<boolean> {
    var isValid: boolean = false;
    return new Promise<boolean>(async (resolve, reject) => {
      var isTimeRecordingGridValid: Boolean = await this.shiftReportTimeRecordingDetails?.isValidFormData();
      var isTravelRecordingGridValid: Boolean = await this.shiftReportTravelRecordingDetails?.isValidFormData();
      if (isTimeRecordingGridValid && isTravelRecordingGridValid) {
        isValid = true;
      }
      resolve(isValid);
      reject(false);
    });
  }

  async onTransitionBtnClick(data: any) {
    var currentTransition: TransitionModel = <TransitionModel>data.transition;
    if (currentTransition.Value.toLowerCase() === 'did not attend') {
      this.didNotAttendAction();
    } else {
      var isValid = await this.validateChildControls();
      if (isValid) {
        var warningTxt = this.showCustomWarnings() ?? '';
        if (warningTxt.length > 0) {
          this.alertService.showConfirmationAlert('Warning', warningTxt, 'fa-solid fa-triangle-exclamation', true).then((response: any) => {
            if (response.isConfirmed) {
              this.shiftReportTimeRecordingDetails?.onRibbonTransitionClick(data);
              this.shiftReportTravelRecordingDetails?.onRibbonTransitionClick(data);
            }
          });
        } else {
          this.shiftReportTimeRecordingDetails?.onRibbonTransitionClick(data);
          this.shiftReportTravelRecordingDetails?.onRibbonTransitionClick(data);
        }
      }
    }
  }

  showWarning(commandName:string):boolean
  {
    if(commandName.toLowerCase()=="re-submit" || commandName.toLowerCase()=="submit"|| commandName.toLowerCase()=="save"){
      return true;
    }
    else {
      return false;
    }
  }

  async onRibbonCommandClick(command: ObjectCommandModel) {
    var isValid: boolean = true;
    if (command.Definition.PerformValidations) {
      isValid = await this.validateChildControls();
    }
    if (isValid) {
      var warningTxt = this.showCustomWarnings() ?? '';
      if ( this.showWarning(command.Name) &&  warningTxt.length > 0) {
        this.alertService.showConfirmationAlert('Warning', warningTxt, 'fa-solid fa-triangle-exclamation', true).then((response: any) => {
          if (response.isConfirmed) {
            this.shiftReportTimeRecordingDetails?.commandAction(command);
            this.shiftReportTravelRecordingDetails?.commandAction(command);
          }
        });
      } else {
        this.shiftReportTimeRecordingDetails?.commandAction(command);
        this.shiftReportTravelRecordingDetails?.commandAction(command);
      }
    }
  }

  didNotAttendAction() {
    var url = environment.apiUrl + this.shiftReportTimeRecordingDetails.componentSettings.Commands?.
      find(x => x.Command.toLocaleLowerCase() == 'update'
        || x.Command.toLocaleLowerCase() == 'saveall').Definition.APIActionEndPoint + this.recordID;
    return lastValueFrom(this.httpClient.put(url,
      {
        "LeaveHome": null,
        "StartWork": null,
        "FinishWork": null,
        "ArriveHome": null,
        "BreakTime": null
      }
      ,
      {
        headers: {
          'objectID': this.shiftReportTimeRecordingDetails.gridProperties.ObjectID
        },
        responseType: "text"
      }
    )).then(response => {
      this.eventQueue.dispatch(new AppEvent(AppEventType.PerformRibbonTransitionOperation, { NewID: this.newID, updateNewIDWithRecordIDInURL: false, RecordID: this.recordID }));

    })
      .catch((ex) => { throw ex.message });
  }

  showCustomWarnings(): string {
    var currentRow = this.shiftReportTimeRecordingDetails.currentRowData;
    var leavehome = new Date(currentRow?.row?.data["LeaveHome"] ?? '');
    var startwork = new Date(currentRow?.row?.data["StartWork"] ?? '');
    var finishwork = new Date(currentRow?.row?.data["FinishWork"] ?? '');
    var arrivehome = new Date(currentRow?.row?.data["ArriveHome"] ?? '');
    let _tempDate = new Date(startwork);

    if (currentRow?.row?.data["BreakTime"] != null && new Date(currentRow?.row?.data["BreakTime"]).toString() === 'Invalid Date') {
      var breaktime = currentRow?.row?.data["BreakTime"].split(":");
      _tempDate.setHours(startwork.getHours() + Number.parseInt(breaktime[0]));
      _tempDate.setMinutes(startwork.getMinutes() + Number.parseInt(breaktime[1]));
    } else if (currentRow?.row?.data["BreakTime"] != null) {
      breaktime = new Date(currentRow?.row?.data["BreakTime"]);
      _tempDate.setHours(startwork.getHours() + (<Date>breaktime).getHours());
      _tempDate.setMinutes(startwork.getMinutes() + (<Date>breaktime).getMinutes());
    }

    let doortodoor;
    let dutydetected;

    if (startwork != null) {

      if (finishwork != null) {
        dutydetected = (finishwork.getTime() - _tempDate.getTime());
      }
      dutydetected = (dutydetected / (3600 * 1000))
    }

    if (leavehome != null && arrivehome != null) {
      doortodoor = (arrivehome.getTime() - leavehome.getTime());
      doortodoor = doortodoor / (3600 * 1000);
    }

    if (dutydetected > 12 && doortodoor > 14) {
      return "Over 12 hrs on duty and 14 hrs door to door detected.";

    }
    else if (dutydetected > 12) {
      return "Over 12 hours of duty detected.";

    }
    else if (doortodoor > 14) {
      return "Over 14 hours Door to door.";
    }
    return '';
  }

  async shiftDetailsPageURL() {
    var recordID = await this.GetKeyValueFromQueryString('RecordID');
    return "/Lobas.Web/Pages/shiftreport/shiftreportdetails.aspx?RecordID=" + recordID;
  }

  ngOnInit() {
    this.getAllQueryStrings();
    this.ribbonSubscription = this.eventQueue.on(AppEventType.RibbonTransitionClick).
      subscribe(event => this.onTransitionBtnClick(event.payload));

    this.performRibbonCommandOperation = this.eventQueue.on(AppEventType.PerformRibbonCommandOperation).
      subscribe(event => this.onRibbonCommandClick(event.payload));
  }


  ngOnDestroy() {
    if (this.ribbonSubscription) {
      this.ribbonSubscription.unsubscribe();
    }
    if (this.performRibbonCommandOperation) {
      this.performRibbonCommandOperation.unsubscribe();
    }
  }

  async SetPageModeForsShiftStatus(shiftReportRibbonControl:RibbonControlComponent)  {
    if (!this.IsNullorEmpty(this.recordID)) {
      this.httpClient.get<Array<JobShiftReportModel>>(environment.apiUrl + "api/ShiftReport/GetJobByShiftReport/" + this.recordID)
        .subscribe(async (response) => {
          if (response && response[0] != null && response.length > 0) {
            var jobshiftReportRecord: JobShiftReportModel = response[0];
            if (jobshiftReportRecord  == null || jobshiftReportRecord.JobRecordID == Guid.EMPTY) {
              this.showRecordNotFound();
              return;
            }
            if (this.isAccessAllowed(jobshiftReportRecord)) {
              if (this.IsNullorEmpty(this.jobId)) {
                var returnurl =  "";
                var quesryStringReturnEnabled = await this.GetKeyValueFromQueryString("ReturnUrlEnabled");
                var queryStringReturnURL = await this.GetReturnURL();
                if (!this.IsNullorEmpty(quesryStringReturnEnabled) && !this.IsNullorEmpty(queryStringReturnURL)) {
                  returnurl = "&ReturnUrlEnabled=" + quesryStringReturnEnabled + "&returnurl=" + await this.DecryptUrl(queryStringReturnURL);
                }
                var newQuerystring = "RecordID=" + this.recordID + "&JobID=" + jobshiftReportRecord.JobRecordID + returnurl;
                this.ReloadPageShiftDetail("/AngularUI/shift-details", newQuerystring);
                //this.ngOnInit();
                //this.ReloadPage("/AngularUI/shift-details", newQuerystring);
                return;
              }
              var didNotAttendEnumId = "4D52F3E5-A6BA-49BD-A084-1FBAACB061FA";
              if (jobshiftReportRecord != null && jobshiftReportRecord.JobRecordID != Guid.EMPTY) {
                if (jobshiftReportRecord.ShiftReportStatus_EnumID.toUpperCase() == didNotAttendEnumId) {
                  this.IsShiftReportStatusDidNotAttend = true;
                }
                this.setRecordEditableProperty(jobshiftReportRecord, shiftReportRibbonControl);
                shiftReportRibbonControl.bindEnumerationTransitions();
              }
            }
            else {
              this._router.navigateByUrl('unauthorised');
            }
          }
          else {
              this._router.navigateByUrl('unauthorised');
          }
        });
      }
      else {
          this.showRecordNotFound();
        }
    }

    isAccessAllowed(jobshiftReportRecord:JobShiftReportModel):boolean {
      var roles = this.localStorageService.GetLocal(EnumHelper.LocalStorageKey.userRoles);
      return jobshiftReportRecord.ShiftReportContactID.toLowerCase() === this.loggedinUserID.toLowerCase() || roles.toLowerCase().includes('superuser')
    }

  setRecordEditableProperty(jobshiftReportRecord:JobShiftReportModel,shiftReportRibbonControl:RibbonControlComponent) {
    
    this.shiftReportTimeRecordingDetails.ChangeModeOfControls("View");
    this.shiftReportTravelRecordingDetails.ChangeModeOfControls("View");
    this.IsEditMode = false;
    
    var rejectedEnumId = "27E2BBE4-B8D9-425C-B3C5-21FBE11A684A";
    var reportOverdueEnumId = "52059AA6-D872-4413-A751-A89162457DAD";
    var reportDueEnumId = "55FA3BF2-6D2F-457E-A524-592D4BC4A392";

    if (jobshiftReportRecord.ShiftReportStatus_EnumID.toUpperCase() == rejectedEnumId
                  || jobshiftReportRecord.ShiftReportStatus_EnumID.toUpperCase() == reportOverdueEnumId
                  || jobshiftReportRecord.ShiftReportStatus_EnumID.toUpperCase() == reportDueEnumId) {
                    if (jobshiftReportRecord.ShiftReportContactID.toLowerCase() === this.loggedinUserID.toLowerCase()) {
                      this.shiftReportTimeRecordingDetails.ChangeModeOfControls("Edit");
                      this.shiftReportTravelRecordingDetails.ChangeModeOfControls("Edit");
                      this.IsEditMode = true;
                    } 
                    else {
                      this.shiftReportRibbonControl.hideTransitionAndCommands =true;
                    }
                  }
  }

  showRecordNotFound() {
    this.translateService.get('ShiftReportNotAvailable', { }).subscribe((text: string) => {
    var InfoTitle:string="";
    this.translateService.get('InfoTitle').subscribe((translated: string) => {
    InfoTitle = translated;
    });
    var OkButtonText:string="";
    this.translateService.get('OkButtonText').subscribe((translated: string) => {
      OkButtonText = translated;
    });
      this.alertService.showAlertPopup(InfoTitle, text,'fa-solid fa-circle-info', OkButtonText);
    });
    this._router.navigateByUrl('home');
  }


  expandCollaspeComponent() {
    this.isVisible = !this.isVisible;
  }
  expandCollaspeComponent2() {
    this.isVisible2 = !this.isVisible2;
  }
  expandCollaspeComponent3() {
    this.isVisible3 = !this.isVisible3;
  }
  expandCollaspeComponent4() {
    this.isVisible4 = !this.isVisible4;
  }
  expandCollaspeComponent5() {
    this.isVisible5 = !this.isVisible5;
  }
  expandCollaspeComponent6() {
    this.isVisible6 = !this.isVisible6;
  }
  expandCollaspeComponent7() {
    this.isVisible7 = !this.isVisible7;
  }
  getAllQueryStrings() {
    this.GetKeyValueFromQueryString('recordID').then((recordID => {
      this.recordID = recordID;
      this.GetKeyValueFromQueryString('relationshipRecordID').then((relationshipRecordID => {
        this.relationshipRecordID = relationshipRecordID;

        this.GetKeyValueFromQueryString('NRID').then((notificationId => {
          this.NotificationId = notificationId;
          if(this.NotificationId != "" && this.NotificationId != '' && this.NotificationId != null&& this.NotificationId != undefined ){
            this.notificationService.notificationUserAuthorised(this.NotificationId,this.loggedinUserID);
          }
          console.log('NotificationId1',this.NotificationId);
          this.GetKeyValueFromQueryString('JobID').then((JobID => {
            this.jobId = JobID;
            this.parameterSet = true;
            this.newID = Guid.EMPTY;
            this.SetPageModeForsShiftStatus(this.shiftReportRibbonControl);
          }));

        }));
      }));
    }));
  }
  async gridCustomActions(data: any) {
    switch (data.CommandObject.Command.toLowerCase()) {
      case "cancel":
      case "cancelall":
        try {
          var redirectURL = data.CommandObject.Definition?.VisibilityOption[0]?.RedirectURL ?? "";
          if (redirectURL.length === 0) {
            redirectURL = this._router.getCurrentNavigation().previousNavigation.finalUrl.toString();
          }
          this._router.navigateByUrl(redirectURL);
        } catch (Ex) {
          this._router.navigateByUrl('app-home');
        }

        break;
      case "raiseclosecall":
        this.GetKeyValueFromQueryString('recordID').then((recordID => {
          this.GetKeyValueFromQueryString('JobID').then((async JobID => {
            var queryStringParameters = 'newID=' + Guid.create() + '&jobID=' + JobID;
            if (data.CommandObject.Definition.VisibilityOption[0].ReturnUrlEnabled && data.CommandObject.Definition.VisibilityOption[0].ReturnUrlEnabled.toLowerCase() === "true") {
              this.RedirectToEncryptUrl('closecall-details?' + queryStringParameters + "&" + "ReturnUrlEnabled=true" + "&" + "ReturnUrl=" + await this.DecryptUrl(window.location.href))
            }
            else {
              this.RedirectToEncryptUrl('closecall-details?' + queryStringParameters)
            }
          }));
        }))
        break;
      default:
        this.translateService.get('CustomActionYetToImplemented', { title: data.CommandObject.Name }).subscribe((text: string) => {
          var InfoTitle:string="";
          this.translateService.get('InfoTitle').subscribe((translated: string) => {
          InfoTitle = translated;
          });
          var OkButtonText:string="";
          this.translateService.get('OkButtonText').subscribe((translated: string) => {
          OkButtonText = translated;
          });

          this.alertService.showAlertPopup(InfoTitle, text,'fa-solid fa-circle-info', OkButtonText);
        });
        break;
    }
  }

}
