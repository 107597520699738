
<!-- ***************************This code has been generated using LOBAS Templates 4.0.23.0  -->

<app-breadcrumb [ComponentObjectID]="componentObjectID"></app-breadcrumb>

<div class="row">
    <div class="col-lg-12">
        <app-grid #objectdatasetDetail (gridCustomActions)="gridCustomActions($event)" [IsUnauthorizedPage]="false"
        instanceID="objectdatasetDetail"></app-grid>
    </div>
</div>