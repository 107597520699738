import { Injectable } from "@angular/core";
import { CardSettings, FieldSettings } from "./FieldSettings";
import { Grouping } from "./FieldSettings";
@Injectable({
    providedIn: 'root',
})

export class GridComponentProperties {
    ObjectName: string = ''
    Title: string = ''
    isDetailComponent: boolean = false;
    ComponentMinHeight: string = '130px';
    ComponentMaxHeight: string = '540px';
    dataRowTemplateName: string;
    UILayoutID: string = '00000000-0000-0000-0000-000000000000'
    Datasource: string = ''
    ObjectID: string = '00000000-0000-0000-0000-000000000000'
    GetRecordsApiEndPoint: string = ''
    IsHeaderVisible: Boolean = true;
    RelationshipID: string = ''
    AllowedRoles: string[] = []
    GridPageSize: number = 20
    isAlignmentTopToBottom: boolean = false
    isWebpartHeaderHidden: boolean = false
    FieldSettings: FieldSettings
    Filter: string = ""
    Mode: string = 'view';
    BannerUrl: string = "";
    Grouping: Grouping[] = [];
    showCheckBoxesMode: string = "always";
    IsCommandButtonsVisible: Boolean = true;
    CardSettings: CardSettings[];
    GridDisplayMode: string = "auto";
    IsRelatedControl: boolean = false;
    LookUpDataFilters:LookupFilters;
    RemoteOperations:boolean = true;
    showColumnHeaders:boolean = true;
    BulkEnumerationTransitionInstanceID:string = '';    
    RemeberScrollLocation:boolean = true;
    ShowGridHeaderSearch:boolean = true;
}

export class LookupFilters{
    EnumerationDescription:string;
    FilterValues:string[];
}

export class ChatComponentProperties {
    ObjectName: string = ''
    Title: string = ''
    Datasource: string = ''
    UILayoutID: string = '00000000-0000-0000-0000-000000000000'
    ObjectID: string = '00000000-0000-0000-0000-000000000000'
    GetRecordsApiEndPoint: string = ''
    InsertRecordsAPIEndPoint: string = ''
    RelationshipID: string = ''
    AllowedRoles: string[] = []
    OrderBy: string = ""
    Filter: string = ""
    Mode: string = "Default Note";
    ComponentMinHeight: string = '130px';
    ComponentMaxHeight: string = '400px';
    NoteType_EnumID: string = '00000000-0000-0000-0000-000000000000'
}
export class DefaultAddressesComponentProperties {
    Title: string = ''
    RelationshipID:string='00000000-0000-0000-0000-000000000000';
    AvailableAddresses:string =  "Website,Postal";
    Mode:string='view';
    ComponentMinHeight: string = '130px';
    ComponentMaxHeight: string = '400px';
}

export class ExternalReferencesComponentProperties {
    Title: string = ''
    RelationshipID:string='00000000-0000-0000-0000-000000000000';
    AvailableDataSource:string =  "";
    Mode:string='view';
    ObjectID:string='00000000-0000-0000-0000-000000000000'
    ComponentMinHeight: string = '130px';
    ComponentMaxHeight: string = '400px';
}

