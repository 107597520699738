import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxPopupComponent } from 'devextreme-angular';
import { EncryptionService } from 'src/app/encryption.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { DocumentAssestsViewerDetailComponent } from '../document-assests-viewer-detail/document-assests-viewer-detail.component';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpXhrBackend } from '@angular/common/http';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MailData, SendEmailComponent } from '../send-email/send-email.component';
import { catchError } from 'rxjs';
import Swal from 'sweetalert2';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { AppEvent } from 'src/app/models/AppEvent';
import { AppEventType } from 'src/app/enums/appEventType';
import { NoteModel } from 'src/app/models/NoteModel';
import { Guid } from 'guid-typescript';
import { SignalRService } from 'src/app/services/signalr-service/signalr-service.service';

@Component({
  selector: 'app-send-close-call-report-popup',
  templateUrl: './send-close-call-report-popup.component.html',
  styleUrls: ['./send-close-call-report-popup.component.scss']
})
export class SendCloseCallReportPopupComponent extends PageBaseService {
    
  @ViewChild("SendCloseCallReport", { static: true }) SendCloseCallReport: DxPopupComponent;
  @ViewChild("SendEmail", { static: false }) SendEmail: SendEmailComponent;  
  
  closeCallReportUrl:string=""
  docViewerVisible: Boolean = false;
  sendEmailVisible: Boolean = false;
  tempCloseCallReportID : string=''
  closeCallReportModel:CloseCallReportModel;
  parentRecordID:string;
  OldApihttpClient: HttpClient = new HttpClient(new HttpXhrBackend({
    build: () => new XMLHttpRequest()
  }));
  constructor(private el: ElementRef,private auth: AuthService,private _router: Router, private _enc: EncryptionService, 
    private httpClient: HttpClient, private alertService: AlertService, private eventQueue: LobasEventQueueService,
    private signalRService: SignalRService)
  {
    super(_router, _enc);
    
    
  }
  ngOnInit()
  {

  }
  showPopup(RecordID:string) 
  {
    this.el.nativeElement.querySelector(".customloader").style = 'display:block';
    document.getElementById("body-overlay").classList.add("body-overlay");
    this.parentRecordID = RecordID;
     
  this.GetTempCloseCallReport(RecordID)
  
  }

  async GetTempCloseCallReport(RecordID:string)
{
  var token = this.auth.getGuidToken() ?? ''
  await this.OldApihttpClient.get<any>(environment.OldapiUrl + 'api/CloseCall/GetCloseCallReportDetails/'+RecordID ,
    {
      headers: {
        "Token": token,
        "ObjectID": "256EE4E0-3B90-43D7-A6C2-CD6C89ABA647",
        "LoggingLevel": "0",
        "Content-Type": "application/json;charset=UTF-8"
      }
    })
    .subscribe((response) => {
      this.el.nativeElement.querySelector(".customloader").style = 'display:none';
      document.getElementById("body-overlay").classList.remove("body-overlay");
      this.closeCallReportModel = null;
      this.SendCloseCallReport.visible = true;
      this.docViewerVisible = true;
      this.sendEmailVisible=true;
      this.closeCallReportUrl = environment.azureStorageURL +"Temp/"+response+"/"+response + "_CloseCallReport_" + response + ".pdf";
      this.tempCloseCallReportID = response;
      this.SendEmail.ClearForm()
      this.SendEmail.BindData(RecordID,"256EE4E0-3B90-43D7-A6C2-CD6C89ABA647","Close Call","Normal");
    });
}

  PopupClose()
  {
    this.SendCloseCallReport.visible = false;
  }
  documentexpandCollaspe()
  {
    this.docViewerVisible = !this.docViewerVisible;
  }
  SendEmailexpandCollaspe()
  {
    this.sendEmailVisible = !this.sendEmailVisible;
  }

  Cancel(e:any)
  {
    this.DeleteTempCloseCallReport();
  }
  Send(e:any)
  {
    if(this.SendEmail.IsValid(e))
    {
      this.el.nativeElement.querySelector(".customloader").style = 'display:block';
      var UserID = this.auth.getGuidUserID() ?? ''
      this.closeCallReportModel = new CloseCallReportModel();
      this.closeCallReportModel.CloseCallRecordID = this.parentRecordID;
      this.closeCallReportModel.mailData = this.SendEmail.GetMailFromData("A Close Call has been reported. Please view the attached report.");
      this.closeCallReportModel.tempCloseCallReportID = this.tempCloseCallReportID;
      this.closeCallReportModel.UserID = UserID;
      this.SaveCloseCallReportAndSendMail(this.closeCallReportModel)
    }    
  }

  DeleteTempCloseCallReport()
  {
    this.SendCloseCallReport.visible = false;
    return new Promise((resolve, reject) => {
      this.httpClient.post<boolean>(environment.apiUrl + 'api/Document/DeleteTempFile/' + this.tempCloseCallReportID, {})
        .subscribe((response) => {
          this.SendCloseCallReport.visible = false;
          resolve(<boolean>response);
          catchError((err: any) => {
            this.SendCloseCallReport.visible = false;
            this.alertService.showAlertPopup('Error', err.error.Message, 'fa-solid fa-xmark', 'OK'); throw err
          })
        });
    });
  }

  SaveCloseCallReportAndSendMail(closeCallReportModel: CloseCallReportModel): Promise<CloseCallReportModel> {
    return new Promise((resolve, reject) => {
      this.httpClient.post<CloseCallReportModel>(environment.apiUrl + 'api/CloseCall/SaveCloseCallReportAndSendMail', JSON.stringify(closeCallReportModel))
        .subscribe(async (response) => {
          closeCallReportModel = response;
          this.el.nativeElement.querySelector(".customloader").style = 'display:none';
          this.SendCloseCallReport.visible = false;
          await Swal.fire({
            title: "Success",
            text: "Close Call Report sent successfully.",
            iconHtml: `<i class='fa-solid fa-check'></i>`,
            confirmButtonText: "Ok",
            allowOutsideClick : false      
          }).then((response: any) => {
            if (response.isConfirmed) { 
              this.saveNotesForSendEmail(closeCallReportModel)            
              this.eventQueue.dispatch(new AppEvent(AppEventType.RefreshRibbonTransitionOnCommandUpdate, { currentTransition: response.CloseCallStatus_EnumID, RecordID: this.parentRecordID }));
              //Updating document gird.
              this.eventQueue.dispatch(new AppEvent(AppEventType.ActionUpdateSuccess, true));
            }
          });
          resolve(<CloseCallReportModel>response);
          catchError((err: any) => {
            this.SendCloseCallReport.visible = false;
            this.alertService.showAlertPopup('Error', err.error.Message, 'fa-solid fa-xmark', 'OK'); throw err

          })
        });
    });

  }

  saveNotesForSendEmail(closeCallReportModel:CloseCallReportModel){
    closeCallReportModel.Notes.forEach(note => {
    this.signalRService.sendMessage(this.getNoteModel(note),  this.parentRecordID, '0CE894E1-6FDE-42D7-8256-E682260FD565');
   });    
  }
  
  getNoteModel(noteBody:string):NoteModel{
    var note = new NoteModel();
    note.RecordID = Guid.create().toString();
    note.NoteType_EnumID = '5E100908-B8DC-48E7-B746-86404CEB023C';
    note.Note = noteBody

    return note;
  }

}


export class CloseCallReportModel
{
  CloseCallRecordID:string='';
  UserID:String='';
  tempCloseCallReportID:string='';
  mailData:MailData=null
  Notes:string[]
}