import { Component } from '@angular/core';
import { register } from 'swiper/element/bundle';
import Chart from 'chart.js/auto';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { environment } from 'src/environments/environment';
import { workerSelection, shiftPlanner } from './data';
register();

@Component({
  selector: 'app-calendar-roster',
  templateUrl: './calendar-roster.component.html',
  styleUrls: ['./calendar-roster.component.scss']
})
export class CalendarRosterComponent {
  isVisible: Boolean = false;
  swiperElement: HTMLScriptElement;
  isProfilePopupVisible: boolean = false;
  isJobviewPopupVisible: boolean = false;
  isWorkerPopupVisible: boolean = false;
  isFormEditable: boolean = true;
  workerSelection = workerSelection;
  shiftPlanner = shiftPlanner;
  chart: any = [];
  increaseNumber: number = 0;
  lastScrollTop: number = 0;
  lastValue: number = 1.00;
  userImg: string = "assets/images/user-worker.jpg?version=" + environment.version;
  departmentList = [{
    ID: 0,
    Name: 'Planning',
  }]
  clientList = [{
    ID: 0,
    Name: 'Network Rail - Lot 12',
  }]
  projectList = [{
    ID: 0,
    Name: 'StoneBridge',
  }]

  constructor(private eventQueue: LobasEventQueueService) {
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }
  expandCollaspeComponent() {
    this.isVisible = !this.isVisible;
  }
  sideScroll(element, direction, speed, distance, step) {
    let scrollAmount = 0;
    var slideTimer = setInterval(function () {
      if (direction == 'left') {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }

  handleSubmit = function (e) {
    e.preventDefault();
  }

  clndr_prev_btn() {
    var container = document.getElementById('calendar_content');
    this.sideScroll(container, 'left', 1, 1400, 50);
  }
  clndr_next_btn() {
    var container = document.getElementById('calendar_content');
    this.sideScroll(container, 'right', 1, 1400, 50);
  }

  openCommonPopup(): void {
    this.isProfilePopupVisible = true;
  }

  openJobviewPopup(): void {
    //alert("Alert");
    this.isJobviewPopupVisible = true;
    this.isWorkerPopupVisible = false;
  }

  show_worker_column(): void {
    this.isWorkerPopupVisible = true;
  }

  hide_workerpop() {
    //alert("Hide");
    this.isWorkerPopupVisible = false;
  }

  toggleFormEditable() {
    this.isFormEditable = !this.isFormEditable;
  }
  ngOnInit() {

    localStorage.setItem("SwiperinitialSlide", JSON.stringify(3));
    this.swiperElement = document.createElement("script");
    this.swiperElement.src = "../../assets/js/swiper.js?v="+environment.version;
    document.body.appendChild(this.swiperElement);
  }
}