
//***************************This code has been generated using LOBAS Templates 4.0.23.0  

import { Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { GridComponent } from 'src/app/Components/common/grid/grid.component';
import { Guid } from 'guid-typescript';
import { AuthService } from 'src/app/services/auth/auth.service';
import { EncryptionService } from 'src/app/encryption.service';
import { Router } from '@angular/router';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { DocumentAssestsViewerPopupComponent } from 'src/app/Components/common/document-assests-viewer-popup/document-assests-viewer-popup.component';
import { DocumentUploadPopupComponent } from 'src/app/Components/common/document-upload-popup/document-upload-popup.component';
import { ObjectCommandModel } from 'src/app/models/ObjectCommandModel';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { Subscription } from 'rxjs';
import { ExtendedAttributesComponent } from 'src/app/Components/common/extended-attributes/extended-attributes.component';

@Component({
  selector: 'app-asset-detail',
  templateUrl: './asset-detail.component.html',
  styleUrls: ['./asset-detail.component.scss']
})
export class assetdetailcomponent extends PageBaseService implements OnInit {
  private fleetDetail:ExtendedAttributesComponent;
  @ViewChild("fleetDetails") set content(fleetComponent : ExtendedAttributesComponent){
    if(fleetComponent){
      this.fleetDetail = fleetComponent;
    }
  }
  @ViewChild("assetDocViewPopup", { static: false }) assetDocViewPopup: DocumentAssestsViewerPopupComponent
  @ViewChild("assetDocUploadPopup", { static: false }) assetDocUploadPopup: DocumentUploadPopupComponent
  @ViewChild("grdAssetDocument", { static: true }) grdAssetDocument: GridComponent
  @ViewChild("assetDetail", { static: true }) grdassetDetail: GridComponent
  componentObjectID: string;
  loggedinUserID: string = Guid.EMPTY;
  parameterSet: Boolean = false;
  public recordID: string = Guid.EMPTY;
  relationshipRecordID: string = Guid.EMPTY;    
  newID: string = Guid.EMPTY;
  performRibbonCommandOperation: Subscription;

  constructor(private alertService: AlertService, private translateService: TranslateService, private auth: AuthService, private eventQueue: LobasEventQueueService, private _router: Router, private _enc: EncryptionService) {
    super(_router, _enc);
    this.componentObjectID = "a9aeb3a7-0bc5-45b0-856a-230afc0d3996";
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
    this.loggedinUserID = this.auth.getGuidUserID() ?? '';
  }
  
  async ngOnInit() {
    this.getAllQueryStrings();
    this.performRibbonCommandOperation = this.eventQueue.on(AppEventType.PerformRibbonCommandOperation).
      subscribe(event => this.onRibbonCommandClick(event.payload));

  }
  getAllQueryStrings() {
    this.GetKeyValueFromQueryString('recordID').then((recordID => {
      this.recordID = recordID;
      this.parameterSet=true;
    }));
  }
  gridCustomActions(data: any) {
    if (data != undefined && data != null) {
      switch (data.CommandObject.Command.toLowerCase()) {
        case "cancel":
        case "cancelall":
          try {
            var redirectURL = data.CommandObject.Definition?.VisibilityOption[0]?.RedirectURL ?? "";
            if (redirectURL.length === 0) {
              redirectURL = this._router.getCurrentNavigation().previousNavigation.finalUrl.toString();
            }
            this._router.navigateByUrl(redirectURL.toLowerCase());
          } catch (Ex) {
            this._router.navigateByUrl('app-home');
          }
          break;
        default:
          this.translateService.get('CustomActionYetToImplemented', { title: data.CommandObject.Name }).subscribe((text: string) => {
            this.alertService.showAlertPopup(this.translateService.instant('InfoTitle'), text,
              'info', this.translateService.instant('OkButtonText'));
          });
          break;
      }
    }

  }
  async validateChildControls() {
    var isValid: boolean = false;
    return new Promise<boolean>(async (resolve, reject) => {
      var isjobDetailsGridValid: Boolean = await this.grdassetDetail?.isValidFormData();
      var isFleetDetailsValid:boolean = this.fleetDetail.isFormValid();
      if (isjobDetailsGridValid && isFleetDetailsValid) {
        isValid = true;
      }
      resolve(isValid);
      reject(false);
    });
  }
  async onRibbonCommandClick(command: ObjectCommandModel) {
    var isValid: boolean = true;
    if (command.Definition.PerformValidations) {
      isValid = await this.validateChildControls();
    }
    if (isValid) {
      var showFleetAlert = !this.grdassetDetail.dataGrid.instance.hasEditData();
      this.grdassetDetail.commandAction(command);
      if(command.Command.toLowerCase()!=='cancel' && command.Command.toLowerCase()!=='cancelall' )
      {
        this.fleetDetail.submitForm(showFleetAlert);
      }
    }
  }
  DocumentgridCustomActions(data: any) {
    if (data != undefined && data != null) {
      switch (data.CommandObject.Command.toLowerCase()) {
        case "view":
        case "viewdocument":
          this.assetDocViewPopup.showDocumentAssetViewerPopup(true, data.SelectedRecords[0], "viewDocument", null);
          break;
        case "uploadpopup":
          if (data.CommandObject.Name.toUpperCase() == "EDIT") {
            this.assetDocUploadPopup.OpenUploadPopup(data.SelectedRecords[0], "editpopup", this.grdAssetDocument);
          }
          else {
            this.assetDocUploadPopup.OpenUploadPopup(null, "newpopup", this.grdAssetDocument);
          }
          break;
        case "deleteupload":
          var RelationshipID = "";
          if (this.grdAssetDocument.gridProperties != null && this.grdAssetDocument.gridProperties != undefined) {
            RelationshipID = this.grdAssetDocument.gridProperties.RelationshipID;
          }
          this.assetDocUploadPopup.DeleteUploadDocument(data.SelectedRecords[0], RelationshipID, this.grdAssetDocument);
          break;
        
        case "downloaddocument":
          this.assetDocUploadPopup.downloadFile(data.SelectedRecords[0]);
          break;
      }
    }

  }

}