import { Component, EventEmitter, Input, Output, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DxTextBoxComponent, getElement } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { DxDataGridComponent, DxPopupComponent } from 'devextreme-angular';

@Component({
  selector: 'app-grid-menu-option-popup',
  templateUrl: './grid-menu-option-popup.component.html',
  styleUrls: ['./grid-menu-option-popup.component.scss']
})
export class GridMenuOptionPopupComponent {
  @Input()
  filterBuilderFields: any;
  @Input()
  filter: any = '';
  @Input()
  isShowFilterRow: boolean;
  @Input() searchPanelText :any;
  @Output() GetUpdatedfilterBuilder: EventEmitter<any> = new EventEmitter<{ filter: any, showFillterOnRow: boolean }>();
  @Output() ShowColumnChooser: EventEmitter<boolean> = new EventEmitter();
  @Output() IsResetGrid: EventEmitter<boolean> = new EventEmitter();
  @Output() ExportGrid: EventEmitter<string> = new EventEmitter();

  @ViewChild("gridMenuPopup", { static: false }) gridMenuPopup: DxPopupComponent
  @ViewChild("searchBox", { static: false }) searchBox: DxTextBoxComponent


  moreInfoButtonOptions: Record<string, unknown>;
  gridMenuOptionName: string = "";
  isFilterBuilderPopup: boolean = false;
  isExportOptionsPopup: boolean = false;
  isShowExportOptions: boolean = false;
  isColumnChooserPopup: boolean = false;
  popuptitle: string;
  searchText: string = "";
  hideFilterBuilder: boolean = false;


  constructor() {
    this.gridMenuOptionName = "";
    this.moreInfoButtonOptions = {
      text: 'More info',
      onClick: () => {
        const message = `More info about`;
        notify({
          message,
          position: {
            my: 'center top',
            at: 'center top',
          },
        }, 'success', 3000);
      },
    };
  }

  showInfo(HeaderName) {
    
    this.popuptitle = HeaderName;
    if (HeaderName.toLowerCase() == "filter builder") {
      this.gridMenuPopup.visible = true;
      if (window.innerWidth < 979) {
        this.hideFilterBuilder = true;

      }     
      this.isFilterBuilderPopup = true;
      this.isExportOptionsPopup = false;
      this.isShowExportOptions = false;
    }
    else if (HeaderName.toLowerCase() == "export options") {
      this.gridMenuPopup.visible = true;
      this.isFilterBuilderPopup = false;
      this.isExportOptionsPopup = true;
      this.isShowExportOptions = true;

    }
  }
  ExportClick(type) {
    this.ExportGrid.emit(type);
    this.gridMenuPopup.visible = false;
  }
  ApplyFilter() {
    this.GetUpdatedfilterBuilder.emit({ filterValue: this.filter, showFillterOnRow: this.isShowFilterRow, searchValue: this.searchBox.value });
    this.gridMenuPopup.visible = false;
  }

  onValueChanged(e) {
    if (e.value===true) {
      this.isShowFilterRow = true;
    }
    else {
      this.isShowFilterRow = false;
    }

  }
}
