<div class="webpart-card" *ngIf="SecurityQuestionVisibility">
    <div class="webpart-cardbody">
        <div *ngIf="IsShowHeader"  class="webpart-card-header" [ngClass]="this.isVisible ? 'webpart-card-closed' : 'webpart-card-opened'">
            <div class="webpart-card-header-title" >
                <span>{{'Security Questions & Answer'|translate}}</span>
            </div>
            <div class="webpart-card-header-action">
                <dx-button (onClick)="expandCollaspeComponent()"
                    [icon]="isVisible ? 'fa-regular fa-angle-up' : 'fa-regular fa-angle-down'"></dx-button>
            </div>
        </div>

        <div [hidden]="isVisible">

            <form method="post" class="security_que_ans_com">
                <div class="form">
                    <dx-validation-group #securityQuestion>
                    <div *ngFor='let obj of securityQuestiondataSource;let i = index' class="form-group-row">

                        <div class="field_group">
                            <div class="field_label required-label-asterisk">Security Question {{i+1}}</div>
                            <div class="field_control">
                                <dx-select-box displayExpr="Question" valueExpr="QuestionNumber"
                                    [dataSource]="obj.securityQuestionList" [inputAttr]="{ 'aria-label': 'QuestionNumber' }"
                                    [searchMode]="searchModeOption" [searchExpr]="searchExprOption"
                                    [searchTimeout]="searchTimeoutOption" [minSearchLength]="minSearchLengthOption"
                                    [searchEnabled]="true" [showDataBeforeSearch]="showDataBeforeSearchOption"
                                    (onSelectionChanged)="QuestionSelectionChanged($event,i,obj)" (onValueChanged)="onValueChanged($event)"
                                    [(value)]="obj.QuestionValue">
                                    <dx-validator>
                                        <dxi-validation-rule type="required"></dxi-validation-rule>
                                    </dx-validator>
                                </dx-select-box>
                            </div>
                        </div>
                        <div class="field_group">
                            <div class="field_label required-label-asterisk">Security Answer</div>
                            <div class="field_control">
                                <dx-text-box (onChange)="onAnswerValueChange($event,i,obj)" 
                                [(value)]="obj.AnswerValue"
                                placeholder="Security Answer {{i+1}}">
                                    <dx-validator>
                                        <dxi-validation-rule type="required"></dxi-validation-rule>
                                    </dx-validator>
                                </dx-text-box>
                            </div>
                        </div>
                    </div>
                    <div class="form-group-row">
                        <div class="field_group">
                            <div class="field_label required-label-asterisk">Password</div>
                            <div class="field_control">
                                <dx-text-box mode="password"  [(value)]="currentPassword" [inputAttr]="{ 'aria-label': 'Password' }">
                                    <dx-validator>
                                        <dxi-validation-rule type="required"></dxi-validation-rule>
                                    </dx-validator>
                                </dx-text-box>
                            </div>
                        </div>
                    </div>
                </dx-validation-group>
                    <div class="form-btn-outer">
                        <dx-button text="Save" (onClick)="SaveSecurityQuestionAndAnswer($event)">
                        </dx-button>
                    </div>
                </div>
            </form>

        </div>

    </div>
</div>