import { HttpClient, HttpXhrBackend } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/encryption.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { ValidationPopupComponent } from '../validation-popup/validation-popup.component';
import { DxValidationGroupComponent } from 'devextreme-angular';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends PageBaseService {
  @ViewChild("validationpopup") validationpopup: ValidationPopupComponent;
  @ViewChild('changePassword', {static: false}) changePasswordGroup: DxValidationGroupComponent
  isVisible: Boolean = false;
  currentPassword: string = '';
  password: string = '';
  confirmPassword: string = '';
  UserID: string = '';
  changePasswordControlVisibility:boolean=false;
  constructor(private el: ElementRef, private alertService: AlertService, private auth: AuthService, private httpClient: HttpClient, private _router: Router, private _enc: EncryptionService) {
    super(_router, _enc);
    this.UserID = this.auth.getGuidUserID() ?? '';
  }

  async ngOnInit() {
    var recordId;
    recordId = await this.GetKeyValueFromQueryString('RecordID');
    if (recordId == this.UserID) {
      this.changePasswordControlVisibility = true;
    }
  }

  expandCollaspeComponent() {
    this.isVisible = !this.isVisible;
  }
  passwordComparison = () => this.password;

  Submit(e: any) {    
    const { isValid } =  this.changePasswordGroup.instance.validate();    
    if (isValid) {
      this.el.nativeElement.querySelector(".customloader").style = 'display:block';
      this.PasswordReset();      
    }
    else{
      const brokenRules = e.validationGroup.validate().brokenRules;           
        this.validationpopup.showPopup(brokenRules.map((rule: any) => {
          return "<p>" + rule.message + "</p>"
        }).join(""));
    }
  }

  async PasswordReset() {
    var userID = this.UserID;
    var oldPassword = this.currentPassword;
    var newPassword = this.password;
    var baseUrl = window.location.origin;
    await this.httpClient.post<any>(environment.apiUrl + 'api/AuthenticateAuthorized/ResetPasswordWithOldPassword', { userID, oldPassword, newPassword, baseUrl })
      .subscribe((response) => {
        if (response != null) {
          var userName = response.Message;
          var password = newPassword;
          this.httpClient.post<any>(environment.OldapiUrl + 'api/Account/ResetPassword', { userName, password })
            .subscribe((response) => {
              this.el.nativeElement.querySelector(".customloader").style = 'display:none';
              this.alertService.showAlertPopup('Success', 'You have successfully changed your password', 'fa-solid fa-check', 'OK')
              catchError((err: any) => {
                this.el.nativeElement.querySelector(".customloader").style = 'display:none';
                this.alertService.showAlertPopup('Error', err.error.Message, 'fa-solid fa-xmark', 'OK'); throw err
              })
            });
        }
        catchError((err: any) => {
          this.el.nativeElement.querySelector(".customloader").style = 'display:none';
          this.alertService.showAlertPopup('Error', err.error.Message, 'fa-solid fa-xmark', 'OK'); throw err

        })
      });
  }
}
