
export const dtsrcBest = [{
  "RecordId": 1,
  "Name": "Stu Piper",
  "Raised": "543",
  "Rating": "★ ★ ★ ★ ☆"
}, {
  "RecordId": 2,
  "Name": "Andy South",
  "Raised": "190",
  "Rating": "★ ★ ★ ☆ ☆"
}, {
  "RecordId": 3,
  "Name": "Slavia Szov",
  "Raised": "119",
  "Rating": "★ ★ ★ ★ ★"
}, {
  "RecordId": 4,
  "Name": "Roy Worth",
  "Raised": "117",
  "Rating": "★ ★ ★ ★ ★"
},]
export const dtsrcWorst = [{
  "RecordId": 1,
  "Name": "Brian Greenaway",
  "Raised": "5",
  "Rating": "★ ★ ★ ★ ☆"
}, {
  "RecordId": 2,
  "Name": "Andy North",
  "Raised": "18",
  "Rating": "★ ★ ★ ☆ ☆"
}, {
  "RecordId": 3,
  "Name": "Simon Pang",
  "Raised": "1",
  "Rating": "★ ★ ★ ★ ★"
}, {
  "RecordId": 4,
  "Name": "Abi Zi",
  "Raised": "11",
  "Rating": "★ ★ ★ ★ ★"
},]

export const closecallSources = [
  { value: 'approved', name: 'Approved', color: '#80e352' },
  { value: 'rejected', name: 'Rejected', color: '#cc0001' },
  { value: 'pending', name: 'Pending', color: '#fb8325' },
  { value: 'onhold', name: 'On Hold', color: '#fed966' }
];
export const closecallInfo = [{
  month: 'Jul',
  approved: 95,
  rejected: 30,
  pending: 65,
  onhold: 80,
}, {
  month: 'Aug',
  approved: 92,
  rejected: 40,
  pending: 75,
  onhold: 75,
}, {
  month: 'Sep',
  approved: 94,
  rejected: 30,
  pending: 40,
  onhold: 60,
}, {
  month: 'Oct',
  approved: 60,
  rejected: 35,
  pending: 45,
  onhold: 40,
}, {
  month: 'Nov',
  approved: 40,
  rejected: 50,
  pending: 55,
  onhold: 55,
}, {
  month: 'Dec',
  approved: 35,
  rejected: 45,
  pending: 60,
  onhold: 70,
}, {
  month: 'Jan',
  approved: 40,
  rejected: 55,
  pending: 75,
  onhold: 60,
}, {
  month: 'Feb',
  approved: 30,
  rejected: 44,
  pending: 70,
  onhold: 40,
}, {
  month: 'Mar',
  approved: 55,
  rejected: 50,
  pending: 60,
  onhold: 60,
}, {
  month: 'Apr',
  approved: 75,
  rejected: 40,
  pending: 50,
  onhold: 70,
}, {
  month: 'May',
  approved: 90,
  rejected: 25,
  pending: 70,
  onhold: 50,
}, {
  month: 'Jun',
  approved: 85,
  rejected: 30,
  pending: 60,
  onhold: 65,
}];