export class QuestionnaireResponseWithRelatedItemsModel {
    RecordID: string;
    QuestionnaireName: string;
    QuestionnaireDescription: string;
    QuestionnaireVersion: string;
    QuestionnaireResponseStatus_EnumID: string;
    QuestioneeTargetReached_EnumID: string;
    QuestionerTargetReached_EnumID: string;
    ReferenceNumber: string;
    StartedDate: Date | null;
    JSON: string;
    Score: number | null;
    QuestionneeName: string;
    RecordDataObjectStatus_EnumID: string;
    QuestionneeContactID: string;
    QuestionerCanEditResponses: boolean;
    Confidential: boolean;
    QuestionnaireJSON: string;
    RecordCreatedDate: Date;
    CreatedUsersName: string;
    RecordUpdatedDate: Date | null;
    UpdatedUsersName: string;
  }