import { HttpClient, HttpXhrBackend } from '@angular/common/http';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { catchError } from 'rxjs';
import { EncryptionService } from 'src/app/encryption.service';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { BrandingModel, BrandingService } from 'src/app/services/branding/branding.service';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends PageBaseService{

  firstName: string = '';
  surName: string = '';
  email: string = '';
  jobTitle: string = '';
  mobileNumber: number;
  userName: string = '';
  password: string = '';
  confirmPassword: string = '';
  language: string = '';
  timezone: string = '';
  isRobot: boolean = false;
  viaList = [
    'Via Email',
    'Via Security Questions'
  ];
  RadioButtonValue: string='Via Email';
  UserNameExistsText: string = "";
  IsUserNameExists: boolean = false;
  bgImage: string = '';
  isShown: boolean = true;
  IsUserNameScreen: boolean = false;
  IsEmailSendScreen: boolean = false;
  IsEmailSentScreen: boolean = false;
  IsResetPasswordScreen: boolean = false;
  IsResetPasswordSuccessfulScreen: boolean = false;
  UserNameList: UsersList[];
  UserInputEmailAddress: string = null;
  securityQuestionList: SaveSecurityQuestionAnswerModel[]=[];
  httpClient: HttpClient = new HttpClient(new HttpXhrBackend({
    build: () => new XMLHttpRequest()
  }));
  isEmailHide:boolean=false;
  verifySecurityQuestionAnswerModel: VerifySecurityQuestionAnswerModel
  constructor(private translateService: TranslateService,private eventQueue: LobasEventQueueService,private el: ElementRef,private cookieServiceChild: CookieService, public brandingService: BrandingService, private alertService: AlertService, private route: ActivatedRoute,private _router:Router,private _enc: EncryptionService) {
    super(_router,_enc);
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, false));
  }
  async ngOnInit() {
    var uesrID = "";
    uesrID  = await this.GetKeyValueFromQueryString('UserId');
    this.UserNameList = await this.GetUserDetails();
    if (uesrID != null && uesrID!='') {
      this.UserNameList.forEach(element => {
        if (element.UserID.toLowerCase() == uesrID.toLowerCase()) {
          this.userName = element.UserName;
        }
      });
      this.IsUserNameScreen = false;
      this.IsEmailSendScreen = false;
      this.IsEmailSentScreen = false;
      this.IsResetPasswordScreen = true;
      this.IsResetPasswordSuccessfulScreen = false;
    }
    else {
      this.IsUserNameScreen = true;
      this.IsEmailSendScreen = false;
      this.IsEmailSentScreen = false;
      this.IsResetPasswordScreen = false;
      this.IsResetPasswordSuccessfulScreen = false;
    }
  }
  onKeyDown(e:any){
    if(e.event.keyCode == 32){
          e.event.preventDefault();  
    }
}
  GetUserDetails(): Promise<UsersList[]> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.apiUrl + "api/Authenticate/GetUserNames")
        .subscribe((response) => {
          resolve(<UsersList[]>response);
        });
    });
  }
  Cancel = () => {
    this._router.navigateByUrl("AngularUI/login")
  }

  passwordComparison = () => this.password;

  handlePasswordReset = function (e) {
    e.preventDefault();
    this.PasswordReset();
  }

  async PasswordReset() {
    var userName = this.userName;
    var password = this.password;
    await this.httpClient.post<any>(environment.OldapiUrl + 'api/Account/ResetPassword', { userName, password })
      .subscribe((response) => {

        if (response != null) {
          var UserID = "";
          var baseUrl = window.location.origin;
          this.httpClient.post<any>(environment.apiUrl + 'api/Authenticate/ResetPassword', { userName, password, UserID, baseUrl })
            .subscribe((response) => {
              this.IsUserNameScreen = false;
              this.IsEmailSendScreen = false;
              this.IsEmailSentScreen = false;
              this.IsResetPasswordScreen = false;
              this.IsResetPasswordSuccessfulScreen = true;
              this.el.nativeElement.querySelector(".customloader").style = 'display:none';
              catchError((err: any) => {
                this.el.nativeElement.querySelector(".customloader").style = 'display:none';
                this.alertService.showAlertPopup('Error', err.error.Message, 'fa-solid fa-xmark', 'OK'); throw err
              })
            });
        }
        catchError((err: any) => {
          this.el.nativeElement.querySelector(".customloader").style = 'display:none';
          this.alertService.showAlertPopup('Error', err.error.Message, 'fa-solid fa-xmark', 'OK'); throw err

        })
      });
  }

  handleSubmit = function (e) {
    e.preventDefault();
    var isUserNameExists = false;
    this.UserNameList.forEach(element => {
      if (element.UserName == this.userName) {
        isUserNameExists = true;
      }
    });
    if (isUserNameExists) {

      this.UserNameExistsText = "";
      this.IsUserNameExists = false;
      this.IsUserNameScreen = false;
      this.IsEmailSendScreen = true;
      this.IsEmailSentScreen = false;
      this.IsResetPasswordScreen = false;
      this.IsResetPasswordSuccessfulScreen = false;
      this.isRobot = false;
      this.GetSecurityQuestionAndAnswers();
    }
    else {
      this.UserNameExistsText = "The Username doesnot exist.";
      this.isRobot = false;
      this.IsUserNameExists = true;
    }

  }
 
  onValueChanged(e:any)
  {
    this.isEmailHide = !this.isEmailHide;
  }
  SendEmailOrVerifySecurityQuestions = function (e) {
    e.preventDefault();
    if(this.RadioButtonValue.toLowerCase()=="via email")
    {
      this.sentEmail();
    }
    else
    {
      this.VerifySecurityQuestionsAndAnswers() 
    }    
  }
  

  async VerifySecurityQuestionsAndAnswers()
  {
    if(this.RadioButtonValue.toLowerCase()=="via security questions")
    {
      var UserID = ""
      this.UserNameList.forEach(element => {
        if (element.UserName == this.userName) {
          UserID = element.UserID;
        }
      });
      this.verifySecurityQuestionAnswerModel = new VerifySecurityQuestionAnswerModel();
    
      this.verifySecurityQuestionAnswerModel.securityQuestionAnswerList = this.securityQuestionList;
      
    await this.httpClient.post<any>(environment.apiUrl + 'api/aspnet_SecurityQuestionUnAuthorised/VerifySecurityQuestionAndAnswer/'+UserID ,JSON.stringify(this.verifySecurityQuestionAnswerModel),
    {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .subscribe((response) => {
      if (response && response==true) {
          window.location.href = window.location.origin +"/AngularUI/reset-password?UserId=" + UserID;
      }
      else
      {
        var IncorrectAnswerTitle:string="";
        var UnableToResetPasswordMessage:string="";
        this.translateService.get('IncorrectAnswerTitle').subscribe((translated: string) => {
          IncorrectAnswerTitle = translated;
        });
        this.translateService.get('UnableToResetPasswordMessage').subscribe((translated: string) => {
          UnableToResetPasswordMessage = translated;
        });
        this.alertService.showAlertPopup(IncorrectAnswerTitle,UnableToResetPasswordMessage, 'fa-solid fa-xmark', 'OK')
      }
    });


    }
  }


  async sentEmail() {
    var UserName = this.userName;
    var EmailAddress = this.UserInputEmailAddress;
    var UserID = "";
    var baseUrl = window.location.origin;
    await this.httpClient.post<any>(environment.apiUrl + 'api/Authenticate/SendEmailForResetPassword', { UserName, EmailAddress, UserID, baseUrl })
      .subscribe((response) => {
        if (response != null) {
          this.IsUserNameScreen = false;
          this.IsEmailSendScreen = false;
          this.IsEmailSentScreen = true;
          this.IsResetPasswordScreen = false;
          this.IsResetPasswordSuccessfulScreen = false;
        }
        catchError((err: any) => { this.alertService.showAlertPopup('Error', err.error.Message, 'fa-solid fa-xmark', 'OK'); throw err })
      });
  }
  // handleValueChange = function (e) {


  //   // if (!this.UserNameList.includes(this.userName)) {
  //   //   this.UserNameExistsText = "The Username doesnot exist.";
  //   //   this.isRobot = false;
  //   //   this.IsUserNameExists = true;
  //   // }
  //   // else {
  //   //   this.UserNameExistsText = "";
  //   //   this.IsUserNameExists = false;
  //   // }
  // }

  async GetSecurityQuestionAndAnswers() {
    var UserID = ""
    this.UserNameList.forEach(element => {
      if (element.UserName == this.userName) {
        UserID = element.UserID;
      }
    });
    
    await this.httpClient.get<any>(environment.apiUrl + 'api/aspnet_SecurityQuestionUnAuthorised/GetAspnetSecurityQuestion/' + UserID)
    .subscribe((response) => {
      if (response) {
          response.forEach(element => {
              this.securityQuestionList.push({QuestionTextValue: element.Question,AnswerValue:""});
        });
      }
    });
  }
}
export class UsersList {
  public UserName: string;
  public UserID: string;
}

export class SaveSecurityQuestionAnswerModel{
  public AnswerValue: string;
  public QuestionTextValue: string;
}

export class VerifySecurityQuestionAnswerModel{
  public securityQuestionAnswerList: SaveSecurityQuestionAnswerModel[];
}
