import { Component } from '@angular/core';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BrandingService } from 'src/app/services/branding/branding.service';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';

@Component({
  selector: 'app-log-out',
  templateUrl: './log-out.component.html',
  styleUrls: ['./log-out.component.scss']
})
export class LogOutComponent {

  constructor(private eventQueue:LobasEventQueueService,public brandingService: BrandingService, private auth: AuthService)
  {
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, false));
    auth.logout();
  }
}
