import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RecordRelationshipBindingModel } from 'src/app/models/RecordRelationshipBindingModel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecordRelationshipBindingToolService {

  constructor( private httpClient: HttpClient) { }

  insertInRelationshipBindingRecord(recordRelationshipBindingModel:RecordRelationshipBindingModel){
  return this.httpClient.post<RecordRelationshipBindingModel>(environment.apiUrl + 'api/RelationshipRecord/insertRelationshipBindingRecord', recordRelationshipBindingModel);
  }
}
