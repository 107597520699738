<div class="webpart-card">
    <div class="webpart-cardbody">
        <div class="webpart-card-header" [ngClass]="this.isVisible ? 'webpart-card-closed' : 'webpart-card-opened'">
            <div class="webpart-card-header-title">
                <span>User Account Details</span>
            </div>
            <div class="webpart-card-header-action">
                <dx-button (onClick)="expandCollaspeComponent()"
                    [icon]="isVisible ? 'fa-regular fa-angle-up' : 'fa-regular fa-angle-down'"></dx-button>
            </div>
        </div>

        <div [hidden]="isVisible">
            <div class="webpart-detail-view">
                <form #Form="ngForm">
                    <div class="form">
                        <div class="form-group-row">
                            <div class="field_group">
                                <div class="field_label">Username</div>
                                <div class="field_control">
                                    <span class="field_control_value_span">{{userdetails.UserName}}</span>
                                </div>
                            </div>
                            <div class="field_group">
                                <div class="field_label">User Role</div>
                                <div class="field_control">
                                    <span class="field_control_value_span">{{userdetails.UserRoles}}</span>
                                </div>
                            </div>
                            <div class="field_group" [hidden]="ishideAccountLocked">
                                <div class="field_label">Account Locked</div>
                                <div class="field_control">
                                    <dx-check-box name="AccountLocked" [inputAttr]="{ 'aria-label': 'AccountLocked' }"
                                        [(ngModel)]="userdetails.AccountLocked">
                                    </dx-check-box>
                                </div>
                            </div>
                            <div class="field_group" [hidden]="ishideUserApproved">
                                <div class="field_label">User Approved</div>
                                <div class="field_control">
                                    <dx-check-box [inputAttr]="{ 'aria-label': 'UserApproved' }"
                                        [(ngModel)]="userdetails.UserApproved" name="UserApproved" [disabled]=true>
                                    </dx-check-box>
                                </div>
                            </div>
                            <div class="field_group">
                                <div class="field_label">Mode</div>
                                <div class="field_control">
                                    <dx-select-box valueExpr="EnumerationId" displayExpr="Value"
                                        [inputAttr]="{ 'aria-label': 'BrandingMode' }"
                                        [dataSource]="dataSourceBrandingMode" name="BrandingMode"
                                        [(ngModel)]="userdetails.BrandingMode"></dx-select-box>

                                </div>
                            </div>
                        </div>
                        <div class="form-btn-outer">
                            <dx-button #btnSave id="btnSave" text="Save" (onClick)="updateUserDetails()"
                                [(visible)]="IsVisiblebtnSave">
                            </dx-button>

                            <dx-button #btnAddEditRole (onClick)="openRoleRermissionPopup()" text="Edit Role Assignment"
                                [(visible)]="IsVisiblebtnAddEditRole"></dx-button>

                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
</div>

<dx-popup #addEditUserRoles popup [showTitle]="true" title="Assign User Roles " [dragEnabled]="false"
    [hideOnOutsideClick]="false" [wrapperAttr]="{ class: 'lobas-popup'}" [showCloseButton]="true">
    <div>
        <dx-list #ddlrolesList [dataSource]="roles" [selectionMode]="selectionModeValue"
            [selectAllMode]="selectAllModeValue" [showSelectionControls]="true" [selectByClick]="selectByClick"
            [(selectedItemKeys)]="selectedKeys" (onSelectionChanged)="onSelectionChanged($event)">
        </dx-list>
        <div #divOverlay class="overlay_userroles" [hidden]="hidedivOverlay"></div>
    </div>
</dx-popup>