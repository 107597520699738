import { Component, Input, OnInit } from '@angular/core';
import { fakeAsync } from '@angular/core/testing';
import { MenuNavigation } from 'src/app/services/menuNavigation/menu.service';
import { EnumHelper } from 'src/app/util/enum-helper';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { BreadcrumbService, ObjectRecordDetails } from 'src/app/services/breadcrumb/breadcrumb.service';
import { Guid } from 'guid-typescript';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { EncryptionService } from 'src/app/encryption.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})

export class BreadcrumbComponent extends PageBaseService implements OnInit {
  @Input()
  ComponentObjectID: string;

  ShowBreadcrumb: boolean = true;
  ActiveRecord: boolean = false;
  InActiveRecord: boolean = false;
  ReturnUrlEnable: boolean = false;
  distinctUrlItems: MenuNavigation[] = [];
  breadcrumbNode: MenuNavigation[] = [];
  isPresentInPath: boolean = false;
  found: boolean;
  objectRecordDetails: ObjectRecordDetails = null;
  objectRecordDescription: string;
  RecordID: string;
  IsDetailsPage: boolean;
  returnUrl: string = '';
  constructor(private localStorageService: LocalStorageService, private clientStorage: LocalStorageService, private breadcrumbService: BreadcrumbService, private auth: AuthService, private route: ActivatedRoute, private _router: Router, private _enc: EncryptionService) {
    super(_router, _enc);
    this.ShowBreadcrumb = true;
    this.ActiveRecord = false;
    this.InActiveRecord = false;
    //this.ReturnUrlEnable = true;
    this.isPresentInPath = false;
    this.distinctUrlItems = this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.distinctMenuItems);
    this.IsDetailsPage = false;
  }
  async ngOnInit() {
    if (this.distinctUrlItems != null && this.distinctUrlItems != undefined) {
      this.GetBreadcrumbNodes(this.distinctUrlItems, window.location.href);
      if (this.breadcrumbNode != null && this.breadcrumbNode != undefined && this.breadcrumbNode.length > 0) {
        if (this.breadcrumbNode[0].url.toLowerCase().includes("detail")) {
          this.breadcrumbNode.splice(0, 1);
        }
      }

      await this.GetKeyValueFromQueryString("ReturnUrlEnabled").then(async response => {
        if (response) {
          this.returnUrl = await this.GetReturnURL();
          if (this.returnUrl) {   
            this.ReturnUrlEnable = this.IsReturnURLIconVisible( this.returnUrl);
          }
        }
      })
    }

    this.RecordID = await this.GetKeyValueFromQueryString('RecordID');
    if (this.ComponentObjectID != null && this.ComponentObjectID != undefined && this.RecordID != null && this.RecordID != undefined && this.RecordID) {
      if(window.navigator.onLine){
        this.objectRecordDetails = await this.breadcrumbService.GetObjectRecordDetails(this.auth.getGuidToken() ?? '', this.RecordID, this.ComponentObjectID);
      }
      if (this.objectRecordDetails != null && this.objectRecordDetails != undefined) {
        this.IsDetailsPage = true;
        this.objectRecordDescription = this.objectRecordDetails.ObjectDescription;
        if (this.objectRecordDetails.ObjectRecordStatus != null && this.objectRecordDetails.ObjectRecordStatus != undefined && this.objectRecordDetails.ObjectRecordStatus == Guid.parse("00000000-0000-0001-3500-000000000003")) {
          this.InActiveRecord = true;
        }
        if (this.objectRecordDetails.ObjectRecordStatus != null && this.objectRecordDetails.ObjectRecordStatus != undefined && this.objectRecordDetails.ObjectRecordStatus == Guid.parse("00000000-0000-0001-3500-000000000002")) {
          this.ActiveRecord = true;
        }
        
      }
      this.IsDetailsPage = true;
    }
  }
  ;
  RedirectToReturnURL() {  
    //window.location.href = this.returnUrl;
    this._router.navigateByUrl(this.returnUrl);
  }

  GetBreadcrumbNodes(distinctNodes: MenuNavigation[], CurrentUrl: string) {
    this.isPresentInPath = false;
    distinctNodes.forEach(menu => {
      this.isPresentInPath = this.SearchAndGetPath(menu, CurrentUrl);
      if (this.isPresentInPath) {
        return;
      }
    })
  }

  SearchAndGetPath(rootElement: MenuNavigation, CurrentUrl: string) {
    if (rootElement == null || rootElement == undefined) {
      return false;
    }

    if (rootElement.url.toLowerCase()==window.location.pathname.toLowerCase() && rootElement.url!='') {
      if(rootElement.url.includes("/") && rootElement.url.includes("AngularUI")) 
        {
          rootElement.url =/[^/]*$/.exec(rootElement.url)[0];
        }
      this.breadcrumbNode.push(rootElement);
      return true;
    }

    if (rootElement.items) {
      for (let i = 0; i <= rootElement.items.length - 1; i++) {
        this.found = this.SearchAndGetPath(rootElement.items[i], CurrentUrl);
        if (this.found) {
           if(rootElement.url.includes("/") && rootElement.url.includes("AngularUI")) 
        {
          rootElement.url =/[^/]*$/.exec(rootElement.url)[0];
        }
          this.breadcrumbNode.push(rootElement);
          return true;
        }
      }
    }
    return false;
  }
  RedirectToURL(url:string){
    this._router.navigateByUrl(url);
  }

  IsReturnURLIconVisible(ReturnURL: string): boolean {
    ReturnURL = ReturnURL.toLowerCase().split("?")[0].replace(window.location.origin.toLowerCase(), "");
    if (this.breadcrumbNode && this.breadcrumbNode[0]) {
      if (this.breadcrumbNode[0].items) {
        var node = this.breadcrumbNode[0].items.filter(x => x.url?.toLowerCase() == ReturnURL.toString().toLowerCase())[0];
        if (node) {
          return false;
        }
        else {
          node = this.breadcrumbNode.filter(x => x.url?.toLowerCase() == ReturnURL.toString().toLowerCase())[0];
          if (node) {
            return false;
          }
          else {
            return true;
          }
        }
      }
      else {
        node = this.breadcrumbNode.filter(x => x.url?.toLowerCase() == ReturnURL.toString().toLowerCase())[0];
        if (node) {
          return false;
        }
        else {
          return true;
        }
      }
    }
    else {
      return true;
    }
  }
}
