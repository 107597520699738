import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RecordSearchComponent } from 'src/app/Components/common/record-search/record-search.component';
import { EncryptionService } from 'src/app/encryption.service';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';

@Component({
  selector: 'app-assettest-list-page',
  templateUrl: './assettest-list-page.component.html',
  styleUrls: ['./assettest-list-page.component.scss']
})
export class AssettestListPageComponent extends PageBaseService {
  isActiveTabVisible: boolean = true;
  isInactiveTabVisible: boolean = true;
  ComponentObjectID: string;
  tab1Index: number = 0;
  @ViewChild('assetRecordSearchPopup', { static: false }) assetRecordSearchPopup: RecordSearchComponent
  componentObjectID: string = 'c163a1b6-2799-4cd9-b09b-0584d01bc993';
  constructor(private _router: Router, private _enc: EncryptionService, private localStorageService: LocalStorageService) {
    super(_router, _enc);
  }

  async ngOnInit() {
    var tabNumber = await this.GetKeyValueFromQueryString("TabControl");
    if (tabNumber) {
      this.tab1Index = Number(tabNumber) - 1;
      this.SetTabIndex("tab1Index", this.tab1Index, this.localStorageService);
    }
    else {
      this.tab1Index = this.GetTabIndex("tab1Index", this.localStorageService);
      this.SetTabIndex("tab1Index", this.tab1Index, this.localStorageService);
    }

    //this.activeGrid = this.grdCloseCall;




  }
  onTab1ItemClick(ev: any) {
    this.SetTabIndex("tab1Index", ev?.itemIndex, this.localStorageService);
  }

  openAssetTestRecordSearchPopup() {
    this.assetRecordSearchPopup.openPopup();
  }
}
