import { ChangeDetectorRef, Component, Input, ViewChild,Output ,EventEmitter} from '@angular/core';
import { DxDropDownBoxComponent, DxDropDownBoxTypes } from 'devextreme-angular/ui/drop-down-box';
import { ComponentPropertiesService } from 'src/app/services/componentProperties/component-properties.service';
import { GridComponent } from '../grid/grid.component';
import { DxPopupComponent, DxSelectBoxComponent, DxValidationGroupComponent } from 'devextreme-angular';
import { WorkerSelectionServiceService } from 'src/app/services/workerSelection/worker-selection-service.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DatetimeChangeEventDetail } from '@ionic/angular';
import dxSelectBox from 'devextreme/ui/select_box';
import { ValidationPopupComponent } from '../validation-popup/validation-popup.component';
import { RelationshipRecordModel } from 'src/app/models/RelationshipRecordModel';
import { Guid } from 'guid-typescript';
import { DateTimeHelperService } from 'src/app/services/datetimehelper/date-time-helper.service';
@Component({
  selector: 'app-worker-selection-tool',
  templateUrl: './worker-selection-tool.component.html',
  styleUrls: ['./worker-selection-tool.component.scss']
})

export class WorkerSelectionToolComponent {

  componentID: 'WorkerSelection'
  instanceID: 'workerSelectionToolInstance'
  isGridBoxOpened = false;
  workerDataSource:any[];
  searchModeOption:string = 'contains';
  searchExprOption = 'RecordLookupValue';
  showDataBeforeSearchOption = false;
  searchTimeoutOption = 200;
  minSearchLengthOption = 0;
  ShiftRequirementID;
  Description:string='';
  StartDate:any;
  EndDate:any;
  TradeName: string;
  @Output() GridRefreshAction = new EventEmitter();
  @ViewChild("WorkerSelectionTool", { static: false }) workerSelectionTool: DxPopupComponent;
  @ViewChild("WorkerSelectBox", { static: false }) dxWorkerSelectBox: DxSelectBoxComponent;
  @ViewChild('WorkerSelectionGroup', { static: false }) WorkerSelectionGroup: DxValidationGroupComponent

  constructor(private dateTimeHelperService :DateTimeHelperService, private httpClient: HttpClient,private componentPropertyData: ComponentPropertiesService,private ref: ChangeDetectorRef, private workerselection : WorkerSelectionServiceService ){

  }

 ngOnInit() {
 
}

getworkerdata(ShiftRequirementID:string){
  this.ShiftRequirementID= ShiftRequirementID;
 this.workerselection.getWorkers(ShiftRequirementID).subscribe((response:any) => {
    this.workerDataSource = response?.filter(x=> x.RecordDataObjectStatus_EnumID=="00000000-0000-0001-3500-000000000002");
    this.TradeName = this.workerDataSource[0].TradeName;
    this.StartDate = this.workerDataSource[0].RecordStartDate
    this.EndDate = this.workerDataSource[0].RecordStartDate
    var emptycolumn:any={};
    emptycolumn.RecordID=Guid.EMPTY;
    emptycolumn.RecordLookupValue='';
    this.workerDataSource.unshift(emptycolumn);
    });
}

  Submit(e: any) {
    var relationshipModel= new RelationshipRecordModel();
    relationshipModel.Object1_RecordID = this.ShiftRequirementID;
    relationshipModel.Object2_RecordID= this.dxWorkerSelectBox.value;
    relationshipModel.RelationshipID= 'A21C0006-675C-453E-9B31-DEFB0EE1231E';

    if(relationshipModel.Object2_RecordID==null){
      relationshipModel.Object2_RecordID= '00000000-0000-0000-0000-000000000000'
    }

    if(relationshipModel.Object2_RecordID === '00000000-0000-0000-0000-000000000000'){
      this.workerselection.UnAssignWorkers(relationshipModel).subscribe((response:any) => {
        this.closePopup();
        this.GridRefreshAction.emit();
       });
    }else{
      this.workerselection.AssignWorkers(relationshipModel).subscribe((response:any) => {
        this.closePopup();
        this.GridRefreshAction.emit();
       });
      }
  }

  openPopup() {
    this.dxWorkerSelectBox.instance.reset();
    this.workerSelectionTool.visible = true;
  }

  closePopup() {
    this.workerSelectionTool.visible = false;
  }

  getJobDetails(JobId:any){
    this.workerselection.getJobDetails(JobId).subscribe((response:any) => {
        this.Description = response.RecordDescription,
        this.StartDate = this.dateTimeHelperService.FormatData(response.RecordStartDate, 'DateTime'),
        this.EndDate = this.dateTimeHelperService.FormatData(response.RecordEndDate, 'DateTime')
  });
  }
}
