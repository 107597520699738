import { Component } from '@angular/core';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';

@Component({
  selector: 'app-questionnaire-response',
  templateUrl: './questionnaire-response.component.html',
  styleUrls: ['./questionnaire-response.component.scss']
})
export class QuestionnaireResponseComponent {
  isVisible: Boolean = false;
  constructor(private eventQueue:LobasEventQueueService){
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }
  expandCollaspeComponent() {
    this.isVisible = !this.isVisible;
  }
}
