<div class="webpart-card"[ngClass]="isComponentVisible? 'ribbon-command-hide-mobile': ''">
    <div class="webpart-cardbody">
        <div
            [ngClass]="!isRibbonVisible? 'ribbon-command-control': 'ribbon-command-control ribbon-command-control-device'">
            <p>{{ ParentEnumerationDescriptionTxt }}</p>
            <div class="form-btn-outer" *ngIf="this.isTransitionsLoaded">
                <i (click)="openRibbonControl()" class="bottom-grip-btn fa-regular"></i>
                <div *ngIf="!hideTransitionAndCommands" [hidden]="isRibbonVisible" class="form-btn-group-outer">
                    <div #transitionCollectionDiv *ngFor="let transition of this.ribbonControlResponse.Transitions">
                        <dx-button [icon]="transition.Icon" text={{transition.Description}}
                            [ngStyle]="{'background-color': transition.Color, 'border-color': transition.Color}"
                            [elementAttr]="{ id: '{{transition.EnumerationID}}' }"
                            (onClick)="transitionAction(transition)"
                            *ngIf="transition.EnumerationID.toString() != this.emptyGUID">
                        </dx-button>
                    </div>
                    <div #commandCollectionDiv *ngFor="let command of this.ribbonControlResponse.Commands">
                        <dx-button *ngIf="(command.RecordID.toString() != this.emptyGUID) && IsEditMode"
                            text={{command.Name}} [elementAttr]="{ id: '{{command.Command}}', class: '' }"
                            (onClick)="commandAction(command)">
                        </dx-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<dx-popup [showTitle]="true" [wrapperAttr]="{class: 'ribcmd_popup ribcmd_popup_small lobas-popup lobas-form-popup' }"
    title="Status Change" #customNotePopup [showTitle]="true" [resizeEnabled]="false" [dragEnabled]="false"
    [hideOnOutsideClick]="false" [showCloseButton]="true">
    <p class="ribcmd_popup_msg">{{this.customNotePopupLbltxt}}</p>
    <form #customNoteForm method="post">
        <div class="form-row">
            <div class="field_group">
                <div *ngIf="this.isMandatoryNote" class="field_label required-label-asterisk">Note</div>
                <div *ngIf="!this.isMandatoryNote" class="field_label">Note</div>
                <div class="field_control">
                    <dx-text-area #notetxtBox hint="Custom note" [(value)]="this.transitionNote">
                        <dx-validator validationGroup="customNoteValidationGroup" *ngIf="this.isMandatoryNote">
                            <dxi-validation-rule type="required" message="Note is required" />
                        </dx-validator>
                    </dx-text-area>
                </div>
            </div>
        </div>
        <div class="form-btn-outer">
            <dx-button (onClick)="this.cancelNotePopup()" text="Cancel">
            </dx-button>
            <dx-button text={{this.okBtnTxt}} validationGroup="customNoteValidationGroup"
                (onClick)="this.updateTransition($event)">
            </dx-button>
        </div>
    </form>
</dx-popup>
<app-validation-popup #validationpopup></app-validation-popup>