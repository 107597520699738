<div class="card-slider">
    <swiper-container class="mySwiper" loop="true" autoplay="true">
        <swiper-slide *ngFor="let slide of slideDataSource">
            <div class="card-slider-box" (click)="CardSliderClick(slide.URL)">
                <div class="card-slider-icon">
                    <i class="{{slide.Icon}}"></i>
                </div>
                <div class="card-slider-content">
                    <div class="card-slider-count">
                        {{slide.Count}}
                    </div>
                    <h4>{{slide.Name}}</h4>
                </div>
            </div>
        </swiper-slide>
    </swiper-container>
</div>