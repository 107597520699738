<div class="customloader" id="customloader"></div>
<div class="login-background"><img src="{{brandingService.GetSplashScreen()}}" /></div>
<div class="login-container validation-container verify-details-cont">
    <div class="card my-auto shadow">
        <div class="card-body">
            <div class="logo-head ">
                <img class="login_logo" src="{{brandingService.GetDesktopLogo()}}" />
            </div>
            <div>
                <div class="text-center">
                    <!-- <div class="text-centre">
                        <h1 i18n style="margin-top:0"><strong>statrack</strong></h1>
                    </div> -->
                    <p>You have successfully logged out.</p>
                    <a class="ret_to_login login_btns" [routerLink]="['/login']">Return to Log In page</a>
                </div>
            </div>
        </div>
        
    </div>

</div>