import { Component } from '@angular/core';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';

@Component({
  selector: 'app-notification-details',
  templateUrl: './notification-details.component.html',
  styleUrls: ['./notification-details.component.scss']
})
export class NotificationDetailsComponent {
  isVisible: Boolean = false;
  isVisible2: Boolean = false;

  constructor(private eventQueue:LobasEventQueueService){
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }
  expandCollaspeComponent() {
    this.isVisible = !this.isVisible;
  }
  expandCollaspe2Component() {
    this.isVisible2 = !this.isVisible2;
  }
}
