import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { BrandingService } from 'src/app/services/branding/branding.service';
import { MenuService, MenuNavigation } from 'src/app/services/menuNavigation/menu.service';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { EnumHelper } from 'src/app/util/enum-helper';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { AppEventType } from 'src/app/enums/appEventType';
import notify from 'devextreme/ui/notify';
import { EncryptionService } from 'src/app/encryption.service';
import { Router } from '@angular/router';
import { DocumentModel, DocumentViewerService } from 'src/app/services/documentViewer/document-viewer.service';
import { HttpClient } from '@angular/common/http';
import { UserIconImageService } from 'src/app/services/userIconImage/user-icon-image.service';
import { DeviceHelper } from 'src/app/util/device-Helper';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: false,
  providers: [MenuService],

})


export class HeaderComponent implements OnInit {
  menuItems: MenuNavigation[] = [];
  distinctUrlItems: MenuNavigation[] = [];
  rootMenu: MenuNavigation[]
  accountMenu: MenuNavigation[];
  notificationCount: number;
  showSubmenuModes: any;
  showFirstSubmenuModes: any;
  toggle: boolean = true;
  hasNotificationPermision: boolean = false;
  userID: string;
  userNameInitial: string = "";

  navSliderMenuburgerBtn() {
    var navHeader = document.getElementById("navigation-header");
    if (navHeader != null) {
      navHeader.classList.add('sidebar-active');
    }
  }

  navSliderMenuCloseBtn() {
    var navHeader = document.getElementById("navigation-header");
    if (navHeader != null) {
      navHeader.classList.remove('sidebar-active');
    }
  }

  constructor(private router: Router, private localStorageService: LocalStorageService, 
    private menuService: MenuService, private httpClient: HttpClient,
    private docServices: DocumentViewerService,
    private auth: AuthService, public brandingService: BrandingService,
    private eventQueue: LobasEventQueueService, private encrypt: EncryptionService, private userIconImageService:UserIconImageService) {
    this.menuItems = this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.jsonMenu);
    this.rootMenu = [];
    this.accountMenu = [];
    this.notificationCount = 0;
    this.distinctUrlItems = this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.distinctMenuItems);
    this.userID = this.auth.getGuidUserID() ?? '';
  }

  handleEvent(res) {
    notify(res);
  }

  GetUserDetails() {
    if (localStorage.getItem("GetUserNameByUserID_" + this.auth.getGuidUserID())) {
      this.userNameInitial = localStorage.getItem("GetUserNameByUserID_" + this.auth.getGuidUserID());
    }
    else {
      this.httpClient.get(environment.apiUrl + 'api/Contact/GetRecordWithRecordId/' + this.auth.getGuidUserID()).subscribe((response: any) => {
        this.userNameInitial = response.FirstName + " " + response.LastName;
        localStorage.setItem("GetUserNameByUserID_" + this.auth.getGuidUserID(), response.FirstName + " " + response.LastName);
      });
    }

  }

  async ngOnInit() {
    this.eventQueue.on(AppEventType.UpdateNotificationCountPostRead).
    subscribe(event => this.updateNotificationCountPostRead());
    
    this.eventQueue.on(AppEventType.ClickedOnNotification).
    subscribe(event => this.handleEvent(event.payload));
    
    this.GetUserDetails()

    if (this.menuItems === null || this.menuItems.length === 0) {
      this.menuItems = await this.menuService.GetMenuItems(this.auth.getGuidToken() ?? '');
      if (this.menuItems != null && this.menuItems.length > 0) {
        this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.jsonMenu, this.menuItems);
      }
    }
    if (this.distinctUrlItems == null || this.distinctUrlItems.length == 0) {
      this.distinctUrlItems = await this.menuService.GetDistinctMenuItems(this.auth.getGuidToken() ?? '');
      if (this.distinctUrlItems != null && this.distinctUrlItems.length > 0) {
        this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.distinctMenuItems, this.distinctUrlItems);
      }


    }
    var isMobileDevice = false;
    if (DeviceHelper.isMobileDevice() || DeviceHelper.isTabletDevice()) {
        isMobileDevice = true;
    }
    
    this.menuItems.forEach(parent => {
      switch (parent.name.toLowerCase()) {
        case 'home':
          this.rootMenu = this.filterListItems(parent, parent.items);
          break;
        case 'profile root menu':
          parent.items.forEach(child => {
            switch (child.name.toLowerCase()) {
              case 'account':

                this.accountMenu = [child];
                child.items.forEach(async subChild => {
                  if (subChild.name.toLowerCase() === 'log out') {
                    subChild.url = '';
                  }
                  if (subChild.name.toLowerCase() == 'profile' && subChild.url != '' && this.userID != '') {
                    if(isMobileDevice){
                    subChild.url = subChild.url + '?d= RecordID=' + this.userID;
                    }
                    else{
                      subChild.url = subChild.url + '?d=' + await this.encrypt.encryptionAES('RecordID=' + this.userID);
                    }
                  }
                })
                break;
              case 'notification bell':
                this.hasNotificationPermision = true;
                break;
            }
          })
          break;
      }
    });

    this.notificationCount = await this.menuService.GetUserNotificationCount(this.auth.getGuidToken() ?? '');

    this.showSubmenuModes = [{
      name: 'onHover',
      delay: { show: 0, hide: 500 },
    }, {
      name: 'onClick',
      delay: { show: 0, hide: 300 },
    }];
    this.showFirstSubmenuModes = this.showSubmenuModes[0];
  }

  ItemClick(data: any) {
    if (data.itemData.name.toLowerCase() === 'log out') {
      this.auth.logout();
    }
    if(data.itemData.url.includes("/") && data.itemData.url.includes("AngularUI")) 
      {
        var url =/[^/]*$/.exec(data.itemData.url)[0]
        this.router.navigateByUrl(url)
      }
      else{
        if(data.itemData.url!="" &&  data.itemData.url!=null){
        window.location.href=data.itemData.url;
        }
      }
   ;
  }

  itemClickMenu(e) {
    if(e.itemData.url.includes("/") && e.itemData.url.includes("AngularUI")) 
    {
      var url =/[^/]*$/.exec(e.itemData.url)[0]
      this.router.navigateByUrl(url)
    }
    else{
      if(e.itemData.url!="" &&  e.itemData.url!=null){
        window.location.href=e.itemData.url;
      }
     
    }
 ;
  }

  onItemRendered = async (e: any) => {
    if (e.node && e.node.text && e.node.text.toLowerCase() == "account") {
      var id = document.getElementById("dvAccount");
      if (id == null || id == undefined) {
        var userIcon = await this.userIconImageService.getProfileIconImage(this.userID, this.userNameInitial, 'dp-user-img', 'dp-user-icon')
        e.element.appendChild(userIcon);
      }
    }
  }

  updateNotificationCountPostRead(){
    if(this.notificationCount >0){
      this.notificationCount = this.notificationCount-1;
    }
  }

  async getNotifications() {
    var isMobileDevice = false;
    if (DeviceHelper.isMobileDevice() || DeviceHelper.isTabletDevice()) {
        isMobileDevice = true;
    }

    if(isMobileDevice){
      this.router.navigateByUrl("/notification"+ "?d=TabControl=1");
    }else{
      this.router.navigateByUrl("/notification"+ "?d=" + await this.encrypt.encryptionAES("TabControl=1"));
    } 
  
    //window.location.href = environment.lobasDesktopAppBaseURL + "AngularUI/notification";
  }
  filterListItems(parent: MenuNavigation, items: MenuNavigation[]): MenuNavigation[] {
    var listitems: MenuNavigation[] = [];

    if(parent && parent.items)
      {      
        for (let i = 0; i <parent.items.length ; i++) {
          if (parent.items[i].url.toLowerCase().includes("details"))
            {
                parent.items.splice(i, 1);
                i=i-1;
            }
            else if (parent.items[i].items && parent.items[i].items.length>0){
              parent.items[i].items = this.filterListItems(parent.items[i], parent.items[i].items)
            }
          }
    }
    return parent.items;
  }

}
