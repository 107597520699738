<app-breadcrumb></app-breadcrumb>
<div class="webpart-card m-b-30">
    <div class="webpart-cardbody">
        <div class="webpart-card-header" [ngClass]="!isVisible ? 'webpart-card-closed' : 'webpart-card-open'">
            <div class="webpart-card-header-title">
                <span>About</span>
            </div>
            <div class="webpart-card-header-action">
                <dx-button (onClick)="expandCollaspeComponent()"
                    [icon]="!isVisible ? 'fa-regular fa-angle-up' : 'fa-regular fa-angle-down'"></dx-button>
            </div>
        </div>

        <div class="about_page" [hidden]="!isVisible">
             <p>Download <a href="/assets/privacy-policy/bardel-privacy-policy.pdf" download>Privacy & Cookies</a></p>
             <p>About <a target="_blank" href="https://statrack.co.uk/">statrack</a></p>
             <p>Version LOBAS {{this.version}}</p>
             <p>Environment {{this.environmentName}}</p>
             <p>2007-{{this.currentYear}} {{this.copyRight}}</p>
        </div>
    </div>
</div>
<app-big-button instanceID="Add_To_HomeScreen_BigButton"
(customBigButtonAction)="add_To_HomeScreen()"></app-big-button>