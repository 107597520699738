<div class="row">
    <div class="col-lg-12">
        <ol class="bread_crumb" *ngIf="ShowBreadcrumb">
            <li class="breadcrumb_item">
                <a [routerLink]="['/']"><i class="fa-solid fa-house"></i></a>
            </li>
            <div *ngFor="let x of breadcrumbNode.slice().reverse()">
                <li class="breadcrumb_item"
                    *ngIf="x.name.toLowerCase()!='home' && x.name.toLowerCase()!='profile root menu' && x.url!='' ">
                    <a style="cursor: pointer;" (click)="RedirectToURL(x.url)"><span>{{x.name}}</span></a>
                </li>
                <li class="breadcrumb_item"
                    *ngIf="x.name.toLowerCase()!='home' && x.name.toLowerCase()!='profile root menu' && x.url=='' ">
                    {{x.name}}
                </li>
            </div>
            <li class="breadcrumb_item" *ngIf="IsDetailsPage">
                {{objectRecordDescription}}
            </li>
            <li class="breadcrumb_item" *ngIf="InActiveRecord">
                <i class="fa-regular fa-times-circle" style="color: #db1428 !important" id="IconInActive"></i>
            </li>
            <li class="breadcrumb_item" *ngIf="ActiveRecord">
                <i class="fa-regular fa-check-circle" style="color: #22b93b !important" id="IconActive"></i>
            </li>
            <li class="breadcrumb_item" *ngIf="ReturnUrlEnable">
                <i class="fa-solid fa-share goback_brdcrmb" (click)="RedirectToReturnURL()"></i>
            </li>
        </ol>
    </div>
</div>