import { Injectable } from "@angular/core";
import { Guid } from "guid-typescript";
import { RibbonComponentProperties } from "./RibbonComponentProperties";

@Injectable({
providedIn:'root'
})

export class ObjectCommandModel{
    RecordID:string
    Name:string
    Command: string
    Version: number
    Icon: string
    Definition: VisibilityOptionCollection
    UILayoutID: string
}

export class TransitionModel{
    ParentEnumerationDescription:string
    EnumerationID:Guid
    Value:string
    Description:string
    Color:string
    Icon:string
    DisplayOrder:Number
    TransitionTemplate_EnumID:Guid
    ExtendedAttributes:string 
}

export class VisibilityOption{
    Type:string
    Message: string
    label: string
    icon: string
    Version: number
    RedirectURL: string
    ReturnUrlEnabled: string
}

export class VisibilityOptionCollection{
    HttpType:string
    APIActionEndPoint:string
    APIActionEndPointWithRelationship:string
    IsCustomOperation:boolean
    VisibilityOption:VisibilityOption[]
    WarningPopUpTitle:string
    WarningPopUpIcon:string
    WarningPopUpMessage:string
    WarningPopUpImgSource:string
    PerformValidations:boolean
    APIUpdateActionEndPoint:string
}



export class RibbonControlResponse 
{
    ParentEnumerationDescription:string;
    Commands:Array<ObjectCommandModel>;
    Transitions:Array<TransitionModel>;
    RibbonCtrlProperties: RibbonComponentProperties;    
}