import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHelperService } from '../httpHelper/http-helper.service';
import { AuthService } from '../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs';
import { AlertService } from '../alertService/alert.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentViewerService {

  constructor(private httpClient: HttpClient, private httpHelper: HttpHelperService,private authService: AuthService,private alertService: AlertService) { }

  
  SetDocumentReadCounter(RecordID: string) {
    this.httpClient.put<boolean>(environment.apiUrl + 'api/Document/SetDocumentReadCounter/'+RecordID.toString(),{})
            .subscribe((response) => {
              
              catchError((err: any) => {
                this.alertService.showAlertPopup('Error', err.error.Message, 'fa-solid fa-xmark', 'OK'); throw err
              })
            });
  
  }
  GetDocumentRecord(RecordID: string) :Promise<DocumentModel> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.apiUrl + 'api/Document/Get/' + RecordID)
        .subscribe((response) => {
          resolve(<DocumentModel>response);
        });
    });
  }
  GetRelatedDocument(RecordID: string, relationshipID: string):Promise<DocumentModel[]> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.apiUrl + 'api/Document/GetDocumentByRelationshipIdAndRecordID/'+ relationshipID + '/' + RecordID)
        .subscribe((response) => {
          resolve(<DocumentModel[]>response);
        });
    });
  }
  
   

  GetDetails() {    

  }

}


export class ImagedataSource {
  image: string = '';
  thumbImage: string = '';
  alt: string = '';
  title: string = '';
}



export class DocumentModel {
  public RecordID: string;
  public DocumentType_EnumID: string | null;
  public Path: string;
  public Name: string;
  public FileExtension_EnumID: string;
  public ExtendedAttributes: string;
  public ByteSize: Int32Array;
  public Mime: string;
  public ReadCounter: Int16Array;
  public Version: string;
  public Description: string;
  public IsDefault: boolean | null;
  public IsPublic: boolean;
  public RecordCreatedDate: Date | null;
  public RecordUpdatedDate: Date | null;
  public RecordUpdated_UserID: string | null;
  public RecordCreated_UserID: string | null;
  public CreatedUserIcon: string;
  public UpdatedUserIcon: string;
  public CreatedUsersName: string;
  public UpdatedUsersName: string;
  public RecordTenancyID: string;
  public RecordLookupValue: string;
  public RecordDescription: string;
  public RecordDataObjectStatus_EnumID: string | null;
  public RecordStartDate: Date | null;
  public RecordEndDate: Date | null;
}

export class ImageLocationModel
{
  public DocumentRecordID:string|null;
  public Location:string|null;
  public Message:string|null;
}

export class UploadDocumentModel {
  public RecordID: string;
  public DocumentType_EnumID: string | null;
  public Path: string;
  public Name: string;
  public FileExtension_EnumID: string;
  public ByteSize: Number | null;
  public Mime: string | null;
  public Version: string | null;
  public Description: string |null;
  public IsDefault: boolean | null;
  public IsPublic: boolean | null;
  public RecordDataObjectStatus_EnumID: string | null;
  public RecordStartDate: Date | null;
  public RecordEndDate: Date | null;
  public RelationshipID: string | null;
  public Object1_RecordID: string | null;
  public docTempID: string | null;
  public IsUpload:boolean | null;
  public IsValidDocumentType:boolean|null;
  public IsRecordDataObjectStatus_EnumIDVisible:Boolean|null;
  public ParentRecordID: string | null; 

}