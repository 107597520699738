import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CustomUIFieldModel } from 'src/app/models/CustomUIFieldModel';
import { Items } from 'src/app/models/FieldSettings';
import { environment } from 'src/environments/environment';
import { AlertService } from '../alertService/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ExtendedAttributesComponentProperties } from 'src/app/models/ExtendedAttributesComponentProperties';
import { EnumerationHelperService } from '../enumerationhelper/enumeration-helper.service';
import { LocalStorageService } from '../localStorage/local-storage.service';
import { EnumHelper } from 'src/app/util/enum-helper';
import { EnumerationModel } from 'src/app/models/EnumerationModel';
import CustomStore from 'devextreme/data/custom_store';
import { OfflineDataHelperService } from 'src/app/services/offlineDataHelper/offline-data-helper.service';
import DataSource from 'devextreme/data/data_source';

@Injectable({
  providedIn: 'root'
})
export class ExtendedAttributesService {
  private instanceID: string = '';
  private lookupDataSource: { key: string, values: EnumerationModel[] }[] = [];
  constructor(private httpClient: HttpClient, private alertService: AlertService, private translateService: TranslateService,
    private enumerationHelperService: EnumerationHelperService, 
    private localStorageService: LocalStorageService, private offlineDataHelper: OfflineDataHelperService) { }

  async getComponentProperties(componentID: string, instanceID: string): Promise<ExtendedAttributesComponentProperties> {
    var properties: ExtendedAttributesComponentProperties;
    this.instanceID = instanceID;
    var ComponentUrl = "";
    ComponentUrl = "api/ComponentProperties/GetComponentPropertiesData?componentID=";
    await lastValueFrom(
      this.httpClient.get(environment.apiUrl + ComponentUrl + componentID + "&instanceID=" + instanceID)
    ).then((response: any) => {
      properties = this.setComponentProperties(response.Properties);
    });
    return new Promise<ExtendedAttributesComponentProperties>((resolve, reject) => { resolve(properties) });
  }

  async getObjectFields(objectID: string): Promise<CustomUIFieldModel[]> {
    var uiFields: CustomUIFieldModel[];
    var getUIFieldsURL = "api/UILayoutDefinition/GetExtendedAttributesObjectField/" + objectID;
    await lastValueFrom(
      this.httpClient.get(environment.apiUrl + getUIFieldsURL)
    ).then((response: CustomUIFieldModel[]) => {
      uiFields = response;
    });

    return new Promise<CustomUIFieldModel[]>((resolve, reject) => { resolve(uiFields) });
  }

  setComponentProperties(response): ExtendedAttributesComponentProperties {
    var componentProperties = JSON.parse(response);
    componentProperties.ExtendedAttributesSettings.forEach(componentProperty => {
      switch (componentProperty.key.toLowerCase()) {
        case "title":
          componentProperties.Title = componentProperty.value;
          break;
        case "fieldsettings":
          componentProperties.FieldSettings = JSON.parse(componentProperty.value);
          break;
        case "objectid":
          componentProperties.ObjectID = componentProperty.value;
          break;
        case "objectname":
          componentProperties.ObjectName = componentProperty.value;
          break;
        case "formatalignment":
          componentProperties.FormatAlignment = componentProperty.value;
          break;
        case "hidesavebutton":
          if (componentProperty.value && componentProperty.value.toLowerCase() == "true")
            componentProperties.HideSaveButton = true;
          else
            componentProperties.HideSaveButton = false;
          break;
      }
    });
    return <ExtendedAttributesComponentProperties>componentProperties;
  }

  UpdateExtendedAttributeByObjectIDAndRecordID(recordID: string, objectID: string, extendedAttributes: any, showAlert: boolean = true) {
    var url: string = environment.apiUrl + "api/UILayoutDefinition/UpdateExtendedAttributeByObjectIDAndRecordID/" + recordID + "/" + objectID;
    lastValueFrom(
      this.httpClient.put(url, JSON.stringify(extendedAttributes), {
        responseType: 'text'
      })).then(response => {
        if (showAlert) {
          this.alertService.showAlertPopup(this.translateService.instant('SuccessTitle'), this.translateService.instant('UpdateSuccessMessage'),
            'success', this.translateService.instant('OkButtonText'));
        }

      })
  }

  LoadFormData(objectID: string, recordID: string) {
    var url: string = environment.apiUrl + "api/UILayoutDefinition/GetExtendedAttributeByObjectIDAndRecordID/" + recordID + "/" + objectID;
    return lastValueFrom<any>(this.httpClient.get(url));
  }

  getFormItems(fieldSettings: Items[], extendedAttributesObjectFields: CustomUIFieldModel[], hidesavebutton: boolean = false) {
    var filterdItems: CustomUIFieldModel[] = extendedAttributesObjectFields;
    var enumFields = filterdItems.filter(x => x.OverrideUIType.toLowerCase() === "enumerationcombobox" || x.OverrideUIType.toLowerCase() === "enumeration")
    .map(x => x.Definition.EnumerationTypeDescription);
    if (enumFields && enumFields.length > 0) {
      this.lookupDataField(enumFields);
    }
    if (fieldSettings.length > 0) {
      filterdItems = this.getFillteredItems(fieldSettings, extendedAttributesObjectFields);
    }
    var items = filterdItems.map(data => {
      var editorType = this.setUITypes(data.OverrideUIType);
      var validationRules = this.setValidation(data);
      var editorOption = this.getEditorOption(data);
      return {
        label: { text: data.ExtendedAttributes.FieldUICaption },
        dataField: data.FieldName,
        isRequired: data.ExtendedAttributes.IsRequiredField,
        editorType: editorType,
        validationRules: validationRules,
        editorOptions:editorOption
      }
    });

    return items;
  }

  getEditorOption(data:CustomUIFieldModel) {
    var editorOption = null;
    switch (data.OverrideUIType.toLowerCase()) {
      case 'date':
      case 'datetime':
        editorOption = {  
              pickerType: 'list',  
              type: 'datetime',  
              width: '100%'  
          }  
        break;
      case 'time':
        editorOption = {  
              pickerType: 'list',  
              type: 'time',  
              width: '100%'  
          } 
        break;
      case 'enumeration':
      case 'enumerationcombobox':
          editorOption = {
            dataSource:this.lookupDataSource?.find(item => item.key.toLowerCase() === data.Definition.EnumerationTypeDescription.toLowerCase()).values,
            displayExpr:'Value', 
            valueExpr:'EnumerationId'
          }
        break;
      case 'combobox':
          editorOption = {
            dataSource: 
            new DataSource(this.getDataSource(data.ExtendedAttributes.APIEndpoint)),
            displayExpr:data.ExtendedAttributes.TextFieldName, 
            valueExpr:data.ExtendedAttributes.ValueFieldName
          }
          editorOption.dataSource.load();
        break;
      default:
        break;
    }
    return editorOption;
  }

  private getFillteredItems(Field: any[], unfilteredItems: CustomUIFieldModel[]) {
    var FilteredColumns: CustomUIFieldModel[] = [];
    Field = Field.sort((a, b) => a.VisibleIndex - b.VisibleIndex);
    let filteredColumn;
    Field.forEach(x => {
      if (x.IsVisible) {
        if (x.IsVisible && x.FieldName.toLowerCase() == 'blank') {
          var blankfiledUIlayout = new CustomUIFieldModel;
          blankfiledUIlayout.OverrideUIType = 'empty';
          FilteredColumns.push(blankfiledUIlayout);
        }
        filteredColumn = unfilteredItems.filter(y => y.FieldName.toLowerCase() == x.FieldName.toLowerCase());
        if (x.IsVisible) {
          filteredColumn[0].VisibleIndex = x.VisibleIndex;
          FilteredColumns.push(filteredColumn[0]);
        }
      }
    });
    return FilteredColumns;
  }

  private setUITypes(overrideUIType) {
    var editorType: any;
    switch (overrideUIType.toLowerCase()) {
      case 'datetime':
        editorType = "dxDateBox"
        break;
      case 'date':
        editorType = "dxDateBox"
        break;
      case 'time':
        editorType = "dxDateBox"
        break;
      case 'checkbox':
        editorType = "dxCheckBox"
        break;
      case 'uicolor':
        editorType = 'dxColorBox'
        break;
      case 'intinputbox':
      case 'bigintinputbox':
      case 'decimalinputbox':
      case 'currencyinputbox':
        editorType = 'dxNumberBox'
        break;
      case 'radiobuttontruefalse':
      case 'radiobuttonyesno':
        editorType = 'dxRadioGroup'
        break;
      case 'enumeration':
      case 'enumerationcombobox':
      case 'combobox':
        editorType = 'dxSelectBox'
        break;
      default:
        editorType = "dxTextBox"
        break;
    }
    return editorType;
  }
  private setValidation(field: CustomUIFieldModel) {
    var validationRules = undefined;
    if (!validationRules && field.ExtendedAttributes.IsRequiredField) {
      validationRules = [{ "type": "required", "message": field.ExtendedAttributes.FieldUICaption + " is Required" }]
    }
    else if (field.Definition.ValidationRules && field.Definition.ValidationRules != null) {
      validationRules = JSON.parse(field.Definition.ValidationRules);
    }
    else {
      validationRules = undefined;
    }

    return validationRules;
  }

  private lookupDataField(enumerationFieldTypes: string[]) {
    this.lookupDataSource = <{ key: string, values: EnumerationModel[] }[]>this.localStorageService.
      GetLocalStorage(EnumHelper.LocalStorageKey.enumerationList, [this.instanceID]) ?? [];
    if (this.lookupDataSource.length === 0) {
      this.enumerationHelperService.bulkEnumerationTypeData(enumerationFieldTypes).subscribe((lookupData) => {
        this.lookupDataSource = lookupData;
        this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.enumerationList, lookupData, [this.instanceID]);
      });
    }
  }

  private getDataSource(endpoint:String) {
    var httpClient = this.httpClient;
    var apiURI = environment.apiUrl
    return new CustomStore({
      key: 'RecordID',
      byKey: (key) => {
        return key;
      },
      async load(loadOptions: any) {
        let params: HttpParams = new HttpParams();
        [
          'skip',
          'take',
          'sort',
          'filter',
          'requireTotalCount'
        ].forEach((i) => {
          if (i in loadOptions && loadOptions[i] !== undefined && loadOptions[i] !== null && loadOptions[i] !== '') { params = params.set(i, JSON.stringify(loadOptions[i])); }
        });
          var response = lastValueFrom(httpClient.get(
            apiURI + endpoint, { params }))
            .then((data: any) => (
              {
                data: data.data,
                totalCount: data.totalCount
              }),
              error => { })
            .finally(() => {
            });
          return response;
      }
    },);
  }
}