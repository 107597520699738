
<!-- ***************************This code has been generated using LOBAS Templates 4.0.0.0  -->

<app-breadcrumb [ComponentObjectID]="componentObjectID"></app-breadcrumb>

<div class="row">
    <div class="col-lg-12">
        <div class="row webpartrow">
            <div class="col-lg-6 webpartcol screen-50">
                <div class="m-b-30">
                    <app-grid #testerDetailsViewMode [IsUnauthorizedPage]="false"
                        instanceID="testerDetails"></app-grid>
                </div>
            </div>
            <div class="col-lg-6 webpartcol screen-50">
                <div class="m-b-30">
                    <app-grid #testerDetailsEditMode (gridCustomActions)="gridCustomActions($event)"  [IsUnauthorizedPage]="false"
                        instanceID="testerDetailsEditMode"></app-grid>
                </div>
            </div>
        </div>
    </div>
</div>