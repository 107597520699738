import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DxPopupComponent } from 'devextreme-angular';
import { lastValueFrom } from 'rxjs';
import { GridComponent } from 'src/app/Components/common/grid/grid.component';
import { EncryptionService } from 'src/app/encryption.service';
import { RelationshipRecordModel } from 'src/app/models/RelationshipRecordModel';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { ComponentPropertiesService } from 'src/app/services/componentProperties/component-properties.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { ShiftRequirementService } from 'src/app/services/ShiftRequirement/shift-requirement.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-assignworkitem-popup',
  templateUrl: './assignworkitem-popup.component.html',
  styleUrls: ['./assignworkitem-popup.component.scss']
})
export class AssignworkitemPopupComponent extends PageBaseService {

  @ViewChild("AssignWorkItem", { static: false }) AssignWorkItem: DxPopupComponent;
  @ViewChild("assignworkitemGrid", { static: false }) assignGrid: GridComponent;
  componentID: string = 'grid';
  relationshipID: string = '';
  recordID: string;
  objectid: string
  okAlertBtnTxt: string = "Ok";
  successAlertTitle: string = "Success";
  @Input() instanceID: string;
  @Output() GridRefreshAction = new EventEmitter();


  constructor(public translateService: TranslateService, private shiftRequirementService: ShiftRequirementService,private componentPropertyData: ComponentPropertiesService, private _router: Router, private _enc: EncryptionService, private httpClient: HttpClient, private alertService: AlertService) {
    super(_router, _enc);
  }

  async ngOnInit() {
    this.getComponentProperties();
    this.getAllQueryStrings();
  }

  openPopup() {
    this.assignGrid.dataGrid.instance.refresh();
    this.AssignWorkItem.visible = true;
  }

  closePopup() {
    this.AssignWorkItem.visible = false;
  }

  getComponentProperties() {
    this.componentPropertyData.getComponentPropertiesData(this.componentID, this.instanceID).subscribe((response: any) => {
      if (response.Properties != null) {
        this.setComponentProperties(response.Properties)

      }
    });
  }

  setComponentProperties(componentProperties) {
    var componentProperties = JSON.parse(componentProperties);
    componentProperties.GridProperties.forEach(componentProperty => {
      switch (componentProperty.key.toLowerCase()) {
        case "workitempickerrelationship":
          this.relationshipID = componentProperty.value;
          break;
        case "objectid":
          this.objectid = componentProperty.value;
          break;
      }
    });
  }
  rowClickGrid(e: any) {
    var text = "You want to assign WorkItem?"
    Swal.fire({
      title: "Confirmation",
      text: text,
      iconHtml: "<i class='fa-solid fa-triangle-exclamation'></i>",
      confirmButtonText: "Apply",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      allowOutsideClick : false
    }).then((response: any) => {
      if (response.isConfirmed) {
        if (this.relationshipID == '80167FC6-FA20-4726-AC8D-F8A673F3B3AF') {
          let relationshipModel = new RelationshipRecordModel();
          relationshipModel.Object1_RecordID = this.recordID
          relationshipModel.Object2_RecordID = e.data.RelatedWorkItemID
          relationshipModel.RelationshipID = '80167FC6-FA20-4726-AC8D-F8A673F3B3AF'
          lastValueFrom(
            this.httpClient.post(environment.apiUrl + 'api/RelationshipRecord/insert', JSON.stringify(relationshipModel),
              {
                headers: {
                  'objectID': 'C14BBB2D-4C1F-43B5-A3FB-8333A163F76F'
                },
                responseType: "text"
              }
            )).then(response => {
              this.showSuccessMessage();
              this.closePopup();
              this.GridRefreshAction.emit();
            });
        }
        else if (this.relationshipID.toString().toUpperCase() == 'F953601A-25CD-4C2D-86C6-138306B67ADE') {
          this.shiftRequirementService.InsertShiftRequirementRecordFromJobWorkItemPicker(this.recordID, e.data.RecordID, this.objectid)
            .then(response => {
              this.showSuccessMessage();
              this.closePopup();
              this.GridRefreshAction.emit();
            });
        }
        else {
          this.closePopup();
        }
      }
    });
  }

  getAllQueryStrings() {
    this.GetKeyValueFromQueryString('recordID').then((recordID => {
      this.recordID = recordID;
    }));
  }
  showSuccessMessage() {
    var InsertSuccessMessage: string = "";
    this.translateService.get('InsertSuccessMessage').subscribe((translated: string) => {
      InsertSuccessMessage = translated;
      this.alertService.showAlertPopup(this.successAlertTitle, InsertSuccessMessage, 'fa-solid fa-check', this.okAlertBtnTxt);
    });
  }
}