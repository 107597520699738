<dx-popup [showTitle]="true" (onHiding)="closePopup()" [wrapperAttr]="{class: 'bulk_transition_popup lobas-popup' }"
    [title]="title" #popup [showTitle]="true" [resizeEnabled]="false"
    [dragEnabled]="false" [hideOnOutsideClick]="false" [showCloseButton]="isAllTransitionPerformedStatus!=1">
    <div [hidden]="isAllTransitionPerformedStatus!=0">
        <div class="form-row">
            <div class="field_group">
                <div class="field_label required-label-asterisk">
                    From
                </div>
                <div class="field_control">
                    <dx-select-box #fromEnumeration itemTemplate="selectBoxTemplate"
                        [dataSource]="fromEnumerationDataSource" displayExpr="Description" valueExpr="EnumerationId"
                        [inputAttr]="{ 'aria-label': 'From' }" [searchMode]="searchModeOption"
                        [searchExpr]="searchExprOption" [searchTimeout]="searchTimeoutOption"
                        [minSearchLength]="minSearchLengthOption" [searchEnabled]="true"
                        (onSelectionChanged)="onFromSelectionChanged($event)"
                        [showDataBeforeSearch]="showDataBeforeSearchOption">

                        <div class="list_box_outer" *dxTemplate="let item of 'selectBoxTemplate'">
                            <div id="item.EnumerationId">
                                {{item?.Description}}
                            </div>
                        </div>
                    </dx-select-box>
                </div>
            </div>

            <div class="field_group">
                <div class="field_label required-label-asterisk">To</div>
                <div class="field_control">
                    <dx-select-box itemTemplate="selectBoxTemplate" [disabled]="isToEnumerationCtrlDisabled"
                        #toEnumeration [dataSource]="toEnumerationDataSource" displayExpr="Description"
                        valueExpr="EnumerationID" [inputAttr]="{ 'aria-label': 'To' }" [searchMode]="searchModeOption"
                        [searchExpr]="searchExprOption" [searchTimeout]="searchTimeoutOption"
                        [minSearchLength]="minSearchLengthOption" [searchEnabled]="true"
                        [showDataBeforeSearch]="showDataBeforeSearchOption">
                        <div class="list_box_outer" *dxTemplate="let item of 'selectBoxTemplate'">
                            <div id="item.EnumerationID"></div>
                            {{item?.Description}}
                        </div>
                    </dx-select-box>
                </div>
            </div>
        </div>

        <div class="form-btn-outer">
            <dx-button text="Apply" (onClick)="applyAction()">
            </dx-button>
        </div>
    </div>

    <div #messageBox [hidden]="isAllTransitionPerformedStatus==0">
        <p class="bulk_transition_message">{{successCount}}/{{totalCount}} record(s) changed from {{fromEnumerationDescription}} to {{toEnumerationDescription}}.</p>
        <p class="bulk_transition_message" [hidden]="failedCount==0">Update failed for {{failedCount}} record(s).</p>
        <div class="form-btn-outer">
            <dx-button #changeStatusButton text="Change Status" (onClick)="changeStatusClick()">
            </dx-button>
            <dx-button #doneButton text="Done" (onClick)="closePopup()">
            </dx-button>
        </div>
    </div>
</dx-popup>