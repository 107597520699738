import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/encryption.service';
import { CookieService } from 'ngx-cookie-service';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { Guid } from 'guid-typescript';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { DocumentAssestsViewerPopupComponent } from '../common/document-assests-viewer-popup/document-assests-viewer-popup.component';
import { DocumentUploadPopupComponent } from '../common/document-upload-popup/document-upload-popup.component';
import { GridComponent } from '../common/grid/grid.component';
import { MapComponent } from '../common/map/map.component';
import { RecordRelationshipBindingToolComponent } from '../common/record-relationship-binding-tool/record-relationship-binding-tool.component';
import { ObjectCommandModel } from 'src/app/models/ObjectCommandModel';
import { elementAt, Subscription } from 'rxjs';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { AddressPopupComponent } from '../common/address-popup/address-popup.component';
import { AddressService } from 'src/app/services/address/address.service';
@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss']
})
export class CompanyDetailsComponent extends PageBaseService implements OnInit {
  ComponentObjectID: string;
  loggedinUserID: string = Guid.EMPTY;
  parameterSet: Boolean = false;
  recordID: string = Guid.EMPTY;
  relationshipRecordID: string = Guid.EMPTY;
  newID: string = Guid.EMPTY;
  showMapControl: boolean = true;
  ribbonSubscription: Subscription;
  performRibbonCommandOperation: Subscription;
  okAlertBtnTxt: string = this.translateService.instant('OkButtonText');
  successAlertTitle: string = this.translateService.instant('SuccessTitle');
  @ViewChild("companyDocViewPopup", { static: false }) docViewPopup: DocumentAssestsViewerPopupComponent
  @ViewChild("companyDocUploadPopup", { static: false }) UploadPopup: DocumentUploadPopupComponent
  @ViewChild("grdCompanyDocument", { static: false }) gridCompanyDocument: GridComponent;
  @ViewChild("companyDetailMap", { static: false }) companyDetailMap: MapComponent;
  @ViewChild("recordrelationshipbindingtool", { static: false }) recordrelationshipcomponent: RecordRelationshipBindingToolComponent;
  @ViewChild("Contactsgrid", { static: false }) gridContacts: GridComponent;
  @ViewChild("grdCompanyDetails", { static: false }) gridCompanyDetails: GridComponent;
  @ViewChild("CompanyDetails_AddressesList", { static: false }) CompanyDetails_AddressesList: GridComponent
  @ViewChild("CompanyDetails_AddressPopUp", { static: false }) CompanyDetails_AddressesPopup: AddressPopupComponent
  tab1Index: number = 0;

  constructor(private addressservice: AddressService,private localStorageService: LocalStorageService,private alertService: AlertService, private translateService: TranslateService, private auth: AuthService, private eventQueue: LobasEventQueueService, private _router: Router, private _enc: EncryptionService) {
    super(_router, _enc);
    this.ComponentObjectID = '4A44F112-F49F-4419-9897-90DD3132B90A';
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
    this.loggedinUserID = this.auth.getGuidUserID() ?? '';
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
    this.eventQueue.on(AppEventType.ActionUpdateSuccess).subscribe(event => this.RefreshOtherControls(event.payload));
  }
  RefreshOtherControls(payload: any): void {
    this.CompanyDetails_AddressesList.dataGrid.instance.refresh();
  }
  async ngOnInit() {
    this.tab1Index = this.GetTabIndex("tab1Index",this.localStorageService);
    if (this.tab1Index ==0) {
      this.showMapControl = true;
    }
    else {
      this.showMapControl = false;
    }
    this.getAllQueryStrings();
    this.ribbonSubscription =  this.eventQueue.on(AppEventType.RibbonTransitionClick).
      subscribe(event => this.onTransitionBtnClick(event.payload));

    this.performRibbonCommandOperation = this.eventQueue.on(AppEventType.PerformRibbonCommandOperation).
      subscribe(event => this.onRibbonCommandClick(event.payload));
  }
  onTab1ItemClick(ev: any) 
  {    
    this.SetTabIndex("tab1Index",ev?.itemIndex,this.localStorageService);
  }
  getAllQueryStrings() {
    this.GetKeyValueFromQueryString('recordID').then((recordID => {
      this.recordID = recordID;
      this.GetKeyValueFromQueryString('relationshipRecordID').then((relationshipRecordID => {
        this.relationshipRecordID = relationshipRecordID;
        this.GetKeyValueFromQueryString('newID').then((newID => {
          this.newID = newID;
          this.parameterSet = true;
        }))
      }));
    }));
  }
  onItemClick(ev: any) {
    if (ev?.itemData?.title?.toLowerCase() == "details") {
      this.showMapControl = true;
    }
    else {
      this.showMapControl = false;
    }
  }
  gridCustomActions(data: any) {
    switch (data.CommandObject.Command.toLowerCase()) {
      case "cancel":
        case "cancelall":
          try {
            var redirectURL = data.CommandObject.Definition?.VisibilityOption[0]?.RedirectURL ?? "";
            if (redirectURL.length === 0) {
              redirectURL = this._router.getCurrentNavigation().previousNavigation.finalUrl.toString();
            }
            this._router.navigateByUrl(redirectURL.toLowerCase());
          } catch (Ex) {
            this._router.navigateByUrl('app-home');
          }
          break;
      case "assignrelationshipdata":
        this.recordrelationshipcomponent.openPopup();
        break;
      default:
        this.translateService.get('CustomActionYetToImplemented', { title: data.CommandObject.Name }).subscribe((text: string) => {
          this.alertService.showAlertPopup(this.translateService.instant('InfoTitle'), text,
            'info', this.translateService.instant('OkButtonText'));
        });
        break;
    }
  }
  RelationshipRecordActions(refreshGrid: boolean) {
    if (refreshGrid) {
      this.gridContacts.refreshGrid(false);
    }
  }
  async validateChildControls() {
    var isValid: Boolean = false;
    return new Promise<Boolean>(async (resolve, reject) => {
      isValid = await this.gridCompanyDetails.isValidFormData();
      resolve(isValid);
      reject(false);
    });
  }
  async onRibbonCommandClick(command: ObjectCommandModel) {
    var isValid: Boolean = true;
    if (command.Definition.PerformValidations) {
      isValid = await this.validateChildControls();
    }
    if (isValid) {
      this.companyDetailMap.onSaveAllClicked().then(response => {
        this.gridCompanyDetails.commandAction(command).then(response => {
          if (response === true) {
            this.alertService.showAlertPopup(this.successAlertTitle,
              this.translateService.instant('UpdateSuccessMessage'), 'fa-solid fa-check',
              this.okAlertBtnTxt);
          }
        });
      });
    }
  }

  async onTransitionBtnClick(data: any) {
    var isValid = await this.validateChildControls();
    if (isValid) {
      this.companyDetailMap.onSaveAllClicked().then(response => {
        this.gridCompanyDetails.onRibbonTransitionClick(data).then(response => {
          if (response === true) {
              this.alertService.showAlertPopup(this.successAlertTitle,
              this.translateService.instant('UpdateSuccessMessage'), 'fa-solid fa-check',
              this.okAlertBtnTxt);
          }
        });
      });
    }
  }

  addressGridCustomActions(data: any) {
    switch (data.CommandObject.Command.toLowerCase()) {
      case "addnewaddress":
        this.CompanyDetails_AddressesPopup.OpenPopup(Guid.EMPTY,"newpopup");
      break;
      case "editpopup":
        this.CompanyDetails_AddressesPopup.OpenPopup(data.SelectedRecords[0],"editpopup");
      break;  
      case "setaddressasdefault":
        data.SelectedRecords.forEach(element => {    
          const index = data.SelectedRecords.indexOf(element);
          if(index === data.SelectedRecords.length - 1) 
          {
            this.addressservice.SetDefaultAddress(element,this.eventQueue,true,true);
          }
          else{
            this.addressservice.SetDefaultAddress(element,this.eventQueue,false);
          }
          
        });
      break;
      default:
        this.translateService.get('CustomActionYetToImplemented', { title: data.CommandObject.Name }).subscribe((text: string) => {
          this.alertService.showAlertPopup(this.translateService.instant('InfoTitle'), text,
            'fa-solid fa-circle-info', this.translateService.instant('OkButtonText'));
        });
        break;
    }
  }
  DocumentgridCustomActions(data: any) {
    if (data != undefined && data != null) {
      switch (data.CommandObject.Command.toLowerCase()) {
        case "view":
        case "viewdocument":
          this.docViewPopup.showDocumentAssetViewerPopup(true, data.SelectedRecords[0], "viewDocument", null);
          break;
        case "uploadpopup":
          if (data.CommandObject.Name.toUpperCase() == "EDIT") {
            this.UploadPopup.OpenUploadPopup(data.SelectedRecords[0], "editpopup", this.gridCompanyDocument, this.companyDetailMap);
          }
          else {
            this.UploadPopup.OpenUploadPopup(null, "newpopup", this.gridCompanyDocument, this.companyDetailMap);
          }
          break;
        case "deleteupload":
          var RelationshipID = "";
          if (this.gridCompanyDocument.gridProperties != null && this.gridCompanyDocument.gridProperties != undefined) {
            RelationshipID = this.gridCompanyDocument.gridProperties.RelationshipID;
          }
          this.UploadPopup.DeleteUploadDocument(data.SelectedRecords[0], RelationshipID, this.gridCompanyDocument);
          break;
        case "setpinfromimage":
          this.UploadPopup.SetImageLocation(data.SelectedRecords[0], this.companyDetailMap);
          break;
        case "downloaddocument":
          this.UploadPopup.downloadFile(data.SelectedRecords[0]);
          break;
      }
    }

  }
}


