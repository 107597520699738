
<!-- ***************************This code has been generated using LOBAS Templates 4.0.0.0  --> 

<app-breadcrumb></app-breadcrumb>

<div class="row webpartrow">
    <div class="col-lg-12">
        <div class="m-b-30">
            <app-grid  (gridCustomActions)="gridCustomActions($event)" [IsUnauthorizedPage]="false" instanceID="documentcontactGrid"></app-grid>
        </div>        
    </div>
</div>