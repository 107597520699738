<app-breadcrumb></app-breadcrumb>
<div class="row webpartrow">
    <dx-tab-panel  [(selectedIndex)]="tab1Index" (onTitleClick)="onTab1ItemClick($event)" [animationEnabled]="false" [elementAttr]="{ class: 'custom_tabpanel' }">
            <dxi-item [visible]="isactiveTabVisible"  title="{{'active'|translate}}">
                <app-grid  instanceID="activeCompanyGrid"  (isGridHidden)="hideActiveTabVisible($event)" [IsUnauthorizedPage]="false"></app-grid>    
            </dxi-item>
            <dxi-item [visible]="isinactiveTabVisible" title="{{'inActive'|translate}}">
                <app-grid instanceID="inactiveCompanyGrid" (isGridHidden)="hideInActiveTabVisible($event)"  [IsUnauthorizedPage]="false" ></app-grid>
            </dxi-item>
            <dxi-item [visible]="isdeletedTabVisible" title="{{'deleted'|translate}}">
                <app-grid instanceID="deletedCompanyGrid" (isGridHidden)="hideDeletedTabVisible($event)" [IsUnauthorizedPage]="false"></app-grid>
            </dxi-item>
        </dx-tab-panel>
</div>