import { Injectable } from "@angular/core";
import { GridComponent } from "../Components/common/grid/grid.component";
@Injectable({
    providedIn: 'root',
})

export class TabControlComponentProperties {
    IsHeaderVisible: boolean = false
    Title: string = ''
    TabList: Tab[] = [];
}

export class Tab{
    title:string;
    AllowedRole:string[];
    SubComponentType:string;
    Inputs:any;
    DisplayIndex:number;
    Component:any;
}

