
//***************************This code has been generated using LOBAS Templates 4.0.23.0  

import { Component, ViewChild } from '@angular/core';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { GridComponent } from 'src/app/Components/common/grid/grid.component';

@Component({
  selector: 'app-fatiguemetricresults-detail',
  templateUrl: './fatiguemetricresults-detail.component.html',
  styleUrls: ['./fatiguemetricresults-detail.component.scss']
})
export class fatiguemetricresultsdetailcomponent {
  componentObjectID: string;
  
  constructor(private eventQueue: LobasEventQueueService) {
    this.componentObjectID = "565aaa68-48bd-4dff-ae72-b6d61a095a2b";
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }
  gridCustomActions(data: any) {
    if (data != undefined && data != null) {
      switch (data.CommandObject.Command.toLowerCase()) {
        case "addCustomCasesHere":
              //Logical code.
          break;
      }
    }
  }
}