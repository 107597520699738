
import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { HttpHelperService } from '../httpHelper/http-helper.service';
import { environment } from 'src/environments/environment';
import { DxFormComponent } from 'devextreme-angular';

export class ContactDetails {
  RecordID: string;
  UserName: string;
  Path: string | null
  Mime: string | null;
  EmailAddress: string | null
  MobileNumber: string | null;
  SentinelNumber: string | null;
}
export class AddressTypes {
  AddressType: string;
  Value: string | null;
}

@Injectable({
  providedIn: 'root'
})
@Injectable({
  providedIn: 'root'
})
export class ContactDetailsService {
  constructor(private httpClient: HttpClient, private httpHelper: HttpHelperService) { }
  getContactDetails(contactId: string): Promise<ContactDetails> {
    return new Promise<ContactDetails>((resolve) => {
      this.httpClient.get(environment.apiUrl + "api/Contact/GetContactDetails/" + contactId)
        .subscribe((response) => {
          resolve(<ContactDetails>response);
        })
    });
  }
  getComponentProperties(url: string): Promise<any> {
    return new Promise<any>((resolve) => {
      this.httpClient.get(environment.apiUrl + url)
        .subscribe((response) => {
          resolve(<any>response);
        })
    });
  }

  getAddresses(recordId: string,RelationshipID :string ,addressTypes:string): Promise<AddressTypes[]> {
    return new Promise<AddressTypes[]>((resolve) => {
      this.httpClient.get(environment.apiUrl + "api/Address/GetAddressesByAddressTypes/" + recordId+"/"+ RelationshipID+"/"+addressTypes)
        .subscribe((response) => {
          resolve(<AddressTypes[]>response);
        })
    });
  }
  postAddresses(recordId: string,relationshipID:string,addressTypes:AddressTypes[]): Promise<any> {
    return new Promise<string>((resolve) => {
      this.httpClient.post(environment.apiUrl + "api/Address/UpdateAddressTypeEnumerationValue/" + recordId+"/"+relationshipID,addressTypes
        ,{ 
          responseType: "text"
        }
      )
        .subscribe((response) => {
          resolve(<any>response);
        })
    });
  }


}