<dx-popup [showTitle]="true" title="Worker Selection"
    [wrapperAttr]="{class: 'ribcmd_popup lobas-popup lobas-form-popup' }"
    #distanceCalculationEngine [showTitle]="true" [resizeEnabled]="false" [dragEnabled]="false" [hideOnOutsideClick]="false"
    [showCloseButton]="true">
    <div class="form-row">
        <div class="field_group">
            <div class="field_control">
                <div class="dx-field">
                    <div class="worker_section">
                        <div class="m-b-30">
                            <app-distance-calculation-engine-header></app-distance-calculation-engine-header>
                        </div>
                        <app-grid #distanceCalculationGrid instanceID="distanceCalculationGrid" groupCellTemplate="groupCellTemplate" 
                        [recordID]="this.jobID" ></app-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-btn-outer">
        <dx-button text="Cancel" (onClick)="closePopup()">
        </dx-button>
    </div>
</dx-popup>
