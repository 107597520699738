<app-breadcrumb></app-breadcrumb>
<div class="row webpartrow">
    <dx-tab-panel [(selectedIndex)]="tab1Index" (onTitleClick)="onTab1ItemClick($event)" [animationEnabled]="false" [elementAttr]="{ class: 'custom_tabpanel' }">
            <dxi-item [visible]="isactiveTradeTabVisible"  title="{{'active'|translate}}">
                <app-grid  instanceID="activeTradeGrid"   (isGridHidden)="hideActiveTradeTabVisible($event)" [IsUnauthorizedPage]="false"></app-grid>    
            </dxi-item>
            <dxi-item [visible]="isinactiveTradeTabVisible" title="{{'inActive'|translate}}">
                <app-grid instanceID="inactiveTradeGrid" (isGridHidden)="hideInActiveTradeTabVisible($event)"  [IsUnauthorizedPage]="false" ></app-grid>
            </dxi-item>
            <dxi-item [visible]="isdeletedTradeTabVisible" title="{{'deleted'|translate}}">
                <app-grid instanceID="deletedTradeGrid" (isGridHidden)="hideDeletedTradeTabVisible($event)" [IsUnauthorizedPage]="false"></app-grid>
            </dxi-item>
        </dx-tab-panel>
</div>