<app-breadcrumb [ComponentObjectID]="ComponentObjectID"></app-breadcrumb>
<div class="row webpartrow">
    <div class="col-lg-6 webpartcol screen-50 m-b-30">
        <app-grid [IsUnauthorizedPage]="false" instanceID="tradeDetails" (gridCustomActions)="gridCustomActions($event)"></app-grid>
    </div>
    <div class="col-lg-6 webpartcol screen-50 m-b-30">
        <app-grid [IsUnauthorizedPage]="false" #grdTradeDetails_Worker instanceID="TradeDetails_Worker" (gridCustomActions)="gridCustomActions($event)" [isRowClick]="true" (rowClick)="rowClickGrid($event)"></app-grid>
    </div>
    <app-record-relationship-binding-tool #recordrelationshipbindingtool instanceID="ContactRelationshipBindingTool"
      ddlGridInstanceID="ContactRelationshipBindingToolDDLGrid" [recordObjectId]="ComponentObjectID"(RelationshipRecordActions)="RelationshipRecordActions($event)">
    </app-record-relationship-binding-tool>
</div>


