import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/encryption.service';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';

@Component({
  selector: 'app-time-series',
  templateUrl: './time-series.component.html',
  styleUrls: ['./time-series.component.scss']
})
export class TimeSeriesComponent  extends PageBaseService{
  constructor(private eventQueue: LobasEventQueueService,private localStorageService: LocalStorageService,private _router: Router, private _enc: EncryptionService){
    super(_router, _enc);
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }
  isactiveTimeSeriesTabVisible: boolean =true;
  isinactiveTimeSeriesTabVisible: boolean=true;
  isdeletedTimeSeriesTabVisible: boolean =true;
  tab1Index:number=0;
  async ngOnInit() 
  {
    this.tab1Index = this.GetTabIndex("tab1Index",this.localStorageService);
  }
    
  onTab1ItemClick(ev: any) 
  {    
    this.SetTabIndex("tab1Index",ev?.itemIndex,this.localStorageService);
  }

  hideActiveTimeSeriesTabVisible(isGridHidden) {
    this.isactiveTimeSeriesTabVisible = !isGridHidden;
  }
  
  hideInActiveTimeSeriesTabVisible(isGridHidden) {
    this.isinactiveTimeSeriesTabVisible = !isGridHidden;
  } 
  
  hideDeletedTimeSeriesTabVisible(isGridHidden) {
    this.isdeletedTimeSeriesTabVisible = !isGridHidden;
  }
}
