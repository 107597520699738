
//***************************This code has been generated using LOBAS Templates 4.0.0.0  

import { Component, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ComponentInfo } from 'src/app/models/ComponentInfo';
import { AppEvent } from 'src/app/models/AppEvent';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { AppEventType } from 'src/app/enums/appEventType';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { Column, Row, dxDataGridColumn } from 'devextreme/ui/data_grid';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/encryption.service';
import { GridComponent } from 'src/app/Components/common/grid/grid.component'; 
import { environment } from 'src/environments/environment';
import { EnumHelper } from 'src/app/util/enum-helper';
import { UserIconImageService } from 'src/app/services/userIconImage/user-icon-image.service';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { DateTimeHelperService } from 'src/app/services/datetimehelper/date-time-helper.service';
import { Guid } from 'guid-typescript';
import { OfflineDataHelperService } from 'src/app/services/offlineDataHelper/offline-data-helper.service';

@Component({
  selector: 'app-contact-grid',
  templateUrl: './contact-grid.component.html',
  styleUrls: ['./contact-grid.component.scss']
})
export class contactgridcomponent extends PageBaseService  {
  componentObjectID: string;

  isActiveTabVisible: boolean = true;
  isInactiveTabVisible: boolean = true;
  ComponentObjectID: string;
  activeGrid: GridComponent = null;
  tab1Index:number=0;
  @ViewChild("grdActivePeople", { static: true }) grdActivePeople!: GridComponent

  constructor(private dateTimeHelperService :DateTimeHelperService,private location: Location, private eventQueue: LobasEventQueueService,
    private alertService: AlertService, private translateService: TranslateService,
    private _router: Router, private _enc: EncryptionService, private userIconImageService: UserIconImageService, private localStorageService: LocalStorageService, private offlineDataHelper: OfflineDataHelperService) {
    super(_router, _enc);
    this.ComponentObjectID = '39245297-F727-40D4-9701-F284A0C7219A';
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));

  }
  goBackToPrevPage() {
    this.location.back();
  }

  formatData(data: string, dataType: String): String {
    return this.dateTimeHelperService.FormatData(data, dataType);
  }
  gridCustomActions(data: any) {
    switch (data.CommandObject.Command.toLowerCase()) {
      case "newcontact":
      this.GetKeyValueFromQueryString('recordID').then((async recordID => {
        var queryStringParameters = 'newID=' + Guid.create();
        if (data.CommandObject.Definition.VisibilityOption[0].ReturnUrlEnabled && data.CommandObject.Definition.VisibilityOption[0].ReturnUrlEnabled.toLowerCase() === "true") {
          queryStringParameters = queryStringParameters + "&" + "ReturnUrlEnabled=true" + "&" + "ReturnUrl=" + await this.GetDecryptedUrlForReturnUrl(window.location.href);
        }
        if(this.offlineDataHelper.isOnline){
          this.RedirectToEncryptUrl('people-details?' + queryStringParameters)
        }else{
          this._router.navigateByUrl('people-details?' + queryStringParameters);
        }
      }))
      break;
      default:
    this.translateService.get('CustomActionYetToImplemented', { title: data.CommandObject.Name }).subscribe((text: string) => {

      var InfoTitle:string="";
      var OkButtonText:string="";
      this.translateService.get('InfoTitle').subscribe((translated: string) => {
        InfoTitle = translated;
      });
      this.translateService.get('OkButtonText').subscribe((translated: string) => {
        OkButtonText = translated;
      });

      if(data.CommandObject.Command.toLowerCase()=="sendinvite" || data.CommandObject.Command.toLowerCase()=="resendverificationemail")
        {this.alertService.showAlertPopup(InfoTitle, "This feature is unavailable from this location.  Please navigate to the contacts detail legacy UI page to perform this operation.",
          'fa-solid fa-circle-info', OkButtonText);}
      else{
        this.alertService.showAlertPopup(InfoTitle, text,'fa-solid fa-circle-info', OkButtonText);
      }
      
    });
    
  }
}
  async ngOnInit(): Promise<void> {
    this.activeGrid = this.grdActivePeople;
    this.tab1Index = this.GetTabIndex("tab1Index",this.localStorageService);
  }

  onTab1ItemClick(ev: any) {    
    this.SetTabIndex("tab1Index",ev?.itemIndex,this.localStorageService);
    }
  
  goToDetail(item,rowClickOnlyAction) {
    if(rowClickOnlyAction){
      let data = item.data;
      let navigateURL = rowClickOnlyAction.Definition.VisibilityOption[0].RedirectURL
      var queryStringParameters = 'RecordID=' + data.RecordID;
      if (data.RelationshipRecordID) {
        queryStringParameters = queryStringParameters + '?relationshipRecordID=' + data.RelationshipRecordID;
      }
      this.navigateTo(navigateURL, queryStringParameters);
    }
  } 

  public rowClickActiveGrid(ev: any) {
    let data = ev.data;
    let navigateURL = ev.navigateURL;
    var queryStringParameters = 'RecordID=' + data.RecordID;
    if (data.RelationshipRecordID) {
      queryStringParameters = queryStringParameters + '?relationshipRecordID=' + data.RelationshipRecordID;
    }
    this.navigateTo(navigateURL, queryStringParameters);
  } 
  userImageDict: Map<string, string> = new Map<string, string>();

  getInitials(userName: string): String {
    return this.userIconImageService.getInitialsString(userName);
  }

  isUserImageAvailable(userID): boolean {
    return this.userIconImageService.isUserImageAvailable(userID);
  }


  getUserImage(userID: string, userName: string): String {
    if (this.userIconImageService.isUserImageAvailable(userID)) {
      return this.userIconImageService.getProfileIconImageURL(userID);
    }
    else {
      return null;
    }
  }
}