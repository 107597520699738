import { Component } from '@angular/core';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';

@Component({
  selector: 'app-address-details',
  templateUrl: './address-details.component.html',
  styleUrls: ['./address-details.component.scss']
})
export class AddressDetailsComponent {
  isVisible: Boolean = false;
  expandCollaspeComponent() {
    this.isVisible = !this.isVisible;
  }
  constructor(private eventQueue:LobasEventQueueService){
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }
}
