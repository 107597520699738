import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/encryption.service';
import { ComponentInfo } from 'src/app/models/ComponentInfo';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { DeviceHelper } from 'src/app/util/device-Helper';
import { Guid } from 'guid-typescript';


@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss']
})
export class JobComponent  extends PageBaseService {
  ComponentObjectID: string;
  myJobGrid= new ComponentInfo();
  allJobGrid= new ComponentInfo();
  inactiveJobGrid= new ComponentInfo();
  deletedJobGrid= new ComponentInfo();
  tab1Index:number=0;
  isMyJobTabVisible: boolean =false;
  isAllJobTabVisible: boolean=false;
  isInactiveJobTabVisible: boolean=false;
  isDeletedJobTabVisible: boolean =false;
  constructor(private localStorageService: LocalStorageService,private location: Location, private eventQueue: LobasEventQueueService,private alertService: AlertService,private _router: Router, private _enc: EncryptionService) {
    super(_router, _enc);
    this.ComponentObjectID = '5EF72874-C6A0-4B0B-8FA1-B69335C783E3';
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }
  gridCustomActions(data: any) {
    switch (data.CommandObject.Command.toLowerCase()) {
      case "gotoplanner":
      case  "updatejob":
        this.AddQuerystringParameterAndnavigateWithDecryptedUrl('job-planner', 'RecordID=' + data.SelectedRecords[0]);
        break;
      case "insertjob":
        this.AddQuerystringParameterAndnavigateWithDecryptedUrl('job-planner', 'newID=' + Guid.create());
         break;
      default:
        this.alertService.showAlertPopup("Info", "This " + data.CommandObject.Name + " is custom action. Yet to be implemented.", "fa-solid fa-circle-info", "OK");
        break;
    }
    
  }
  onTab1ItemClick(ev: any) {    
    this.SetTabIndex("tab1Index",ev?.itemIndex,this.localStorageService);
  }
  async ngOnInit()
  {
    var tabNumber = await this.GetKeyValueFromQueryString("TabControl");
    if (tabNumber) {
      this.tab1Index = Number(tabNumber) - 1;
      this.SetTabIndex("tab1Index", this.tab1Index, this.localStorageService);
    }
    else {
      this.tab1Index = this.GetTabIndex("tab1Index", this.localStorageService);
      this.SetTabIndex("tab1Index", this.tab1Index, this.localStorageService);
    }  
  }

  hideMyJobTab(isGridHidden) {
    this.isMyJobTabVisible = !isGridHidden;
  }
  
  hideAllJobTab(isGridHidden) {
    this.isAllJobTabVisible = !isGridHidden;
  } 

  hideInactiveJobTab(isGridHidden) {
    this.isInactiveJobTabVisible = !isGridHidden;
  } 
  
  hideDeletedJobTab(isGridHidden) {
    this.isDeletedJobTabVisible = !isGridHidden;
  }



  public rowClick(ev: any) {
    let data = ev.data;
    let navigateURL = ev.navigateURL;
    var isMobileDevice = false;
    if (DeviceHelper.isMobileDevice() || DeviceHelper.isTabletDevice()) {
        isMobileDevice = true;
    }
    var queryStringParameters="";
    if(isMobileDevice){
      queryStringParameters = '&RecordID=' + data.RecordID;
    }
    else{
      queryStringParameters = 'RecordID=' + data.RecordID;
    }
    
    if (data.ShiftReportRecordID) {
      queryStringParameters = queryStringParameters + '&ShiftReportRecordID=' + data.ShiftReportRecordID;
    }
    this.AddQuerystringParameterAndnavigateWithDecryptedUrl(navigateURL, queryStringParameters);
  } 
}