
//***************************This code has been generated using LOBAS Templates 4.0.0.0  

import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ComponentInfo } from 'src/app/models/ComponentInfo';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/encryption.service';
import { Guid } from 'guid-typescript';
import { BigButtonComponent } from 'src/app/Components/common/big-button/big-button.component'; 
import { CookieService } from 'ngx-cookie-service';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { AuthService } from 'src/app/services/auth/auth.service';
import { QuestionnaireResponseViewerComponent } from 'src/app/Components/questionnaire-response-viewer/questionnaire-response-viewer.component'; 
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { RecordRelationshipBindingComponentProperties } from 'src/app/models/RecordRelationshipBindingComponentProperties';
import { RecordRelationshipBindingToolComponent } from 'src/app/Components/common/record-relationship-binding-tool/record-relationship-binding-tool.component'; 
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { GridComponent } from 'src/app/Components/common/grid/grid.component';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { AddressPopupComponent } from 'src/app/Components/common/address-popup/address-popup.component';
import { AddressService } from 'src/app/services/address/address.service';
import { EnumHelper } from 'src/app/util/enum-helper';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.scss']
})
export class contactdetailcomponent extends PageBaseService implements OnInit{
  componentObjectID: string;
  gridInfo1 = new ComponentInfo();
  recordID: string = Guid.EMPTY;
  relationshipRecordID: string = Guid.EMPTY;
  newID: string = Guid.EMPTY;
  bigbuttonRedirectUrl: string;
  showMapControl: boolean = true;
  parameterSet: Boolean = false;
  loggedinUserID: string = Guid.EMPTY;
  isSetUrl: boolean = false;
  isInitComplete: boolean=false;
  tab1Index:number=0;
  hideTabByUserRole: boolean = true;

  @ViewChild("questionnaireResponseViewer", { static: false }) questionnaireResponseViewer: QuestionnaireResponseViewerComponent
  @ViewChild("recordrelationshipbindingtool", { static: false }) recordrelationshipcomponent: RecordRelationshipBindingToolComponent
  @ViewChild("contactDetails_Trade", { static: false }) grdcontactDetails_Trade: GridComponent
  @ViewChild("ContactDetails_DistributedDocuments", { static: false }) ContactDetails_DistributedDocuments: GridComponent
  @ViewChild("peopleDetails_AddressesList", { static: false }) peopleDetails_AddressesList: GridComponent
  @ViewChild("peopleDetails_AddressesPopup", { static: false }) peopleDetails_AddressesPopup: AddressPopupComponent
  


  constructor(private addressservice: AddressService,private localStorageService: LocalStorageService, private httpClient: HttpClient, private alertService: AlertService, private notificationService: NotificationService, private translateService: TranslateService, private auth: AuthService, private eventQueue: LobasEventQueueService, private location: Location, private _router: Router, private _enc: EncryptionService, private cookieService: CookieService) {
    super(_router, _enc);
    this.componentObjectID = '39245297-F727-40D4-9701-F284A0C7219A';
    this.contactDetailsPageURL().then((response) => {
      this.bigbuttonRedirectUrl = response;
      this.isSetUrl = true;
    });
    this.loggedinUserID = this.auth.getGuidUserID() ?? '';
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
    this.eventQueue.on(AppEventType.ActionUpdateSuccess).subscribe(event => this.RefreshOtherControls(event.payload));

  }
  RefreshOtherControls(payload: any): void {
    this.peopleDetails_AddressesList.dataGrid.instance.refresh();
  }
  async contactDetailsPageURL() {
    var recordID = await this.GetKeyValueFromQueryString('RecordID');
    if (recordID === null || recordID === '') {
      recordID = this.cookieService.get("UserID")
    }
    return "/Lobas.Web/Pages/Contact/ContactDetails.aspx?RecordID=" + recordID;
  }
  async ngOnInit() {
    this.tab1Index = this.GetTabIndex("tab1Index",this.localStorageService);
    var userRoles = <string[]>JSON.parse(this.localStorageService.GetLocal(EnumHelper.LocalStorageKey.userRoles));
    if (userRoles.length == 1 && userRoles[0].toLowerCase() === 'user') {
      this.hideTabByUserRole = false;
    }
    this.getAllQueryStrings();
    this.isInitComplete = true;

    if (this.tab1Index ==0) {
      this.showMapControl = true;
    }
    else {
      this.showMapControl = false;
    }
  }
  getAllQueryStrings() {
    this.GetKeyValueFromQueryString('newID').then((newID => {
      this.newID = newID;
      this.recordID= this.newID;

      this.ContactDetails_DistributedDocuments.ChangeModeOfControls("edit");      
   
    this.GetKeyValueFromQueryString('recordID').then((recordID => {
      
      if(recordID==='' || recordID===""){
        this.recordID=this.newID;
      }
      else{
        this.recordID = recordID;
      }
      this.parameterSet = true;
      this.GetKeyValueFromQueryString('relationshipRecordID').then((relationshipRecordID => {
        this.relationshipRecordID = relationshipRecordID;
     
      }));
    }));
  }));  
  }
  RelationshipRecordActions(refreshGrid: boolean) {
    if(refreshGrid) {
       this.grdcontactDetails_Trade.refreshGrid(false);
      }
  }
  goBackToPrevPage() {
    this.location.back();
  }
  public userNotifiactionList_rowClick(ev: any, objectName:string = '') {
    let data = ev.data;
    if( data.NotificationRecipientID){
    this.RedirectToActionURL(data.NotificationRecipientID);
    }else{
      if(objectName.length>0 && objectName.toLowerCase() == 'questionnaireresponse'){
        this._router.navigateByUrl("questionnaire-response-details?RecordID=" + data.RecordID);
      }
    }
    // let navigateURL = ev.navigateURL;
    // var queryStringParameters;
    // if (data.NotificationRecipientID) {
    //   queryStringParameters = 'RecordID=' + data.NotificationRecipientID;
    // }
    // else {
    //   queryStringParameters = 'RecordID=' + data.RecordID;
    // }
    // this.AddQuerystringParameterAndnavigateWithDecryptedUrl(navigateURL, queryStringParameters);
  }

  onTab1ItemClick(ev: any) {

    if (ev?.itemData?.title?.toLowerCase() == "details") {
      this.showMapControl = true;
    }
    else {
      this.showMapControl = false;
    }
    this.SetTabIndex("tab1Index",ev?.itemIndex,this.localStorageService);    
  }
  
  addressGridCustomActions(data: any) {
    switch (data.CommandObject.Command.toLowerCase()) {
      case "addnewaddress":
        this.peopleDetails_AddressesPopup.OpenPopup(Guid.EMPTY,"newpopup");
      break;
      case "editpopup":
        this.peopleDetails_AddressesPopup.OpenPopup(data.SelectedRecords[0],"editpopup");
      break;  
      case "setaddressasdefault":
        data.SelectedRecords.forEach(element => {    
          const index = data.SelectedRecords.indexOf(element);
          if(index === data.SelectedRecords.length - 1) 
          {
            this.addressservice.SetDefaultAddress(element,this.eventQueue,true,true);
          }
          else{
            this.addressservice.SetDefaultAddress(element,this.eventQueue,false);
          }
          
        });
      break;
      default:
        this.translateService.get('CustomActionYetToImplemented', { title: data.CommandObject.Name }).subscribe((text: string) => {
          this.alertService.showAlertPopup(this.translateService.instant('InfoTitle'), text,
            'fa-solid fa-circle-info', this.translateService.instant('OkButtonText'));
        });
      break;
    }

  }
  gridCustomActions(data: any) {
    switch (data.CommandObject.Command.toLowerCase()) {
      case "viewquestionnaireresponse":
        this.questionnaireResponseViewer.showPopup(data.SelectedRecords);
        break;
      case "addworkertraderelationship":
          this.recordrelationshipcomponent.openPopup();
          break;
      case "updatestatuscancel":
        this.notificationService.updatestatusNotificationRecipientcancel(data,this.ContactDetails_DistributedDocuments);
        break;
      case "remindnotification":
        const allRows = this.ContactDetails_DistributedDocuments.dataGrid.instance.getVisibleRows();        
        this.notificationService.remindNotification(data,allRows);
        break;          
      default:
        this.translateService.get('CustomActionYetToImplemented', { title: data.CommandObject.Name }).subscribe((text: string) => {

          var InfoTitle:string="";
          var OkButtonText:string="";
          this.translateService.get('InfoTitle').subscribe((translated: string) => {
            InfoTitle = translated;
          });
          this.translateService.get('OkButtonText').subscribe((translated: string) => {
            OkButtonText = translated;
          });
          this.alertService.showAlertPopup(InfoTitle, text,'fa-solid fa-circle-info',OkButtonText);
        });
        break;
    }

  }
  RedirectToActionURL(recordID: string) {
    this.httpClient.get<any>(environment.apiUrl + 'api/NotificationRecipient/GetNotificationRecipientByRelationshipIdAndRecordID/D70AB205-4698-4E02-B107-A1D8849F4645/' + recordID)
      .subscribe((response) => {
        if (response) {
          var notificationRecipient;
          response.forEach(element => {
            if (element.Object1_RecordID.toLowerCase() == recordID.toLowerCase() && element.Object2_RecordID.toLowerCase() == this.loggedinUserID.toLowerCase()) {
              notificationRecipient = element;
            }
          });
          if (notificationRecipient) {
            //notificationRecipient.ActionURL = notificationRecipient.ActionURL.replaceAll("https://test.statrack.co.uk/", "http://localhost:4200/");
            if (notificationRecipient.ActionURL != null) {
              if (!notificationRecipient.ActionURL.toLowerCase().includes("/AngularUI/notification-recipient-details".toLowerCase())) {
                this.updateNotificationReadStatus(recordID,notificationRecipient.NotificationStatus_EnumID)
                this.RedirectToEncryptUrl(notificationRecipient.ActionURL);
              }
              else if (recordID && !notificationRecipient.ActionURL.toLowerCase().includes("recordid=" + recordID.toLowerCase())) {
                this.updateNotificationReadStatus(recordID,notificationRecipient.NotificationStatus_EnumID)
                this.RedirectToEncryptUrl(notificationRecipient.ActionURL);
              }
              else {
                this.updateNotificationReadStatus(recordID,notificationRecipient.NotificationStatus_EnumID)
                this.navigateTo("notification-recipient-details", 'RecordID=' + recordID);
              }
            }
          }
          else {
            this._router.navigateByUrl('unauthorised');
          }
        }
        else {
          this._router.navigateByUrl('unauthorised');
        }
      });
  }
  updateNotificationReadStatus(recordID: string, notificationStatus_EnumID: string) {
    if (recordID != "" && recordID != '' && recordID != null && recordID != undefined) {
      if (notificationStatus_EnumID.toLowerCase() === '00000000-0000-0001-4100-000000000004' || notificationStatus_EnumID.toLowerCase() === '00000000-0000-0001-4100-000000000004') {
        this.notificationService.updateNotificationReadStatus(recordID).then((resp) => {
          //this.NotificationRecipientRibbonControl.bindEnumerationTransitions();  
        });
      }
    }
  }
}