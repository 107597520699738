<header class="navigation-header" id="navigation-header">
  <nav>
    <div class="hamburger-nav-icon"><i (click)="navSliderMenuburgerBtn()" class="fa-light fa-bars"></i><i
        (click)="navSliderMenuCloseBtn()" class="fa-solid fa-xmark"></i></div>
    <!-- <div class="hamburger-nav-close-icon"></div> -->
    <div class="left_header">
      <!-- <div class="mobile_logo">
        <img [routerLink]="['/home']" src={{brandingService.GetMobileAppLogo()}} alt="Logo" />
      </div> -->
      <div class="main_logo">
        <img [routerLink]="['/home']" class="desktop_main_logo" src={{brandingService.GetDesktopLogo()}} alt="Logo" />
      </div>
      <div class="dx-menu-outer">
        <dx-menu #menu [dataSource]="rootMenu" displayExpr="name" [showFirstSubmenuMode]="showFirstSubmenuModes"
          (onItemClick)="itemClickMenu($event)" orientation="horizontal" [hideSubmenuOnMouseLeave]="false"
          [adaptivityEnabled]="toggle" itemTemplate="list-item">
          <div *dxTemplate="let data of 'list-item'; let index = index" class="custom-temp-menu">
            <i *ngIf="data.icon" [ngClass]="data.icon"></i>
            <span>{{data.name}}</span>
          </div>
        </dx-menu>
      </div>

    </div>
    <div class="main_logo center_logo">
      <img [routerLink]="['/home']" class="desktop_main_logo" src={{brandingService.GetDesktopLogo()}} alt="Logo" />
    </div>
    <div class="right_header">
      <div class="dx-menu-outer">
        <div class="header-notifi-count" *ngIf="hasNotificationPermision" (click)="getNotifications()">
          <i class="fa-regular fa-bell" aria-hidden="true"></i>
          <label [textContent]="notificationCount"></label>
        </div>
        <dx-menu #rightmenu [dataSource]="accountMenu" displayExpr="name" [showFirstSubmenuMode]="showFirstSubmenuModes"
          orientation="horizontal" [hideSubmenuOnMouseLeave]="true" [adaptivityEnabled]="toggle"
          (onItemRendered)="onItemRendered($event)" (onItemClick)="ItemClick($event)" itemTemplate="list-data">
          <div *dxTemplate="let data of 'list-data'; let index = index" class="custom-temp-menu">
            <i [ngClass]="data.icon"></i>
           <span>{{data.name}}</span>
          </div>
        </dx-menu>

      </div>
    </div>
  </nav>
</header>