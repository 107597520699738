<div class="customloader" id="customloader"></div>
<div class="login-background"><img src="{{brandingService.GetSplashScreen()}}" /></div>
<div class="login-container reset-container validation-container">
    <div class="card">
        <div class="card-body " *ngIf="IsUserNameScreen">
            <div class="logo-head ">
                <img class="login_logo" src="{{brandingService.GetDesktopLogo()}}" />
            </div>
            <div class="text-center">
                <h2>Reset Password</h2>
                <!-- <img class="login_logo" src="{{brandingService.GetDesktopLogo()}}" /> -->
                <p *ngIf="!IsUserNameExists">If you are having problems logging in, you can reset your password via
                    Email or your security questions.</p>
                <p *ngIf="IsUserNameExists" class="NotificationResetPassword">{{UserNameExistsText}}</p>
            </div>

            <p class="text-center"></p>
            <form method="post" (submit)="handleSubmit($event)">

                <div class="form">
                    <div class="field_group" *ngIf="isShown">
                        <div class="field_control">
                            <dx-text-box placeholder="Username" (onKeyDown)="onKeyDown($event)" [(value)]="userName" [inputAttr]="{ 'aria-label': 'Username' }">
                                <dx-validator>
                                    <dxi-validation-rule type="required"
                                        message="Username is required"></dxi-validation-rule>
                                </dx-validator>
                            </dx-text-box>
                        </div>
                    </div>

                    <div class="checkbox_field_group field_group">
                        <div class="field_control">
                            <dx-check-box [(value)]="isRobot" text="{{'IAmNotARobotMessage'|translate}}"
                                [elementAttr]="{ 'aria-label': 'Is Robot' }">
                                <dx-validator>
                                    <dxi-validation-rule type="required"
                                        message="This field is required"></dxi-validation-rule>
                                </dx-validator>
                            </dx-check-box>
                        </div>
                    </div>
                    <div class="form_btn_groups">
                        <dx-button id="button" (onClick)="Cancel()" text="Cancel">
                        </dx-button>
                        <dx-button id="button" [useSubmitBehavior]="true" text="Continue">
                        </dx-button>
                    </div>
                </div>

            </form>


        </div>
        <div class="card-body" *ngIf="IsEmailSendScreen">
            <div class="logo-head ">
                <img class="login_logo" src="{{brandingService.GetDesktopLogo()}}" />
            </div>
            <div class="text-center">
                <h2>Reset Password</h2>
            </div>
            <form method="post" (submit)="SendEmailOrVerifySecurityQuestions($event)">
                <div class="form">
                    <div class="field_group">
                        <p class="m-0">Reset password for: <strong>{{userName}}</strong></p>
                    </div>                    
                    <div class="field_group">
                        <div class="field_control">
                            <dx-radio-group [items]="viaList" [(value)]="RadioButtonValue" (onValueChanged)="onValueChanged($event)">
                            </dx-radio-group>
                        </div>
                    </div>
                    <div *ngIf="!isEmailHide" class="field_group" >
                        <div class="field_control">
                            <dx-text-box [(value)]="UserInputEmailAddress" [disabled]="isEmailHide" placeholder="{{'EmailExamplePlaceholder'|translate}}"
                                [inputAttr]="{ 'aria-label': 'Email' }">
                                <dx-validator>

                                    <dxi-validation-rule type="required"
                                        message="Email is required"></dxi-validation-rule>
                                    <dxi-validation-rule type="pattern"
                                        pattern="\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*"
                                        message="Email is invalid">
                                    </dxi-validation-rule>
                                </dx-validator>
                            </dx-text-box>
                        </div>
                    </div>
                    <div *ngIf="isEmailHide">
                    <div *ngFor='let sq of securityQuestionList;let i = index'>
                        <div class="field_group">
                            <p class="m-0">{{'Security Question'|translate}}</p>
                            <p class="m-0"><strong>{{sq.QuestionTextValue}}</strong></p>
                        </div>
                        <div class="field_group">
                            <div class="field_control">
                                <p class="m-0">{{'Security Answer'|translate}}</p>
                                <dx-text-box [(value)]="sq.AnswerValue" [disabled]="!isEmailHide"  placeholder="{{'SecurityAnswerPlaceHolder'|translate}}">
                                    <dx-validator>
                                        <dxi-validation-rule type="required" message="Answer is required"></dxi-validation-rule>
                                    </dx-validator>
                                </dx-text-box>
                            </div>
                        </div>
                    </div>
</div>
                    

                    <div class="checkbox_field_group field_group">
                        <div class="field_control">
                            <dx-check-box [(value)]="isRobot" text="{{'IAmNotARobotMessage'|translate}}"
                                [elementAttr]="{ 'aria-label': 'Is Robot' }">
                                <dx-validator>
                                    <dxi-validation-rule type="required"
                                        message="This field is required"></dxi-validation-rule>
                                </dx-validator>
                            </dx-check-box>
                        </div>
                    </div>
                    <div class="form_btn_groups">
                        <dx-button id="button" (onClick)="Cancel()" text="Cancel">
                        </dx-button>
                        <dx-button id="button" [useSubmitBehavior]="true" text="Continue">
                        </dx-button>
                    </div>
                </div>

            </form>


        </div>
        <div class="card-body" *ngIf="IsEmailSentScreen">
            <div class="logo-head ">
                <img class="login_logo" src="{{brandingService.GetDesktopLogo()}}" />
            </div>
            <div class="text-center">
                <h2>Email Sent</h2>
                <p>If we have matched your email address you will get a notification shortly.</p>
                <a class="ret_to_login login_btns" [routerLink]="['/login']">Return to Log In page</a>
            </div>
        </div>
        <div class="card-body change-password" *ngIf="IsResetPasswordScreen">
            <div class="logo-head ">
                <img class="login_logo" src="{{brandingService.GetDesktopLogo()}}" />
            </div>
            <div class="text-center">
                <h2>Reset Password</h2>
            </div>
            <form method="post" (submit)="handlePasswordReset($event)">

                <div class="form">
                    <div class="field_group">

                    </div>
                    <div class="field_group">
                        <div class="field_label required-label-asterisk">Username</div>
                        <p class="m-0"><strong>{{userName}}</strong></p>
                    </div>
                    <div class="field_group">
                        <div class="field_label required-label-asterisk">New Password</div>
                        <div class="field_control">
                            <dx-text-box mode="password" [(value)]="password"
                                [inputAttr]="{ 'aria-label': 'New Password' }">
                                <dx-validator>
                                    <dxi-validation-rule type="required"
                                        message="Password is required"></dxi-validation-rule>
                                    <dxi-validation-rule type="pattern"
                                        pattern="^(?! )(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&amp;*-.+ ])(?=.*?[0-9]).{6,}[^\s]$"
                                        message="Password must have at least 6 characters with 1 uppercase, 1 lowercase, 1 numeric and 1 special character eg: #?!@$%^&*-.+ Do not use space character at the start or end of your Password.">
                                    </dxi-validation-rule>
                                </dx-validator>
                            </dx-text-box>
                        </div>
                        <div class="paswd_str_mtr">
                            <password-strength-meter [password]="password"></password-strength-meter>
                        </div>
                    </div>
                    <div class="field_group">
                        <div class="field_label required-label-asterisk">Confirm New Password</div>
                        <div class="field_control">
                            <dx-text-box mode="password" [(value)]="confirmPassword"
                                [inputAttr]="{ 'aria-label': 'Confirm Password' }">
                                <dx-validator>
                                    <dxi-validation-rule type="required"
                                        message="Confirm Password is required"></dxi-validation-rule>
                                    <dxi-validation-rule type="compare" [comparisonTarget]="passwordComparison"
                                        message="Password and Confirm New Password fields must match."></dxi-validation-rule>
                                </dx-validator>
                            </dx-text-box>
                        </div>
                        <div class="paswd_str_mtr">
                            <password-strength-meter [password]="confirmPassword"></password-strength-meter>
                        </div>
                    </div>

                    <div class="checkbox_field_group field_group">
                        <div class="field_control">
                            <dx-check-box [(value)]="isRobot" text="{{'IAmNotARobotMessage'|translate}}"
                                [elementAttr]="{ 'aria-label': 'Is Robot' }">
                                <dx-validator>
                                    <dxi-validation-rule type="required"
                                        message="This field is required"></dxi-validation-rule>
                                </dx-validator>
                            </dx-check-box>
                        </div>
                    </div>
                    <div class="form_btn_groups">
                        <dx-button id="button" [useSubmitBehavior]="true" text="Change Password">
                        </dx-button>
                    </div>
                </div>

            </form>


        </div>
        <div class="card-body" *ngIf="IsResetPasswordSuccessfulScreen">
            <div class="logo-head ">
                <img class="login_logo" src="{{brandingService.GetDesktopLogo()}}" />
            </div>
            <div class="text-center">
                <h2>Password Changed</h2>
                <p style="max-width: 390px">Your password has been changed Successfully. You may now log in with the new
                    password</p>
                <a class="ret_to_login login_btns" [routerLink]="['/login']">Return to Log In page</a>
            </div>
        </div>

    </div>

</div>