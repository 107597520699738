<app-breadcrumb [ComponentObjectID]="ComponentObjectID"></app-breadcrumb>

<div class="row webpartrow">
    <div class="col-lg-6 webpartcol screen-50">
        <div class="m-b-30">
            <app-grid  [IsUnauthorizedPage]="false" instanceID="documentDetails"></app-grid>
        </div>
       
    </div>
    <div class="col-lg-6 webpartcol screen-50">
        <div class="m-b-30 document-det-viewer">
            <app-document-assests-viewer-detail instanceID="DocumentDetail_DocumentAssetViewerDetail"></app-document-assests-viewer-detail>
        </div>
    </div>
</div>