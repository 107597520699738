<div class="customloader" id="customloader"></div>
<div class="login-background"><img src="{{brandingService.GetSplashScreen()}}" /></div>
<div class="login-container">

    <div class="card my-auto shadow">
        <div class="card-body">
            <div class="logo-head ">
                <img class="login_logo" src="{{brandingService.GetDesktopLogo()}}" />
            </div>
            <div style="height: 5px;"></div>
            <div *ngIf="isLoginForm">
                <div *ngIf="IsloginError" class="login-err-req-msg">{{loginErrorMessage}}</div>
                <div *ngIf="isSessionExpired" class="login-err-req-msg">{{sessionExpiredErrorMessage}}</div>
                <div *ngIf="IsloginRequired" [hidden]="!loginForm.controls['password'].errors?.['required'] && !loginForm.controls['userName'].errors?.['required']" class="login-err-req-msg">{{loginRequiredMessage}}</div>
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <input type="text" #userName i18n-placeholder placeholder="Username" class="form-control"
                            formControlName="userName" required />
                        <div *ngIf="loginForm.controls['userName'].invalid && (loginForm.controls['userName'].dirty || loginForm.controls['userName'].touched)"
                            class="validation-error-text">
                            <div *ngIf="loginForm.controls['userName'].errors?.['required']">{{usernameRequired}}</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="password" formControlName="password" i18n-placeholder placeholder="Password"
                            class="form-control" required />
                        <div *ngIf="loginForm.controls['password'].invalid && (loginForm.controls['password'].dirty || loginForm.controls['password'].touched)"
                            class="validation-error-text">
                            <div *ngIf="loginForm.controls['password'].errors?.['required']">{{passwordRequired}}</div>
                        </div>
                    </div>
                    <input type="submit" class="login_btns btn btn-primary w-100" i18n-value value="Login">
                </form>
                <a class="register_link_btn" routerLink="/reset-password" i18n>Reset Password?</a>
            </div>
            <div *ngIf="!isLoginForm">
                <div class="text-center">
                    <p>Your account is locked due to too many unsuccessful attempts.<br /> Contact your system
                        administrator for assistance.</p>
                    <dx-button class="ret_to_login login_btns" id="button" (onClick)="Reload()"
                        text="Return to Log In page">
                    </dx-button>
                </div>
            </div>
        </div>
    </div>
</div>