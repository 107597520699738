
export class RecordSearchComponentProperties {
    DDLObjectName:string = '';
    HeaderText:string = '';
    NavigateWithReturnLink: boolean = false;
    NavigateWithNewMode: boolean = false;
    LabelText: string = '';
    NavigateToURL: string = '';
    NumberOfSecondsForDefaultSearch: string = '';
    IsCustomAction:boolean = false;
    RelationshipID:string = '';
}