<dx-scheduler class="calendar-scheduler" #scheduler [timeZone]="currentTimezone" [dataSource]="appointmentsData"
  [remoteFiltering]="true" [views]="['day', 'week', 'workWeek', 'month']" currentView="month" [height]="730"
  dateSerializationFormat="yyyy-MM-ddTHH:mm:ssZ" textExpr="LookupValue" startDateExpr="StartDate" endDateExpr="EndDate"
  allDayExpr="IsAllDayEvent" descriptionExpr="Description" recurrenceRuleExpr="RecurrenceRule"
  [currentDate]="currentDate" [startDayHour]="0" (onAppointmentClick)="onAppointmentFormOpening($event)" 
  appointmentTemplate="appointment-template">
  <div *dxTemplate="let model of 'appointment-template'" class="custom_cal_temp">
    <p class="custom_cal_temp_sub" *ngIf="model.appointmentData.LookupValue">{{model.appointmentData.LookupValue}}</p>
    <p>{{model.appointmentData.StartDate | date: 'HH:mm' }} - {{model.appointmentData.EndDate |date: 'HH:mm'}}</p>
  </div>
  <dxo-editing [allowDeleting]="false" [allowAdding]="true" [allowUpdating]="true"></dxo-editing>
  <dxi-resource fieldExpr="AppointmentEventType_EnumID" label="Appointment Type" displayExpr="Value"
    valueExpr="EnumerationId" colorExpr="Colour" [dataSource]="appointEventresources"></dxi-resource>
</dx-scheduler>
<dx-popup #popup [wrapperAttr]="{class: 'lobas-popup lobas-form-popup' }" [width]="800" [closeOnOutsideClick]="true"
  [(visible)]="isCustomPopupVisible" [title]="Title" [hideOnOutsideClick]="false" [showCloseButton]="true"
  [resizeEnabled]="false" [dragEnabled]="false" (onHiding)="onHiding($event)">
  <dx-validation-group #schedulerGroup>
    <form #Form="ngForm">
      <div class="form">
        <div class="scheduler-form-group-row form-group-row">
          <div class="w-50">
            <div class="field_group">
              <div class="field_label required-label-asterisk">Type</div>
              <div class="field_control">
                <dx-select-box #Type  displayExpr="Value" valueExpr="EnumerationId" [dataSource]="appointEventresources"
                [inputAttr]="{ 'aria-label': 'EnumerationId' }" [(value)]="appointmenttype">
                <dx-validator>
                    <dxi-validation-rule type="required" message="Appointment Type  is required"></dxi-validation-rule>
                </dx-validator>
            </dx-select-box>
              </div>
            </div>
            <div class="field_group">
              <div class="field_label required-label-asterisk">Start Date</div>
              <div class="field_control">
                <dx-date-box #StartDate [(value)]="startDate" type="datetime" [inputAttr]="{ 'aria-label': 'Date' }">
                  <dx-validator>
                    <dxi-validation-rule type="required" message="Start date is required">
                    </dxi-validation-rule>
                  </dx-validator>
                </dx-date-box>
              </div>
            </div>
            <div class="field_group">
              <div class="field_label required-label-asterisk">End Date</div>
              <div class="field_control">
                <dx-date-box #EndDate [(value)]="endDate" type="datetime" [inputAttr]="{ 'aria-label': 'Date' }">
                  <dx-validator>
                    <dxi-validation-rule type="required" message="End date is required">
                    </dxi-validation-rule>
                  </dx-validator>
                </dx-date-box>
              </div>
            </div>
          </div>
          <div class="w-50">
            <div class="field_group">
              <div class="field_label required-label-asterisk">Details</div>
              <div class="field_control">
                <dx-text-area #Description [inputAttr]="{ 'aria-label': 'Description' }" [(value)]="description">
                  <dx-validator>
                    <dxi-validation-rule type="required" message="Detail is required">
                    </dxi-validation-rule>
                  </dx-validator>
                </dx-text-area>
              </div>
            </div>
          </div>
          <div class="w-50">
            <div class="field_group">
              <div class="field_label">Link</div>
              <div class="field_control">
                <dx-text-box [hidden]="!isLinkVisible" #AppointmentLink [inputAttr]="{ 'aria-label': 'Link' }"
                  [(value)]="Link">
                </dx-text-box>
                <div [hidden]="isLinkVisible" *ngIf="Link!='' && Link!=null"> <a target="_blank" href={{this.Link}}><i class="fa-solid fa-link"></i></a></div>
              </div>
            </div>
          </div>
          <div class="w-50">
            <div class="field_group">
              <div class="field_label required-label-asterisk">Subject</div>
              <div class="field_control">
                <dx-text-box #AppointmentSubject aria-disabled="true" [inputAttr]="{ 'aria-label': 'Subject' }"
                  [(value)]="Subject">
                  <dx-validator>
                    <dxi-validation-rule type="required" message="Subject is required">
                    </dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
            </div>
          </div>
        </div>
        <div class="form-btn-outer">
          <dx-button #btnSave *ngIf="action!='view'" id="btnCancel" (onClick)="closePopup()" text="Cancel">
          </dx-button>
          <dx-button #btnSave *ngIf="action!='view'" id="btnSave" (onClick)="Submit($event)" text="Save">
          </dx-button>
        </div>
      </div>
    </form>
  </dx-validation-group>
</dx-popup>
<div class="text-end">
  <dx-button *ngIf="isUserHavePermissionToAdd" (onClick)="openPopup()" text="Add">
  </dx-button>
</div>
<app-validation-popup #validationpopup></app-validation-popup>