import { Component, ViewChild } from '@angular/core';
import { DocumentAssestsViewerPopupComponent } from '../common/document-assests-viewer-popup/document-assests-viewer-popup.component';
import { DocumentUploadPopupComponent } from '../common/document-upload-popup/document-upload-popup.component';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { GridComponent } from '../common/grid/grid.component';
import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/encryption.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Guid } from 'guid-typescript';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent extends PageBaseService{
  @ViewChild("DocumentDocViewPopup", { static: false }) DocumentDocViewPopup: DocumentAssestsViewerPopupComponent
  @ViewChild("DocumentDocUploadPopup", { static: false }) DocumentDocUploadPopup: DocumentUploadPopupComponent
  @ViewChild("grdMyDocuments_Public", { static: true }) grdMyDocuments_Public: GridComponent;
  isVisible: Boolean = false;
  isVisible2: Boolean = false;
  isVisible3: Boolean = false;
  tab1Index:number=0;
  loggedinUserID: string = Guid.EMPTY;
  constructor(private localStorageService: LocalStorageService,private eventQueue:LobasEventQueueService ,private notificationService: NotificationService, private auth: AuthService, private httpClient: HttpClient,private _router: Router, private _enc: EncryptionService) {
    super(_router, _enc);
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
    this.loggedinUserID = this.auth.getGuidUserID() ?? '';
  }
  async ngOnInit() {
    this.tab1Index = this.GetTabIndex("tab1Index",this.localStorageService);
      }
    
  onTab1ItemClick(ev: any) {    
  this.SetTabIndex("tab1Index",ev?.itemIndex,this.localStorageService);
  }
  expandCollaspeComponent() {
    this.isVisible = !this.isVisible;
  }
  expandCollaspeComponent2() {
    this.isVisible2 = !this.isVisible2;
  }
  expandCollaspeComponent3() {
    this.isVisible3 = !this.isVisible3;
  }
  DocumentgridCustomActions(data: any) {
    if (data != undefined && data != null) {
      switch (data.CommandObject.Command.toLowerCase()) {
        case "view":
        case "viewdocument":
          this.DocumentDocViewPopup.showDocumentAssetViewerPopup(true, data.SelectedRecords[0], "viewDocument", null);
          break;
        case "uploadpopup":
          if(data.CommandObject.Name.toUpperCase()=="EDIT")
            {
              this.DocumentDocUploadPopup.OpenUploadPopup(data.SelectedRecords[0],"editpopup",this.grdMyDocuments_Public);
            }
            else{
              this.DocumentDocUploadPopup.OpenUploadPopup(null,"newpopup",this.grdMyDocuments_Public);
            }          
          break;
        case "deleteupload":
          var RelationshipID ="";
          if(this.grdMyDocuments_Public.gridProperties!=null && this.grdMyDocuments_Public.gridProperties!=undefined)
              {
                RelationshipID=this.grdMyDocuments_Public.gridProperties.RelationshipID;
              }
            this.DocumentDocUploadPopup.DeleteUploadDocument(data.SelectedRecords[0],RelationshipID,this.grdMyDocuments_Public);
          break;
        case "downloaddocument":            
          this.DocumentDocUploadPopup.downloadFile(data.SelectedRecords[0]);
          break;
      }
    }

  }

  public rowClickDistributedGrid(ev: any) {
    let data = ev.data;
    this.RedirectToActionURL(data.NotificationRecipientID);
    //let navigateURL = ev.navigateURL;
    // if(data.NotificationRecipientID)
    //   {
    //     var queryStringParameters = 'RecordID=' + data.NotificationRecipientID;    
    //     this.navigateTo(navigateURL, queryStringParameters);
    //   }
    
  }
  RedirectToActionURL(recordID: string) {
    this.httpClient.get<any>(environment.apiUrl + 'api/NotificationRecipient/GetNotificationRecipientByRelationshipIdAndRecordID/D70AB205-4698-4E02-B107-A1D8849F4645/' + recordID)
      .subscribe((response) => {
        if (response) {
          var notificationRecipient;
          response.forEach(element => {
            if (element.Object1_RecordID.toLowerCase() == recordID.toLowerCase() && element.Object2_RecordID.toLowerCase() == this.loggedinUserID.toLowerCase()) {
              notificationRecipient = element;
            }
          });
          if (notificationRecipient) {
            //notificationRecipient.ActionURL = notificationRecipient.ActionURL.replaceAll("https://test.statrack.co.uk/","http://localhost:4200/");
            if (notificationRecipient.ActionURL != null) {
              if (!notificationRecipient.ActionURL.toLowerCase().includes("/AngularUI/notification-recipient-details".toLowerCase())) {
                this.updateNotificationReadStatus(recordID,notificationRecipient.NotificationStatus_EnumID);
                this.RedirectToEncryptUrl(notificationRecipient.ActionURL);
              }
              else if (recordID && !notificationRecipient.ActionURL.toLowerCase().includes("recordid=" + recordID.toLowerCase())) {
                this.updateNotificationReadStatus(recordID,notificationRecipient.NotificationStatus_EnumID)
                this.RedirectToEncryptUrl(notificationRecipient.ActionURL);
              }
              else {
                this.updateNotificationReadStatus(recordID,notificationRecipient.NotificationStatus_EnumID)
                this.navigateTo("notification-recipient-details", 'RecordID=' + recordID);
              }
            }
          }
          else {
            this._router.navigateByUrl('unauthorised');
          }
        }
        else {
          this._router.navigateByUrl('unauthorised');
        }
      });
  } 
  updateNotificationReadStatus(recordID:string, notificationStatus_EnumID: string) {
    if (recordID != "" && recordID != '' && recordID != null && recordID != undefined) {
    if(notificationStatus_EnumID.toLowerCase() === '00000000-0000-0001-4100-000000000004' || notificationStatus_EnumID.toLowerCase() === '00000000-0000-0001-4100-000000000004' ){
      this.notificationService.updateNotificationReadStatus(recordID).then((resp)=>{
        //this.NotificationRecipientRibbonControl.bindEnumerationTransitions();  
      });
    }
  }
  }
}
 