<form id="SendEmail" method="post">
    <div class="form-row">
        <dx-validation-group #securityQuestion>
            <div class="field_group_row">
                <div class="field_group">
                    <div class="field_label required-label-asterisk">Subject</div>
                    <div class="field_control">
                        <dx-text-box [(value)]="subject">
                            <dx-validator>
                                <dxi-validation-rule type="required"
                                    message="Subject is required"></dxi-validation-rule>
                            </dx-validator>
                        </dx-text-box>
                    </div>
                </div>
                <div class="field_group">
                    <div class="field_label required-label-asterisk">Priority</div>
                    <div class="field_control">
                        <!-- <dx-select-box displayExpr="Value" valueExpr="Id"
                    [dataSource]="priorityDataSourceList" [inputAttr]="{ 'aria-label': 'Id' }"
                    [(value)]="priorityTextValue" [searchMode]="searchModeOption" [searchExpr]="searchExprOption"
                    [searchTimeout]="searchTimeoutOption" [minSearchLength]="minSearchLengthOption"
                    [searchEnabled]="true" [showDataBeforeSearch]="showDataBeforeSearchOption"
                    (onSelectionChanged)="selectionChanged($event)">
                    <dx-validator>
                        <dxi-validation-rule type="required"
                            message="Document Type is required"></dxi-validation-rule>
                    </dx-validator>
                </dx-select-box> -->
                        <dx-select-box [items]="priorityList" [inputAttr]="{ 'aria-label': 'Priority' }"
                            [(value)]="priorityTextValue"></dx-select-box>
                    </div>
                </div>
            </div>
            <div class="field_group">
                <div class="field_label required-label-asterisk">Recipient(s)</div>
                <div class="field_control">
                    <dx-text-box [(value)]="recipients">
                        <dx-validator>
                            <dxi-validation-rule type="required"
                                message="Enter single or multiple email addresses, separated by a comma, without spaces."></dxi-validation-rule>
                            <dxi-validation-rule type="pattern"
                                pattern="^((\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)*([,])*)*$"
                                message="Supports single or multiple comma seperated email ids without spaces"></dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
            </div>

            <div class="field_group">
                <div class="field_label required-label-asterisk">Message</div>
                <div class="field_control">

                    <dx-text-area [height]="90" [(value)]="message" [inputAttr]="{ 'aria-label': 'Message' }">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Message is required"></dxi-validation-rule>
                        </dx-validator>
                    </dx-text-area>
                </div>
            </div>
        </dx-validation-group>
    </div>
</form>
<app-validation-popup #sendEmailValidationpopup></app-validation-popup>