import { Component, ViewChild } from '@angular/core';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { RecordSearchComponent } from '../common/record-search/record-search.component';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SecurityQuestionsPopupComponent } from '../common/security-questions-popup/security-questions-popup.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
@ViewChild('assetRecordSearchPopup', {static:false}) assetRecordSearchPopup : RecordSearchComponent
@ViewChild('questionnaireRecordSearchPopup', {static:false}) questionnaireRecordSearchPopup : RecordSearchComponent
@ViewChild('jobRecordSearchPopup', {static:false}) jobRecordSearchPopup : RecordSearchComponent
@ViewChild("SecurityQuestionPopupUpComponent", { static: true }) securityQuestionPopupUpComponent: SecurityQuestionsPopupComponent;
swScriptElement: HTMLScriptElement;
PushNotificationScriptElement: HTMLScriptElement;
PushNotificationForIosScriptElement: HTMLScriptElement;
IsShowSecurityQuestionPopup:boolean=false;
  constructor(private eventQueue: LobasEventQueueService, private auth:AuthService, private httpClient: HttpClient)
  {
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
    this.swScriptElement = document.createElement("script");
    this.swScriptElement.src = "../../assets/js/sw.js?v="+environment.version;
    document.body.appendChild(this.swScriptElement);

    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
    this.PushNotificationScriptElement = document.createElement("script");
    this.PushNotificationScriptElement.src = "../../assets/js/PushNotification.js?v="+environment.version;
    document.body.appendChild(this.PushNotificationScriptElement);

    this.PushNotificationForIosScriptElement = document.createElement("script");
    this.PushNotificationForIosScriptElement.src = "../../assets/js/PushNotificationForIOS.js?v="+environment.version;
    document.body.appendChild(this.PushNotificationForIosScriptElement);
  }

  ngOnInit()
  {
    this.ShowSecurityQuestions();
    this.auth.resetDeviceID();
  }
  openQuestionnaireResponseRecordSearchPopup(){
    this.questionnaireRecordSearchPopup.openPopup();
  }

  openAssetTestRecordSearchPopup(){
    this.assetRecordSearchPopup.openPopup();
  }

  openJobRecordSearchPopup(){
    this.jobRecordSearchPopup.openPopup();
  }

  createQuestionnaireAndRedirect(data:any) {
    var userID = this.auth.getGuidUserID() ?? '';
    var result = lastValueFrom(
      this.httpClient.post( environment.apiUrl + 'api/QuestionnaireResponse/QuestionnaireResponseWithRelationship',
        { "UserID": userID, "QuestionnaireResponseID": data.RecordID, "QuestionnaireID":  data.ParentID },
        {responseType:"text"}
      )
    ).then((response => {
      this.questionnaireRecordSearchPopup.redirectToPath(data.RecordID, data.ParentID);
    }));
  }


  ShowSecurityQuestions() {
    var userid = this.auth.getGuidUserID() ?? '';
    var questionsCount = environment.securityQuestionCount;
    this.httpClient.get<any>(environment.apiUrl + 'api/aspnet_SecurityQuestion/GetAspnetSecurityQuestion/' + userid)
      .subscribe((response) => {

        if (response)
          {
            if(response.length<questionsCount)
              {
                this.IsShowSecurityQuestionPopup = true;
                this.securityQuestionPopupUpComponent.ShowPopUp(true);
              }
              else
              {
                this.IsShowSecurityQuestionPopup = false;
                this.securityQuestionPopupUpComponent.ShowPopUp(false);
              }            
          }
          else
          {
            this.IsShowSecurityQuestionPopup = false;
            this.securityQuestionPopupUpComponent.ShowPopUp(false);
          }
      });

  }
}
