import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EncryptionService } from 'src/app/encryption.service';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';


@Component({
  selector: 'app-trade',
  templateUrl: './trade.component.html',
  styleUrls: ['./trade.component.scss']
})
export class TradeComponent extends PageBaseService {
  constructor(private localStorageService: LocalStorageService,private eventQueue:LobasEventQueueService ,private _router: Router, private _enc: EncryptionService) {
    super(_router, _enc);
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }
  isactiveTradeTabVisible: boolean =true;
  isinactiveTradeTabVisible: boolean=true;
  isdeletedTradeTabVisible: boolean =true;
  tab1Index:number=0;
  ngOnInit()
  {
    this.tab1Index = this.GetTabIndex("tab1Index",this.localStorageService);
  }
  onTab1ItemClick(ev: any) 
  {    
    this.SetTabIndex("tab1Index",ev?.itemIndex,this.localStorageService);
  }
  hideActiveTradeTabVisible(isGridHidden) {
    this.isactiveTradeTabVisible = !isGridHidden;
  }
  
  hideInActiveTradeTabVisible(isGridHidden) {
    this.isinactiveTradeTabVisible = !isGridHidden;
  } 
  
  hideDeletedTradeTabVisible(isGridHidden) {
    this.isdeletedTradeTabVisible = !isGridHidden;
  }
}
