export enum AppEventType {
  ClickedOnNotification = 'CLICKED_ON_NOTIFICATION',
  SocketEvent = 'SOCKET_EVENT',
  AccountChanged = "AccountChanged",
  SaveAllClicked = "SaveAllClicked",
  ConnectionStateChanged  = "ConnectionStateChanged",
  RibbonTransitionClick = 'RibbonTransitionClick',
  RibbonCommandClick = 'RibbonCommandClick',
  PerformRibbonCommandOperation = 'PerformRibbonCommandOperation',
  PerformRibbonTransitionOperation = 'PerformRibbonTransitionOperation',
  RefreshRibbonTransitionOnCommandUpdate = 'RefreshRibbonTransitionOnCommandUpdate', 
  ShowHideHeader = 'ShowHideHeader',
  PerformMapActions= 'PerformMapActions',
  ActionUpdateSuccess = "ActionUpdateSuccess",
  UpdateNotificationCountPostRead = "UpdateNotificationCountPostRead"
}