<app-breadcrumb [ComponentObjectID]="ComponentObjectID"></app-breadcrumb>
<div class="row webpartrow">
    <div class="col-lg-6 webpartcol screen-30">
        <div class="m-b-30">
            <app-grid #jobDetails (gridCustomActions)="gridCustomActions($event)"
                [executeUpdateTransitionStatusFromRibbonCtrl]="true" [suscribeToRibbonCommandTransitionAction]="true"
                [IsUnauthorizedPage]="false" instanceID="JobPlanner_Details" (OnValueChangedEvent)="bindLocationDropDown($event)"></app-grid>
        </div>
    </div>
    <div class="col-lg-6 webpartcol screen-30">
        <div class="m-b-30">
            <app-map #jobPlannerMap *ngIf="parameterSet" [recordID]="this.recordID" mapDivId="JobPlanner_Map"
                name="Location" mode="selectlocation" relationshipID="4A36E00B-AD51-4A56-803E-32EFD4F46369"></app-map>
        </div>
    </div>
    <div class="col-lg-12 webpartcol screen-100">
        <div class="m-b-30">
            <app-grid #shiftrequirementgrid (gridCustomActions)="gridCustomActions($event)" [IsUnauthorizedPage]="false" instanceID="jobdetails_shiftrequirementgrid"></app-grid>
        </div>
    </div>
    <div class="col-lg-6 webpartcol">
        <div class="m-b-30">
            <app-grid #grdDocument [IsUnauthorizedPage]="false"
                (gridCustomActions)="DocumentgridCustomActions($event,true)"
                instanceID="JobPlanner_JobDocumentGrid"></app-grid>
            <app-document-upload-popup instanceID="JobDetails_JobDocumentUploadPopup"
                #jobDetailDocumentUploadPopup></app-document-upload-popup>
            <app-document-assests-viewer-popup instanceID="JobDetails_DocumentAssetViewerPopup"
                #jobDetailDocumentViewPopup></app-document-assests-viewer-popup>
        </div>
    </div>
    <div class="col-lg-6 webpartcol screen-30">
       
        <div class="m-b-30">
            <app-chat *ngIf="parameterSet" [recordID]="this.recordID" [loggedinUserID]=this.loggedinUserID
                instanceID='jobDetails_Notes' [newID]="this.newID"></app-chat>
        </div>
        <div class="m-b-30">
            <app-ribbon-control *ngIf="parameterSet" instanceID="JobPlanner_Ribbon" [parentRecordID]="this.recordID"
                [relationshipRecordID]="this.relationshipRecordID" [newID]="this.newID"></app-ribbon-control>
        </div>
    </div>
</div>
<app-change-planned-time-popup #changePlannedTime (GridRefreshAction)="refreshShiftRequirementGrid()"></app-change-planned-time-popup>
<app-assignworkitem-popup #WorkItemPopup instanceID="Jobdetails_WorkItemPicker"  (GridRefreshAction)="refreshShiftRequirementGrid()"></app-assignworkitem-popup>

<app-worker-selection-tool #workerSelectionTool (GridRefreshAction)="refreshShiftRequirementGrid()"></app-worker-selection-tool>


