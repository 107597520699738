<dx-popup [showTitle]="true" title="Change Planned Time"
    [wrapperAttr]="{class: 'lobas-popup lobas-form-popup' }" #ChangePlannedTime
    [showTitle]="true" [resizeEnabled]="false" [dragEnabled]="false" [hideOnOutsideClick]="false"
    [showCloseButton]="true">
    <form method="post">
        <dx-validation-group #ChangePlannedTimeGroup>
            <div class="form changed_plantime_form">
                <div class="form-row">
                    <div class="field_group">
                        <div class="field_label required-label-asterisk">
                           New Planned Start</div>
                        <div class="field_control">
                                <dx-date-box #dxStartDate [value]="StartDate"
                                    type="datetime">
                                    <dx-validator>
                                        <dxi-validation-rule type="required"
                                                message="New Planned Start cannot be blank"></dxi-validation-rule>
                                    </dx-validator>
                                </dx-date-box>
                        </div>
                    </div>
                    <div class="field_group">
                    <div class="field_label required-label-asterisk">
                            New Planned Finish</div>
                        <div class="field_control">
                             <dx-date-box #dxEndDate [value]="EndDate" type="datetime">
                                    <dx-validator>
                                        <dxi-validation-rule type="required"
                                        message="New Planned Finish cannot be blank"></dxi-validation-rule>
                                        <dxi-validation-rule type="custom"  [reevaluate]="true"
                                        message="New Planned Finish Time cannot be before New Planned Start Time"
                                        [validationCallback]="validateStartDateLessThanEndDate">
                                    </dxi-validation-rule>
                                    </dx-validator>
                                </dx-date-box>
                        </div>
                    </div>
                </div>
                <div class="form-btn-outer">
                    <dx-button text="Cancel" (onClick)="closePopup()">
                    </dx-button>
                    <dx-button text="Save" (onClick)="Submit($event)">
                    </dx-button>
                </div>
            </div>
        </dx-validation-group>
    </form>

    <app-validation-popup #validationpopup></app-validation-popup> 
</dx-popup>