export module EnumHelper {
    export enum LocalStorageKey {
        tenancyID = 1,
        tokenId = 2,
        jsonMenu = 3,
        bearerToken = 4,
        brandingData = 5,
        userLanguage = 6,
        gridSettings = 7,
        userID = 8,
        userRoles = 9,
        distinctMenuItems = 10,
        themeName = 11 ,
        gridComponentProperties = 13,
        componentSettings = 12,
        enumerationList = 14,
        enumeration = 15 ,
        googleMap= 16 ,
        documentAssestsViewerComponentProperties=17,
        documentUploadPopupComponentProperties=18,
        document=19,
        profileImage = 20,
        bigButtonComponentProperties = 21,
        recordSearchComponentProperties = 22,
        chatComponentProperties=23,
        cardSliderComponentProperties=24,
        defaultAddressesComponentProperties=25,
        pageTabSettings=26,
        addressPopupComponentProperties=27,
        sessionExpired = 28,
        bulkEnumerationTransitionComponent = 29,
        ExtendedAttributesObjectFields = 30,
        ExtendedAttributesComponentProperties = 31,
        externalReferencesComponentProperties = 32,
        externalReferenceDataSourceList=33,
        TabControlComponentProperties = 34
      }

      export enum SortOder {
        Asc = 1,
        Desc = 2
      }
}