import { Injectable } from "@angular/core";
@Injectable({
    providedIn: 'root',
})

export class DocumentViewerComponentProperties {
    Title: string = ''
    AllowedRoles:string[] = []
    RelationshipID:string = ''
    HideWebPartHeader: boolean = false
    BindOnRelationshipOnly:string="false"
}

