import { Component } from '@angular/core';
import { AppEvent } from 'src/app/models/AppEvent';
import { AppEventType } from 'src/app/enums/appEventType';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { dtsrcBest, dtsrcWorst, closecallSources, closecallInfo } from './data'
@Component({
  selector: 'app-close-call-dashboard',
  templateUrl: './close-call-dashboard.component.html',
  styleUrls: ['./close-call-dashboard.component.scss']
})
export class CloseCallDashboardComponent {
  isVisible: boolean = false;
  isVisible2: boolean = false;
  isVisible3: boolean = false;
  isVisible4: boolean = false;

  dtsrcBest = dtsrcBest;
  dtsrcWorst = dtsrcWorst;
  closecallSources = closecallSources;
  closecallInfo = closecallInfo;
  constructor(private eventQueue: LobasEventQueueService) {
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }
  expandCollaspeComponent() {
    this.isVisible = !this.isVisible;
  }
  expandCollaspeComponent2() {
    this.isVisible2 = !this.isVisible2;
  }
  expandCollaspeComponent3() {
    this.isVisible3 = !this.isVisible3;
  }
  expandCollaspeComponent4() {
    this.isVisible4 = !this.isVisible4;
  }

}
