<div class="webpart-card">
    <div class="webpart-cardbody">
        <strong>{{this.statusMessage}}</strong>
        <div class="webpart-card-header" [ngClass]="isVisible ? 'webpart-card-closed' : 'webpart-card-open'">
            <div class="webpart-card-header-title">
                <span>{{this.componentProperties?.Title}}</span>
            </div>
            <div class="webpart-card-header-action">
                <dx-button (onClick)="expandCollaspeComponent()"
                    [icon]="isVisible ? 'fa-regular fa-angle-up' : 'fa-regular fa-angle-down'"></dx-button>
            </div>
        </div>       
        <div [hidden]="isVisible">
            <form class="lobas-cus-form" (submit)="submitForm(true)">
                <dx-form #extendedAttributeForm labelMode="outside"  [showValidationSummary]="false" 
                    [validationGroup]="this.componentProperties.Title.replace('', '')" labelLocation="top"
                    [formData]="formData" onsubmit="submitForm($event)" [colCount]="2" [minColWidth]="300"
                    [showColonAfterLabel]="false">
                </dx-form>
                <div class="text-end">
                    <dx-button [useSubmitBehavior]="true" *ngIf="!this.componentProperties.HideSaveButton" text="Save"></dx-button>
                </div>
            </form>
            <app-validation-popup #validationpopup></app-validation-popup>
        </div>
    </div>