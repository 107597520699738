import { Definition } from "./UILayoutFieldModel";

export class CustomUIFieldModel {
    RecordID: string = '';
    Version:number = 0;
    FieldName:string = '';
    FieldGroup: string = '';
    OverrideUIType:string = '';
    TextAlign: string = ''; 
    ValidationExpression:string='';
    Definition:Definition;
    ExtendedAttributes:CustomFieldExtendAttributeModel;
    RecordTenancyID:string = '';
    UILayoutID:string = '';
    VisibleIndex:number = -1;

    public CustomUIFieldModel(){
       this.Definition = new Definition();
       this.ExtendedAttributes = new CustomFieldExtendAttributeModel();
    }
}


export class CustomFieldExtendAttributeModel{
    FieldUICaption:string = ''; 
    DBDataType:string ='';
    DBDataTypeLength:string = ''; 
    IsRequiredField:boolean = false;     
    IsNullable :boolean = false;
    APIEndpoint :string = "";
    TextFieldName :string = "";
    ValueFieldName :string = "";
}