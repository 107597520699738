import { UILayoutFieldModel } from "./UILayoutFieldModel";
import { ObjectCommandModel } from "./ObjectCommandModel";
import { GridSettingsModel } from "./GridSettingsModel";
import { ObjectAttributesModel } from "./ObjectAttributesModel";
import { ObjectDatasetExtendedAttributesModel } from "./ObjectDatasetExtendedAttributesModel";


export class ComponentSettingsModel{    
    UIFields:UILayoutFieldModel[]
    Commands:ObjectCommandModel[]
    Settings:GridSettingsModel
    ObjectAttributes:ObjectAttributesModel[]
    ObjectDataset:ObjectDatasetExtendedAttributesModel
}