<div class="webpart-card" [hidden]="!isUserHavePermissionToViewGrid"
    [ngClass]="this.isGridview ? 'gridmode' : 'cardmode'">
    <div class="webpart-cardbody" id="gridContainer"
        [ngClass]="this.gridProperties.isDetailComponent ? 'detailmode' : ''">
        <div class="webpart-hrd-group" [hidden]="this.isWebpartHeaderHidden">
            <div class="webpart-card-header" [ngClass]="this.isVisible ? 'webpart-card-closed' : 'webpart-card-opened'">
                <div class="webpart-card-header-title">
                    <span>{{this.Title ?? this.gridProperties.Title| translate }}</span>
                    <dx-button (onClick)="redirectToBannerUrl()" icon="fa-regular fa-solid fa-link"
                        [hidden]="this.hideLink"></dx-button>
                </div>
                <div class="webpart-card-header-action">
                    <dx-button [hidden]="this.gridProperties.isDetailComponent"
                        hint="Refresh" (onClick)="refreshGrid()" icon="fa-regular fa-arrows-rotate"></dx-button>
                    <dx-button [elementAttr]="{class:'hidebtn-mbl'}"
                        [hidden]="this.gridProperties.isDetailComponent || dataRowTemplateName" hint="Column Chooser"
                        (onClick)="ShowColumnChooser(true)" icon="fa-regular far fa-columns"></dx-button>
                    <dx-button [elementAttr]="{class:'hidebtn-mbl'}"
                        [hidden]="this.gridProperties.isDetailComponent || dataRowTemplateName" hint="Export Options"
                        (onClick)="OpenGridMenuPopup('Export Options')" icon="fa-regular fa-file-export"></dx-button>
                    <dx-button [hidden]="this.gridProperties.isDetailComponent "
                        hint="Filter Builder" (onClick)="OpenGridMenuPopup('Filter Builder')"
                        icon="fa-regular far fa-filter"></dx-button>
                        <dx-button [elementAttr]="{class:'hidebtn-mbl'}" hint="Info" (onClick)="InfoIconClick()" icon="fa-regular fa-info-circle"
                        [visible]="!ishideInfoIcon"></dx-button>
                    <dx-button [elementAttr]="{class:'hidebtn-mbl'}"
                        [hidden]="this.gridProperties.isDetailComponent || this.gridProperties.GridDisplayMode.toLowerCase() === 'grid' || this.gridProperties.GridDisplayMode.toLowerCase() === 'card'"
                        [hint]="isGridview? 'Card View' : 'Grid View'" (onClick)="toggleGridView()"
                        [icon]="isGridview? 'fa-regular fa-border-all' :'fa-regular fa-bars'"></dx-button>
                    <dx-button (onClick)="expandCollaspeComponent()" [hint]="isVisible? 'Expand' : 'Collapse'"
                        [icon]="isVisible ? 'fa-regular fa-angle-up' : 'fa-regular fa-angle-down'"></dx-button>
                </div>
            </div>

        </div>
        <div [ngClass]="this.showSingleCard ? 'singlecard' : ''" [hidden]="isVisible">
            <div class="grid-filter" [hidden]="!this.dataGrid.filterValue && !searchPanelText">
                <div class="grid-filter-inner">
                    <div class="grid-filter-normal-search-outer" [hidden]=!searchPanelText>
                        <div class="grid-filter-normal-search">
                            <i class="fa-regular fa-magnifying-glass"></i>
                            <span>{{searchPanelText}}</span>
                        </div>
                        <div class="grid-filter-and" [hidden]="!searchPanelText || !this.dataGrid.filterValue">AND</div>
                    </div>
                    <div class="dx-filter-builderbox" [hidden]="!this.dataGrid.filterValue">
                        <i class="fa-solid fa-filter"></i>
                        <dx-filter-builder [fields]="filterBuilderFields" [(value)]="this.dataGrid.filterValue">
                        </dx-filter-builder>
                    </div>
                </div>
                <span (click)="clearGridFilter()" class="close_gridtoolmenu"><i class="fa-regular fa-times"></i></span>
            </div>
            <div class="infoicon_content" id="divInfoIcon" [hidden]="isVisibledivInfoIcon"></div>

            <dx-data-grid
                [ngStyle]="{'min-height': this.gridProperties.ComponentMinHeight, 'max-height':this.gridProperties.ComponentMaxHeight}"
                #dataGrid
                [class]="this.gridProperties.isDetailComponent ? 'lobas-grid-height-unset' : 'lobas-grid-height'"
                [ngClass]="this.gridProperties.isAlignmentTopToBottom ? 'top-to-bottom-mode' : ''"
                [columnAutoWidth]="false" class="lobas-grid" (onCellDblClick)="onCellDblClick($event,'')" (onCellClick)="onCellClick($event,'')" (onAdaptiveDetailRowPreparing)="onAdaptiveDetailRowPreparing($event)"
                (onContentReady)="onContentReady($event)" [errorRowEnabled]="false" [filterSyncEnabled]="true"
                [dataSource]="dataSource" [showBorders]="false"
                [remoteOperations]="this.gridProperties.RemoteOperations" [cacheEnabled]="true"
                dataRowTemplate="{{dataRowTemplateName}}" (onExporting)="onExporting($event)"
                [allowColumnResizing]="false" (onInitNewRow)="onInitNewRow($event)"
                (onSelectionChanged)="selectionChanged($event)" [filterValue]="gridFilterValue" keyExpr="RecordId"
                (onRowValidating)="onRowValidating($event)" (onRowPrepared)="onRowPrepared($event)"
                [focusedRowEnabled]="false" (onEditorPrepared)="onEditorPrepared($event)"
                (onCellPrepared)="onCellPrepared($event)" (onEditorPreparing)="onEditorPreparing($event)"
                focusedRowKey="117" (onEditingStart)="onEditingStart($event)"
                [columnMinWidth]="50">

                <dxo-load-panel [enabled]="false" indicatorSrc="/" [shading]="false" [showPane]="false" text="">
                </dxo-load-panel>
                <dxo-editing [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false" [useIcons]="false"
                    [(editRowKey)]="editRowKey">
                    <dxo-popup [wrapperAttr]="{class: 'lobas-popup grid_addeditpopup'}" [title]="popuptitle"
                        [showTitle]="true" [showCloseButton]="true">
                    </dxo-popup>
                </dxo-editing>
                <dxo-sorting mode="multiple"></dxo-sorting>
                <dxo-filter-row [visible]="this.componentSettings?.Settings?.ShowFilterRow && showFilterSearch  ?? true"></dxo-filter-row>
                <dxo-selection mode="multiple" [showCheckBoxesMode]="gridProperties.showCheckBoxesMode"></dxo-selection>
                <dxo-scrolling [useNative]="true" *ngIf="!gridProperties.isDetailComponent" mode="virtual"
                    rowRenderingMode="standard"></dxo-scrolling>
                <dxo-export [formats]="['pdf','xlsx']" [enabled]="false"  [allowExportSelectedData]="true">
                </dxo-export>
                <dxo-search-panel [text]="searchPanelText"></dxo-search-panel>
                <dxo-group-panel [visible]="gridProperties.Grouping?.length >0 ? true:false"></dxo-group-panel>
                <div class="list_box_outer" *dxTemplate="let item of 'dataRowTemplate'"
                    (onItemDblClick)="selectContext($event)">
                    <ng-template
                        [ngTemplateOutlet]="this.templateName=='defaultItemTemplate'? defaultItemTemplate: externalItemTemplate"
                        [ngTemplateOutletContext]="{ item: item, listCardColumn:listCardColumn, burgerMenuActions:burgerMenuActions, 
                                gridProperties:gridProperties, isGridview:isGridview, rowClickOnlyAction:this.rowClickCommandAction}">
                    </ng-template>
                </div>

                <div *dxTemplate="let data of 'distCalEditTemplate'">
                    <div id="divParentDistanceCalculator" class="distance-calculator-box">
                        <dx-number-box format="#####.##"
                            (onValueChanged)="data.setValue($event.value?.toString() ?? '')"
                            [value]="data.row.data.DistanceTravelled">
                            <dxi-validation-rule [type]="'required'"
                                message="Commuting Mileage is Required"></dxi-validation-rule>
                        </dx-number-box>
                        <i id="iRouteIcon" class="fas fa-route" title="Calculate Distance"
                            (click)="this.mapService.calculateDistanceBetween(data.row.data, 'StartLocation','DestinationLocation', 'ReturnLocation', 'DistanceTravelled')"
                            aria-hidden="true"></i>
                    </div>
                </div>
                <div *dxTemplate="let data of 'groupCellTemplate'">
                    <div>{{data.value}}</div>
                </div>
                <div *dxTemplate="let data of 'fatigueIconTemplate'">
                    <div *ngIf="data.value == 2"><i class="icongreen fa-regular fa-thumbs-up"></i></div>
                    <i *ngIf="data.value == 1" class="iconred fa-regular fa-thumbs-down"></i>
                    <i  *ngIf="data.value == 0" class="iconyellow fa-regular fa-triangle-exclamation"></i>
                </div>
            </dx-data-grid>
            <app-validation-popup #validationpopup></app-validation-popup>
            <app-contact-details-popup #contactDetailtsPopup></app-contact-details-popup>
            <app-dblclk-columninfo-popup #cellInfoPopup></app-dblclk-columninfo-popup>
            <div class="burger-menu-overlay active-burgermenu-overlay" #burgerMenuOverlay
                [hidden]="!this.openBurgerMenu"></div>
            <div class="burger-menu active-burgermenu" [style]="burgerMenuPossiton" #burgerMenu
                [hidden]="!this.openBurgerMenu">
                <ul>
                    <li>
                        <dx-button (onClick)="burgerMenuClickClose()"
                            [elementAttr]="{ id: '', class: 'close-burger-menu'}" icon="fa-regular fa-xmark">
                        </dx-button>
                    </li>

                    <li *ngFor="let command of this.burgerMenuActions">
                        <dx-button [icon]="command.Icon" [text]="command.Name"
                            [elementAttr]="{ id: '{{command.Command}}', class: 'burger-menu-btn' }"
                            (onClick)="commandAction(command)">
                        </dx-button>
                    </li>
                </ul>
            </div>

            <div class="grid-footer" [hidden]="gridProperties.isDetailComponent && (!gridProperties.IsCommandButtonsVisible && gridProperties.Mode.toLowerCase() === 'view') || (!gridProperties.IsCommandButtonsVisible && gridProperties.Mode.toLowerCase() === 'edit')">
                <div [hidden]="this.gridProperties.isDetailComponent" class="grid-footer-left">
                    <p>
                        <span>Items: <strong>{{totalRows}}</strong></span>
                        <span [hidden]="this.selectedCount == 0 || gridProperties.showCheckBoxesMode.toLowerCase() === 'never'">Selected: <strong>{{selectedCount}}</strong></span>                        
                    </p>
                </div>
                <div class="grid-footer-right"
                    *ngIf="gridProperties.Mode.toLowerCase() === 'edit' || !gridProperties.isDetailComponent">
                    <div class="grid-footer-btn-outer" #commandCollectionDiv ng-repeat=""
                        *ngFor="let command of this.commandButtonActions">
                        <dx-button text={{command.Name}} [elementAttr]="{ id: '{{command.Command}}', class: '' }"
                            (onClick)="commandAction(command)">
                        </dx-button>
                    </div>
                </div>
            </div>
            <app-grid-menu-option-popup #gridMenuOptionPopup
                (GetUpdatedfilterBuilder)="UpdateFilterBuilderFields($event)" (IsResetGrid)="ResetGrid($event)"
                (ShowColumnChooser)="ShowColumnChooser($event)" (ExportGrid)="ExportGrid($event)"
                [searchPanelText]="searchPanelText" [filterBuilderFields]="filterBuilderFields"
                [isShowFilterRow]="this.componentSettings?.Settings?.ShowFilterRow ?? true"
                [filter]="this.dataGrid.filterValue"></app-grid-menu-option-popup>
        </div>
        <div [hidden]="NoDataInOffline">
            <app-offline-warrning></app-offline-warrning>
        </div>
    </div>

</div>

<ng-template #defaultItemTemplate let-item="item" let-listCardColumn="listCardColumn"
    let-burgerMenuActions="burgerMenuActions" let-gridProperties="gridProperties" let-isGridview="isGridview" ,
    burgerMenuPossiton="burgerMenuPossiton">
    <div class="list_box" [ngClass]="burgerMenuActions.length>0 ? 'list_box_with_brgntn' : ''">

        <dx-button *ngIf="!gridProperties.isDetailComponent && !isGridview && burgerMenuActions.length>0"
            id="btnCardActionMenu" (onClick)="ShowCurrentCardActionMenu($event,item)"
            [elementAttr]="{ class: 'burger-command-button'}" icon="fa-solid fa-ellipsis"></dx-button>
        <div class="burger-menu-overlay active-burgermenu-overlay" id="burgerMenuOverlay{{item.rowIndex}}"
            style="visibility: hidden;" #burgerMenuOverlay></div>
        <div class="burger-menu active-burgermenu" id="burgerMenu{{item.rowIndex}}" style="visibility: hidden;"
            [style]="burgerMenuPossiton" #CardburgerMenu>
            <ul>
                <li>
                    <dx-button (onClick)="CloseCardBurgerMenu(item)"
                        [elementAttr]="{ id: '', class: 'close-burger-menu'}" icon="fa-regular fa-xmark">
                    </dx-button>
                </li>

                <li *ngFor="let command of burgerMenuActions">
                    <dx-button [icon]="command.Icon" [text]="command.Name"
                        [elementAttr]="{ id: '{{command.Command}}', class: 'burger-menu-btn' }"
                        (onClick)="commandAction(command)">
                    </dx-button>
                </li>
            </ul>
        </div>
        <div class="list_box_col" *ngFor="let col of listCardColumn" (click)="onCellClick($event,item)">
            <div *ngIf="col.Definition.Visible">
                <div class="list_label">{{col.Definition.FieldUICaption ?? col.FieldName }}</div>
                <div [ngClass]="col.Definition.TruncatedText ? 'showtruncatedtext' : 'shownormaltext'" title={{formatData(item.data[col.FieldName],col.OverrideUIType)}} class="list_data"
                    *ngIf="col.OverrideUIType!=='EnumerationCombobox' && col.FieldName!='FileImg' && col.OverrideUIType.toLowerCase()!='usericon'  && col.OverrideUIType.toLowerCase()!='url'  && col.OverrideUIType.toLowerCase()!='textarea' && col.OverrideUIType!=='Relationship'">
                   {{formatData(item.data[col.FieldName], col.OverrideUIType) }}</div>
                <div [ngClass]="col.Definition.TruncatedText ? 'showtruncatedtext' : 'shownormaltext'" [title]="GetCardHtmlIconOrValue(item,col)" class="list_data" *ngIf="col.OverrideUIType==='EnumerationCombobox' || col.OverrideUIType==='Relationship' "
                    [innerHTML]="GetCardHtmlIconOrValue(item,col)"></div>
                <div [ngClass]="col.Definition.TruncatedText ? 'showtruncatedtext' : 'shownormaltext'" class="list_data" *ngIf="col.OverrideUIType.toLowerCase()==='usericon'"
                    [innerHTML]="GetUsersIcon(item,col.FieldName)"></div>
                <div [ngClass]="col.Definition.TruncatedText ? 'showtruncatedtext' : 'shownormaltext'" [title]="GetURLIcon(item,col.FieldName)" class="list_data" *ngIf="col.OverrideUIType.toLowerCase()==='url'"
                    [innerHTML]="GetURLIcon(item,col.FieldName)"></div>
                <div [ngClass]="col.Definition.TruncatedText ? 'showtruncatedtext' : 'shownormaltext'" [title]="formatData(item.data[col.FieldName], col.OverrideUIType)" class="list_data" *ngIf="col.OverrideUIType.toLowerCase()==='textarea'"
                    [innerHTML]="formatData(item.data[col.FieldName], col.OverrideUIType)"></div>
                <div [ngClass]="col.Definition.TruncatedText ? 'showtruncatedtext' : 'shownormaltext'" class="list_data" *ngIf="col.FieldName==='FileImg'" [innerHTML]="SetFile(item,col,false)"></div>
            </div>
            <div *ngIf="col.OverrideUIType=='empty'">
                <div class="list_label">&#160;</div>
                <div class="list_data">&#160;</div>
            </div>
        </div>
    </div>
</ng-template>

<app-bulk-enumeration-transition #bulkEnumerationTransitionPopup *ngIf="this.isBulkTransitionInstanceSet"  [instanceID]="this.gridProperties.BulkEnumerationTransitionInstanceID"></app-bulk-enumeration-transition>
