
<dx-popup [showTitle]="true" title="Worker Selection"
    [wrapperAttr]="{class: 'lobas-popup lobas-form-popup assign_contact' }" #WorkerSelectionTool
    [showTitle]="true" [resizeEnabled]="false" [dragEnabled]="false" [hideOnOutsideClick]="false"
    [showCloseButton]="true">
    <form method="post" class="wselection_form">
            <div class="form">
                <div class="custom-job-worker-info">
                    <p>
                        <span>Job - {{Description}}</span> 
                        <span>{{this.TradeName}} - {{StartDate}} - {{EndDate}}</span>
                    </p>
                </div>
                <div class="form-row">
                     <div class="field_group">
                        <div class="field_label">Worker</div>
                        <div class="field_control">
                            <div class="dx-field">
                                <div class="">     
                          <dx-select-box #WorkerSelectBox
                          [dataSource]="workerDataSource"
                          displayExpr="RecordLookupValue"
                          valueExpr="RecordID"
                          [searchExpr]="searchExprOption"
                          [searchTimeout]="searchTimeoutOption"
                          [minSearchLength]="minSearchLengthOption"
                          [searchEnabled]="true"
                          [showDataBeforeSearch]="showDataBeforeSearchOption">
                          
                    </dx-select-box>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-btn-outer">
                    <dx-button text="Assign Worker" (onClick)="Submit($event)" >
                    </dx-button>
                </div>
            </div>
    </form>
</dx-popup>