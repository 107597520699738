import { Component } from '@angular/core';
import { Guid } from 'guid-typescript';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';

@Component({
  selector: 'app-tester',
  templateUrl: './tester.component.html',
  styleUrls: ['./tester.component.scss']
})
export class TesterComponent {
  loggedinUserID: string = Guid.EMPTY;
  constructor(private eventQueue: LobasEventQueueService,private auth: AuthService) {
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
    this.loggedinUserID = this.auth.getGuidUserID() ?? '';
  }
}
