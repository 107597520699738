import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MenuService, MenuNavigation } from 'src/app/services/menuNavigation/menu.service';
import { BrandingModel, BrandingService } from 'src/app/services/branding/branding.service';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { EnumHelper } from 'src/app/util/enum-helper';
import { TranslateService } from '@ngx-translate/core';
import { locale, loadMessages } from "devextreme/localization";
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { AppEvent } from 'src/app/models/AppEvent';
import { AppEventType } from 'src/app/enums/appEventType';
import { UserIconImageService } from 'src/app/services/userIconImage/user-icon-image.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { EncryptionService } from 'src/app/encryption.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent extends PageBaseService implements OnInit {

  loginForm: FormGroup;
  password = '';
  public selectedDate: Date = new Date();
  public languageList: string[] = [];
  public loading: boolean = false;
  public usernameRequired;
  public passwordRequired;
  IsloginError: boolean = false;
  IsloginRequired: boolean = false;
  loginErrorMessage: string = '';
  loginRequiredMessage: string = '';
  isLoginForm: boolean = true;
  menuItems: MenuNavigation[];
  branding: BrandingModel;
  bgImage: string = '';
  isSessionExpired:boolean=false; 
  sessionExpiredErrorMessage:string;

  constructor(private eventQueue: LobasEventQueueService, 
    private localStorageService: LocalStorageService, 
    private auth: AuthService, private _router: Router, 
    private el: ElementRef, private menuService: MenuService, 
    public brandingService: BrandingService, 
    public translate: TranslateService,
    public userIconImageService:UserIconImageService,private _enc: EncryptionService) {
    super(_router,_enc);
    this.menuItems = this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.jsonMenu);
    this.branding = this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.brandingData,);
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, false));
    this.loginForm = new FormGroup({
      userName: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
    });
  }

  ngOnInit(): void {

    this.translate.get('UsernameRequired').subscribe((translated: string) => {
      this.usernameRequired = translated;
    });

    this.translate.get('PasswordRequired').subscribe((translated: string) => {
      this.passwordRequired = translated;
    });

    this.translate.get('ReloginTokenExpire').subscribe((translated: string) => {
      this.sessionExpiredErrorMessage = translated;
    });

    if(this.localStorageService.GetLocal(EnumHelper.LocalStorageKey.sessionExpired)!=null){
      this.isSessionExpired = true;
      this.localStorageService.ClearLocalStorageWithKey(EnumHelper.LocalStorageKey.sessionExpired);
    }
    this.el.nativeElement.querySelector(".customloader").style = 'display:none';
    if (this.auth.isLoggedIn()) {
      this._router.navigate(['home']);
    }
  }

  Reload() {
    window.location.href = window.location.href;
  }

  onSubmit(): void {
    if (this.loginForm.valid) {
      try {
        this.el.nativeElement.querySelector(".customloader").style = 'display:block';
        this.auth.login(this.loginForm.value).subscribe(
          data => {
            if (!data.error) {
              if (data.Message && data.Message.includes("Your login attempt was not successful. Please try again.")) {
                this.loginErrorMessage = "Your login attempt was not successful. Please try again.";
                this.IsloginError = true;
                this.IsloginRequired = false;
                this.el.nativeElement.querySelector(".customloader").style = 'display:none';
              }
              else if (data.Message && data.Message.includes("Your account is locked due to too many unsuccessful attempts. Contact your system administrator for assistance.")) {
                this.isLoginForm = false;
                this.el.nativeElement.querySelector(".customloader").style = 'display:none';
              }
              else {
                this.auth.setToken(data);
                this.userIconImageService.getContactProfileDocumentImages();

                var datalanguage = data.language;
                var languagesList = ['en-IN', 'en-US', 'en', 'en-GB']
                datalanguage = (data.language == '' || !languagesList.includes(data.language)) ? 'en-GB' : data.language;

                this.translate.use(datalanguage);
                locale(datalanguage);
                localStorage.setItem('UserLanguage', datalanguage);
                this.menuService.GetMenuItems(data.oldToken)
                  .then(async data => {
                    this.menuItems = data
                    this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.jsonMenu, this.menuItems)
                    if (environment.production) {
                      this.RedirectoLoginWithReturnUrl(true);
                    }
                    else {
                        var returnURL:string = await this.GetReturnURL();
                        if(!this.IsNullorEmpty(returnURL))
                        {
                          window.location.href = returnURL;
                        }
                        else
                        {
                          this._router.navigate(['/home']);
                        }
                    }
                  });
              }
            }
          },
          (error) => {
            var errMsg = error?.error?.Message;
            this.el.nativeElement.querySelector(".customloader").style = 'display:none';
          }
        );
      } catch (err: any) {
        this.translate.get('UsernamePasswordIncorrect').subscribe((translated: string) => {
          alert(translated);
        });
      }
    } else {
      this.IsloginRequired = true;
      this.IsloginError = false;
      this.loginRequiredMessage = "Please complete the required fields.";
    }
  }
}