<div class="webpart-card" [ngClass]="isFullscreenWebpart?'webpart-card-fullscreen':''">
    <div class="webpart-cardbody">
        <div class="webpart-card-header" [ngClass]="this.isVisible ? 'webpart-card-closed' : 'webpart-card-opened'">
            <div class="webpart-card-header-title">
                <span>Questionnaire Response</span>
            </div>
            <div class="webpart-card-header-action">
                <dx-button (onClick)="expandCollaspeWebpart()"
                    [icon]="isFullscreenWebpart ? 'fa-regular fa-window-restore' : 'fa-regular fa-window-maximize'"></dx-button>
                <dx-button hint="Info" icon="fa-regular fa-info-circle" (onClick)="InfoIconClick()"></dx-button>
                <dx-button (onClick)="expandCollaspeComponent()"
                    [icon]="isVisible ? 'fa-regular fa-angle-up' : 'fa-regular fa-angle-down'"
                    [hidden]="isFullscreenWebpart? true:false"></dx-button>
            </div>
        </div>
        <div class="infoicon_content" id="divInfoIcon" [hidden]="isVisibledivInfoIcon"></div>
        <div [hidden]="isVisible" class="webpart-scroll">
            <survey [model]="surveyModel"></survey>
        </div>

    </div>
</div>