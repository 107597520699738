import { Guid } from "guid-typescript"

export class NoteModel {
    RecordID:string = Guid.EMPTY;
    NoteType_EnumID:string = Guid.EMPTY;
    Note:string = '';
    ExtendedAttributes:string = '';
    Group:string =''
    RecordCreatedDate?: Date | null = new Date();
    RecordUpdatedDate?: Date | null;
    RecordUpdated_UserID?: string | null;
    RecordCreated_UserID?: string | null;
    CreatedUserIcon: string;
    UpdatedUserIcon: string;
    CreatedUsersName: string;
    UpdatedUsersName: string;
    RecordTenancyID: string;
    RecordLookupValue: string;
    RecordDescription: string;
    RecordDataObjectStatus_EnumID?: string | null;
    RecordStartDate?: Date | null;
    RecordEndDate?: Date | null;
  }