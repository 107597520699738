
//***************************This code has been generated using LOBAS Templates 4.0.23.0  

import { Component, ViewChild } from '@angular/core';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { GridComponent } from 'src/app/Components/common/grid/grid.component';

@Component({
  selector: 'app-objectrecord-detail',
  templateUrl: './objectrecord-detail.component.html',
  styleUrls: ['./objectrecord-detail.component.scss']
})
export class objectrecorddetailcomponent {
  componentObjectID: string;
  
  constructor(private eventQueue: LobasEventQueueService) {
    this.componentObjectID = "f98488ab-8dc8-4d7c-985c-78f76e7345ec";
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }
  gridCustomActions(data: any) {
    if (data != undefined && data != null) {
      switch (data.CommandObject.Command.toLowerCase()) {
        case "addCustomCasesHere":
              //Logical code.
          break;
      }
    }
  }
}