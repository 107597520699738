export class RecordRelationshipBindingModel {
    Object1_RecordID: string; 
    Object2_RecordID: string; 
    StartDate?: Date | null;
    EndDate?: Date | null;
    RelationshipName: string;
    PointInTimeValidations:string;
    MandatoryStartDate: boolean = false;
    MandatoryEndDate: boolean = false;
    AllowDuplicates:boolean = false;
}