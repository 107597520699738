import { Component,ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { QuestionnaireResponseViewerComponent } from '../questionnaire-response-viewer/questionnaire-response-viewer.component';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { EncryptionService } from 'src/app/encryption.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent extends PageBaseService {
  @ViewChild("questionnaireResponseViewer", { static: false }) questionnaireResponseViewer: QuestionnaireResponseViewerComponent
  tab1Index:number=0;
  constructor( private _router: Router, private _enc: EncryptionService,private alertService: AlertService,private translateService: TranslateService,private eventQueue:LobasEventQueueService,private localStorageService: LocalStorageService ){    
    super(_router, _enc);
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }

  async ngOnInit() 
  {
    this.tab1Index = this.GetTabIndex("tab1Index",this.localStorageService);
  }
    
  onTab1ItemClick(ev: any) {    
    this.SetTabIndex("tab1Index",ev?.itemIndex,this.localStorageService);
    }
  

  gridCustomActions(data: any) {
    switch (data.CommandObject.Command.toLowerCase()) {
       case "viewquestionnaireresponse":
          this.questionnaireResponseViewer.showPopup(data.SelectedRecords);
         break;
      default:
        this.translateService.get('CustomActionYetToImplemented', { title: data.CommandObject.Name }).subscribe((text: string) => {

          var InfoTitle:string="";
          var OkButtonText:string="";
          this.translateService.get('InfoTitle').subscribe((translated: string) => {
            InfoTitle = translated;
          });
          this.translateService.get('OkButtonText').subscribe((translated: string) => {
            OkButtonText = translated;
          });
          this.alertService.showAlertPopup(InfoTitle, text,'fa-solid fa-circle-info', OkButtonText);
        });
        break;
    }

  }
}