import { Injectable } from "@angular/core";
import { FieldSettings } from "./FieldSettings";
@Injectable({
    providedIn: 'root',
})

export class DocumentUploadComponentProperties {
    Title: string = ''
    AllowedRoles:string[] = []
    RelationshipID:string = ''
    HideWebPartHeader: boolean = false
    BindOnRelationshipOnly:string="false"
    FieldSettings: FieldSettings 
    DefaultDocumentType:string=''
    DocumentTypes:string=''
}

