import { Component, EventEmitter,ViewChild,Output } from '@angular/core';
import { DxDateBoxComponent, DxPopupComponent, DxValidationGroupComponent } from 'devextreme-angular';
import { ValidationPopupComponent } from 'src/app/Components/common/validation-popup/validation-popup.component';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { ShiftRequirementService } from 'src/app/services/ShiftRequirement/shift-requirement.service';

@Component({
  selector: 'app-change-planned-time-popup',
  templateUrl: './change-planned-time-popup.component.html',
  styleUrls: ['./change-planned-time-popup.component.scss']
})
export class ChangePlannedTimePopupComponent {

  @ViewChild('dxStartDate', { static: false }) dxStartDate: DxDateBoxComponent;
  @ViewChild('dxEndDate', { static: false }) dxEndDate: DxDateBoxComponent;
  @ViewChild("ChangePlannedTime", { static: false }) changePlannedTime: DxPopupComponent;
  @ViewChild('ChangePlannedTimeGroup', { static: false }) changePlannedTimeGroup: DxValidationGroupComponent
  @ViewChild("validationpopup") validationpopup: ValidationPopupComponent;
   StartDate: Date;
   EndDate: Date;
   SelectedRows:Array<string>;
   ParentRecordID:string
   ParentObjectID:string
   @Output() GridRefreshAction = new EventEmitter();
   constructor(private alertService:AlertService ,private shiftRequirementService:ShiftRequirementService){
  }
   openPopup(RecordID,data:any) {
    this.ParentRecordID=RecordID;
    this.SelectedRows= data.SelectedRecords;
    this.ParentObjectID=data.ObjectID;
    var SelectedRowsData= data.SelectedRowsData;
    this.dxStartDate.instance.reset();  
    this.dxEndDate.instance.reset(); 
    
    if(SelectedRowsData.length==1)
    {
      SelectedRowsData.forEach(element => {
        this.dxStartDate.value= element.PlannedStart;
        this.dxEndDate.value= element.PlannedEnd;
       });
       this.changePlannedTime.visible = true;
    }
    else{
      this.shiftRequirementService.GetJobRecord(this.ParentRecordID).then(
        response=>
        {
          var Result=JSON.parse(response);
          this.dxStartDate.value=Result.RecordStartDate;
          this.dxEndDate.value= Result.RecordEndDate;
          this.changePlannedTime.visible = true;
        });
    } 
   
  }

  closePopup() {
    this.changePlannedTime.visible = false;
  }

  Submit(e: any) {    
    
    const { isValid } =  this.changePlannedTimeGroup.instance.validate();    
    if (isValid) {
      this.shiftRequirementService.changedPlannedTime(this.ParentRecordID,this.SelectedRows,this.ParentObjectID,new Date(this.dxStartDate.value), new Date(this.dxEndDate.value)).then(
        response=>
        {
          this.GridRefreshAction.emit();
          this.closePopup();
        }).catch(error=>{
          this.closePopup();
          this.alertService.showAlertPopup('Error',error.error, 'fa-solid fa-xmark', 'OK');
        });
    }
    else{
      const brokenRules = e.validationGroup.validate().brokenRules;           
        this.validationpopup.showPopup(brokenRules.map((rule: any) => {
          return "<p>" + rule.message + "</p>"
        }).join(""));
    }
  }

  validateStartDateLessThanEndDate=(e) =>{     
        if(new Date(this.dxStartDate.value) >= new Date(this.dxEndDate.value)) {
          return false;  
        }      
    return true;
  }

}
