
//***************************This code has been generated using LOBAS Templates 4.0.23.0  

import { Component, ViewChild } from '@angular/core';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { GridComponent } from 'src/app/Components/common/grid/grid.component';

@Component({
  selector: 'app-uilayout-detail',
  templateUrl: './uilayout-detail.component.html',
  styleUrls: ['./uilayout-detail.component.scss']
})
export class uilayoutdetailcomponent {
  componentObjectID: string;
  
  constructor(private eventQueue: LobasEventQueueService) {
    this.componentObjectID = "45a7758d-2393-4bff-9437-f08ea563bf3c";
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }
  gridCustomActions(data: any) {
    if (data != undefined && data != null) {
      switch (data.CommandObject.Command.toLowerCase()) {
        case "addCustomCasesHere":
              //Logical code.
          break;
      }
    }
  }
}