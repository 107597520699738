import { Component } from '@angular/core';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { ComponentInfo } from 'src/app/models/ComponentInfo';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';

@Component({
  selector: 'app-document-details',
  templateUrl: './document-details.component.html',
  styleUrls: ['./document-details.component.scss']
})
export class DocumentDetailsComponent {
  ComponentObjectID: string;
  gridInfo1 = new ComponentInfo();

  constructor(private eventQueue:LobasEventQueueService){
    this.ComponentObjectID = 'C4A883E3-8729-4FA9-9FC3-1ED1AF5B5C96';
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }
}
