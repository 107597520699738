<app-breadcrumb [ComponentObjectID]="ComponentObjectID"></app-breadcrumb>

<div class="row webpartrow">
    <div class="col-lg-6 webpartcol screen-50">
        <div class="m-b-30">
            <!-- <app-grid  [IsUnauthorizedPage]="false" instanceID="NotificationRecipientDetails_NotificationDetail"></app-grid> -->
            <div class="webpart-card notif-rec-doc-single-notif-view">
                <div class="notif_row">
                    <div class="notif_col_right">
                        <app-notification-view #notificationDetail [suscribeToRibbonCommandTransitionAction]="true"></app-notification-view>
                    </div>
                </div>
            </div>
        </div>
        <div class="m-b-30">
            <app-ribbon-control *ngIf="parameterSet" #NotificationDocumentRibbonControl instanceID="NotificationRecipientDocumentRibbonControl"
                [parentRecordID]="this.recordID" [relationshipRecordID]="this.relationshipRecordID"
                [newID]="this.newID"></app-ribbon-control>
        </div>
    </div>
    <div class="col-lg-6 webpartcol screen-50">
        <div class="m-b-30 document-det-viewer">
            <app-document-assests-viewer-detail
                instanceID="NotificationRecipientDetails_DocumentAssetViewerDetail"></app-document-assests-viewer-detail>
        </div>
    </div>
</div>