import { Component, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ComponentInfo } from 'src/app/models/ComponentInfo';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/encryption.service';
import { CookieService } from 'ngx-cookie-service';
import { DocumentAssestsViewerPopupComponent } from '../../common/document-assests-viewer-popup/document-assests-viewer-popup.component';
import { Guid } from 'guid-typescript';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { DocumentUploadPopupComponent } from '../../common/document-upload-popup/document-upload-popup.component';
import { GridComponent } from '../../common/grid/grid.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Subscription, lastValueFrom } from 'rxjs';
import { ShiftReportViaJobURLModel } from 'src/app/models/ShiftReportViaJobURLModel';
import { RecordSearchComponent } from '../../common/record-search/record-search.component';
import { ObjectCommandModel } from 'src/app/models/ObjectCommandModel';
import { DistanceCalculationEngineComponent } from '../distance-calculation-engine/distance-calculation-engine.component';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { DeviceHelper } from 'src/app/util/device-Helper';
import { WorkerSelectionToolComponent } from '../../common/worker-selection-tool/worker-selection-tool.component';
@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent extends PageBaseService implements OnDestroy {
  @ViewChild("jobDetailDocViewPopup", { static: false }) jobDetailDocViewPopup: DocumentAssestsViewerPopupComponent;
  @ViewChild("jobDetailDocUploadPopup", { static: false }) jobDetailDocUploadPopup: DocumentUploadPopupComponent;
  @ViewChild("jobDetailDocumentViewPopup", { static: false }) jobDetailDocumentViewPopup: DocumentAssestsViewerPopupComponent;
  @ViewChild("jobDetailDocumentUploadPopup", { static: false }) jobDetailDocumentUploadPopup: DocumentUploadPopupComponent;
  @ViewChild("grdJobDetailEvidenceDocument", { static: true }) grdJobDetailEvidenceDocument: GridComponent;
  @ViewChild("grdJObDocumentGrid", { static: true }) grdJObDocumentGrid: GridComponent;
  @ViewChild("jobDetailsGrid", {static :false}) jobDetailsGrid:GridComponent
  @ViewChild('assetRecordSearchPopup', {static:false}) assetRecordSearchPopup : RecordSearchComponent;
  @ViewChild('distanceCalculationEnginepopup', {static:false}) distanceCalculationEngine : DistanceCalculationEngineComponent;
  @ViewChild('JobDetailworkerSelectionTool', {static:false}) JobDetailworkerSelectionTool : WorkerSelectionToolComponent;
  ComponentObjectID: string;
  jobDetails = new ComponentInfo();
  JobDetailsCloseCallgrid = new ComponentInfo();
  bigbuttonRedirectUrl: string;
  recordID: string = Guid.EMPTY;
  relationshipRecordID: string = Guid.EMPTY;
  parameterSet: Boolean = false;
  newID: string = Guid.EMPTY;
  loggedinUserID: string = ''
  parameterSetUserID: boolean = false;
  okAlertBtnTxt: string = "";
  errorAlertTitle: string = "";
  ribbonSubscription: Subscription;
  performRibbonCommandOperation: Subscription;
  NotificationId : string;
  isviewLoad :boolean = false;
  LoadWorkerselection:boolean=false;
  shiftReportRecordID : string = Guid.EMPTY;
  constructor(private httpClient: HttpClient, private eventQueue: LobasEventQueueService, private notificationService: NotificationService,private translateService: TranslateService, private alertService: AlertService, private location: Location, private _router: Router, private _enc: EncryptionService, private cookieService: CookieService, private auth: AuthService) {
    super(_router, _enc);
    this.loggedinUserID = this.auth.getGuidUserID() ?? '';
    if (this.loggedinUserID && this.loggedinUserID.length > 0) {
      this.parameterSetUserID = true;
    }
    this.ComponentObjectID = '5EF72874-C6A0-4B0B-8FA1-B69335C783E3';
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }

  ngOnInit() {
    this.translateService.get('OkButtonText').subscribe((translated: string) => {
      this.okAlertBtnTxt = translated;
    });

    this.translateService.get('warningText').subscribe((translated: string) => {
      this.errorAlertTitle = translated;
    });
    this.getAllQueryStrings();
    
    this.ribbonSubscription = this.eventQueue.on(AppEventType.RibbonTransitionClick).
    subscribe(event => this.onTransitionBtnClick(event.payload));

    this.performRibbonCommandOperation = this.eventQueue.on(AppEventType.PerformRibbonCommandOperation).
      subscribe(event => this.onRibbonCommandClick(event.payload));
  }
  
  async ngAfterViewInit() {   
  this.isviewLoad = true;
  }
  ngOnDestroy() {
    if(this.ribbonSubscription) {
      this.ribbonSubscription.unsubscribe();
    }
    if(this.performRibbonCommandOperation) {
      this.performRibbonCommandOperation.unsubscribe();
    }
  }
  async validateChildControls() {
    var isValid: boolean = false;
    return new Promise<boolean>(async (resolve, reject) => {
      var isjobDetailsGridValid: Boolean = await this.jobDetailsGrid?.isValidFormData();
      if (isjobDetailsGridValid) {
        isValid = true;
      }
      resolve(isValid);
      reject(false);
    });
  } 
  
  async onTransitionBtnClick(data: any) {
      var isValid = await this.validateChildControls();
      if (isValid) {
          this.jobDetailsGrid?.onRibbonTransitionClick(data);    
      }
  }

  async onRibbonCommandClick(command: ObjectCommandModel) {
    var isValid:boolean = true;
    if(command.Definition.PerformValidations){
        isValid = await this.validateChildControls();
    }
    if(isValid){
      this.jobDetailsGrid?.commandAction(command);  
    }    
  }

  goBackToPrevPage() {
    this.location.back();
  }

  DocumentgridCustomActions(data: any,isJobDocumentgrid=false) {
    if (data != undefined && data != null) {
      switch (data.CommandObject.Command.toLowerCase()) {
        case "view":
        case "viewdocument":
          if(data.SelectedRecords[0] != undefined && data.SelectedRecords[0] != null)
              this.jobDetailDocViewPopup.showDocumentAssetViewerPopup(true, data.SelectedRecords[0], "viewDocument", null);
          break;
        case "uploadpopup":
          if (data.CommandObject.Name.toUpperCase() == "EDIT") {
            if(isJobDocumentgrid){
              this.jobDetailDocumentUploadPopup.OpenUploadPopup(data.SelectedRecords[0], "editpopup", this.grdJObDocumentGrid);
            }else{
              this.jobDetailDocUploadPopup.OpenUploadPopup(data.SelectedRecords[0], "editpopup", this.grdJobDetailEvidenceDocument); 
            }    
          }
          else {
            if(isJobDocumentgrid){
              this.jobDetailDocumentUploadPopup.OpenUploadPopup(null, "newpopup", this.grdJObDocumentGrid);
            }
            else{
              this.jobDetailDocUploadPopup.OpenUploadPopup(null, "newpopup", this.grdJobDetailEvidenceDocument);
            }
            
          }
          break;
          case "downloaddocument":
            if(isJobDocumentgrid){ 
                this.jobDetailDocumentUploadPopup.downloadFile(data.SelectedRecords[0]);
            }
            else{
              this.jobDetailDocUploadPopup.downloadFile(data.SelectedRecords[0]);
            }
         
          break;
      }
    }

  }

  getAllQueryStrings() {
    this.GetKeyValueFromQueryString('recordID').then((recordID => {
      this.recordID = recordID;
      this.GetKeyValueFromQueryString('relationshipRecordID').then((relationshipRecordID => {
        this.relationshipRecordID = relationshipRecordID;
        this.GetKeyValueFromQueryString('newID').then((newID => {
          this.newID = newID;
          this.GetKeyValueFromQueryString('ShiftReportRecordID').then((ShiftReportRecordID => {
            this.shiftReportRecordID = ShiftReportRecordID;
            this.parameterSet = true;
            this.GetKeyValueFromQueryString('NRID').then((notificationId => {
              this.NotificationId = notificationId;
              if(this.NotificationId != "" && this.NotificationId != '' && this.NotificationId != null&& this.NotificationId != undefined ){
                //this.notificationService.updateNotificationReadStatus(this.NotificationId);
                this.notificationService.notificationUserAuthorised(this.NotificationId,this.loggedinUserID);
              }
          }))
        }))
      }));
      }));
    }));
  }
  async gridCustomActions(data: any) {
    switch (data.CommandObject.Command.toLowerCase()) {
      case "raiseclosecall":
        this.GetKeyValueFromQueryString('recordID').then((async recordID => {
          var queryStringParameters = 'newID=' + Guid.create() + '&jobID=' + recordID;
          if (data.CommandObject.Definition.VisibilityOption[0].ReturnUrlEnabled && data.CommandObject.Definition.VisibilityOption[0].ReturnUrlEnabled.toLowerCase() === "true") {
            queryStringParameters = queryStringParameters + "&" + "ReturnUrlEnabled=true" + "&" + "ReturnUrl=" + await this.GetDecryptedUrlForReturnUrl(window.location.href);
          }
          this.navigateTo('closecall-details',  queryStringParameters);
        }))
        break;
      case "updateshiftreport":
        this.performShiftReportBtnClick(data);
        break;
      case "newassettest":
        this.assetRecordSearchPopup.openPopup();
        break;
      case "gotoplanner":
          this.AddQuerystringParameterAndnavigateWithDecryptedUrl('job-planner', 'RecordID=' +  this.recordID);
          break;
      default:
        this.translateService.get('CustomActionYetToImplemented', { title: data.CommandObject.Name }).subscribe((text: string) => {         
          var InfoTitle:string="";
          this.translateService.get('InfoTitle').subscribe((translated: string) => {
          InfoTitle = translated;
          });
          var OkButtonText:string="";
          this.translateService.get('OkButtonText').subscribe((translated: string) => {
            OkButtonText = translated;
          });

          this.alertService.showAlertPopup(InfoTitle, text,'fa-solid fa-circle-info', OkButtonText);
        });
        break;
    }
  }


  performShiftReportBtnClick(data:any) {
    var isMobileDevice = false;
    if (DeviceHelper.isMobileDevice() || DeviceHelper.isTabletDevice()) {
        isMobileDevice = true;
    }
    var encryptedQueryParams;
    var apiURL = environment.apiUrl + 'api/shiftreport/GetShiftReportByJobIDAndLoggedInUser/' +
      this.recordID + '/' + this.loggedinUserID + '/true';
    var result = lastValueFrom(this.httpClient.get(apiURL)).then((async response => {
      var shiftObj: ShiftReportViaJobURLModel = <ShiftReportViaJobURLModel>response;
      if (shiftObj && shiftObj.IsShiftReportExist && shiftObj.Message.length > 0) {
        var RedirectURL =shiftObj.RedirectURL + "&ReturnUrl=" + await this.GetDecryptedUrlForReturnUrl(window.location.href);
        if(isMobileDevice){
          encryptedQueryParams= RedirectURL.substring(RedirectURL.indexOf('?')+1).replace(" ", "");
        }
        else{
          encryptedQueryParams = await this.encrypt.encryptionAES(RedirectURL.substring(RedirectURL.indexOf('?')+1).replace(" ", ""));
        }
       this._router.navigateByUrl(RedirectURL.split("?")[0] + "?d=" +  encryptedQueryParams );
      } else {
        this.alertService.showAlertPopup(this.errorAlertTitle, shiftObj.Message, 'fa-solid fa-triangle-exclamation', this.okAlertBtnTxt);
      }
    }));
  }

  OpenDistanceCalculationGrid(){
    this.distanceCalculationEngine.openPopup();
  }


  
}

