import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/encryption.service';
import { LocalStorageService } from '../localStorage/local-storage.service';
import { EnumHelper } from 'src/app/util/enum-helper';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { Device } from '@capacitor/device';
import { DeviceHelper } from 'src/app/util/device-Helper';

@Injectable({
  providedIn: 'root'
})
export class PageBaseService {
  queryString:string='';
  url:string='';
  parameterName = "?d=";
  currentURL: string = '';
  constructor(private router: Router, public encrypt: EncryptionService) {
    this.EncryptUrlAndReload();
  }

  async navigateTo(url: string, queryString: string) {
    var isMobileDevice = false;
    if (DeviceHelper.isMobileDevice() || DeviceHelper.isTabletDevice()) {
        isMobileDevice = true;
    }
    if (url && url.toLowerCase().includes("/lobas.web/pages")) {
      if (url.includes("?")) {
        window.location.href = window.location.origin + url + "&" + queryString;
      }
      else {
        window.location.href = window.location.origin + url + "?" + queryString;
      }
    }
    else {
      var urlQueryString = "";
      var UrlWithoutQueryString = url;
      if (url.includes("?")) {
        UrlWithoutQueryString  = url.split("?")[0];
        urlQueryString = url.substring(url.indexOf('?')+1);
      }      
      if(queryString!="" && queryString!=null) {
        if(urlQueryString!="")
        {
          urlQueryString = queryString + "&" + urlQueryString;
        }
        else
        {
          urlQueryString = queryString; 
        }
        
      }

      if(urlQueryString!="") {
        var encryptedQueryString ='';
        if(isMobileDevice){
          encryptedQueryString = urlQueryString;
          this.router.navigateByUrl(UrlWithoutQueryString + '?' + encryptedQueryString);
        }else{
          encryptedQueryString = await this.encrypt.encryptionAES(urlQueryString);
          this.router.navigateByUrl(UrlWithoutQueryString + '?d=' + encryptedQueryString);
        }
        
      }
      else {
        this.router.navigateByUrl(UrlWithoutQueryString);
      }
    }
  }


  

  navigate(url: string) {
    this.router.navigateByUrl(url);

  }


  async ReloadPage(url: string, queryString: string) {
    var qs;
    var isMobileDevice = false;
    if (DeviceHelper.isMobileDevice() || DeviceHelper.isTabletDevice()) {
        isMobileDevice = true;
    }
    if(isMobileDevice){
      qs= queryString
    }else{
      qs = await this.encrypt.encryptionAES(queryString);
    }
    window.location.href = window.location.origin + url + "?d=" + qs;
  }
 async ReloadPageShiftDetail(url: string, queryString: string) {
  var qs='';
  var isMobileDevice = false;
  if (DeviceHelper.isMobileDevice() || DeviceHelper.isTabletDevice()) {
      isMobileDevice = true;
  }
  if(isMobileDevice){
    qs= queryString
  }else{
    qs = await this.encrypt.encryptionAES(queryString);
  }
    console.log("Current route I am on:",this.router.url);
    const currentUrl=self ? this.router.url :"shift-details" + "?d=" + qs;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigateByUrl("shift-details" + "?d=" + qs);
    });
  }
  async ReloadComponent(url: string, queryString: string) {
    var qs;
    var isMobileDevice = false;
    if (DeviceHelper.isMobileDevice() || DeviceHelper.isTabletDevice()) {
        isMobileDevice = true;
    }
    if(isMobileDevice){
      qs = queryString;
    const currentUrl=self ? this.router.url :url + "?d=" + qs;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigateByUrl(url + "?d=" + qs);
    });
    }
    else{
      qs = await this.encrypt.encryptionAES(queryString);
    const currentUrl=self ? this.router.url :url + "?d=" + qs;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigateByUrl(url + "?d=" + qs);
    });
    }
      
  }
  EncryptUrlAndReload() {
    if (!window.location.href.includes(this.parameterName) && window.location.href.includes("?")) {
      this.RedirectToEncryptUrl(window.location.href);
    }
  }

  async RedirectToEncryptUrl(Completeurl: string) {
    var url="";
    if(Completeurl.includes(window.location.origin)){
      Completeurl= Completeurl.replaceAll(window.location.origin +"/AngularUI/","");
    }
    if(Completeurl.includes(this.parameterName))
      {
        //window.location.href =
        this.router.navigateByUrl(Completeurl);
        
      }
      else
      {     
        this.url = Completeurl.split('?')[0];
        this.queryString = this.GetDecryptedQueryString(Completeurl)//Completeurl.split('?')[1];
        if (this.queryString) {
          //window.location.href = 
          var isMobileDevice = false;
          if (DeviceHelper.isMobileDevice() || DeviceHelper.isTabletDevice()) {
              isMobileDevice = true;
          }
          if(isMobileDevice){
            url=this.url + "?" + this.queryString;
          }
          else{
            url=this.url + this.parameterName + await this.encrypt.encryptionAES(this.queryString);
          }

          if(window.location.pathname.includes(this.url)){
            this.ReloadComponent(this.url,this.queryString)
          }
          else{
            this.router.navigateByUrl(url);
          }
          

        }
        else {
          //window.location.href = 
          this.router.navigateByUrl(this.url);
        }
      }
  }

  async DecryptUrl(EncryptedUrl) {
    this.url = EncryptedUrl.split(this.parameterName)[0];
    this.queryString = EncryptedUrl.split(this.parameterName)[1];
    
    var isMobileDevice = false;
    if (DeviceHelper.isMobileDevice() || DeviceHelper.isTabletDevice()) {
        isMobileDevice = true;
    }
    if(isMobileDevice)
      return this.url + '?' + this.queryString;
    else
      return this.url + '?' + await this.encrypt.decryptionAES(this.queryString);
    
  }

  async GetKeyValueFromQueryString(key: string) {
    if (window.location.href.includes(this.parameterName)) {
      var KeyValue = await this.getParameterByName(key, await this.DecryptUrl(window.location.href));
      return KeyValue;
    }
    else if (window.location.href.includes("?")) {
      var KeyValue = await this.getParameterByName(key, window.location.href);
      return KeyValue;
    }
    else {
      return "";
    }
  }

  IsNullorEmpty(text: string) {
    if (text != null && text != undefined && text != '') {
      return false;
    }
    else {
      return true;
    }
  }

  GetFileExtension(fileName: string) {
    var GetFileExtension = fileName.split('?')[0].split('.').pop();
    return GetFileExtension
  }

  GetFileNameWithoutExtension(fileName: string) {
    var GetFileNameWithoutExtension = fileName.split('.').slice(0, -1).join('.');
    return GetFileNameWithoutExtension;
  }
  getParameterByName(paramName, url) {
    var isMobileDevice = false;
    if (DeviceHelper.isMobileDevice() || DeviceHelper.isTabletDevice()) {
        isMobileDevice = true;
    }
    
    let paramValue = '';
    var queryString = this.GetDecryptedQueryString(url) ///url.split('?')[1]
    if (url.includes('?')) {
      var httpParams = new HttpParams({ fromString: queryString });
      Object.entries(httpParams).forEach((data) => {
        if (data[0].toLowerCase() === "map") {
          Array.from(data[1].entries()).forEach(entry => {
            if (entry[0].toLowerCase() === paramName.toLowerCase()) {
              paramValue = entry[1][0];
            }
            else if(isMobileDevice && entry[0].toLowerCase().includes(paramName.toLowerCase()+"=")){
              paramValue= entry[0].split("=")[1];
            }
          });
        }
      });
    }
    return paramValue;
  }

  async GetReturnURL() {
    var isMobileDevice = false;
    if (DeviceHelper.isMobileDevice() || DeviceHelper.isTabletDevice()) {
        isMobileDevice = true;
    }
    if (window.location.href.includes("?")) {
      var url = "";
      if(window.location.href.includes(this.parameterName))
        {
          url= await this.DecryptUrl(window.location.href);
        }
        else{
          url = window.location.href;
        }
        var returnURLIndex = url.toLowerCase().indexOf("&returnurl=");
        if (returnURLIndex > 0) {
          var returnUrl = url.substring(returnURLIndex + 11, url.length);
          returnUrl = decodeURIComponent(returnUrl)
          var UrlWithouQueryString = returnUrl.split('?')[0];
          var queryString = this.GetDecryptedQueryString(returnUrl)//returnUrl.split('?')[1];
          if (queryString) {
            if(isMobileDevice){
              return UrlWithouQueryString +  this.parameterName + queryString;
            }
            return UrlWithouQueryString + this.parameterName + await this.encrypt.encryptionAES(queryString);
          }
          else {
            return returnUrl;
          }
      }
      else {
        return "";
      }
    }
    else {
      return "";
    }
  }

  async GetDecryptedUrlForReturnUrl(url) {
    this.url = url.split(this.parameterName)[0];
    this.queryString = url.split(this.parameterName)[1];1
    if (this.queryString) 
    {
      this.url = this.url + '?' + await this.encrypt.decryptionAES(this.queryString);
      return this.url;
    }
    else 
    {
      return this.url;
    }
  }


async AddQuerystringParameterAndnavigateWithDecryptedUrl(Completeurl: string, querystringParam) {
    this.queryString = this.GetDecryptedQueryString(Completeurl)//Completeurl.split('?')[1];
    if (this.queryString) {
      this.url = Completeurl.split('?')[0];
      this.queryString = querystringParam + '&' + this.queryString;
      this.navigateTo(this.url, this.queryString);
    }
    else {
      if (querystringParam) {
        this.navigateTo(Completeurl, querystringParam);
      }
      else {
        //        this.navigate(Completeurl);
        this.RedirectToEncryptUrl(Completeurl)
      }
    }
  }
  GetDecryptedQueryString(url:string)
  {   
    var queryStringArray = url.split('?');
    var newQueryString ="";
    for(let i =1 ; i< queryStringArray.length;i++)
      {
        if(i==1)
          {
            newQueryString =  queryStringArray[i];
          }
          else{
            newQueryString = newQueryString + "?" + queryStringArray[i];
          }
      }
    return newQueryString;
  }



  SetTabIndex(tabName,tabIndexValue,localStorage: LocalStorageService )
  {
    localStorage.SetLocal(EnumHelper.LocalStorageKey.pageTabSettings,tabIndexValue,[window.location.pathname.toLowerCase().replaceAll("/","_"),tabName.toLowerCase()]);
        
  }

  GetTabIndex(tabName,localStorage: LocalStorageService)
  {
    return ( (localStorage.GetLocal(EnumHelper.LocalStorageKey.pageTabSettings,[window.location.pathname.toLowerCase().replaceAll("/","_"),tabName.toLowerCase()])) ?  (Number)(localStorage.GetLocal(EnumHelper.LocalStorageKey.pageTabSettings,[window.location.pathname.toLowerCase().replaceAll("/","_"),tabName.toLowerCase()])) : 0);
  }

  hasPermission(allowedRole:string[], localStorageService:LocalStorageService):boolean {
    var isAllowed = false;
    var roles = localStorageService.GetLocal(EnumHelper.LocalStorageKey.userRoles);
    if(roles && roles!=null && roles.length>0) {
      roles = roles.toLowerCase();
      allowedRole.forEach((role)=>{
        if(roles.includes(role.toLowerCase())) {
          isAllowed = true;
        }
      });
    }
    return isAllowed;
  }
  

  async RedirectoLoginWithReturnUrl(isProduction = false)
  {
    var isMobileDevice = false;
    if (DeviceHelper.isMobileDevice() || DeviceHelper.isTabletDevice()) {
        isMobileDevice = true;
    }
     
    var decUrl ="";
    if(window.location.href.includes("?d="))
    {
      decUrl = await this.DecryptUrl(window.location.href);
      
    }
    else
    {
      decUrl =  window.location.href;
    }
    if(!isProduction)
    {
      if(isMobileDevice){
        window.location.href = window.location.origin + '/AngularUI/login'+ this.parameterName + "ReturnUrlEnabled=true&ReturnURL=" +decUrl; 
      }else{
        window.location.href = window.location.origin + '/AngularUI/login'+ this.parameterName + await this.encrypt.encryptionAES("ReturnUrlEnabled=true&ReturnURL=" +decUrl); 
      }
    }
    else{
      var returnURL = await this.GetReturnURL();
      if(returnURL.includes("?d="))
      {
        decUrl = await this.DecryptUrl(returnURL);
      }
      else
      {
        decUrl = returnURL;
      }

      if(isMobileDevice){
        window.location.href = window.location.origin + "/Lobas.Web/Pages/Account/Login.aspx"+ this.parameterName + "ReturnUrlEnabled=true&LoginReturnURL=" +decUrl; 
      }else{
        window.location.href = window.location.origin + "/Lobas.Web/Pages/Account/Login.aspx"+ this.parameterName + await this.encrypt.encryptionAES("ReturnUrlEnabled=true&LoginReturnURL=" +decUrl); 
      }
    }
  }

}


