<app-breadcrumb></app-breadcrumb>

<div class="calendar_container">
    <button type="button" class="filterbtn" onclick="filterBtn()">
        <i class="fa-regular fa-filter"></i>
    </button>
    <div class="calendar_filters" id="calendar-filter">
        <div class="calendar_filter_left">

            <div class="form-group">
                <input type="text" class="form-control" placeholder="Search by Worker" />
                <img src="assets/images/search.png" alt="Icon" />
            </div>
            <div class="form-group">
                <select class="form-control">
                    <option>Select Job</option>
                    <option>Amey Central</option>
                    <option>Balfer Cefa</option>
                    <option>Billy Anderson</option>
                    <option>Park Longman</option>
                    <option>Parkinsor Half</option>
                </select>
                <img src="assets/images/arrow-down.png" alt="Icon" />
            </div>
            <div class="form-group">
                <input type="submit" class="filter_search_btn" value="Search" />
            </div>
        </div>
    </div>
    <div class="calendar_body">
        <button class="clndr_prev_btn" (click)="clndr_prev_btn()" id="clndr_prev_btn" type="button"><i
                class="fa-regular fa-chevron-left" aria-hidden="true"></i></button>
        <button class="clndr_next_btn" (click)="clndr_next_btn()" id="clndr_next_btn" type="button"><i
                class="fa-regular fa-chevron-right" aria-hidden="true"></i></button>
        <div class="calendar_content" id="calendar_content">

            <div class="calendar_row calendar_header">

                <div class="calendar_column calendar_header_item">
                    <h3>Friday<strong>14/06/2023</strong></h3>
                </div>
                <div class="calendar_column calendar_header_item">
                    <h3>Saturday<strong>15/06/2024</strong></h3>
                </div>
                <div class="calendar_column calendar_header_item">
                    <h3>Sunday<strong>16/06/2024</strong></h3>
                </div>
                <div class="calendar_column calendar_header_item">
                    <h3>Monday<strong>17/06/2024</strong></h3>
                </div>
                <div class="calendar_column calendar_header_item">
                    <h3>Tuesday<strong>18/06/2024</strong></h3>
                </div>
                <div class="calendar_column calendar_header_item">
                    <h3>Wednesday<strong>19/06/2024</strong></h3>
                </div>
                <div class="calendar_column calendar_header_item">
                    <h3>Thursday<strong>20/06/2024</strong></h3>
                </div>
                <div class="calendar_column calendar_header_item">
                    <h3>Friday<strong>21/06/2024</strong></h3>
                </div>
                <div class="calendar_column calendar_header_item">
                    <h3>Saturday<strong>22/06/2024</strong></h3>
                </div>
                <div class="calendar_column calendar_header_item">
                    <h3>Sunday<strong>23/06/2024</strong></h3>
                </div>
                <div class="calendar_column calendar_header_item">
                    <h3>Monday<strong>24/06/2024</strong></h3>
                </div>
                <div class="calendar_column calendar_header_item">
                    <h3>Tuesday<strong>25/06/2024</strong></h3>
                </div>
                <div class="calendar_column calendar_header_item">
                    <h3>Wednesday<strong>26/06/2024</strong></h3>
                </div>
                <div class="calendar_column calendar_header_item">
                    <h3>Thursday<strong>27/06/2024</strong></h3>
                </div>
                <div class="calendar_column calendar_header_item">
                    <h3>Friday<strong>28/06/2024</strong></h3>
                </div>
                <div class="calendar_column calendar_header_item">
                    <h3>Saturday<strong>29/06/2024</strong></h3>
                </div>
                <div class="calendar_column calendar_header_item">
                    <h3>Sunday<strong>30/06/2024</strong></h3>
                </div>
                <div class="calendar_column calendar_header_item">
                    <h3>Monday<strong>01/07/2024</strong></h3>
                </div>
                <div class="calendar_column calendar_header_item">
                    <h3>Tuesday<strong>02/07/2024</strong></h3>
                </div>
                <div class="calendar_column calendar_header_item">
                    <h3>Wednesday<strong>03/07/2024</strong></h3>
                </div>
                <div class="calendar_column calendar_header_item">
                    <h3>Thursday<strong>04/07/2024</strong></h3>
                </div>
                <div class="calendar_column calendar_header_item">
                    <h3>Friday<strong>05/07/2024</strong></h3>
                </div>
            </div>


            <div class="calendar_row">

                <!-- <div class="calendar_column">
            <div class="calendar_user_item">
              <h4>Amey Central</h4>
            </div>
          </div> -->

                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column_group" data-company-name="Amey Central"
                    style="width: calc(12.5% * 2);min-width: calc(230px * 2);">
                    <div class="calendar_column" style="width: calc(100% / 2);">
                        <div class="calendar_data_item">
                            <div class="calendar_job_title job_border_green">
                                <h5 class="job_bg_green">
                                    <span class="open_job_popup" (click)="openJobviewPopup()">Canterbury Terrace</span>
                                    <div class="calendar_timings"><img src="assets/images/watch.png" alt="Icon" />20:00
                                        - 08:00</div>
                                    <marquee>Some notes recorded here needed for planning.</marquee>
                                </h5>
                                <div class="badge-btn-group">
                                    <div class="badge-btn-outer">
                                        <span>AP ( TBT )</span>
                                        <button type="button" (click)="openCommonPopup()"
                                            class="badge-btn">M.Brewer</button>
                                        <button type="button" (click)="openCommonPopup()"
                                            class="badge-btn badge-btn-red">D.Groom</button>
                                        <button type="button" (click)="openCommonPopup()"
                                            class="badge-btn">J.Tilley</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>NP</span>
                                        <button type="button" (click)="openCommonPopup()"
                                            class="badge-btn">R.Foley</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>EA</span>
                                        <button type="button" (click)="openCommonPopup()"
                                            class="badge-btn badge-btn-red">M.Farrel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="calendar_column" style="width: calc(100% / 2);">
                        <div class="calendar_data_item">
                            <div class="calendar_job_title job_border_yellow">
                                <h5 class="job_bg_yellow">
                                    <span class="open_job_popup" (click)="openJobviewPopup()">Canterbury Terrace</span>
                                    <div class="calendar_timings"><img src="assets/images/watch.png" alt="Icon" />21:00
                                        - 07:00</div>
                                </h5>
                                <div class="badge-btn-group">
                                    <div class="badge-btn-outer">
                                        <span>AP ( TBT )</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">M.Brewer</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">D.Groom</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">J.Tilley</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>NP</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">R.Foley</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>EA</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">M.Farrel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="calendar_column_group" data-company-name="Amey Central - Amey Central Planning"
                    style="width: calc(12.5% * 5);min-width: calc(230px * 5);">
                    <div class="calendar_column" style="width: calc(100% / 5);">
                        <div class="calendar_data_item">
                            <div class="calendar_job_title job_border_green">
                                <h5 class="job_bg_green">
                                    <span class="open_job_popup" (click)="openJobviewPopup()">Amey Central
                                        Planning</span>
                                    <div class="calendar_timings"><img src="assets/images/watch.png" alt="Icon" />07:00
                                        - 15:00</div>
                                </h5>
                                <div class="badge-btn-group">
                                    <div class="badge-btn-outer">
                                        <span>PLANNER</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">D.Ford</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="calendar_column" style="width: calc(100% / 5);">
                        <div class="calendar_data_item">
                            <div class="calendar_job_title job_border_yellow">
                                <h5 class="job_bg_yellow">
                                    <span class="open_job_popup" (click)="openJobviewPopup()">Amey Central
                                        Planning</span>
                                    <div class="calendar_timings"><img src="assets/images/watch.png" alt="Icon" />07:00
                                        - 15:00</div>
                                    <marquee>Lorem Ipsum is simply text of the printing and typesetting industry. Lorem
                                        Ipsum has been the industry's standard text.</marquee>
                                </h5>
                                <div class="badge-btn-group">
                                    <div class="badge-btn-outer">
                                        <span>PLANNER</span>
                                        <button type="button" class="badge-btn badge-btn-red"
                                            (click)="openCommonPopup()">D.Ford</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="calendar_column" style="width: calc(100% / 5);">
                        <div class="calendar_data_item">
                            <div class="calendar_job_title job_border_yellow">
                                <h5 class="job_bg_yellow">
                                    <span class="open_job_popup" (click)="openJobviewPopup()">Amey Central
                                        Planning</span>
                                    <div class="calendar_timings"><img src="assets/images/watch.png" alt="Icon" />07:00
                                        - 15:00</div>
                                </h5>
                                <div class="badge-btn-group">
                                    <div class="badge-btn-outer">
                                        <span>PLANNER</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">D.Ford</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="calendar_column" style="width: calc(100% / 5);">
                        <div class="calendar_data_item">
                            <div class="calendar_job_title job_border_yellow">
                                <h5 class="job_bg_yellow">
                                    <span class="open_job_popup" (click)="openJobviewPopup()">Amey Central
                                        Planning</span>
                                    <div class="calendar_timings"><img src="assets/images/watch.png" alt="Icon" />07:00
                                        - 15:00</div>
                                </h5>
                                <div class="badge-btn-group">
                                    <div class="badge-btn-outer">
                                        <span>PLANNER</span>
                                        <button type="button" class="badge-btn badge-btn-red"
                                            (click)="openCommonPopup()">D.Ford</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="calendar_column" style="width: calc(100% / 5);">
                        <div class="calendar_data_item">
                            <div class="calendar_job_title job_border_red">
                                <h5 class="job_bg_red">
                                    <span class="open_job_popup" (click)="openJobviewPopup()">Amey Central
                                        Planning</span>
                                    <div class="calendar_timings"><img src="assets/images/watch.png" alt="Icon" />07:00
                                        - 15:00</div>
                                </h5>
                                <div class="badge-btn-group">
                                    <div class="badge-btn-outer">
                                        <span>PLANNER</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">D.Ford</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column_group" data-company-name="Amey Central"
                    style="width: calc(12.5% * 2);min-width: calc(230px * 2);">
                    <div class="calendar_column" style="width: calc(100% / 2);">
                        <div class="calendar_data_item">
                            <div class="calendar_job_title job_border_green">
                                <h5 class="job_bg_green">
                                    <span class="open_job_popup" (click)="openJobviewPopup()">Canterbury Terrace</span>
                                    <div class="calendar_timings"><img src="assets/images/watch.png" alt="Icon" />20:00
                                        - 08:00</div>
                                    <marquee>Some notes recorded here needed for planning.</marquee>
                                </h5>
                                <div class="badge-btn-group">
                                    <div class="badge-btn-outer">
                                        <span>AP ( TBT )</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">M.Brewer</button>
                                        <button type="button" class="badge-btn badge-btn-red"
                                            (click)="openCommonPopup()">D.Groom</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">J.Tilley</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>NP</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">R.Foley</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>EA</span>
                                        <button type="button" class="badge-btn badge-btn-red"
                                            (click)="openCommonPopup()">M.Farrel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="calendar_column" style="width: calc(100% / 2);">
                        <div class="calendar_data_item">
                            <div class="calendar_job_title job_border_yellow">
                                <h5 class="job_bg_yellow">
                                    <span class="open_job_popup" (click)="openJobviewPopup()">Canterbury Terrace</span>
                                    <div class="calendar_timings"><img src="assets/images/watch.png" alt="Icon" />21:00
                                        - 07:00</div>
                                </h5>
                                <div class="badge-btn-group">
                                    <div class="badge-btn-outer">
                                        <span>AP ( TBT )</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">M.Brewer</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">D.Groom</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">J.Tilley</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>NP</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">R.Foley</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>EA</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">M.Farrel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>




            <div class="calendar_row">

                <!-- <div class="calendar_column">
            <div class="calendar_user_item">
              <h4>Balfour Cefa</h4>
            </div>
          </div> -->

                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column_group" data-company-name="Balfour Cefa"
                    style="width: calc(12.5% * 1);min-width: calc(230px * 1);">
                    <div class="calendar_column" style="width: calc(100% / 1);">
                        <div class="calendar_data_item">
                            <div class="calendar_job_title job_border_yellow">
                                <h5 class="job_bg_yellow">
                                    <span class="open_job_popup" (click)="openJobviewPopup()">Suffolk RD + Vermon
                                        Road</span>
                                    <div class="calendar_timings"><img src="assets/images/watch.png" alt="Icon" />21:00
                                        - 07:00</div>
                                </h5>
                                <div class="badge-btn-group">
                                    <div class="badge-btn-outer">
                                        <span>NP</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">D.Ford</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>AP</span>
                                        <button type="button" class="badge-btn badge-btn-red"
                                            (click)="openCommonPopup()">R.Foley</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column_group" data-company-name="Balfour Cefa"
                    style="width: calc(12.5% * 1);min-width: calc(230px * 1);">
                    <div class="calendar_column" style="width: calc(100% / 1);">
                        <div class="calendar_data_item">
                            <div class="calendar_job_title job_border_yellow">
                                <h5 class="job_bg_yellow">
                                    <span class="open_job_popup" (click)="openJobviewPopup()">Suffolk RD + Vermon
                                        Road</span>
                                    <div class="calendar_timings"><img src="assets/images/watch.png" alt="Icon" />21:00
                                        - 07:00</div>
                                </h5>
                                <div class="badge-btn-group">
                                    <div class="badge-btn-outer">
                                        <span>AP</span>
                                        <button type="button" class="badge-btn badge-btn-red"
                                            (click)="openCommonPopup()">R.Foley</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column_group" data-company-name="Balfour Cefa"
                    style="width: calc(12.5% * 1);min-width: calc(230px * 1);">
                    <div class="calendar_column" style="width: calc(100% / 1);">
                        <div class="calendar_data_item">
                            <div class="calendar_job_title job_border_red">
                                <h5 class="job_bg_red">
                                    <span class="open_job_popup" (click)="openJobviewPopup()">Suffolk RD + Vermon
                                        Road</span>
                                    <div class="calendar_timings"><img src="assets/images/watch.png" alt="Icon" />21:00
                                        - 07:00</div>
                                </h5>
                                <div class="badge-btn-group">
                                    <div class="badge-btn-outer">
                                        <span>NP</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">D.Ford</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>AP</span>
                                        <button type="button" class="badge-btn badge-btn-red"
                                            (click)="openCommonPopup()">R.Foley</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column_group" data-company-name="Balfour Cefa"
                    style="width: calc(12.5% * 1);min-width: calc(230px * 1);">
                    <div class="calendar_column" style="width: calc(100% / 1);">
                        <div class="calendar_data_item">
                            <div class="calendar_job_title job_border_red">
                                <h5 class="job_bg_red">
                                    <span class="open_job_popup" (click)="openJobviewPopup()">Suffolk RD + Vermon
                                        Road</span>
                                    <div class="calendar_timings"><img src="assets/images/watch.png" alt="Icon" />21:00
                                        - 07:00</div>
                                </h5>
                                <div class="badge-btn-group">
                                    <div class="badge-btn-outer">
                                        <span>AP</span>
                                        <button type="button" class="badge-btn badge-btn-green"
                                            (click)="openCommonPopup()">D.Ford</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="calendar_row">

                <!-- <div class="calendar_column">
            <div class="calendar_user_item">
              <h4>BAM Nuttal</h4>
            </div>
          </div> -->

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column_group" data-company-name="BAM Nuttal"
                    style="width: calc(12.5% * 3);min-width: calc(230px * 3);">
                    <div class="calendar_column" style="width: calc(100% / 3);">
                        <div class="calendar_data_item">
                            <div class="calendar_job_title job_border_yellow">
                                <h5 class="job_bg_yellow">
                                    <span class="open_job_popup" (click)="openJobviewPopup()">Mitre Bridge</span>
                                    <div class="calendar_timings"><img src="assets/images/watch.png" alt="Icon" />21:00
                                        - 05:00</div>
                                </h5>
                                <div class="badge-btn-group">
                                    <div class="badge-btn-outer">
                                        <span>AP ( TBT )</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">A.Adebayo</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">M.Patel</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>NP</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">A.Scott</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>EA</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">R.Brown</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">J.Kerr</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">C.Rothwell</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="calendar_column" style="width: calc(100% / 3);">
                        <div class="calendar_data_item">
                            <div class="calendar_job_title job_border_red">
                                <h5 class="job_bg_red">
                                    <span class="open_job_popup" (click)="openJobviewPopup()">Mitre Bridge</span>
                                    <div class="calendar_timings"><img src="assets/images/watch.png" alt="Icon" />21:00
                                        - 05:00</div>
                                    <marquee>Notes written by legal experts covering investment law and arbitration
                                        concepts.</marquee>
                                </h5>
                                <div class="badge-btn-group">
                                    <div class="badge-btn-outer">
                                        <span>AP ( TBT )</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">A.Adebayo</button>
                                        <button type="button" class="badge-btn badge-btn-red"
                                            (click)="openCommonPopup()">M.Brewer</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>NP</span>
                                        <button type="button" class="badge-btn badge-btn-red"
                                            (click)="openCommonPopup()">M.Patel</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>EA</span>
                                        <button type="button" class="badge-btn badge-btn-red"
                                            (click)="openCommonPopup()">J.Kerr</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">C.Rothwell</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">K.Smith</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="calendar_column" style="width: calc(100% / 3);">
                        <div class="calendar_data_item">
                            <div class="calendar_job_title job_border_green">
                                <h5 class="job_bg_green">
                                    <span class="open_job_popup" (click)="openJobviewPopup()">Mitre Bridge</span>
                                    <div class="calendar_timings"><img src="assets/images/watch.png" alt="Icon" />21:00
                                        - 05:00</div>
                                </h5>
                                <div class="badge-btn-group">
                                    <div class="badge-btn-outer">
                                        <span>AP ( TBT )</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">M.Brewer</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">T.Connelly</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>NP</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">M.Patel</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>EA</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">M.Connelly</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">J.Kerr</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">B.Wallhead</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column_group" data-company-name="BAM Nuttal"
                    style="width: calc(12.5% * 3);min-width: calc(230px * 3);">
                    <div class="calendar_column" style="width: calc(100% / 3);">
                        <div class="calendar_data_item">
                            <div class="calendar_job_title job_border_yellow">
                                <h5 class="job_bg_yellow">
                                    <span class="open_job_popup" (click)="openJobviewPopup()">Mitre Bridge</span>
                                    <div class="calendar_timings"><img src="assets/images/watch.png" alt="Icon" />21:00
                                        - 05:00</div>
                                </h5>
                                <div class="badge-btn-group">
                                    <div class="badge-btn-outer">
                                        <span>AP ( TBT )</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">A.Adebayo</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">M.Patel</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>NP</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">A.Scott</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>EA</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">R.Brown</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">J.Kerr</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">C.Rothwell</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="calendar_column" style="width: calc(100% / 3);">
                        <div class="calendar_data_item">
                            <div class="calendar_job_title job_border_red">
                                <h5 class="job_bg_red">
                                    <span class="open_job_popup" (click)="openJobviewPopup()">Mitre Bridge</span>
                                    <div class="calendar_timings"><img src="assets/images/watch.png" alt="Icon" />21:00
                                        - 05:00</div>
                                    <marquee>Notes written by legal experts covering investment law and arbitration
                                        concepts.</marquee>
                                </h5>
                                <div class="badge-btn-group">
                                    <div class="badge-btn-outer">
                                        <span>AP ( TBT )</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">A.Adebayo</button>
                                        <button type="button" class="badge-btn badge-btn-red"
                                            (click)="openCommonPopup()">M.Brewer</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>NP</span>
                                        <button type="button" class="badge-btn badge-btn-red"
                                            (click)="openCommonPopup()">M.Patel</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>EA</span>
                                        <button type="button" class="badge-btn badge-btn-red"
                                            (click)="openCommonPopup()">J.Kerr</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">C.Rothwell</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">K.Smith</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="calendar_column" style="width: calc(100% / 3);">
                        <div class="calendar_data_item">
                            <div class="calendar_job_title job_border_green">
                                <h5 class="job_bg_green">
                                    <span class="open_job_popup" (click)="openJobviewPopup()">Mitre Bridge</span>
                                    <div class="calendar_timings"><img src="assets/images/watch.png" alt="Icon" />21:00
                                        - 05:00</div>
                                </h5>
                                <div class="badge-btn-group">
                                    <div class="badge-btn-outer">
                                        <span>AP ( TBT )</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">M.Brewer</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">T.Connelly</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>NP</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">M.Patel</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>EA</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">M.Connelly</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">J.Kerr</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">B.Wallhead</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="calendar_row">

                <!-- <div class="calendar_column">
            <div class="calendar_user_item">
              <h4>Bridgeway Consulting Ltd</h4>
            </div>
          </div> -->

                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column_group" data-company-name="Bridgeway Consulting Ltd"
                    style="width: calc(12.5% * 1);min-width: calc(230px * 1);">
                    <div class="calendar_column" style="width: calc(100% / 1);">
                        <div class="calendar_data_item">
                            <div class="calendar_job_title job_border_green">
                                <h5 class="job_bg_green">
                                    <span class="open_job_popup" (click)="openJobviewPopup()">Manor Park</span>
                                    <div class="calendar_timings"><img src="assets/images/watch.png" alt="Icon" />21:00
                                        - 07:00</div>
                                </h5>
                                <div class="badge-btn-group">
                                    <div class="badge-btn-outer">
                                        <span>AP ( TBT )</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">S.Coker</button>
                                        <button type="button" class="badge-btn badge-btn-red"
                                            (click)="openCommonPopup()">T.Dersley</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">C.Duke</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">J.Maxwell</button>
                                        <button type="button" class="badge-btn badge-btn-red"
                                            (click)="openCommonPopup()">F.Page</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">L.Roberts</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">S.Ruston</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>NP</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">D.Pipkin</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>EA</span>
                                        <button type="button" class="badge-btn badge-btn-red"
                                            (click)="openCommonPopup()">B.Bishop</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">S.Foley</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">S.Holloway</button>
                                        <button type="button" class="badge-btn badge-btn-red"
                                            (click)="openCommonPopup()">D.Kirkby</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">S.Maxwell</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">M.Pipkin</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">M.Ruston</button>
                                        <button type="button" class="badge-btn badge-btn-red"
                                            (click)="openCommonPopup()">N.Ruston</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column_group" data-company-name="Bridgeway Consulting Ltd"
                    style="width: calc(12.5% * 1);min-width: calc(230px * 1);">
                    <div class="calendar_column" style="width: calc(100% / 1);">
                        <div class="calendar_data_item">
                            <div class="calendar_job_title job_border_yellow">
                                <h5 class="job_bg_yellow">
                                    <span class="open_job_popup" (click)="openJobviewPopup()">Manor Park</span>
                                    <div class="calendar_timings"><img src="assets/images/watch.png" alt="Icon" />21:00
                                        - 07:00</div>
                                </h5>
                                <div class="badge-btn-group">
                                    <div class="badge-btn-outer">
                                        <span>AP ( TBT )</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">S.Coker</button>
                                        <button type="button" class="badge-btn badge-btn-red"
                                            (click)="openCommonPopup()">T.Dersley</button>
                                        <button type="button" class="badge-btn badge-btn-red"
                                            (click)="openCommonPopup()">F.Page</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">L.Roberts</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">S.Ruston</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>NP</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">D.Pipkin</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>EA</span>
                                        <button type="button" class="badge-btn badge-btn-red"
                                            (click)="openCommonPopup()">D.Kirkby</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">S.Maxwell</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">M.Pipkin</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">M.Ruston</button>
                                        <button type="button" class="badge-btn badge-btn-red"
                                            (click)="openCommonPopup()">N.Ruston</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="calendar_row">

                <!-- <div class="calendar_column">
            <div class="calendar_user_item">
              <h4>Carlisle Support Service</h4>
            </div>
          </div> -->

                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column_group" data-company-name="Carlisle Support Service"
                    style="width: calc(12.5% * 1);min-width: calc(230px * 1);">
                    <div class="calendar_column" style="width: calc(100% / 1);">
                        <div class="calendar_data_item">
                            <div class="calendar_job_title job_border_green">
                                <h5 class="job_bg_green">
                                    <span class="open_job_popup" (click)="openJobviewPopup()">Inagestone ISO</span>
                                    <div class="calendar_timings"><img src="assets/images/watch.png" alt="Icon" />21:00
                                        - 07:00</div>
                                </h5>
                                <div class="badge-btn-group">
                                    <div class="badge-btn-outer">
                                        <span>AP ( TBT )</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">D.James</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>NP</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">B.Evans</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>EA</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">R.Vincent</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">A.Woodiwis</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column_group" data-company-name="Carlisle Support Service"
                    style="width: calc(12.5% * 1);min-width: calc(230px * 1);">
                    <div class="calendar_column" style="width: calc(100% / 1);">
                        <div class="calendar_data_item">
                            <div class="calendar_job_title job_border_red">
                                <h5 class="job_bg_red">
                                    <span class="open_job_popup" (click)="openJobviewPopup()">Inagestone ISO</span>
                                    <div class="calendar_timings"><img src="assets/images/watch.png" alt="Icon" />21:00
                                        - 07:00</div>
                                </h5>
                                <div class="badge-btn-group">
                                    <div class="badge-btn-outer">
                                        <span>AP ( TBT )</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">D.James</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>NP</span>
                                        <button type="button" class="badge-btn badge-btn-red"
                                            (click)="openCommonPopup()">B.Evans</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>EA</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">R.Vincent</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>



                <div class="calendar_column"><!--Blank--></div>



                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>


                <div class="calendar_column_group" data-company-name="Carlisle Support Service"
                    style="width: calc(12.5% * 2);min-width: calc(230px * 2);">
                    <div class="calendar_column" style="width: calc(100% / 2);">
                        <div class="calendar_data_item">
                            <div class="calendar_job_title job_border_yellow">
                                <h5 class="job_bg_yellow">
                                    <span class="open_job_popup" (click)="openJobviewPopup()">Inagestone ISO</span>
                                    <div class="calendar_timings"><img src="assets/images/watch.png" alt="Icon" />21:00
                                        - 07:00</div>
                                </h5>
                                <div class="badge-btn-group">
                                    <div class="badge-btn-outer">
                                        <span>AP ( TBT )</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">D.James</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>NP</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">B.Evans</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>EA</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">A.Woodiwis</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="calendar_column" style="width: calc(100% / 2);">
                        <div class="calendar_data_item">
                            <div class="calendar_job_title job_border_green">
                                <h5 class="job_bg_green">
                                    <span class="open_job_popup" (click)="openJobviewPopup()">Inagestone ISO</span>
                                    <div class="calendar_timings"><img src="assets/images/watch.png" alt="Icon" />21:00
                                        - 07:00</div>
                                </h5>
                                <div class="badge-btn-group">
                                    <div class="badge-btn-outer">
                                        <span>AP ( TBT )</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">D.James</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>NP</span>
                                        <button type="button" class="badge-btn badge-btn-red"
                                            (click)="openCommonPopup()">B.Evans</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>EA</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">R.Vincent</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>

            </div>

            <div class="calendar_row">

                <!-- <div class="calendar_column">
            <div class="calendar_user_item">
              <h4>Colas</h4>
            </div>
          </div> -->

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column_group" data-company-name="Colas"
                    style="width: calc(12.5% * 1);min-width: calc(230px * 1);">
                    <div class="calendar_column" style="width: calc(100% / 1);">
                        <div class="calendar_data_item">
                            <div class="calendar_job_title job_border_yellow">
                                <h5 class="job_bg_yellow">
                                    <span class="open_job_popup" (click)="openJobviewPopup()">Leigh Bridge</span>
                                    <div class="calendar_timings"><img src="assets/images/watch.png" alt="Icon" />06:00
                                        - 18:00</div>
                                </h5>
                                <div class="badge-btn-group">
                                    <div class="badge-btn-outer">
                                        <span>AP ( TBT )</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">G.Carter</button>
                                        <button type="button" class="badge-btn badge-btn-red"
                                            (click)="openCommonPopup()">G.Lever</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">A.Tinsley</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>NP</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">S.Foley</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>EA</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">J.Kerr</button>
                                        <button type="button" class="badge-btn badge-btn-red"
                                            (click)="openCommonPopup()">V.Tinsley</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">B.Wallhead</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column_group" data-company-name="Colas"
                    style="width: calc(12.5% * 1);min-width: calc(230px * 1);">
                    <div class="calendar_column" style="width: calc(100% / 1);">
                        <div class="calendar_data_item">
                            <div class="calendar_job_title job_border_red">
                                <h5 class="job_bg_red">
                                    <span class="open_job_popup" (click)="openJobviewPopup()">Leigh Bridge</span>
                                    <div class="calendar_timings"><img src="assets/images/watch.png" alt="Icon" />06:00
                                        - 18:00</div>
                                </h5>
                                <div class="badge-btn-group">
                                    <div class="badge-btn-outer">
                                        <span>AP ( TBT )</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">G.Carter</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>NP</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">S.Foley</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>EA</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">B.Wallhead</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>
                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column"><!--Blank--></div>

                <div class="calendar_column_group" data-company-name="Colas"
                    style="width: calc(12.5% * 2);min-width: calc(230px * 2);">
                    <div class="calendar_column" style="width: calc(100% / 2);">
                        <div class="calendar_data_item">
                            <div class="calendar_job_title job_border_green">
                                <h5 class="job_bg_green">
                                    <span class="open_job_popup" (click)="openJobviewPopup()">Leigh Bridge</span>
                                    <div class="calendar_timings"><img src="assets/images/watch.png" alt="Icon" />06:00
                                        - 18:00</div>
                                </h5>
                                <div class="badge-btn-group">
                                    <div class="badge-btn-outer">
                                        <span>AP ( TBT )</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">G.Carter</button>
                                        <button type="button" class="badge-btn badge-btn-red"
                                            (click)="openCommonPopup()">G.Lever</button>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">A.Tinsley</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>NP</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">S.Foley</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>EA</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">J.Kerr</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="calendar_column" style="width: calc(100% / 2);">
                        <div class="calendar_data_item">
                            <div class="calendar_job_title job_border_yellow">
                                <h5 class="job_bg_yellow">
                                    <span class="open_job_popup" (click)="openJobviewPopup()">Leigh Bridge</span>
                                    <div class="calendar_timings"><img src="assets/images/watch.png" alt="Icon" />06:00
                                        - 18:00</div>
                                </h5>
                                <div class="badge-btn-group">
                                    <div class="badge-btn-outer">
                                        <span>AP ( TBT )</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">G.Carter</button>
                                    </div>
                                    <div class="badge-btn-outer">
                                        <span>NP</span>
                                        <button type="button" class="badge-btn"
                                            (click)="openCommonPopup()">S.Foley</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>


<dx-popup container="" [dragEnabled]="false" [resizeEnabled]="false"
    [wrapperAttr]="{ id: 'profileview_popup', class: 'lobas-popup profileview_popup customview_popup' }"
    position="center" [(visible)]="isProfilePopupVisible">
    <!-- <dxi-toolbar-item text="Mark Brewer" location="before">
    </dxi-toolbar-item> -->
    <div class="profileview_popup small-popup-content">



        <div class="custom-popup__content">
            <div class="popup_content_right">
                <img class="worker-profile-pic" [src]="userImg" alt="Profile Image" />
                <h6>Mark Brewer</h6>
                <h5><i class="fa-regular fa-phone"></i>+44 7123 123123 </h5>
                <h5><i class="fa-regular fa-envelope"></i>dan&#64;work.com</h5>
                <h5><i><img src="/assets/images/sentinel.png"></i>1234567</h5>

                <!-- <h6>Contracted Hours</h6>
                <h5>35/140</h5>
                <h6>Trades</h6>
                <h5>NP, AP, EA, FA</h5> -->
            </div>
            <div class="popup_content_left">
                <h6>Shifts</h6>
                <div class="carouselouter">
                    <!-- <div class="shift-swiper swiper mySwiper">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <div class="swiper-card  shift-submitted">
                                    <div class="shift-swiper-heading">
                                        <span>115305 - OFFICE HOURS</span>
                                    </div>
                                    <div class="shift-swiper-body">
                                        <p>Wed 12/06/2024, 07:30</p>
                                        <p>Wed 12/06/2024, 15:30</p>
                                        <p>AA OFFICE HOURS</p>
                                    </div>
                                    <div class="shift-swiper-footer">
                                        Submitted
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="swiper-card  shift-submitted">
                                    <div class="shift-swiper-heading">
                                        <span>115305 - OFFICE HOURS</span>
                                    </div>
                                    <div class="shift-swiper-body">
                                        <p>Wed 12/06/2024, 07:30</p>
                                        <p>Wed 12/06/2024, 15:30</p>
                                        <p>AA OFFICE HOURS</p>
                                    </div>
                                    <div class="shift-swiper-footer">
                                        Submitted
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="swiper-card  shift-submitted">
                                    <div class="shift-swiper-heading">
                                        <span>115305 - OFFICE HOURS</span>
                                    </div>
                                    <div class="shift-swiper-body">
                                        <p>Wed 12/06/2024, 07:30</p>
                                        <p>Wed 12/06/2024, 15:30</p>
                                        <p>AA OFFICE HOURS</p>
                                    </div>
                                    <div class="shift-swiper-footer">
                                        Submitted
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->


                    <swiper-container class="shift-swiper" pagination="false" effect="coverflow" grab-cursor="true"
                        centered-slides="true" slides-per-view="auto" coverflow-effect-rotate="20"
                        coverflow-effect-stretch="0" initial-slide="3" coverflow-effect-depth="260"
                        coverflow-effect-modifier="2" coverflow-effect-slide-shadows="true">
                        <swiper-slide>
                            <div class="swiper-card  shift-submitted">
                                <div class="shift-swiper-heading">
                                    <span>115305 - OFFICE HOURS</span>
                                </div>
                                <div class="shift-swiper-body">
                                    <p>Wed 12/06/2024, 07:30</p>
                                    <p>Wed 12/06/2024, 15:30</p>
                                    <p>AA OFFICE HOURS</p>
                                </div>
                                <div class="shift-swiper-footer">
                                    Submitted
                                </div>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="swiper-card  shift-submitted">
                                <div class="shift-swiper-heading">
                                    <span>114031 - Manor Park HL</span>
                                </div>
                                <div class="shift-swiper-body">
                                    <p>Fri 05/06/2024 22:00</p>
                                    <p>Sat 06/06/2024 07:00</p>
                                    <p>FES FM Ltd</p>
                                </div>
                                <div class="shift-swiper-footer">
                                    Submitted
                                </div>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="swiper-card  shift-due">
                                <div class="shift-swiper-heading">
                                    <span>113789 - Grays BOND CHECK</span>
                                </div>
                                <div class="shift-swiper-body">
                                    <p>Mon 08/06/2024 08:00</p>
                                    <p>Mon 08/06/2024 16:00</p>
                                    <p>Octavirus infrastructure Ltd</p>
                                </div>
                                <div class="shift-swiper-footer">
                                    Report Due
                                </div>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="swiper-card  shift-future">
                                <div class="shift-swiper-heading">
                                    <span>113865 - Eurostar Training</span>
                                </div>
                                <div class="shift-swiper-body">
                                    <p>Thu 20/06/2024 08:00</p>
                                    <p>Thu 20/06/2024 16:00</p>
                                    <p>Krause - Hall Ltd</p>
                                </div>
                                <div class="shift-swiper-footer">
                                    Future
                                </div>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="swiper-card  shift-future">
                                <div class="shift-swiper-heading">
                                    <span>115312 - OFFICE HOURS</span>
                                </div>
                                <div class="shift-swiper-body">
                                    <p>Fri 21/06/2024, 07:30</p>
                                    <p>Fri 21/06/2024, 15:30</p>
                                    <p>AA OFFICE HOURS</p>
                                </div>
                                <div class="shift-swiper-footer">
                                    Future
                                </div>
                            </div>
                        </swiper-slide>

                    </swiper-container>

                </div>
                <!-- <div class="graph_outer">
                    <canvas style="width: 100%;" id="canvas">{{chart}}</canvas>
                </div> -->
                <div>
                    <h6>WTL & Fatigue Guide</h6>
                    <swiper-container class="fatigue-guide-swiper" pagination="false" navigation="true"
                        grab-cursor="true" centered-slides="true" slides-per-view="auto" initial-slide="3"
                        space-between=2>
                        <swiper-slide>
                            <div class="fatigue-guide-card">
                                <div class="fatigue-guide-btn fatigue-guide-btn-yellow">
                                    <i class="fa-regular fa-triangle-exclamation"></i>
                                </div>
                                <p>14/06/24</p>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="fatigue-guide-card">
                                <div class="fatigue-guide-btn fatigue-guide-btn-green">
                                    <i class="fa-regular fa-thumbs-up"></i>
                                </div>
                                <p>15/06/24</p>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="fatigue-guide-card">
                                <div class="fatigue-guide-btn fatigue-guide-btn-green">
                                    <i class="fa-regular fa-thumbs-up"></i>
                                </div>
                                <p>16/06/24</p>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="fatigue-guide-card">
                                <div class="fatigue-guide-btn fatigue-guide-btn-green">
                                    <i class="fa-regular fa-thumbs-up"></i>
                                </div>
                                <p>17/06/24</p>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="fatigue-guide-card">
                                <div class="fatigue-guide-btn fatigue-guide-btn-yellow">
                                    <i class="fa-regular fa-triangle-exclamation"></i>
                                </div>
                                <p>18/06/24</p>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="fatigue-guide-card">
                                <div class="fatigue-guide-btn fatigue-guide-btn-yellow">
                                    <i class="fa-regular fa-triangle-exclamation"></i>
                                </div>
                                <p>19/06/24</p>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="fatigue-guide-card">
                                <div class="fatigue-guide-btn fatigue-guide-btn-red">
                                    <i class="fa-regular fa-thumbs-down"></i>
                                </div>
                                <p>20/06/24</p>
                            </div>
                        </swiper-slide>
                    </swiper-container>
                </div>
                <div class="btn_grp">
                    <h6>Trades</h6>
                    <div class="btn_grp_box">
                        <span>Planner</span>
                        <span>COSS</span>
                        <span>NP</span>
                        <span>AP</span>
                    </div>
                </div>
            </div>

        </div>


    </div>
</dx-popup>


<dx-popup container="" [dragEnabled]="false" [resizeEnabled]="false"
    [wrapperAttr]="{id: 'jobview_popup', class: 'lobas-popup jobloc_popup customview_popup'}" position="center"
    [(visible)]="isJobviewPopupVisible">
    <dxi-toolbar-item text="111282 - Canterbury Terrace" location="before">
    </dxi-toolbar-item>
    <div class="profileview_popup small-popup-content">
        <div class="webpart-card">
            <div class="webpart-card-header">
                <div class="webpart-card-header-title">
                    <span>Job Details</span>
                </div>
                <div class="webpart-card-header-action">
                    <button class="edit_mode" *ngIf="!isFormEditable" (click)="toggleFormEditable()" type="button"><i
                            class="fa-regular fa-edit"></i></button>
                    <button class="show_mode" *ngIf="isFormEditable" (click)="toggleFormEditable()" type="button"><i
                            class="fa-regular fa-eye"></i></button>
                    <button class="webpart_card_toggler" type="button"><i class="fa-regular fa-chevron-up"></i></button>
                </div>
            </div>
            <div class="webpart-card-body">
                <form method="post" (submit)="handleSubmit($event)" *ngIf="isFormEditable">
                    <div class="form">
                        <div class="form-group-row">
                            <div class="field_group">
                                <div class="field_label required-label-asterisk">Department</div>
                                <div class="field_control">
                                    <dx-select-box displayExpr="Name" valueExpr="ID" [value]="0"
                                        [dataSource]="departmentList" [searchEnabled]="true"
                                        [inputAttr]="{ 'aria-label': 'Department' }">Planning
                                        <dx-validator>
                                            <dxi-validation-rule type="required"
                                                message="This field is required"></dxi-validation-rule>
                                        </dx-validator>
                                    </dx-select-box>
                                </div>
                            </div>

                            <div class="field_group">
                                <div class="field_label required-label-asterisk">Client</div>
                                <div class="field_control">
                                    <dx-select-box displayExpr="Name" valueExpr="ID" [value]="0"
                                        [dataSource]="clientList" [searchEnabled]="true"
                                        [inputAttr]="{ 'aria-label': 'Client' }">Network Rail - Lot 12
                                        <dx-validator>
                                            <dxi-validation-rule type="required"
                                                message="This field is required"></dxi-validation-rule>
                                        </dx-validator>
                                    </dx-select-box>
                                </div>
                            </div>

                            <div class="field_group">
                                <div class="field_label">Project</div>
                                <div class="field_control">
                                    <dx-select-box displayExpr="Name" valueExpr="ID" [value]="0"
                                        [dataSource]="projectList" [searchEnabled]="true"
                                        [inputAttr]="{ 'aria-label': 'Project' }">StoneBridge
                                    </dx-select-box>
                                </div>
                            </div>

                            <div class="field_group">
                                <div class="field_label required-label-asterisk">Start</div>
                                <div class="field_control">
                                    <dx-date-box value="2024-06-15T20:00:00" type="datetime"
                                        [inputAttr]="{ 'aria-label': 'Start' }">
                                        <dx-validator>
                                            <dxi-validation-rule type="required"
                                                message="This field is required"></dxi-validation-rule>
                                        </dx-validator>
                                    </dx-date-box>
                                </div>
                            </div>

                            <div class="field_group">
                                <div class="field_label required-label-asterisk">Finish</div>
                                <div class="field_control">
                                    <dx-date-box value="2024-06-16T08:00:00" type="datetime"
                                        [inputAttr]="{ 'aria-label': 'Finish' }">
                                        <dx-validator>
                                            <dxi-validation-rule type="required"
                                                message="This field is required"></dxi-validation-rule>
                                        </dx-validator>
                                    </dx-date-box>
                                </div>
                            </div>

                            <div class="field_group">
                                <div class="field_label">Site Contact</div>
                                <div class="field_control">
                                    <dx-text-box value="TBC" [inputAttr]="{ 'aria-label': 'Site Contact' }">
                                    </dx-text-box>
                                </div>
                            </div>

                            <div class="field_group">
                                <div class="field_label">Planning Notes</div>
                                <div class="field_control">
                                    <dx-text-area value="Client providing own NOM" height="130px"
                                        [inputAttr]="{ 'aria-label': 'Planning Notes' }">
                                    </dx-text-area>
                                </div>
                            </div>

                            <div class="field_group">
                                <div class="field_label">Worker Notes</div>
                                <div class="field_control">
                                    <dx-text-area value="Client providing own NOM" height="130px"
                                        [inputAttr]="{ 'aria-label': 'Worker Notes' }">
                                    </dx-text-area>
                                </div>
                            </div>
                            <div class="field_group">
                                <div class="field_label">Order No</div>
                                <div class="field_control">
                                    <dx-number-box [value]="1234" [inputAttr]="{ 'aria-label': 'Order No' }">1234
                                    </dx-number-box>
                                </div>
                            </div>
                        </div>
                        <div class="form-btn-outer">
                            <dx-button id="button" text="Cancel">
                            </dx-button>
                            <dx-button id="button" text="Save" [useSubmitBehavior]="true">
                            </dx-button>
                        </div>

                    </div>

                </form>
                <div class="webpart-detail-view" *ngIf="!isFormEditable">
                    <ul>
                        <li>
                            <label>Department</label>
                            <div class="detail_value">
                                Planning
                            </div>
                        </li>
                        <li>
                            <label>Client</label>
                            <div class="detail_value">
                                Network Rail - Lot 12
                            </div>
                        </li>
                        <li>
                            <label>Project</label>
                            <div class="detail_value">
                                StoneBridge
                            </div>
                        </li>
                        <li>
                            <label>Start</label>
                            <div class="detail_value">
                                15-Jun-2024 08:00 PM
                            </div>
                        </li>
                        <li>
                            <label>Finish</label>
                            <div class="detail_value">
                                16-Jun-2024 08:00 AM
                            </div>
                        </li>
                        <li>
                            <label>Site Contact</label>
                            <div class="detail_value">
                                TBC
                            </div>
                        </li>
                        <li>
                            <label>Planning Notes</label>
                            <div class="detail_value">
                                Client providing own NOM
                            </div>
                        </li>
                        <li>
                            <label>Worker Notes</label>
                            <div class="detail_value">
                                Client providing own NOM
                            </div>
                        </li>
                        <li>
                            <label>Order No</label>
                            <div class="detail_value">
                                1234
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="prfviewpop_row">
            <div class="prfviewpop_col50">
                <div class="webpart-card shiftplannergrid_outer">
                    <div class="webpart-card-header">
                        <div class="webpart-card-header-title">
                            <span>Shift Planner</span>
                        </div>
                        <div class="webpart-card-header-action">
                            <button class="webpart_card_toggler" type="button"><i
                                    class="fa-regular fa-chevron-up"></i></button>
                        </div>
                    </div>
                    <div class="webpart-card-body">
                        <div class="table-filters">
                            <form>
                                <div class="field_group">
                                    <div class="field_label">Shift Start</div>
                                    <div class="field_control">
                                        <dx-date-box value="2024-06-19T22:00:00" type="datetime"
                                            [inputAttr]="{ 'aria-label': 'Shift Start' }">
                                        </dx-date-box>
                                    </div>
                                </div>
                                <div class="field_group">
                                    <div class="field_label">Shift End</div>
                                    <div class="field_control">
                                        <dx-date-box value="2024-06-20T06:00:00" type="datetime"
                                            [inputAttr]="{ 'aria-label': 'Shift End' }">
                                        </dx-date-box>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <dx-data-grid [keyExpr]="'ID'" [allowColumnReordering]="false" #dataGrid
                            [showColumnHeaders]="true" class="lobas-grid" [dataSource]="shiftPlanner"
                            [columnHidingEnabled]="true" [showBorders]="false" [remoteOperations]="true"
                            keyExpr="RecordId">
                            <dxo-load-panel [enabled]="false" indicatorSrc="/" [shading]="false" [showPane]="true"
                                text="">
                            </dxo-load-panel>
                            <dxo-filter-row [visible]="true"></dxo-filter-row>
                            <dxo-group-panel [visible]="true"></dxo-group-panel>
                            <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
                            <dxi-column width="120px" dataField="Trade" cssClass="lobas-grid-td"></dxi-column>
                            <dxi-column width="200px" dataField="Workers" cssClass="lobas-grid-td"
                                cellTemplate="cellTemplate"></dxi-column>
                            <dxi-column width="150px" dataField="Actual Start" cssClass="lobas-grid-td"></dxi-column>
                            <dxi-column width="200px" dataField="Actual Finish" cssClass="lobas-grid-td"></dxi-column>
                            <div [ngClass]="data.value == 'Unfilled' ? 'red-col':'' || data.value == 'Steve Jones' ? 'green-col':'' " *dxTemplate="let data of 'cellTemplate'" class="grid-td-color">
                                <span (click)="show_worker_column()">{{data.value}}</span>
                            </div>
                            <dxi-column width="150px" dataField="Hold" cssClass="lobas-grid-td"></dxi-column>
                            <dxi-column [groupIndex]="0" width="0px" caption="" dataField="Person Team"
                                cssClass="lobas-grid-td"></dxi-column>
                        </dx-data-grid>
                        <div class="form-btn-outer">
                            <dx-button text="Add">
                            </dx-button>
                        </div>
                    </div>
                </div>


            </div>

            <div class="prfviewpop_col50">
                <div class="webpart-card location-webpart-card">
                    <div class="webpart-card-header">
                        <div class="webpart-card-header-title">
                            <span>Location</span>
                        </div>
                        <div class="webpart-card-header-action">
                            <button class="webpart_card_toggler" type="button"><i
                                    class="fa-regular fa-chevron-up"></i></button>
                        </div>
                    </div>
                    <div class="webpart-card-body">
                        <form>
                            <label for="location">Location</label>
                            <div class="location-icon-outer">
                                <input id="location" type="text" value="Meltings Road, Northamption, NT1 1AB"
                                    placeholder="Enter your location" />
                                <i class="fa-regular fa-location-crosshairs"></i>
                            </div>
                        </form>
                        <div class="map-iframe">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2481.9761537480676!2d-0.044335922977657316!3d51.53199720890561!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761d216001ae91%3A0x152b003de5a4f93f!2sEast%20London%20Liquor%20Company!5e0!3m2!1sen!2sin!4v1707740473862!5m2!1sen!2sin"
                                style="border:0;" allowfullscreen="" loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</dx-popup>

<dx-popup container="" [dragEnabled]="false" [resizeEnabled]="false" [showCloseButton]="false"
    [wrapperAttr]="{id: 'jobview_popup', class: 'lobas-popup worker_section customview_popup'}" position="center"
    [(visible)]="isWorkerPopupVisible">
    <div class="webpart-card">
        <div class="webpart-card-header">
            <div class="webpart-card-header-title">
                <span>Worker Selection</span>
            </div>
            <div class="webpart-card-header-action">
                <button (click)="hide_workerpop()" type="button"><i class="fa-regular fa-circle-xmark"
                        aria-hidden="true"></i></button>
            </div>
        </div>
        <div class="webpart-card-body">
            <div class="table-filters">
                <div class="table-filter-fields">
                    <h5 title="Trade"><i class="fa-regular fa-layer-group"></i> EA</h5>
                    <h5 title="Worker"><i class="fa-regular fa-user-helmet-safety"></i> UNFILLED</h5>
                </div>
                <form>
                    <input type="datetime-local" value="2024-06-15T20:00" class="form_control" />
                    <input type="datetime-local" value="2024-06-16T08:00" class="form_control" />
                </form>
            </div>
        </div>
    </div>
    <div class="webpart-card m-t-15">
        <div class="webpart-cardbody">
            <div class="webpart-card-header" [ngClass]="isVisible ? 'webpart-card-closed' : 'webpart-card-open'">
                <div class="webpart-card-header-title"></div>
                <div class="webpart-card-header-action">
                    <dx-button hint="Refresh" icon="fa-regular fa-arrows-rotate"></dx-button>
                    <dx-button hint="Filter Builder" icon="fa-regular far fa-filter"></dx-button>
                    <dx-button (onClick)="expandCollaspeComponent()"
                        [icon]="isVisible ? 'fa-regular fa-angle-up' : 'fa-regular fa-angle-down'"></dx-button>
                </div>
            </div>
            <div [hidden]="isVisible">
                <dx-data-grid [keyExpr]="'ID'" [allowColumnReordering]="true" #dataGrid [showColumnHeaders]="true"
                    class="close-call-dash-grid lobas-grid" [dataSource]="workerSelection" [columnHidingEnabled]="true"
                    [showBorders]="false" [remoteOperations]="true" keyExpr="RecordId">
                    <dxo-load-panel [enabled]="false" indicatorSrc="/" [shading]="false" [showPane]="true" text="">
                    </dxo-load-panel>
                    <dxo-filter-row [visible]="true"></dxo-filter-row>
                    <dxo-group-panel [visible]="true"></dxo-group-panel>
                    <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
                    <dxi-column width="200px" dataField="Name" cssClass="lobas-grid-td"></dxi-column>
                    <dxi-column width="120px" dataField="Fatigue" cssClass="lobas-grid-td"
                        cellTemplate="cellTemplate"></dxi-column>
                    <dxi-column width="150px" dataField="Hrs Rem" cssClass="lobas-grid-td"></dxi-column>
                    <dxi-column width="200px" dataField="Distance (miles)" cssClass="lobas-grid-td"></dxi-column>
                    <dxi-column width="120px" dataField="Time (mins)" cssClass="lobas-grid-td"></dxi-column>
                    <div *dxTemplate="let data of 'cellTemplate'" class="grid-td-fa-icon">
                        <i [ngClass]="data.value"></i>
                    </div>
                    <dxi-column [groupIndex]="0" width="0px" caption="" dataField="Status"
                        cssClass="lobas-grid-td"></dxi-column>
                </dx-data-grid>
            </div>
        </div>
    </div>
</dx-popup>