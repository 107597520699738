import { HttpClient, HttpParams, HttpXhrBackend } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { OfflineDataHelperService } from "./services/offlineDataHelper/offline-data-helper.service";

@Injectable({
  providedIn: "root"
})
export class EncryptionService {
  encURLresult:string;
  decURLresult:string;
  httpClient: HttpClient = new HttpClient(new HttpXhrBackend({
    build: () => new XMLHttpRequest()
  }));
  constructor(private offlineDataHelper: OfflineDataHelperService) { }

   async encryptionAES(text) {
    // Encrypt
    if(this.offlineDataHelper.isOnline){
      this.encURLresult = await this.GetEncryptedUrl(text);
    }
    if(this.encURLresult!=undefined && this.encURLresult!=null)
      {
        return this.encURLresult;
      }
      else
      {
        return "";
      }
  }

   async decryptionAES(text) {    
    if(this.offlineDataHelper.isOnline){
    this.decURLresult = await this.GetDecryptUrl(text);
    }
    return  this.decURLresult;
  }

    GetEncryptedUrl(text: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.httpClient.post(environment.apiUrl + "api/EncryptionDecryptionUrl/Encrypt/" ,{ text })
        .subscribe((response) => {
          //alert(response)
          resolve(<string>response);
        });
    });

  }

    GetDecryptUrl(text: string): Promise<string> {
    return  new Promise((resolve, reject) => {
      this.httpClient.post(environment.apiUrl + "api/EncryptionDecryptionUrl/Decrypt/" ,{text})
        .subscribe((response) => {
          resolve(<string>response);
        });
    });

  }
}
