export class UILayoutFieldModel{
    RecordID:string = '00000000-0000-0000-0000-000000000000'
    Version: number = 0
    FieldName:string = ''
    FieldGroup:string = ''
    OverrideUIType:string = ''
    TextAlign:string = ''
    ValidationExpression:string = ''
    Definition:Definition = new Definition();
    ExtendedAttributes:string = ''
    RecordCreatedDate:string = ''
    RecordUpdatedDate:string = ''
    RecordUpdated_UserID:string = ''
    RecordCreated_UserID:string = ''
    CreatedUserIcon:string = ''
    UpdatedUserIcon:string = ''
    CreatedUsersName:string = ''
    UpdatedUsersName:string = ''
    RecordTenancyID:string = ''
    RecordLookupValue:string = ''
    RecordDescription:string = ''
    RecordDataObjectStatus_EnumID:string = ''
    VisibleIndex: number = 0;

    public UILayoutFieldModel()
    {
        this.Definition = new Definition();
    }
}


export class Definition{
    AllowResize:boolean= false;
    DateOnlyFilter: boolean = false
    DefaultValue: string = ''
    Entity: string = ''
    FieldName: string = ''
    FieldUICaption: string = ''
    DBDataType: string = ''
    FieldLength: number = 0
    UITypes: number = 0
    EnumerationTypeDescription: string = ''
    NULLABLE: string = ''
    VisibleIndex: number = 0
    DBAreas: number = 0
    HasConstraint: boolean = false
    ConstraintDefaultValue: string = ''
    IsPrimaryKey: boolean = false
    IsUniqueKey: boolean = false
    PrimaryKeyType: string = ''
    IsLookField: boolean = false
    LookupField: string = ''
    ValidationRegularExpression: boolean = false
    FieldTables: number = 0
    FieldViews: number = 0
    IsDefaultValueViewField: boolean = false
    GroupByField: string = ''
    CsvColumnName: string = ''
    CsvColumnFormat: string = ''
    IsForeignKey: boolean = false
    ForeignKeyTableName: string = ''
    ForeignKeyColumnName: string = ''
    IsCollectableEnabled: boolean = false
    Visible: boolean = false
    ShowInCustomizationWindow: boolean = false
    ShowInCustomizationFilterBuilder: boolean = false
    HorizonalAlign: string = 'ltr'
    Mask: string = ''
    RegularExpressionValidator: string = ''
    Required: boolean = false
    AutoNumberName: string = ''
    ColumnWidth: number = 0
    DeviceWidth: number = 0
    AdaptivePriority: number = 0
    StartLocationFieldName: string = ''
    DestinationLocationFieldName: string = ''
    ReturnLocationFieldName: string = ''
    PlaceHolder: string = ''
    TimeZoneAdjusted: boolean = true;
    UIViewLargeText: boolean = false
    IsEditable:boolean = false
    UpdateNotAllowed:boolean = false;
    TruncatedText:boolean = true;
    InsertNotAllowed:boolean = false;
    ReadOnly:boolean=false
    ColumnColor: string = ''
    ValidationRules = null
    ShowEnumerationIcon: boolean = false
    ShowUserDetailsPopup:string = ''
    Format:string=''
    RelationshipID:string=""
    Derived:string=null
    OnValueChangedEvent:boolean=false
}
