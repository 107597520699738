import { ComponentPropertiesService } from 'src/app/services/componentProperties/component-properties.service';
import { GridComponent } from '../grid/grid.component';
import { booleanAttribute, Component, ComponentFactoryResolver, Input, ViewChild } from '@angular/core';
import { Tab, TabControlComponentProperties } from 'src/app/models/TabControlComponentProperties';
import { EnumHelper } from 'src/app/util/enum-helper';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import dxTabPanel from 'devextreme/ui/tab_panel';
import { dxItem } from 'devextreme/ui/widget/ui.widget';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/encryption.service';
import { ExtendedAttributesService } from 'src/app/services/extendedAttributes/extended-attributes.service';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { COMPONENTREGISTRY, registerComponent } from 'src/app/models/app-component-registry';
import { Guid } from 'guid-typescript';

@Component({
    selector: 'app-tab-control',
    templateUrl: './tab-control.component.html',
    styleUrls: ['./tab-control.component.scss']
})

export class TabControlComponent extends PageBaseService {
    componentID: string = 'TabControl'
    @Input() instanceID:string =  ''
    componentProperties: TabControlComponentProperties = null;
    windowPath:string = window.location.pathname.toLowerCase().replaceAll("/", "_")
    tabLocalStorageKey: string[];
    tab1Index:number=0;
    @ViewChild("tabPanel", { static: false }) tabPanel: dxTabPanel
    TabList: Tab[] = [];

    constructor(private localStorageService: LocalStorageService, 
         private _router: Router, 
        private _enc: EncryptionService,
        private extendedAttributesService: ExtendedAttributesService,
        private httpClient: HttpClient) {
        super(_router, _enc);
    }

    async ngOnInit() {
        this.componentProperties = <TabControlComponentProperties>this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.TabControlComponentProperties, [this.instanceID]) ?? new TabControlComponentProperties();
        if (this.componentProperties.TabList.length === 0 ) {
            await this.getComponentProperties();
            this.localStorageService.SetLocal(EnumHelper.LocalStorageKey.ExtendedAttributesComponentProperties, JSON.stringify(this.componentProperties), [this.instanceID])
          }
        this.generateTabs();
    }

    async getComponentProperties() {
        var componentUrl = "api/ComponentProperties/GetComponentPropertiesData?componentID=" + this.componentID + "&instanceID=" + this.instanceID;
        await lastValueFrom(
          this.httpClient.get(environment.apiUrl + componentUrl)
        ).then((response: any) => {
            this.setComponentProperties(response.Properties);
        });
      }


      setComponentProperties(response){
        var componentProperties = JSON.parse(response);
        componentProperties.TabProperties.forEach(componentProperty => {
          switch (componentProperty.key.toLowerCase()) {
            case "isheadervisible":
              this.componentProperties.IsHeaderVisible = componentProperty.value;
              break;
            case "title":
                this.componentProperties.Title = componentProperty.value;
              break;
            case "tablist":
                this.componentProperties.TabList = componentProperty.value;
              break;
          }
        });
      }

    generateTabs() {
        this.componentProperties.TabList.forEach((tab:Tab) => {
            if(this.hasPermission(tab.AllowedRole, this.localStorageService)) {
                tab.Component = COMPONENTREGISTRY.getTypeFor(tab.SubComponentType);
                this.TabList.push(tab);
            }
        });
        this.tab1Index = this.GetTabIndex("tab1Index",this.localStorageService);
    }

    onTab1ItemClick(ev: any) {
        this.SetTabIndex("tab1Index", ev?.itemIndex, this.localStorageService);
    }
}