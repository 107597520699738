<div class="webpart-card">
    <div class="webpart-cardbody">
        <div class="webpart-card-header" [ngClass]="isVisible ? 'webpart-card-closed' : 'webpart-card-open'">
            <div class="webpart-card-header-title">
                <span>24hr On Call</span>
            </div>
            <div class="webpart-card-header-action">
                <dx-button (onClick)="expandCollaspeComponent()"
                    [icon]="isVisible ? 'fa-regular fa-angle-up' : 'fa-regular fa-angle-down'"></dx-button>
            </div>
        </div>

        <div [hidden]="isVisible">
            <div class="assistance-card">
                <div class="assistance_content">
                    <p>Isolation South: <a href="tel:07398846242">07398846242</a></p>
                    <p>Isolation North: <a href="tel:07940990620">07940990620</a></p>
                    <p>Safety Critical: <a href="tel:07398846241">07398846241</a></p>
                </div>
            </div>
        </div>
    </div>
</div>