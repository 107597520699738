import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { UILayoutFieldModel } from 'src/app/models/UILayoutFieldModel';
import { ComponentInfo } from 'src/app/models/ComponentInfo';
import { LocalStorageService } from '../localStorage/local-storage.service';
import { EnumHelper } from 'src/app/util/enum-helper';
import { GridSettingsModel } from 'src/app/models/GridSettingsModel';
import { OfflineDataHelperService } from '../offlineDataHelper/offline-data-helper.service';
import { CookieService } from 'ngx-cookie-service';
import { DxDataGridComponent } from 'devextreme-angular';


@Injectable({
  providedIn: 'root'
})
export class GridHelperService {

  userID: string = ''
  tenancyID: string = ''
  constructor(private httpClient: HttpClient, private clientStorage: LocalStorageService, private cookieService: CookieService
    , private gridSettings: GridSettingsModel, private offlineDataHelper: OfflineDataHelperService) {
    this.userID = this.cookieService.get("UserID");
    this.tenancyID = this.cookieService.get("TenancyID");
  }

  async GetGridSettings(componentInfo: ComponentInfo) {
    return this.httpClient.post(environment.apiUrl + "api/UILayoutDefinition/GetUILayoutData/", componentInfo);
  }

  setCustomGridFilters(data:string, filterArray: any) {
    if (filterArray && filterArray.length > 0) {
      var jsonObj:any = data;
      if(typeof(data) === 'string'){
        jsonObj = JSON.parse(data);
      }
      jsonObj.filterValue = filterArray;
      return jsonObj;
    }
}



  GetGridColumns(uiFields: UILayoutFieldModel[]): any {
    var columns = uiFields.map(item => {
      switch (item.OverrideUIType.toLowerCase()) {
        // case 'action':
        //   return { caption: item.FieldName, dataType: 'string', fieldName: item.FieldName, visible: true, type:"buttons" }
        //   break;
        case 'datetime':
          return { caption: item.FieldName, dataType: 'datetime', fieldName: item.FieldName, visible: false }
          break;
        case 'date':
          return { caption: item.FieldName, dataType: 'date', fieldName: item.FieldName, visible: false }
          break;
        case 'checkbox':
        case 'radiobuttonyesno':
        case 'radiobuttontruefalse':
          return { caption: item.FieldName, dataType: 'boolean', fieldName: item.FieldName, visible: false }
          break;
        case 'enumeration':
        case 'EnumerationCombobox':
          return { caption: item.FieldName, dataType: 'string', fieldName: item.FieldName, visible: false }
          break;
        case 'Combobox':
          return { caption: item.FieldName, dataType: 'string', fieldName: item.FieldName, visible: false }
          break;
        default:
          return { caption: item.FieldName, dataType: 'string', fieldName: item.FieldName, visible: false }
          break;
      }
    })

    return columns;
  }


  loadState = async (gridID, IsUnauthorized) => {
    var GridSettingsUrl = "";
    if (IsUnauthorized) {
      GridSettingsUrl = 'api/UILayoutDefinitionUnauthorized/getGridSettings/';
    }
    else {
      GridSettingsUrl = 'api/UILayoutDefinition/getGridSettings/'
    }
    var settings = '';
    if (this.clientStorage.GetLocalStorage(EnumHelper.LocalStorageKey.gridSettings, [gridID, this.tenancyID])) {
      settings = JSON.parse(this.clientStorage.GetLocal(EnumHelper.LocalStorageKey.gridSettings, [gridID, this.tenancyID]));
    }
    else {
      var response = await this.httpClient.get(environment.apiUrl + GridSettingsUrl + gridID).toPromise();
      this.clientStorage.SetLocal(EnumHelper.LocalStorageKey.gridSettings, ((<GridSettingsModel>response).Settings), [gridID, this.tenancyID]);
      settings = JSON.parse((<GridSettingsModel>response).Settings);
    }


    return settings;

  }

  saveState = async (state, gridID, IsUnauthorizedPage, objectID) => {
    var currentState = this.clientStorage.GetLocal(EnumHelper.LocalStorageKey.gridSettings, [gridID, this.tenancyID]);
    state.selectedRowKeys = [];
    if(currentState != JSON.stringify(state)){  
      var apiEndPoint = "";
      if (IsUnauthorizedPage) {
        apiEndPoint = 'api/UILayoutDefinitionUnauthorized/UpcertGridSettings/';
      }
      else {
        apiEndPoint = 'api/UILayoutDefinition/UpcertGridSettings/';
      }
      var gridSettings = new GridSettingsModel();

      this.clientStorage.SetLocal(EnumHelper.LocalStorageKey.gridSettings, JSON.stringify(state), [gridID, this.tenancyID]);
      gridSettings.Settings = JSON.stringify(state);

      if (this.offlineDataHelper.isOnline) {
        await this.httpClient.post(environment.apiUrl + apiEndPoint + gridID, gridSettings,
          { responseType: 'text' }
        ).subscribe((response) => {
        });

      } else {
        var key: string = gridID + "_" + this.userID;
        this.offlineDataHelper.addChangeToQueue(apiEndPoint + gridID, 'post', gridSettings, key, objectID );
      }
    }

  }

  saveGridFilterRowState = async (IsShowFilterRow, gridID) => {

    this.clientStorage.SetLocal(EnumHelper.LocalStorageKey.gridSettings, JSON.stringify(IsShowFilterRow), ["FilterRow", gridID, this.tenancyID]);
    await this.httpClient.post(environment.apiUrl + 'api/UILayoutDefinition/UpdateFilterRowGridSettings/' + gridID, IsShowFilterRow)
      .subscribe((response) => { });
    this.clientStorage.ClearLocalStorageWithKey(EnumHelper.LocalStorageKey.gridSettings, [gridID, this.tenancyID]);

  }

  ResetGrid = async (gridID) => {


    await this.httpClient.post(environment.apiUrl + 'api/UILayoutDefinition/DeleteGridSettings/' + gridID, true)
      .subscribe((response) => { });
    this.clientStorage.ClearLocalStorageWithKey(EnumHelper.LocalStorageKey.gridSettings, [gridID, this.tenancyID]);

  }
  // GetActionColumn(): UILayoutFieldModel {
  //   return {
  //     RecordID: '00000000-0000-0000-0000-000000000000',
  //     Version: 0,
  //     FieldName: 'Action',
  //     FieldGroup: '',
  //     OverrideUIType: 'action',
  //     TextAlign: '',
  //     ValidationExpression: '',
  //     Definition: '',
  //     ExtendedAttributes: '',
  //     RecordCreatedDate: '',
  //     RecordUpdatedDate: '',
  //     RecordUpdated_UserID: '',
  //     RecordCreated_UserID: '',
  //     CreatedUserIcon: '',
  //     UpdatedUserIcon: '',
  //     CreatedUsersName: '',
  //     UpdatedUsersName: '',
  //     RecordTenancyID: '',
  //     RecordLookupValue: '',
  //     RecordDescription: '',
  //     RecordDataObjectStatus_EnumID: ''
  //   }
  // }
}
