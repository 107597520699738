import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { CookieService } from 'ngx-cookie-service';
import { DistanceModel } from 'src/app/models/DistanceModel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DistanceCalculateSignalrServiceService {
  
  hubConnection: signalR.HubConnection;
   tokenID:string;
    constructor(private cookieService: CookieService){
      const bearerTokenID = this.cookieService.get("BearerToken");
      this.tokenID = this.cookieService.get("TokenID");
      const customHeaders = {
        "Authorization": "Bearer " + bearerTokenID,
        "tokenID": this.tokenID,
        "Content-Type":"application/json"
       };  
       this.hubConnection = new signalR.HubConnectionBuilder().withUrl(environment.apiUrl+"DistanceHub",{
        withCredentials: true,
        headers:customHeaders
       }).configureLogging(signalR.LogLevel.Information)
       .withAutomaticReconnect()
       .build();

       this.hubConnection.on('CalculatedDistance', (distance: DistanceModel) => {
      
      });
      this.startConnection();
    }

    private startConnection() {
      this.hubConnection.start()
        .then(() => console.log('Connection started'))
        .catch(err => console.log('Error while starting connection: ' + err));
  
      this.hubConnection.onclose(error => {
        console.error('Connection closed due to error: ', error);
        this.startConnection();
      });
    }    

    public GetDistanceWithSourceAndDestination(sourcePostCode:string, destinationPostCode:string){
      this.hubConnection.invoke('GetDistanceWithSourceAndDestination',this.tokenID,sourcePostCode,destinationPostCode)
      .catch(err => console.error(err));
    }
    
    


}
