import { Injectable } from '@angular/core';
import { HttpHelperService } from '../httpHelper/http-helper.service'; 
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})


export class MenuService {
  constructor(private httpClient: HttpClient, private httpHelper: HttpHelperService, ) { }
  
  GetMenuItems(tokenId:string): Promise<MenuNavigation[]> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.apiUrl + 'api/sitemap/GetSiteMapMenuHierarchy/false/')
        .subscribe((response) => {
          resolve(<MenuNavigation[]>response);
        });
    });
  }

  GetDistinctMenuItems(tokenId:string): Promise<MenuNavigation[]> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.apiUrl + 'api/sitemap/GetSiteMapMenuHierarchy/true/')
        .subscribe((response) => {
          resolve(<MenuNavigation[]>response);
        });
    })
  }

  GetUserNotificationCount(tokenId: string): Promise<number> {
    if(tokenId.length >0){      
      return new Promise((resolve, reject) => {
        this.httpClient.get(environment.apiUrl + 'api/notification/NotificationCount/')
          .subscribe((response:any) => {
            resolve(<number>response.count);
          })
      })
    }else{
      return new Promise((resolve,reject)=>{
        return 20;
      })
  }
  }
  
}

export class MenuNavigation {
  id: string = '';

  name: string = '';

  url?: string = '';

  icon?: string;

  disabled?: boolean;

  items: MenuNavigation[] = [];
}

