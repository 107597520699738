<div class="webpart-card" [ngClass]="isVisibleNotes ? 'unset-webpart-height' : ''">
    <div class="webpart-cardbody" id="gridContainer"
        [ngClass]="this.ChatProperties.Mode.toLowerCase()=='chat' ? 'chat-webpart-cardbody' : ''">
        <div class="webpart-card-header" [hidden]="this.ChatProperties.Mode.toLowerCase()!='chat'"
            [ngClass]="isVisible ? 'webpart-card-closed' : 'webpart-card-open'">
            <div class="webpart-card-header-title">
                <span>{{this.ChatProperties.Title}}</span>
            </div>
            <div class="webpart-card-header-action">
                <dx-button (onClick)="expandCollaspeComponent()"
                    [icon]="isVisible ? 'fa-regular fa-angle-up' : 'fa-regular fa-angle-down'"></dx-button>
            </div>
        </div>
        <div [hidden]="this.ChatProperties.Mode.toLowerCase()!='chat' || isVisible" class="chat-body"
            [ngStyle]="{'min-height': this.ChatProperties.ComponentMinHeight, 'max-height':this.ChatProperties.ComponentMaxHeight}"
            id="msgContainer" #chatContainer>
            <div *ngFor="let note of listNoteModel">
                <div
                    *ngIf="note.RecordCreated_UserID.toLowerCase() !== this.loggedinUserID.toLowerCase(); else  rightdiv">
                    <div class="msg left-msg">
                        <div class="msg-image">
                            <div [hidden]='isUserImageAvailable(note.RecordCreated_UserID)' class="grid-user-icons">
                                {{this.getInitials(note.CreatedUsersName)}}</div>
                            <img [hidden]='!isUserImageAvailable(note.RecordCreated_UserID)'
                                src='{{this.getUserImage(note.RecordCreated_UserID,note.CreatedUsersName)}}' />
                        </div>
                        <div class="msg-bubble">
                            <div class="msg-text">
                                {{note.Note }}
                            </div>
                            <div class="msg-info">
                                <div class="msg-info-time"> {{formatData(note.RecordCreatedDate) }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #rightdiv>
                    <div class="msg right-msg">

                        <div class="msg-image">
                            <div [hidden]='isUserImageAvailable(note.RecordCreated_UserID)' class="grid-user-icons">
                                {{this.getInitials(note.CreatedUsersName)}}</div>
                            <img [hidden]='!isUserImageAvailable(note.RecordCreated_UserID)'
                                src='{{this.getUserImage(note.RecordCreated_UserID,note.CreatedUsersName)}}' />
                        </div>
                        <div class="msg-bubble">
                            <div class="msg-text">
                                <div [innerHTML]="note.Note"></div>
                            </div>
                            <div class="msg-info">
                                <div class="msg-info-time"> {{formatData(note.RecordCreatedDate) }}</div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
        <div [hidden]="this.ChatProperties.Mode.toLowerCase()=='chat' || isVisible"
            class="chatgridouter hideinnercomponentborder">
            <app-grid  [Paging]="true" (expandCollaspeChatComponent)="expandCollaspeChatComponent()" #notesGrid [Title]="this.ChatProperties.Title" [IsUnauthorizedPage]="false" instanceID="NotesGrid"
                [externalItemTemplate]="customItemTemplate" templateName="externalItemTemplate"></app-grid>
        </div>
        <div class="chat-footer" [hidden]="isVisible || isVisibleNotes">
            <div class="notes-grid-footer">
                <p><span>Items: <strong>{{listNoteModel.length}}</strong></span></p>
            </div>
            <form method="post">
                <div class="form">
                    <div class="field_control">
                        <dx-text-area #txtMsgBox height="100px" maxLength="1000" [inputAttr]="{ 'aria-label': 'Chat' }">
                        </dx-text-area>
                        <dx-button [elementAttr]="{ id: 'button', class: 'send-msg-btn' }"
                            icon="fa-regular fa-paper-plane" (onClick)="sendMessage()">
                        </dx-button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<ng-template #customItemTemplate let-item="item" let-listCardColumn="listCardColumn">
    <div class="people_boxouter">
        <div class="people_message">
            {{item.data["Note"]}}
        </div>
        <div class="people_boxouter_bottom">
            <div class="people_username">
                {{item.data["CreatedUsersName"] }}
            </div>
            <div class="people_time">
                {{formatData(item.data["RecordCreatedDate"]) }}
            </div>
        </div>
    </div>
</ng-template>