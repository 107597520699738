import { HttpClient } from '@angular/common/http';
import { XmlParser } from '@angular/compiler';
import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxButtonComponent, DxFileUploaderComponent, DxPopupComponent, DxValidationGroupComponent } from 'devextreme-angular';
import { SimplifiedSearchMode } from 'devextreme/common';
import { Guid } from 'guid-typescript';
import { EncryptionService } from 'src/app/encryption.service';
import { DocumentUploadComponentProperties } from 'src/app/models/DocumentUploadComponentProperties';
import { EnumerationModel } from 'src/app/models/EnumerationModel';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { DocumentModel, DocumentViewerService, ImageLocationModel, UploadDocumentModel } from 'src/app/services/documentViewer/document-viewer.service';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { EnumHelper } from 'src/app/util/enum-helper';
import { environment } from 'src/environments/environment';
import { ValidationPopupComponent } from '../validation-popup/validation-popup.component';
import { catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { Form } from '@angular/forms';
import { GridComponent } from '../grid/grid.component';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import * as fs from 'file-saver';
import { MapComponent } from '../map/map.component';
import * as saveAs from 'file-saver';
import dxValidationGroup from 'devextreme/ui/validation_group';

declare function SetLocation(Location): any;
@Component({
  selector: 'app-document-upload-popup',
  templateUrl: './document-upload-popup.component.html',
  styleUrls: ['./document-upload-popup.component.scss']
})
export class DocumentUploadPopupComponent extends PageBaseService {


  @ViewChild("documentUploadValidationpopup") validationpopup: ValidationPopupComponent;
  @ViewChild("DocumentUploadPopupUp", { static: false }) DocumentUploadPopupUp: DxPopupComponent;
  @ViewChild("fileUploader", { static: false }) fileUploader: DxFileUploaderComponent;
  @ViewChild("btnSave", { static: false }) btnSave: DxButtonComponent;
  @ViewChild("UploadDocumentFormValidationGroup", { static: false }) validationGroup: DxValidationGroupComponent;
  
  name: string = '';
  @Input() instanceID: string;
  recordDataObjectStatus_EnumID: string = '';
  map: MapComponent;
  yesButton: string = "";
  version: string = '';
  description: string = '';
  ddType_EnumID: string = '';
  fileName: string = '';
  validFrom: Date = new Date();
  validTo: Date = new Date();
  markAsPublic: boolean = false;
  searchModeOption: SimplifiedSearchMode = 'contains';
  searchExprOption = 'Value';
  searchTimeoutOption = 200;
  minSearchLengthOption = 0;
  showDataBeforeSearchOption = false;
  recordStatusList: EnumerationModel[] = [];
  documentTypeDataSourceList: EnumerationModel[] = [];
  fileExtensionList: EnumerationModel[] = [];
  documentTypesList: EnumerationModel[] = [];
  documentValue: File[] = [];
  docSaveApiUrl: string = ''
  componentID: string = 'documentUploadPopup'
  ShowMarkASPublic: Boolean = false;
  ShowName: Boolean = false;
  ShowRecordStatus: Boolean = false;
  ShowVersion: Boolean = false;
  ShowDescription: Boolean = false;
  ShowType: Boolean = false;
  ShowUploadFile: Boolean = false;
  ShowValidFrom: Boolean = false;
  ShowValidTo: Boolean = false;
  documentUploadPopupProperties: DocumentUploadComponentProperties;
  documentTypevalue: string = Guid.EMPTY;
  relationshipID: string = '';
  defaultDocumentType: string = '';
  title: string = '';
  documentTypes: string = '';
  fileReadOnlyMode: boolean = false;
  invalidFileExtensionMessage: string = ''
  invalidMaxFileSizeMessage: string = ''
  invalidMinFileSizeMessage: string = ''
  allowedFileExtensions: any;
  maxFileSize: number;
  CurrentrecordID: string;
  CurrentAction: string;
  documentUploadTempID: string;
  documentUploadModel: UploadDocumentModel;
  txtrecordDataObjectStatus_EnumID: string;
  documentGrid: GridComponent;
  isEditpopup:boolean=false;
  constructor(public translateService: TranslateService, private cookieService: CookieService, private docViewService: DocumentViewerService, private alertService: AlertService, private httpClient: HttpClient, private localStorageService: LocalStorageService, private _router: Router, private _enc: EncryptionService) {
    super(_router, _enc);
    this.recordStatusList = this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.enumerationList, ["Record Status"]);
    this.documentTypesList = this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.enumerationList, ["Document Type"]);
    this.fileExtensionList = this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.enumerationList, ["File Extension"]);
    this.documentTypeDataSourceList = this.documentTypesList;
    this.docSaveApiUrl = environment.apiUrl + "api/FileUpload/AsyncUpload";
    this.onUploadStarted = this.onUploadStarted.bind(this);
  }

  async ngOnInit() {
    this.translateService.get('YesButtonText').subscribe((translated: string) => {
      this.yesButton = translated;
    });
    this.documentUploadPopupProperties = <DocumentUploadComponentProperties>this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.documentUploadPopupComponentProperties, [this.instanceID]);
    if (this.documentUploadPopupProperties == null || this.documentUploadPopupProperties == undefined) {
      this.documentUploadPopupProperties = new DocumentUploadComponentProperties();
      this.GetComponentProperties();
    }

    if (this.recordStatusList == null || this.recordStatusList == undefined) {
      await this.GetEnumerationListByTypeDescription("Record Status", this.recordStatusList, "Record Status");

    }
    if (this.fileExtensionList == null || this.fileExtensionList == undefined) {
      await this.GetEnumerationListByTypeDescription("File Extension", this.fileExtensionList, "File Extension");

    }

    if (this.documentTypeDataSourceList == null || this.documentTypeDataSourceList == undefined) {
      await this.GetEnumerationListByTypeDescription("Document Type", this.documentTypesList, "Document Type");
      await this.GetEnumerationListByTypeDescription("Document Type", this.documentTypeDataSourceList, "Document Type");

    }
  }

  GetComponentProperties() {

    var ComponentUrl = "";
    ComponentUrl = "api/ComponentProperties/GetComponentPropertiesData?componentID=";
    this.httpClient.get(environment.apiUrl + ComponentUrl + this.componentID + "&instanceID=" + this.instanceID)
      .subscribe((response: any) => {
        this.setComponentProperties(response.Properties);
      });

  }

  setComponentProperties(componentProperties) {
    var docViewProperties = JSON.parse(componentProperties);
    docViewProperties.DocumentUploadPopupProperties.forEach(componentProperty => {
      switch (componentProperty.key.toLowerCase()) {
        case "title":
          this.documentUploadPopupProperties.Title = componentProperty.value;
          break;
        case "relationshipid":
          this.documentUploadPopupProperties.RelationshipID = componentProperty.value;
          break;
        case "bindonrelationshiponly":
          this.documentUploadPopupProperties.BindOnRelationshipOnly = componentProperty.value;
          break;
        case "allowedroles":
          this.documentUploadPopupProperties.AllowedRoles = componentProperty.value.split(',').map(x => x.toLowerCase());;
          break;
        case "fieldsettings":
          this.documentUploadPopupProperties.FieldSettings = JSON.parse(componentProperty.value);
          break;
        case "defaultdocumenttype":
          this.documentUploadPopupProperties.DefaultDocumentType = componentProperty.value;
          break;
        case "documenttypes":
          this.documentUploadPopupProperties.DocumentTypes = componentProperty.value;
          break;
      }
    });
    this.localStorageService.SetLocal(EnumHelper.LocalStorageKey.documentUploadPopupComponentProperties, JSON.stringify(this.documentUploadPopupProperties), [this.instanceID])
  }

  OpenUploadPopup(recordID: string, action: string, dataGrid: GridComponent, mapComponent: MapComponent = null) {
    this.recordStatusList = this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.enumerationList, ["Record Status"]);
    if (mapComponent) {
      this.map = mapComponent;
    }
    this.SetComponentFieldSettingsAndProperties(this.documentUploadPopupProperties, recordID, action);
    this.documentGrid = dataGrid;
  }

  SetComponentFieldSettingsAndProperties(documentUploadPopupProperties: DocumentUploadComponentProperties, recordID: string, action: string) {
    var Field = documentUploadPopupProperties.FieldSettings.Items;
    Field = Field.sort((a, b) => a.VisibleIndex - b.VisibleIndex);
    Field.forEach(x => {
      if (x.IsVisible) {
        if (x.IsVisible) {
          if (x.FieldName.toLowerCase() == "name") { this.ShowName = true }
          if (x.FieldName.toLowerCase() == "recordstatus") { this.ShowRecordStatus = true }
          if (x.FieldName.toLowerCase() == "version") { this.ShowVersion = true }
          if (x.FieldName.toLowerCase() == "description") { this.ShowDescription = true }
          if (x.FieldName.toLowerCase() == "type") { this.ShowType = true }
          if (x.FieldName.toLowerCase() == "uploadfile") { this.ShowUploadFile = true }
          if (x.FieldName.toLowerCase() == "validfrom") { this.ShowValidFrom = true }
          if (x.FieldName.toLowerCase() == "validto") { this.ShowValidTo = true }
          if (x.FieldName.toLowerCase() == "markaspublic") { this.ShowMarkASPublic = true }
        }
      }
    });
    this.relationshipID = documentUploadPopupProperties.RelationshipID;
    this.defaultDocumentType = documentUploadPopupProperties.DefaultDocumentType;
    this.title = documentUploadPopupProperties.Title;
    this.documentTypes = documentUploadPopupProperties.DocumentTypes;
    if (this.BindDocumentTypeDDl(this.documentTypes)) {
      this.PerformAction(recordID, action);
    }


  }
  async PerformAction(recordID: string, action: string) {
    this.CurrentrecordID = recordID;
    switch (action.toLowerCase()) {
      case "uploadpopup":
      case "newpopup":
        this.isEditpopup=false;
        this.NewUploadDocument();
        if (!this.IsNullorEmpty(this.defaultDocumentType)) {
          this.setValidation(this.defaultDocumentType);
          // ASPxUploadDocument.ClientSideEvents.Init = "function(s, e) { openFileDialog(s, e, '" + ASPxUploadDocument.ClientID + "'); }";

        }
        else {
          this.ddType_EnumID = null;
        }
        break;
      case "editpopup":
        if (!this.IsNullorEmpty(recordID) && recordID != Guid.EMPTY) {
          this.CurrentAction = "editpopup";
          this.EditUploadedDocument(recordID);
        }
        break;

      case "setprofilepicture":
        //EnumerationCL enumerationCL = new EnumerationCL();
        this.isEditpopup=false;
        var quesryStringRecordID = await this.GetKeyValueFromQueryString("RecordID");
        var MyprofileImageDocumentTypeEnumID = this.GetEnumID("My Profile Image");
        if (this.ddType_EnumID != null && this.ddType_EnumID == MyprofileImageDocumentTypeEnumID && !this.IsNullorEmpty(quesryStringRecordID)) {
          this.CurrentrecordID = MyprofileImageDocumentTypeEnumID;
        }
        if (this.CurrentrecordID != Guid.EMPTY) {
          //Edit Existing upload.
          this.EditUploadedDocument(this.CurrentrecordID);
        }
        else {
          //New Upload
          this.NewUploadDocument();
          if (!this.IsNullorEmpty(this.defaultDocumentType)) {
            this.setValidation(this.defaultDocumentType);
            // ASPxUploadDocument.ClientSideEvents.Init = "function(s, e) { openFileDialog(s, e, '" + ASPxUploadDocument.ClientID + "'); }";
          }
          else {
            this.ddType_EnumID = null;
          }
        }

        break;

    }
  }
  GetEnumID(value: string): string {
    if (this.documentTypesList != null && this.documentTypesList != undefined) {
      var EnumerationId = '';
      this.documentTypesList.forEach(y => {
        if (value.trim().toUpperCase() == y.Value.trim().toUpperCase()) {
          EnumerationId = y.EnumerationId;
        }
      })
      return EnumerationId;
    }
    else {
      return "";
    }
  }
  NewUploadDocument() {
    this.CurrentAction = "newpopup";
    this.cookieService.delete("UploadedFileName");
    this.documentUploadModel = new UploadDocumentModel();
    this.documentUploadModel.RecordID = Guid.create().toString();
    this.description = "";
    this.title = "Add New";
    this.validFrom = null;
    this.validTo = null;
    this.markAsPublic = false;
    this.name = '';
    this.version = "";
    this.recordDataObjectStatus_EnumID = null;
    this.setDefaultStatus();
    this.DocumentUploadPopupUp.visible = true;
    if (this.IsNullorEmpty(this.defaultDocumentType)) {
      this.ddType_EnumID = null;
    }
    if (this.documentTypeDataSourceList.length > 1) {
      this.ddType_EnumID = null;
    }
    this.documentValue = [];
    this.validationGroup.instance.reset();
  }
  setDefaultStatus() {
    if (this.recordDataObjectStatus_EnumID == null) {
      this.recordDataObjectStatus_EnumID = "00000000-0000-0001-3500-000000000002";
    }
  }
  async EditUploadedDocument(recordID: string) {
    this.cookieService.delete("UploadedFileName");
    var documentRelationshipView = await this.docViewService.GetRelatedDocument(this.CurrentrecordID, "10BDB7AC-D8B0-4C78-9B32-467E69E9E943");
    var documentRecord = await this.docViewService.GetDocumentRecord(recordID);
    this.recordDataObjectStatus_EnumID = documentRecord.RecordDataObjectStatus_EnumID;
    this.title = "Edit";
    this.documentUploadModel = new UploadDocumentModel();
    this.documentUploadModel.RecordID = documentRecord.RecordID;
    this.documentUploadModel.Path = documentRecord.Path;
    this.isEditpopup=true;
    if (documentRecord.RecordDataObjectStatus_EnumID != "00000000-0000-0001-3500-000000000004") {
      this.name = documentRecord.Name;
      this.version = documentRecord.Version;
      this.description = documentRecord.Description;
      this.markAsPublic = documentRecord.IsPublic;
      if (documentRecord.RecordStartDate != null) {
        this.validFrom = documentRecord.RecordStartDate;
      }
      else {
        this.validFrom = null;

      }
      if (documentRecord.RecordEndDate != null) {
        this.validTo = documentRecord.RecordEndDate;
      }
      else {
        this.validTo = null;

      }
      var FileName = documentRecord.Path;
      this.cookieService.set("UploadedFileName", FileName);
    }
    else {
      //tbDocumentName.Text = "";
      this.description = "";
      this.name = "";
    }
    if (documentRecord.DocumentType_EnumID != null && documentRecord.DocumentType_EnumID != Guid.EMPTY) {
      this.ddType_EnumID = documentRecord.DocumentType_EnumID;
    }
    this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.document, false, ["UploadDocument", "IsUpload"]);
    if (documentRecord != null && documentRecord.RecordID != Guid.EMPTY) {
      this.setValidation(documentRecord.DocumentType_EnumID);
    }

    if (!this.IsNullorEmpty(this.defaultDocumentType) && documentRelationshipView == null) {
      this.ddType_EnumID = this.defaultDocumentType
    }
    if (documentRelationshipView != null) {
      this.fileReadOnlyMode = false;
    }
    this.DocumentUploadPopupUp.visible = true;
    this.documentValue = [];
  }

  BindDocumentTypeDDl(_DocumentTypes: string): boolean {

    var validDocumentTypeList = null;
    var ExcludedDocumentTypeList = null;
    this.documentTypevalue = Guid.EMPTY;

    if (this.documentTypesList == null || !(this.documentTypesList.length > 0)) {
      this.documentTypesList = this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.enumerationList, ["Document Type"]);
    }

    if (this.documentTypeDataSourceList == null || !(this.documentTypeDataSourceList.length > 0)) {
      this.documentTypeDataSourceList = this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.enumerationList, ["Document Type"]);
    }

    if (!this.IsNullorEmpty(_DocumentTypes) && this.documentTypeDataSourceList != null) {
      var strWrongdocumentTypesEnumeration = this.ValidatedocumentTypesEnumeration(_DocumentTypes.replaceAll("!", ""));
      if (this.IsNullorEmpty(strWrongdocumentTypesEnumeration)) {
        validDocumentTypeList = _DocumentTypes.toUpperCase().split(',').map(s => s.trim());;//.ToList();
        ExcludedDocumentTypeList = validDocumentTypeList.filter(x => x.includes("!")).map(s => s.trim().replace("!", ""));//.map(y => y);
        validDocumentTypeList = validDocumentTypeList.filter(x => !x.includes("!"));//.map(y => y).ToList();

        if (ExcludedDocumentTypeList != null && ExcludedDocumentTypeList.length > 0)
          this.documentTypeDataSourceList = this.documentTypeDataSourceList.filter(X => X.Value.toUpperCase() != ExcludedDocumentTypeList.filter(y => y.trim() == X.Value.toUpperCase())[0]);
        if (validDocumentTypeList != null && validDocumentTypeList.length > 0)
          this.documentTypeDataSourceList = this.documentTypeDataSourceList.filter(x => x.Value.toUpperCase() == validDocumentTypeList.filter(y => y.trim() == x.Value.toUpperCase())[0]);
      }
      else {
        this.alertService.showAlertPopup('Error', "Document Type " + strWrongdocumentTypesEnumeration + " is not a valid option", 'fa-solid fa-xmark', 'OK');
        return false;
      }
    }
    else if (!this.IsNullorEmpty(this.defaultDocumentType) && this.documentTypeDataSourceList != null && this.IsNullorEmpty(_DocumentTypes)) {
      this.documentTypeDataSourceList = this.documentTypeDataSourceList.filter(x => x.EnumerationId.toLowerCase() == this.defaultDocumentType.toLowerCase());
    }
    if (!this.IsNullorEmpty(this.ddType_EnumID)) {
      this.documentTypevalue = this.ddType_EnumID;
    }
    if (this.documentTypeDataSourceList != null && this.documentTypeDataSourceList.length == 1) {
      this.ddType_EnumID = this.documentTypeDataSourceList[0].EnumerationId;
      if (!this.IsNullorEmpty(this.ddType_EnumID)) {
        this.documentTypevalue = this.ddType_EnumID;
        // this.ShowType = false;
      }
    }
    if (this.ddType_EnumID != null && this.ddType_EnumID.toLowerCase() != Guid.EMPTY.toLowerCase()) {
      this.setValidation(this.ddType_EnumID);
    }
    if (!this.IsNullorEmpty(this.documentTypevalue)) {
      if (this.documentTypevalue.toLowerCase() == Guid.EMPTY.toLowerCase()) {
        this.ddType_EnumID = null;
      }
      else {
        this.ddType_EnumID = this.documentTypevalue;
      }

    }

    return true;
  }

  ValidatedocumentTypesEnumeration(documntTypes: string): string {
    var notvaliddocumentType = '';
    documntTypes.split(',').forEach(x => {
      var docTypeExists = false;
      this.documentTypesList.forEach(y => {
        if (x.trim().toUpperCase() == y.Value.trim().toUpperCase()) {
          docTypeExists = true;
        }
      })
      if (!docTypeExists) {
        notvaliddocumentType = notvaliddocumentType + "," + x.trim();
      }
    })
    return notvaliddocumentType.slice(1, notvaliddocumentType.length);
  }

  async selectionChanged(e: any) {
    if (!this.IsNullorEmpty(this.CurrentrecordID)) {
      var documentRelationshipView = await this.docViewService.GetRelatedDocument(this.CurrentrecordID, "10BDB7AC-D8B0-4C78-9B32-467E69E9E943");
      if (documentRelationshipView != null) {
        this.fileReadOnlyMode = false;
      }
    }
    // this.documentValue = null;
    this.setValidation(this.ddType_EnumID);
  }

  Save(e: any) {
    var validationmessage = "";
    const { isValid } = this.validationGroup.instance.validate();  
    if (!isValid) {
      const brokenRules = e.validationGroup.validate().brokenRules;
      validationmessage = brokenRules.map((rule: any) => {
        return "<p>" + rule.message + "</p>"
      }).join("");
    }

    if (isValid && this.CurrentAction.toLowerCase() == "newpopup" && (this.documentValue != null && this.documentValue.length > 0)) {
      this.SaveUpload();
    }
    else if (isValid && this.CurrentAction.toLowerCase() == "editpopup") {
      this.SaveUpload();
    }
    else {

      if (!(this.documentValue != null && this.documentValue.length > 0 && this.CurrentAction.toLowerCase() == "newpopup")) {
        validationmessage = validationmessage + "<p>Select file is required or please select again.</p>";
      }
      this.validationpopup.showPopup(validationmessage);
    }


  }

  CheckValidDocumentType(documentType_EnumID: string, fileExtenstion: string): boolean {
    var IsValid = false;
    if (documentType_EnumID != null && documentType_EnumID.toLowerCase() != Guid.EMPTY.toLowerCase()) {
      var fileRestrictions = "";
      this.documentTypesList.forEach(y => {
        if (y.EnumerationId.trim().toLowerCase() == documentType_EnumID.toLowerCase()) {
          fileRestrictions = y.ExtendedAttributes;
        }
      })
    }

    if (!this.IsNullorEmpty(fileRestrictions)) {
      var parser = new DOMParser();
      var xmlDoc = parser.parseFromString(fileRestrictions, "text/xml");
      xmlDoc.getElementsByTagName("FileRestrictions")[0].attributes["MaxFileSize"].nodeValue
      var extensionList = <HTMLCollection>xmlDoc.getElementsByTagName("FileRestriction");
      for (let i = 0; i < extensionList.length; i++) {
        if (xmlDoc.getElementsByTagName("FileRestriction")[i].attributes["Extension"].nodeValue.toLowerCase() == fileExtenstion.toLowerCase()) {
          IsValid = true;
          break;
        }
      }
    }
    return IsValid;
  }
  async SaveUpload() {
    var uploadedDocumentName = null;
    if (!this.IsNullorEmpty(this.cookieService.get("UploadedFileName"))) {
      uploadedDocumentName = this.cookieService.get("UploadedFileName")
    }
    else if (this.documentValue != null && this.documentValue.length > 0) {
      uploadedDocumentName = this.documentValue[0].name;

    }


    if (this.documentValue != null && this.documentValue.length > 0) {
      this.documentUploadModel.ByteSize = this.documentValue[0].size;
    }
    else {
      this.documentUploadModel.ByteSize = null;
    }

    if (this.CheckValidDocumentType(this.ddType_EnumID, "." + this.GetFileExtension(uploadedDocumentName))) {
      if (this.documentUploadModel != null) {
        var fileName;

        this.documentUploadModel.DocumentType_EnumID = this.ddType_EnumID;
        this.documentUploadModel.Name = this.name;
        if (this.documentValue != null && this.documentValue.length > 0) {
          this.documentUploadModel.Path = uploadedDocumentName;
          var fileextension = this.GetFileExtension(this.documentUploadModel.Path);
          if (this.fileExtensionList != null) {
            var fileExtensionEnumID = "";
            this.fileExtensionList.forEach(y => {
              if (y.Value.trim().toLowerCase() == fileextension.toLowerCase()) {
                fileExtensionEnumID = y.EnumerationId;
                this.documentUploadModel.FileExtension_EnumID = fileExtensionEnumID;
              }
            })
          }
        }

        this.documentUploadModel.Mime = '';
        this.documentUploadModel.Version = this.version;
        this.documentUploadModel.Description = this.description;
        this.documentUploadModel.IsPublic = this.markAsPublic;
        this.documentUploadModel.RecordDataObjectStatus_EnumID = this.recordDataObjectStatus_EnumID;
        this.documentUploadModel.RecordStartDate = this.validFrom
        this.documentUploadModel.RecordEndDate = this.validTo
        if (!this.IsNullorEmpty(this.relationshipID)) {
          this.documentUploadModel.RelationshipID = this.relationshipID;
        } else {
          this.documentUploadModel.RelationshipID = null;
        }
        if (!this.IsNullorEmpty(this.CurrentrecordID)) {
          this.documentUploadModel.Object1_RecordID = this.CurrentrecordID;
        } else {
          this.documentUploadModel.Object1_RecordID = null;
        }
        if (!this.IsNullorEmpty(this.documentUploadTempID)) {
          this.documentUploadModel.docTempID = this.documentUploadTempID;
        } else {
          this.documentUploadModel.docTempID = null;
        }
        this.documentUploadModel.IsValidDocumentType = true;
        this.documentUploadModel.IsRecordDataObjectStatus_EnumIDVisible = this.ShowRecordStatus;
        this.documentUploadModel.ParentRecordID = await this.GetParentRecordID();
        var isUpload = this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.document, ["UploadDocument", "IsUpload"]);
        if (isUpload) {
          this.documentUploadModel.IsUpload = isUpload;
        }
        else {
          this.documentUploadModel.IsUpload = false;
        }


      }
      var SaveDocument = await this.saveDocument(this.documentUploadModel);
    }
    else {

      var errmessage = "<p>Selected document type supports file types <b>" + this.allowedFileExtensions.join(",") + "</b>. Current document with extension <b>" + this.GetFileExtension(uploadedDocumentName) + "</b> is not supported.</p>";

      this.validationpopup.showPopup(errmessage);
    }
  }

  saveDocument(documentUploadModel: UploadDocumentModel): Promise<ImageLocationModel> {
    return new Promise((resolve, reject) => {
      this.httpClient.post<ImageLocationModel>(environment.apiUrl + 'api/Document/SaveDocumentUpload', JSON.stringify(documentUploadModel))
        .subscribe((response) => {
          //alert(response)
          if (!this.IsNullorEmpty(response.Location) && this.map) {
            var arrLatLng = response.Location.split(",");
            this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap, arrLatLng[0], ["lat"]);
            this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap, arrLatLng[1], ["lng"]);

            var Location = response.Location;

            var YesButtonText:string="";
            var CancelButtonText:string="";

            this.translateService.get('YesButtonText').subscribe((translated: string) => {
              YesButtonText= translated;
            });
            this.translateService.get('CancelButtonText').subscribe((translated: string) => {
              CancelButtonText = translated;
            });

            this.translateService.get('ConfirmationMessage', { command: "set location for" }).subscribe((text: string) => {
              Swal.fire({
                title: "Confirmation",
                text: text,
                iconHtml: "<i class='fa-solid fa-triangle-exclamation'></i>",
                confirmButtonText: YesButtonText,
                showCancelButton: true,
                cancelButtonText: CancelButtonText,
                allowOutsideClick : false
              }).then((response: any) => {
                if (response.isConfirmed) {
                  this.documentGrid.dataGrid.instance.refresh().then((value) => { });
                  this.map.SetImageLocation(Location);
                  SetLocation(Location);
                  this.alertService.showAlertPopup('Success', "Location set successfully.", 'fa-solid fa-check', 'OK');

                }
              });
            });
          }
          else {
            this.documentGrid.dataGrid.instance.refresh().then((value) => {
              this.alertService.showAlertPopup('Success', "Document uploaded successfully.", 'fa-solid fa-check', 'OK');
            });
            this.documentGrid.dataGrid.instance.refresh();
        
          }

          this.cookieService.delete("UploadedFileName");
          this.DocumentUploadPopupUp.visible = false;

          resolve(<ImageLocationModel>response);
        });
    });

  }
  Cancel(e: any) {
    this.DocumentUploadPopupUp.visible = false;
    this.cookieService.delete("UploadedFileName");
    return new Promise((resolve, reject) => {
      this.httpClient.post<boolean>(environment.apiUrl + 'api/Document/DeleteTempFile/' + this.documentUploadTempID, {})
        .subscribe((response) => {
          resolve(<boolean>response);
          catchError((err: any) => {
            this.alertService.showAlertPopup('Error', err.error.Message, 'fa-solid fa-xmark', 'OK'); throw err
          })
        });
    });
  }

  async GetParentRecordID(): Promise<string> {
    var ParentID = Guid.EMPTY;
    var quesryStringRecordID = await this.GetKeyValueFromQueryString("RecordID");
    var quesryStringNewID = await this.GetKeyValueFromQueryString("NewID");

    if (!this.IsNullorEmpty(quesryStringRecordID)) {
      ParentID = quesryStringRecordID;

    }
    if (this.IsNullorEmpty(quesryStringRecordID) && !this.IsNullorEmpty(quesryStringNewID)) {
      ParentID = quesryStringNewID;
    }
    return ParentID;
  }

  GetEnumerationListByTypeDescription(enumerationType: string, recordStatusList: EnumerationModel[], key: string) {

    this.httpClient.get(environment.apiUrl + "api/EnumerationUnAuthorize/GetEnumerationListByTypeDescription/" + enumerationType)
      .subscribe((response) => {
        this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.enumerationList, <EnumerationModel[]>response, [key]);
      });

  }

  onUploadError(e) {
    var xhttp = e.request;
    if (xhttp.readyState == 4 && xhttp.status == 0) {

    }
  }
  onUploadedCompleted(e) {
    this.name = this.documentValue[0].name;
    this.documentUploadTempID = e.request.responseText;
    this.cookieService.set("UploadedFileName", this.documentValue[0].name);
    this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.document, true, ["UploadDocument", "IsUpload"]);
    this.isEditpopup=false;
    this.btnSave.disabled=false;
  }

  onUploadStarted(e) {
    var filename = e.file.name;
    if (filename.includes("\\") || filename.includes("|") || filename.includes("!") || filename.includes("#") || filename.includes("$") || filename.includes("%")
        || filename.includes("&") || filename.includes("/") || filename.includes("=") || filename.includes("?") || filename.includes("»") || filename.includes("«")
        || filename.includes("@") || filename.includes("£") || filename.includes("§") || filename.includes("€") || filename.includes(";")
        || filename.includes("'") || filename.includes("<") || filename.includes(">") || filename.includes(",") || filename.includes("–"))
    {
      this.fileUploader.instance.abortUpload(e.file);
      this.fileUploader.instance.clear();
      var strInvalidCharacters = "\\ | ! # $ % & / = ? » « @ £ § € ; ' < > , –";
      this.validationpopup.showPopup("File name cannot contain following characters: </br>" + strInvalidCharacters);
    }
  }

  onValueChanged(e) {
  }


  setValidation(documentType_EnumID: string) {
    if (documentType_EnumID != null && documentType_EnumID.toLowerCase() != Guid.EMPTY.toLowerCase()) {
      var fileRestrictions = "";
      this.documentTypesList.forEach(y => {
        if (y.EnumerationId.trim().toLowerCase() == documentType_EnumID.toLowerCase()) {
          fileRestrictions = y.ExtendedAttributes;
        }
      })

      this.maxFileSize = 2097152;
      this.allowedFileExtensions = [];
      if (!this.IsNullorEmpty(fileRestrictions)) {
        var parser = new DOMParser();
        var xmlDoc = parser.parseFromString(fileRestrictions, "text/xml");
        this.allowedFileExtensions = [];
        this.maxFileSize = xmlDoc.getElementsByTagName("FileRestrictions")[0].attributes["MaxFileSize"].nodeValue;
        xmlDoc.getElementsByTagName("FileRestrictions")[0].attributes["MaxFileSize"].nodeValue
        var extensionList = <HTMLCollection>xmlDoc.getElementsByTagName("FileRestriction");
        for (let i = 0; i < extensionList.length; i++) {
          this.allowedFileExtensions.push(xmlDoc.getElementsByTagName("FileRestriction")[i].attributes["Extension"].nodeValue)
        }
      }
      this.invalidFileExtensionMessage = "Allowed File extensions are :" + this.allowedFileExtensions.join(",");
      this.invalidMaxFileSizeMessage = "File size exceeds the maximum allowed size, which is: " + this.maxFileSize / 1048576 + "MB.";
      this.invalidMinFileSizeMessage = "Minimum File size allowed is: 1KB."
    }
    else {
      this.ddType_EnumID = null;
    }

  }

  addParameter(e) {
    this.docSaveApiUrl = this.updateQueryStringParameter(this.docSaveApiUrl, "TempID", this.documentUploadTempID);
    e.component.option("uploadUrl", this.docSaveApiUrl);
  }
  updateQueryStringParameter(uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
      return uri + separator + key + "=" + value;
    }
  }
  validToValueChanged(e) {
    this.validTo = e.value;
  }
  validFromValueChanged(e) {
    this.validFrom = e.value;
  }

  DeleteUploadDocument(RecordID: string, RelationshipID: string, docGrid: GridComponent) {
    this.documentGrid = docGrid;
    return new Promise((resolve, reject) => {
      this.httpClient.put<DocumentModel>(environment.apiUrl + 'api/Document/DeleteUpload', { RecordID, RelationshipID })
        .subscribe((response) => {
          //alert(response)
          this.alertService.showAlertPopup('Success', "Record successfully deleted.", 'fa-solid fa-check', 'OK');
          this.documentGrid.dataGrid.instance.refresh().then((value) => { });
          resolve(<DocumentModel>response);
          catchError((err: any) => {
            this.alertService.showAlertPopup('Error', err.error.Message, 'fa-solid fa-xmark', 'OK'); throw err
          })
        });
    });
  }

  SetImageLocation(RecordID: any, closecallDetailMap: MapComponent) {
    this.map = closecallDetailMap;
    this.httpClient.get(environment.apiUrl + "api/Document/GetImageLocation/" + RecordID)
      .subscribe((response: any) => {


        if (!this.IsNullorEmpty(response.Location) && this.map) {
          var arrLatLng = response.Location.split(",");
          this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap, arrLatLng[0], ["lat"]);
          this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap, arrLatLng[1], ["lng"]);

          var Location = response.Location;
          var YesButtonText:string="";
            var NoButtonText:string="";

            this.translateService.get('YesButtonText').subscribe((translated: string) => {
              YesButtonText= translated;
            });
            this.translateService.get('NoButtonText').subscribe((translated: string) => {
              NoButtonText = translated;
            });
          this.translateService.get('ConfirmationMessage', { command: "set location for" }).subscribe((text: string) => {
            Swal.fire({
              title: "title",
              text: text,
              iconHtml: "<i class='fa-solid fa-triangle-exclamation'></i>",
              confirmButtonText:YesButtonText,  
              showCancelButton: true,
              cancelButtonText: NoButtonText,
              allowOutsideClick : false
            }).then((response: any) => {
              if (response.isConfirmed) {
                this.map.SetImageLocation(Location);
                SetLocation(Location);
                this.alertService.showAlertPopup('Success', "Location set successfully.", 'fa-solid fa-check', 'OK');

              }
            });
          });
        }
        else {
          this.alertService.showAlertPopup('Success', "No location exists for this image.", 'fa-solid fa-check', 'OK');
        }


      });
  }



  async downloadFile(recordID: string) {
    var documentRecord = await this.docViewService.GetDocumentRecord(recordID);
    if (documentRecord) {
      var url = environment.azureStorageURL + "Documents/" + documentRecord.RecordID + "_" + documentRecord.Path;
      // this.Download(url)
     // this.DownloadFileBytearray(recordID, documentRecord.Mime, documentRecord.Path);
     const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', url);
    link.setAttribute('download',documentRecord.Path);
    document.body.appendChild(link);
    link.click();
    link.remove();
    }
    else {
      this.alertService.showAlertPopup('Error', "No document exits", 'fa-solid fa-xmark', 'OK');
    }

  }

  DownloadFileBytearray(RecordID: string, mime: string, name: string) {
    this.downloadDocument(RecordID, name)
      .subscribe(data => saveAs(data, name));
  }

  downloadDocument(recordid: string, filename) {
    return this.httpClient.get(environment.apiUrl + 'api/Document/DownloadFile/' + recordid + "/" + filename, { responseType: 'blob' });
  }

  Download(Url) {
    fetch(Url)
      .then(response => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'file.pdf';
        a.click();
        URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('File download failed:', error);
      });
  }
}
