import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';
import { flush } from '@angular/core/testing';
import { EventType, Router } from '@angular/router';

import { DxButtonComponent, DxDataGridComponent, DxPopupComponent, DxSelectBoxComponent } from 'devextreme-angular';
import { DxDropDownBoxComponent, DxDropDownBoxTypes } from 'devextreme-angular/ui/drop-down-box';
import CustomStore from 'devextreme/data/custom_store';
import { concat, forkJoin, lastValueFrom } from 'rxjs';
import { RecordSearchComponentProperties } from 'src/app/models/RecordSearchComponentProperties';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { EnumHelper } from 'src/app/util/enum-helper';
import { environment } from 'src/environments/environment';
import { GridComponent } from 'src/app/Components/common/grid/grid.component';
import dxDropDownBox from 'devextreme/ui/drop_down_box';
import { Guid } from 'guid-typescript';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { EncryptionService } from 'src/app/encryption.service';
import DataSource from 'devextreme/data/data_source';
import { EnumerationModel } from 'src/app/models/EnumerationModel';
import dxDataGrid from 'devextreme/ui/data_grid';
import { BulkTransitionComponentProperties } from 'src/app/models/BulkTransitionComponentProperties';
import { BulkTransitionService } from 'src/app/services/bulkTransition/bulk-transition.service';

@Component({
  selector: 'app-bulk-enumeration-transition',
  templateUrl: './bulk-enumeration-transition.component.html',
  styleUrls: ['./bulk-enumeration-transition.component.scss']
})
export class BulkEnumerationTransitionComponent extends PageBaseService implements OnInit {
  @ViewChild("popup", { static: false }) popup: DxPopupComponent;
  @ViewChild('fromEnumeration', { static: false }) fromEnumeration: DxSelectBoxComponent
  @ViewChild('toEnumeration', { static: false }) toEnumeration: DxSelectBoxComponent
  @ViewChild('changeStatusButton', { static: false }) changeStatusButton: DxButtonComponent
  @ViewChild('doneButton', { static: false }) doneButton: DxButtonComponent
  @Output() customActions = new EventEmitter<any>();
  searchModeOption: any = 'contains';
  searchExprOption: any = "Description";
  searchTimeoutOption = 200;
  minSearchLengthOption = 0;
  showDataBeforeSearchOption = false;
  fromEnumerationDataSource: any = null;
  toEnumerationDataSource: any = null;
  fromEnumerationDescription: any = null;
  toEnumerationDescription: any = null;
  isToEnumerationCtrlDisabled: boolean = true;
  isAllTransitionPerformedStatus: number = 0;
  currentGrid: DxDataGridComponent;
  title:string = "";

  //BulkEnumerationTransitionJobStatus
  @Input() instanceID: string = '';
  componentID: string = 'BulkEnumerationTransition';

  okAlertBtnTxt: string = this.translateService.instant('OkButtonText');
  errorAlertTitle: string = this.translateService.instant('warningText');
  noSelectedRecordMsg: string = this.translateService.instant('RecordSearchNoSelectedRecordMsg');
  componentProperties: BulkTransitionComponentProperties;
  selectedRecords: any[];
  enumerationData: EnumerationModel[];

  constructor(private auth: AuthService, private translateService: TranslateService,
    private alertService: AlertService, private ref: ChangeDetectorRef,
    private httpClient: HttpClient, private localStorageService: LocalStorageService,
    private _router: Router, private _enc: EncryptionService, private bulkTransitionService: BulkTransitionService) {
    super(_router, _enc);
  }

  ngOnInit(): void {
    this.AfterUpdateTransitionCompleted = this.AfterUpdateTransitionCompleted.bind(this);
    if (this.instanceID && this.instanceID != null && this.instanceID != "") {
      this.componentProperties = <BulkTransitionComponentProperties>this.localStorageService.
        GetLocalStorage(EnumHelper.LocalStorageKey.recordSearchComponentProperties, [this.instanceID])
        ?? new BulkTransitionComponentProperties();

      if (this.componentProperties.HeaderText.length === 0 && this.instanceID.length != 0) {
        this.getComponentProperties();
      }
    }
  }

  openPopup() {
    this.popup.visible = true;
  }

  onFromSelectionChanged(e) {
    if (this.popup.visible) {
      this.GetTransitions(e.selectedItem?.EnumerationId);
      this.isToEnumerationCtrlDisabled = false;
    }
  }
  openPopupWithDDLBinding(dataGrid: DxDataGridComponent, selectedRows: any[], enumerationData: { key: string, values: EnumerationModel[] }[]) {
    if (selectedRows && selectedRows.length > 0) {
      this.currentGrid = dataGrid;
      this.selectedRecords = selectedRows;
      var allEnumerations = enumerationData.find(x => x.key.toLocaleLowerCase() == this.componentProperties.EnumerationTypeDescription.toLocaleLowerCase())?.values ?? [];
      var selectedEnumerations: EnumerationModel[] = [];

      allEnumerations.forEach(element => {
        if (element.EnumerationId && selectedRows.find(y => y[this.componentProperties.StatusEnumerationFieldName]?.toLowerCase() == element.EnumerationId.toLocaleLowerCase())) {
          selectedEnumerations.push(element);
        }
      });

      if (selectedEnumerations.length == 1) {
        this.fromEnumeration.value = selectedEnumerations[0]?.EnumerationId;
      }
      selectedEnumerations = selectedEnumerations.sort((a, b) => a.DisplayOrder - b.DisplayOrder);


      this.fromEnumerationDataSource = selectedEnumerations;

      this.popup.visible = true;
    } else {
      this.alertService.showAlertPopup("Error", "Kindly select atleast one record", "error", "Ok");
    }
  }

  closePopup() {
    this.popup.visible = false;
    this.isAllTransitionPerformedStatus = 0;
    this.fromEnumeration.instance.clear();
    this.toEnumeration.instance.clear();
    this.currentGrid.instance.clearSelection();
    this.title = this.componentProperties?.HeaderText;
  }

  changeStatusClick() {
    this.title = this.componentProperties?.HeaderText;
    this.isAllTransitionPerformedStatus = 0;
    this.fromEnumerationDataSource = this.fromEnumerationDataSource.filter(x => x.EnumerationId.toLowerCase() != this.fromEnumeration.value.toLowerCase())
    this.fromEnumeration.instance.clear();
    this.toEnumeration.instance.clear();
    this.toEnumerationDataSource = [];
    this.isToEnumerationCtrlDisabled = true;
  }

  async applyAction() {
     if (this.fromEnumeration.value == null) {
      this.alertService.showAlertPopup(this.errorAlertTitle,
        this.noSelectedRecordMsg, 'fa-solid fa-triangle-exclamation', this.okAlertBtnTxt);
      return;
    }
    else if (this.toEnumeration.value == null) {
      this.alertService.showAlertPopup(this.errorAlertTitle,
        this.noSelectedRecordMsg, 'fa-solid fa-triangle-exclamation', this.okAlertBtnTxt);
      return;
    }
    else {
      
      var fromEnumerationID = this.fromEnumerationDataSource.filter(x => x.EnumerationId.toLowerCase() == this.fromEnumeration.value.toLocaleLowerCase())[0];
      var toEnumerationID = this.toEnumerationDataSource.filter(x => x.EnumerationID.toLowerCase() == this.toEnumeration.value.toLocaleLowerCase())[0];

      this.fromEnumerationDescription = fromEnumerationID.Description;
      this.toEnumerationDescription = toEnumerationID.Description;

      if (this.componentProperties.IsCustomAction) {
        this.customActions.emit({ "FromEnumerationID": fromEnumerationID, "FoEnumerationID": toEnumerationID, "SelectedRecords": this.selectedRecords });
      }
      else {
        this.isAllTransitionPerformedStatus = 1;
        var counter = 0;
        this.doneButton.visible = false;
        this.changeStatusButton.visible = false;
        this.bulkTransitionService.UpdateBulkTransitions(this.componentProperties.ObjectID,
          this.componentProperties.StatusEnumerationFieldName, fromEnumerationID,
          toEnumerationID, this.selectedRecords, this.componentProperties.TransitionEndPoint, this.AfterUpdateTransitionCompleted);
      }
    }
  }

  successCount:number= 0;
  totalCount:number= 0;
  failedCount:number= 0;
  AfterUpdateTransitionCompleted = (totalCount, successCount, failedCount, isCompleted) => {    
   this.successCount = successCount;
   this.totalCount= totalCount;
   this.failedCount = failedCount;

    this.title = this.componentProperties?.HeaderText + " in progress";

    if (isCompleted) {
      this.title = this.componentProperties?.HeaderText + " completed";
      this.fromEnumerationDataSource = this.fromEnumerationDataSource.filter(x => x.EnumerationId.toLowerCase() != this.fromEnumeration.value.toLowerCase());
      this.isAllTransitionPerformedStatus = 2;

      if (this.fromEnumerationDataSource.length <= 1) {
        this.changeStatusButton.visible = false;
      }
      else {
        this.changeStatusButton.visible = true;
      }
      this.doneButton.visible = true;
    }
    this.currentGrid.instance.refresh();
  };

  onHiding() {
    this.fromEnumeration.value = null;
    this.toEnumeration.value = null;
  }

  getComponentProperties() {
    var ComponentUrl = "api/ComponentProperties/GetComponentPropertiesData?componentID=";
    var result = lastValueFrom(
      this.httpClient.get(environment.apiUrl + ComponentUrl + this.componentID + "&instanceID=" + this.instanceID)
    ).then((response: any) => {
      this.setComponentProperties(response.Properties);
      this.title = this.componentProperties?.HeaderText;
    });
  }

  setComponentProperties(componentProperties) {
    var componentProperties = JSON.parse(componentProperties);
    componentProperties.RecordSearchProperties.forEach(componentProperty => {
      switch (componentProperty.key.toLowerCase()) {
        case "objectid":
          this.componentProperties.ObjectID = componentProperty.value;
          break;
        case "headertext":
          this.componentProperties.HeaderText = componentProperty.value;
          break;
        case "enumerationtypedescription":
          this.componentProperties.EnumerationTypeDescription = componentProperty.value;
          break;
        case "enumerationtypeid":
          this.componentProperties.EnumerationTypeID = componentProperty.value;
          break;
        case "statusenumerationfieldname":
          this.componentProperties.StatusEnumerationFieldName = componentProperty.value;
          break;
        case "transitionendpoint":
          this.componentProperties.TransitionEndPoint = componentProperty.value;
          break;
        case "iscustomaction":
          if (componentProperty.value.toLowerCase() == "true") {
            this.componentProperties.IsCustomAction = true;
          } else {
            this.componentProperties.IsCustomAction = false;
          }
          break;
      }
    });
    this.localStorageService.SetLocal(EnumHelper.LocalStorageKey.bulkEnumerationTransitionComponent, JSON.stringify(this.componentProperties), [this.instanceID]);
  }

  GetTransitions(selectedParentID: string) {
    if (selectedParentID && selectedParentID.length > 0 && selectedParentID != "00000000-0000-0000-0000-000000000000") {
      var bulkTransitionURL = "api/EnumerationTransition/GetChildForBulkTransition";
      var result = lastValueFrom(
        this.httpClient.get(environment.apiUrl + bulkTransitionURL + "/" + selectedParentID + "/" + this.componentProperties.StatusEnumerationFieldName)
      ).then((response: any) => {
        this.toEnumerationDataSource = response;
      });
    }
    else {
      this.toEnumerationDataSource = [];
    }
  }
}