import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, booleanAttribute } from '@angular/core';
import { UILayoutFieldModel } from 'src/app/models/UILayoutFieldModel';
import { GridHelperService } from 'src/app/services/gridHelper/grid-helper.service';
import { jsPDF } from 'jspdf';
import { exportDataGrid as exportPDF } from 'devextreme/pdf_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { ComponentSettingsModel } from 'src/app/models/ComponentSettingsModel';
import { GridComponentProperties } from 'src/app/models/GridComponentProperties';
import { lastValueFrom, forkJoin, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DxDataGridComponent } from 'devextreme-angular';
import { GridSettingsModel } from 'src/app/models/GridSettingsModel';
import CustomStore from 'devextreme/data/custom_store';
import Swal from 'sweetalert2';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { ObjectCommandModel, TransitionModel } from 'src/app/models/ObjectCommandModel';
import dxButton from 'devextreme/ui/button';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { EnumHelper } from 'src/app/util/enum-helper';
import { CommonHelper } from 'src/app/util/common-helper';
import { EnumerationModel } from 'src/app/models/EnumerationModel';
import { map, max } from 'rxjs/operators';
import { OfflineDataHelperService } from 'src/app/services/offlineDataHelper/offline-data-helper.service';
import { Network } from '@capacitor/network';
import { RelatedRecordDescriptionModel } from 'src/app/models/RelatedRecordDescriptionModel';
import { ObjectRecordModel } from 'src/app/models/ObjectRecordModel';
import ArrayStore from 'devextreme/data/array_store';
import { ValidationPopupComponent } from '../validation-popup/validation-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { Guid } from 'guid-typescript';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { AppEventType } from 'src/app/enums/appEventType';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { EncryptionService } from 'src/app/encryption.service';
import { GridMenuOptionPopupComponent } from '../grid-menu-option-popup/grid-menu-option-popup.component';
import { Column, Row } from 'devextreme/ui/data_grid';
import { Items } from 'src/app/models/FieldSettings';
import { formatDate } from '@angular/common';
import { locale } from "devextreme/localization";
import { AppEvent } from 'src/app/models/AppEvent';
import { ColumnDefaultWidth } from 'src/app/models/ColumnDefaultWidth';
import { DocumentModel } from 'src/app/models/DocumentModel';
import { UserIconImageService } from 'src/app/services/userIconImage/user-icon-image.service';
import { DxiDataGridColumn } from 'devextreme-angular/ui/nested/base/data-grid-column-dxi';
import dxDateBox from 'devextreme/ui/date_box';
import { Colors } from 'chart.js';
import { ContactDetailsPopupComponent } from '../contact-details-popup/contact-details-popup.component';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { DateTimeHelperService } from 'src/app/services/datetimehelper/date-time-helper.service';
import { MapService } from 'src/app/services/map/map.service';
import { ObjectModel } from 'src/app/models/ObjectModel';
import { DblclkColumninfoPopupComponent } from '../dblclk-columninfo-popup/dblclk-columninfo-popup.component';
import { BulkTransitionService } from 'src/app/services/bulkTransition/bulk-transition.service';
import { BulkEnumerationTransitionComponent } from '../bulk-enumeration-transition/bulk-enumeration-transition/bulk-enumeration-transition.component';
import { registerComponent } from 'src/app/models/app-component-registry';

@registerComponent
@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class GridComponent extends PageBaseService implements OnInit, OnDestroy {
  private bulkEnumerationTransitionPopup: BulkEnumerationTransitionComponent;
  @ViewChild("commandCollectionDiv", { static: false }) commandCollectionDiv: HTMLElement
  @ViewChild("bulkEnumerationTransitionPopup", {static:false}) set content(content: BulkEnumerationTransitionComponent){
    if(content) { 
     this.bulkEnumerationTransitionPopup = content;
    }
  }
  @Input() externalItemTemplate: TemplateRef<any>;
  defaultItemTemplate: TemplateRef<any>
  @Input() GroupingTemplate:string = '';
  @Input() ColumnTemplates:Map<string, string> = null
  @Input() IsInLineGrouping:boolean = false;
  @Input() templateName: String = "defaultItemTemplate";
  @Input() filterExpressionsArrayByQueryString: any;
  @Input() groupCellTemplate: String = null;
  @Input() Title: string;
  @Input() Paging: boolean=true;
  timeOutHandler;
  gridSetting: GridSettingsModel = null;
  dataRowTemplateName: String = "";
  componentSettings: ComponentSettingsModel
  selectedRows: string[]
  selectedRecordID: string = ''
  selectedRowIndex: number = 0;
  gridHelper: GridHelperService;
  filterBuilderFields: Array<any>;
  filter: any = '';
  gridFilterValue: any;
  toggleFilterBody: boolean = false;
  selectedCount: number = 0
  isPopupVisible: boolean = false;
  totalRows: number = 0;
  isVisible: Boolean = false;
  showGridMenuPopup: Boolean = false;
  @Input() isGridview: Boolean = true;
  isUserHavePermissionToViewGrid: Boolean = true;
  showSingleCard: Boolean = false;
  openBurgerMenu: boolean = false;
  burgerMenuActions: ObjectCommandModel[]
  burgerMenuPossiton: any = '';
  commandButtonActions: ObjectCommandModel[];
  rowClickCommandAction:any;
  componentID: string = "grid";
  ishideInfoIcon: Boolean = true;
  isVisibledivInfoIcon: Boolean = true;
  lookupDataSource: { key: string, values: EnumerationModel[] }[] = [];
  relationshipLookupDataSource:  { key: string, values: ObjectModel[] }[] = []; 
  editRowKey: string = "";
  isCachable: Boolean = false;
  cacheSize: number = 0;
  listFilteredColumn: UILayoutFieldModel[] = [];
  popuptitle: string = "";
  hideLink: boolean = true;
  okAlertBtnTxt: string = "Ok";
  successAlertTitle: string = "Success";
  errorAlertTitle: string = "";
  notAllowedAlertPopupTitle = "";
  reachedLastPage: Boolean = false;
  searchEditorOptions: any;
  searchPanelText: string;
  dbSource: string = '';
  dataSourceName: string = '';
  fetchUpdateFromDB: boolean = false;
  NoDataInOffline: boolean = true;
  isTransitionAction: boolean = false;
  saveAll: boolean = false;
  getColumnWidth = new ColumnDefaultWidth();
  listCardColumn: UILayoutFieldModel[] = [];
  fileExtensionEnumList: EnumerationModel[] = [];
  isBurgerMenuCommand: boolean = false;
  gridProperties: GridComponentProperties;
  isGridVisible: boolean;
  @Output("expandCollaspeChatComponent") expandCollaspeChatComponent: EventEmitter<any> = new EventEmitter();
  relationshipColumns : UILayoutFieldModel[];
  relationshipValues: {[key: string]: string} = {};
  timeZoneAdjustedOffValues: {[key: string]: string} = {};
  relationshipID:string;
  newID: string;
  showFilterSearch:boolean;
  @Output() isCustomStoreLoaded = new EventEmitter<boolean>();
  @Input() isRowClick: boolean = false;
  @Input() saveGridSetting: boolean = true;
  @Input() isWebpartHeaderHidden: boolean = false;
  @Output() rowClick = new EventEmitter<{ data: any, navigateURL: string }>();
  @Output() isGridHidden = new EventEmitter<boolean>();
  @Output() gridCustomActions = new EventEmitter<any>();
  @Output() CustomOnInitNewRow: EventEmitter<any> = new EventEmitter<{ event: any, ComponentSettings: ComponentSettingsModel }>();
  @Output() OnValueChangedEvent = new EventEmitter<string>();
  @Input() isCustomOnInit:boolean=false;
  @Input() instanceID: string;
  @Input() IsUnauthorizedPage: boolean = false;
  @Input() parentRecordId: string = '';
  @Input() recordID: string = '';
  @Input() applyAdaptivePriority: boolean = true;
  @Input() columnHidingEnabled: boolean = true;
  @Input() executeUpdateTransitionStatusFromRibbonCtrl = false;
  @ViewChild("dataGrid", { static: true }) dataGrid!: DxDataGridComponent
  @ViewChild("burgerMenu", { static: false }) divBurgerMenu: ElementRef
  @ViewChild("dvCardburgerMenu", { static: false }) dvCardburgerMenu: ElementRef
  @ViewChild("burgerMenu", { static: false }) CardburgerMenu: ElementRef
  @ViewChild("validationpopup") validationpopup: ValidationPopupComponent;
  @ViewChild("gridMenuOptionPopup") gridMenuOptionPopup: GridMenuOptionPopupComponent;
  @ViewChild("contactDetailtsPopup", { static: false }) contactDetailtsPopup: ContactDetailsPopupComponent;
  @ViewChild("cellInfoPopup", { static: false }) cellInfoPopup: DblclkColumninfoPopupComponent;
  connectionChangedSubscription: Subscription;
  isBulkTransitionInstanceSet:boolean = false;
  alertPopupRedirectUrl: string = '';
  dataSource: any = {};
  ShowCurrentCardActionMenu(buttonElement, item) {
    document.getElementById("burgerMenu" + item.rowIndex).style.visibility = "visible";
    document.getElementById('burgerMenuOverlay' + item.rowIndex).style.visibility = "visible";
    this.selectedRecordID = item.key;
    this.dataGrid.selectedRowKeys = [item.key];
    this.selectedRows = [item.key];
  }
  CloseCardBurgerMenu(item) {
    document.getElementById("burgerMenu" + item.rowIndex).style.visibility = "hidden";
    document.getElementById('burgerMenuOverlay' + item.rowIndex).style.visibility = "hidden";
    this.selectedRecordID = null;
    this.dataGrid.selectedRowKeys = null;
    this.selectedRows = null;
  }

  currentRowData: any = null;

  UpdateField(recordID:String, key:String, value:String ) {

  }
  onEditorPreparing(e) {
    if (e.dataField && e.parentType === 'dataRow') {  
        this.currentRowData = e;
        if (this.componentSettings.UIFields && this.componentSettings.UIFields.length > 0) {
            let column = this.componentSettings.UIFields.filter(x => 
                x.FieldName.toLowerCase() === e.fieldName?.toLowerCase() && 
                x.Definition.OnValueChangedEvent
            );
            if (column.length > 0) {
                e.editorOptions.onValueChanged = (arg) => {  
                    const selectedItem = arg.component.option('selectedItem');  
                    const newValue = selectedItem[e.editorOptions.valueExpr];
                        e.setValue(newValue);  
                       this.OnValueChangedEvent.emit(newValue);
                } 
            }
        }
      } 
   }
   
  onEditorPrepared(e) {
    if (e.parentType == "dataRow" && e.value == null && e.setValue) {
      e.setValue(undefined);
    }
    if (this.componentSettings.UIFields && this.componentSettings.UIFields.length > 0) {
      let column = this.componentSettings.UIFields.filter(x => x.Definition?.Required && x.FieldName.toLowerCase() === e.fieldName?.toLowerCase()
        && (x.OverrideUIType?.toLowerCase() == 'radiobuttontruefalse' || x.OverrideUIType?.toLowerCase() == 'radiobuttonyesno'))
      var spnElement: HTMLElement = e.editorElement.parentElement.parentElement.children[0].children[0];
      if (column.length > 0 && spnElement && spnElement.innerHTML && this.gridProperties.Mode.toLowerCase() === "edit") {
        spnElement.innerHTML = spnElement?.innerHTML + "<span class=\"dx-field-item-required-mark\">&nbsp;*</span>";
      }
    }

  }

  UpdateGridCellValue(recordID:String, columnToChange:string, value:String){
   var data = this.dataGrid.instance.getVisibleRows().map(item => {
      if(item.data.RecordID.toLowerCase() == recordID.toLocaleLowerCase()) {
               item.data[columnToChange] = value;
      }
    });
  }

  onInitNewRow(e) {
    if(this.isCustomOnInit)
    {
      this.CustomOnInitNewRow.emit({ event:e, ComponentSettings: this.componentSettings});
    }else{
      this.componentSettings.UIFields.forEach(col => {
        if (col.Definition.DefaultValue && col.Definition.DefaultValue.length > 0) {
          e.data[col.FieldName] = col.Definition.DefaultValue;
        }
        // if ( col.Definition.ReadOnly) {
        //   e.component.columnOption(col.FieldName).formItem.visible = false;         
        // }
        if (col.Definition.UpdateNotAllowed) {
          e.component.columnOption(col.FieldName).formItem.visible = true;         
        }
        if (col.Definition.InsertNotAllowed) {
          e.component.columnOption(col.FieldName).formItem.visible = false;         
        }
      });
    }
  }
   onEditingStart(e) {
    this.componentSettings.UIFields.forEach(col => {
      if (col.Definition.ReadOnly && col.Definition.IsEditable && col.Definition.Derived==null) {
        e.component.columnOption(col.FieldName).formItem.visible = true;
      }
      if (col.Definition.UpdateNotAllowed) {
        e.component.columnOption(col.FieldName).formItem.visible = false;         
      }
      if (col.Definition.InsertNotAllowed) {
        e.component.columnOption(col.FieldName).formItem.visible = true;         
      }
    });

   }

  refresh() {
    this.dataGrid.instance.refresh();
    this.dataGrid.selectedRowKeys = null;
    this.selectedRows = null;
  }


  myCellTemplate = (container: any, options: any) => {
    let divBurgerMenu = document.createElement("div");
    if (this.burgerMenuActions.length === 1) {
     
      new dxButton(divBurgerMenu, {
        icon: this.burgerMenuActions[0].Icon, elementAttr:
        {
          class: 'burger-command-button-onCol'
        },
        onClick: ($event) => {
          this.isBurgerMenuCommand = true;
          $event.event.stopPropagation();
          this.selectedRecordID = options.key;
          this.dataGrid.instance.deselectAll();
          this.selectedRows = null;
          this.commandAction(this.burgerMenuActions[0])
        }
      });
      container.appendChild(divBurgerMenu);
    } else {
      new dxButton(divBurgerMenu, { icon: "fa-solid fa-ellipsis", elementAttr: { class: 'burger-command-button' }, onClick: ($event) => { this.burgerMenuClick($event, options.key) } });
      container.appendChild(divBurgerMenu);
    }
  };

  onRowValidating(e: any) {
    if (!e.isValid) {
      const brokenRules = e.brokenRules;
      this.validationpopup.showPopup(brokenRules.map((rule: any) => {
        return "<p>" + rule.message + "</p>"
      }).join(""))
    }
  }

  constructor(private bulkTransitionService:BulkTransitionService, public mapService: MapService, private dateTimeHelperService: DateTimeHelperService, private userIconImageService: UserIconImageService, private localStorageService: LocalStorageService, public gridHelperService: GridHelperService, private _router: Router, private _enc: EncryptionService,
    private httpClient: HttpClient, private alertService: AlertService, private route: ActivatedRoute, private offlineDataHelper: OfflineDataHelperService,
    public translateService: TranslateService, private eventQueue: LobasEventQueueService) {
    super(_router, _enc);
    this.gridHelper = gridHelperService
    this.gridFilterValue = this.filter;
    var localeId = localStorage.getItem('UserLanguage');
    locale(localeId);
    this.onCellClick = this.onCellClick.bind(this);
    this.onEditorPreparing = this.onEditorPreparing.bind(this);
    this.compareValidater = this.compareValidater.bind(this);
    Network.addListener("networkStatusChange", status => {
      if (!status.connected && !this.gridProperties?.isDetailComponent) {
        //this.dataGrid.instance.refresh();
      } else {
        this.fetchUpdateFromDB = true;
        this.loadDataSource(this.componentSettings, false);
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.translateService.get('OkButtonText').subscribe((translated: string) => {
    this.okAlertBtnTxt = translated;
    });    
    this.translateService.get('SuccessTitle').subscribe((translated: string) => {
    this.successAlertTitle = translated;
    });    
    this.translateService.get('warningText').subscribe((translated: string) => {
    this.errorAlertTitle = translated;
    });    
    this.translateService.get('NotAllowedTitle').subscribe((translated: string) => {
    this.notAllowedAlertPopupTitle = translated;
    });

  }
  async ngAfterContentInit(): Promise<void> {
    this.componentSettings = <ComponentSettingsModel>this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.componentSettings, [this.instanceID]) ?? new ComponentSettingsModel();
    this.gridProperties = <GridComponentProperties>this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.gridComponentProperties, [this.instanceID]) ?? new GridComponentProperties();
    if (this.parentRecordId.length === 0 || this.parentRecordId == Guid.EMPTY) {
      this.parentRecordId = await this.GetKeyValueFromQueryString('RecordID');
    }
    if (this.recordID.length === 0 || this.recordID == Guid.EMPTY) {
      this.recordID = await this.GetKeyValueFromQueryString('RecordID');
    }
    this.newID= await this.GetKeyValueFromQueryString('newID');
    if (this.gridProperties.GridDisplayMode === 'card') {
      this.showSingleCard = true;
    }
    this.fileExtensionEnumList = <EnumerationModel[]>this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.enumerationList, ["File Extension"]);
    if (this.fileExtensionEnumList == null || this.fileExtensionEnumList == undefined) {
      this.fileExtensionEnumList = await this.GetEnumerationListByTypeDescription("File Extension");
      this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.enumerationList, this.fileExtensionEnumList, ["File Extension"]);
    }
    this.lookupDataSource = <{ key: string, values: EnumerationModel[] }[]>this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.enumerationList, [this.instanceID]) ?? [];
    this.getComponentProperties(this.IsUnauthorizedPage);

    this.connectionChangedSubscription = this.eventQueue.on(AppEventType.ConnectionStateChanged).
      subscribe(event => this.onConnectionStateChanged(event.payload));
  }

  ngOnDestroy() {
    if (this.connectionChangedSubscription) {
      this.connectionChangedSubscription.unsubscribe();
    }
  }

  async onRibbonTransitionClick(data):Promise<any> {
    this.isTransitionAction = true;
    if (this.dataGrid.instance.hasEditData()) {
      this.dataGrid.instance.saveEditData();
    } 
    else if (this.executeUpdateTransitionStatusFromRibbonCtrl) {
        var queryStringRecordID = await this.GetKeyValueFromQueryString('RecordID');
        this.eventQueue.dispatch(new AppEvent(AppEventType.PerformRibbonTransitionOperation, { NewID: '', updateNewIDWithRecordIDInURL: false, RecordID: queryStringRecordID })); 
    }
    else{
      return true;
    }
  }

  onConnectionStateChanged(payload: any) {
    if (payload?.toString().toUpperCase() == "CONNECTED") {
      this.fetchUpdateFromDB = true;
      this.dataGrid.instance.refresh();
    }
    else {
      this.dataGrid.instance.refresh();
      this.loadDataSource(this.componentSettings, false);
    }
  }

  getComponentProperties(IsUnauthorized: boolean) {
    var ComponentUrl = "";
    if (IsUnauthorized) {
      ComponentUrl = "api/ComponentPropertiesUnauthorized/GetComponentPropertiesData?componentID=";
    }
    else {
      if(this.offlineDataHelper.isOnline){
        ComponentUrl = "api/ComponentProperties/GetComponentPropertiesData?componentID=";
        }
    }
    if (this.gridProperties && this.gridProperties.UILayoutID != Guid.EMPTY) {
      this.isBulkTransitionInstanceSet = true;
      this.isWebpartHeaderHidden = this.gridProperties.isWebpartHeaderHidden;
      this.dbSource = this.gridProperties.Datasource.length != 0 ? this.gridProperties.Datasource : this.gridProperties.ObjectName + 'ListView';
      this.setGridVisibilityViaRole();
      if (this.isGridVisible) {
        this.GetGridSettings(IsUnauthorized);
        this.onResize(this);
      }
    } else {
      this.httpClient.get(environment.apiUrl + ComponentUrl + this.componentID + "&instanceID=" + this.instanceID)
        .subscribe((response: any) => {
          this.setComponentProperties(response.Properties);
          this.setGridVisibilityViaRole();
          if (this.isGridVisible) {
            this.GetGridSettings(IsUnauthorized);
            this.onResize(this);
          }
        });
    }
  }
  getAlllookupDataSource(IsUnauthorized: boolean) {
    this.relationshipColumns = this.componentSettings.UIFields.filter(x => x.OverrideUIType.toLowerCase() == 'relationship');
    if (this.lookupDataSource && this.lookupDataSource.length > 0) {
      this.getAllRelationShipDataSource(IsUnauthorized);

    } else {
      const listEnumColumnValues = this.componentSettings.UIFields
        .filter(x => x.OverrideUIType.toLowerCase() === "enumerationcombobox" || x.OverrideUIType.toLowerCase() === "enumeration")
        .map(x => x.Definition.EnumerationTypeDescription);
      if (listEnumColumnValues?.length > 0) {
        const requests = listEnumColumnValues.map(item =>
          this.httpClient.get(environment.apiUrl + "api/Enumeration/GetEnumerationListByTypeDescription/" + item?.replace('/', '_'))
        );
        forkJoin(requests).pipe(
          map((responses: any[]) => {
            responses.forEach((response, index) => {
              this.lookupDataSource.push({ key: listEnumColumnValues[index], values: response });
            });
            return this.lookupDataSource;
          })
        ).subscribe((lookupData) => {
          this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.enumerationList, lookupData, [this.instanceID]);
          this.getAllRelationShipDataSource(IsUnauthorized);
        });
      }
      else {
          this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.enumerationList, '', [this.instanceID]);
          this.getAllRelationShipDataSource(IsUnauthorized);
       
      }
    }


  }

  getAllRelationShipDataSource(IsUnauthorized: boolean) {
    if (this.relationshipColumns?.length > 0) {
      let completedRequests = 0;
      this.relationshipColumns.forEach((res) => {
      this.httpClient.get(environment.apiUrl + "api/ObjectRecord/GetByName/" + res.FieldName)
        .subscribe((response: any) => {
          this.relationshipLookupDataSource.push({ key: res.FieldName, values: response });
          completedRequests++;
          if (completedRequests === this.relationshipColumns.length) {
            this.LoadGrid(this.componentSettings);
            this.loadDataSource(this.componentSettings, IsUnauthorized);
          }
        });
    });
    }
    else {
      this.LoadGrid(this.componentSettings)
      this.loadDataSource(this.componentSettings, IsUnauthorized)
    }
  }

  setComponentProperties(componentProperties) {

    this.gridProperties.Filter = null;
    var gridProperties = JSON.parse(componentProperties);
    gridProperties.GridProperties.forEach(componentProperty => {
      switch (componentProperty.key.toLowerCase()) {
        case "objectname":
          this.gridProperties.ObjectName = componentProperty.value;
          break;
        case "uilayoutid":
          this.gridProperties.UILayoutID = componentProperty.value;
          break;
        case "datasource":
          this.gridProperties.Datasource = componentProperty.value;
          break;
        case "objectid":
          this.gridProperties.ObjectID = componentProperty.value;
          break;
        case "getrecordsapiendpoint":
          this.gridProperties.GetRecordsApiEndPoint = componentProperty.value;
          break;
        case "title":
          this.gridProperties.Title = componentProperty.value;
          break;
        case "isrelatedcontrol":
          if (componentProperty.value && componentProperty.value.toLowerCase() == "true")
            this.gridProperties.IsRelatedControl = true;
          else
            this.gridProperties.IsRelatedControl = false;
          break;
        case "isdetailcomponent":
          if (componentProperty.value.toLowerCase() == "false")
            this.gridProperties.isDetailComponent = false;
          else
            this.gridProperties.isDetailComponent = true;
          break;
        case "relationshipid":
          this.gridProperties.RelationshipID = componentProperty.value;
          break;
        case "filter":
          this.gridProperties.Filter = componentProperty.value;
          break;
        case "allowedroles":
          if (componentProperty.value && componentProperty.value != null && componentProperty.value.length > 0)
            this.gridProperties.AllowedRoles = componentProperty.value.split(',').map(x => x?.toLowerCase());
          break;
        case "gridpagesize":
          this.gridProperties.GridPageSize = Number(componentProperty.value);
          break;
        case "fieldsettings":
          this.gridProperties.FieldSettings = JSON.parse(componentProperty.value);
          break;
        case "mode":
          this.gridProperties.Mode = componentProperty.value;
          break;
        case "bannerurl":
          this.gridProperties.BannerUrl = componentProperty.value;
          break;
        case "grouping":
          this.gridProperties.Grouping = componentProperty.value;
          break;
        case "showcheckboxesmode":
          this.gridProperties.showCheckBoxesMode = componentProperty.value.toLowerCase();
          break;
        case "cardsettings":
          this.gridProperties.CardSettings = JSON.parse(componentProperty.value);
          break;
        case "griddisplaymode":
          this.gridProperties.GridDisplayMode = componentProperty.value;
          break;
        case "iscommandbuttonsvisible":
          this.gridProperties.IsCommandButtonsVisible = JSON.parse(componentProperty.value);
          break;
        case "lookupdatafilters":
          this.gridProperties.LookUpDataFilters = componentProperty.value;
          break;
        case "componentminheight":
          this.gridProperties.ComponentMinHeight = componentProperty.value;
          break;
        case "componentmaxheight":
          this.gridProperties.ComponentMaxHeight = componentProperty.value;
          break;
        case "iswebpartheaderhidden":
          if (componentProperty.value.toLowerCase() == "false") {

            this.gridProperties.isWebpartHeaderHidden = false;
            this.isWebpartHeaderHidden = false;
          }
          else {
            this.gridProperties.isWebpartHeaderHidden = true;
            this.isWebpartHeaderHidden = true;
          }
          break;
        case "remoteoperations":
          if (componentProperty.value.toLowerCase() == "false")
            this.gridProperties.RemoteOperations = false;
          else
            this.gridProperties.RemoteOperations = true;
          break;
        case "bulkenumerationtransitioninstanceid":
            this.gridProperties.BulkEnumerationTransitionInstanceID = componentProperty.value ?? '';   
            this.isBulkTransitionInstanceSet = true;        
          break;
        case "remeberscrolllocation":
          if (componentProperty.value.toLowerCase() == "false")
            this.gridProperties.RemeberScrollLocation = false;
          else
            this.gridProperties.RemeberScrollLocation = true;
          break;
      case "showgridheadersearch":
        if (componentProperty.value.toLowerCase() == "false")
          this.gridProperties.ShowGridHeaderSearch = false;
        else
        this.gridProperties.ShowGridHeaderSearch = true;
        break;

      }

    });

    this.dbSource = this.gridProperties.Datasource.length != 0 ? this.gridProperties.Datasource : this.gridProperties.ObjectName + 'ListView';

    this.localStorageService.SetLocal(EnumHelper.LocalStorageKey.gridComponentProperties, JSON.stringify(this.gridProperties), [this.instanceID])

  }

  btnBurger: any;
  burgerMenuClick(btnBurger: any, recordID: any,) {
    this.btnBurger = btnBurger;
    this.btnBurger.element.classList.add("hide-btn")
    this.selectedRecordID = recordID;
    this.openBurgerMenu = true;
    setTimeout(() => {
      var burgermenuheight = this.divBurgerMenu.nativeElement.offsetHeight - 24;
      var brgmenu = this.divBurgerMenu.nativeElement


      if ((this.btnBurger.event.clientX < 200) && (window.innerHeight < (this.btnBurger.event.clientY + burgermenuheight + 20))) {
        brgmenu.classList.add("bottomleftout-burger-menu");
        this.burgerMenuPossiton = { top: this.btnBurger.event.clientY + 'px', left: this.btnBurger.event.clientX + 'px', marginTop: '-' + burgermenuheight + 'px' }
      }
      else if (this.btnBurger.event.clientX < 200) {
        brgmenu.classList.add("leftout-burger-menu");
        this.burgerMenuPossiton = { top: this.btnBurger.event.clientY + 'px', left: this.btnBurger.event.clientX + 'px' }

      } else if (window.innerHeight < this.btnBurger.event.clientY + burgermenuheight + 20) {
        brgmenu.classList.add("bottomout-burger-menu");
        this.burgerMenuPossiton = { top: this.btnBurger.event.clientY + 'px', left: this.btnBurger.event.clientX + 'px', marginTop: '-' + burgermenuheight + 'px' }
      } else {
        this.burgerMenuPossiton = { top: this.btnBurger.event.clientY + 'px', left: this.btnBurger.event.clientX + 'px' }
      }
    }, 1);




  }
  burgerMenuClickClose() {
    this.selectedRecordID = null;
    this.openBurgerMenu = false;
    this.divBurgerMenu.nativeElement.classList.remove("leftout-burger-menu");
    this.divBurgerMenu.nativeElement.classList.remove("bottomout-burger-menu");
    this.divBurgerMenu.nativeElement.classList.remove("bottomleftout-burger-menu");

    this.btnBurger.element.classList.remove("hide-btn");
    this.burgerMenuPossiton =null;
  }



  onExporting(e: any) {
    switch (e.format.toLowerCase()) {
      case 'pdf':
        const doc = new jsPDF();
        exportPDF({
          jsPDFDocument: doc,
          component: e.component,
          indent: 5,

        }).then(() => {
          doc.save(this.instanceID + '.pdf');
        });
        break;
      case 'xlsx':
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(this.instanceID);
        exportDataGrid({
          component: e.component,
          worksheet,
          autoFilterEnabled: true,

        }).then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
          });
        });
        break;
    }
  }


  async loadDataSource(gridSettings: ComponentSettingsModel, IsUnauthorized: boolean) {
    var httpClient = this.httpClient;
    var baseAPIURL = environment.apiUrl;
    var componentInfo = this.gridProperties;
    var cacheSize = this.cacheSize;
    var isCachable = this.isCachable;
    var offlineDataHelper = this.offlineDataHelper;
    var dbSourceName = this.dbSource;
    var TimeZoneAdjustedcolumn = gridSettings.UIFields.filter(x => !x.Definition.TimeZoneAdjusted).map(y=>y.FieldName);
    let showNoDataFoundInOfflineModeView = () => {
      this.NoDataInOffline = false;
      this.isVisible = true;
    }
    let hideNoDataFoundInOfflineModeView = () => {
      this.NoDataInOffline = true;
      this.isVisible = false;
    }
    var queryStringRecordID = await this.GetKeyValueFromQueryString('RecordID');
    var RecordId = this.recordID;
    var filter = null;
    if (this.gridProperties && this.gridProperties != null && this.gridProperties.Filter != null) {
      filter = this.gridProperties.Filter;
      for (let i = 0; i < filter.length; i++) {
        for (let j = 0; j < filter[i].length; j++) {
          if (filter[i][j] === "RecordID") {
            if (!RecordId && this.newID) {
              filter[i][j] = this.newID;
            }
            else {
              filter[i][j] = RecordId;
            }
          }
        }
      }
    }
    let RedirectUrl = () => {
      this.route.url.subscribe((event) => {
        this.RedirectToEncryptUrl(event[0].path + '?RecordID=' + this.newID);
      });
    }
    if(componentInfo.GetRecordsApiEndPoint)
    {
    this.dataSource = new CustomStore({
      key: 'RecordID',
      byKey: (key) => {
        return key;
      },
      async load(loadOptions: any) {
        var localData = await offlineDataHelper.getGridDataViaGridID(dbSourceName);
        let params: HttpParams = new HttpParams();
        [
          'skip',
          'take',
          'requireTotalCount',
          'requireGroupCount',
          'sort',
          'filter',
          'totalSummary',
          'group',
          'groupSummary',
          'timezoneAdjustOffFields'
        ].forEach((i) => {
          if (i == 'filter' ) {
            var filterArray = loadOptions[i];
            if (filterArray) {
              if (filterArray.length > 0) {
                if (!(filterArray[0] instanceof Array)) {
                  var array = filterArray;
                  filterArray = [];
                  filterArray[0] = array;
                }
              }
            }
            else {
              filterArray = []
            }
            if (componentInfo.isDetailComponent && !componentInfo.IsRelatedControl) {

              if (filterArray.length > 0) {
                filterArray[filterArray.length] = "and";
              }
              filterArray[filterArray.length] = ['RecordID', '=', RecordId];
            } else {
              if (localData != null && localData.syncDate != null) {
                // filterArray[filterArray.length] = ['SyncDate', '>', localData.syncDate.toJSON()];
              }
            }
            if (filter != null) {
              if (!(filter[0] instanceof Array)) {
                var array = filter;
                filter = [];
                filter[0] = array;
              }
              var customFillter = [];
              if (filterArray.length > 0) {
                customFillter[customFillter.length] = filterArray;
                customFillter[customFillter.length] = "and"

              }
              customFillter[customFillter.length] = filter;
              filterArray = customFillter;
            }
            loadOptions[i] = filterArray;
          }
          if (i === 'timezoneAdjustOffFields' && TimeZoneAdjustedcolumn.length>0) {
            loadOptions[i] = TimeZoneAdjustedcolumn;
        }
          if (i in loadOptions && loadOptions[i] !== undefined && loadOptions[i] !== null && loadOptions[i] !== '') { params = params.set(i, JSON.stringify(loadOptions[i])); }
        });
        if ((offlineDataHelper.isOnline)) {
          hideNoDataFoundInOfflineModeView();
          var response = lastValueFrom(httpClient.get(
            baseAPIURL + componentInfo.GetRecordsApiEndPoint, { params }))
            .then((data: any) => (
              {
                data: data.data,
                totalCount: data.totalCount,
                summary: data.summary,
                groupCount: data.groupCount,
              }),
              error => { })
            .finally(() => {
            });

          if (isCachable) {
            offlineDataHelper.bulkInsertData(dbSourceName, response, cacheSize);
          }
          return response;
        }
        else {
          if (localData && localData.data.length > 0) {
            hideNoDataFoundInOfflineModeView();
          } else {
            showNoDataFoundInOfflineModeView();
          }

          var arrayStore = new ArrayStore({
            data: localData?.data,
            key: "RecordID",
          });

          var result = await arrayStore.load(loadOptions).then((data: any) => ({
            data: data,
            totalCount: data.length,
            summary: null,
            groupCount: -1,
          }));
          if (result) {
          }
          return result;
        }
      },
      onLoaded: function (result) {

      },
      insert: async (values: any) => {
        if (offlineDataHelper.isOnline) {
          if (this.newID) {
            values.RecordID = this.newID;
          }
          var relationshipDict: any = {};

          var url = baseAPIURL + gridSettings.Commands?.find(x => x.Command.toLocaleLowerCase() == 'insert'||x.Command.toLocaleLowerCase() == 'updatedetail').Definition.APIActionEndPoint;
         

          if (this.gridProperties.RelationshipID !== "" &&
            gridSettings.Commands?.find(x => x.Command.toLocaleLowerCase() == 'insert' || x.Command.toLocaleLowerCase() == 'saveall').Definition.APIActionEndPointWithRelationship !== null) {
            url = baseAPIURL + gridSettings.Commands?.find(x => x.Command.toLocaleLowerCase() == 'insert' || x.Command.toLocaleLowerCase() == 'saveall').Definition.APIActionEndPointWithRelationship
              + this.parentRecordId + "/" + this.gridProperties.RelationshipID;
          }
          else
          {
              if(url.indexOf("insertnew") > 0){
                relationshipDict.FieldValues = values;
                if(this.gridProperties.RelationshipID !== "")
                {
                  this.relationshipValues["ParentRecordID"] = this.parentRecordId;
                  this.relationshipValues["RelationshipID"] = this.gridProperties.RelationshipID;
                }
      
                if(Object.keys(this.relationshipValues).length > 0 ) {
                  
                  relationshipDict.RelationshipValues = this.relationshipValues
                  
                }   
                if(TimeZoneAdjustedcolumn.length > 0 ) {
                  relationshipDict.TimezoneAdjustOffFields =TimeZoneAdjustedcolumn;
                }                    
                values = relationshipDict;
            }
          }

          return lastValueFrom(
            this.httpClient.post(
              url
              , values,
              {
                headers: {
                  'objectID': this.gridProperties.ObjectID
                },
                responseType: "text"
              }
            )).then(response => {
              if (this.executeUpdateTransitionStatusFromRibbonCtrl && this.isTransitionAction) {
                this.isTransitionAction = false;
                this.eventQueue.dispatch(new AppEvent(AppEventType.PerformRibbonTransitionOperation, { NewID: this.newID, updateNewIDWithRecordIDInURL: true, RecordID: queryStringRecordID }));
              } else {                
                var InsertSuccessMessage:string="";
                this.translateService.get('InsertSuccessMessage').subscribe((translated: string) => {
                  InsertSuccessMessage = translated;
                });
                this.alertService.showAlertPopup(this.successAlertTitle,InsertSuccessMessage, 'fa-solid fa-check',this.okAlertBtnTxt).then(response => {
                    if (this.newID && this.saveAll) {
                      this.saveAll = false;
                      RedirectUrl();
                    }
                  }
                  )
              }
            })
            .catch((ex) => { throw ex.message });
        } else {
          return await offlineDataHelper.insertRecord(this.dbSource, values, this.gridProperties.RelationshipID, gridSettings.Commands?.find(x => x.Command.toLocaleLowerCase() == 'insert' || x.Command.toLocaleLowerCase() == 'updatedetail'), this.gridProperties.ObjectID).then(
            response => {
              var InsertSuccessMessageOffline:string="";
                this.translateService.get('InsertSuccessMessageOffline').subscribe((translated: string) => {
                  InsertSuccessMessageOffline = translated;
                });
              this.alertService.showAlertPopup(this.successAlertTitle, InsertSuccessMessageOffline, 'fa-solid fa-check',this.okAlertBtnTxt);
              this.dataGrid.instance.refresh();
            }
          );
        }
      },
      update: (key, values) => {
        if (offlineDataHelper.isOnline) {
          var apiEndPoint = gridSettings.Commands?.find(x => x.Command.toLocaleLowerCase() == 'update'
            || x.Command.toLocaleLowerCase() == 'saveall')?.Definition.APIActionEndPoint;
            var url = baseAPIURL;
            if(apiEndPoint !=null ){
              url = url + apiEndPoint + key;
            }
            else
            {
              apiEndPoint = gridSettings.Commands?.find(x => x.Command.toLocaleLowerCase() == 'updatedetail').Definition.APIUpdateActionEndPoint;
              url = url + apiEndPoint + key;
            }    
              if(url.indexOf("updatenew") > 0){
              var relationshipDict:any={};
              relationshipDict.FieldValues = values;
              if(Object.keys(this.relationshipValues).length > 0 ) {                
                relationshipDict.RelationshipValues = this.relationshipValues                
              }
              if(TimeZoneAdjustedcolumn.length > 0 ) {
                relationshipDict.TimezoneAdjustOffFields =TimeZoneAdjustedcolumn;   
              }  
              values=relationshipDict;
            }
            
          return lastValueFrom(this.httpClient.put(url, values
            ,
            {
              headers: {
                'objectID': this.gridProperties.ObjectID
              },
              responseType: "text"
            }
          )).then(response => {
            if (this.executeUpdateTransitionStatusFromRibbonCtrl && this.isTransitionAction) {
              this.isTransitionAction = false;
              this.eventQueue.dispatch(new AppEvent(AppEventType.PerformRibbonTransitionOperation, { NewID: this.newID, updateNewIDWithRecordIDInURL: false, RecordID: queryStringRecordID }));
            }
            else {
              
              var UpdateSuccessMessage:string="";
              this.translateService.get('UpdateSuccessMessage').subscribe((translated: string) => {
                UpdateSuccessMessage = translated;
              });
              this.alertService.showAlertPopup(this.successAlertTitle,UpdateSuccessMessage, 'fa-solid fa-check',this.okAlertBtnTxt);
              this.eventQueue.dispatch(new AppEvent(AppEventType.ActionUpdateSuccess, true));
            }
          })
            .catch((ex) => { throw ex.message });
        } else {
          return offlineDataHelper.updateRecord(dbSourceName, key, values, gridSettings.Commands?.find(x => x.Command.toLocaleLowerCase() == 'update' || x.Command.toLowerCase() == "updatedetail")[0],this.gridProperties.ObjectID)
            .then(response => {
              var UpdateSuccessMessageOffline:string="";
              this.translateService.get('UpdateSuccessMessageOffline').subscribe((translated: string) => {
                UpdateSuccessMessageOffline = translated;
              });
              this.alertService.showAlertPopup(this.successAlertTitle,UpdateSuccessMessageOffline, 'fa-solid fa-check',this.okAlertBtnTxt);
              this.dataGrid.instance.refresh();
            })
        }
      },
    },);
    }
    this.isCustomStoreLoaded.emit(true);
  }

  setGridVisibilityViaRole() {
    var userRoles = <string[]>JSON.parse(this.localStorageService.GetLocal(EnumHelper.LocalStorageKey.userRoles));
    if (userRoles && this.gridProperties.AllowedRoles.length != 0) {
      this.isUserHavePermissionToViewGrid = false;
      userRoles.forEach(val => {
        if (this.gridProperties.AllowedRoles.findIndex(y => y.toLowerCase() === val.toLowerCase()) != -1) {
          this.isUserHavePermissionToViewGrid = true;
        }
      });

      if (!this.isUserHavePermissionToViewGrid) {
        this.isGridVisible = false;
        this.isGridHidden.emit(true);
      }
      else {
        this.isGridVisible = true;
        this.isGridHidden.emit(false);
      }
    }
    else {
      this.isGridVisible = true;
      this.isGridHidden.emit(false);
    }
  }

  GetGridSettings(IsUnauthorized): void {
    this.dataSourceName = this.gridProperties.Datasource.length > 0 ? this.gridProperties.Datasource : this.gridProperties.ObjectName + 'ListView';
    if (this.gridProperties.ObjectName == '') {
      this.alertService.showAlertPopup(this.errorAlertTitle, 'Object name is not set in Grid properties', 'fa-solid fa-xmark', this.okAlertBtnTxt)
      return;
    }
    var requestData: any = {};
    requestData.ComponentId = this.instanceID;
    requestData.UILayoutID = this.gridProperties.UILayoutID;
    requestData.Datasource = this.gridProperties.ObjectName.toLowerCase() === 'objectrecord' ? '' : this.dataSourceName;
    requestData.ObjectID = this.gridProperties.ObjectID;
    var UILayoutDefinitionUrl = "";
    if (IsUnauthorized) {
      UILayoutDefinitionUrl = "api/UILayoutDefinitionUnauthorized/GetUILayoutData/";
    }
    else {
      UILayoutDefinitionUrl = "api/UILayoutDefinition/GetUILayoutData/";
    }

    if (this.componentSettings && this.componentSettings.UIFields && this.componentSettings.UIFields.length > 0) {
      this.SetupGridCommandsAndBannerProperties(IsUnauthorized);
    } else {
      if (this.offlineDataHelper.isOnline) {
        this.httpClient.post(environment.apiUrl + UILayoutDefinitionUrl, requestData)
          .subscribe((response) => {
            this.componentSettings = <ComponentSettingsModel>response;
            this.localStorageService.SetLocal(EnumHelper.LocalStorageKey.componentSettings, JSON.stringify(this.componentSettings), [this.instanceID]);
            this.SetupGridCommandsAndBannerProperties(IsUnauthorized);
          });
      }
    }
  }

  SetupGridCommandsAndBannerProperties(IsUnauthorized: boolean) {
    this.isCachable = this.componentSettings.ObjectDataset.IsCacheEnabled;
    this.cacheSize = this.componentSettings.ObjectDataset.RecordsToCache;
    this.rowClickCommandAction = this.componentSettings.Commands.filter(x => x.Command?.toLowerCase() === "rowclick")[0];
    this.burgerMenuActions = this.componentSettings.Commands.filter(x => {
      return x.Definition.VisibilityOption.some(y => y.Type.toLowerCase().includes("context"))
    });

    if ((this.gridProperties.IsCommandButtonsVisible && this.gridProperties.isDetailComponent) || !this.gridProperties.isDetailComponent) {
      this.commandButtonActions = this.componentSettings.Commands.filter(x => {
        return x.Definition.VisibilityOption.some(y => y.Type.toLowerCase().includes("button"))
      });
    }

    if (this.gridProperties.BannerUrl !== null && this.gridProperties.BannerUrl !== "") {
      this.hideLink = false;
    }
    this.getAlllookupDataSource(IsUnauthorized);

  }

  LoadBurgerMenu(columns): any {
    if (this.burgerMenuActions && this.burgerMenuActions.length > 0) {
      columns.push({
        formItem: { visible: false },
        caption: "Action",
        width: "70px",
        minWidth:"70px" ,
        allowResizing:false,
        cssClass: "lobas-grid-td text-left",
        cellTemplate: this.myCellTemplate
      });
    }
  }
  // Function to filter values based on key
  filterValuesByKey(keyToFilter,addEmptyColumn=false) {
    var defaultFilter;
    var filteredItem = this.lookupDataSource?.find(item => item.key === keyToFilter);
    if (this.gridProperties.LookUpDataFilters) {
      filteredItem.values = filteredItem?.values.filter(item1 => !!this.gridProperties.LookUpDataFilters.FilterValues.find(item2 => item1?.EnumerationId == item2));
    }
    if(addEmptyColumn)
    {
       var emptycolumn=new EnumerationModel();
       emptycolumn.EnumerationId=Guid.EMPTY
       emptycolumn.Value='';
       filteredItem.values.unshift(emptycolumn);
    }
    return filteredItem ? filteredItem.values : [];
  }
  filterValuesByKeyRelation(keyToFilter, dataSource,addEmptyColumn=false) {
    var filteredItem = dataSource?.find(item => item.key === keyToFilter);
     if(addEmptyColumn)
     {
       var emptycolumn=new ObjectModel();
       emptycolumn.RecordID=Guid.EMPTY;
       emptycolumn.LookupValue='';
       filteredItem.values.unshift(emptycolumn);
     }
    return filteredItem ? filteredItem.values : [];
  }
  getEnumColumnDefinition(item: UILayoutFieldModel, dataType: string): any {
    var addInlineGroup:boolean = false;
    if(window.innerWidth < 767 && item.Definition.DeviceWidth!=0) {
      item.Definition.ColumnWidth=item.Definition.DeviceWidth;
     }

     if (this.gridProperties.Grouping && this.gridProperties.Grouping.length > 0) {
      this.gridProperties.Grouping.forEach(element => {
        if(this.IsInLineGrouping && element.GroupIndex ===0 && element.FieldName.toLowerCase() ===  item.FieldName.toLowerCase()){
          addInlineGroup = true
        }
      });
    }
    var enableFiltering: boolean = true;
    let FieldName = item.Definition.FieldUICaption == null || item.Definition.FieldUICaption == "" ? item.FieldName : item.Definition.FieldUICaption;

    var validationRules: any = [];
     
    if (item.Definition.ValidationRules && item.Definition.ValidationRules != null) {
      validationRules = JSON.parse(item.Definition.ValidationRules);
    }

    var width = undefined;
    var minWidth = undefined;
    var isReziable = true;
    if(this.getColumnWidth.getColumnDefaultWidth(item.OverrideUIType)>0) {
      width = this.getColumnWidth.getColumnDefaultWidth(item.OverrideUIType) + "px";
      minWidth = width;
      isReziable = false;
    }
    else {
      if(item.Definition?.ColumnWidth>0 || item.Definition?.DeviceWidth>0) { 
        width = item.Definition?.ColumnWidth>0 ? item.Definition?.ColumnWidth + "px" : undefined;  
        minWidth = item.Definition?.DeviceWidth>0 ? item.Definition?.DeviceWidth + "px" : undefined;     
      }
    }

    if(item.Definition?.AllowResize) { 
      isReziable = true;
      width= undefined;
      minWidth= undefined;
    }

    return {
      allowFiltering: enableFiltering,
      caption: FieldName,
      allowEditing: !item.Definition.ReadOnly && item.Definition.Derived==null,
      dataType: dataType, fieldName: item.FieldName,
      dataField: item.FieldName,
      visible: false,
      cssClass: item.Definition.HorizonalAlign.toLowerCase() === 'ltr' ? "lobas-grid-td text-left" : 'lobas-grid-td text-right',
      formItem: { visible: (item.Definition.IsEditable && !item.Definition.ReadOnly) ||
                (item.Definition.IsEditable && item.Definition.ReadOnly && item.Definition.DefaultValue.length>0) ||
                (item.Definition.IsEditable && item.Definition.ReadOnly  && this.newID.length===0), label: { showColon: false, text: FieldName } },
      hidingPriority: this.applyAdaptivePriority ? item.Definition?.AdaptivePriority : null,
      width: width,
      minWidth: minWidth,
      validationRules: validationRules,
      searchEnabled: true,
      searchExpr: 'Value',
      searchOperation: "contains",
      searchValue: 'Value',
      readOnly: item.Definition.ReadOnly,
      lookup
        : {
        dataSource: this.filterValuesByKey(item.Definition.EnumerationTypeDescription,!item.Definition.Required),
        displayExpr: 'Value',
        valueExpr: 'EnumerationId',
      },
      calculateGroupValue: addInlineGroup ? this.calculateGroupVal : '',
      allowResizing: isReziable
    }
  }

  getRelationshipColumnDefinition(item: UILayoutFieldModel, dataType: string): any {
    var enableFiltering: boolean = true;
    let FieldName = item.Definition.FieldUICaption == null || item.Definition.FieldUICaption == "" ? item.FieldName : item.Definition.FieldUICaption;

    var validationRules: any = [];

    if (item.Definition.ValidationRules && item.Definition.ValidationRules != null) {
      validationRules = JSON.parse(item.Definition.ValidationRules);
    }

    if (item.Definition?.RelationshipID != null) {
      if (this.relationshipValues[item.FieldName] == undefined) {
        this.relationshipValues[item.FieldName] = item.Definition.RelationshipID;
      }
    }
    var width;
    var minWidth;
    var isReziable = true;
    if(this.getColumnWidth.getColumnDefaultWidth(item.OverrideUIType)>0) {
      width = this.getColumnWidth.getColumnDefaultWidth(item.OverrideUIType);
      isReziable = false;
    }
    if(this.getColumnWidth.getColumnDefaultWidth(item.OverrideUIType)>0) {
      width = this.getColumnWidth.getColumnDefaultWidth(item.OverrideUIType) + "px";
      minWidth = width;
      isReziable = false;
    }
    else {
      if(item.Definition?.ColumnWidth>0) { 
        width = item.Definition?.ColumnWidth + "px";
        minWidth = width;
      }
      else {
        width = undefined;
        minWidth = undefined;
      }
    }
    return {
      allowFiltering: enableFiltering,
      caption: FieldName,
      allowEditing: !item.Definition.ReadOnly && item.Definition.Derived==null,
      dataType: dataType, fieldName: item.FieldName,
      dataField: item.FieldName,
      cssClass: item.Definition.HorizonalAlign.toLowerCase() === 'ltr' ? "lobas-grid-td text-left" : 'lobas-grid-td text-right',
      formItem: { visible: (item.Definition.IsEditable && !item.Definition.ReadOnly) ||
        (item.Definition.IsEditable && item.Definition.ReadOnly && item.Definition.DefaultValue.length>0) ||
        (item.Definition.IsEditable && item.Definition.ReadOnly  && this.newID.length===0), label: { showColon: false, text: FieldName } },
      hidingPriority: this.applyAdaptivePriority ? item.Definition?.AdaptivePriority : null,
      width: width,
      minWidth: minWidth,
      validationRules: validationRules,
      searchEnabled: true,
      readOnly: item.Definition.ReadOnly,
      lookup
        : {
        dataSource: this.filterValuesByKeyRelation(item.FieldName, this.relationshipLookupDataSource,!item.Definition.Required),  
        displayExpr: 'LookupValue',
        valueExpr: 'RecordID',
      }
    }
  }


  LoadGrid(response: ComponentSettingsModel) {
    this.componentSettings = response;
    this.gridSetting = JSON.parse(this.componentSettings.Settings.Settings);
    if (this.gridProperties.isDetailComponent && this.gridProperties.FieldSettings != null && this.gridProperties.FieldSettings?.Info?.length > 0) {
      this.ishideInfoIcon = false;
    }
    if (this.gridProperties.isDetailComponent && this.gridProperties.FieldSettings?.Items?.length > 0) {
      this.listFilteredColumn = this.getFillteredColumn(this.gridProperties.FieldSettings.Items)
    }
    else {
      this.listFilteredColumn = this.componentSettings.UIFields;
      this.listFilteredColumn.sort((a, b) => a.Definition.VisibleIndex - b.Definition.VisibleIndex);
      this.relationshipColumns = this.componentSettings.UIFields
        .filter(x => x.OverrideUIType.toLowerCase() == 'relationship');
    }
    if (this.gridProperties.CardSettings?.length > 0) {
      this.listCardColumn = this.getFillteredColumn(this.gridProperties.CardSettings)
    }
    else {
      this.listCardColumn = this.listFilteredColumn;
    }

    var columns =
      this.listFilteredColumn.map(item => {
        switch (item.OverrideUIType.toLowerCase()) {
          case 'datetime':
            return this.getColumnDefinition(item, "datetime", '');
            break;
          case 'date':
            return this.getColumnDefinition(item, "date", '');
            break;
          case 'time':
            return this.getColumnDefinition(item, "time", '');
            break;
          case 'checkbox':
            return this.getColumnDefinition(item, "boolean", '');
            break;
          case 'uicolor':
            return this.getColumnDefinition(item, "string", 'dxColorBox');
            break;
          case 'intinputbox':
          case 'bigintinputbox':
          case 'decimalinputbox':
          case 'currencyinputbox':
            return this.getColumnDefinition(item, "number", 'dxNumberBox');
            break;
          case 'radiobuttontruefalse':
          case 'radiobuttonyesno':
            return this.getRadioButtonsColumnDefinition(item, "boolean", 'dxRadioGroup');
            break;
          case 'enumeration':
          case 'enumerationcombobox':
            return this.getEnumColumnDefinition(item, "string");
            break;
          case 'combobox':
            return this.getColumnDefinition(item, "string", '');
            break;
          case 'textarea':
            return this.getColumnDefinition(item, "string", "dxTextArea");
            break;
          case 'url':
            return this.getURLColumnDefinition(item, "string", "");
            break;
          case 'empty':
            return this.getColumnDefinition(item, "empty", "");
            break;
          case 'relationship':
            return this.getRelationshipColumnDefinition(item, "string");
            break;
          default:
            return this.getColumnDefinition(item, "string", '');
            break;
        }
      });


    if(this.gridProperties.Grouping && this.gridProperties.Grouping.length >0 &&
        this.gridProperties.Grouping.find(x=>x.FieldName.toLowerCase() == "all")){     
        this.dataGrid.grouping = { expandMode:'buttonClick', contextMenuEnabled : true, autoExpandAll:true }; 
        this.dataGrid.instance.option("toolbar.visible",true);
    }else{
      this.dataGrid.grouping = { expandMode:'buttonClick', contextMenuEnabled : false, autoExpandAll:true }
      this.dataGrid.instance.option("toolbar.visible",false);
    }
      
    var EmptyColumnChooserTxt:string="";
    this.translateService.get('EmptyColumnChooserTxt').subscribe((translated: string) => {
      EmptyColumnChooserTxt = translated;
    });
    this.dataGrid.columnHidingEnabled = this.columnHidingEnabled;
    this.filterBuilderFields = columns.filter(x => x.allowFiltering);
    this.dataGrid.showBorders = true;
    this.dataGrid.columnChooser = { height: 305, enabled: true, mode: 'dragAndDrop', search: { enabled: true }, sortOrder: 'desc', emptyPanelText: EmptyColumnChooserTxt, position: { offset: '0 0' } };
    this.dataGrid.allowColumnReordering = true;
    this.getSetting = this.getSetting.bind(this);
    this.setSettings = this.setSettings.bind(this);
    this.dataGrid.stateStoring.customLoad = this.getSetting;
    this.dataGrid.stateStoring.customSave = this.setSettings;
    this.dataGrid.stateStoring.type = "custom";
    this.dataGrid.stateStoring.enabled = true;
    this.dataGrid.instance.state(this.gridSetting);
    this.dataGrid.columns = columns;
    this.dataGrid.instance.refresh();
    //this.dataGrid.paging.enabled=this.Paging;

    if (this.dataGrid.filterValue) {
      this.filter = this.dataGrid.filterValue;
    }
    if (this.dataGrid.searchPanel.text) {
      this.searchPanelText = this.dataGrid.searchPanel.text;
    }
    if (this.gridProperties.isDetailComponent && this.gridProperties.Mode.toLowerCase() === "edit") {
      this.isGridview = false;
      this.dataRowTemplateName = 'dataRowTemplate';
      this.dataGrid.filterRow.visible = true;
      this.dataGrid.showColumnHeaders = false;
      this.dataGrid.instance.option("editing.mode", "form");
      if (this.recordID && this.recordID.length > 0)
        this.editRowKey = this.recordID;
      this.detailViewEditMode(false);
    }
    else {
      if (!this.gridProperties.isDetailComponent) {
        this.LoadBurgerMenu(columns);
      }
      this.dataGrid.instance.option("editing.mode", "popup");
      this.dataGrid.showColumnHeaders = true;
      this.showFilterSearch = this.gridProperties.ShowGridHeaderSearch
    }
  }
  getRadioButtonsColumnDefinition(item: UILayoutFieldModel, dataType: string, editorType: string): any {
    if(window.innerWidth < 767 && item.Definition.DeviceWidth!=0) {
      item.Definition.ColumnWidth=item.Definition.DeviceWidth;
     }
     var addInlineGroup:boolean = false;
     if (this.gridProperties.Grouping && this.gridProperties.Grouping.length > 0) {
      this.gridProperties.Grouping.forEach(element => {
        if(this.IsInLineGrouping && element.GroupIndex ===0 && element.FieldName.toLowerCase() ===  item.FieldName.toLowerCase()){
          addInlineGroup = true
        }
      });
    }

    var items = [
      { id: true, text: "Yes" },
      { id: false, text: "No" },
    ];

    if (item.OverrideUIType.toLowerCase() == "radiobuttontruefalse") {
      items = [
        { id: true, text: "True" },
        { id: false, text: "False" },
      ];
    }
    else if (item.OverrideUIType.toLowerCase() == "radiobuttonyesno") {
      items = [
        { id: true, text: "Yes" },
        { id: false, text: "No" },
      ];
    }

    let FieldName = item.Definition.FieldUICaption == null || item.Definition.FieldUICaption == "" ? item.FieldName : item.Definition.FieldUICaption
    var validationRules: any = [];
    if (item.Definition.Required) {
      validationRules = [
        {
          type: 'custom',
          message: FieldName + ' is Required',
          validationCallback: function (e: any) {
            if (e.value === false || e.value === true) {
              return true;
            }
            else {
              return false;
            }
          }
        }
      ];
    }

    var width;
    var minWidth;
    var isReziable = true;
    if(this.getColumnWidth.getColumnDefaultWidth(item.OverrideUIType)>0) {
      width = this.getColumnWidth.getColumnDefaultWidth(item.OverrideUIType);
      isReziable = false;
    }
    if(this.getColumnWidth.getColumnDefaultWidth(item.OverrideUIType)>0) {
      width = this.getColumnWidth.getColumnDefaultWidth(item.OverrideUIType) + "px";
      minWidth = width;
      isReziable = false;
    }
    else {
      if(item.Definition?.ColumnWidth>0) { 
        width = item.Definition?.ColumnWidth + "px";
        minWidth = width;
      }
      else {
        width = undefined;
        minWidth = undefined;
      }
    }

    return {
      allowFiltering: true,
      caption: FieldName,
      allowEditing: !item.Definition.ReadOnly && item.Definition.Derived==null,
      dataType: dataType,
      fieldName: item.FieldName,
      dataField: item.FieldName,
      visible: false,
      showRequiredMark: true,
      hidingPriority: this.applyAdaptivePriority ? item.Definition?.AdaptivePriority : null,
      width: width,
      minWidth: minWidth,
      formItem: {
        visible: (item.Definition.IsEditable && !item.Definition.ReadOnly) || (item.Definition.IsEditable && item.Definition.ReadOnly && this.newID.length===0), label: { showColon: false, text: FieldName },
        editorType: editorType, editorOptions: {
          items: items,
          valueExpr: "id",
          displayExpr: "text",
          layout: 'horizontal'
        }
      },
      onValueChanged: function (e) {
        this.dataSource[e.rowIndex].choice = e.value;
      },
      cssClass: item.Definition.HorizonalAlign.toLowerCase() === 'ltr' ? "lobas-grid-td text-left" : 'lobas-grid-td text-right',
      validationRules: validationRules,
      readOnly: item.Definition.ReadOnly,
      searchEnabled: true,
      searchExpr: 'id',
      searchOperation: "contains",
      searchValue: 'id',
      lookup
        : {
        dataSource: items,
        displayExpr: 'text',
        valueExpr: 'id',
      },
      calculateGroupValue: addInlineGroup ? this.calculateGroupVal : '',
      allowResizing: isReziable
    }
  }

  getURLColumnDefinition(item: UILayoutFieldModel, dataType: string, editorType: string): any {
    var addInlineGroup:boolean = false;
    var format: any;
    var editorOptions: any;
    var enableFiltering: boolean = true;
    var validationRules: any = [];
    var customValidationRule: any;


    if(window.innerWidth < 767 && item.Definition.DeviceWidth!=0) {
      item.Definition.ColumnWidth=item.Definition.DeviceWidth;
     }

     if (this.gridProperties.Grouping && this.gridProperties.Grouping.length > 0) {
      this.gridProperties.Grouping.forEach(element => {
        if(this.IsInLineGrouping && element.GroupIndex ===0 && element.FieldName.toLowerCase() ===  item.FieldName.toLowerCase()){
          addInlineGroup = true
        }
      });
    }

    let FieldName = item.Definition.FieldUICaption == null || item.Definition.FieldUICaption == "" ?
      item.FieldName : item.Definition.FieldUICaption;
    const pattern = "^[a-zA-Z0-9~@#$^*()_+=[\\]{}|\\\\,.?:/-<>'-/;%]*$"

    customValidationRule = {
      type: "pattern",
      message: "The " + FieldName + " format is incorrect",
      pattern: pattern,
      validationCallback: e => {
        this.customValidator = e.validator;
        const gridInstance = this?.dataGrid?.instance;
        gridInstance?.repaint();
        // @ts-expect-error - getController is a private method
        gridInstance?.getController('validating').validate(false);
        return true;
      },
      reevaluate: true
    };
    validationRules.push(customValidationRule);

    if (item.Definition.ValidationRules && item.Definition.ValidationRules != null) {
      validationRules = JSON.parse(item.Definition.ValidationRules);
    }
    var width;
    var minWidth;
    var isReziable = true;
    if(this.getColumnWidth.getColumnDefaultWidth(item.OverrideUIType)>0) {
      width = this.getColumnWidth.getColumnDefaultWidth(item.OverrideUIType);
      isReziable = false;
    }
    if(this.getColumnWidth.getColumnDefaultWidth(item.OverrideUIType)>0) {
      width = this.getColumnWidth.getColumnDefaultWidth(item.OverrideUIType) + "px";
      minWidth = width;
      isReziable = false;
    }
    else {
      if(item.Definition?.ColumnWidth>0) { 
        width = item.Definition?.ColumnWidth + "px";
        minWidth = width;
      }
      else {
        width = undefined;
        minWidth = undefined;
      }
    }

    return {
      allowFiltering: enableFiltering,
      caption: FieldName,
      allowEditing: !item.Definition.ReadOnly && item.Definition.Derived==null,
      dataType: dataType,
      editorOptions: editorOptions,
      fieldName: item.FieldName,
      dataField: item.FieldName,
      helpText: FieldName,
      visible: false,
      readOnly: item.Definition.ReadOnly,
      cssClass: item.Definition.HorizonalAlign.toLowerCase() === 'ltr' ? "lobas-grid-td text-left" : 'lobas-grid-td text-right',
      formItem: { visible: (item.Definition.IsEditable && !item.Definition.ReadOnly) || (item.Definition.IsEditable && item.Definition.ReadOnly && this.newID.length===0), label: { showColon: false, text: FieldName }, editorType: editorType },
      format: format,
      hidingPriority: this.applyAdaptivePriority ? item.Definition?.AdaptivePriority : null,
      width: width,
      minWidth:minWidth,
      validationRules: validationRules,
      editCellTemplate: item.OverrideUIType.toLowerCase().includes('edittemplate') ? item.OverrideUIType : '',
      calculateGroupValue: addInlineGroup ? this.calculateGroupVal : '',
      allowResizing: isReziable

    }
  }

  getColumnDefinition(item: UILayoutFieldModel, dataType: string, editorType: string): any {
    if(window.innerWidth < 767 && item.Definition.DeviceWidth!=0) {
      item.Definition.ColumnWidth=item.Definition.DeviceWidth;
     }
    var format: any;
    var editorOptions: any;
    var enableFiltering: boolean = true;
    var validationRules: any = [];
    var customValidationRule: any;
    var addInlineGroup:boolean = false;
    var colGroupIndex:number = null;

    if (item.OverrideUIType.toLowerCase() == 'icon' || item.FieldName.toLowerCase() == 'fileimg' || item.OverrideUIType.toLowerCase() == 'usericon') {
      enableFiltering = false;
    }
    if (item.OverrideUIType.toLowerCase() == "decimalinputbox") {
      var addDefaultValidation = true;

      let Fieldformat = item.Definition.Format == null || item.Definition.Format == "" ? "###0.00" : item.Definition.Format;
      editorOptions = { showClearButton: true, showSpinButtons: true, format: Fieldformat };

      if (item.Definition.ValidationRules && item.Definition.ValidationRules != null) {
        validationRules = JSON.parse(item.Definition.ValidationRules);

        validationRules.forEach((element, index) => {
          if (validationRules[index].type === "range") {
            addDefaultValidation = false;
          }
        });
      }

      if (!addDefaultValidation) {
        customValidationRule = {
          type: "range",
          message: item.Definition.FieldUICaption + " range must be between -9999.99 to 9999.99",
          min: "-9999.99",
          max: "9999.99",
          validationCallback: e => {
            this.customValidator = e.validator;
            const gridInstance = this?.dataGrid?.instance;
            gridInstance?.repaint();
            // @ts-expect-error - getController is a private method
            gridInstance?.getController('validating').validate(false);
            return true;
          },
          reevaluate: true
        };
        validationRules.push(customValidationRule);
      }
    }
    else if (item.OverrideUIType.toLowerCase() == "currencyinputbox") {
      let Fieldformat = item.Definition.Format == null || item.Definition.Format == "" ? "£ #,##0.00" : item.Definition.Format;
      editorOptions = { showClearButton: true, showSpinButtons: true, format: Fieldformat };
      var addDefaultValidation = true;
      if (item.Definition.ValidationRules && item.Definition.ValidationRules != null) {
        validationRules = JSON.parse(item.Definition.ValidationRules);

        validationRules.forEach((element, index) => {
          if (validationRules[index].type === "range") {
            addDefaultValidation = false;
          }
        });
      }
      if (!addDefaultValidation) {
        customValidationRule = {
          type: "range",
          message: item.Definition.FieldUICaption + " range must be between -9999.99 to 9999.99",
          min: "-9999.99",
          max: "9999.99",
          validationCallback: e => {
            this.customValidator = e.validator;
            const gridInstance = this?.dataGrid?.instance;
            gridInstance?.repaint();
            // @ts-expect-error - getController is a private method
            gridInstance?.getController('validating').validate(false);
            return true;
          },
          reevaluate: true
        };
        validationRules.push(customValidationRule);
      }

    }
    else if (item.OverrideUIType.toLowerCase() == "uicolor") {
      editorOptions = { focusStateEnabled: false };
    }
    else if (item.OverrideUIType.toLowerCase() == "bigintinputbox") {
      editorOptions = { format: "#" };
      customValidationRule = {
        type: "range",
        message: item.Definition.FieldUICaption + " range must be between -922337203685477 to 922337203685477",
        min: "-922337203685477",
        max: "922337203685477",
        validationCallback: e => {
          this.customValidator = e.validator;
          const gridInstance = this?.dataGrid?.instance;
          gridInstance?.repaint();
          // @ts-expect-error - getController is a private method
          gridInstance?.getController('validating').validate(false);
          return true;
        },
        reevaluate: true
      };
      validationRules.push(customValidationRule);
    }
    else if (item.OverrideUIType.toLowerCase() == "intinputbox") {
      editorOptions = { format: "#" };
      customValidationRule = {
        type: "range",
        message: item.Definition.FieldUICaption + " range must be between -2147483648 to 2147483647",
        min: "-2147483648",
        max: "2147483647",
        validationCallback: e => {
          this.customValidator = e.validator;
          const gridInstance = this?.dataGrid?.instance;
          gridInstance?.repaint();
          // @ts-expect-error - getController is a private method
          gridInstance?.getController('validating').validate(false);
          return true;
        },
        reevaluate: true
      };
      validationRules.push(customValidationRule);
    }

    if (dataType.toLowerCase() == "date")
      format = { type: 'shortDate' }
    else if (dataType.toLowerCase() == "time") {
      format = { type: 'shortTime' };
      dataType = 'datetime';
      editorOptions = { type: 'time' };
    }

    let FieldName = item.Definition.FieldUICaption == null || item.Definition.FieldUICaption == "" ?
      item.FieldName : item.Definition.FieldUICaption;


    if (item.Definition.ValidationRules && item.Definition.ValidationRules != null) {
      validationRules = JSON.parse(item.Definition.ValidationRules);
    }

    validationRules.forEach((element, index) => {
      if (validationRules[index].type === "compare") {
        var fieldName = validationRules[index].comparisonTarget;
        validationRules[index].comparisonTarget = () => this.compareValidater(fieldName);
        var customValidationRule = {
          type: "custom",
          message: "",
          validationCallback: e => {
            this.customValidator = e.validator;
            const gridInstance = this?.dataGrid?.instance;
            gridInstance?.repaint();
            // @ts-expect-error - getController is a private method
            gridInstance?.getController('validating').validate(false);
            return true;
          },
          reevaluate: true
        };
        validationRules.push(customValidationRule);
      }
    });
    if (dataType.toLowerCase() == "empty") {
      return {
        allowEditing: false
      }
    }

    if (this.gridProperties.Grouping && this.gridProperties.Grouping.length > 0) {
      this.gridProperties.Grouping.forEach(element => {
        if(this.IsInLineGrouping && element.GroupIndex === 0 && element.FieldName.toLowerCase() ===  item.FieldName.toLowerCase()){
          addInlineGroup = true
        }
      });

      if(this.gridProperties.Grouping.find(x=>x.FieldName.toLowerCase() === item.FieldName.toLowerCase())){
        colGroupIndex = this.gridProperties.Grouping.find(x=>x.FieldName.toLowerCase() === item.FieldName.toLowerCase()).GroupIndex ??
                      item.VisibleIndex;
      }
    }
    var width;
    var minWidth;
    var isReziable = true;
   
    if(this.getColumnWidth.getColumnDefaultWidth(item.OverrideUIType)>0) {
      width = this.getColumnWidth.getColumnDefaultWidth(item.OverrideUIType) + "px";
      minWidth = width;
      isReziable = false;
    }
    else {
      if(item.Definition?.ColumnWidth>0) { 
        width = item.Definition?.ColumnWidth + "px";
        minWidth = width;
      }
      else {
        width = undefined;
        minWidth = undefined;
      }
    }

    if(item.Definition?.AllowResize) { 
      isReziable = true;
      width= undefined;
      minWidth= undefined;
    }

    var adaptivePriority = "";
    if(item.Definition?.AdaptivePriority==0) {
      adaptivePriority = null;
    }
    else {
      adaptivePriority = item.Definition?.AdaptivePriority?.toString();
    }

    var col = {
      allowFiltering: enableFiltering,
      caption: FieldName,
      allowEditing: !item.Definition.ReadOnly && item.Definition.Derived==null,
      dataType: dataType,
      editorOptions: editorOptions,
      fieldName: item.FieldName,
      dataField: item.FieldName,
      visible: false,
      cssClass: item.Definition.HorizonalAlign.toLowerCase() === 'ltr' ? "lobas-grid-td text-left" : 'lobas-grid-td text-right',
      formItem: { visible: (item.Definition.IsEditable && !item.Definition.ReadOnly) || (item.Definition.IsEditable && item.Definition.ReadOnly && this.newID.length===0), label: { showColon: false, text: FieldName }, editorType: editorType },
      format: format,
      hidingPriority:  this.applyAdaptivePriority ? adaptivePriority : null,
      width: width,
      minWidth: minWidth,
      validationRules: validationRules,
      editCellTemplate: item.OverrideUIType.toLowerCase().includes('edittemplate') ? item.OverrideUIType : '',
      groupCellTemplate: this.groupCellTemplate!=null ? this.groupCellTemplate : null,
      cellTemplate : this.ColumnTemplates!=null && this.ColumnTemplates.has(item.FieldName)?this.ColumnTemplates.get(item.FieldName):null,
      calculateGroupValue: addInlineGroup ? this.calculateGroupVal : '',
      groupIndex : colGroupIndex ?? null,
      allowResizing: isReziable
    }
    return col;
  }

  customValidator: any;

  applyGridFilter() {
    this.dataGrid.filterValue = this.filter
  }
  clearGridFilter() {
    this.searchPanelText = '';
    this.dataGrid.filterValue = '';
  }

  toggleFilerDiv() {
    this.toggleFilterBody = !this.toggleFilterBody;
  }

  calculateGroupVal = (rowData) =>{ 
   var GroupingExpression:any;
    switch(this.GroupingTemplate.toLowerCase()){
      case "teamsgridgrouptemplate":
        GroupingExpression = this.dateTimeHelperService.FormatData(rowData["ShiftStarts"], 'datetime') + " - Shift Ends: " + this.dateTimeHelperService.FormatData( rowData.ShiftEnds, 'datetime');
      break;
    }   

    return GroupingExpression;
  }

  onContentReady(e: any) {
    if (this.gridProperties.isDetailComponent && this.gridProperties.Mode.toLowerCase() === "edit") {
      this.detailViewEditMode(true);
    } else {
      this.totalRows = e.component.totalCount();
    }
    this.filter = this.dataGrid.filterValue;
  }

  selectionChanged(e: any) {
    if (e.selectedRowKeys.length > 0) {
      this.selectedRows = e.selectedRowKeys;
      this.selectedCount = e.selectedRowKeys.length;

      this.selectedRecordID = e.selectedRowKeys[e.selectedRowKeys.length - 1];
      this.selectedRowIndex = e.component.getRowIndexByKey(e.selectedRowKeys[e.selectedRowKeys.length - 1]);
    } else {
      this.selectedRows = <string[]>[];
      this.selectedCount = 0;
      this.selectedRecordID = '';
      this.selectedRowIndex = -1;
    }
  }

  IsCommandExecuteOnlyWithSelectedRecords(command: ObjectCommandModel) {
    var commandName=command.Command.toLowerCase()
    if (commandName.toLowerCase() != 'insert'  && commandName.toLowerCase() != 'insertjob' && commandName.toLowerCase() != 'uploadpopup' &&  commandName.toLowerCase() != 'addnewaddress' && commandName.toLowerCase() !='assignworkitems' &&
      commandName.toLowerCase() != 'addworkertraderelationship' && commandName.toLowerCase() != 'assignrelationshipdata' && commandName.toLowerCase()!='newcontact' && commandName.toLowerCase()!='jobworkitempicker') {
      return true;
    }
    else {
      return false;
    }
  }

  async commandAction(command: ObjectCommandModel): Promise<any> {

    if (this.openBurgerMenu) {
      this.openBurgerMenu = false;
      this.isBurgerMenuCommand = true;
      this.btnBurger.element.classList.remove("hide-btn");
    }
    else if (this.IsCommandExecuteOnlyWithSelectedRecords(command) && !this.gridProperties.isDetailComponent
      && (this.selectedRows?.length === 0 || (!this.selectedRecordID && !this.isBurgerMenuCommand))) {

        
      var InsertSelectRecordErrorMessage:string="";
      this.translateService.get('InsertSelectRecordErrorMessage').subscribe((translated: string) => {
        InsertSelectRecordErrorMessage = translated;
      });
      this.alertService.showAlertPopup(this.errorAlertTitle,InsertSelectRecordErrorMessage, 'fa-solid fa-triangle-exclamation', this.okAlertBtnTxt);
      return;
    }
    if (command.Definition.HttpType) {
      if (command.Command.toLowerCase() === 'insert') {
        this.popuptitle = "New" + " " + this.gridProperties.Title.toLowerCase().replace('list', '');
        this.dataGrid.instance.addRow();
      }
      else if (command.Command.toLowerCase() === 'delete' || command.Command.toLowerCase() === 'deleteupload') {
        this.apiCallWithConfirmation(command, this.isBurgerMenuCommand);
      }
      else if (command.Command.toLowerCase() === 'bulktransition') {
        this.bulkEnumerationTransitionPopup.openPopupWithDDLBinding(this.dataGrid, this.dataGrid.instance.getSelectedRowsData(), this.lookupDataSource);
      }
      else if (command.Command.toLowerCase() === 'update' || command.Command.toLowerCase() === 'saveall'|| command.Command.toLowerCase()==='updatedetail') {
        if (this.gridProperties.isDetailComponent) {
          this.componentSettings.Commands.push(command);
          //this.loadDataSource(this.componentSettings, false);
          if (command.Command.toLowerCase() === 'saveall'|| command.Command.toLowerCase() === 'updatedetail') {
            this.saveAll = true;
          }

          if (this.dataGrid.instance.hasEditData()) {
            this.dataGrid.instance.saveEditData();
          }
          else {
            return true;
          }   
        }
        else {
          this.popuptitle = "Edit" + " " + this.gridProperties.Title.toLocaleLowerCase().replace('list', '');
          if (this.isBurgerMenuCommand) {
            var selectedRowIndex = this.dataGrid.instance.getRowIndexByKey(this.selectedRecordID);
            this.dataGrid.instance.editRow(selectedRowIndex);
          }
          else {
            this.dataGrid.instance.editRow(this.selectedRowIndex);
          }
          this.dataGrid.instance.deselectAll();
        }
      }
      else {
        this.performCommandAction(command, this.isBurgerMenuCommand);
      }
    }
    else {

      if (command.Definition.IsCustomOperation) {
        this.gridCustomActions.emit({ "SelectedRecords": this.selectedRows, "ObjectID": this.gridProperties.ObjectID, "CommandObject": command ,"SelectedRowsData": this.dataGrid.instance.getSelectedRowsData()})
      } else {
        this.translateService.get('APIEndpointNotDefined', { action: command.Command }).subscribe((text: string) => {
        });
      }
    }
  }

  apiCallWithConfirmation(command: ObjectCommandModel, isBurgerMenuCommand) {

    var title = command.Definition.HttpType[0].toUpperCase() + command.Definition.HttpType.slice(1);
    var CancelButtonText:string="";
    this.translateService.get('CancelButtonText').subscribe((translated: string) => {
      CancelButtonText = translated;
    });
    this.translateService.get('ConfirmationMessage', { command: command.Name.toLocaleLowerCase() }).subscribe((text: string) => {
      Swal.fire({
        title: title,
        text: text,
        iconHtml: "<i class='fa-solid fa-triangle-exclamation'></i>",
        confirmButtonText: this.okAlertBtnTxt,
        showCancelButton: true,
        cancelButtonText: CancelButtonText,
        allowOutsideClick : false
      }).then((response: any) => {
        if (response.isConfirmed) {
          this.performCommandAction(command, isBurgerMenuCommand);
        }
      });
    });
  }

  performCommandAction(command: ObjectCommandModel, isBurgerMenuCommand: boolean) {

    var dataToSubmit = null;
    if (this.isBurgerMenuCommand) {
      this.isBurgerMenuCommand=false;
      dataToSubmit = [this.selectedRecordID];
    }
    else {
      dataToSubmit = this.selectedRows;
    }

    if (command.Definition.IsCustomOperation) {
      this.gridCustomActions.emit({ "SelectedRecords": dataToSubmit, "ObjectID": this.gridProperties.ObjectID, "CommandObject": command ,"SelectedRowsData": this.dataGrid.instance.getSelectedRowsData()})
    } else {
      switch (command.Definition.HttpType.toLowerCase()) {
        case 'get':
          this.getRows(command, isBurgerMenuCommand);
          break;
        case 'put':
          this.putRows(command, isBurgerMenuCommand);
          break;
        case 'post':
          this.postRows(command, isBurgerMenuCommand);
          break;
        case 'delete':
        case 'deleteupload':
          this.deleteRows(command, isBurgerMenuCommand);
          break;
      }
    }
  }

  getRows(command: ObjectCommandModel, isBurgerMenuCommand: boolean) {
    this.httpClient.get(environment.apiUrl + command.Definition.APIActionEndPoint + this.selectedRecordID,).subscribe((response: any) => {
      this.dataGrid.instance.deselectAll();
      this.translateService.get('OperationPerformedSuccess', { command: command.Name }).subscribe((text: string) => {
        this.alertService.showAlertPopup(this.successAlertTitle, text,
          'fa-solid fa-check', this.okAlertBtnTxt);
      });
      this.dataGrid.instance.refresh();
    });
  }

  postRows(command: ObjectCommandModel, isBurgerMenuCommand: boolean) {
    this.httpClient.post(environment.apiUrl + command.Definition.APIActionEndPoint,
      { "SelectedRecords": this.selectedRows, "ObjectID": this.gridProperties.ObjectID }
    )
      .subscribe((response: any) => {
        this.dataGrid.instance.deselectAll();

        this.translateService.get('OperationPerformedSuccess', { command: command.Name }).subscribe((text: string) => {
          this.alertService.showAlertPopup(this.successAlertTitle, text,
            'fa-solid fa-check', this.okAlertBtnTxt);
        });

        this.dataGrid.instance.refresh();
      });
  }

  putRows(command: ObjectCommandModel, isBurgerMenuCommand: boolean) {
    var recordIds = null;
    if (isBurgerMenuCommand && !this.selectedRows) {
      recordIds = [this.selectedRecordID];
    }
    else {
      recordIds = this.selectedRows;
    }

    this.httpClient.put(environment.apiUrl + command.Definition.APIActionEndPoint,
      { "SelectedRecords": recordIds, "ObjectID": this.gridProperties.ObjectID },
      {
        headers: {
          'objectID': this.gridProperties.ObjectID
        }
      }
    )
      .subscribe((response: any) => {
        this.dataGrid.instance.deselectAll();
        this.translateService.get('OperationPerformedSuccess', { command: command.Name }).subscribe((text: string) => {
          this.alertService.showAlertPopup(this.successAlertTitle, text,
            'fa-solid fa-check', this.okAlertBtnTxt);
        });
        this.dataGrid.instance.refresh();
      });
  }

  async deleteRows(command: ObjectCommandModel, isBurgerMenuCommand: boolean) {
    var recordsToDelete: any = null;
    if (this.gridProperties.isDetailComponent) {
      var recordId = '';
      recordId = await this.GetKeyValueFromQueryString('RecordID');
      recordsToDelete = { "SelectedRecords": [recordId], "ObjectID": this.gridProperties.ObjectID };

    } else {
      if (isBurgerMenuCommand) {
        recordsToDelete = { "SelectedRecords": [this.selectedRecordID], "ObjectID": this.gridProperties.ObjectID, "Relationship": this.relationshipValues }
          ;
      }
      else {
        recordsToDelete = { "SelectedRecords": this.selectedRows, "ObjectID": this.gridProperties.ObjectID, "Relationship": this.relationshipValues };
      }

    }

    if (this.offlineDataHelper.isOnline) {
      this.httpClient.delete(environment.apiUrl + command.Definition.APIActionEndPoint,
        {
          body: recordsToDelete,
          responseType: "text",
          headers: {
            'objectID': this.gridProperties.ObjectID
          }
        })
        .subscribe((response: any) => {
          this.dataGrid.instance.deselectAll();          
          var MultipleDeleteSuccessMessage:string="";
          this.translateService.get('MultipleDeleteSuccessMessage').subscribe((translated: string) => {
            MultipleDeleteSuccessMessage = translated;
          });
          this.alertService.showAlertPopup(this.successAlertTitle, MultipleDeleteSuccessMessage,'fa-solid fa-check', this.okAlertBtnTxt);
          this.dataGrid.instance.refresh();
        });
    } else {
      this.offlineDataHelper.deleteRecord(this.dbSource, recordsToDelete, command).then(response => {
        var MultipleDeleteSuccessMessageOffline:string="";
        this.translateService.get('MultipleDeleteSuccessMessageOffline').subscribe((translated: string) => {
          MultipleDeleteSuccessMessageOffline = translated;
        });
        this.alertService.showAlertPopup(this.successAlertTitle, MultipleDeleteSuccessMessageOffline,'fa-solid fa-check', this.okAlertBtnTxt);
        this.dataGrid.instance.refresh();
        this.dataGrid.instance.deselectAll();
      });
    }
  }

  onResize(event) {
    if (!this.gridProperties.isDetailComponent) {
      if (window.innerWidth < 979) {
        if (this.gridProperties.GridDisplayMode.toLowerCase() === "card") {
          this.isGridview = false;
          this.dataRowTemplateName = 'dataRowTemplate';
        }
        else if (this.gridProperties.GridDisplayMode.toLowerCase() === "grid") {
          this.isGridview = true;
          this.dataRowTemplateName = '';
        }
        else {
          this.isGridview = false;
          this.dataRowTemplateName = 'dataRowTemplate';
        }
      }
      else if (this.gridProperties.GridDisplayMode.toLowerCase() === "card") {
        this.isGridview = false;
        this.dataRowTemplateName = 'dataRowTemplate';
      }
      else {
        this.isGridview = true;
        this.dataRowTemplateName = '';
      }
    }
    else {
      this.isGridview = false;
      this.dataRowTemplateName = 'dataRowTemplate';
    }
  }

  getSetting = async () => {
    //var data = JSON.stringify(this.gridSetting);
    //if(!data || data.length <=0){
      var data = await this.gridHelper.loadState(this.instanceID, this.IsUnauthorizedPage);
    //}
    if (this.filterExpressionsArrayByQueryString && this.filterExpressionsArrayByQueryString.length > 0) {
      var jsonObj = this.gridHelper.setCustomGridFilters(data, this.filterExpressionsArrayByQueryString);
      data = jsonObj;
      this.filterExpressionsArrayByQueryString = [];
    }
    data = this.setPageSizeAndGrouping(data);
    return data;
  }

  setPageSizeAndGrouping(settings) {
    if (settings) {
      settings.pageSize = this.gridProperties.GridPageSize;
    }
    if(this.gridProperties.Grouping &&
      this.gridProperties.Grouping.length > 0 &&
      !this.gridProperties.Grouping.find(x=>x.FieldName.toLowerCase() === "all")
    ){
      settings.columns.map((col)=>{
        col.groupIndex = this.gridProperties.Grouping.find(x=>x.FieldName.toLowerCase() === col.name?.toLowerCase() ?? '')?.GroupIndex ?? null;
      });
    }
    this.gridSetting = settings;
    return settings;
  }

  setSettings = async (state: any) => {
    if(!this.gridProperties.RemeberScrollLocation){
      state.pageIndex = 0;
    }
    if (this.saveGridSetting) {
      await this.gridHelper.saveState(state, this.instanceID, this.IsUnauthorizedPage, this.gridProperties.ObjectID);
    }
  }

  refreshGrid(showSuccessPopup: boolean = true) {
    
    var DataRefreshed:string="";
    this.translateService.get('DataRefreshed').subscribe((translated: string) => {
      DataRefreshed = translated;
    });
    this.dataGrid.instance.refresh().then((value) => {
      if (showSuccessPopup) {
        this.alertService.showAlertPopup(this.successAlertTitle, DataRefreshed,'fa-solid fa-check', this.okAlertBtnTxt);
      }
    });
  }
  scrollUp() {
    this.dataGrid.instance.getScrollable().scrollTo({ top: 0 });
    }


  ShowGridTools() {
    this.isPopupVisible = !this.isPopupVisible;
  }
  OpenGridMenuPopup(value: string) {
    this.gridMenuOptionPopup.showInfo(value);
  }

  toggleGridView() {
    this.isGridview = !this.isGridview;
    if (this.dataRowTemplateName?.toLocaleLowerCase() != 'datarowtemplate') {
      this.dataRowTemplateName = 'dataRowTemplate';
      this.dataGrid.filterRow.visible = false;
      this.dataGrid.showColumnHeaders = false;

      this.toggleFilterBody = true;
    } else {
      this.dataRowTemplateName = '';
      this.dataGrid.filterRow.visible = true;
      this.dataGrid.showColumnHeaders = true;
    }
    this.dataGrid.instance.option("headerFilter.visible", !this.dataGrid.instance.option("headerFilter.visible"))
  }

  expandCollaspeComponent() {
    this.isVisible = !this.isVisible;
    this.expandCollaspeChatComponent.emit();
  }

  performCellClickReDirect(data: any, navigateURL: string) {

    this.AddQuerystringParameterAndnavigateWithDecryptedUrl(navigateURL, 'RecordID=' + data.RecordID);
  }
  onCellDblClick(event, item) {
    clearTimeout(this.timeOutHandler);
    this.timeOutHandler = null;
    this.cellInfoPopup.openPopup(event.text, event.event);
  }

  onCellClick = async (event, item) => {
    var rowClickCommandAction = this.rowClickCommandAction;
    if (!this.timeOutHandler)
      this.timeOutHandler = setTimeout(async () => {
        this.timeOutHandler = null;
    let column = this.componentSettings.UIFields.filter(x => x.FieldName?.toLowerCase() === event?.column?.fieldName?.toLowerCase() && x.Definition.ShowUserDetailsPopup)
    if (column.length > 0 && event?.data[column[0]?.Definition.ShowUserDetailsPopup]) {
      this.contactDetailtsPopup.showPopup(event.data[column[0].Definition.ShowUserDetailsPopup]?.toString(), event.event);
    }
    else if (event != null && event.column &&((event.column != null && event.rowType?.toLowerCase() != "header" && event.rowType?.toLowerCase() != "filter" && event.column.caption != null && event.column.caption?.toLowerCase() != "action") || (event.column == undefined || event.column == null))) {
      var CustomRowClickCommand = this.componentSettings.Commands.filter(x => (x.Definition != null && x.Definition.IsCustomOperation != null && x.Definition.IsCustomOperation == true && x.Name?.toLowerCase() === "row click"))[0];
      if (!this.gridProperties.isDetailComponent && ((this.rowClickCommandAction && this.rowClickCommandAction?.RecordID != Guid.EMPTY) || CustomRowClickCommand)) {
        var navigateURL = ""
        if (this.rowClickCommandAction && this.rowClickCommandAction.Definition && this.rowClickCommandAction.Definition.VisibilityOption[0]) {
          navigateURL = this.rowClickCommandAction.Definition.VisibilityOption[0].RedirectURL;
          if (this.rowClickCommandAction.Definition.VisibilityOption[0].ReturnUrlEnabled && this.rowClickCommandAction.Definition.VisibilityOption[0].ReturnUrlEnabled.toLowerCase() === "true" && navigateURL) {
            if (navigateURL.includes("?")) {
              navigateURL = navigateURL + "&" + "ReturnUrlEnabled=true" + "&" + "ReturnUrl=" + await this.GetDecryptedUrlForReturnUrl(window.location.href);
            }
            else {
              navigateURL = navigateURL + "?" + "ReturnUrlEnabled=true&" + "ReturnUrl=" + await this.GetDecryptedUrlForReturnUrl(window.location.href);
            }
          }
        }
        if (navigateURL.length > 0) {
          var recordId = "";
          var data = null;
          if (this.isGridview) {
            if (!event.column.type && (event.column.caption && event.column.caption?.toLowerCase() != 'action'
              && event.rowType.toLowerCase() == 'data')) {
              data = event.data;
            }
            else {
              return;
            }
          } else {
            data = item.data;
          }

          if (this.isRowClick) {
            this.rowClick.emit({ data: data, navigateURL: navigateURL });
          }
          else {
            this.performCellClickReDirect(data, navigateURL);
          }
        }
        else if (!this.gridProperties.isDetailComponent && (CustomRowClickCommand || this.rowClickCommandAction?.RecordID != Guid.EMPTY)) {
          this.gridCustomActions.emit({ "SelectedRecords": [event.key], "ObjectID": this.gridProperties.ObjectID, "CommandObject": CustomRowClickCommand ,"SelectedRowsData": this.dataGrid.instance.getSelectedRowsData()})
        }
      }
      else {
        if (this.isRowClick) {
          this.rowClick.emit({ data: event.data, navigateURL: navigateURL });
        }
      }
    } else {
      if (event.rowType?.toLowerCase() === "header") {
        this.saveGridSetting = true;
      }
      else if(!this.isGridview){
        if (rowClickCommandAction && rowClickCommandAction.Definition && rowClickCommandAction.Definition.VisibilityOption[0]) {
          navigateURL = this.rowClickCommandAction.Definition.VisibilityOption[0].RedirectURL;
          if (this.rowClickCommandAction.Definition.VisibilityOption[0].ReturnUrlEnabled && this.rowClickCommandAction.Definition.VisibilityOption[0].ReturnUrlEnabled.toLowerCase() === "true" && navigateURL) {
            if (navigateURL.includes("?")) {
              navigateURL = navigateURL + "&" + "ReturnUrlEnabled=true" + "&" + "ReturnUrl=" + await this.GetDecryptedUrlForReturnUrl(window.location.href);
            }
            else {
              navigateURL = navigateURL + "?" + "ReturnUrlEnabled=true&" + "ReturnUrl=" + await this.GetDecryptedUrlForReturnUrl(window.location.href);
            }
          }
        }
        if(navigateURL) {
          this.performCellClickReDirect(item.data, navigateURL);
        }
      }
    }
 }, 300);
  }
  onAdaptiveDetailRowPreparing(e) {
    e.formOptions.items.forEach(col => {
      col.label = { showColon: false, text: col.caption } ;
    });
  }

  selectContext(event) {
    let route = this._router.config.find(r => r.path === 'people-details/:id');
    route.data = event.data;
    this._router.navigateByUrl(`${'people-details'}/${event.data.RecordID}`);
  }

  async redirectToBannerUrl() {
    const navigateURL = this.gridProperties.BannerUrl;
    if (this.gridProperties.BannerUrl) {
      const baseUrl = window.location.origin;
      this.gridProperties.isDetailComponent = true;

      if (this.gridProperties.RelationshipID) {
        let RecordId = await this.GetKeyValueFromQueryString('RecordID');
        let JobID = await this.GetKeyValueFromQueryString('JobID');
        let Key = this.gridProperties.ObjectName + "ID";
        let CurrentObjectRecordID = await this.GetKeyValueFromQueryString(Key);
        const objectId = this.gridProperties.ObjectID;
        const RelationshipID = this.gridProperties.RelationshipID;
        CurrentObjectRecordID = CurrentObjectRecordID ? CurrentObjectRecordID : Guid.EMPTY;
        RecordId = RecordId ? RecordId : Guid.EMPTY;
        this.httpClient.get<RelatedRecordDescriptionModel>(environment.apiUrl +
          'api/RelationshipRecord/GetRelatedRecordIDWithLookupValue/' + RelationshipID + '/' + RecordId + '/' + CurrentObjectRecordID)
          .subscribe((response: RelatedRecordDescriptionModel) => {
            if (response && response.RecordID) {
              var relatedRecordDescriptionModel: RelatedRecordDescriptionModel = response;
              if (JobID) {
                var queryStringParameters = 'RecordID=' + JobID + '&RelationshipRecordID=' + relatedRecordDescriptionModel.RecordID;
                this.navigateTo(navigateURL, queryStringParameters);
              } else {
                this.httpClient.get<ObjectRecordModel>(environment.apiUrl + "api/ObjectRecord/GetRelatedRecordsByRecordId/" + objectId + '/' + RelationshipID + '/' + RecordId)
                  .subscribe((response: ObjectRecordModel) => {
                    if (response[0]?.RecordID) {
                      var queryStringParameters = 'RecordID=' + response[0]?.RecordID + '&RelationshipRecordID=' + relatedRecordDescriptionModel.RecordID;;

                      this.navigateTo(navigateURL, queryStringParameters);
                    }
                  });
              }

            }
          });
      }

    }
  }

  GetEnumValue(EnumerationTypeDescription: string, EnumerationId: string) {
    return this.filterValuesByKey(EnumerationTypeDescription).filter(x => x.EnumerationId === EnumerationId).map(x => x.Value);
  }

  GetRelationshipValue(fieldname: string,companyId:string){
    return this.filterValuesByKeyRelation(fieldname,this.relationshipLookupDataSource).filter(x => x.RecordID  === companyId).map(x => x.LookupValue);
  }
  

  UpdateFilterBuilderFields(eventData: { filterValue: any, showFillterOnRow: boolean, searchValue: string }) {
    this.ShowfilterRow(eventData.showFillterOnRow);
    this.dataGrid.instance.hideColumnChooser();
    this.dataGrid.filterValue = eventData.filterValue;
    this.searchPanelText = eventData.searchValue;

  }
  async ShowfilterRow(show: boolean) {
    this.dataGrid.instance.hideColumnChooser();
    //this.isShowFilterRow = show;
    //this.dataGrid.filterRow = { visible: this.isShowFilterRow ?? true };
    this.componentSettings.Settings.ShowFilterRow = show;
    this.localStorageService.SetLocal(EnumHelper.LocalStorageKey.componentSettings, JSON.stringify(this.componentSettings), [this.instanceID]);
    await this.gridHelper.saveGridFilterRowState(show, this.instanceID);
  }

  ShowColumnChooser(Show: boolean) {
    this.dataGrid.instance.hideColumnChooser();
    if (Show) {
      let elements = document.querySelectorAll(".dx-datagrid-column-chooser>.dx-popup-normal");
      elements.forEach(element => {
        if (element.querySelector("#btnResetGrid")) {
          const btnResetGrid = document.querySelectorAll("#btnResetGrid");
          btnResetGrid.forEach(btn => {
            btn.remove();
          });
        }
        let btn = document.createElement('button');
        btn.id = "btnResetGrid";
        btn.textContent = "Reset Grid";
        btn.addEventListener("click", async () => {
          await this.ResetGrid(true);
        });
        element.appendChild(btn);
        element.classList.add("lobas-column-chooser-popup");

      });
      this.dataGrid.instance.showColumnChooser();
    }
  }
  ExportGrid(Type: string) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('DataGrid');
    switch (Type.toLowerCase()) {
      case 'pdf':
        const doc = new jsPDF();
        exportPDF({
          jsPDFDocument: doc,
          component: this.dataGrid.instance,
          indent: 5,

        }).then(() => {
          doc.save('DataGrid.pdf');
        });
        break;
      case 'xlsx':
        exportDataGrid({
          component: this.dataGrid.instance,
          worksheet,
          autoFilterEnabled: true,

        }).then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
          });
        });
        break;
      case 'csv':
        exportDataGrid({
          component: this.dataGrid.instance,
          worksheet: worksheet
        }).then(function () {
          workbook.csv.writeBuffer().then(function (buffer) {
            saveAs(new Blob([buffer], { type: "application/octet-stream" }), "DataGrid.csv");
          });
        });
        break;
    }
  }
  async ResetGrid(IsResetGrid: boolean) {
    this.dataGrid.instance.hideColumnChooser();
    if (this.offlineDataHelper.isOnline) {
      if (this.ResetGrid) {
        this.gridHelper.ResetGrid(this.instanceID).then(response => {
          this.isPopupVisible = false;
          this.translateService.get('ResetGridSettingSuccessfully', { title: this.gridProperties.Title }).subscribe((text: string) => {
            this.alertService.showAlertPopup(this.successAlertTitle, text,
              'fa-solid fa-check', this.okAlertBtnTxt).then((response: any) => {
                this.gridHelper.loadState(this.instanceID, this.IsUnauthorizedPage).then(response => {
                  this.dataGrid.instance.state(response);
                })
              });
          });
        });
      }
    } else {
      
      var GridResetInOfflineNotAllowedMessage:string="";
      this.translateService.get('GridResetInOfflineNotAllowedMessage').subscribe((translated: string) => {
        GridResetInOfflineNotAllowedMessage = translated;
      });
      this.alertService.showAlertPopup(this.notAllowedAlertPopupTitle,GridResetInOfflineNotAllowedMessage, 'fa-solid fa-triangle-exclamation', this.okAlertBtnTxt)
    }

  }

  async detailViewEditMode(onContentReady) {
    if (this.newID && this.newID.length > 0 && !onContentReady) {
      this.dataGrid.instance.addRow();
    }
    else {
      var editRowIndex = this.dataGrid.instance.getRowIndexByKey(this.recordID);
      if (editRowIndex == -1) {
        editRowIndex = this.dataGrid.instance.getRowIndexByKey(this.recordID.toLowerCase());
      }
      this.dataGrid.instance.editRow(editRowIndex);
    }
  }


  GetURLIcon(item, colFieldName) {
    if (item && item.data && item.data[colFieldName]) {
      var Colvalue = item.data[colFieldName];
      if (Colvalue.toLowerCase().startsWith("http")) {
        return "<a title='" + Colvalue + "' href='" + Colvalue + "' target='_blank' class='color_hyperlink fas fa-link text-decoration-none' >"
      }
      else {
        return "<a title='" + Colvalue + "' href='" + Colvalue + "'  class='color_hyperlink fas fa-link text-decoration-none' >"
      }

    }
    else {
      return ""
    }

  }
  GetCardHtmlIconOrValue(item, col) {
    var columnValue = this.GetEnumValue(col.Definition.EnumerationTypeDescription, item.data[col.FieldName]);
    if (columnValue.length > 0) {
      var value = columnValue.toString();
      var column = this.componentSettings.UIFields.filter(x => x.FieldName?.toLowerCase() === col.FieldName?.toLowerCase() && x.Definition.ShowEnumerationIcon)
      if (column.length > 0) {
        var enumIcon = "";
        if (column[0].Definition.EnumerationTypeDescription) {
          var enumList = this.filterValuesByKey(column[0].Definition.EnumerationTypeDescription);
          if (enumList != null && enumList != undefined && enumList.length > 0) {
            enumIcon = enumList.filter(x => x.Value.toLowerCase() == columnValue[0].toLowerCase())[0]?.Icon;
            if (enumIcon) {
              value = "<div class='" + enumIcon + "'>"
              return value;
            }
            else {
              return value;
            }
          }
          else {
            return value;
          }
        }
        else {
          return value;
        }
      }
      else {
        return value;
      }
    }
    else if(col.OverrideUIType.toLowerCase()=='relationship'){
      columnValue= this.GetRelationshipValue(col.FieldName,item.data[col.FieldName]);
       if (columnValue.length > 0) {
        var value = "<div>" + columnValue + "</div>"
       }
      return value;
    }
    return "";
  }

  GetUsersIcon(item, FieldName) {
    return this.userIconImageService.getProfileIconImage(item.data[FieldName], item.data[FieldName + "UsersName"]).outerHTML;
  }

  SetFile(item, col, iconsOnly) {
    var Icon = ''
    var div = ""
    var recordID = item.data["RecordID"];
    var path = item.data["Path"];
    var fileExtension_EnumID = item.data["FileExtension_EnumID"];
    var path = item.data["Path"];
    //Icon = this.GetFileExtensionIcon(fileExtension_EnumID);
    if (this.fileExtensionEnumList != null && this.fileExtensionEnumList != undefined && fileExtension_EnumID != Guid.EMPTY) {

      Icon = this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.enumeration, [fileExtension_EnumID, "Icon"]);
      if (this.IsNullorEmpty(Icon)) {
        Icon = this.fileExtensionEnumList.filter(x => x.EnumerationId == fileExtension_EnumID)[0].Icon;
        this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.enumeration, Icon, [fileExtension_EnumID, "Icon"]);
      }
    }

    if (iconsOnly == true) {
      div = "<div class='grid-file-icon'><i class='fa-solid " + Icon + "'></i></div>"
      return div;
    }
    else {
      if (recordID && path && fileExtension_EnumID) {
        var fileextention = this.GetFileExtension(path).toLowerCase();
        if (fileextention == "bmp" || fileextention == "jpg" || fileextention == "jpeg" || fileextention == "png" || fileextention == "tiff") {
          path = this.GetFileNameWithoutExtension(path) + "-S.jpg";
          var filepath = recordID + '_' + path;
          div = "<div class='grid-user-img'><img id='uploadedImage'  src = '" + environment.azureStorageURL + "Thumbnails/" + filepath + "'/></div>"
        }
        else {
          div = "<div class='grid-file-icon'><i class='fa-solid " + Icon + "'></i></div>"
        }
      }
      else {
        div = "<div class='grid-file-icon'><i class='fa-solid " + Icon + "'></i></div>"
      }
      return div;
    }
  }

  InfoIconClick() {
    var divInfoIcon = document.querySelector('#divInfoIcon')
    if (divInfoIcon) {
      let html = '';
      this.gridProperties.FieldSettings.Info.sort((a, b) => a.VisibleIndex - b.VisibleIndex);
      this.gridProperties.FieldSettings.Info.forEach(item => {
        if (item.IsVisible) {
          const visibleRows = this.dataGrid.instance.getVisibleRows();
          visibleRows.forEach(row => {
            var Field = this.componentSettings.UIFields.filter(x => x.FieldName === item.FieldName || x.Definition?.FieldUICaption === item.FieldName)
            html += `<li>${[Field[0]?.Definition?.FieldUICaption == null || Field[0]?.Definition?.FieldUICaption == "" ? item.FieldName : Field[0]?.Definition?.FieldUICaption]}</li>`;

            switch (Field[0]?.OverrideUIType.toLowerCase()) {
              case 'enumeration':
              case 'enumerationcombobox':
                html += `<li><b>${this.GetEnumValue(Field[0]?.Definition?.EnumerationTypeDescription, row.data[item.FieldName])}</br></b></li>`;
                break;
              default:
                html += `<li><b>${this.dateTimeHelperService.FormatData(row.data[item.FieldName], Field[0]?.OverrideUIType)}</br></b></li>`;
                break;
            }
          });
        }
      });
      divInfoIcon.innerHTML = `<ul>${html}</ul>`;
      this.isVisibledivInfoIcon = !this.isVisibledivInfoIcon;
    }
  }

  formatData(data: string, dataType: String): String {
    if (dataType.toLowerCase() == "radiobuttonyesno" && data != null && data != undefined) {
      if (data) {
        return "Yes"
      }
      else {
        return "No";
      }
    }
    else if (dataType.toLowerCase() == "radiobuttontruefalse" && data != null && data != undefined) {
      if (data) {
        return "True"
      }
      else {
        return "False";
      }
    }
    else if (dataType.toLowerCase() == "currencyinputbox" && data != null && data != undefined) {
      var currencyData = "£ " + parseFloat(data).toFixed(2);
      return currencyData;
    }
    else if (dataType.toLowerCase() == "decimalinputbox" && data != null && data != undefined) {
      var decimalData = "" + parseFloat(data).toFixed(2);
      return decimalData;
    }
    else if (dataType.toLowerCase() == "textarea" && data != null && data != undefined) {
      return data.replaceAll("\n", "<br>").replaceAll(' ', '&nbsp;');
    }
    else {

      return this.dateTimeHelperService.FormatData(data, dataType);
    }
  }

  onRowPrepared(e: any) {
    if(e.rowType === "group" && e.groupIndex > 0 && this.IsInLineGrouping){
      e.rowElement.hidden = true;
    }
  }
  async onCellPrepared(e) {
    if (e.rowType === "data" || e.rowType === "detailAdaptive") {
      this.SetColumnColor(e);
      this.SetColumnIcon(e);
      this.SetUserIcon(e);
      if (e.cellElement.innerHTML == "&nbsp;") {
        e.cellElement.innerHTML = "";
      }
      if (e.column.dataField?.toLowerCase() === "createdusericon") {
        return await this.userIconImageService.getUserIcon(e, e.data.RecordCreated_UserID, e.data.CreatedUsersName);
      }
      else if (e.column.dataField?.toLowerCase() === "updatedusericon") {
        return await this.userIconImageService.getUserIcon(e, e.data.RecordUpdated_UserID, e.data.UpdatedUsersName);
      }
      else if (e.column.dataField?.toLowerCase() === "contactusericon") {
        return await this.userIconImageService.getUserIcon(e, e.data.Contact_RecordID, e.data.Attendees);
      }
      else if (e.column.dataField?.toLowerCase() === "fileimg") {
        return await this.getImgHTML(e, e.data.RecordID, e.data.Path, e.data.FileExtension_EnumID, false);
      }
    }
  }
  async SetUserIcon(e: any) {
    if (this.componentSettings.UIFields && this.componentSettings.UIFields.length > 0) {
      let column = this.componentSettings.UIFields.filter(x => x.FieldName?.toLowerCase() === e.column.dataField?.toLowerCase() && x.OverrideUIType.toLowerCase() === "usericon")
      if (column.length > 0) {
        return await this.userIconImageService.getUserIcon(e, e.data[column[0].FieldName], e.data[column[0].FieldName + "UsersName"]);
      }
    }
  }
  SetColumnColor(e: any) {
    if (this.componentSettings.UIFields && this.componentSettings.UIFields.length > 0) {
      let column = this.componentSettings.UIFields.filter(x => x.FieldName?.toLowerCase() === e.column.dataField?.toLowerCase() && x.Definition.ColumnColor)
      if (column.length > 0) {
        e.cellElement.style.color = e.data[column[0].Definition.ColumnColor];
      }
    }
  }
  SetColumnIcon(e: any) {
    if (this.componentSettings.UIFields && this.componentSettings.UIFields.length > 0) {
      var column = this.componentSettings.UIFields.filter(x => x.FieldName?.toLowerCase() === e.column.dataField?.toLowerCase() && x.Definition.ShowEnumerationIcon)
      if (column.length > 0) {
        var enumIcon = "";
        if (column[0].Definition.EnumerationTypeDescription) {
          var enumList = this.filterValuesByKey(column[0].Definition.EnumerationTypeDescription);
          if (enumList != null && enumList != undefined && enumList.length > 0) {
            enumIcon = enumList.filter(x => x.EnumerationId.toLowerCase() == e.value.toLowerCase())[0]?.Icon;
            if (enumIcon) {
              e.cellElement.innerHTML = ""
              var div = document.createElement('div');
              div.className = enumIcon;
              div.innerHTML = ""
              e.cellElement.appendChild(div);
            }
          }
        }
      }
    }
  }

  async getImgHTML(e: any, recordID: string, path: string, fileExtension_EnumID: string, iconsOnly: boolean) {
    var Icon = ''
    var div = document.createElement('div');
    Icon = await this.GetFileExtensionIcon(fileExtension_EnumID);

    if (iconsOnly == true) {
      div.className = 'grid-file-icon';
      div.innerHTML = "<i class='fa-solid " + Icon + "'></i>";

    }
    else {

      var fileextention = this.GetFileExtension(path).toLowerCase();
      if (fileextention == "bmp" || fileextention == "jpg" || fileextention == "jpeg" || fileextention == "png" || fileextention == "tiff") {
        path = this.GetFileNameWithoutExtension(path) + "-S.jpg";
        var filepath = recordID + '_' + path;
        div.className = 'grid-user-img';
        div.innerHTML = "<img id='uploadedImage'  src = '" + environment.azureStorageURL + "Thumbnails/" + filepath + "'/>";
      }
      else {
        div.className = 'grid-file-icon';
        div.innerHTML = "<i class='fa-solid " + Icon + "'></i>";

      }
    }
    return e.cellElement.appendChild(div);

  }
  async GetFileExtensionIcon(fileExtension_EnumID: string) {
    var Icon = ''
    if (this.fileExtensionEnumList != null && this.fileExtensionEnumList != undefined && fileExtension_EnumID != Guid.EMPTY) {

      Icon = this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.enumeration, [fileExtension_EnumID, "Icon"]);
      if (this.IsNullorEmpty(Icon)) {
        Icon = this.fileExtensionEnumList.filter(x => x.EnumerationId == fileExtension_EnumID)[0].Icon;
        this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.enumeration, Icon, [fileExtension_EnumID, "Icon"]);
      }
    }

    return Icon;
  }
  GetEnumerationListByTypeDescription(enumerationType: string): PromiseLike<EnumerationModel[]> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.apiUrl + "api/EnumerationUnAuthorize/GetEnumerationListByTypeDescription/" + enumerationType)
        .subscribe((response) => {
          resolve(<EnumerationModel[]>response);
        });
    });
  }


  compareValidater = (fieldName: string) => {
    var fieldDetails = fieldName.split('+');
    var data;
    var additionalParametes: number = 0;
    if (fieldDetails.length === 2) {
      var additionalValues = fieldDetails[1].split(' ');
      switch (additionalValues[1].toLowerCase()) {
        case 'day':
          additionalParametes = Number.parseInt(additionalValues[0]);
          data = this.currentRowData?.row.data[fieldDetails[0]];
          if (Date.parse(data)) {
            return new Date(data).setDate(new Date(data).getDate() + additionalParametes);
          }
          break;
        default:
          return this.currentRowData?.row.data[fieldDetails[0]];
          break;
      }

    } else {
      data = this.currentRowData?.row.data[fieldDetails[0]];
      if (Date.parse(data)) {
        data = new Date(data);
      }
      return data;
    }
  };

  async isValidFormData(): Promise<Boolean> {
    var isValid: boolean = false;
    return new Promise<Boolean>(async (resolve, reject) => {
      const gridInstance = this?.dataGrid?.instance;
      gridInstance?.repaint();
      // @ts-expect-error - getController is a private method
      isValid = await gridInstance?.getController('validating').validate(true);
      resolve(isValid);
    });
  }

  getFillteredColumn(Field: any[]) {
    var FilteredColumns: UILayoutFieldModel[] = [];
    Field = Field.sort((a, b) => a.VisibleIndex - b.VisibleIndex);
    let filteredColumn;
    Field.forEach(x => {
      if (x.IsVisible) {
        if (x.IsVisible && x.FieldName.toLowerCase() == 'blank') {
          var blankfiledUIlayout = new UILayoutFieldModel;
          blankfiledUIlayout.OverrideUIType = 'empty';
          FilteredColumns.push(blankfiledUIlayout);
        }
        filteredColumn = this.componentSettings.UIFields.filter(y => y.FieldName.toLowerCase() == x.FieldName.toLowerCase());
        if (x.IsVisible && !CommonHelper.isEmptyObject(filteredColumn)) {
          filteredColumn[0].VisibleIndex = x.VisibleIndex;
          FilteredColumns.push(filteredColumn[0]);
        }
      }
    });

    return FilteredColumns;
  }


  ChangeModeOfControls(mode: string) {
    if (mode.toLowerCase() == "edit") {
      this.gridProperties.Mode = "Edit";
      this.isGridview = false;
      this.dataRowTemplateName = 'dataRowTemplate';
      this.dataGrid.filterRow.visible = true;
      this.dataGrid.showColumnHeaders = false;
      this.dataGrid.instance.option("editing.mode", "form");
      if (this.recordID && this.recordID.length > 0)
        this.editRowKey = this.recordID;
      this.detailViewEditMode(false);
    }
    else {
      this.gridProperties.Mode = "View";
    }
  }
}



