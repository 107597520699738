import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateTimeHelperService {
  userLanguage: string = "en-GB";
  constructor() { 
    this.userLanguage = localStorage.getItem('UserLanguage')?localStorage.getItem('UserLanguage'):"en-GB";
  }

  FormatData(data: any, dataType: String): string {
    if (Date.parse(data) && dataType.toLowerCase() == "datetime" && data!=null && data!=undefined) {
      return formatDate(data, 'short', this.userLanguage);
    }

    else if (Date.parse(data) && dataType.toLowerCase() == "date" && data!=null && data!=undefined) {
      return formatDate(data, 'shortDate', this.userLanguage);
    }
    else if (Date.parse(data) && dataType.toLowerCase() == "time" && data!=null && data!=undefined) {
      return formatDate(data, 'shortTime', this.userLanguage);
    }
    else {
      return data;
    }
  }
}
