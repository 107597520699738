import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Guid } from 'guid-typescript';
import { EncryptionService } from 'src/app/encryption.service';
import { DocumentViewerComponentProperties } from 'src/app/models/DocumentViewerComponentProperties';
import { DocumentModel, DocumentViewerService, ImagedataSource } from 'src/app/services/documentViewer/document-viewer.service';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { EnumHelper } from 'src/app/util/enum-helper';
import { environment } from 'src/environments/environment';
import devices from 'devextreme/core/devices';


@Component({
  selector: 'app-document-assests-viewer-detail',
  templateUrl: './document-assests-viewer-detail.component.html',
  styleUrls: ['./document-assests-viewer-detail.component.scss']
})
export class DocumentAssestsViewerDetailComponent extends PageBaseService {
  documentViewerProperties: DocumentViewerComponentProperties;
  isVisible: Boolean = false;
  isFullscreenWebpart: boolean = false;
  documentSRC: string = '';
  isDocViewer: boolean = false;
  isImage: boolean = false;
  imageObject: ImagedataSource[];
  documentList: DocumentModel[];
  documentRecord: DocumentModel;
  @Input() instanceID: string;
  componentID: string = 'documentassetsviewer';

  constructor(private localStorageService: LocalStorageService, private _router: Router, private _enc: EncryptionService, private docViewService: DocumentViewerService, private httpClient: HttpClient) {
    super(_router, _enc);
  }
  async ngOnInit() {
    this.documentViewerProperties = <DocumentViewerComponentProperties>this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.documentAssestsViewerComponentProperties, [this.instanceID]);
    if (this.documentViewerProperties == null || this.documentViewerProperties == undefined) {
      this.documentViewerProperties = new DocumentViewerComponentProperties();
      this.GetComponentProperties();
    }
    else{
      this.BindDocument();
    }
    
  }

  isPdf:boolean;

  isIOS:boolean;

  expandCollaspeComponent() {
    this.isVisible = !this.isVisible;
  }
  fullscreenWebpart() {
    this.isFullscreenWebpart = !this.isFullscreenWebpart
  }
  async BindDocument() {

    var RecordID = await this.GetKeyValueFromQueryString('RecordID');
    var action = "viewdocument";

    if (RecordID != null && RecordID != undefined && RecordID != '') {
      this.documentRecord = new DocumentModel();
      switch (action.toLowerCase()) {
        case "viewdocument":
          if (!this.IsNullorEmpty(this.documentViewerProperties.RelationshipID) && this.documentViewerProperties.BindOnRelationshipOnly.toLowerCase() == "true") {

            var documentRelationshipView = await this.docViewService.GetRelatedDocument(RecordID, this.documentViewerProperties.RelationshipID);
            if (documentRelationshipView != null && documentRelationshipView.length > 0) {
              this.documentRecord.RecordID = documentRelationshipView[0].RecordID;
              this.documentRecord.Mime = documentRelationshipView[0].Mime;
              this.documentRecord.Name = documentRelationshipView[0].Name;
              this.documentRecord.Path = documentRelationshipView[0].Path;
            }
          }
          else {

            this.documentRecord = await this.docViewService.GetDocumentRecord(RecordID);
          }
          if (!this.IsNullorEmpty(this.documentRecord.Path) && !this.IsNullorEmpty(this.documentRecord.Mime) && this.documentRecord.RecordID != Guid.EMPTY.toString()) {

            var Name = this.documentRecord.RecordID + "_" + this.documentRecord.Path;
            this.documentSRC = environment.azureStorageURL + "Documents/" + Name;

            if ((this.GetFileExtension(this.documentRecord.Path).toLowerCase() == "jpg"
              || this.GetFileExtension(this.documentRecord.Path).toLowerCase() == "jpeg" ||
              this.GetFileExtension(this.documentRecord.Path).toLowerCase() == "png" ||
              this.GetFileExtension(this.documentRecord.Path).toLowerCase() == "gif")) {
              this.isDocViewer = false;
              this.isImage = true;
            }
            else {
              this.isDocViewer = true;
              this.isImage = false;
            }
            if (this.documentRecord != null && this.documentRecord.RecordID != Guid.EMPTY.toString()) {
              this.docViewService.SetDocumentReadCounter(this.documentRecord.RecordID);
            }
          }
          break;
      }

      this.isPdf = this.documentSRC.toLowerCase().endsWith(".pdf");
      this.isIOS =  devices.current().ios;

      if(this.isPdf && this.isIOS) {
        this.isDocViewer = false;
      }
    }
  }

  GetComponentProperties() {

    var ComponentUrl = "";
    ComponentUrl = "api/ComponentProperties/GetComponentPropertiesData?componentID=";
    this.httpClient.get(environment.apiUrl + ComponentUrl + this.componentID + "&instanceID=" + this.instanceID)
      .subscribe((response: any) => {
        this.setComponentProperties(response.Properties);
      });

  }

  setComponentProperties(componentProperties) {
    var docViewProperties = JSON.parse(componentProperties);
    docViewProperties.DocumentAssetsViewerProperties.forEach(componentProperty => {
      switch (componentProperty.key.toLowerCase()) {
        case "title":
          this.documentViewerProperties.Title = componentProperty.value;
          break;
        case "relationshipid":
          this.documentViewerProperties.RelationshipID = componentProperty.value;
          break;
        case "bindonrelationshiponly":
          this.documentViewerProperties.BindOnRelationshipOnly = componentProperty.value;
          break;
        case "allowedroles":
          this.documentViewerProperties.AllowedRoles = componentProperty.value.split(',').map(x => x.toLowerCase());;
          break;

      }
    });

    this.localStorageService.SetLocal(EnumHelper.LocalStorageKey.documentAssestsViewerComponentProperties, JSON.stringify(this.documentViewerProperties), [this.instanceID])
    this.BindDocument();
  }
}



