import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs";
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { EnumHelper } from "src/app/util/enum-helper";

@Injectable()
export class AuthIntercepetor implements HttpInterceptor {
    constructor(private cookieService: CookieService){
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const bearerTokenID = this.cookieService.get("BearerToken");
        var tokenID:string = this.cookieService.get("TokenID");
        if(tokenID.includes("=")){
            tokenID = tokenID.split("=")[1];
        }
        const CurrentTimeZone = this.cookieService.get("CurrentTimeZone");
        const tzName = new Date().toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' }).substring(4)
        

        if (tokenID) {
            const cloned = req.clone({
                headers: req.headers.set("Authorization", "Bearer " + bearerTokenID).set("tokenID", tokenID).set("Content-Type","application/json").set("currentTimeZone", CurrentTimeZone).set("RequestTimeZone", tzName)

            });
            return next.handle(cloned);
        } else {
            return next.handle(req);
        }
    }
}