import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BrandingService } from 'src/app/services/branding/branding.service';
@Component({
  selector: 'app-unauthorised',
  templateUrl: './unauthorised.component.html',
  styleUrls: ['./unauthorised.component.scss']
})
export class UnauthorisedComponent {
  constructor(public brandingService: BrandingService, private router: Router) {
  }
  redirectToHome() {
    this.router.navigateByUrl('home');
  }
}
