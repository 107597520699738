<app-breadcrumb></app-breadcrumb>
<div class="row webpartrow list-control">
    <div class="col-lg-12">
        <dx-tab-panel [(selectedIndex)]="tab1Index" (onTitleClick)="onTab1ItemClick($event)"  [swipeEnabled]="false" [animationEnabled]="false" [elementAttr]="{ class: 'custom_tabpanel' }">
            <dxi-item title="Outstanding">
                <app-grid #grdMyQuestionnaireResponse_Outstanding [IsUnauthorizedPage]="false" (gridCustomActions)="gridCustomActions($event)" instanceID="MyQuestionnaireResponse_Outstanding"   ></app-grid>
            </dxi-item>
            <dxi-item title="All Responses">
                    <app-grid #grdMyQuestionnaireResponse_All [IsUnauthorizedPage]="false" (gridCustomActions)="gridCustomActions($event)"  instanceID="MyQuestionnaireResponse_All"></app-grid>
            </dxi-item>
        </dx-tab-panel>

    <app-questionnaire-response-viewer #questionnaireResponseViewer></app-questionnaire-response-viewer>
    </div>
</div>