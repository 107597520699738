<app-breadcrumb></app-breadcrumb>
<div class="row webpartrow list-control">
    <div class="col-lg-12 m-b-30">
        <dx-tab-panel [(selectedIndex)]="tab1Index" (onTitleClick)="onTab1ItemClick($event)" [swipeEnabled]="false"
            [elementAttr]="{ class: 'custom_tabpanel' }">
            <dxi-item [visible]="isMyJobTabVisible" title="{{'My'}}">
                <app-grid (isGridHidden)="hideMyJobTab($event)" (gridCustomActions)="gridCustomActions($event)" [gridProperties]="myJobGrid"
                    [IsUnauthorizedPage]="false" instanceID="myJobGrid" [isRowClick]="true"
                    (rowClick)="rowClick($event)"></app-grid>
            </dxi-item>
            <dxi-item [visible]="isAllJobTabVisible" title="{{'All'}}">
                <app-grid (isGridHidden)="hideAllJobTab($event)" (gridCustomActions)="gridCustomActions($event)" [gridProperties]="allJobGrid"
                    [IsUnauthorizedPage]="false" instanceID="allJobGrid" [isRowClick]="true"
                    (rowClick)="rowClick($event)"></app-grid>
            </dxi-item>
            <dxi-item [visible]="isInactiveJobTabVisible" title="{{'Inactive'}}">
                <app-grid (isGridHidden)="hideInactiveJobTab($event)" (gridCustomActions)="gridCustomActions($event)" [gridProperties]="inactiveJobGrid"
                    [IsUnauthorizedPage]="false" instanceID="inactiveJobGrid" [isRowClick]="true"
                    (rowClick)="rowClick($event)"></app-grid>
            </dxi-item>
            <dxi-item [visible]="isDeletedJobTabVisible" title="{{'Deleted'}}">
                <app-grid (isGridHidden)="hideDeletedJobTab($event)" (gridCustomActions)="gridCustomActions($event)" [gridProperties]="deletedJobGrid"
                    [IsUnauthorizedPage]="false" instanceID="deletedJobGrid" [isRowClick]="true"
                    (rowClick)="rowClick($event)"></app-grid>
            </dxi-item>

        </dx-tab-panel>
    </div>
    <div class="col-lg-12">
        <app-big-button #bigbutton url="/Lobas.Web/Pages/Job/JobList.aspx" allowRoles="superuser"></app-big-button>
        <div class="clearfix"></div>
    </div>
</div>