import { Injectable } from "@angular/core";
import { FieldSettings } from "./FieldSettings";
@Injectable({
    providedIn: 'root',
})

export class ComponentInfo {
    ComponentId: string = ''
    Title: string = ''
    isDetailComponent: boolean = false;
    ComponentMinHeight: string = '130px';
    ComponentMaxHeight: string = '540px';
    GridDisplayMode: string = "auto";
    dataRowTemplateName: string;
    UILayoutID: string = '00000000-0000-0000-0000-000000000000'
    Datasource: string = ''
    ObjectID: string = '00000000-0000-0000-0000-000000000000'
    GetRecordsApiEndPoint: string = ''
    DeleteRecordsAPIEndPoint: string = ''
    IsHeaderVisible: Boolean = true;
    RelationshipID: string = ''
    AllowedRoles: string[] = []
    GridPageSize: number = 20
    isAlignmentTopToBottom: boolean = false
    isWebpartHeaderHidden: boolean = false
    FieldSettings: FieldSettings[] = []
}
