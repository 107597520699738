<app-header *ngIf="showHead"></app-header>
<div class="content-wrapper">
   <div class="content-wrapper-scroll">
      <!-- <div id="parallax" class="paralax-background-image"></div> -->
      <div class="container-fluid" *ngIf="this.brandingHelper.IsBrandingLoaded()">
         <router-outlet></router-outlet>
      </div>
      <!-- <div class="container-fluid" *ngIf="!this.brandingHelper.IsBrandingLoaded()">
         <span class="rotateloader"><img src="../assets/images/loader.gif" alt="Loader" /></span>
         <div class="rotateloaderoverlay"></div>
      </div> -->
   </div>
</div>
<!-- <app-footer></app-footer> -->
<div id="body-overlay" ></div>