import { Guid } from "guid-typescript"

export class RemindNotificationRecipientModel {
    RecordID: string ;
    Name:string = Guid.EMPTY;
    Subject:string = '';
    ContactID:string = '';    
    UrlDomain:string = '';    
  }

  export class RemindNotificationRecipientModelList {
    RemindNotificationRecipientList: RemindNotificationRecipientModel[] =[]
    responseMessage :string=''
  }


  export class CancelNotificationRecipientModel {
    RecordID: string ;   
  }

  export class CancelNotificationRecipientModelList {
    cancelNotificationRecipientModels: CancelNotificationRecipientModel[] =[]
    responseMessage :string=''
  }



  