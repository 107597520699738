import { Component, ViewChild } from '@angular/core';
import { ComponentInfo } from 'src/app/models/ComponentInfo';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/encryption.service';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { AppEvent } from 'src/app/models/AppEvent';
import { AppEventType } from 'src/app/enums/appEventType';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { GridComponent } from '../../common/grid/grid.component';
@Component({
  selector: 'app-shift',
  templateUrl: './shift.component.html',
  styleUrls: ['./shift.component.scss']
})
export class ShiftComponent  extends PageBaseService{
  ComponentObjectID: string;
  myShiftReportGrid=new ComponentInfo();
  filterExpressionsArrayByQueryString:any;
  isMyShiftReportTabVisible: boolean =false;
  isAllShiftReportTabVisible: boolean=false;
  isDeletedShiftReportTabVisible: boolean =false;
  tab1Index:number=0;
  okAlertBtnTxt: string = this.translateService.instant('OkButtonText');
  successAlertTitle: string = this.translateService.instant('SuccessTitle');
  errorAlertTitle: string = this.translateService.instant('warningText');
  @ViewChild("allshiftreportgrid", { static: false }) allshiftreportgrid: GridComponent
  constructor(private localStorageService: LocalStorageService, private eventQueue:LobasEventQueueService, private _router: Router, private _enc: EncryptionService, 
    private httpClient: HttpClient, public translateService: TranslateService, private alertService: AlertService) {
    super(_router, _enc);
   this.ComponentObjectID = 'E8548C49-1CC3-4D3F-8CE7-EAB74B1EE593';
   this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }

  onTab1ItemClick(ev: any) {    
    this.SetTabIndex("tab1Index",ev?.itemIndex,this.localStorageService);
  }

  public rowClick(ev: any) {
    let data = ev.data;
    let navigateURL = ev.navigateURL;
    var queryStringParameters = 'RecordID=' + data.RecordID;
    if (data.JobID) {
      queryStringParameters = queryStringParameters + '&JobID=' + data.JobID;
    }
    this.AddQuerystringParameterAndnavigateWithDecryptedUrl(navigateURL, queryStringParameters);
  } 
  async ngOnInit()
  {
    var tabNumber = await this.GetKeyValueFromQueryString("TabControl");
    if (tabNumber) {
      this.tab1Index = Number(tabNumber) - 1;
      this.SetTabIndex("tab1Index", this.tab1Index, this.localStorageService);
    }
    else {
      this.tab1Index = this.GetTabIndex("tab1Index", this.localStorageService);
      this.SetTabIndex("tab1Index", this.tab1Index, this.localStorageService);
    }
    
    var filterByQuerystring = await this.GetKeyValueFromQueryString('FilterBy');
    if(filterByQuerystring)
    {
      this.UpdateFilterExpressionsByQueryString(filterByQuerystring,'myShiftReportGrid');
    }
  }

  UpdateFilterExpressionsByQueryString(queryStringFilterBy:string,instanceName) {     
    this.filterExpressionsArrayByQueryString = [];
    if(queryStringFilterBy.toLowerCase()==="shiftreportsdue" && instanceName.toLowerCase()=="myshiftreportgrid")
      {
        
        this.filterExpressionsArrayByQueryString.push(['ShiftReportStatus_EnumID', '=', '52059aa6-d872-4413-a751-a89162457dad'])
        
      }
      else if(queryStringFilterBy.toLowerCase()==="shiftreportsoverdue" && instanceName.toLowerCase()=="myshiftreportgrid")
      {        
        this.filterExpressionsArrayByQueryString.push(  ['ShiftReportStatus_EnumID', '=', '55fa3bf2-6d2f-457e-a524-592d4bc4a392'])
        
      }          
    }

    hideMyshiftReportTab(isGridHidden) {
      this.isMyShiftReportTabVisible = !isGridHidden;
    }
    
    hideAllShiftReportTab(isGridHidden) {
      this.isAllShiftReportTabVisible = !isGridHidden;
    } 
    
    hideDeletedShiftReportTab(isGridHidden) {
      this.isDeletedShiftReportTabVisible = !isGridHidden;
    }

    gridCustomActions(data: any) {
        if(data.CommandObject.Command.toLowerCase()=="approveshiftreport") {
          var objectData: any = {};
          objectData.RecordIdList = [];
          objectData.ShiftReportStatus_EnumID = "7D4D0447-BD69-40A0-9B7C-674146776C3B";
          var url= environment.apiUrl + data.CommandObject.Definition.APIActionEndPoint; 
          data.SelectedRecords.forEach(recordID => {
            objectData.RecordIdList.push(recordID);
          });
          lastValueFrom(this.httpClient.put(url, objectData,
            {
              headers: {
                'objectID': this.ComponentObjectID
              },
              responseType: "text"
            }
          )).then(response => {
            this.translateService.get('OperationPerformedSuccess', { command: data.CommandObject.Name }).subscribe((text: string) => {
              this.alertService.showAlertPopup(this.successAlertTitle, text,
                'fa-solid fa-check', this.okAlertBtnTxt);
            });
            this.allshiftreportgrid.refresh();
          })
          .catch((ex) => { throw ex.message });
        }
    }
}

