
import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { CookieService } from 'ngx-cookie-service';
import { NoteModel } from 'src/app/models/NoteModel';
import { HubConnectionBuilder, IHttpConnectionOptions } from '@microsoft/signalr';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})


export class SignalRService {
   hubConnection: signalR.HubConnection;
   tokenID:string;
    constructor(private cookieService: CookieService){
      const bearerTokenID = this.cookieService.get("BearerToken");
      this.tokenID = this.cookieService.get("TokenID");
      if(this.tokenID.includes("=")){
        this.tokenID = this.tokenID.split("=")[1];
      }
      const customHeaders = {
        "Authorization": "Bearer " + bearerTokenID,
        "tokenID": this.tokenID,
        "Content-Type":"application/json"
       };  
       
    this.hubConnection = new signalR.HubConnectionBuilder()
    .withUrl(environment.apiUrl+"chathub", {
      withCredentials: true,
      headers:customHeaders
  })
  .configureLogging(signalR.LogLevel.Information)
  .withAutomaticReconnect()
  .build();

    this.hubConnection.on('ReceiveMessage', (note: NoteModel,parentID:string) => {
      console.log(`Received message from ${note}`);
      // Handle the received message here
    });
    this.startConnection();
  }

  private startConnection() {
    this.hubConnection.start()
      .then(() => console.log('Connection started'))
      .catch(err => console.log('Error while starting connection: ' + err));

    this.hubConnection.onclose(error => {
      console.error('Connection closed due to error: ', error);
      this.startConnection(); // Optional: Attempt to restart the connection
    });
  }
  public sendMessage(note: NoteModel, parentID: string,relationshipID :string) {
    this.hubConnection.invoke('SendMessage',this.tokenID,"08269E66-AD83-409C-926F-99F144701128", note, parentID, relationshipID)
    .catch(err => console.error(err));
  }
}
