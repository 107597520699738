import { Component } from '@angular/core';

@Component({
  selector: 'app-time-recording',
  templateUrl: './time-recording.component.html',
  styleUrls: ['./time-recording.component.scss']
})
export class TimeRecordingComponent {
  isVisible: boolean = false;
  expandCollaspeComponent() {
    this.isVisible = !this.isVisible
  }
}
