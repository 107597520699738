import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})


export class GridSettingsModel{
    Settings:string = '';
    ShowFilterRow:boolean = false;
}
