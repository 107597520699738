<div class="customloader" id="customloader"></div>
<dx-popup #SendCloseCallReport [wrapperAttr]="{class: 'sendclosecallreppopup lobas-popup' }" [showTitle]="true"
    title="Send Close Call Report" [resizeEnabled]="true" [dragEnabled]="false" [hideOnOutsideClick]="false">
    <!-- <dx-button class="close-btn aaa" icon="far fa-times-circle" (onClick)="PopupClose()">
    </dx-button> -->
    <div class="sendclosecallreppopup_row">
        <div class="sendclosecallreppopup_col">
            <app-send-email #SendEmail></app-send-email>
        </div>
        <div class="sendclosecallreppopup_col">
            <ngx-doc-viewer [viewerUrl]="closeCallReportUrl" [url]="closeCallReportUrl" viewer="url"></ngx-doc-viewer>
        </div>
    </div>
    <div class="form-btn-outer">
        <dx-button id="btnCancel" (onClick)="Cancel($event)" text="Cancel">
        </dx-button>
        <dx-button id="btnSave" (onClick)="Send($event)" text="Send">
        </dx-button>
    </div>
    <!-- <div class="webpart-card">
        <div class="webpart-cardbody">
            <div class="webpart-card-header" [ngClass]="!docViewerVisible ? 'webpart-card-closed' : 'webpart-card-open'">
                <div class="webpart-card-header-title">
                    <span>Viewer</span>
                </div>
                <div class="webpart-card-header-action">
                    <dx-button (onClick)="documentexpandCollaspe()"
                        [icon]="!docViewerVisible ? 'fa-regular fa-angle-up' : 'fa-regular fa-angle-down'"></dx-button>
                </div>
            </div>

            <div [hidden]="!docViewerVisible">
                <ngx-doc-viewer [viewerUrl]="closeCallReportUrl" [url]="closeCallReportUrl"
                    viewer="url"></ngx-doc-viewer>
            </div>
        </div>

        <div class="webpart-cardbody m-t-15">
            <div class="webpart-card-header" [ngClass]="!sendEmailVisible ? 'webpart-card-closed' : 'webpart-card-open'">
                <div class="webpart-card-header-title">
                    <span>Message Details</span>
                </div>
                <div class="webpart-card-header-action">
                    <dx-button (onClick)="SendEmailexpandCollaspe()"
                        [icon]="!sendEmailVisible ? 'fa-regular fa-angle-up' : 'fa-regular fa-angle-down'"></dx-button>
                </div>
            </div>
            <div [hidden]="!sendEmailVisible">
                <app-send-email #SendEmail></app-send-email>
            </div>
        </div>
        <div class="form-btn-outer">
            <dx-button id="btnCancel" (onClick)="Cancel($event)" text="Cancel">
            </dx-button>
            <dx-button id="btnSave" (onClick)="Send($event)" text="Send">
            </dx-button>
        </div>
    </div> -->
</dx-popup>