import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ComponentPropertiesService {

  constructor(private httpClient: HttpClient) { }
  
getComponentPropertiesData(componentID:string,instanceID:string){
      return this.httpClient.get(environment.apiUrl + "api/ComponentProperties/GetComponentPropertiesData?componentID=" + componentID + "&instanceID=" + instanceID)
  }
}
