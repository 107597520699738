export class RelationshipRecordModel {
    RecordID: string; 
    RelationshipID: string; 
    Object1_RecordID: string; 
    Object2_RecordID: string; 
    StartDate?: Date; 
    EndDate?: Date; 
    DataRelationStatus_EnumID?: string; 
    CreatedDate?: Date; 
    Created_UserID?: string; 
    Updated_UserID?: string;
    ExtendedAttributes: string;
    DisplayOrder?: number; 

  }
  