import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { lastValueFrom } from 'rxjs';
import { RelationshipRecordModel } from 'src/app/models/RelationshipRecordModel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WorkerSelectionServiceService {

  constructor(private httpClient: HttpClient) { }

  getWorkers(ShiftRequirementID:string){
    return this.httpClient.get(environment.apiUrl + 'api/Contact/GetWorker/'+ShiftRequirementID);
  }

  getJobDetails(jobId:any){
    return  this.httpClient.get(environment.apiUrl + 'api/Job/Get/'+jobId);
  }

  AssignWorkers(relationshipModel:RelationshipRecordModel){
    // return this.httpClient.post(environment.apiUrl + 'api/Job/AssignWorker/'+JobId,workerId)
    return this.httpClient.post(environment.apiUrl + 'api/RelationshipRecord/insert', JSON.stringify(relationshipModel),
        {
          headers: {
            'objectID': 'C14BBB2D-4C1F-43B5-A3FB-8333A163F76F'
          },
          responseType: "text"
        }
      )
  }

  UnAssignWorkers(relationshipModel:RelationshipRecordModel){
    return this.httpClient.post(environment.apiUrl + 'api/RelationshipRecord/DeleteRelationshipRecord', JSON.stringify(relationshipModel),
    {
      headers: {
        'objectID': 'C14BBB2D-4C1F-43B5-A3FB-8333A163F76F'
      },
      responseType: "text"
    }
  )
  }
}
