import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './Components/login/login.component';
import { NotFoundComponent } from './Components/not-found/not-found.component';
import { RegisterComponent } from './Components/register/register.component';
import { AuthGuard } from './guards/auth.guard';
import { contactdetailcomponent } from './Components/Lobas/contact/detail/contact/contact-detail.component'; 
import { CalendarRosterComponent } from './Components/calendar-roster/calendar-roster.component';
import { TesterComponent } from './Components/common/tester/tester.component';
import { ResetPasswordComponent } from './Components/reset-password/reset-password.component';
import { EmailVerificationComponent } from './Components/email-verification/email-verification.component';
import { JobComponent } from './Components/stat/job/job.component';
import { JobDetailsComponent } from './Components/stat/job-details/job-details.component';
import { CloseCallComponent } from './Components/stat/close-call/close-call.component';
import { CloseCallDetailsComponent } from './Components/stat/close-call-details/close-call-details.component';
import { CloseCallDashboardComponent } from './Components/stat/close-call-dashboard/close-call-dashboard.component';
import { CompanyComponent } from './Components/company/company.component';
import { CompanyDetailsComponent } from './Components/company-details/company-details.component';
import { DocumentComponent } from './Components/document/document.component';
import { DistanceManagerComponent } from './Components/distance-manager/distance-manager.component';
import { DocumentDetailsComponent } from './Components/document-details/document-details.component';
import { AppointmentComponent } from './Components/appointment/appointment.component';
import { AppointmentDetailsComponent } from './Components/appointment-details/appointment-details.component';
import { NotificationComponent } from './Components/notification/notification.component';
import { NotificationDetailsComponent } from './Components/notification-details/notification-details.component';
import { QuestionnaireDetailsComponent } from './Components/questionnaire-details/questionnaire-details.component';
import { QuestionnaireComponent } from './Components/questionnaire/questionnaire.component';
import { QuestionnaireResponseDetailsComponent } from './Components/questionnaire-response-details/questionnaire-response-details.component';
import { AddressDetailsComponent } from './Components/address-details/address-details.component';
import { ShiftComponent } from './Components/stat/shift/shift.component';
import { ShiftDetailsComponent } from './Components/stat/shift-details/shift-details.component';
import { assetgridcomponent } from './Components/stat/asset/grid/asset/asset-grid.component';
import { assetdetailcomponent } from './Components/stat/asset/detail/asset/asset-detail.component';
import { HomeComponent } from './Components/home/home.component';
import { UnauthorisedComponent } from './Components/common/unauthorised/unauthorised.component';
import { NotificationRecipientDocumentComponent } from './Components/notification-recipient-document/notification-recipient-document.component';
import { NotificationRecipientDetailsComponent } from './Components/notification-recipient-details/notification-recipient-details.component';
import { LogOutComponent } from './Components/log-out/log-out.component';
import { ProjectComponent } from './Components/admin/Maintenance/project/project.component';
import { AboutComponent } from './Components/about/about.component';
import { TimeSeriesComponent } from './Components/admin/Maintenance/time-series/time-series.component';
import { TradeComponent } from './Components/config/trade/trade.component';
import { TradeDetailsComponent } from './Components/config/trade-details/trade-details.component';
import { contactgridcomponent } from './Components/Lobas/contact/grid/contact/contact-grid.component';
import { testergridcomponent } from './Components/Test/tester/grid/tester/tester-grid.component';
import { testerdetailcomponent } from './Components/Test/tester/detail/tester/tester-detail.component';
import { assettestgridcomponent } from './Components/stat/assettest/grid/assettest/assettest-grid.component'; 
import { assettestdetailcomponent } from './Components/stat/assettest/detail/assettest/assettest-detail.component';
import { AssettestListPageComponent } from './Components/stat/assettest/custom/assettest-list-page/assettest-list-page.component';
import { workitemdetailcomponent } from './Components/stat/workitem/detail/workitem/workitem-detail.component';
import { workitemgridcomponent } from './Components/stat/workitem/grid/workitem/workitem-grid.component';
import { taxrategridcomponent } from './Components/Lobas/taxrate/grid/taxrate/taxrate-grid.component';
import { taxratedetailcomponent } from './Components/Lobas/taxrate/detail/taxrate/taxrate-detail.component';
import { fatiguemetricresultsgridcomponent } from './Components/stat/fatiguemetricresults/grid/fatiguemetricresults/fatiguemetricresults-grid.component';
import { fatiguemetricresultsdetailcomponent } from './Components/stat/fatiguemetricresults/detail/fatiguemetricresults/fatiguemetricresults-detail.component';
import { financetransactionheadergridcomponent } from './Components/stat/financetransactionheader/grid/financetransactionheader/financetransactionheader-grid.component';
import { financetransactionheaderdetailcomponent } from './Components/stat/financetransactionheader/detail/financetransactionheader/financetransactionheader-detail.component';
import { autonumbergridcomponent } from './Components/Lobas/autonumber/grid/autonumber/autonumber-grid.component';
import { autonumberdetailcomponent } from './Components/Lobas/autonumber/detail/autonumber/autonumber-detail.component';
import { JobSetupComponent } from './Components/stat/job-setup/job-setup.component';
import { sitemapgridcomponent } from './Components/Lobas/sitemap/grid/sitemap/sitemap-grid.component';
import { sitemapdetailcomponent } from './Components/Lobas/sitemap/detail/sitemap/sitemap-detail.component';
import { tokengridcomponent } from './Components/Lobas/token/grid/token/token-grid.component';
import { tokendetailcomponent } from './Components/Lobas/token/detail/token/token-detail.component';
import { ecmtriggergridcomponent } from './Components/Lobas/ecmtrigger/grid/ecmtrigger/ecmtrigger-grid.component';
import { ecmtriggerdetailcomponent } from './Components/Lobas/ecmtrigger/detail/ecmtrigger/ecmtrigger-detail.component';
import { JobPlannerComponent } from './Components/stat/job-planner/job-planner.component';
import { objectgridcomponent } from './Components/Lobas/object/grid/object/object-grid.component';
import { objectdetailcomponent } from './Components/Lobas/object/detail/object/object-detail.component';
import { objectattributesgridcomponent } from './Components/Lobas/objectattributes/grid/objectattributes/objectattributes-grid.component';
import { objectattributesdetailcomponent } from './Components/Lobas/objectattributes/detail/objectattributes/objectattributes-detail.component';
import { relationshipgridcomponent } from './Components/Lobas/relationship/grid/relationship/relationship-grid.component';
import { relationshipdetailcomponent } from './Components/Lobas/relationship/detail/relationship/relationship-detail.component';
import { objectdatasetgridcomponent } from './Components/Lobas/objectdataset/grid/objectdataset/objectdataset-grid.component';
import { objectdatasetdetailcomponent } from './Components/Lobas/objectdataset/detail/objectdataset/objectdataset-detail.component';
import { datatransformgridcomponent } from './Components/Lobas/datatransform/grid/datatransform/datatransform-grid.component';
import { datatransformdetailcomponent } from './Components/Lobas/datatransform/detail/datatransform/datatransform-detail.component';
import { objectt4gridcomponent } from './Components/Lobas/objectt4/grid/objectt4/objectt4-grid.component';
import { objectt4detailcomponent } from './Components/Lobas/objectt4/detail/objectt4/objectt4-detail.component';
import { objectrecordgridcomponent } from './Components/Lobas/objectrecord/grid/objectrecord/objectrecord-grid.component';
import { objectrecorddetailcomponent } from './Components/Lobas/objectrecord/detail/objectrecord/objectrecord-detail.component';
import { uilayoutgridcomponent } from './Components/Lobas/uilayout/grid/uilayout/uilayout-grid.component';
import { uilayoutdetailcomponent } from './Components/Lobas/uilayout/detail/uilayout/uilayout-detail.component';
import { uilayoutfieldgridcomponent } from './Components/Lobas/uilayoutfield/grid/uilayoutfield/uilayoutfield-grid.component';
import { uilayoutfielddetailcomponent } from './Components/Lobas/uilayoutfield/detail/uilayoutfield/uilayoutfield-detail.component';
import { objectcommandgridcomponent } from './Components/Lobas/objectcommand/grid/objectcommand/objectcommand-grid.component';
import { objectcommanddetailcomponent } from './Components/Lobas/objectcommand/detail/objectcommand/objectcommand-detail.component';

const routes: Routes = [
  { path: 'job-planner', canActivate: [AuthGuard], component: JobPlannerComponent },
  { path: 'job-setup', canActivate: [AuthGuard], component: JobSetupComponent },
  { path: 'financetransactionheader-details', canActivate: [AuthGuard], component: financetransactionheaderdetailcomponent },
  { path: 'financetransactionheader', canActivate: [AuthGuard], component: financetransactionheadergridcomponent },
  { path: 'tax-rate-details', canActivate: [AuthGuard], component: taxratedetailcomponent },
  { path: 'tax-rate', canActivate: [AuthGuard], component: taxrategridcomponent },
  { path: 'work-items-details', canActivate: [AuthGuard], component: workitemdetailcomponent },
  { path: 'work-items', canActivate: [AuthGuard], component: workitemgridcomponent },
  { path: 'trade-details', canActivate: [AuthGuard], component: TradeDetailsComponent },
  { path: 'trade', canActivate: [AuthGuard], component: TradeComponent },
  { path: 'TimeSeries', canActivate: [AuthGuard], component: TimeSeriesComponent },
  { path: 'job', canActivate: [AuthGuard], component: JobComponent },
  { path: 'job-details', canActivate: [AuthGuard], component: JobDetailsComponent },
  { path: 'notification-details', canActivate: [AuthGuard], component: NotificationDetailsComponent },
  { path: 'shift', canActivate: [AuthGuard], component: ShiftComponent },
  { path: 'shift-details', canActivate: [AuthGuard], component: ShiftDetailsComponent },
  { path: 'closecall', canActivate: [AuthGuard], component: CloseCallComponent },
  { path: 'closecall-details', canActivate: [AuthGuard], component: CloseCallDetailsComponent },
  { path: 'closecall-home', canActivate: [AuthGuard], component: CloseCallDashboardComponent },
  { path: 'autonumber', canActivate: [AuthGuard], component: autonumbergridcomponent },
  { path: 'autonumber-details', canActivate: [AuthGuard], component: autonumberdetailcomponent },
  { path: 'token', canActivate: [AuthGuard], component: tokengridcomponent },
  { path: 'token-details', canActivate: [AuthGuard], component: tokendetailcomponent },
  { path: 'asset', canActivate: [AuthGuard], component: assetgridcomponent },
  { path: 'asset-details', canActivate: [AuthGuard], component: assetdetailcomponent },
  { path: 'assettest', canActivate: [AuthGuard], component: AssettestListPageComponent },
  { path: 'assettest-details', canActivate: [AuthGuard], component: assettestdetailcomponent },
  { path: 'home', canActivate: [AuthGuard], component: HomeComponent },
  { path: 'people', canActivate: [AuthGuard], component: contactgridcomponent },
  { path: 'people-details', canActivate: [AuthGuard], component: contactdetailcomponent },
  { path: 'company', canActivate: [AuthGuard], component: CompanyComponent },
  { path: 'company-details', canActivate: [AuthGuard], component: CompanyDetailsComponent },
  { path: 'document', canActivate: [AuthGuard], component: DocumentComponent },
  { path: 'distance-manager', canActivate: [AuthGuard], component: DistanceManagerComponent },
  { path: 'document-details', canActivate: [AuthGuard], component: DocumentDetailsComponent },
  { path: 'appointment', canActivate: [AuthGuard], component: AppointmentComponent },
  { path: 'appointment-details', canActivate: [AuthGuard], component: AppointmentDetailsComponent },
  { path: 'notification', canActivate: [AuthGuard], component: NotificationComponent },
  { path: 'notification-details', canActivate: [AuthGuard], component: NotificationDetailsComponent },
  { path: 'questionnaire', canActivate: [AuthGuard], component: QuestionnaireComponent },
  { path: 'questionnaire-details', canActivate: [AuthGuard], component: QuestionnaireDetailsComponent },
  { path: 'questionnaire-response-details', canActivate: [AuthGuard], component: QuestionnaireResponseDetailsComponent },
  { path: 'address-details', canActivate: [AuthGuard], component: AddressDetailsComponent },
  { path: 'Project', canActivate: [AuthGuard], component: ProjectComponent },
  { path: 'about', canActivate: [AuthGuard], component: AboutComponent },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogOutComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'roster', component: CalendarRosterComponent },
  { path: 'tester', component: testergridcomponent },
  { path: 'register', component: RegisterComponent },
  { path: 'email-verification', component: EmailVerificationComponent },
  { path: 'tester-list', component: testergridcomponent },
  { path: 'tester-details', component: testerdetailcomponent },
  { path: 'unauthorised', component: UnauthorisedComponent },
  { path: 'notification-recipient-document-details', canActivate: [AuthGuard], component: NotificationRecipientDocumentComponent },
  { path: 'notification-recipient-details', canActivate: [AuthGuard], component: NotificationRecipientDetailsComponent },
  { path: 'admin', canActivate: [AuthGuard], loadChildren: () => import('./Modules/admin/admin.module').then(m => m.AdminModule) },
  { path: 'fatiguemetricresults-grid', component: fatiguemetricresultsgridcomponent },
  { path: 'FatigueMetricResults-detail', component: fatiguemetricresultsdetailcomponent },
  { path: 'sitemap', canActivate: [AuthGuard], component: sitemapgridcomponent },
  { path: 'sitemap-details',  canActivate: [AuthGuard],component: sitemapdetailcomponent },
  { path: 'ecmtrigger', canActivate: [AuthGuard], component: ecmtriggergridcomponent },
  { path: 'ecmtrigger-details', canActivate: [AuthGuard], component: ecmtriggerdetailcomponent },
  { path: 'object', canActivate: [AuthGuard], component: objectgridcomponent },
  { path: 'object-details', canActivate: [AuthGuard], component: objectdetailcomponent },
  { path: 'objectattributes', canActivate: [AuthGuard], component: objectattributesgridcomponent },
  { path: 'objectattributes-details', canActivate: [AuthGuard], component: objectattributesdetailcomponent },
  { path: 'relationship', canActivate: [AuthGuard], component: relationshipgridcomponent },
  { path: 'relationship-details', canActivate: [AuthGuard], component: relationshipdetailcomponent },
  { path: 'objectdataset', canActivate: [AuthGuard], component: objectdatasetgridcomponent },
  { path: 'objectdataset-details', canActivate: [AuthGuard], component: objectdatasetdetailcomponent },
  { path: 'datatransform', canActivate: [AuthGuard], component: datatransformgridcomponent },
  { path: 'datatransform-details', canActivate: [AuthGuard], component: datatransformdetailcomponent },
  { path: 't4definition', canActivate: [AuthGuard], component: objectt4gridcomponent },
  { path: 't4definition-details', canActivate: [AuthGuard], component: objectt4detailcomponent },
  { path: 'objectrecord', canActivate: [AuthGuard], component: objectrecordgridcomponent },
  { path: 'objectrecord-details', canActivate: [AuthGuard], component: objectrecorddetailcomponent },
  { path: 'uilayout', canActivate: [AuthGuard], component: uilayoutgridcomponent },
  { path: 'uilayout-details', canActivate: [AuthGuard], component: uilayoutdetailcomponent },
  { path: 'uilayoutfield', canActivate: [AuthGuard], component: uilayoutfieldgridcomponent },
  { path: 'uilayoutfield-details', canActivate: [AuthGuard], component: uilayoutfielddetailcomponent },
  { path: 'objectcommand', canActivate: [AuthGuard], component: objectcommandgridcomponent },
  { path: 'objectcommand-details', canActivate: [AuthGuard], component: objectcommanddetailcomponent },
  {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }