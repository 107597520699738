
<!-- ***************************This code has been generated using LOBAS Templates 4.0.0.0  -->

<app-breadcrumb [ComponentObjectID]="componentObjectID"></app-breadcrumb>

<!-- <div class="row webpartrow">
    <div class="col-lg-12 webpartcol screen-100">
        <div class="m-b-30">
            
        </div>
    </div>
</div> -->
<div class="row webpartrow">
    <div class="col-lg-6 webpartcol screen-50">
       <div class="m-b-30">
            <app-grid #assettestDetail (gridCustomActions)="gridCustomActions($event)" [IsUnauthorizedPage]="false"
            instanceID="assettestDetail"></app-grid>
        </div>
    </div>
    <div class="col-lg-6 webpartcol screen-50">
        <!-- <div class="m-b-30">
            <app-map #closecallDetailMap mapDivId="AssetTest_Map" name="Location" mode="set"
            relationshipID="2218AA0B-1B06-4FA0-9850-03C4FFE901C7"></app-map>
        </div> -->
    </div>
</div>