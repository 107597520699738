import { Component, ElementRef, ViewChildren, Input, OnInit, NgZone, ViewChild, QueryList } from '@angular/core';
import { GridHelperService } from 'src/app/services/gridHelper/grid-helper.service';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { EnumHelper } from 'src/app/util/enum-helper';
import { OfflineDataHelperService } from 'src/app/services/offlineDataHelper/offline-data-helper.service';
import { TranslateService } from '@ngx-translate/core';
import { Guid } from 'guid-typescript';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { EncryptionService } from 'src/app/encryption.service';
import { formatDate } from '@angular/common';
import { UserIconImageService } from 'src/app/services/userIconImage/user-icon-image.service';
import { SignalRService } from 'src/app/services/signalr-service/signalr-service.service';
import { NoteModel } from 'src/app/models/NoteModel';
import { ChatComponentProperties } from 'src/app/models/GridComponentProperties';
import { DxTextAreaComponent } from 'devextreme-angular';
import { DateTimeHelperService } from 'src/app/services/datetimehelper/date-time-helper.service';
import { GridComponent } from '../grid/grid.component';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent extends PageBaseService implements OnInit {
  isVisible: Boolean = false;
  isVisibleNotes: Boolean = false;
  componentID: string = "Note";
  public user: string = '';

  public messages: string[] = [];
  @Input() recordID: string;
  @Input() instanceID: string;
  @Input() loggedinUserID: string=Guid.EMPTY;
  @Input() newID:string
  public relationshipID: string;
  private onStableSubscription: any;
  receivedMessage: string;
  @ViewChild("txtMsgBox", { static: false }) txtMsgBox: DxTextAreaComponent
  listNoteModel: Array<NoteModel> = [];
  ChatProperties: ChatComponentProperties;
  @ViewChild("chatContainer", { static: false }) chatContainer: ElementRef
  @ViewChild("notesGrid", { static: true }) notesGrid!: GridComponent

  constructor(private dateTimeHelperService: DateTimeHelperService, private ngZone: NgZone, private signalRService: SignalRService, private userIconImageService: UserIconImageService, private localStorageService: LocalStorageService, public gridHelperService: GridHelperService, private _router: Router, private _enc: EncryptionService,
    private httpClient: HttpClient, private alertService: AlertService, private route: ActivatedRoute, private offlineDataHelper: OfflineDataHelperService,
    public translateService: TranslateService, private eventQueue: LobasEventQueueService, ) {
    super(_router, _enc);

  }
   ngOnInit() {
    if(!this.recordID && this.newID)
    {
      this.recordID= this.newID
    }
    this.ChatProperties = <ChatComponentProperties>this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.chatComponentProperties, [this.instanceID]) ?? new ChatComponentProperties();
    this.getChatComponentProperties(false);
    this.signalRService.hubConnection.on('ReceiveMessage', (note: NoteModel, parentID: string) => {
      this.ngZone.run(() => {
        if (parentID === this.recordID && note.RecordID != Guid.EMPTY) {
          var noteModel = this.ConverttoNoteModel(note);
          if (this.ChatProperties.Mode.toLowerCase()=="chat") {
            this.listNoteModel.push(noteModel);
            this.scrollToBottom();
          }
          else {
              this.listNoteModel.unshift(noteModel);
              this.notesGrid.dataSource= {
                store: {
                    type: 'array',
                    data: this.listNoteModel
                }
              };
              this.notesGrid.scrollUp();
          }
        }
      });
    });
  }

  async setupDataSource() {
    const fetchData = async () => {
      let params = new HttpParams();
      let filter = null;
      if (this.ChatProperties.Filter) {
        filter = this.ChatProperties.Filter;
        for (let i = 0; i < filter.length; i++) {
          for (let j = 0; j < filter[i].length; j++) {
            if (filter[i][j] === "RecordID") {
              filter[i][j] = this.recordID;
            }
          }
        }
        params = params.set('filter', JSON.stringify(filter));
      }
      const sortOption = this.ChatProperties.Mode.toLowerCase()==="chat"
        ? '[{"Selector": "RecordCreatedDate","desc": false}]'
        : '[{"Selector": "RecordCreatedDate","desc": true}]';

      params = params.set('sort', sortOption);

      const baseAPIURL = environment.apiUrl;
      const response = await lastValueFrom(this.httpClient.get<any>(
        baseAPIURL + 'api/note/getNoteRelationshipView',
        { params }
      ));
      return {
        data: response.data,
        totalCount: response.totalCount,
        summary: response.summary,
        groupCount: response.groupCount,
      }
    };
    const response = await fetchData();
    this.listNoteModel=[];
    this.listNoteModel = response.data;
    if (this.ChatProperties.Mode.toLowerCase()!=="chat") { 
      this.notesGrid.dataSource= {
        store: {
            type: 'array',
            data: this.listNoteModel
        }
      };
    }
   };

  scrollToBottom(): void {
    if (this.ChatProperties.Mode.toLowerCase()==="chat") {
      this.onStableSubscription = this.ngZone.onStable.subscribe(() => {
        this.chatContainer.nativeElement.scrollTo(0, this.chatContainer.nativeElement.scrollHeight);
        this.onStableSubscription.unsubscribe();
      });
    }
  }

  formatData(data: any): String {
    return this.dateTimeHelperService.FormatData(data, 'datetime');
  }

  getNoteModel(): NoteModel {
    var note = new NoteModel();
    note.RecordID = Guid.create().toString();
    note.NoteType_EnumID = this.ChatProperties.NoteType_EnumID;
    note.Note = this.txtMsgBox.value;
    return note;
  }
  public sendMessage(): void {
    if (this.txtMsgBox.value) {
      this.signalRService.sendMessage(this.getNoteModel(), this.recordID, this.relationshipID);
      this.txtMsgBox.value = null;
    }
  }
  expandCollaspeComponent() {
    this.isVisible = !this.isVisible;
  }
  expandCollaspeChatComponent(){
    this.isVisibleNotes = !this.isVisibleNotes;
  }
  ngOnDestroy(): void {
    if (this.onStableSubscription) {
      this.onStableSubscription.unsubscribe();
    }
  }
  getChatComponentProperties(IsUnauthorized: boolean) {

    if(this.offlineDataHelper.isOnline){
      var ComponentUrl = "";
    if (IsUnauthorized) {
      ComponentUrl = "api/ComponentPropertiesUnauthorized/GetComponentPropertiesData?componentID=";
    }
    else {
      ComponentUrl = "api/ComponentProperties/GetComponentPropertiesData?componentID=";
    }
    if (this.ChatProperties && this.ChatProperties.UILayoutID != Guid.EMPTY && this.ChatProperties.RelationshipID != Guid.EMPTY) {
      this.relationshipID = this.ChatProperties.RelationshipID;
      this.setupDataSource().then(reponse => {
        this.scrollToBottom();
      });


    } else {
      this.httpClient.get(environment.apiUrl + ComponentUrl + this.componentID + "&instanceID=" + this.instanceID)
        .subscribe((response: any) => {
          this.setChatComponentProperties(response.Properties);
          this.relationshipID = this.ChatProperties.RelationshipID;
          this.setupDataSource().then(reponse => {
            this.scrollToBottom();
          });
        });
    }
    }
  }

  setChatComponentProperties(componentProperties) {
    this.ChatProperties.Filter = null;
    var gridProperties = JSON.parse(componentProperties);
    gridProperties.GridProperties.forEach(componentProperty => {
      switch (componentProperty.key.toLowerCase()) {
        case "objectname":
          this.ChatProperties.ObjectName = componentProperty.value;
          break;
        case "datasource":
          this.ChatProperties.Datasource = componentProperty.value;
          break;
        case "objectid":
          this.ChatProperties.ObjectID = componentProperty.value;
          break;
        case "getrecordsapiendpoint":
          this.ChatProperties.GetRecordsApiEndPoint = componentProperty.value;
          break;
        case "title":
          this.ChatProperties.Title = componentProperty.value;
          break;
        case "relationshipid":
          this.ChatProperties.RelationshipID = componentProperty.value;
          break;
        case "orderby":
          this.ChatProperties.OrderBy = componentProperty.value;
          break;
        case "filter":
          this.ChatProperties.Filter = componentProperty.value;
          break;
        case "componentminheight":
          this.ChatProperties.ComponentMinHeight = componentProperty.value;
          break;
        case "componentmaxheight":
          this.ChatProperties.ComponentMaxHeight = componentProperty.value;
          break;
        case "allowedroles":
          this.ChatProperties.AllowedRoles = componentProperty.value.split(',').map(x => x?.toLowerCase());
          break;
        case "mode":
             this.ChatProperties.Mode = componentProperty.value;
          break;
        case "notetype_enumid":
          this.ChatProperties.NoteType_EnumID = componentProperty.value;
          break;
      }
    });
    this.localStorageService.SetLocal(EnumHelper.LocalStorageKey.gridComponentProperties, JSON.stringify(this.ChatProperties), [this.instanceID])


  }


  getInitials(userName: string): String {
    return this.userIconImageService.getInitialsString(userName);
  }

  isUserImageAvailable(userID): boolean {
    return this.userIconImageService.isUserImageAvailable(userID);
  }

  getUserImage(userID: string, userName: string): string {
    if (this.userIconImageService.isUserImageAvailable(userID)) {
      return this.userIconImageService.getProfileIconImageURL(userID);
    }
    else {
      return null;
    }
  }


  ConverttoNoteModel(note: any) {
    return {
      RecordID: note.recordID ? note.recordID : note.RecordID,
      NoteType_EnumID: note.noteType_EnumID ? note.noteType_EnumID : note.NoteType_EnumID,
      Note: note.note ? note.note : note.Note,
      ExtendedAttributes: note.extendedAttributes ? note.extendedAttributes : note.ExtendedAttributes,
      Group: note.group ? note.group : note.Group,
      RecordCreatedDate: note.recordCreatedDate ? note.recordCreatedDate : note.RecordCreatedDate,
      RecordUpdatedDate: note.recordUpdatedDate ? note.recordUpdatedDate : note.RecordUpdatedDate,
      RecordUpdated_UserID: note.recordUpdated_UserID ? note.recordUpdated_UserID : note.RecordUpdated_UserID,
      RecordCreated_UserID: note.recordCreated_UserID ? note.recordCreated_UserID : note.RecordCreated_UserID,
      CreatedUserIcon: note.createdUserIcon ? note.createdUserIcon : note.CreatedUserIcon,
      UpdatedUserIcon: note.updatedUserIcon ? note.updatedUserIcon : note.UpdatedUserIcon,
      CreatedUsersName: note.createdUsersName ? note.createdUsersName : note.CreatedUsersName,
      UpdatedUsersName: note.updatedUsersName ? note.updatedUsersName : note.UpdatedUsersName,
      RecordTenancyID: note.recordTenancyID ? note.recordTenancyID : note.RecordTenancyID,
      RecordLookupValue: note.recordLookupValue ? note.recordLookupValue : note.RecordLookupValue,
      RecordDescription: note.recordDescription ? note.recordDescription : note.RecordDescription,
      RecordDataObjectStatus_EnumID: note.recordDataObjectStatus_EnumID ? note.recordDataObjectStatus_EnumID : note.RecordDataObjectStatus_EnumID,
      RecordStartDate: note.recordStartDate ? note.recordStartDate : note.RecordStartDate,
      RecordEndDate: note.recordEndDate ? note.recordEndDate : note.RecordEndDate
    };
  }
}




