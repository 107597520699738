<app-breadcrumb [ComponentObjectID]="ComponentObjectID"></app-breadcrumb>
<div class="row webpartrow">
    <div class="col-lg-12 webpartcol screen-100">
        <div class="m-b-30">
            <app-grid [IsUnauthorizedPage]="false" instanceID="CloseCallDetails_JobDetails" *ngIf="parameterSet" [recordID]="this.jobID"></app-grid>
            <app-send-close-call-report-popup #closeCallReport></app-send-close-call-report-popup>
        </div>
    </div>
</div>
<div class="row webpartrow">
    <div class="col-lg-6 webpartcol screen-50">
       <div class="m-b-30">
             <app-grid #grdCloseCallDetails  (gridCustomActions)="gridCustomActions($event)" *ngIf="parameterSet" [parentRecordId]="this.jobID" 
              [suscribeToRibbonCommandTransitionAction]="true" [executeUpdateTransitionStatusFromRibbonCtrl]="true" [isCustomOnInit]="this.isCustomOnInit" (CustomOnInitNewRow)="CustomOnInitNewRow($event)"  [IsUnauthorizedPage]="false"
             instanceID="closecalldetails"></app-grid>
        </div>
        <div class="m-b-30">
            <app-chat *ngIf="parameterSet" [loggedinUserID]="loggedinUserID" [recordID]="getRecordID()" [newID]="this.newID" instanceID="closeCall_Notes"></app-chat>
        </div>
    </div>
    <div class="col-lg-6 webpartcol screen-50">
        <div class="m-b-30">
            <app-map [hidden]="!parameterSet" #closecallDetailMap mapDivId="CloseCall_Map" name="Location" mode="set"
            relationshipID="1E0F556B-7106-4B05-B113-46715A57F753"></app-map>
        </div>
        <div class="m-b-30">
            <app-grid [hidden]="!parameterSet" #grdCloseCallDocument [IsUnauthorizedPage]="false" (gridCustomActions)="DocumentgridCustomActions($event)" instanceID="closeCall_Documentgrid"></app-grid>
            <app-document-assests-viewer-popup instanceID="CloseCallDetails_DocumentAssetViewerPopup" #closecallDocViewPopup></app-document-assests-viewer-popup>
            <app-document-upload-popup instanceID="CloseCallDetails_DocumentUploadPopup" instanceID="closeCallDetails_DocumentUploadPopUp" #closecallDocUploadPopup></app-document-upload-popup>
        </div>
        <div class="m-b-30">
         <app-ribbon-control *ngIf="parameterSet" [IsEditMode]="this.IsEditMode" instanceID="CloseCallRibbonControl" [parentRecordID]="this.recordID"
            [relationshipRecordID]="this.relationshipRecordID"
            [newID]="this.newID"></app-ribbon-control> 
        </div>
    </div>
</div> 
