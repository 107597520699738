<app-breadcrumb [ComponentObjectID]="componentObjectID"></app-breadcrumb>

<dx-tab-panel [(selectedIndex)]="tab1Index" (onTitleClick)="onTab1ItemClick($event)"  [swipeEnabled]="false" [animationEnabled]="false" [elementAttr]="{ class: 'custom_tabpanel' }">
    <dxi-item [visible]="isActiveTabVisible" #tabActive title="{{'tab_MyAssetTest'|translate}}">
        <app-assettest-by-login-grid (customActions)="openAssetTestRecordSearchPopup()" componentInstanceID = "assettestbylogingrid"></app-assettest-by-login-grid>
    </dxi-item>
    <dxi-item [visible]="isInactiveTabVisible" #tabInactive title="{{'tab_AllAssetTest'|translate}}">
        <app-assettest-related-items-grid (customActions)="openAssetTestRecordSearchPopup()" componentInstanceID = "assettestbyusergrid"> </app-assettest-related-items-grid>
    </dxi-item>
     <dxi-item [visible]="isInactiveTabVisible" #tabInactive title="{{'tab_DeletedAssetTest'|translate}}">
        <app-assettest-related-items-grid (customActions)="openAssetTestRecordSearchPopup()" componentInstanceID = "assettestDeletedRecords"> </app-assettest-related-items-grid>
    </dxi-item>
</dx-tab-panel>

<app-record-search instanceID="assetRecordSearch" ddlGridInstanceID="assetRecordSearchDDLGrid"
#assetRecordSearchPopup></app-record-search>
