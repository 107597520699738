
//***************************This code has been generated using LOBAS Templates 4.0.23.0  


import { Component, ViewChild } from '@angular/core';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { GridComponent } from 'src/app/Components/common/grid/grid.component';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/encryption.service';
import { ObjectService } from 'src/app/services/object/object.service';
import { Csv, Workbook } from 'exceljs';
import * as saveAs from 'file-saver';
@Component({
  selector: 'app-object-detail',
  templateUrl: './object-detail.component.html',
  styleUrls: ['./object-detail.component.scss']
})
export class objectdetailcomponent  extends PageBaseService {
  componentObjectID: string;
  tab1Index:number=0;
  isDeletedJobTabVisible: boolean =false;
  recordID=''

  constructor(private localStorageService: LocalStorageService,private eventQueue: LobasEventQueueService,private _router: Router, private _enc: EncryptionService, private objectService:ObjectService) {
    super(_router, _enc);
    this.componentObjectID = "63e9b245-42ae-402e-8b94-edede5bb9f21";
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }
  onTab1ItemClick(ev: any) {    
    this.SetTabIndex("tab1Index",ev?.itemIndex,this.localStorageService);
  }
  async ngOnInit()
  {
    var tabNumber = await this.GetKeyValueFromQueryString("TabControl");
    if (tabNumber) {
      this.tab1Index = Number(tabNumber) - 1;
      this.SetTabIndex("tab1Index", this.tab1Index, this.localStorageService);
    }
    else {
      this.tab1Index = this.GetTabIndex("tab1Index", this.localStorageService);
      this.SetTabIndex("tab1Index", this.tab1Index, this.localStorageService);
    }  
    this.getDataFromQueryString()
  }
  gridCustomActions(data: any) {
    if (data != undefined && data != null) {
      switch (data.CommandObject.Command.toLowerCase()) {
        case "addCustomCasesHere":
              //Logical code.
          break;
        case "export":
        case "exportwithdata":
          const workbook = new Workbook();
          const worksheet = workbook.addWorksheet('DataGrid');
          var objectName=''
          var schema=''  
          var exportData=false
          data.CommandObject.Command.toLowerCase()==='export' ? exportData= false : exportData=true;
          this.objectService.getObjectDataFromRecordID(this.recordID).subscribe(res=>{
            objectName= res['ObjectName'],
            schema = res['Schema']
            this.objectService.getExportedData(objectName, schema, this.recordID,exportData).subscribe((res:[])=>
            { 
              var xmldata ="";
              for (const key in res)
                {
                  console.log(res[key]);
                  if(res[key] != null && res[key] != undefined)
                  xmldata += res[key];
                }
              workbook.csv.writeBuffer().then(function (data1) {
                saveAs(new Blob([xmldata], { type: "application/xml" }), "C:\DataGrid.xml");
              })
            }
            )
          });
        break;
      }
    }
  }

  getDataFromQueryString(){
    this.GetKeyValueFromQueryString('recordID').then((recordID => {
      this.recordID = recordID;
  }))
  }
}