import { Component } from '@angular/core';

@Component({
  selector: 'app-offline-warrning',
  templateUrl: './offline-warrning.component.html',
  styleUrls: ['./offline-warrning.component.scss']
})
export class OfflineWarrningComponent {

}
