
//***************************This code has been generated using LOBAS Templates 4.0.0.0  

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { ComponentInfo } from 'src/app/models/ComponentInfo';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';

@Component({
  selector: 'app-assettest-by-login-grid',
  templateUrl: './assettest-by-login-grid.component.html',
  styleUrls: ['./assettest-by-login-grid.component.scss']
})
export class assettestbylogingridcomponent implements OnInit {
  componentObjectID: string;
@Input() componentInstanceID:string;
@Output() customActions= new EventEmitter<boolean>(); 
  constructor(private eventQueue: LobasEventQueueService) {
    //super(_router, _enc);
    this.componentObjectID = 'c163a1b6-2799-4cd9-b09b-0584d01bc993';
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }

  ngOnInit(): void {
    if(!this.componentInstanceID || this.componentInstanceID.length <= 0){
      this.componentInstanceID = 'assettestbyloginGrid'; 
    }
  }

  gridCustomActions(data: any) {
    if (data != undefined && data != null) {
      switch (data.CommandObject.Command.toLowerCase()) {
        case "insert":
          this.customActions.emit(true);
          break;
      }
    }
  }
}