import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/encryption.service';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';

@Component({
  selector: 'app-questionnaire-details',
  templateUrl: './questionnaire-details.component.html',
  styleUrls: ['./questionnaire-details.component.scss']
})
export class QuestionnaireDetailsComponent extends PageBaseService {
  
  isVisible: Boolean = false;
  isVisible2: Boolean = false;
  NotificationId :string;
  loggedinUserID: string = '';
  constructor(private eventQueue:LobasEventQueueService,private auth: AuthService, private _router: Router, private _enc: EncryptionService,private notificationService: NotificationService,){
    super(_router, _enc);
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
    this.loggedinUserID = this.auth.getGuidUserID() ?? '';
  }
  expandCollaspeComponent() {
    this.isVisible = !this.isVisible;
  }
  expandCollaspeComponent2() {
    this.isVisible2 = !this.isVisible2;
  }

  getAllQueryStrings() {
    this.GetKeyValueFromQueryString('NRID').then((notificationId => {
      this.NotificationId = notificationId;
      if(this.NotificationId != "" && this.NotificationId != '' && this.NotificationId != null&& this.NotificationId != undefined ){
        this.notificationService.notificationUserAuthorised(this.NotificationId,this.loggedinUserID);
      }
    }));
  }

  
}
