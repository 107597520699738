import { AuthService } from '../services/auth/auth.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { PageBaseService } from '../services/pageBase/page-base.service';
import { EncryptionService } from '../encryption.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends PageBaseService implements CanActivate {
  constructor(private auth: AuthService,private _router: Router, private _enc: EncryptionService) { 
    super(_router,_enc);
  }
  

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (!this.auth.isLoggedIn()) {   
      this.RedirectoLoginWithReturnUrl();
      return false;
    }
    else
    {
      return true;
    }
  }

  

}