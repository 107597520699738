
//***************************This code has been generated using LOBAS Templates 4.0.23.0  

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/encryption.service';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { ComponentInfo } from 'src/app/models/ComponentInfo';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';

@Component({
  selector: 'app-workitem-grid',
  templateUrl: './workitem-grid.component.html',
  styleUrls: ['./workitem-grid.component.scss']
})
export class workitemgridcomponent extends PageBaseService{
  componentObjectID: string; 
  constructor(private localStorageService: LocalStorageService, private auth: AuthService, private eventQueue: LobasEventQueueService, private _router: Router, private _enc: EncryptionService) {
    super(_router, _enc);
    this.componentObjectID = '9579d64b-3e97-4ac9-a255-8357bd44d157';
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }
  ngOnInit() {
    this.tab1Index = this.GetTabIndex("tab1Index",this.localStorageService);
  }
  isactiveTabVisible: boolean =true;
  isinactiveTabVisible: boolean=true;
  isdeletedTabVisible: boolean =true;
  tab1Index:number=0;
  hideActiveTabVisible(isGridHidden) {
    this.isactiveTabVisible = !isGridHidden;
  }
  
  hideInActiveTabVisible(isGridHidden) {
    this.isinactiveTabVisible = !isGridHidden;
  } 
  
  hideDeletedTabVisible(isGridHidden) {
    this.isdeletedTabVisible = !isGridHidden;
  }
  onTab1ItemClick(ev: any) 
  {    
    this.SetTabIndex("tab1Index",ev?.itemIndex,this.localStorageService);
  }

  gridCustomActions(data: any) {
    if (data != undefined && data != null) {
      switch (data.CommandObject.Command.toLowerCase()) {
        case "CustomActionHere":
          //Execution code.
          break;
      }
    }
  }
}