import { Injectable } from "@angular/core";
import { Observable, Subject, filter } from "rxjs";
import { AppEventType } from "src/app/enums/appEventType";
import { AppEvent } from "src/app/models/AppEvent";



@Injectable()
export class LobasEventQueueService {

  private eventBrocker = new Subject<AppEvent<any>>();

  on(eventType: AppEventType): Observable<AppEvent<any>> {
    return this.eventBrocker.pipe(filter(event => event.type === eventType));
  }

  dispatch<T>(event: AppEvent<T>): void {
    this.eventBrocker.next(event);
  }

}