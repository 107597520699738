import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CommonHelper } from 'src/app/util/common-helper';
import { EnumHelper } from 'src/app/util/enum-helper';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { PageBaseService } from '../pageBase/page-base.service';
import { EncryptionService } from 'src/app/encryption.service';

@Injectable({
  providedIn: 'root'
})

export class LocalStorageService extends PageBaseService {

  constructor(private cookieService:CookieService, private _router:Router,private _encr :EncryptionService) { 

    super(_router,_encr);
  }


  SetLocal(localStorageKey: EnumHelper.LocalStorageKey,value: string, storageKeyhelper:string[] = []) {
    localStorage.setItem(this.GetKey(localStorageKey, storageKeyhelper),value);
  }

  GetLocal(localStorageKey: EnumHelper.LocalStorageKey,  storageKeyhelper:string[] = []) {

    let json = localStorage.getItem(this.GetKey(localStorageKey, storageKeyhelper)) || '';
    if (!CommonHelper.isNullOrEmpty(json))
    {
      return json;
    }
    else 
    {
        return null;
    }
    }


  SetLocalStorage(localStorageKey: EnumHelper.LocalStorageKey,value: any, storageKeyhelper:string[] = []) {
    localStorage.setItem(this.GetKey(localStorageKey, storageKeyhelper), JSON.stringify(value));
  }

  GetLocalStorage(localStorageKey: EnumHelper.LocalStorageKey,  storageKeyhelper:string[] = []) {
    let json = localStorage.getItem(this.GetKey(localStorageKey, storageKeyhelper)) || '';
    if (!CommonHelper.isNullOrEmpty(json) && json!="undefined") {
      return JSON.parse(json);
    }
    else {
      return null;
    }
  }

    CleanLocalStorageByPrefix(localStorageKey: EnumHelper.LocalStorageKey) {
      for (var key in localStorage){
        if(key.startsWith(localStorageKey.toString())) {
          localStorage.removeItem(key);
        }
      }
    }

    ClearLocalStorageWithKey(localStorageKey: EnumHelper.LocalStorageKey,  storageKeyhelper:string[] = []) {
      localStorage.removeItem(this.GetKey(localStorageKey,storageKeyhelper));
    }

    async clearAllLocalStorageAndCookies(IsLogout=null, sessionExpired=false) {
      localStorage.clear();
      document.cookie.split(";").forEach(x=> { 
        var key = x.split("=")[0].replace(" ", "");
        if(key.toLowerCase() != 'deviceid' && key.toLowerCase() != 'mobiledeviceid'){
            this.cookieService.delete(x.split("=")[0].replace(" ", ""),'/');
        }
      });
      if(sessionExpired) {
        this.SetLocal(EnumHelper.LocalStorageKey.sessionExpired, "1");
      }
      if(window.location.href.indexOf("login")<=0) {
        this.RedirectoLoginWithReturnUrl();
      }
    }

    

    GetKey(localStorageKey: EnumHelper.LocalStorageKey, storageKeyhelper:string[]):string{
      var cacheKey = EnumHelper.LocalStorageKey[parseInt(localStorageKey.toString())].toLowerCase();
      if(storageKeyhelper.length > 0){
        storageKeyhelper.forEach(element=>{
          cacheKey = cacheKey + "_" + element?.replaceAll(' ','');
        })
      }
      return cacheKey;
    }
  }

