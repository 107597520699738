import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { HttpHelperService } from '../httpHelper/http-helper.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { catchError, lastValueFrom } from 'rxjs';
import { AlertService } from '../alertService/alert.service';
import { CalculatedValue } from 'survey-core';
import { AddressModel, GetContactAddressByAddressType, MapLocationImageModel } from 'src/app/models/Address';
import { LobasEventQueueService } from '../lobasEventQueueService/lobas.event.queueservice';
import { AppEvent } from 'src/app/models/AppEvent';
import { AppEventType } from 'src/app/enums/appEventType';

declare function calculateDistance(startLocationID: any, destinationLocationID: any, returnLocationId: any, unit: any, GoogleMapApikey: any);


@Injectable({
  providedIn: 'root'
})
export class MapService {
   SaveImage(mapLocationImageModel :MapLocationImageModel, eventQueue: LobasEventQueueService) {     
    this.httpClient.post<any>(environment.apiUrl + 'api/Address/SaveMapLocationImage', JSON.stringify(mapLocationImageModel)
    )
      .subscribe((response) => {
        eventQueue.dispatch(new AppEvent(AppEventType.ActionUpdateSuccess, true));
        catchError((err: any) => {
          this.alertService.showAlertPopup('Error', err.error.Message, 'fa-solid fa-xmark', 'OK'); throw err
        })
      });
  }



  
  constructor(private httpClient: HttpClient, private httpHelper: HttpHelperService, private authService: AuthService, private alertService: AlertService) {

  }

  async calculateDistanceBetween(data: any, orginFieldName: string, destinationFieldName: string, returnLocationFieldName: string, outputFieldName: string) {
    if (data[orginFieldName] == "" && data[destinationFieldName] == "") {
      this.alertService.showAlertPopup('Warning', 'Please enter suitable location details for Start and Destination fields.', 'fa-solid fa-triangle-exclamation', 'Ok');
    } 
    else {
      var orgin = data[orginFieldName].length === 0 ? null : data[orginFieldName];
      var destination = data[destinationFieldName].length === 0 ? null : data[destinationFieldName];
      var returnLocation = data[returnLocationFieldName].length === 0 ? null : data[returnLocationFieldName];

      var result = lastValueFrom(
        this.httpClient.get(environment.apiUrl + 'api/GoogleHelper/getDistanceTravelled/' + orgin + '/' + destination + '/' + returnLocation)
      ).then(((response: any) => {
        data[outputFieldName] = response.TotalDistance;
      }));
    }
  }
  fetchCompanyAddressListByCustomerID(CustomerID:string): PromiseLike<AddressModel[]> {
    return new Promise((resolve, reject) => {
      this.httpClient.get<AddressModel[]>(environment.apiUrl + "api/Address/GetCompanySiteAddressesByCustomerID/"+CustomerID)
        .subscribe((response) => {
          resolve(<AddressModel[]>response);
        });
    });
  } 
  fetchCompanyAddressListByJobID(CustomerID:string): PromiseLike<AddressModel[]> {
    return new Promise((resolve, reject) => {
      this.httpClient.get<AddressModel[]>(environment.apiUrl + "api/Address/GetCompanySiteAddressesByJobID/"+CustomerID)
        .subscribe((response) => {
          resolve(<AddressModel[]>response);
        });
    });
  } 
}
