import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ComponentInfo } from 'src/app/models/ComponentInfo';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/encryption.service';
import { Guid } from 'guid-typescript';
import { BigButtonComponent } from '../common/big-button/big-button.component';
import { CookieService } from 'ngx-cookie-service';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { AuthService } from 'src/app/services/auth/auth.service';
import { QuestionnaireResponseViewerComponent } from '../questionnaire-response-viewer/questionnaire-response-viewer.component';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { RecordRelationshipBindingComponentProperties } from 'src/app/models/RecordRelationshipBindingComponentProperties';
import { RecordRelationshipBindingToolComponent } from '../common/record-relationship-binding-tool/record-relationship-binding-tool.component';
import { GridComponent } from '../common/grid/grid.component';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
@Component({
  selector: 'app-people-details',
  templateUrl: './people-details.component.html',
  styleUrls: ['./people-details.component.scss']
})
export class PeopleDetailsComponent extends PageBaseService implements OnInit {
  ComponentObjectID: string;
  gridInfo1 = new ComponentInfo();
  recordID: string = Guid.EMPTY;
  relationshipRecordID: string = Guid.EMPTY;
  newID: string = Guid.EMPTY;
  bigbuttonRedirectUrl: string;
  showMapControl: boolean = true;
  parameterSet: Boolean = false;
  loggedinUserID: string = Guid.EMPTY;
  isSetUrl: boolean = false;
  isInitComplete: boolean=false;
  ContactDetails_QuestionnaireResponse = new ComponentInfo();
  ContactDetails_UserNotifiactionList = new ComponentInfo();
  ContactDetails_DistributedDocuments = new ComponentInfo();

  tab1Index:number=0;
  @ViewChild("questionnaireResponseViewer", { static: false }) questionnaireResponseViewer: QuestionnaireResponseViewerComponent
  @ViewChild("recordrelationshipbindingtool", { static: false }) recordrelationshipcomponent: RecordRelationshipBindingToolComponent
  @ViewChild("contactDetails_Trade", { static: false }) grdcontactDetails_Trade: GridComponent
  

  constructor(private localStorageService: LocalStorageService,private alertService: AlertService, private translateService: TranslateService, private auth: AuthService, private eventQueue: LobasEventQueueService, private location: Location, private _router: Router, private _enc: EncryptionService, private cookieService: CookieService) {
    super(_router, _enc);
    this.ComponentObjectID = '39245297-F727-40D4-9701-F284A0C7219A';
    this.contactDetailsPageURL().then((response) => {
      this.bigbuttonRedirectUrl = response;
      this.isSetUrl = true;
    });
    this.loggedinUserID = this.auth.getGuidUserID() ?? '';
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));

  }
  async contactDetailsPageURL() {
    var recordID = await this.GetKeyValueFromQueryString('RecordID');
    if (recordID === null || recordID === '') {
      recordID = this.cookieService.get("UserID")
    }
    return "/Lobas.Web/Pages/Contact/ContactDetails.aspx?RecordID=" + recordID;
  }
  async ngOnInit() {
    this.tab1Index = this.GetTabIndex("tab1Index",this.localStorageService);
    this.getAllQueryStrings();
    this.isInitComplete = true;

    if (this.tab1Index ==0) {
      this.showMapControl = true;
    }
    else {
      this.showMapControl = false;
    }
  }
  getAllQueryStrings() {
    this.GetKeyValueFromQueryString('recordID').then((recordID => {
      this.recordID = recordID;
      this.GetKeyValueFromQueryString('relationshipRecordID').then((relationshipRecordID => {
        this.relationshipRecordID = relationshipRecordID;
        this.GetKeyValueFromQueryString('newID').then((newID => {
          this.newID = newID;
          this.parameterSet = true;
        }))
      }));
    }));
  }
  RelationshipRecordActions(refreshGrid: boolean) {
    if(refreshGrid) {
       this.grdcontactDetails_Trade.refreshGrid(false);
      }
  }
  goBackToPrevPage() {
    this.location.back();
  }
  public userNotifiactionList_rowClick(ev: any) {
    let data = ev.data;
    let navigateURL = ev.navigateURL;
    var queryStringParameters;
    if (data.NotificationRecipientID) {
      queryStringParameters = 'RecordID=' + data.NotificationRecipientID;
    }
    else {
      queryStringParameters = 'RecordID=' + data.RecordID;
    }
    this.AddQuerystringParameterAndnavigateWithDecryptedUrl(navigateURL, queryStringParameters);
  }

  onTab1ItemClick(ev: any) {

    if (ev?.itemData?.title?.toLowerCase() == "details") {
      this.showMapControl = true;
    }
    else {
      this.showMapControl = false;
    }
    this.SetTabIndex("tab1Index",ev?.itemIndex,this.localStorageService);    
  }
  
  gridCustomActions(data: any) {
    switch (data.CommandObject.Command.toLowerCase()) {
      case "viewquestionnaireresponse":
        this.questionnaireResponseViewer.showPopup(data.SelectedRecords);
        break;
      case "addworkertraderelationship":
          this.recordrelationshipcomponent.openPopup();
          break;
      default:
        this.translateService.get('CustomActionYetToImplemented', { title: data.CommandObject.Name }).subscribe((text: string) => {

          var InfoTitle: string ="";
          var OkButtonText: string ="";
          this.translateService.get('InfoTitle').subscribe((translated: string) => {
            InfoTitle = translated;
          });
          this.translateService.get('OkButtonText').subscribe((translated: string) => {
            OkButtonText = translated;
          });
          this.alertService.showAlertPopup(InfoTitle, text,'fa-solid fa-circle-info', OkButtonText);
        });
        break;
    }

  }
}