import { formatDate } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { ObjectCommandModel } from 'src/app/models/ObjectCommandModel';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { UserIconImageService } from 'src/app/services/userIconImage/user-icon-image.service';
import { GridComponent } from '../common/grid/grid.component';
import { environment } from 'src/environments/environment';
import { EnumHelper } from 'src/app/util/enum-helper';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import dxTabPanel, { ItemClickEvent } from 'devextreme/ui/tab_panel';
import { DxTabsComponent } from 'devextreme-angular';
import { NotificationViewComponent } from '../notification-view/notification-view.component';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/encryption.service';
import { DateTimeHelperService } from 'src/app/services/datetimehelper/date-time-helper.service';


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent extends PageBaseService {

  isVisible: Boolean = false;
  isVisible2: Boolean = false;
  isVisible3: Boolean = false;
  isViewMobile: Boolean = false;
  @ViewChild("grdMyNotifications_Unread", { static: true }) grdMyNotifications_Unread!: GridComponent
  @ViewChild("grdMyNotifications_Outstanding", { static: true }) grdMyNotifications_Outstanding!: GridComponent
  @ViewChild("grdMyNotifications_All", { static: true }) grdMyNotifications_All!: GridComponent
  @ViewChild("tabpanel", { static: true }) tabpanel!: dxTabPanel;
  tab1Index:number=0;

  
  activeGrid: GridComponent = null;

  @ViewChild("detailUnread", { static: true }) detailUnread!: NotificationViewComponent
  @ViewChild("detailOutstanding", { static: true }) detailOutstanding!: NotificationViewComponent
  @ViewChild("detailAll", { static: true }) detailAll!: NotificationViewComponent
  activeDetail: NotificationViewComponent = null;

  @ViewChild('userIconDiv', { static: false }) userIconDiv: Element
  constructor(private dateTimeHelperService: DateTimeHelperService,private _router: Router, private _enc: EncryptionService,private eventQueue: LobasEventQueueService, private userIconImageService: UserIconImageService, private localStorageService: LocalStorageService) {
    super(_router, _enc);
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }
  expandCollaspeComponent() {
    this.isVisible = !this.isVisible;
  }
  expandCollaspeComponent2() {
    this.isVisible2 = !this.isVisible2;
  }
  expandCollaspeComponent3() {
    this.isVisible3 = !this.isVisible3;
  }
  closeDetailComponent() {
    this.isViewMobile = false
  }
  closeDetailComponent2() {
    this.isViewMobile = false
  }
  closeDetailComponent3() {
    this.isViewMobile = false
  }
  NotificationgridCustomActions(data: any) {
    if (data != undefined && data != null) {
      switch (data.CommandObject.Command.toLowerCase()) {

      }
    }
  }
  ShowCurrentCardActionMenu(buttonElement, item) {
    this.activeGrid.ShowCurrentCardActionMenu(buttonElement, item);
  }

  onTab1ItemClick(e: any) {
    this.setActiveTab(e?.itemIndex);
  }

  setActiveTab(tabIndex:number) {
    this.tab1Index = tabIndex;
    if (tabIndex === 0) {
      this.activeGrid = this.grdMyNotifications_Unread;
      this.activeDetail = this.detailUnread;
    }
    else if (tabIndex === 1) {
      this.activeGrid = this.grdMyNotifications_Outstanding;
      this.activeDetail = this.detailOutstanding;
    }
    else if (tabIndex === 2) {
      this.activeGrid = this.grdMyNotifications_All;
      this.activeDetail = this.detailAll;
    }
    this.SetTabIndex("tab1Index", tabIndex, this.localStorageService);
  }

  async ngOnInit(): Promise<void> {
    
    var tabNumber = await this.GetKeyValueFromQueryString("TabControl");
    if (tabNumber) {
      this.tab1Index = Number(tabNumber) - 1;
      this.setActiveTab(this.tab1Index);
    }
    else {
      this.tab1Index = this.GetTabIndex("tab1Index", this.localStorageService);
      this.setActiveTab(this.tab1Index);
    }
  }

  CloseCardBurgerMenu(item) {
    this.activeGrid.CloseCardBurgerMenu(item);
  }

  formatData(data: string, dataType: string): string {
    return this.dateTimeHelperService.FormatData(data, dataType);
  }

  GetEnumValue(EnumerationTypeDescription: string, EnumerationId: string) {
    return this.activeGrid.GetEnumValue(EnumerationTypeDescription, EnumerationId);
  }

  commandAction(command: ObjectCommandModel) {
    this.activeGrid.commandAction(command);
  }

  onCellClick(event, item) {
    event.target.classList.add('notifi_active_row');
    this.isViewMobile = true
    var userID = item.data["From"];
    var userName = item.data["FromUsersName"];
    var recordID = item.data["RecordID"];
    var readStatus = "00000000-0000-0001-4100-000000000005";
    this.activeDetail.bindData(item, this.userIconImageService.getProfileIconImage(userID, userName).outerHTML).then(response => {
      if(response.toLowerCase() != "alreadyread"){
        this.grdMyNotifications_Unread.UpdateGridCellValue(recordID,"NotificationStatus_EnumID", readStatus);
        this.grdMyNotifications_Outstanding.UpdateGridCellValue(recordID,"NotificationStatus_EnumID", readStatus);
        this.grdMyNotifications_All.UpdateGridCellValue(recordID,"NotificationStatus_EnumID", readStatus);
        this.eventQueue.dispatch(new AppEvent(AppEventType.UpdateNotificationCountPostRead, true));
      }
    })
      .catch((ex) => { throw ex.message });
  }
  closeNotification() {
    this.isViewMobile = false
  }

  updateReadStatusInAllGrid(recordID:String) {
    var readEnumerationId = "00000000-0000-0001-4100-000000000005";
    this.grdMyNotifications_Unread.UpdateField(recordID, "NotificationStatus_EnumID",readEnumerationId );
  }

  getUserImage(item:any) {
    var userID = item.data["From"];
    var userName = item.data["FromUsersName"];
    return this.userIconImageService.getProfileIconImage(userID, userName).outerHTML;
  }
}