import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/encryption.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CarouselService, ShiftCarousel, ShiftsCarouselList } from 'src/app/services/carousel/carousel.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-shift-carousel',
  templateUrl: './shift-carousel.component.html',
  styleUrls: ['./shift-carousel.component.scss']
})
export class ShiftCarouselComponent extends PageBaseService {
  swiperElement: HTMLScriptElement;
  shiftCarouselDataset: ShiftCarousel[]=[]
  ShiftsCarouselList: ShiftsCarouselList;
  constructor(private carouselService:CarouselService,private auth: AuthService,private _router: Router, private _enc: EncryptionService) {
    super(_router, _enc);
  }

  async ngOnInit() {
    this.ShiftsCarouselList = await this.carouselService.GetShiftCarousel(this.auth.getGuidToken() ?? '',14,-7);
    
    if (this.ShiftsCarouselList) {
      this.shiftCarouselDataset = this.ShiftsCarouselList.shiftsCarouselModel;      
      localStorage.setItem("SwiperinitialSlide", JSON.stringify(this.ShiftsCarouselList.nearestSlideIndex));
      this.swiperElement = document.createElement("script");
      this.swiperElement.src = "../../assets/js/swiper.js?v="+environment.version;
      document.body.appendChild(this.swiperElement);
    }
    
  }

  cardClick(data:ShiftCarousel){    
    this.navigateTo('job-details',"RecordID="+data.Job_RecordID + '&RelationshipRecordID='+data.ShiftAppointmentResourceRelationship_RecordID);
  }


  DateFormat(data: Date ): String {
    var locale = localStorage.getItem('UserLanguage');
    let language: string | null = "en-GB";
    language = localStorage.getItem("UserLanguage");
    if (language == null) {
      language = "en-GB";
    }    
    var formatdatedata = formatDate(data, 'short', locale);
    var WeekOfDay ='';
    if(data)
    {      
      var date = new  Date (data.toString());
      WeekOfDay = date.toDateString().substring(0,3);
    }
    return ( WeekOfDay +' ' + formatdatedata);

  }
}
