
//***************************This code has been generated using LOBAS Templates 4.0.23.0  

import { Component, ViewChild } from '@angular/core';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { GridComponent } from 'src/app/Components/common/grid/grid.component';

@Component({
  selector: 'app-relationship-detail',
  templateUrl: './relationship-detail.component.html',
  styleUrls: ['./relationship-detail.component.scss']
})
export class relationshipdetailcomponent {
  componentObjectID: string;
  
  constructor(private eventQueue: LobasEventQueueService) {
    this.componentObjectID = "7e13d67e-98b6-45ff-aaf3-8616ace837fc";
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }
  gridCustomActions(data: any) {
    if (data != undefined && data != null) {
      switch (data.CommandObject.Command.toLowerCase()) {
        case "addCustomCasesHere":
              //Logical code.
          break;
      }
    }
  }
}