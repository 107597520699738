import { Component, Input, OnInit } from '@angular/core';
import { ComponentInfo } from 'src/app/models/ComponentInfo';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/encryption.service'
import { JobShiftReportModel } from 'src/app/models/JobShiftReportModel';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { EnumHelper } from 'src/app/util/enum-helper';
import { environment } from 'src/environments/environment';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AlertService } from 'src/app/services/alertService/alert.service';


@Component({
  selector: 'app-teams-widget',
  templateUrl: './teams-widget.component.html',
  styleUrls: ['./teams-widget.component.scss']
})
export class TeamsWidgetComponent extends PageBaseService implements OnInit {
  ComponentObjectID: string;
  gridInfo1 = new ComponentInfo();
  @Input() UserIDForLogin: string;
  userID: string = '';

  constructor(private _router: Router, private _enc: EncryptionService, private auth: AuthService, private httpClient: HttpClient, private localStorageService: LocalStorageService, private alertService: AlertService) {
    super(_router, _enc);
    this.ComponentObjectID = '5EF72874-C6A0-4B0B-8FA1-B69335C783E3';
  }

  ngOnInit(): void {
    this.userID = this.UserIDForLogin;
  }

  // public rowClick(data: any, navigateURL: string) {

  public rowClick(ev: any) {
    let data = ev.data;
    let navigateURL = ev.navigateURL;

    if (this.UserIDForLogin) {
      var RecordId = data.ShiftReportRecordID;
      var JobID = data.Job_RecordID;
      if(!RecordId){
        this.alertService.showAlertPopup('Warning', 'The Shift Report is not available yet', 'fa-solid fa-triangle-exclamation', 'OK');
        return;
      }
      this.httpClient.get<Array<JobShiftReportModel>>(environment.apiUrl + "api/ShiftReport/GetJobByShiftReport/" + RecordId)
        .subscribe((response) => {
          if (response && response[0] != null && response.length > 0) {
            var jobshiftReportRecord: JobShiftReportModel = response[0];
            var roles = this.localStorageService.GetLocal(EnumHelper.LocalStorageKey.userRoles);

            if (jobshiftReportRecord.ShiftReportContactID.toUpperCase() == this.userID.toUpperCase() || 
            roles.toUpperCase().includes('SUPERUSER')) {
              var queryStringParameters = 'RecordID=' + RecordId + '&JobID=' + JobID;
              this.navigateTo(navigateURL, queryStringParameters);
            }
            else {
              this._router.navigateByUrl('unauthorised');
            }
          } else {
            this._router.navigateByUrl('unauthorised');
          }
        });
    }
  }
}


