import { Component, ViewChild } from '@angular/core';
import { DxoPopupComponent } from 'devextreme-angular/ui/nested';
@Component({
  selector: 'app-dblclk-columninfo-popup',
  templateUrl: './dblclk-columninfo-popup.component.html',
  styleUrls: ['./dblclk-columninfo-popup.component.scss']
})
export class DblclkColumninfoPopupComponent {
  popupVisible: boolean = false;
  cellData: any;
  positionOf: string;
  @ViewChild("cellInfoPopup", { static: false }) cellInfoPopup: DxoPopupComponent
  openPopup(cellValue: string, position: string) {
    if (cellValue && cellValue.length > 0) {
      this.positionOf = position;
      this.cellData = cellValue;
      this.cellInfoPopup.visible = true;
    }
  }
}
