<div class="big-button" style="cursor: pointer;" [ngClass]="this.selectedSize" [hidden]="!isUserHavePermissionToViewbutton">
   
   <div class="big-button-box" [ngClass]="this.selectedStyle" (click)="this.performBigButtonAction()">
        <div class="big-button-icon">
            <i class={{this.componentProperties.Icon}}></i>
        </div>
        <div class="big-button-content" >
            <h4>{{this.componentProperties.Title.length > 0 ? this.componentProperties.Title : 'View Legacy UI'}}</h4>          
        </div>
    </div>
</div>