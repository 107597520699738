import { HttpClient } from '@angular/common/http';
import { TmplAstRecursiveVisitor } from '@angular/compiler';
import { Component, Input, OnDestroy,ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxSelectBoxComponent } from 'devextreme-angular';
import dxSelectBox from 'devextreme/ui/select_box';
import { Guid } from 'guid-typescript';
import { Subscription } from 'rxjs';
import { EncryptionService } from 'src/app/encryption.service';
import { AppEventType } from 'src/app/enums/appEventType';
import { AddressModel, GetContactAddressByAddressType, MapLocationImageModel } from 'src/app/models/Address';
import { ObjectCommandModel } from 'src/app/models/ObjectCommandModel';
import { RelationshipRecordModel } from 'src/app/models/RelationshipRecordModel';
import { AddressService } from 'src/app/services/address/address.service';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { MapService } from 'src/app/services/map/map.service';
import { OfflineDataHelperService } from 'src/app/services/offlineDataHelper/offline-data-helper.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { EnumHelper } from 'src/app/util/enum-helper';
import { ValidationPopupComponent } from '../validation-popup/validation-popup.component';
declare function GetMyLocation(GetLocationControlID, WhatThreeWordControlID): any;
declare function MapAddressOnMapAfterLoadControl(): any;
declare function geocode(address): any;
declare function MapWhatThreeWordsOnMap(address): any;
declare function DeclareMapVariable(isSetMode, mapDivId): any;
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent extends PageBaseService implements OnDestroy {
  isVisible: Boolean = false;
  savedLocation: string = '';
  @Input() mode: string;
  @Input() mapDivId: string;
  @Input() name: string = '';
  @Input() relationshipID: string = '';
  @Input() recordID: string = '';
  postalCode: string = '';
  defaultLocation: string = '';
  useDefaultRecord: string;
  addressList: AddressModel[];
  addressRecord: AddressModel;
  isNavigateMode: boolean = false;
  isViewMode: boolean = false;
  isSetMode: boolean = false;
  isSelectLocation:boolean=false;
  postCode:GetContactAddressByAddressType;
  mapElement:HTMLScriptElement;
  performMapActionsSubscription:Subscription;
  ribbonTransitionClickSubscription:Subscription;
  dataSourceCustomerJobLocation: AddressModel[];
  SelectedValue: string = '';
  @ViewChild("ddldelectLocation", { static:false }) ddldelectLocation: DxSelectBoxComponent
  @ViewChild("validationpopup") validationpopup: ValidationPopupComponent;
  
  expandCollaspeComponent() {
    this.isVisible = !this.isVisible;
  }
  constructor(private eventQueue: LobasEventQueueService, private localStorageService: LocalStorageService, private auth: AuthService, private httpClient: HttpClient, private mapservice: MapService,private addressservice: AddressService, private _router: Router, private _enc: EncryptionService,private alertService: AlertService, private offlineDataHelper: OfflineDataHelperService ) {

    super(_router, _enc);    
    this.defaultLocation = '///exchanges.motion.fame';
    this.useDefaultRecord = "true";
    this.ClearGoogleMapLocalStorage();
    this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap, "15", ["hdnmapzoom"]);
  }
  ClearGoogleMapLocalStorage() {
    this.localStorageService.ClearLocalStorageWithKey(EnumHelper.LocalStorageKey.googleMap, ["savedlocation"]);
    this.localStorageService.ClearLocalStorageWithKey(EnumHelper.LocalStorageKey.googleMap, ["coordinatesaddressdatatobindonmap"]);
    this.localStorageService.ClearLocalStorageWithKey(EnumHelper.LocalStorageKey.googleMap, ["hdnsourceaddress"]);
    this.localStorageService.ClearLocalStorageWithKey(EnumHelper.LocalStorageKey.googleMap, ["postaladressdatatobindonmap"]);
    this.localStorageService.ClearLocalStorageWithKey(EnumHelper.LocalStorageKey.googleMap, ["adressdatatobindonmap"]);
    this.localStorageService.ClearLocalStorageWithKey(EnumHelper.LocalStorageKey.googleMap, ["hdndestinationaddress"]);
    this.localStorageService.ClearLocalStorageWithKey(EnumHelper.LocalStorageKey.googleMap, ["whatthreewordforsetmode"]);
    this.localStorageService.ClearLocalStorageWithKey(EnumHelper.LocalStorageKey.googleMap, ["islatlngdestinationaddress"]);
    this.localStorageService.ClearLocalStorageWithKey(EnumHelper.LocalStorageKey.googleMap, ["islatlngsourceaddress"]);
    this.localStorageService.ClearLocalStorageWithKey(EnumHelper.LocalStorageKey.googleMap, ["isautozoom"]);
    this.localStorageService.ClearLocalStorageWithKey(EnumHelper.LocalStorageKey.googleMap, ["lat"]);
    this.localStorageService.ClearLocalStorageWithKey(EnumHelper.LocalStorageKey.googleMap, ["lng"]);
    this.localStorageService.ClearLocalStorageWithKey(EnumHelper.LocalStorageKey.googleMap, ["hdnmapzoom"]);
    this.localStorageService.ClearLocalStorageWithKey(EnumHelper.LocalStorageKey.googleMap, ["cpsaveaddressfrommap"]);
  }
  ChangeMapdivId(newID) {
    document.getElementById('map').id = newID;
  }
  async ChangeMapControlMode() {
    if (this.mode.toLowerCase() == "view") {
      this.isNavigateMode = false;
      this.isSetMode = false;
      this.isViewMode = true;
      this.isSelectLocation = false;
    }
    else if (this.mode.toLowerCase() == "set") {
      this.isNavigateMode = false;
      this.isSetMode = true;
      this.isViewMode = false;
      this.isSelectLocation = false;
    }
    else if (this.mode.toLowerCase() == "navigate") {
      this.isNavigateMode = true;
      this.isSetMode = false;
      this.isViewMode = false;
      this.isSelectLocation = false;
      
    }
    else if (this.mode.toLowerCase() == "selectlocation") {
      this.isNavigateMode = false;
      this.isSetMode = false;
      this.isViewMode = false;
      this.isSelectLocation = true;
      this.BindDropDown();
    }

  }
 

 
  onValueChanged (e) {
   if(e.value===Guid.EMPTY)
   {
    this.isSetMode = true;
   }
   else if(e.value !==''){
    this.isSetMode = false;
    var address =  this.dataSourceCustomerJobLocation?.filter(x => x.RecordID.toLowerCase() === e.value.toLowerCase()).map(x => x.AddressData)[0];
    if(address?.includes('///')) {
    MapWhatThreeWordsOnMap(address);
    }
    else{
      geocode({ address: address });
    }
   }
  }
  PerformMapActionsSubscription:Subscription;
  async ngOnInit() {
   
    this.performMapActionsSubscription =  this.eventQueue.on(AppEventType.PerformMapActions).
    subscribe(event => this.PerformMapActions(event.payload));

    this.ribbonTransitionClickSubscription =  this.eventQueue.on(AppEventType.RibbonTransitionClick).
        subscribe(event => this.PerformRibbonTransitionClick(event) );
        
   this.ChangeMapdivId(this.mapDivId);
   
   this.ChangeMapControlMode();
   DeclareMapVariable(this.isSetMode, this.mapDivId);
   
  }
  
  ngOnDestroy() {
    if(this.performMapActionsSubscription) {
      this.performMapActionsSubscription.unsubscribe();
    }
    if(this.ribbonTransitionClickSubscription) {
      this.ribbonTransitionClickSubscription.unsubscribe();
    }
  }

  async PerformRibbonTransitionClick(data)
  {
    // if((!this.IsNullorEmpty(await this.GetKeyValueFromQueryString("RecordID"))) && this.isSetMode)
    //   {
    //     this.onSaveAllClicked() ;
    //   }
  }

  PerformMapActions(data) {
    // if(data && data.Action)
    //   {
    //     if((data.Action.toLowerCase()=="save" || data.Action.toLowerCase()=="saveall") && this.isSetMode)
    //       {
    //         this.onSaveAllClicked() ;
    //       }
    //   }
  }
  async ngAfterViewInit() {   

    var RecordID = await this.GetKeyValueFromQueryString('RecordID');
    if (!this.IsNullorEmpty(RecordID)) {
      if(this.offlineDataHelper.isOnline){
      await this.GetDetails(RecordID);
      }
    }

    window.setTimeout( function(){  
      MapAddressOnMapAfterLoadControl();
    },1000);    
  }
  async GetDetails(RecordID: string) {
    var userID = this.auth.getGuidUserID() ?? '';
    var tenancyID = this.auth.getGuidTenancyID() ?? ''; 
    this.addressList = await this.addressservice.GetAddresssList(this.relationshipID, RecordID, "00000000-0000-0001-1800-000000000007");
    this.postCode = await this.addressservice.GetAddressByRelationShipAndAddressType(userID, "Postal", "63A9FACF-CC7F-4106-852B-38C849D06D9B",  tenancyID);    
    if(this.postCode!=null && this.postCode!=undefined)
      {
        this.postalCode = this.postCode.AddressByAddressType
      }

    if (this.addressList != null && this.addressList.length > 0) {
      this.addressList.sort((a, b) => new Date(b.RecordCreatedDate).getTime() - new Date(a.RecordCreatedDate).getTime());
      if (this.useDefaultRecord.toUpperCase() == "TRUE" && this.addressRecord == null) {

        this.addressRecord = this.addressList[0];
      }
    }
    else {
      this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap, this.defaultLocation, ["whatthreewordforsetmode"]);
    }
    if (!this.IsNullorEmpty(this.postalCode)) {
      this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap, this.postalCode, ["hdnsourceaddress"]);
      this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap, "True", ["islatlngsourceaddress"]);
    }
    if (this.mode.toLowerCase() == "navigate" && this.IsNullorEmpty(this.postalCode)) {
      
      GetMyLocation('txtLocationGoogleApi_Source', 'txtWhatThreeWordLocation_Source')
    }
    if (this.mode.toLowerCase() == "navigate" && this.addressRecord != null) {
      if (!this.IsNullorEmpty(this.addressRecord.Latitude) && !this.IsNullorEmpty(this.addressRecord.Longitude)) {
        this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap, this.addressRecord.Latitude + "," + this.addressRecord.Longitude, ["hdndestinationaddress"]);
      }
      else if ((!this.IsNullorEmpty(this.addressRecord.PostalCode)) || (!this.IsNullorEmpty(this.addressRecord.AddressData))) {
        if ((!this.IsNullorEmpty(this.addressRecord.PostalCode))) {
          this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap, this.addressRecord.PostalCode, ["hdndestinationaddress"]);
        }
        else {
          this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap, this.addressRecord.AddressData, ["hdndestinationaddress"]);
        }
        this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap, "True", ["islatlngdestinationaddress"]);
      }
      else {
        this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap, this.defaultLocation, ["whatthreewordforsetmode"]);
      }
    }
    else {
      if (this.useDefaultRecord.toUpperCase() != "TRUE" && this.addressList.length > 1) {
        this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap, "True", ["isautozoom"]);
        var coordinatesAddress = "";
        var postalAndCountryCodeAddress = "";
        this.addressList.forEach(addressRecord => {
          if ((!this.IsNullorEmpty(addressRecord.Latitude))
            && (!this.IsNullorEmpty(addressRecord.Longitude))) {
            coordinatesAddress = coordinatesAddress + addressRecord.Latitude + ',' + addressRecord.Longitude + '|';
          }
          else if ((!this.IsNullorEmpty(addressRecord.PostalCode))) {
            if (addressRecord.CountryCode_EnumID != null && addressRecord.CountryCode_EnumID.toString() != Guid.EMPTY) {
              postalAndCountryCodeAddress = postalAndCountryCodeAddress + addressRecord.PostalCode + "|";
            }
            else {
              postalAndCountryCodeAddress = postalAndCountryCodeAddress + addressRecord.PostalCode + "|";
            }

          }
          else if (!this.IsNullorEmpty(addressRecord.AddressData)) {
            postalAndCountryCodeAddress = postalAndCountryCodeAddress + addressRecord.AddressData + "|";
          }
        });
        this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap, coordinatesAddress, ["coordinatesaddressdatatobindonmap"]);
        this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap, postalAndCountryCodeAddress, ["postaladressdatatobindonmap"]);
      }
      else {
        if (this.addressRecord != null && this.addressRecord != undefined) {

          if (!this.IsNullorEmpty(this.addressRecord.W3W)) {
            this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap, this.addressRecord.W3W, ["whatthreewordforsetmode"]);
          }
          else if (!this.IsNullorEmpty(this.addressRecord.Latitude) && !this.IsNullorEmpty(this.addressRecord.Longitude)) {
            this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap, this.addressRecord.Latitude + "," + this.addressRecord.Longitude, ["adressdatatobindonmap"]);
          }
          else if ((!this.IsNullorEmpty(this.addressRecord.PostalCode)) || (!this.IsNullorEmpty(this.addressRecord.AddressData))) {
            if ((!this.IsNullorEmpty(this.addressRecord.PostalCode))) {
              this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap, this.addressRecord.PostalCode + "|", ["postaladressdatatobindonmap"]);
            }
            else {
              this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap, this.addressRecord.AddressData + "|", ["postaladressdatatobindonmap"]);
            }
            this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap, "", ["coordinatesaddressdatatobindonmap"]);
            this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap, "False", ["isautozoom"]);
          }
          else {
            this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap, this.defaultLocation, ["whatthreewordforsetmode"]);
          }
          if (this.mode.toLowerCase() == "set") {
            if (!this.IsNullorEmpty(this.addressRecord.W3W)) {
              this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap, "///" + this.addressRecord.W3W, ["savedlocation"]);
            }
            else {
              this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap, this.addressRecord.AddressData, ["savedlocation"]);
            }
            this.savedLocation = this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.googleMap, ["savedlocation"]);
          }
          else if(this.mode.toLowerCase() == "selectlocation"&& !this.IsNullorEmpty(this.addressRecord?.RecordID)) 
          {
            if(this.dataSourceCustomerJobLocation)
            {
              this.SelectedValue=this.addressRecord.RecordID;
            }else{
              this.BindDropDown();
            }
           
          }

        }
      }
    }
  }


  Save()
  {
    this.onSaveAllClicked();
  }
  async isValidData() {
    // var isValid: boolean = false;
    // return new Promise<Boolean>(async (resolve, reject) => {
    //   const mapInstance = this?.dataGrid?.instance;
    //   gridInstance?.repaint();
    //   isValid = await gridInstance?.getController('validating').validate(true);
    //   resolve(isValid);
    // });
  }

  async onSaveAllClicked(customerID='') {
    var newID = await this.GetKeyValueFromQueryString("NewID");
    var RecordID = await this.GetKeyValueFromQueryString('RecordID');
    var Object1_RecordID;
    
    if (newID != null && newID!='') {
      Object1_RecordID = Guid.parse(newID);
    }
    else {
      Object1_RecordID = Guid.parse(RecordID);
    }
    if (this.mode.toLowerCase() == "set" ) {
      if (this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.googleMap, ["lat"]) != null
       && this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.googleMap, ["lng"]) != null) {
        var currentLat = this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.googleMap, ["lat"]).toString();
        var currentLng = this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.googleMap, ["lng"]).toString();
        var What3wordLocationSelector = (<HTMLInputElement>document.getElementById("txtWhatThreeWordLocation_Set")).value;
        var GoogleApiLocationSelector = (<HTMLInputElement>document.getElementById("txtLocationGoogleApi_Set")).value;
        this.addressList = null;
        this.addressRecord = null;
        if (RecordID != null && RecordID != '' && this.relationshipID != "00000000-0000-0000-0000-000000000000") {
          this.addressList = await this.addressservice.GetAddresssList(this.relationshipID, RecordID, "00000000-0000-0001-1800-000000000007");
        }
        if (this.addressList != null && this.addressList.length >= 1) {
          var lblSavedLocation = "";
          lblSavedLocation = this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.googleMap, ["savedlocation"]);
          this.addressList.sort((a, b) => Number(a.Default) - Number(b.Default));
          this.addressRecord = this.addressList[0];
          if (this.addressRecord == null) {
            this.addressList.sort((a, b) => new Date(b.RecordCreatedDate).getTime() - new Date(a.RecordCreatedDate).getTime());
            this.addressRecord = this.addressList[0];
          }
          var IsLocationChanged = false;
          if (this.addressRecord.Latitude != currentLat
            && this.addressRecord.Longitude != currentLng) {
            IsLocationChanged = true;
          }
          this.addressRecord.Latitude = currentLat;
          this.addressRecord.Longitude = currentLng;
          if (!this.IsNullorEmpty(What3wordLocationSelector)) {
            this.addressRecord.W3W = What3wordLocationSelector.replace("///", "");
          }
          else if (lblSavedLocation != '' && lblSavedLocation.includes("///") && (GoogleApiLocationSelector == null || GoogleApiLocationSelector == undefined || GoogleApiLocationSelector == '')) {
            this.addressRecord.W3W = lblSavedLocation.replace("///", "");
          }
          else {
            this.addressRecord.W3W = null;
          }

          this.addressRecord.AddressPurpose_EnumID = '00000000-0000-0001-1700-000000000013';
          this.addressRecord.CountryCode_EnumID = '00000000-0000-0001-2000-000000000011';


          if (this.addressRecord.W3W == null) {
            if (!this.IsNullorEmpty(GoogleApiLocationSelector)) {
              this.addressRecord.AddressData = GoogleApiLocationSelector;
            }
            else {
              if (!this.IsNullorEmpty(lblSavedLocation)) {
                this.addressRecord.AddressData = lblSavedLocation;
              }
              else {
                this.addressRecord.AddressData = this.addressRecord.Latitude + "," + this.addressRecord.Longitude;
              }
            }
          }
          else {
            this.addressRecord.AddressData = this.addressRecord.W3W
          }
          if (!this.IsNullorEmpty(this.addressRecord.W3W)) {
            this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap, "///" + this.addressRecord.W3W, ["savedlocation"])
          }
          else {
            this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap, this.addressRecord.AddressData, ["savedlocation"])
          }
          this.savedLocation = this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.googleMap, ["savedlocation"]);

          if (IsLocationChanged) {
            await this.addressservice.UpdateAddrress(this.addressRecord,this.addressRecord.RecordID,this.eventQueue,false,true,false);
            this.SaveImage(currentLat,currentLng,RecordID);          
          }

        }
        else if (this.addressList == null || this.addressList.length == 0) {
          this.addressRecord = new AddressModel();
          this.addressRecord.RecordID = Guid.create().toString();
          this.addressRecord.AddressType_EnumID = "00000000-0000-0001-1800-000000000007";
          this.addressRecord.Latitude = currentLat;
          this.addressRecord.Longitude = currentLng;
          this.addressRecord.RecordDataObjectStatus_EnumID = "00000000-0000-0001-3500-000000000002";
          if (!this.IsNullorEmpty(What3wordLocationSelector)) {
            this.addressRecord.W3W = What3wordLocationSelector.replace("///", "");
          }
          else {
            this.addressRecord.W3W = null;
          }

          this.addressRecord.AddressPurpose_EnumID = '00000000-0000-0001-1700-000000000013';
          this.addressRecord.CountryCode_EnumID = '00000000-0000-0001-2000-000000000011';
          if (this.addressRecord.W3W == null) {
            if (GoogleApiLocationSelector == null || GoogleApiLocationSelector == undefined || GoogleApiLocationSelector == '') {
              this.addressRecord.AddressData = this.addressRecord.Latitude + ", " + this.addressRecord.Longitude
            }
            else {
              this.addressRecord.AddressData = GoogleApiLocationSelector;
            }
          }
          else {
            this.addressRecord.AddressData = this.addressRecord.W3W;
          }

          if (!this.IsNullorEmpty(this.addressRecord.W3W)) {
            this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap, "///" + this.addressRecord.W3W, ["savedlocation"])
          }
          else {
            this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap, this.addressRecord.AddressData, ["savedlocation"])
          }
          this.savedLocation = this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.googleMap, ["savedlocation"]);
          if (this.relationshipID != "00000000-0000-0000-0000-000000000000") {
            var addressObj = JSON.stringify(this.addressRecord);
            await this.addressservice.InsertAddressWithRelationship(this.addressRecord,Object1_RecordID,this.relationshipID,this.eventQueue,false,true,false);
          }
          else {
            await this.addressservice.InsertAddress(this.addressRecord,this.eventQueue,false,true,false);
          }
          
          this.SaveImage(currentLat,currentLng,RecordID);
        }
        if (1==1) {
          this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap,currentLat + "," + currentLng, ["cpsaveaddressfrommap"]);
          this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap,currentLat + "," + currentLng, ["adressdatatobindonmap"]); 
          MapAddressOnMapAfterLoadControl();         
        }
      }
    }
    else if(this.mode.toLowerCase() == "selectlocation")
    { 
      if(this.ddldelectLocation.selectedItem.RecordID==Guid.EMPTY && (<HTMLInputElement>document.getElementById("txtLocationGoogleApi_Set"))!=null)
      {
        this.addressRecord = new AddressModel();
        this.addressRecord.RecordID = Guid.create().toString();
        this.addressRecord.AddressType_EnumID = "00000000-0000-0001-1800-000000000007";
        this.addressRecord.RecordDataObjectStatus_EnumID = "00000000-0000-0001-3500-000000000002";
        this.addressRecord.CountryCode_EnumID = '00000000-0000-0001-2000-000000000011';
        this.addressRecord.AddressPurpose_EnumID = "00000000-0000-0001-1700-000000000014";
        if (!this.IsNullorEmpty((<HTMLInputElement>document.getElementById("txtLocationGoogleApi_Set")).value)) {
          this.addressRecord.AddressData = (<HTMLInputElement>document.getElementById("txtLocationGoogleApi_Set")).value;
        }
        else if(!this.IsNullorEmpty((<HTMLInputElement>document.getElementById("txtWhatThreeWordLocation_Set")).value)) {
            this.addressRecord.AddressData = (<HTMLInputElement>document.getElementById("txtWhatThreeWordLocation_Set")).value;
          }
        if(!this.IsNullorEmpty(customerID))
        {
         this.addressservice.InsertCompanyAndJobAddress(customerID,this.addressRecord,Object1_RecordID).then(async response=>{
          this.isNavigateMode = false;
          this.isSetMode = false;
          this.isViewMode = false;
          this.isSelectLocation = true;
          return true;
        });
        }
      }
      else  {
        var relationshipModel= new RelationshipRecordModel();
        relationshipModel.RecordID= Guid.create().toString();
        relationshipModel.Object1_RecordID=this.ddldelectLocation.selectedItem.RecordID,
        relationshipModel.Object2_RecordID=Object1_RecordID.toString(),
        relationshipModel.RelationshipID=this.relationshipID
        this.addressservice.InsertUpdateAddressJobRelationshipRecord(relationshipModel).then(response=>{
          return true;
        });
        
      }
    }
  }
  BindDropDown(customerID:string='')
  { 
    var data= new Array<AddressModel>;
    var emptycolumn=new AddressModel();
    emptycolumn.RecordID=Guid.EMPTY
    emptycolumn.AddressData='Add New';
     if(customerID)
       {
          this.mapservice.fetchCompanyAddressListByCustomerID(customerID).then(response=>{
          data= response;
          data.unshift(emptycolumn);
          this.dataSourceCustomerJobLocation= data;
          if(this.addressRecord && this.addressRecord?.RecordID)
          {
             this.SelectedValue=this.addressRecord.RecordID;
          }
       });
      }
      else if(this.recordID)
        {
           this.mapservice.fetchCompanyAddressListByJobID(this.recordID).then(response=>{
           data= response;
           data.unshift(emptycolumn);
           this.dataSourceCustomerJobLocation= data;
           if(this.addressRecord && this.addressRecord?.RecordID)
            {
             this.SelectedValue=this.addressRecord.RecordID;
            }
        });
       }
     else{
      data.push(emptycolumn);
      this.dataSourceCustomerJobLocation= data;
      if(this.addressRecord && this.addressRecord?.RecordID)
        {
         this.SelectedValue=this.addressRecord.RecordID;
        }
     }
   
  }

  async ValidateSelectLocationComponent(): Promise<Boolean> {
    var isValid: boolean = false;
    return new Promise<Boolean>(async (resolve, reject) => {
      if(!this.IsNullorEmpty(this.ddldelectLocation.selectedItem)){
        resolve(true);
     }
     else{
      this.validationpopup.showPopup("<p> Job Location is Required. </p>");
     }
    });
  }
  async SaveImage(currentLat: string, currentLng: string, RecordID: string) {
    if(this.relationshipID.toUpperCase() == "1E0F556B-7106-4B05-B113-46715A57F753")
    {    
      var newID = await this.GetKeyValueFromQueryString("NewID");
      var ParentRecordID;
      
      if (newID != null && newID!='') {
        ParentRecordID = newID;
      }
      else {
        ParentRecordID = RecordID;
      }
      var imgMapZoom = this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.googleMap, ["hdnmapzoom"]);
      var mapLocationImageModel : MapLocationImageModel = new MapLocationImageModel();
      mapLocationImageModel.Lat = currentLat;
      mapLocationImageModel.Lng = currentLng;
      mapLocationImageModel.Zoom = imgMapZoom;
      mapLocationImageModel.ParentRecordID = ParentRecordID;
      mapLocationImageModel.RelationshipRecordID = "F68DB5A1-E821-4574-86D2-621F635FB2C5";
      this.mapservice.SaveImage(mapLocationImageModel,this.eventQueue);
    }
  }
  

  SetImageLocation(Location:string)
  {
    this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap,Location, ["cpsaveaddressfrommap"]);
    this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap,Location, ["adressdatatobindonmap"]); 
    this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.googleMap,Location ,["savedlocation"]);
    this.savedLocation = Location;
    MapAddressOnMapAfterLoadControl();  
  }

}