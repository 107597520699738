import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-webpartplaceholder',
  templateUrl: './webpartplaceholder.component.html',
  styleUrls: ['./webpartplaceholder.component.scss']
})
export class WebpartplaceholderComponent {
@Input() text:string;

constructor(){
  if(this.text && this.text.length <0){
    this.text = "Work in progress..."
  }
}

}
