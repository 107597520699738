
//***************************This code has been generated using LOBAS Templates 4.0.0.0  

import { Component, ViewChild } from '@angular/core';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { GridComponent } from 'src/app/Components/common/grid/grid.component';

@Component({
  selector: 'app-tester-detail',
  templateUrl: './tester-detail.component.html',
  styleUrls: ['./tester-detail.component.scss']
})
export class testerdetailcomponent {
  componentObjectID: string;
  @ViewChild("testerDetailsEditMode", { static: true }) editModetesterDetails!: GridComponent
  @ViewChild("testerDetailsViewMode", { static: true }) testerDetailsViewMode!: GridComponent

  constructor(private eventQueue: LobasEventQueueService) {
    this.componentObjectID = "98b9fda4-c018-4f6e-bf59-d0886b2ab2b4";
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
    this.eventQueue.on(AppEventType.ActionUpdateSuccess).subscribe(event => this.RefreshOtherControls(event.payload));

  }
  RefreshOtherControls(payload: any): void {
    if(payload)
    {
      this.testerDetailsViewMode.dataGrid.instance.refresh();
    }
  }

  gridCustomActions(data: any) {
    if (data != undefined && data != null) {
      switch (data.CommandObject.Command.toLowerCase()) {
        case "doalert()":
          alert("Test Popup");
          break;
      }
    }
  }
}