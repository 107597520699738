import { Component, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ComponentInfo } from 'src/app/models/ComponentInfo';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/encryption.service';
import { CookieService } from 'ngx-cookie-service';
import { DocumentAssestsViewerPopupComponent } from '../../common/document-assests-viewer-popup/document-assests-viewer-popup.component';
import { Guid } from 'guid-typescript';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { DocumentUploadPopupComponent } from '../../common/document-upload-popup/document-upload-popup.component';
import { GridComponent } from '../../common/grid/grid.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Subscription, lastValueFrom } from 'rxjs';
import { ShiftReportViaJobURLModel } from 'src/app/models/ShiftReportViaJobURLModel';
import { RecordSearchComponent } from '../../common/record-search/record-search.component';
import { ObjectCommandModel } from 'src/app/models/ObjectCommandModel';
import { DistanceCalculationEngineComponent } from '../distance-calculation-engine/distance-calculation-engine.component';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { DeviceHelper } from 'src/app/util/device-Helper'
import { MapComponent } from '../../common/map/map.component';
import { ShiftRequirementService } from 'src/app/services/ShiftRequirement/shift-requirement.service';
import { ChangePlannedTimePopupComponent } from '../shiftrequirement/popup/change-planned-time-popup/change-planned-time-popup.component';
import { AssignworkitemPopupComponent } from '../workitem/popup/assignworkitem-popup/assignworkitem-popup.component';
import { WorkerSelectionToolComponent } from '../../common/worker-selection-tool/worker-selection-tool.component';
@Component({
  selector: 'app-job-planner',
  templateUrl: './job-planner.component.html',
  styleUrls: ['./job-planner.component.scss']
})
export class JobPlannerComponent extends PageBaseService implements OnDestroy {
  @ViewChild("jobDetailDocumentViewPopup", { static: false }) jobDetailDocViewPopup: DocumentAssestsViewerPopupComponent;
  @ViewChild("jobDetailDocumentUploadPopup", { static: false }) jobDetailDocumentUploadPopup: DocumentUploadPopupComponent;
  @ViewChild("grdDocument", { static: true }) grdJObDocumentGrid: GridComponent;
  @ViewChild("jobDetails", {static :false}) jobDetailsGrid:GridComponent
  @ViewChild("jobPlannerMap", { static: false }) jobPlannerMap: MapComponent;
  @ViewChild("shiftrequirementgrid", { static: false }) shiftrequirementgrid: GridComponent;
  @ViewChild("changePlannedTime", { static: false }) changePlannedTime: ChangePlannedTimePopupComponent;
  @ViewChild('WorkItemPopup' ) workItemPopup: AssignworkitemPopupComponent
  @ViewChild('workerSelectionTool' ) workerSelectionTool: WorkerSelectionToolComponent


  ComponentObjectID: string;
  jobDetails = new ComponentInfo();
  JobDetailsCloseCallgrid = new ComponentInfo();
  bigbuttonRedirectUrl: string;
  recordID: string = Guid.EMPTY;
  relationshipRecordID: string = Guid.EMPTY;
  parameterSet: Boolean = false;
  newID: string = Guid.EMPTY;
  loggedinUserID: string = ''
  parameterSetUserID: boolean = false;
  okAlertBtnTxt: string = "";
  warningText: string = "";
  ribbonSubscription: Subscription;
  performRibbonCommandOperation: Subscription;
  NotificationId : string;
  successAlertTitle: string = "";
  ShiftRequirementID : string;

  constructor( private shiftRequirementService:ShiftRequirementService,private httpClient: HttpClient, private eventQueue: LobasEventQueueService, private notificationService: NotificationService,private translateService: TranslateService, private alertService: AlertService, private location: Location, private _router: Router, private _enc: EncryptionService, private cookieService: CookieService, private auth: AuthService) {
    super(_router, _enc);
    this.loggedinUserID = this.auth.getGuidUserID() ?? '';
    if (this.loggedinUserID && this.loggedinUserID.length > 0) {
      this.parameterSetUserID = true;
    }
    this.ComponentObjectID = '5EF72874-C6A0-4B0B-8FA1-B69335C783E3';
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }

  ngOnInit() {
    this.translateService.get('OkButtonText').subscribe((translated: string) => {
      this.okAlertBtnTxt = translated;
    });

    this.translateService.get('warningText').subscribe((translated: string) => {
      this.warningText = translated;
    });
    this.getAllQueryStrings();
    
    this.ribbonSubscription = this.eventQueue.on(AppEventType.RibbonTransitionClick).
    subscribe(event => this.onTransitionBtnClick(event.payload));

    this.performRibbonCommandOperation = this.eventQueue.on(AppEventType.PerformRibbonCommandOperation).
      subscribe(event => this.onRibbonCommandClick(event.payload));
  }
  
  ngOnDestroy() {
    if(this.ribbonSubscription) {
      this.ribbonSubscription.unsubscribe();
    }
    if(this.performRibbonCommandOperation) {
      this.performRibbonCommandOperation.unsubscribe();
    }
  }
  async validateChildControls() {
    var isValid: boolean = false;
    return new Promise<boolean>(async (resolve, reject) => {
      var isjobDetailsGridValid: Boolean = await this.jobDetailsGrid?.isValidFormData();
       var  ValidateMapComponent: Boolean =  await this.jobPlannerMap?.ValidateSelectLocationComponent();
      if (isjobDetailsGridValid && ValidateMapComponent) {
        isValid = true;
        resolve(isValid);

      }
      reject(false);
    });
  } 
  
   onRibbonCommandClick(command: ObjectCommandModel) {
    var isValid: Boolean = true;
    if (command.Definition.PerformValidations) {
      this.validateChildControls().then( response=>{
        isValid=response;
        if (isValid) {
          this.SaveAll(command);
        }
      });
    }
    else{
      this.SaveAll(command);
  }
  }
  SaveAll(command: ObjectCommandModel)
  {
    var customerID = this.jobDetailsGrid.dataGrid.instance.getVisibleRows()[0].data.Customer;
    this.jobPlannerMap.onSaveAllClicked(customerID).then(response => {
    this.jobDetailsGrid.commandAction(command).then(jobDetailsGridresponse => {
      if (jobDetailsGridresponse === true) {
        this.alertService.showAlertPopup(this.successAlertTitle,
          this.translateService.instant('UpdateSuccessMessage'), 'fa-solid fa-check',
          this.okAlertBtnTxt);
      }
    });
  });
  }
  
  async onTransitionBtnClick(data: any) {
    var isValid = await this.validateChildControls();
    if (isValid) {
      var customerID = this.jobDetailsGrid.dataGrid.instance.getVisibleRows()[0].data.Customer;
      this.jobPlannerMap.onSaveAllClicked(customerID).then(response => {
        this.jobDetailsGrid.onRibbonTransitionClick(data).then(response => {
          if (response === true) {
            this.alertService.showAlertPopup(this.successAlertTitle,
              this.translateService.instant('UpdateSuccessMessage'), 'fa-solid fa-check',
              this.okAlertBtnTxt);
          }
        });
      });  
    }
  }

  goBackToPrevPage() {
    this.location.back();
  }

  DocumentgridCustomActions(data: any,isJobDocumentgrid=false) {
    if (data != undefined && data != null) {
      switch (data.CommandObject.Command.toLowerCase()) {
        case "view":
        case "viewdocument":
          if(data.SelectedRecords[0] != undefined && data.SelectedRecords[0] != null)
              this.jobDetailDocViewPopup.showDocumentAssetViewerPopup(true, data.SelectedRecords[0], "viewDocument", null);
          break;
        case "uploadpopup":
          if (data.CommandObject.Name.toUpperCase() == "EDIT") {
              this.jobDetailDocumentUploadPopup.OpenUploadPopup(data.SelectedRecords[0], "editpopup", this.grdJObDocumentGrid);
            
          }
          else {
              this.jobDetailDocumentUploadPopup.OpenUploadPopup(null, "newpopup", this.grdJObDocumentGrid);
          }
          break;
          case "downloaddocument":
                this.jobDetailDocumentUploadPopup.downloadFile(data.SelectedRecords[0]);

         
          break;
      }
    }

  }

  getAllQueryStrings() {
    this.GetKeyValueFromQueryString('recordID').then((recordID => {
      this.recordID = recordID;
        this.GetKeyValueFromQueryString('newID').then((newID => {
          this.newID = newID;
            this.parameterSet = true;
        }))
    }));
  }
  createShiftRequirement(data:any) {
    var result = lastValueFrom(
      this.httpClient.post( environment.apiUrl + 'api/ShiftRequirement/InsertShiftRequirementRecordFromJobWorkItemPicker',
        { "JobID": this.recordID, "WorkItemID": data.RecordID },
        {responseType:"text"}
      )
    ).then((response => {
      this.shiftrequirementgrid.refresh();
    }));
  }
  bindLocationDropDown(data: string) {
    this.jobPlannerMap.BindDropDown(data);
  }

   gridCustomActions(data: any) {
    switch (data.CommandObject.Command.toLowerCase()) {
      case "jobworkitempicker":
        this.workItemPopup.openPopup();
        break;
      case "cancel":
      case "cancelall":
            try {
              var redirectURL = data.CommandObject.Definition?.VisibilityOption[0]?.RedirectURL ?? "";
              if (redirectURL.length === 0) {
                redirectURL = this._router.getCurrentNavigation().previousNavigation.finalUrl.toString();
              }
              this._router.navigateByUrl(redirectURL);
            } catch (Ex) {
              this._router.navigateByUrl('app-home');
            }
        break;
        case "deletegroup":
        this.shiftRequirementService.DeleteGroup(data.CommandObject.Definition.APIActionEndPoint,data.SelectedRecords,data.ObjectID,this.recordID).then(response=>{
          this.translateService.get('MultipleDeleteSuccessMessage').subscribe((translated: string) => {
            this.alertService.showAlertPopup(this.successAlertTitle, translated,'fa-solid fa-check', this.okAlertBtnTxt);
          });
          this.shiftrequirementgrid.refresh();
        });
        break;
      case "changeplannedtime":
        this.changePlannedTime.openPopup(this.recordID,data);
        break;
      case "assignworker": 
           let rows = this.shiftrequirementgrid.dataGrid.instance.getVisibleRows();  
           let rowIndex = this.shiftrequirementgrid.dataGrid.instance.getRowIndexByKey(data.SelectedRecords[0]);  
           let row = rows[rowIndex].data;  
        if(!this.IsNullorEmpty(row.Trade)){
          this.ShiftRequirementID= data.SelectedRecords[0];
          this.workerSelectionTool.getJobDetails(this.recordID);
          this.workerSelectionTool.getworkerdata(this.ShiftRequirementID);
          this.workerSelectionTool.openPopup();
		  
        }
        else{
          this.alertService.showAlertPopup(this.warningText, 'Worker not assigned to this Shift Requirement due to the Trade not being assigned.','fa-solid fa-check', this.okAlertBtnTxt);
        }
        break;
      default:
        this.translateService.get('CustomActionYetToImplemented', { title: data.CommandObject.Name }).subscribe((text: string) => {         
          var InfoTitle:string="";
          this.translateService.get('InfoTitle').subscribe((translated: string) => {
          InfoTitle = translated;
          });
          var OkButtonText:string="";
          this.translateService.get('OkButtonText').subscribe((translated: string) => {
            OkButtonText = translated;
          });

          this.alertService.showAlertPopup(InfoTitle, text,'fa-solid fa-circle-info', OkButtonText);
        });
        break;
    }
  }
  refreshShiftRequirementGrid()
  {
    this.shiftrequirementgrid.refresh();
  }
  
}

