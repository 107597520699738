<div class="customloader" id="customloader"></div>
<!-- <span class="loader" id="spnloader"></span> -->
<div class="login-background"><img src="{{brandingService.GetSplashScreen()}}" /></div>
<div class="login-container validation-container verify-details-cont">

    <div class="card my-auto shadow">
        <div class="card-body">
            <div class="logo-head ">
                <img class="login_logo" src="{{brandingService.GetDesktopLogo()}}" />
            </div>
            <div *ngIf="IsEmailVerification">
                <div class="text-center">
                    <h2>Verify Your Details</h2>
                    <p>Your details must be confirmed and a Verification Code must be entered before you can log in.</p>
                </div>
                <!-- <div class="text-center">
                <img src="{{brandingService.GetDesktopLogo()}}" />
            </div> -->
                <!-- <div class="text-center">
                <h4 i18n>{{'welcomeMessage' | translate }}</h4>
            </div> -->
                <form method="post" (submit)="onSubmit($event)">
                    <div class="form-group">
                        <label>Please confirm your details:</label>
                        <!-- <input id="email" type="email" (value)="emailAddress" readonly class="form-control" /> -->
                        <div class="field_control email_field_border">
                        <dx-text-box [readOnly]="true" [(value)]="EmailVerificationEmailAddress" [inputAttr]="{ 'aria-label': 'Email' }">
                          </dx-text-box>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Please enter the Verification Code</label>
                        <div class="form_control_groups">
                            <input name="digit1"  #OTP1 id="digit1" type="text" (keyup)="OTP1keyup($event)"  maxlength="1" required />
                            <input name="digit2"  #OTP2 id="digit2" type="text" (keyup)="OTP2keyup($event)"  maxlength="1" required />
                            <input name="digit3"  #OTP3 id="digit3" type="text" (keyup)="OTP3keyup($event)"  maxlength="1" required/>
                            <input name="digit4"  #OTP4 id="digit4" type="text" (keyup)="OTP4keyup($event)"  maxlength="1" required/>
                            <input name="digit5"  #OTP5 id="digit5" type="text" (keyup)="OTP5keyup($event)"  maxlength="1" required/>
                            <input name="digit6"  #OTP6 id="digit2" type="text" (keyup)="OTP6keyup($event)"  maxlength="1" required/>
                        </div>
                    </div>
                    <div class="checkbox_field_group form-group">
                        <div class="field_control">
                            <dx-check-box (onValueChanged)="onValueChanged($event)" [(value)]="isRobot" text="I'm not a robot"
                                [elementAttr]="{ 'aria-label': 'Is Robot' }">
                            </dx-check-box>
                            <div *ngIf="this.isRobotValidation" style="color: red;">This field is required</div>
                        </div>
                    </div>
                    <div class="form_btn_groups">
                        <dx-button id="button" (onClick)="SendNewCode()" text="Send a new code">
                        </dx-button>
                        <dx-button id="btnVerify" text="Verify" [useSubmitBehavior]="true">
                        </dx-button>
                    </div>
                </form>
            </div>
            <div *ngIf="IsLinkUsed">
                <div class="text-center">
                    <!-- <img class="login_logo" src="{{brandingService.GetDesktopLogo()}}" /> -->
                    <div class="text-centre">
                        <h1 i18n style="margin-top:0"><strong>{{messageHeader}}</strong></h1>
                    </div>
                    <p>{{message}}</p>
                    <a class="ret_to_login login_btns" [routerLink]="['/login']">Continue</a>
                </div>
            </div>
        </div>
        
    </div>

</div>