
//***************************This code has been generated using LOBAS Templates 4.0.0.0  

import { Component } from '@angular/core';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { ComponentInfo } from 'src/app/models/ComponentInfo';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';

@Component({
  selector: 'app-inactive-contact-grid',
  templateUrl: './inactive-contact-grid.component.html',
  styleUrls: ['./inactive-contact-grid.component.scss']
})
export class inactivecontactgridcomponent {
  componentObjectID: string;

  constructor(private eventQueue: LobasEventQueueService) {
    //super(_router, _enc);
    this.componentObjectID = '39245297-f727-40d4-9701-f284a0c7219a';
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }
  gridCustomActions(data: any) {
    if (data != undefined && data != null) {
      switch (data.CommandObject.Command.toLowerCase()) {
        case "CustomActionHere":
          //Execution code.
          break;
      }
    }
  }
}