import { Component, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular';
import { Guid } from 'guid-typescript';
import { GridComponent } from '../../common/grid/grid.component';
import { DistanceModel } from 'src/app/models/DistanceModel';
import { DistanceCalculateSignalrServiceService } from 'src/app/services/distanceCalculateSignalrService/distance-calculate-signalr-service.service';

@Component({
  selector: 'app-distance-calculation-engine',
  templateUrl: './distance-calculation-engine.component.html',
  styleUrls: ['./distance-calculation-engine.component.scss']
})
export class DistanceCalculationEngineComponent {
  @ViewChild("distanceCalculationEngine",{ static: false }) distanceCalculationEngine : DxPopupComponent;
  @Input() jobID: string = Guid.EMPTY;
  @Output() sourcePostalCode:string;
  @Output() destinationPostalCode:string;
  isParameterset:boolean=false;
  columnTemplates:Map<string, string> = null

  
  @ViewChild("distanceCalculationGrid", { static: true }) distanceCalculationGrid: GridComponent
  @ViewChild("customCellTemplate", { static: true }) customCellTemplate!: TemplateRef<any>

  constructor(private distancesignalRService: DistanceCalculateSignalrServiceService){

  }
  ngOnInit() {
    this.columnTemplates = new Map<string, string>();
    this.columnTemplates.set("Fatigue", "fatigueIconTemplate");
    this.distanceCalculationGrid.ColumnTemplates =  this.columnTemplates;
  }

  openPopup() {
    
    this.distanceCalculationEngine.visible = true;
    this.isParameterset=true;
    console.log('jobID: ',this.jobID);
  }
  
  closePopup() {
    this.distanceCalculationEngine.visible = false;
  }

}
