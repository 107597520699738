import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { HttpHelperService } from '../httpHelper/http-helper.service'; 
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class BrandingService {

  public ActiveBranding:BrandingModel;

  constructor( private httpClient: HttpClient, private httpHelper: HttpHelperService, private cookieService:CookieService) {
    this.ActiveBranding = new BrandingModel();
    this.ActiveBranding.RecordID = "8D5E743F-1ABC-DC68-CAD9-3120460DEC3D";
    this.ActiveBranding.Icon = "Icon.png";
    this.ActiveBranding.Logo = "Logo.webp";
    this.ActiveBranding.SplashScreenImage = "SplashScreenImage.webp";
    this.ActiveBranding.Strapline = "RTG Group | ‘One-Stop-Shop’";
    this.ActiveBranding.ApplicationName = "statrack";
  }

  GetDesktopLogo():String
  {
    if( this.ActiveBranding!=null && this.ActiveBranding.RecordID != '00000000-0000-0000-0000-000000000000')
    {
      return environment.brandingURL +  this.ActiveBranding.RecordID +  "/images/" + this.ActiveBranding.Logo;
    }
    else 
    {
      return "";
    }
  }
  GetTransparentLogo():String
  {
    if( this.ActiveBranding!=null && this.ActiveBranding.RecordID != '00000000-0000-0000-0000-000000000000')
    {
      return environment.brandingURL +  this.ActiveBranding.RecordID +  "/images/logo-transparent.png";
    }
    else 
    {
      return "";
    }
  }
  GetSplashScreen():String
  {
    if( this.ActiveBranding!=null && this.ActiveBranding.RecordID != '00000000-0000-0000-0000-000000000000')
    {
      return environment.brandingURL +  this.ActiveBranding.RecordID +  "/images/" + this.ActiveBranding.SplashScreenImage;
    }
    else
    {
      return "";
    }
  }
  // GetMobileAppLogo():String
  // {
  //   if( this.ActiveBranding!=null && this.ActiveBranding.RecordID != '00000000-0000-0000-0000-000000000000')
  //   {
  //     return environment.brandingURL +  this.ActiveBranding.RecordID +  "/images/" + this.ActiveBranding.Icon;
  //   }
  //   else
  //   {
  //     return "";
  //   }
  // }
  
  LoadActiveBrandingCSS():Promise<boolean> {   
    
    if(this.ActiveBranding!=null) 
    {
      this.cookieService.set("BrandingID", this.ActiveBranding.RecordID, 1, "/");
      const head = document.getElementsByTagName('head')[0];
      const style = document.createElement('link');
      style.id = 'currentBranding';
      style.rel = 'stylesheet';
      style.type = 'text/css';
     switch (this.cookieService.get("BrandingMode").toLowerCase())
     {
      case 'dark':
            style.href = environment.brandingURL + this.ActiveBranding.RecordID +   '/darkBranding.css?version=' + environment.version;
             break;
      case 'device':
           if(window.matchMedia('(prefers-color-scheme: dark)').matches)
           {
              style.href = environment.brandingURL + this.ActiveBranding.RecordID +   '/darkBranding.css?version=' + environment.version;
           }
           else{
            style.href = environment.brandingURL + this.ActiveBranding.RecordID +   '/normalBranding.css?version=' + environment.version;
           }
              break;
       default:
             style.href = environment.brandingURL + this.ActiveBranding.RecordID +   '/normalBranding.css?version=' + environment.version;
             break;
     }
      
      head.append(style);
    }

    return Promise.resolve(true);
  }

  GetBranding(): Promise<BrandingModel> {
    return new Promise<BrandingModel>((resolve, reject) => {
      this.httpClient.get(environment.apiUrl + "api/branding/GetBrandingByUrl/" + environment.hostName)
        .subscribe((response) => {
          resolve(<BrandingModel>response);
        })
    });
  }

  IsBrandingLoaded():boolean
  {
    if(this.ActiveBranding!=null)
    {
      return this.ActiveBranding.RecordID != '00000000-0000-0000-0000-000000000000';
    }
    else 
    {
      return true;
    }
  }
}




export class BrandingModel {
  RecordID: string = '00000000-0000-0000-0000-000000000000'
  Logo: string = 'Logo.webp'
  SplashScreenImage: string = 'SplashScreenImage.webp'
  Strapline: string = 'Bardel Computer Systems Ltd'
  URL: string = 'demo1.localhost'
  ThemeName: string = 'Root'
  CSSProperties: string = '{  "BaseSize": "Normal", "PageBackground": "rgba(255, 255, 255, 1)", "AccentColor1": "rgba(51, 122, 183, 1)", "AccentColor2": "rgba(184, 51, 175, 1)", "TextColor1": "rgba(5, 5, 5, 1)", "TextColor2": "rgba(255, 255, 255, 1)", "BorderColor": "rgba(51, 122, 183, 1)", "HyperlinkColour": "rgba(51, 122, 183, 1)", "ValidationColour": "rgba(255, 0, 0, 1)", "BackgroundColor": "rgba(176, 176, 176, 1)",      "BorderRadius": "4px",   "FontFamily": "sans-serif", "HeaderBackgroundColour": "rgba(255, 255, 255, 1)",  "PageDarkBackgroundColor":"rgb(50, 50, 50)",      "HeaderDarkBackgroundColour": "rgb(0, 0, 0)",      "ButtonColour": "rgba(51, 122, 183, 1)",      "ButtonTextColour": "rgba(255, 255, 255, 1)"  }'
  IconStyle: string = 'fa-solid'
  Active: boolean = true
  ApplicationName: string = 'Lobas Demo'
  Icon: string = 'Icon.png'
  RecordCreatedDate: string = ''
  RecordUpdatedDate: string = ''
  RecordUpdated_UserID: string = ''
  RecordCreated_UserID: string = ''
  CreatedUserIcon: string = ''
  UpdatedUserIcon: string = ''
  CreatedUsersName: string = ''
  UpdatedUsersName: string = ''
  RecordTenancyID: string = ''
  RecordLookupValue: string = ''
  RecordDescription: string = ''
  RecordDataObjectStatus_EnumID: string = ''
}
