<div class="single_notification_view" *ngIf="this.currentItem!=null">
    <div class="notification_list_box_col">
        <div class="webpart-card-header">
            <div class="webpart-card-header-title"><span>Details</span></div>
            <div class="webpart-card-header-action" [hidden]="isExpandCollapseinDetail">
                <dx-button (onClick)="expandCollaspeComponent()"
                    [icon]="isVisible ? 'fa-regular fa-angle-up' : 'fa-regular fa-angle-down'"></dx-button>
            </div>
        </div>

        <div [hidden]="isVisible" class="notification_head">
            <div class="notification_head_left" [innerHTML]="userIcon">
            </div>
            <div class="notification_head_right">
                <div class="notification_head_right_top">
                    <div class="notification_username">
                        <span>{{this.FromUsersName}}</span>
                        <!--<span>Lucy Disley</span>-->
                    </div>
                    <div class="notification_time">
                        {{this.RecordCreatedDate}}
                    </div>
                </div>
                <div class="notification_title">
                    {{this.Subject}}
                </div>
            </div>
        </div>
        <div [hidden]="isVisible" class="single-notification-view-desc">
            <p [innerHTML]="this.MessagePayload | safeHtml"></p>
        </div>
    </div>
    <div class="form-btn-outer" *ngIf="showDetailButton">
        <dx-button text="View Details" (onClick)="onClick()">
        </dx-button>
    </div>
</div>