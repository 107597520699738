<app-breadcrumb [ComponentObjectID]="ComponentObjectID"></app-breadcrumb>

<div class="row webpartrow">
    <div class="col-lg-6 webpartcol screen-50">
        <div class="m-b-30">
            <div class="webpart-card" [hidden]="IsShiftReportStatusDidNotAttend">
                <app-grid  #shiftReportTimeRecordingDetails  [IsUnauthorizedPage]="false"  [executeUpdateTransitionStatusFromRibbonCtrl]="true" [suscribeToRibbonCommandTransitionAction]="true"
                    instanceID="shiftReportTimeRecordingDetails"  (gridCustomActions)="gridCustomActions($event)" ></app-grid>
            </div>
            <div class="webpart-card" [hidden]="!IsShiftReportStatusDidNotAttend">
                <app-time-recording ></app-time-recording>
            </div>
        </div>
        <div class="m-b-30" [hidden]="IsShiftReportStatusDidNotAttend">
            <div class="webpart-card">
                <app-grid #shiftReportTravelRecordingDetails [IsUnauthorizedPage]="false"  [executeUpdateTransitionStatusFromRibbonCtrl]="false" [suscribeToRibbonCommandTransitionAction]="true"
                 instanceID="shiftReportTravelRecordingDetails"></app-grid>
            </div>
        </div>
        <div class="m-b-30">
            <app-chat  *ngIf="parameterSet"  [recordID]="this.recordID" [loggedinUserID]= this.loggedinUserID instanceID ='shiftDetails_Notes'></app-chat>
           
        </div>
        <div class="m-b-30">
            <div class="webpart-card">
                <app-ribbon-control #shiftReportRibbonControl [IsEditMode]="this.IsEditMode" [hidden]="!parameterSet"  instanceID="ShiftReportRibbonControl"
                    [parentRecordID]="this.recordID" [relationshipRecordID]="this.relationshipRecordID" [loadOnInit]="false"
                    [newID]="this.newID"></app-ribbon-control>
            </div>
        </div>
    </div>
    <div class="col-lg-6 webpartcol screen-50">
        <div class="m-b-30">
            <app-grid [IsUnauthorizedPage]="false"  [executeUpdateTransitionStatusFromRibbonCtrl]="false" [suscribeToRibbonCommandTransitionAction]="false" instanceID="ShiftReportDetails_JobDetails" *ngIf="parameterSet"
                [recordID]="this.jobId"></app-grid>
        </div>
        <div class="m-b-30">
            <div class="webpart-card">
                <app-grid #grdMyWorkingElsewhere [executeUpdateTransitionStatusFromRibbonCtrl]="false" [suscribeToRibbonCommandTransitionAction]="false" [IsUnauthorizedPage]="false" [parentRecordId]="this.loggedinUserID"
                    instanceID="myWorkingElsewhereGrid"></app-grid>
            </div>
        </div>
        <div class="m-b-30">
            <div class="webpart-card">
                <app-grid [IsUnauthorizedPage]="false"  [executeUpdateTransitionStatusFromRibbonCtrl]="false" [suscribeToRibbonCommandTransitionAction]="false" instanceID="ShiftReportDetailsClosecallGrid" *ngIf="parameterSet"
                    [recordID]="this.jobId"></app-grid>
            </div>
        </div>
    </div>
</div>