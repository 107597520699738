<app-breadcrumb [ComponentObjectID]="ComponentObjectID"></app-breadcrumb>

<div class="row">
    <div class="col-lg-12">
        <dx-tab-panel [(selectedIndex)]="tab1Index" [swipeEnabled]="false" [elementAttr]="{ class: 'custom_tabpanel' }" (onTitleClick)="onTab1ItemClick($event)">
            <dxi-item title="{{'Details'|translate}}">
                <div class="row webpartrow">
                    <div class="col-lg-6 webpartcol screen-50">
                        <div class="m-b-30">
                            <ng-container *ngIf="!!isInitComplete">
                            <app-grid [gridProperties]="gridInfo1" [IsUnauthorizedPage]="false"
                                instanceID="peopleDetails"></app-grid>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-lg-6 webpartcol screen-50">
                        <div class="m-b-30">
                            <app-default-addresses *ngIf="parameterSet" instanceID="Contact_DefaultAddresses"
                            [recordID]="this.recordID"></app-default-addresses>
                        </div>
                    </div>
                </div>
            </dxi-item>

            <dxi-item title="{{'Addresses'|translate}}">
                <div class="row webpartrow">
                    <div class="col-lg-12 webpartcol screen-100">
                        <app-grid  [IsUnauthorizedPage]="false" #peopleDetails_AddressesList instanceID="peopleDetails_Addresses"></app-grid>
                    </div>
                </div>
            </dxi-item>

            <dxi-item title="{{'Comms'|translate}}">
                <div class="row webpartrow">
                    <div class="col-lg-12 webpartcol screen-100">
                        <div class="m-b-30">
                            <app-grid [IsUnauthorizedPage]="false" #ContactDetails_UserNotifiactionList
                                instanceID="ContactDetails_UserNotifiactionList" [isRowClick]="true"
                                (rowClick)="userNotifiactionList_rowClick($event)"></app-grid>
                        </div>
                        <div class="m-b-30">
                            <app-grid [IsUnauthorizedPage]="false" #ContactDetails_DistributedDocuments
                                (gridCustomActions)="gridCustomActions($event)"
                                instanceID="ContactDetails_DistributedDocuments"></app-grid>
                        </div>
                        <div class="m-b-30">
                            <app-grid [IsUnauthorizedPage]="false" #ContactDetails_QuestionnaireResponse
                                instanceID="ContactDetails_QuestionnaireResponse"
                                (gridCustomActions)="gridCustomActions($event)"></app-grid>
                            <app-questionnaire-response-viewer
                                #questionnaireResponseViewer></app-questionnaire-response-viewer>
                        </div>
                    </div>
                </div>
            </dxi-item>

            <dxi-item title="{{'User Account'|translate}}">
                <div class="row webpartrow">
                    <div class="col-lg-6 webpartcol screen-50">
                        <div class="m-b-30">
                            <app-user-account-details></app-user-account-details>
                        </div>
                        <div class="m-b-30">
                            <app-change-password></app-change-password>
                        </div>
                    </div>
                    <div class="col-lg-6 webpartcol screen-50">
                        <div>
                            <app-security-questions></app-security-questions>
                        </div>
                    </div>
                </div>

            </dxi-item>
            <dxi-item title="{{'Calendar'|translate}}">
                <app-scheduler></app-scheduler>
            </dxi-item>
            <dxi-item title="{{'Competencies'|translate}}">
                <div class="row webpartrow">
                    <div class="col-lg-6 webpartcol screen-50">
                        <div class="m-b-30">
                        <app-grid [IsUnauthorizedPage]="false" #contactDetails_Trade
                        instanceID="contactDetails_Trade"  (gridCustomActions)="gridCustomActions($event)"></app-grid>
                        </div>
                    </div>
                </div>
            </dxi-item>
            
            <dxi-item title="{{'Documents & Notes'|translate}}">
                <div class="row webpartrow">
                    <div class="col-lg-6 webpartcol screen-50">
                        <div class="m-b-30">
                            <app-chat *ngIf="parameterSet" [recordID]="this.recordID"
                                [loggedinUserID]=this.loggedinUserID instanceID='contactDetails_Notes'></app-chat>
                        </div>
                    </div>
                    <div class="col-lg-6 webpartcol screen-50">
                    </div>
                </div>
            </dxi-item>
            <dxi-item title="{{'Well Being'|translate}}">
                <div class="row webpartrow">
                    <div class="col-lg-6 webpartcol screen-50">
                        <div class="m-b-30">
                            <app-html-editor></app-html-editor>
                        </div>
                    </div>
                    <div class="col-lg-6 webpartcol screen-50">
                    </div>
                </div>
            </dxi-item>
            <dxi-item title="{{'Fatigue'|translate}}">
                <div class="row webpartrow">
                    <div class="col-lg-12 webpartcol screen-100">
                        <app-grid [IsUnauthorizedPage]="false" instanceID="FatigueRiskStatus_ContactDetails"></app-grid>
                    </div>
                </div>
            </dxi-item>
        </dx-tab-panel>
        <div *ngIf="showMapControl">
            <div class="row webpartrow">
                <div class="col-lg-6 webpartcol screen-50">
                    <div class="m-b-30">
                        <app-map mapDivId="Contact_Map" name="Location" mode="view"
                            relationshipID="63A9FACF-CC7F-4106-852B-38C849D06D9B"></app-map>
                    </div>
                    <!-- <div class="clearfix"></div> -->
                </div>
                <div class="col-lg-6 webpartcol screen-50">
                    <app-big-button #bigbutton *ngIf="isSetUrl" [url]="bigbuttonRedirectUrl"></app-big-button>
                </div>
            </div>
        </div>
    </div>
</div>
<app-record-relationship-binding-tool #recordrelationshipbindingtool instanceID="TradeRelationshipBindingTool"
ddlGridInstanceID="TradeRelationshipBindingToolDDLGrid" [recordObjectId]="ComponentObjectID" (RelationshipRecordActions)="RelationshipRecordActions($event)">
</app-record-relationship-binding-tool>
