import { ErrorHandler, Injectable } from '@angular/core';
import { AlertService } from '../alertService/alert.service';
import { AuthService } from '../auth/auth.service';
import { LocalStorageService } from '../localStorage/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { navigateToUrl } from 'survey-core/typings/utils/utils';
import { EnumHelper } from 'src/app/util/enum-helper';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GolbalExceptionHandlerService implements ErrorHandler {
  userExpiredErrorCount:number = 0;
  isApiActive:boolean = true;
  constructor(private alertService: AlertService, private localStorageService: LocalStorageService,
    private translateService: TranslateService, private cookieService: CookieService) { }

  handleError(err: any): void {
    var errorOccuranceTime = new Date();
    err.status === 0 ? this.isApiActive = false : this.isApiActive = true;
    var errorBody:any = err.error;
    if(!errorBody) {
      errorBody = err.message;
    }
    if(errorBody && errorBody.Message && errorBody.Message.toLowerCase().includes("session expired")){
      this.localStorageService.SetLocal(EnumHelper.LocalStorageKey.sessionExpired, "1");
      this.unauthorizedUser();
      return ;
    }

    if(this.cookieService.check("TokenID")){   
    var ErrorTitle:string="";
    this.translateService.get('ErrorTitle').subscribe((translated: string) => {
      ErrorTitle = translated;
    });
    
    var OkButtonText:string="";
    this.translateService.get('OkButtonText').subscribe((translated: string) => {
      OkButtonText = translated;
    });   
    if (err.status === 0) {
      this.unauthorizedUser();
    }
    else {
      var isAPIError:boolean = false;
      var errorBody:any = err.Error;
      if(!errorBody) {
        errorBody = err.message;
      }
      if((errorBody && errorBody.length >0)){
          try{ errorBody = JSON.parse(err.error); isAPIError = true; }catch(err:any){ isAPIError = false;  }
          if(isAPIError || (err.error && err.error.Message)){
          switch(errorBody.StatusCode){
            case 401:
                this.unauthorizedUser();
              break;
              default:
              if(errorBody.Message.toLowerCase().includes("session expired") || errorBody.Message.toLowerCase().includes("connection is broken")){
                this.unauthorizedUser();
              }else{
                this.alertService.showAlertPopup(ErrorTitle, errorBody.Message + " " + errorOccuranceTime, 'fa-solid fa-xmark', OkButtonText);
              }
              break;
          }
        }else{
            this.showAngularError(errorBody, errorOccuranceTime);
        }

        }else{
          this.showAngularError(err.message, errorOccuranceTime);
        }

    }
  }else{
    this.unauthorizedUser();
  }
  }

   showAngularError(errorMessage:string, errorOccuranceTime:Date){
    //angular error
      var ErrorTitle:string="";
      this.translateService.get('ErrorTitle').subscribe((translated: string) => {
        ErrorTitle = translated;
      });
      var ApiUnreachable:string="";
      this.translateService.get('ApiUnreachable').subscribe((translated: string) => {
        ApiUnreachable = translated;
      });

      var OkButtonText:string="";
      this.translateService.get('OkButtonText').subscribe((translated: string) => {
        OkButtonText = translated;
      }); 
      if(errorMessage.toLowerCase().includes('session expired') || errorMessage.toLowerCase().includes("connection is broken")
        || errorMessage.includes('401')){
          this.unauthorizedUser();
      }else{
        if(environment.production) {
          this.alertService.showAlertPopup(ErrorTitle, 'An error occured. ' + errorOccuranceTime, 'fa-solid fa-xmark',OkButtonText);
        }
        else {
          this.alertService.showAlertPopup(ErrorTitle, errorMessage + ' ' + errorOccuranceTime, 'fa-solid fa-xmark',OkButtonText);
        }
      }   
   }

  unauthorizedUser(){
    this.localStorageService.clearAllLocalStorageAndCookies(true, true);
  }
}