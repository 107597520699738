<app-breadcrumb [ComponentObjectID]="ComponentObjectID"></app-breadcrumb>
<div class="row webpartrow">
    <div class="col-lg-6 webpartcol screen-50">
        <div class="m-b-30">
           <app-grid #jobDetailsGrid [gridProperties]="jobDetails" (gridCustomActions)="gridCustomActions($event)" [executeUpdateTransitionStatusFromRibbonCtrl]="true" 
            [suscribeToRibbonCommandTransitionAction]="true" [IsUnauthorizedPage]="false" instanceID="jobDetails"></app-grid>
        </div>
        <div class="m-b-30">
            <app-teams-widget *ngIf="parameterSetUserID" [UserIDForLogin]="loggedinUserID"> </app-teams-widget>
        </div>
        <div class="m-b-30">
            <app-assistance-oncall></app-assistance-oncall>
        </div>
        <div class="m-b-30">
            <app-grid [gridProperties]="JobDetailsCloseCallgrid" [IsUnauthorizedPage]="false"
                instanceID="JobDetailsCloseCallgrid"></app-grid>
        </div>
        <div class="m-b-30">
            <app-chat  *ngIf="parameterSet"  [recordID]="this.recordID"  [loggedinUserID]= this.loggedinUserID instanceID ='jobDetails_chat'></app-chat>
        </div>
    </div>
    <div class="col-lg-6 webpartcol screen-50">
        <div class="m-b-30">
            <app-ribbon-control *ngIf="parameterSet" instanceID="JobRibbonControl" [parentRecordID]="this.shiftReportRecordID"
             [relationshipRecordID]="this.relationshipRecordID" [newID]="this.newID"></app-ribbon-control>
        </div>
        <div class="m-b-30">
            <app-map *ngIf="isviewLoad" mapDivId="Job_Map" name="Location" mode="navigate"
                relationshipID="4A36E00B-AD51-4A56-803E-32EFD4F46369"></app-map>
        </div>
        <div class="m-b-30">
            <app-grid #grdJObDocumentGrid [IsUnauthorizedPage]="false"
            (gridCustomActions)="DocumentgridCustomActions($event,true)"
            instanceID="JobDetail_JobDocumentGrid"></app-grid>
           
        <app-document-upload-popup instanceID="JobDetails_JobDocumentUploadPopup" 
        #jobDetailDocumentUploadPopup></app-document-upload-popup>
        </div>
        <div class="m-b-30">
             <app-grid #grdJobDetailEvidenceDocument [IsUnauthorizedPage]="false"
        (gridCustomActions)="DocumentgridCustomActions($event)"
        instanceID="JobDetails_EvidenceDocumentgrid"></app-grid>
    <app-document-assests-viewer-popup instanceID="JobDetails_DocumentAssetViewerPopup"
        #jobDetailDocViewPopup></app-document-assests-viewer-popup>
        <app-document-upload-popup instanceID="JobDetails_DocumentUploadPopup" 
        #jobDetailDocUploadPopup></app-document-upload-popup>
        <!-- <dx-button (onClick)="OpenDistanceCalculationGrid()">Distance Calculation Engine</dx-button> -->
        </div>        
        <div class="m-b-30">
            <app-grid #assetTestGrid  [IsUnauthorizedPage]="false" instanceID="JobDetails_AssetTestGrid"></app-grid>
        </div>
    </div>
</div>
<app-record-search instanceID="assetRecordSearch" ddlGridInstanceID="assetRecordSearchDDLGrid" #assetRecordSearchPopup>
    
</app-record-search>



<!-- <app-distance-calculation-engine instanceID="distancecalculationEngine" [jobID]="this.recordID" #distanceCalculationEnginepopup>

</app-distance-calculation-engine> -->

