import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Guid } from 'guid-typescript';
import { Subscription } from 'rxjs';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { EncryptionService } from 'src/app/encryption.service';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { NoteModel } from 'src/app/models/NoteModel';
import { ObjectRecordModel } from 'src/app/models/ObjectRecordModel';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { UserIconImageService } from 'src/app/services/userIconImage/user-icon-image.service';
import { EnumHelper } from 'src/app/util/enum-helper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-notification-view',
  templateUrl: './notification-view.component.html',
  styleUrls: ['./notification-view.component.scss']
})
export class NotificationViewComponent extends PageBaseService implements OnInit, OnDestroy {
  isVisible: boolean = false;
  isExpandCollapseinDetail: boolean = true
  currentItem: any = null;
  userIcon: string;
  objectID: String = '7F103B80-4EB5-45F5-97FF-BFC67C04CA6B';
  notificationID: string;
  FromUsersName: String;
  RecordCreatedDate: String;
  Subject: String;
  MessagePayload;
  NotificationStatus_EnumID: String;
  To: String;
  ribbonSubscription: Subscription;
  @Input() suscribeToRibbonCommandTransitionAction: boolean = false;
  @Input() showDetailButton: boolean = true;
  NotificationRecipientID: String;
  ComponentObjectID: string;
  


  recordID: string = Guid.EMPTY;
  relationshipRecordID: string = Guid.EMPTY;
  newID: string = Guid.EMPTY;
  parameterSet: Boolean = false;
  RelationshipID: string;
  loggedinUserID: string = '';
  constructor(private eventQueue: LobasEventQueueService, private userIconImageService: UserIconImageService, private localStorageService: LocalStorageService, private auth: AuthService, private httpClient: HttpClient, private _router: Router, private _enc: EncryptionService) {
    super(_router, _enc);
    this.loggedinUserID = this.auth.getGuidUserID() ?? '';

  }

  ngOnInit(): void {
    if (this.suscribeToRibbonCommandTransitionAction) {
      this.ribbonSubscription = this.eventQueue.on(AppEventType.RibbonTransitionClick).
        subscribe(event => this.onRibbonTransitionClick(event.payload));
    }
  }

  ngOnDestroy() {
    if (this.ribbonSubscription) {
      this.ribbonSubscription.unsubscribe();
    }
  }

  async onRibbonTransitionClick(data) {
    var queryStringRecordID = await this.GetKeyValueFromQueryString('RecordID');
    this.eventQueue.dispatch(new AppEvent(AppEventType.PerformRibbonTransitionOperation, { NewID: '', updateNewIDWithRecordIDInURL: false, RecordID: queryStringRecordID }));
  }

  bindData(item: any, userIconHTML: string) {
    this.currentItem = item;
    this.userIcon = userIconHTML;
    this.FromUsersName = this.formatData(this.currentItem.data["FromUsersName"], 'varchar');
    this.RecordCreatedDate = this.formatData(this.currentItem.data["RecordCreatedDate"], 'Datetime');
    this.Subject = this.formatData(this.currentItem.data["Subject"], 'varchar');
    this.MessagePayload = this.formatData(this.currentItem.data["MessagePayload"], 'varchar');
    this.NotificationStatus_EnumID = this.currentItem.data["NotificationStatus_EnumID"];
    this.To = this.currentItem.data["To"];
    this.NotificationRecipientID = this.currentItem.data["NotificationRecipientID"];
    return this.updateNotificationReadStatus();
  }


  getInitials(userName: string): String {
    return this.userIconImageService.getInitialsString(userName);
  }
  expandCollaspeComponent() {
    this.isVisible = !this.isVisible;
  }

  BindDataWithRelatedRecords(RelationshipId: string, RecordId: string, ObjectID: string) {
    this.isExpandCollapseinDetail = false
    this.httpClient.get<ObjectRecordModel>(environment.apiUrl + 'api/ObjectRecord/GetRelatedRecordsByRecordId/' + ObjectID + '/' + RelationshipId + '/' + RecordId)
      .subscribe((response: ObjectRecordModel) => {
        if (response && response[0]?.RecordID) {
          this.notificationID = response[0]?.RecordID;
          if (this.notificationID) {
            this.httpClient.get(environment.apiUrl + 'api/Notification/GetUserNotification/' + this.notificationID)
              .subscribe((response: any) => {
                if(response && response != null){
                  this.currentItem = response;
                  this.FromUsersName = this.formatData(response?.FromUsersName, 'varchar');
                  this.RecordCreatedDate = this.formatData(response?.RecordCreatedDate, 'Datetime');
                  this.Subject = this.formatData(response?.Subject, 'varchar');
                  this.MessagePayload = this.formatData(response?.MessagePayload, 'varchar');
                  this.NotificationStatus_EnumID = response?.NotificationStatus_EnumID;
                  this.To = response?.To;
                  this.NotificationRecipientID = response?.NotificationRecipientID;
                  this.updateNotificationReadStatus().then(response => {
                    this.NotificationStatus_EnumID = "00000000-0000-0001-4100-000000000005";
                  }).catch((ex) => { throw ex.message });
                }
              });            
          }
        }
      });
  }

  updateNotificationReadStatus() {
    var values = [];
    values.push(this.objectID);
    var currentStatus = this.NotificationStatus_EnumID?.toUpperCase();//this.currentItem.data["NotificationStatus_EnumID"];
    var receipientID = this.To;// this.currentItem.data["To"];
    if (receipientID && receipientID.toUpperCase() == this.auth.getGuidUserID().toUpperCase()) {
      if (currentStatus == '00000000-0000-0001-4100-000000000001' ||
        currentStatus == '00000000-0000-0001-4100-000000000002' ||
        currentStatus == '00000000-0000-0001-4100-000000000003' ||
        currentStatus == '00000000-0000-0001-4100-000000000004' ||
        currentStatus == '00000000-0000-0001-4100-000000000006') {
        var recordID = this.NotificationRecipientID;
        var objectData: any = {};
        objectData.RecordID = recordID;
        objectData.NotificationStatus_EnumID = "00000000-0000-0001-4100-000000000005";
        var url = environment.apiUrl + "api/NotificationRecipient/update/" + recordID;
        return lastValueFrom(this.httpClient.put(url, objectData,
          {
            headers: {
              'objectID': values
            },
            responseType: "text"
          }
        ));
      }
    }
    return new Promise<String>((resolve, reject)=> { resolve("alreadyRead") });
  }


  formatData(data: string, dataType: String): String {
    var locale = localStorage.getItem('UserLanguage');
    let language: string | null = "en-GB";
    language = localStorage.getItem("UserLanguage");
    if (language == null) {
      language = "en-GB";
    }

    if (dataType.toLowerCase() == "datetime") {
      return formatDate(data, 'short', locale);
    }
    else if (dataType.toLowerCase() == "date") {
      return formatDate(data, 'shortDate', locale);
    }
    else if (Date.parse(data) && dataType.toLowerCase() == "time") {
      return formatDate(data, 'shortTime', locale);
    }
    else {
      return data;
    }
  }
  onClick() {

    // if (this.currentItem != null) {
    //   this.navigateTo("notification-recipient-details", 'RecordID=' + this.NotificationRecipientID);
    // }
    var recordId = this.NotificationRecipientID.toString();
    this.relationshipRecordID = Guid.EMPTY;
    this.newID = Guid.EMPTY;
    this.parameterSet = true;
    this.BindDataWithRelatedRecords('90476D69-8F5E-4DA2-B5E8-962588D9FE94', recordId, '7F103B80-4EB5-45F5-97FF-BFC67C04CA6B');
    this.RedirectToActionURL(recordId);

  }
  RedirectToActionURL(recordID: string) {
    this.httpClient.get<any>(environment.apiUrl + 'api/NotificationRecipient/GetNotificationRecipientByRelationshipIdAndRecordID/D70AB205-4698-4E02-B107-A1D8849F4645/' + recordID)
      .subscribe((response) => {
        if (response) {
          var notificationRecipient;
          response.forEach(element => {
            if (element.Object1_RecordID.toLowerCase() == recordID.toLowerCase() && element.Object2_RecordID.toLowerCase() == this.loggedinUserID.toLowerCase()) {
              notificationRecipient = element;
            }
          });
          if (notificationRecipient) {
            //notificationRecipient.ActionURL=notificationRecipient.ActionURL.replaceAll("https://test.statrack.co.uk/","http://localhost:4200/");
            if (notificationRecipient.ActionURL != null) {
              if (!notificationRecipient.ActionURL.toLowerCase().includes("/AngularUI/notification-recipient-details".toLowerCase())) {
                this.RedirectToEncryptUrl(notificationRecipient.ActionURL);
              }
              else if (recordID && !notificationRecipient.ActionURL.toLowerCase().includes("recordid=" + recordID.toLowerCase())) {
                this.RedirectToEncryptUrl(notificationRecipient.ActionURL);
              }
              else {
                this.navigateTo("notification-recipient-details", 'RecordID=' + this.NotificationRecipientID);
              }
            }
          }
          else {
            this._router.navigateByUrl('unauthorised');
          }
        }
        else {
          this._router.navigateByUrl('unauthorised');
        }
      });
  }
}