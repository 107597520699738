<app-breadcrumb [ComponentObjectID]="ComponentObjectID"></app-breadcrumb>

<div class="row">
    <div class="col-lg-12">
        <dx-tab-panel  [(selectedIndex)]="tab1Index" (onTitleClick)="onTab1ItemClick($event)" [swipeEnabled]="false" [elementAttr]="{ class: 'custom_tabpanel' }"(onTitleClick)="onItemClick($event)">
            <dxi-item title="{{'Details'|translate}}">
                <div class="row webpartrow">
                    <div class="col-lg-6 webpartcol screen-50">
                        <div class="m-b-30">
                            <app-grid #grdCompanyDetails  (gridCustomActions)="gridCustomActions($event)"  
                            [suscribeToRibbonCommandTransitionAction]="true" [executeUpdateTransitionStatusFromRibbonCtrl]="true" [IsUnauthorizedPage]="false"
                           instanceID="CompanyDetails"></app-grid>
                        </div>
                        <div class="m-b-30">
                            <app-grid  [IsUnauthorizedPage]="false"  #Contactsgrid instanceID="CompanyDetails_Contacts"
                            (gridCustomActions)="gridCustomActions($event)"></app-grid>
                        </div>
                    
                    </div>
                    <div class="col-lg-6 webpartcol screen-50">
                        <div class="m-b-30">
                            <app-default-addresses  *ngIf="parameterSet" instanceID="Company_DefaultAddresses"
                            [recordID]="this.recordID"></app-default-addresses>
                        </div>
                        <div class="m-b-30">
                             <app-ribbon-control *ngIf="parameterSet" instanceID="CompanyRibbonControl" [parentRecordID]="this.recordID"
                             [relationshipRecordID]="this.relationshipRecordID" [newID]="this.newID"></app-ribbon-control>
                       </div>
                    </div>
                </div>
            </dxi-item>
            <dxi-item title="{{'Addresses'|translate}}">
                <div class="row webpartrow">
                      <app-grid  [IsUnauthorizedPage]="false" (gridCustomActions)="addressGridCustomActions($event)" #CompanyDetails_AddressesList instanceID="CompanyDetails_Addresses"></app-grid>
                      <app-address-popup instanceID="CompanyDetails_AddressPopUp" #CompanyDetails_AddressPopUp></app-address-popup>
                </div>
            </dxi-item>   
            <dxi-item title="{{'Documents & Notes'|translate}}">
                <div class="row webpartrow">
                    <div class="col-lg-6 webpartcol screen-50">
                        <div class="m-b-30">
                            <app-grid [hidden]="!parameterSet" #grdCompanyDocument [IsUnauthorizedPage]="false" (gridCustomActions)="DocumentgridCustomActions($event)" instanceID="CompanyDetails_Documentgrid"></app-grid>
                            <app-document-assests-viewer-popup instanceID="CompanyDetails_DocumentAssetViewerPopup" #companyDocViewPopup></app-document-assests-viewer-popup>
                            <app-document-upload-popup instanceID="companyDetails_DocumentUploadPopUp"  #companyDocUploadPopup></app-document-upload-popup>
                        </div>
                    </div>
                    <div class="col-lg-6 webpartcol screen-50">
                        <div class="m-b-30">
                            <app-chat *ngIf="parameterSet" [recordID]="this.recordID" [loggedinUserID]=this.loggedinUserID instanceID='CompanyDetails_Notes'></app-chat>
                        </div>
                    </div>
                </div>
            </dxi-item>   
        </dx-tab-panel>
        <div *ngIf="showMapControl">
            <div class="row webpartrow">
                <div class="col-lg-6 webpartcol screen-50">
                    <div class="m-b-30">
                           <app-map [hidden]="!parameterSet" #companyDetailMap mapDivId="Company_Map" name="Location" mode="set"
                           relationshipID="B43888B6-8969-46BC-9738-8714FCDE1578"></app-map>
                    </div>
                </div>
            </div>
        </div> 
    </div>
    <app-record-relationship-binding-tool #recordrelationshipbindingtool instanceID="CompanyContactRelationshipBindingTools"
    ddlGridInstanceID="contactRecordSearchDDLGrid" [recordObjectId]="ComponentObjectID" (RelationshipRecordActions)="RelationshipRecordActions($event)">
  </app-record-relationship-binding-tool>
</div>


