<div class="contactcard_popup">
  <dx-popup (onHiding)="popup_hiding($event)" [wrapperAttr]="{ class: 'contactcard_popup'}" class="contactcard_popup" #contactDetailsPopup
    [showTitle]="false" [height]="360" [width]="300" [dragEnabled]="false" [hideOnOutsideClick]="true"
    [showCloseButton]="false">
    <dxo-position at="left top" my="left top" [of]="positionOf">
    </dxo-position>
    <div class="contactcard_popup_box">
      <h3>{{ contactDetails.UserName }}</h3>
      <div class="contactcard_popup_image text-center">
        <div *ngIf="contactDetails.Path">
          <img class="contactcard_popup_image_img" [src]="getImagePath()" />
        </div>
        <div *ngIf="!contactDetails.Path" class="grid-user-icons">
          {{UserInitials}}
        </div>
      </div>
      <div class="contactcard_popup_content">
        <h4><div class="iconouter"><i class="fa-light fa-phone"></i></div><a href=tel:{{contactDetails.MobileNumber}}>{{ contactDetails.MobileNumber }}</a></h4>
        <h4><div class="iconouter"><i class="fa-light fa-envelope"></i></div><a href=mailto:{{contactDetails.EmailAddress}}>{{
            contactDetails.EmailAddress }}</a></h4>
        <h4><div class="iconouter"><img src="/assets/images/sentinel.png" /></div><span>{{ contactDetails.SentinelNumber }}</span></h4>
      </div>
    </div>
  </dx-popup>
</div>