import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHelperService } from '../httpHelper/http-helper.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CarouselService {

  constructor(private httpClient: HttpClient, private httpHelper: HttpHelperService,private auth: AuthService) { }

  GetShiftCarousel(tokenID:string,DaysInFuture: number, DaysInPast: number): Promise<ShiftsCarouselList> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.apiUrl + 'api/Shifts/GetShiftCarousel/' + DaysInFuture + '/' + DaysInPast)
        .subscribe((response) => {
          resolve(<ShiftsCarouselList>response);
        });
    });
  }
}

export class ShiftsCarouselList
{
  nearestSlideIndex:number=0

  shiftsCarouselModel:ShiftCarousel[]=[];
}

export class ShiftCarousel {
  Job_RecordID: string = '';
  JobNumber: string = '';
  ShiftAppointmentSubject: string = '';
  Trade: string = '';
  StartDate:Date=null;
  EndDate:Date=null;
  ShiftAppointmentEventType: string = '';
  CustomerName: string = '';
  ShiftAppointmentResourceRelationStatus: string = '';
  EnumStatusColour: string = '';
  ShiftAppointmentResourceRelationship_RecordID: string = '';
}
