import { Component, OnInit, ViewChild } from '@angular/core';
import { appointmentsData, resourcesData } from './data'
import { SchedulerService } from 'src/app/services/scheduler/scheduler.service';
import CustomStore from 'devextreme/data/custom_store';
import { CookieService } from 'ngx-cookie-service';
import { DxSchedulerTypes } from 'devextreme-angular/ui/scheduler';
import { formatDate } from 'devextreme/localization';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentPropertiesService } from 'src/app/services/componentProperties/component-properties.service';
import { CalenderComponentProperties } from 'src/app/models/CalenderComponentProperties';
import { AppointmentModel } from 'src/app/models/AppointmentModel';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { lastValueFrom } from 'rxjs';
import dxScheduler from 'devextreme/ui/scheduler';
import { EnumerationModel } from 'src/app/models/EnumerationModel';
import { DxValidationGroupComponent } from 'devextreme-angular/ui/validation-group';
import { ValidationPopupComponent } from '../validation-popup/validation-popup.component';
import { DxDateBoxComponent, DxDropDownBoxComponent, DxListComponent, DxSelectBoxComponent, DxTextAreaComponent, DxTextBoxComponent } from 'devextreme-angular';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { EnumHelper } from 'src/app/util/enum-helper';
import dxTextBox from 'devextreme/ui/text_box';
import { OfflineDataHelperService } from 'src/app/services/offlineDataHelper/offline-data-helper.service';
import dxPopup from 'devextreme/ui/popup';
import dxSelectBox from 'devextreme/ui/select_box';
@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss']
})
export class SchedulerComponent implements OnInit {
  appointmentsData: any = {}; // = appointmentsData
  appointEventresources: EnumerationModel[];
  currentDate: Date = new Date();
  currentTimezone: string = 'Europe/London';
  editAppointmentData: any = {};
  isCustomPopupVisible = false;
  startDate: any;
  endDate: any;
  recordID: string = '00000000-0000-0000-0000-000000000000';
  isDropDownBoxOpened = false;
  appointmenttype: any;
  dataSource: {};
  relationshipID: '1A9FF82C-87B2-43B9-ABA6-B32BFA5FCC08'
  componentID: string = 'Calender';
  instanceID = 'ContactDetail_Schedular'
  componentProperties = new CalenderComponentProperties();
  description: string;
  ddlData: any;
  Link = "";
  action = "view";
  isUserHavePermissionToEdit=false;
  isUserHavePermissionToAdd=false;
  Title = '';
  Subject='';
  isLinkVisible:boolean=true;
  isEditButtonVisible=true;
  NoDataInOffline=true;
  ObjectID='AF614F3A-2830-410C-AE6D-9699388E7DA0';
  okAlertBtnTxt: string = this.translateService.instant('Ok');
  successAlertTitle: string = this.translateService.instant('SuccessTitle');
  @ViewChild('scheduler', { static: false }) scheduler: dxScheduler;
  @ViewChild('popup', { static: false }) popup: dxPopup;
  @ViewChild('schedulerGroup', { static: false }) schedulerGroup: DxValidationGroupComponent;
  @ViewChild("validationpopup") validationpopup: ValidationPopupComponent;
  @ViewChild('StartDate', { static: false }) dxStartDate: DxDateBoxComponent;
  @ViewChild('EndDate', { static: false }) dxEndDate: DxDateBoxComponent;
  @ViewChild('Type', { static: false }) dxType: DxSelectBoxComponent;
  @ViewChild('AppointmentSubject', { static: false }) dxAppointmentSubject: DxTextBoxComponent;
  @ViewChild('Description', { static: false }) dxDescription: DxTextAreaComponent;
  @ViewChild('AppointmentLink', { static: false }) dxAppointmentLink: DxTextBoxComponent;

  constructor(private schedulerService: SchedulerService, private cookieService: CookieService, private auth: AuthService, private componentPropertyData: ComponentPropertiesService, public translateService: TranslateService, public alertService: AlertService, private localStorageService: LocalStorageService,private offlineDataHelper: OfflineDataHelperService) {}

  async ngOnInit(): Promise<void> {
    var timeZone = this.cookieService.get("CurrentTimeZone");
    if (this.schedulerService.getTimezoneName(timeZone) != '') {
      this.currentTimezone = this.schedulerService.getTimezoneName(timeZone);
    }
    if(this.offlineDataHelper.isOnline){
      await this.getComponentProperties();
    this.schedulerService.getappointEventypeResources().then((response) => {
      var app = this.componentProperties.AppointmentType;
      if (this.componentProperties.AppointmentType != null && this.componentProperties.AppointmentType.length != 0 && this.componentProperties.AppointmentType[0] != '' && response) {
        this.appointEventresources = response.filter(x => this.componentProperties.AppointmentType.some(y => y.toLowerCase() === x.EnumerationId.toLowerCase()));
      }
      else {
        this.appointEventresources = <EnumerationModel[]>response;
      }
    });
  }
  }

  onAppointmentFormOpening(e: any): void {
    this.ddlData = this.appointEventresources;
    if (e == null) {
      this.makePopupInEditMode();
      this.Title= 'New Appointment'
      this.dxStartDate.instance.reset();
      this.dxEndDate.instance.reset();
      this.dxType.instance.reset();
      this.dxDescription.instance.reset();

      this.appointmenttype=null;
      this.schedulerGroup.instance.reset()
      if (this.appointEventresources.length == 1) {
        this.appointmenttype = this.appointEventresources[0];
         this.dxType.readOnly = true;
      }
      this.description = "";
      this.Subject = "";
      this.Link = null;
      this.isCustomPopupVisible = true;
      this.action = "add";
    }
    else {
      
        e.cancel = true;
        this.Title= 'Edit Appointment'
        this.startDate = e.appointmentData.StartDate;
        this.endDate = e.appointmentData.EndDate;
        this.description = e.appointmentData.RecordDescription;
        this.appointmenttype = e.appointmentData.AppointmentEventType_EnumID;
        if (this.appointEventresources.length == 1) {
           this.dxType.readOnly = true;
        }
        this.Subject = e.appointmentData.LookupValue;
        this.recordID = e.appointmentData.RecordID;
        this.Link = e.appointmentData.Url
        this.editAppointmentData = e.appointmentData;
      
        if (this.isUserHavePermissionToEdit) {
          this.isCustomPopupVisible=true;
            this.makePopupInEditMode();
        this.action="edit"
      }
      else{
        this.isCustomPopupVisible=true;
          this.makePopupReadonly();
        this.action="view"
      }
    }
  }

  onHiding(e: any): void {
    this.editAppointmentData = {};
  }

  openPopup() {
    this.onAppointmentFormOpening(null);
    
  }

  onAppointmentUpdated(e: DxSchedulerTypes.AppointmentUpdatedEvent) {
    alert('updated');
  }

  changeDropDownBoxValue(e:any) {
    this.appointmenttype = e.addedItems[0]?.EnumerationId;
    this.isDropDownBoxOpened = false;
  }

  Submit(e: any) {
    const { isValid } = this.schedulerGroup.instance.validate()
    if (isValid) {
      this.addAppointment();
    }
    else {
      const brokenRules = e.validationGroup.validate().brokenRules;
      this.validationpopup.showPopup(brokenRules.map((rule: any) => {
        return "<p>" + rule.message + "</p>"
      }).join(""));
    }
  }

  addAppointment() {
    var appointment = new AppointmentModel();
    if(this.appointmenttype.EnumerationId == undefined || this.appointmenttype.EnumerationId == null){
      appointment.AppointmentEventType_EnumID = this.appointmenttype;
    }
    else{
      appointment.AppointmentEventType_EnumID = this.appointmenttype.EnumerationId;
    }
    
    appointment.RecordStartDate = this.startDate;
    appointment.RecordEndDate = this.endDate;
    appointment.Description = this.description;
    appointment.Url = this.Link;  
    appointment.Subject = this.Subject;
    appointment.RecordID = '00000000-0000-0000-0000-000000000000';
    if (this.action == "add") {
      var userID = this.auth.getGuidUserID() ?? '';
      var relationshipID = '1A9FF82C-87B2-43B9-ABA6-B32BFA5FCC08';
      this.schedulerService.addAppointmentWithRelationship(appointment, userID, relationshipID).subscribe(x => {
        this.alertService.showAlertPopup(this.successAlertTitle,
          this.translateService.instant('InsertSuccessMessage'), 'fa-solid fa-check',
          this.okAlertBtnTxt);
        this.appointmentsData = this.schedulerService.getCurrentUsersAppointments(this.componentProperties.Filter);
      });
    }
    else if (this.action == "edit") {
      appointment.RecordID=this.recordID;
      this.schedulerService.updateAppointment(appointment, this.recordID).subscribe(x => {
        this.alertService.showAlertPopup(this.successAlertTitle,
          this.translateService.instant('UpdateSuccessMessage'), 'fa-solid fa-check',
          this.okAlertBtnTxt);
        this.appointmentsData = this.schedulerService.getCurrentUsersAppointments(this.componentProperties.Filter);
      });
    }
  }

  closePopup() {
    this.startDate = null;
    this.endDate = null;
    this.description = null;
    this.appointmenttype = null;
    this.isCustomPopupVisible = false;
  }

  async getComponentProperties() {
    this.componentPropertyData.getComponentPropertiesData(this.componentID, this.instanceID).subscribe((response: any) => {
      this.setComponentProperties(response.Properties)
      this.appointmentsData = this.schedulerService.getCurrentUsersAppointments(this.componentProperties.Filter);
      var data = this.appointmentsData;
      this.checkPermission();
      if(!this.isUserHavePermissionToEdit){
        this.isEditButtonVisible= false;
      }
    })
  }

  setComponentProperties(componentProperties: any) {
    var componentProperties = JSON.parse(componentProperties);
    componentProperties.CalenderProperties.forEach(componentProperty => {
      switch (componentProperty.key.toLowerCase()) {
        case "relationshipid":
          this.componentProperties.RelationshipID = componentProperty.value;
          break;
        case "objectid":
          this.componentProperties.ObjectID = componentProperty.value;
          break;
        case "appointmenttypes":
          this.componentProperties.AppointmentType = componentProperty.value.split(',');
          break;
        case "allowedroles":
          this.componentProperties.AllowedRoles = componentProperty.value;
          break;
        case "filter":
          this.componentProperties.Filter = componentProperty.value;
          break;
      }
    })
  }

  checkPermission() {
    var data;
    this.schedulerService.getObjectCommandWithPermission(this.ObjectID).subscribe(res=>{
      data=res;
      this.isUserHavePermissionToAdd=data.filter(x=>x.Command.toLowerCase() == 'insert').length>0;
      this.isUserHavePermissionToEdit=data.filter(x=>x.Command.toLowerCase() == 'update').length>0;
    }
    );    
  }

  clickOnEdit(e,model:any){
    this.action = "edit";
    this.makePopupInEditMode();
    this.Title= 'Edit Appointment'
        this.startDate = model.appointmentData.StartDate;
        this.endDate = model.appointmentData.EndDate;
        this.description = model.appointmentData.RecordDescription;
        this.appointmenttype = model.appointmentData.AppointmentEventType_EnumID;
        if (this.appointEventresources.length == 1) {
           this.dxType.readOnly = true;
        }
        this.Subject = model.appointmentData.LookupValue;
        this.recordID = model.appointmentData.RecordID;
        this.Link = model.appointmentData.Url;
    this.isCustomPopupVisible = true; 

  }

  makePopupReadonly(){
    this.isCustomPopupVisible = true;
    this.dxType.readOnly= true;
    this.dxStartDate.readOnly= true;
    this.dxEndDate.readOnly= true;
    this.dxAppointmentSubject.readOnly=true;
    this.dxDescription.readOnly =true;
    this.dxAppointmentLink.readOnly =true;
    this.isLinkVisible=false;  
  }

  makePopupInEditMode(){
    this.dxType.readOnly= false;
    this.dxStartDate.readOnly= false;
    this.dxEndDate.readOnly= false;
    this.dxAppointmentSubject.readOnly=false;
    this.dxDescription.readOnly =false;
    this.dxAppointmentLink.readOnly =false;
    this.isLinkVisible=true;  
  }


}
