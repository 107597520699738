import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DocumentModel } from 'src/app/models/DocumentModel';
import { LocalStorageService } from '../localStorage/local-storage.service';
import { EnumHelper } from 'src/app/util/enum-helper';
import { OfflineDataHelperService } from '../offlineDataHelper/offline-data-helper.service';
import { ContactDocumentImageModel } from 'src/app/models/ContactDocumentImageModel';
@Injectable({
  providedIn: 'root'
})
export class UserIconImageService {
  constructor(private httpClient: HttpClient, private localStorageService:LocalStorageService, private offlineDataHelper: OfflineDataHelperService) { 
    this.prepareContactProfileURLs();
  }
  profileDocumentImagesDataSource:string = "ProfileDocumentImage";
  cacheSize: number = 10000;
  userImageDict: Map<string, string> = new Map<string, string>();
  
  getUserIcon(e: any, userID: string, userName: string) {
    let element  = this.getProfileIconImage(userID, userName);
    e.cellElement.innerHTML = ""; 
    e.cellElement.appendChild(element);
  }

  getProfileIconImage(userID:string,userName:string, iconCSSClass:string= 'grid-user-img', initialCSSClass:string="grid-user-icons") {
      if(this.userImageDict.has(userID)) {
          var div = document.createElement('div');
          div.className = iconCSSClass;
          div.innerHTML = '<img src="' + this.userImageDict.get(userID) + '">';
          return div;
      }
      else  {
        return this.getInitials(userName, initialCSSClass);
      }
  }

  getInitials(username: string, initialCSSClass:string="grid-user-icons"): HTMLDivElement {
    let strInitials: string = "";
    var div = document.createElement('div');
    if (username?.length > 1) {
      let arrName: string[] = username.split(" ");
      if (arrName.length > 1 && arrName[1]) {
        strInitials = (arrName[0][0] + arrName[1][0]).toUpperCase();
      }
      div.className = initialCSSClass;
      div.innerText = strInitials;
    }
    return div;
  }

  getInitialsString(username: string): string {
    let strInitials: string = "";
    if (username?.length > 1) {
      let arrName: string[] = username.split(" ");
      if (arrName.length > 1 && arrName[1]) {
        strInitials = (arrName[0][0] + arrName[1][0]).toUpperCase();
      }
    }
    return strInitials;
  }

  getProfileIconImageURL(userID:string)  {
    if(this.userImageDict.has(userID)) {
      return this.userImageDict.get(userID);
    }
    else {
      return '';
    }
  }

  isUserImageAvailable(userID):boolean {
    return this.userImageDict.has(userID);
  }

  getUserIConORImageHTML(userID: string, userName: string) {
      if(this.userImageDict.has(userID)) {
        return this.userImageDict.get(userID);
      }
      else {
        return this.getInitialsString(userName)
      }
  }

  getContactProfileDocumentImages() {
    this.httpClient.get<Array<ContactDocumentImageModel>>(environment.apiUrl + "api/Document/GetContactProfileDocumentImages")
      .subscribe((response:Array<ContactDocumentImageModel>) => {
        if(response && response!=null) {
            this.offlineDataHelper.bulkInsertData(this.profileDocumentImagesDataSource, response, 10000);
          }
        });
  }

  prepareContactProfileURLs() {
    this.offlineDataHelper.getGridDataViaGridID(this.profileDocumentImagesDataSource).then((response:any)=> {
      if(response && response!=null && response.data!=null && response.data.length) {
        response.data.forEach((dataRecord: ContactDocumentImageModel) => {
          var url = environment.azureStorageURL + 'Thumbnails/' + dataRecord.RecordID + '_' + dataRecord.Path.split('.').slice(0, -1).join('.') + '-s.jpg';
          this.userImageDict.set(dataRecord.ContactID, url)
        });
      }
    });
  }
}