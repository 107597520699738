import { ErrorHandler, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, NgFor, registerLocaleData } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './Components/login/login.component';
import { NotFoundComponent } from './Components/not-found/not-found.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { AuthIntercepetor } from './services/auth/auth.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { IonicModule } from '@ionic/angular';
import { RegisterComponent } from './Components/register/register.component';
import { DevExtremeModule, DxButtonModule, DxListModule, DxMenuModule, DxSpeedDialActionModule } from 'devextreme-angular';
import { HeaderComponent } from './Components/common/header/header.component';
import { GolbalExceptionHandlerService } from './services/globalExceptionHandler/golbal-exception-handler.service';
import { FooterComponent } from './Components/common/footer/footer.component';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeEnGB from '@angular/common/locales/en-GB';
import localeEnGBExtra from '@angular/common/locales/extra/en-GB';
import { GridComponent } from './Components/common/grid/grid.component';
import { PeopleComponent } from './Components/people/people.component';
import { JobComponent } from './Components/stat/job/job.component';
import { JobDetailsComponent } from './Components/stat/job-details/job-details.component';
import { BreadcrumbComponent } from './Components/common/breadcrumb/breadcrumb.component';
import { CalendarRosterComponent } from './Components/calendar-roster/calendar-roster.component';
import { PeopleDetailsComponent } from './Components/people-details/people-details.component';
import { ChatComponent } from './Components/common/chat/chat.component';
import { NotificationStatusCarouselComponent } from './Components/common/notification-status-carousel/notification-status-carousel.component';
import { ShiftCarouselComponent } from './Components/common/shift-carousel/shift-carousel.component';
import { BigButtonComponent } from './Components/common/big-button/big-button.component';
import { LobasEventQueueService } from './services/lobasEventQueueService/lobas.event.queueservice';
import { TesterComponent } from './Components/common/tester/tester.component';
import { UserAccountDetailsComponent } from './Components/common/user-account-details/user-account-details.component';
import { GridMenuOptionPopupComponent } from './Components/common/grid-menu-option-popup/grid-menu-option-popup.component';
import { HtmlEditorComponent } from './Components/common/html-editor/html-editor.component';
import { ChangePasswordComponent } from './Components/common/change-password/change-password.component';
import { ResetPasswordComponent } from './Components/reset-password/reset-password.component';
import { CardSliderComponent } from './Components/common/card-slider/card-slider.component';
import { ValidationPopupComponent } from './Components/common/validation-popup/validation-popup.component';
import { ConnectionStateService } from './services/offlineDataHelper/connnectionState.service';
import { EmailVerificationComponent } from './Components/email-verification/email-verification.component';
import { FormsModule } from '@angular/forms';
import { MapComponent } from './Components/common/map/map.component';
import { LoaderComponent } from './Components/common/loader/loader.component';
import { OfflineWarrningComponent } from './Components/common/offline-warrning/offline-warrning.component';
import { RibbonControlComponent } from './Components/common/ribbon-control/ribbon-control.component';
import { LocationSelectorComponent } from './Components/common/location-selector/location-selector.component';
import { CompanyComponent } from './Components/company/company.component';
import { CompanyDetailsComponent } from './Components/company-details/company-details.component';
import { DocumentComponent } from './Components/document/document.component';
import { DocumentDetailsComponent } from './Components/document-details/document-details.component';
import { AppointmentDetailsComponent } from './Components/appointment-details/appointment-details.component';
import { AppointmentComponent } from './Components/appointment/appointment.component';
import { NotificationDetailsComponent } from './Components/notification-details/notification-details.component';
import { NotificationComponent } from './Components/notification/notification.component';
import { QuestionnaireDetailsComponent } from './Components/questionnaire-details/questionnaire-details.component';
import { QuestionnaireComponent } from './Components/questionnaire/questionnaire.component';
import { QuestionnaireResponseDetailsComponent } from './Components/questionnaire-response-details/questionnaire-response-details.component';
import { QuestionnaireResponseComponent } from './Components/questionnaire-response/questionnaire-response.component';
import { WebpartplaceholderComponent } from './Components/common/webpartplaceholder/webpartplaceholder.component';
import { AddressComponent } from './Components/address/address.component';
import { AddressDetailsComponent } from './Components/address-details/address-details.component';
import { ShiftComponent } from './Components/stat/shift/shift.component';
import { ShiftDetailsComponent } from './Components/stat/shift-details/shift-details.component';
import { AssetComponent } from './Components/stat/asset/asset.component';
import { assetdetailcomponent } from './Components/stat/asset/detail/asset/asset-detail.component';
import { CloseCallDetailsComponent } from './Components/stat/close-call-details/close-call-details.component';
import { CloseCallComponent } from './Components/stat/close-call/close-call.component';
import { DocumentAssestsViewerDetailComponent } from './Components/common/document-assests-viewer-detail/document-assests-viewer-detail.component';
import { DocumentAssestsViewerPopupComponent } from './Components/common/document-assests-viewer-popup/document-assests-viewer-popup.component';
import { ExternalReferencesComponent } from './Components/common/external-references/external-references.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgImageSliderModule } from 'ng-image-slider';
import { TeamsWidgetComponent } from './Components/stat/teams-widget/teams-widget.component';
import { HomeComponent } from './Components/home/home.component';
import { SecurityQuestionsComponent } from './Components/common/security-questions/security-questions.component';
import { DocumentUploadPopupComponent } from './Components/common/document-upload-popup/document-upload-popup.component';
import { UnauthorisedComponent } from './Components/common/unauthorised/unauthorised.component';
import { SignalRService } from './services/signalr-service/signalr-service.service';
import { NotificationRecipientDocumentComponent } from './Components/notification-recipient-document/notification-recipient-document.component';
import { LogOutComponent } from './Components/log-out/log-out.component';
import { RecordSearchComponent } from './Components/common/record-search/record-search.component';
import { NotificationViewComponent } from './Components/notification-view/notification-view.component';
import { SchedulerComponent } from './Components/common/scheduler/scheduler.component'
import { ContactDetailsPopupComponent } from './Components/common/contact-details-popup/contact-details-popup.component';
import { ContactDetailsService } from './services/contact-details/contact-details.service';
import { CloseCallDashboardComponent } from './Components/stat/close-call-dashboard/close-call-dashboard.component';
import { SurveyModule } from "survey-angular-ui";
import { QuestionnaireResponseEditorComponent } from './Components/questionnaire-response-editor/questionnaire-response-editor.component';
import { AssistanceOncallComponent } from './Components/common/assistance-oncall/assistance-oncall.component';
import { SecurityQuestionsPopupComponent } from './Components/common/security-questions-popup/security-questions-popup.component';
import { TimeRecordingComponent } from './Components/common/time-recording/time-recording.component';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { DEFAULT_PSM_OPTIONS } from 'angular-password-strength-meter/zxcvbn';
import { NotificationRecipientDetailsComponent } from './Components/notification-recipient-details/notification-recipient-details.component';
import { QuestionnaireResponseViewerComponent } from './Components/questionnaire-response-viewer/questionnaire-response-viewer.component';
import { DefaultAddressesComponent } from './Components/common/default-addresses/default-addresses.component';
import { SendCloseCallReportPopupComponent } from './Components/common/send-close-call-report-popup/send-close-call-report-popup.component';
import { SendEmailComponent } from './Components/common/send-email/send-email.component';
import { ProjectComponent } from './Components/admin/Maintenance/project/project.component';
import { AboutComponent } from './Components/about/about.component';
import { TimeSeriesComponent } from './Components/admin/Maintenance/time-series/time-series.component';
import { TradeComponent } from './Components/config/trade/trade.component';
import { TradeDetailsComponent } from './Components/config/trade-details/trade-details.component';
import { RecordRelationshipBindingToolComponent } from './Components/common/record-relationship-binding-tool/record-relationship-binding-tool.component';
import { contactgridcomponent } from './Components/Lobas/contact/grid/contact/contact-grid.component';
import { documentcontactgridcomponent } from './Components/Lobas/contact/grid/documentcontact/document-contact-grid.component';
import { contactdetailcomponent } from './Components/Lobas/contact/detail/contact/contact-detail.component';
import { inactivecontactgridcomponent } from './Components/Lobas/contact/grid/inactivecontact/inactive-contact-grid.component';
import { usertradecontactgridcomponent } from './Components/Lobas/contact/grid/usertradecontact/user-trade-contact-grid-generated.component';
import { notificationcontactgridcomponent } from './Components/Lobas/contact/grid/notificationcontact/notification-contact-grid-generated.component';
import { testerdetailcomponent } from './Components/Test/tester/detail/tester/tester-detail.component'; 
import { testergridcomponent } from './Components/Test/tester/grid/tester/tester-grid.component';
import { DblclkColumninfoPopupComponent } from './Components/common/dblclk-columninfo-popup/dblclk-columninfo-popup.component'; 
import { assettestgridcomponent } from './Components/stat/assettest/grid/assettest/assettest-grid.component';
import { DistanceCalculationEngineComponent } from './Components/stat/distance-calculation-engine/distance-calculation-engine.component';
import { DistanceCalculationEngineHeaderComponent } from './Components/stat/distance-calculation-engine-header/distance-calculation-engine-header.component';
import { assettestdetailcomponent } from './Components/stat/assettest/detail/assettest/assettest-detail.component'; 
import { assettestrelateditemsgridcomponent } from './Components/stat/assettest/grid/assettestrelateditems/assettest-related-items-grid.component';
import { assettestbylogingridcomponent } from './Components/stat/assettest/grid/assettestbylogin/assettest-by-login-grid.component';
import { AssettestListPageComponent } from './Components/stat/assettest/custom/assettest-list-page/assettest-list-page.component';
import { DistanceManagerComponent } from './Components/distance-manager/distance-manager.component';
import { AddressPopupComponent } from './Components/common/address-popup/address-popup.component';
import { workitemdetailcomponent } from './Components/stat/workitem/detail/workitem/workitem-detail.component';
import { workitemgridcomponent } from './Components/stat/workitem/grid/workitem/workitem-grid.component';
import { taxrategridcomponent } from './Components/Lobas/taxrate/grid/taxrate/taxrate-grid.component';
import { taxratedetailcomponent } from './Components/Lobas/taxrate/detail/taxrate/taxrate-detail.component';
import { APP_BASE_HREF } from '@angular/common';
import { fatiguemetricresultsgridcomponent } from './Components/stat/fatiguemetricresults/grid/fatiguemetricresults/fatiguemetricresults-grid.component';
import { fatiguemetricresultsdetailcomponent } from './Components/stat/fatiguemetricresults/detail/fatiguemetricresults/fatiguemetricresults-detail.component';
import { assetgridcomponent } from './Components/stat/asset/grid/asset/asset-grid.component';
import { financetransactionheadergridcomponent } from './Components/stat/financetransactionheader/grid/financetransactionheader/financetransactionheader-grid.component';
import { financetransactionheaderdetailcomponent } from './Components/stat/financetransactionheader/detail/financetransactionheader/financetransactionheader-detail.component';
import { BulkEnumerationTransitionComponent } from './Components/common/bulk-enumeration-transition/bulk-enumeration-transition/bulk-enumeration-transition.component';
import { JobSetupComponent } from './Components/stat/job-setup/job-setup.component';
import { autonumbergridcomponent } from './Components/Lobas/autonumber/grid/autonumber/autonumber-grid.component';
import { autonumberdetailcomponent } from './Components/Lobas/autonumber/detail/autonumber/autonumber-detail.component';
import { ExtendedAttributesComponent } from './Components/common/extended-attributes/extended-attributes.component';
import { tokengridcomponent } from './Components/Lobas/token/grid/token/token-grid.component';
import { tokendetailcomponent } from './Components/Lobas/token/detail/token/token-detail.component';
import { sitemapgridcomponent } from './Components/Lobas/sitemap/grid/sitemap/sitemap-grid.component';
import { sitemapdetailcomponent } from './Components/Lobas/sitemap/detail/sitemap/sitemap-detail.component';
import { ecmtriggergridcomponent } from './Components/Lobas/ecmtrigger/grid/ecmtrigger/ecmtrigger-grid.component';
import { ecmtriggerdetailcomponent } from './Components/Lobas/ecmtrigger/detail/ecmtrigger/ecmtrigger-detail.component';
import { JobPlannerComponent } from './Components/stat/job-planner/job-planner.component';
import { WorkerSelectionToolComponent } from './Components/common/worker-selection-tool/worker-selection-tool.component';
import { objectgridcomponent } from './Components/Lobas/object/grid/object/object-grid.component';
import { objectdetailcomponent } from './Components/Lobas/object/detail/object/object-detail.component';
import { objectattributesgridcomponent } from './Components/Lobas/objectattributes/grid/objectattributes/objectattributes-grid.component';
import { objectattributesdetailcomponent } from './Components/Lobas/objectattributes/detail/objectattributes/objectattributes-detail.component';
import { ChangePlannedTimePopupComponent } from './Components/stat/shiftrequirement/popup/change-planned-time-popup/change-planned-time-popup.component';

import { relationshipgridcomponent } from './Components/Lobas/relationship/grid/relationship/relationship-grid.component';
import { relationshipdetailcomponent } from './Components/Lobas/relationship/detail/relationship/relationship-detail.component';
import { AssignworkitemPopupComponent } from './Components/stat/workitem/popup/assignworkitem-popup/assignworkitem-popup.component';
import { objectdatasetgridcomponent } from './Components/Lobas/objectdataset/grid/objectdataset/objectdataset-grid.component';
import { objectdatasetdetailcomponent } from './Components/Lobas/objectdataset/detail/objectdataset/objectdataset-detail.component';
import { datatransformgridcomponent } from './Components/Lobas/datatransform/grid/datatransform/datatransform-grid.component';
import { datatransformdetailcomponent } from './Components/Lobas/datatransform/detail/datatransform/datatransform-detail.component';
import { objectt4gridcomponent } from './Components/Lobas/objectt4/grid/objectt4/objectt4-grid.component';
import { objectt4detailcomponent } from './Components/Lobas/objectt4/detail/objectt4/objectt4-detail.component';
import { TabControlComponent } from './Components/common/tab-control/tab-control.component';
import { DynamicModule } from 'ng-dynamic-component';
import { objectrecordgridcomponent } from './Components/Lobas/objectrecord/grid/objectrecord/objectrecord-grid.component';
import { objectrecorddetailcomponent } from './Components/Lobas/objectrecord/detail/objectrecord/objectrecord-detail.component';
import { uilayoutgridcomponent } from './Components/Lobas/uilayout/grid/uilayout/uilayout-grid.component';
import { uilayoutdetailcomponent } from './Components/Lobas/uilayout/detail/uilayout/uilayout-detail.component';
import { uilayoutfieldgridcomponent } from './Components/Lobas/uilayoutfield/grid/uilayoutfield/uilayoutfield-grid.component';
import { uilayoutfielddetailcomponent } from './Components/Lobas/uilayoutfield/detail/uilayoutfield/uilayoutfield-detail.component';
import { objectcommandassigngridcomponent } from './Components/Lobas/objectcommand/grid/objectcommandassign/objectcommandassign-grid.component';
import { objectcommandgridcomponent } from './Components/Lobas/objectcommand/grid/objectcommand/objectcommand-grid.component';
import { objectcommanddetailcomponent } from './Components/Lobas/objectcommand/detail/objectcommand/objectcommand-detail.component';
import { uilayoutassignlistgridcomponent } from './Components/Lobas/uilayout/grid/uilayoutassignlist/uilayoutassignlist-grid.component';
registerLocaleData(localeEn, 'en');
registerLocaleData(localeDe, 'de', localeDeExtra);
registerLocaleData(localeFr, 'fr', localeFrExtra);
registerLocaleData(localeEnGB, 'en-GB', localeEnGBExtra);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');

}

@NgModule({
  declarations: [
    JobSetupComponent,
    financetransactionheadergridcomponent,
    financetransactionheaderdetailcomponent,
    taxrategridcomponent,
    taxratedetailcomponent,
    workitemdetailcomponent,
    workitemgridcomponent,
    assettestrelateditemsgridcomponent,
    assettestbylogingridcomponent,
    contactgridcomponent,
    documentcontactgridcomponent,
    contactdetailcomponent,
    inactivecontactgridcomponent,
    usertradecontactgridcomponent,
    notificationcontactgridcomponent,
    AppComponent,
    LoginComponent,
    NotFoundComponent,
    RegisterComponent,
    HeaderComponent,
    FooterComponent,
    GridComponent,
    PeopleComponent,
    JobComponent,
    JobDetailsComponent,
    BreadcrumbComponent,
    CalendarRosterComponent,
    PeopleDetailsComponent,
    ChatComponent,
    NotificationStatusCarouselComponent,
    ShiftCarouselComponent,
    BigButtonComponent,
    TesterComponent,
    UserAccountDetailsComponent,
    GridMenuOptionPopupComponent,
    HtmlEditorComponent,
    ChangePasswordComponent,
    ResetPasswordComponent,
    CardSliderComponent,
    ValidationPopupComponent,
    EmailVerificationComponent,
    MapComponent,
    LoaderComponent,
    OfflineWarrningComponent,
    RibbonControlComponent,
    LocationSelectorComponent,
    CompanyComponent,
    CompanyDetailsComponent,
    DocumentComponent,
    DocumentDetailsComponent,
    AppointmentDetailsComponent,
    AppointmentComponent,
    NotificationDetailsComponent,
    NotificationComponent,
    QuestionnaireDetailsComponent,
    QuestionnaireComponent,
    QuestionnaireResponseDetailsComponent,
    QuestionnaireResponseComponent,
    WebpartplaceholderComponent,
    AddressComponent,
    AddressDetailsComponent,
    ShiftComponent,
    ShiftDetailsComponent,
    AssetComponent,
    assettestgridcomponent,
    assettestdetailcomponent,
    AssettestListPageComponent,
    CloseCallDetailsComponent,
    CloseCallComponent,
    DocumentAssestsViewerDetailComponent,
    DocumentAssestsViewerPopupComponent,
    ExternalReferencesComponent,
    TeamsWidgetComponent,
    HomeComponent,
    SecurityQuestionsComponent,
    DocumentUploadPopupComponent,
    UnauthorisedComponent,
    NotificationRecipientDocumentComponent,
    LogOutComponent,
    RecordSearchComponent,
    NotificationViewComponent,
    SchedulerComponent,
    ContactDetailsPopupComponent,
    CloseCallDashboardComponent,
    QuestionnaireResponseEditorComponent,
    AssistanceOncallComponent,
    SecurityQuestionsPopupComponent,
    TimeRecordingComponent,
    NotificationRecipientDetailsComponent,
    QuestionnaireResponseViewerComponent,
    DefaultAddressesComponent,
    SendCloseCallReportPopupComponent,
    SendEmailComponent,
    ProjectComponent,
    AboutComponent,
    TimeSeriesComponent,
    testerdetailcomponent,
    testergridcomponent,
    TradeComponent,
    TradeDetailsComponent,
    RecordRelationshipBindingToolComponent,
    DblclkColumninfoPopupComponent,    
    RecordRelationshipBindingToolComponent,
    DistanceCalculationEngineComponent,
    DistanceCalculationEngineHeaderComponent,
    DistanceManagerComponent,
    fatiguemetricresultsgridcomponent,  
    fatiguemetricresultsdetailcomponent,
    AddressPopupComponent,
    assetgridcomponent,
    assetdetailcomponent,
    BulkEnumerationTransitionComponent,
    autonumbergridcomponent,
    autonumberdetailcomponent,
    ExtendedAttributesComponent,
    tokengridcomponent,
    tokendetailcomponent,
    sitemapgridcomponent,
    sitemapdetailcomponent,
    ecmtriggergridcomponent,
    JobPlannerComponent,
    ecmtriggerdetailcomponent,
    WorkerSelectionToolComponent,
    objectgridcomponent,
    objectdetailcomponent,
    objectattributesgridcomponent,
    objectattributesdetailcomponent,
    ChangePlannedTimePopupComponent,
    objectattributesdetailcomponent,
    relationshipgridcomponent,
    relationshipdetailcomponent,
    AssignworkitemPopupComponent,
      objectdatasetgridcomponent,
    objectdatasetdetailcomponent,
    datatransformgridcomponent,
    datatransformdetailcomponent,
    objectt4gridcomponent,
    objectt4detailcomponent,
    objectrecordgridcomponent,
    objectrecorddetailcomponent,
    objectt4detailcomponent,
    TabControlComponent,
    uilayoutgridcomponent,
    uilayoutdetailcomponent,
    uilayoutfieldgridcomponent,
    uilayoutfielddetailcomponent,
    objectcommandassigngridcomponent,
    objectcommandgridcomponent,
    objectcommanddetailcomponent,
    uilayoutassignlistgridcomponent
	],
  imports: [
    PasswordStrengthMeterModule.forRoot(DEFAULT_PSM_OPTIONS),
    NgFor,
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DevExtremeModule,
    DxMenuModule,
    DxButtonModule,
    DxListModule,
    DxSpeedDialActionModule,
    NgxDocViewerModule,
    NgImageSliderModule,
    SurveyModule,
    DynamicModule,
    ToastrModule.forRoot({
      positionClass: 'top-right',

    }),
    IonicModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    {provide: APP_BASE_HREF, useValue: '/AngularUI'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthIntercepetor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GolbalExceptionHandlerService
    }
    , LobasEventQueueService,
    ConnectionStateService,
    SignalRService,
    ContactDetailsService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(private translate: TranslateService, private connectionStateService: ConnectionStateService) {
    translate.addLangs(['en', 'de', 'fr']);
    translate.setDefaultLang('en');
    translate.use('en');
  }
}
