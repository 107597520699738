import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BrandingService } from 'src/app/services/branding/branding.service';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { EnumHelper } from 'src/app/util/enum-helper';
import { TranslateService } from '@ngx-translate/core';
import { EnumerationModel } from 'src/app/models/EnumerationModel';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpXhrBackend } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ComponentInfo } from 'src/app/models/ComponentInfo';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { catchError } from 'rxjs';
import { DeviceHelper } from 'src/app/util/device-Helper';
import { HttpHelperService } from 'src/app/services/httpHelper/http-helper.service';
import { EncryptionService } from 'src/app/encryption.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { SimplifiedSearchMode } from 'devextreme/common';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { AppEvent } from 'src/app/models/AppEvent';
import { AppEventType } from 'src/app/enums/appEventType';





@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})

export class RegisterComponent extends PageBaseService implements OnInit {
    httpClient: HttpClient = new HttpClient(new HttpXhrBackend({
        build: () => new XMLHttpRequest()
    }));
    gridInfo1 = new ComponentInfo();
    userName: string = '';
    password: string = '';
    confirmPassword: string = '';
    languageID: string = '';
    timezoneID: string = '';
    isRobot: boolean = false;
    languageList: EnumerationModel[] = [];
    timezoneList: EnumerationModel[] = [];
    bgImage: string = '';
    UserNameList: UsersList[];
    IsUserNameExists: boolean = false;
    UserNameExistsText: string = "";
    IsLinkExpired:boolean = false;
    searchModeOption:SimplifiedSearchMode = 'contains';
    searchExprOption = 'Value';  
    searchTimeoutOption = 200;  
    minSearchLengthOption = 0;  
    showDataBeforeSearchOption = false;

    constructor(private eventQueue: LobasEventQueueService,private localStorageService: LocalStorageService, private _router:Router,private _enc: EncryptionService, private route: ActivatedRoute,
        private el: ElementRef, public brandingService: BrandingService, public translate: TranslateService,
        private cookieServiceChild: CookieService,
        private alertService: AlertService, private httpHelper: HttpHelperService,private encr: EncryptionService
    ) {
        super(_router,_enc);
        this.timezoneList = this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.enumerationList, ["Time zone"]);
        this.languageList = this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.enumerationList, ["Language"]);
        this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, false));
    }
    passwordComparison = () => this.password;

    async ngOnInit() {

        if (this.timezoneList == null || this.timezoneList == undefined) {
            this.timezoneList = await this.GetEnumerationListByTypeDescription("Time Zone");
            this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.enumerationList, this.timezoneList.sort, ["Time zone"]);
        }

        if (this.languageList == null || this.languageList == undefined) {
            this.languageList = await this.GetEnumerationListByTypeDescription("Language");
            this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.enumerationList, this.languageList.sort, ["Language"]);
        }
        this.CheckIsLinkExpired();
        
    }

    Cancel = () => {
        this._router.navigateByUrl("AngularUI/login")
      }
    async CheckIsLinkExpired() {
        var contactID;
        contactID = await this.GetKeyValueFromQueryString('RecordID');
        this.UserNameList = await this.GetUserDetails();
        this.UserNameList.forEach(element => {
            if (element.UserID.toLowerCase() == contactID.toLowerCase()) {
              this.IsLinkExpired = true;
            }
          });
    }
    GetUserDetails(): Promise<UsersList[]> {
        return new Promise((resolve, reject) => {
            this.httpClient.get(environment.apiUrl + "api/Authenticate/GetUserNames")
                .subscribe((response) => {
                    resolve(<UsersList[]>response);
                });
        });
    }
    GetEnumerationListByTypeDescription(enumerationType: string): Promise<EnumerationModel[]> {
        return new Promise((resolve, reject) => {
            this.httpClient.get(environment.apiUrl + "api/EnumerationUnAuthorize/GetEnumerationListByTypeDescription/" + enumerationType)
                .subscribe((response) => {
                    resolve(<EnumerationModel[]>response);
                });
        });
    }

    onKeyDown(e:any){
        if(e.event.keyCode == 32){
              e.event.preventDefault();  
        }
    }

    handleSubmit = function (e) {
        e.preventDefault();
        var isUserNameExists = false;
        this.UserNameList.forEach(element => {
            if (element.UserName == this.userName) {
                isUserNameExists = true;
            }
        });
        if (isUserNameExists) {
            this.IsUserNameExists = true;
            this.UserNameExistsText = "Username already exists";
        }
        else {
            this.RegisterUser(this.userName, this.password, this.languageID, this.timezoneID);
        }


    }
    async RegisterUser(userName: any, password: any, languageID: any, timezoneID: any) {
        var isMobileDevice = false;
        if (DeviceHelper.isMobileDevice() || DeviceHelper.isTabletDevice()) {
            isMobileDevice = true;
        }
        var contactID, roles;
        contactID = await this.GetKeyValueFromQueryString('RecordID');
        roles = await this.GetKeyValueFromQueryString('roles');
        this.el.nativeElement.querySelector(".customloader").style = 'display:block';
        await this.httpClient.post<any>(environment.OldapiUrl + 'api/Account/RegisterUser', { userName, password, roles })
            .subscribe((response) => {

                if (response != null) {
                    var OldAspnetUserID = response;
                    var EmailAddress = "";
                    var baseUrl = window.location.origin;
                    var EmailAddressRecordID = "";
                    this.httpClient.post<any>(environment.apiUrl + 'api/Authenticate/RegisterUser/' + this.httpHelper.hostName, { userName, password, languageID, timezoneID, contactID, roles, isMobileDevice, OldAspnetUserID, EmailAddress, EmailAddressRecordID, baseUrl })
                        .subscribe((response) => {
                            this.navigateTo('/email-verification','addressid=' + response.Message);
                            this.alertService.showAlertPopup('Success', 'User registration successful.', 'fa-solid fa-check', 'OK')
                            this.el.nativeElement.querySelector(".customloader").style = 'display:none';
                            catchError((err: any) => {
                                this.el.nativeElement.querySelector(".customloader").style = 'display:none';
                                this.alertService.showAlertPopup('Error', err.error.Message, 'fa-solid fa-xmark', 'OK'); throw err
                            })
                        });
                }
                catchError((err: any) => {
                    this.el.nativeElement.querySelector(".customloader").style = 'display:none';
                    this.alertService.showAlertPopup('Error', err.error.Message, 'fa-solid fa-xmark', 'OK'); throw err

                })
            });
    }
}

export class UsersList {
    public UserName: string;
    public UserID: string;
}