<div class="webpart-card">
    <div class="webpart-cardbody" id="gridContainer">
        <div class="webpart-card-header" [ngClass]="this.isVisible ? 'webpart-card-closed' : 'webpart-card-opened'">
            <div class="webpart-card-header-title">
                <span>Notification Stats</span>
            </div>
            <div class="webpart-card-header-action">
                <dx-button (onClick)="expandCollaspeComponent()"
                    [icon]="isVisible ? 'fa-regular fa-angle-up' : 'fa-regular fa-angle-down'"></dx-button>
            </div>
        </div>

        <div [hidden]="isVisible">
            <swiper-container class="notification-swiper" pagination="true" effect="coverflow" grab-cursor="true"
                centered-slides="true" slides-per-view="auto" coverflow-effect-rotate="20" coverflow-effect-stretch="0"
                initial-slide="2" coverflow-effect-depth="260" coverflow-effect-modifier="2"
                coverflow-effect-slide-shadows="true">
                <swiper-slide>
                    <div class="swiper-slide">
                        <div class="swiper-card">
                            <i class="slider_watermark_icon fa-solid fa-file-lines"></i>
                            <i class="fa-regular fa-link slider_link_click_icon"></i>
                            <div class="notification-swiper-body">
                                <div class="notification-swiper-heading">
                                    Safety Check June 2023
                                </div>
                                <h5><span>Recipients</span><strong>40</strong></h5>
                                <h5><span>Read</span><strong>20</strong></h5>
                                <h5><span>Submitted</span><strong>0</strong></h5>
                                <h5><span>Sent</span><strong>10/08/2023</strong></h5>
                            </div>
                            <div class="notification-swiper-footer">
                                <div class="notification-swiper-progress-value">100%</div>
                                <div class="notification-swiper-progress-background" style="background: #28B463;"></div>
                                <div class="notification-swiper-progress-bar" style="width: 100%;background: #28B463;">
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="swiper-slide">
                        <div class="swiper-card">
                            <i class="slider_watermark_icon fa-solid fa-file-lines"></i>
                            <i class="fa-regular fa-link slider_link_click_icon"></i>
                            <div class="notification-swiper-body">
                                <div class="notification-swiper-heading">
                                    Safety Check June 2023
                                </div>
                                <h5><span>Recipients</span><strong>40</strong></h5>
                                <h5><span>Read</span><strong>20</strong></h5>
                                <h5><span>Submitted</span><strong>0</strong></h5>
                                <h5><span>Sent</span><strong>10/08/2023</strong></h5>
                            </div>
                            <div class="notification-swiper-footer">
                                <div class="notification-swiper-progress-value">100%</div>
                                <div class="notification-swiper-progress-background" style="background: #28B463;"></div>
                                <div class="notification-swiper-progress-bar" style="width: 100%;background: #28B463;">
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="swiper-slide">
                        <div class="swiper-card">
                            <i class="slider_watermark_icon fa-solid fa-file-lines"></i>
                            <i class="fa-regular fa-link slider_link_click_icon"></i>
                            <div class="notification-swiper-body">
                                <div class="notification-swiper-heading">
                                    Safety Check June 2023
                                </div>
                                <h5><span>Recipients</span><strong>40</strong></h5>
                                <h5><span>Read</span><strong>20</strong></h5>
                                <h5><span>Submitted</span><strong>0</strong></h5>
                                <h5><span>Sent</span><strong>10/08/2023</strong></h5>
                            </div>
                            <div class="notification-swiper-footer">
                                <div class="notification-swiper-progress-value">25%</div>
                                <div class="notification-swiper-progress-background" style="background: #28B463;"></div>
                                <div class="notification-swiper-progress-bar" style="width: 25%;background: #28B463;">
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="swiper-slide">
                        <div class="swiper-card">
                            <i class="slider_watermark_icon fa-solid fa-file-lines"></i>
                            <i class="fa-regular fa-link slider_link_click_icon"></i>
                            <div class="notification-swiper-body">
                                <div class="notification-swiper-heading">
                                    Safety Check June 2023
                                </div>
                                <h5><span>Recipients</span><strong>40</strong></h5>
                                <h5><span>Read</span><strong>20</strong></h5>
                                <h5><span>Submitted</span><strong>n/a</strong></h5>
                                <h5><span>Sent</span><strong>10/08/2023</strong></h5>
                            </div>
                            <div class="notification-swiper-footer">
                                <div class="notification-swiper-progress-value">25%</div>
                                <div class="notification-swiper-progress-background" style="background: #28B463;"></div>
                                <div class="notification-swiper-progress-bar" style="width: 25%;background: #28B463;">
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="swiper-slide">
                        <div class="swiper-card">
                            <i class="slider_watermark_icon fa-solid fa-file-lines"></i>
                            <i class="fa-regular fa-link slider_link_click_icon"></i>
                            <div class="notification-swiper-body">
                                <div class="notification-swiper-heading">
                                    Safety Check June 2023
                                </div>
                                <h5><span>Recipients</span><strong>40</strong></h5>
                                <h5><span>Read</span><strong>20</strong></h5>
                                <h5><span>Submitted</span><strong>1</strong></h5>
                                <h5><span>Sent</span><strong>10/08/2023</strong></h5>
                            </div>
                            <div class="notification-swiper-footer">
                                <div class="notification-swiper-progress-value">25%</div>
                                <div class="notification-swiper-progress-background" style="background: #28B463;"></div>
                                <div class="notification-swiper-progress-bar" style="width: 25%;background: #28B463;">
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper-container>
        </div>

    </div>
</div>