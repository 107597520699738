<app-breadcrumb></app-breadcrumb>
<div class="row webpartrow list-control">
    <div class="col-lg-12">
        <dx-tab-panel [(selectedIndex)]="tab1Index" (onTitleClick)="onTab1ItemClick($event)"  [swipeEnabled]="false" [elementAttr]="{ class: 'custom_tabpanel' }">
            <dxi-item [visible]="isMyShiftReportTabVisible" title="{{'My'}}">
                <app-grid  [gridProperties]="myShiftReportGrid" (isGridHidden)="hideMyshiftReportTab($event)" [filterExpressionsArrayByQueryString]="filterExpressionsArrayByQueryString" [IsUnauthorizedPage]="false" instanceID="myShiftReportGrid" [isRowClick]="true" (rowClick)="rowClick($event)"></app-grid>    
            </dxi-item>
            <dxi-item [visible]="isAllShiftReportTabVisible" title="{{'All'}}">
                <app-grid #allshiftreportgrid instanceID="allshiftreportgrid" (isGridHidden)="hideAllShiftReportTab($event)"
                (gridCustomActions)="gridCustomActions($event)" [isRowClick]="true" (rowClick)="rowClick($event)"></app-grid>
            </dxi-item>
            <dxi-item [visible]="isDeletedShiftReportTabVisible" title="{{'Deleted'}}">
                <app-grid instanceID="deletedshiftreportgrid" (isGridHidden)="hideDeletedShiftReportTab($event)" [isRowClick]="true" (rowClick)="rowClick($event)"></app-grid>
            </dxi-item>
        </dx-tab-panel>
    </div>
</div>