import { Injectable } from '@angular/core';
import { AlertService } from '../alertService/alert.service';
import Dexie, { Table } from 'dexie';
import { ObjectCommandModel } from 'src/app/models/ObjectCommandModel';
import { lastValueFrom } from 'rxjs';
import { Guid } from 'guid-typescript';
import { HttpClient, HttpHandler, HttpHeaders, HttpXhrBackend } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';


export interface gridRecordDB {
  data: any;
  gridID: string
  syncDate: Date
}

export interface actionQueueDB {
  RecordID: string;
  APIActionEndPoint: string;
  HttpType: string
  Data: any;
  ChangeTime: Date;
  IsConflicted: boolean;
  ObjectID:string;
}
var translateService: TranslateService
@Injectable({
  providedIn: 'root'
})


export class OfflineDataHelperService extends Dexie {
  gridRecordDB!: Table<gridRecordDB, string>;
  actionQueueDB!: Table<actionQueueDB, string>;
  isSyncExecuted: boolean = false;

  // async getRecordByRecordID(recordID:string):Promise<actionQueueDB> {
  //   var record:actionQueueDB =null;
  //   var recordCount = await db.actionQueueDB
  //     .where({
  //       RecordID: recordID,
  //     }).count();

  //     if(recordCount > 0){
  //      record = await db.actionQueueDB
  //       .where({
  //         RecordID: recordID,
  //       }).first();
  //     }      
  //     return record;
  // }

  get isOnline() {
    return window.navigator.onLine;
  }

  constructor(private translateService: TranslateService, private alertService: AlertService, private route: ActivatedRoute) {
    super('lobasDB');
    this.version(4).stores({
      gridRecordDB: 'gridID',
      actionQueueDB: 'RecordID',
    });
    this.open();
  }


  async bulkInsertData(key: string, dataItem: any, maxDataSize: number) {
    if(dataItem.then) { 
      dataItem.then((items: any) => {
        this.saveGridDataToIndexedDB(key, items.data, maxDataSize);
      });
    }
    else {
      this.saveGridDataToIndexedDB(key, dataItem, maxDataSize);
    }
  }

  async saveGridDataToIndexedDB(key: string, dataItems: any, maxDataSize: number, isInsertOperation: boolean = false) {
    this.gridRecordDB.get(key).then(existingItems => {
      if (dataItems.length === 0) {
        existingItems.syncDate = new Date();
        this.gridRecordDB.put(existingItems);
        return;
      } else {
        if (!existingItems) {
          this.gridRecordDB.add({ data: dataItems.length < maxDataSize ? dataItems : dataItems.slice(0, maxDataSize), gridID: key, syncDate: new Date() }, key);
        } else {
          dataItems.forEach((dataRecord: any) => {
            var itemToFind = existingItems.data.filter((x: any) => x.RecordID === dataRecord.RecordID);
            if (itemToFind && itemToFind.length > 0) {
              existingItems.data[existingItems.data.indexOf(itemToFind[0])] = dataRecord;
              existingItems.syncDate = new Date();
            }
            else {
              if (existingItems.data.length < maxDataSize || isInsertOperation) {
                existingItems.data.push(dataRecord);
                existingItems.syncDate = new Date();
              }
            }
          });
          existingItems.data = existingItems.data.slice(0, maxDataSize);
          this.gridRecordDB.put(existingItems);
        }
      }
    });
  }

  async getGridDataViaGridID(dbSource: string): Promise<any> {
    var localData = this.gridRecordDB.where('gridID').equals(dbSource).first();
    return localData;
  }

  async insertRecord(dbSource: string, value: any, relationshipID: string, insertCommand: ObjectCommandModel, objectID:string) {
    var recordID = Guid.create().toString();
    value.RecordID = this.route.snapshot.queryParamMap.get('newID') ?? recordID;
    value.RecordDataObjectStatus_EnumID = '00000000-0000-0001-3500-000000000002';
    this.addChangeToQueue(
      relationshipID === '' ? insertCommand.Definition.APIActionEndPoint : insertCommand.Definition.APIActionEndPointWithRelationship
      , insertCommand.Definition.HttpType
      , value
      , recordID
      , objectID
    );

    await this.gridRecordDB.get(dbSource).then(existingItems => {
      existingItems.data.unshift(value);
      this.gridRecordDB.put(existingItems);
    });
  }

  async updateRecord(dbSource: string, key: string, value: any, updateCommand: ObjectCommandModel, objectID:string) {
    await this.gridRecordDB.get(dbSource).then(async (existingItems) => {
      var itemToFind = existingItems.data.filter((x: any) => x.RecordID === key);
      if (itemToFind && itemToFind.length > 0) {
        Object.keys(value).forEach(key => {
          itemToFind[0][key] = value[key];
        });
        existingItems.data[existingItems.data.indexOf(itemToFind[0])] = itemToFind[0];
      }
      this.gridRecordDB.put(existingItems);

      var record = await this.actionQueueDB.get(key);
      if (record != null) {
        this.actionQueueDB.update(key, { Data: itemToFind[0], ChangeTime: new Date });
      }
      else {
        this.addChangeToQueue(updateCommand.Definition.APIActionEndPoint, updateCommand.Definition.HttpType, value, key, objectID);
      }
    });

  }

  async deleteRecord(dbSource: string, recordsToDelete: any, deleteCommand: ObjectCommandModel) {
    var objectID = recordsToDelete.ObjectID;

    for (const index in recordsToDelete.SelectedRecords) {
      var recordID = recordsToDelete.SelectedRecords[index];
      var queueRecord = await this.actionQueueDB.get(recordID);
      if (queueRecord) {
        switch (queueRecord.HttpType.toLowerCase()) {
          case 'post':
            await this.actionQueueDB.delete(recordID);
            break;
          case 'put':
            await this.actionQueueDB.delete(recordID);
            this.addChangeToQueue(deleteCommand.Definition.APIActionEndPoint, deleteCommand.Definition.HttpType, { ObjectID: objectID, SelectedRecords: [recordID] }, recordID, objectID);
            break;
        }
      } else {
        this.addChangeToQueue(deleteCommand.Definition.APIActionEndPoint, deleteCommand.Definition.HttpType, { ObjectID: objectID, SelectedRecords: [recordID] }, recordID, objectID);
      }
    }

    await this.gridRecordDB.get(dbSource).then(existingItems => {
      existingItems.data.forEach(record => {
        if (recordsToDelete.SelectedRecords.indexOf(record.RecordID) > -1) {
          var itemToFind = existingItems.data.filter((x: any) => x.RecordID === record.RecordID)[0];
          existingItems.data.splice(existingItems.data.indexOf(itemToFind), 1);
        }
      });
      this.gridRecordDB.put(existingItems);
    });
  }

  async getRecordByFieldName(dbSource: string, recordId: any, fieldName:string) {
     var record = await this.gridRecordDB.get(dbSource).then(existingItems => {
        var itemTofound =  existingItems.data.filter((x: any) => x[fieldName].toLowerCase() === recordId.toLowerCase());
          if(itemTofound.length>0) {
            record = itemTofound[0];
          }
      });
      return record;
  }

  async addChangeToQueue(actionEndPoint: string, httpType: string, changeData: any, key: string, objID:string) {
    var objectToAdd = { ObjectID:objID, RecordID: key, APIActionEndPoint: actionEndPoint, Data: changeData, HttpType: httpType, ChangeTime: new Date(), IsConflicted: false };
    var isKeyExist = await this.actionQueueDB.get(key);
    if (httpType.toLowerCase() === 'delete' && isKeyExist) {
      this.actionQueueDB.get(key).then((value) => {
        value.Data.SelectedRecords = value.Data.SelectedRecords.concat(changeData.SelectedRecords.filter(x => value.Data.SelectedRecords.every(y => y !== x)));
        this.actionQueueDB.put(value, key);
      }).catch((err: any) => {

        var ErrorTitle:string="";
        var OkButtonText:string="";
        this.translateService.get('ErrorTitle').subscribe((translated: string) => {
          ErrorTitle = translated;
        });
        this.translateService.get('OkButtonText').subscribe((translated: string) => {
          OkButtonText = translated;
        });
        this.alertService.showAlertPopup(ErrorTitle, err.message, 'fa-solid fa-circle-info', OkButtonText);
      });
    }
    else if (isKeyExist) {
      this.actionQueueDB.put(objectToAdd, key);
    }
    else {
      this.actionQueueDB.add(objectToAdd, key);
    }
  }
}