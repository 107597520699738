import { Component } from '@angular/core';

@Component({
  selector: 'app-assistance-oncall',
  templateUrl: './assistance-oncall.component.html',
  styleUrls: ['./assistance-oncall.component.scss']
})
export class AssistanceOncallComponent {
  isVisible: Boolean = true;
  expandCollaspeComponent() {
    this.isVisible = !this.isVisible
  }
}
