import { Component, Input, ViewChild } from '@angular/core';
import { EnumHelper } from 'src/app/util/enum-helper';
import { Guid } from 'guid-typescript';
import { NoteModel } from 'src/app/models/NoteModel';
import { DefaultAddressesComponentProperties } from 'src/app/models/GridComponentProperties';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { GridHelperService } from 'src/app/services/gridHelper/grid-helper.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/encryption.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { AddressTypes, ContactDetailsService } from 'src/app/services/contact-details/contact-details.service';
import { AddressComponent } from '../../address/address.component';
import { EnumerationHelperService } from 'src/app/services/enumerationhelper/enumeration-helper.service';
import { EnumerationModel } from 'src/app/models/EnumerationModel';
import { DxFormComponent } from 'devextreme-angular';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { OfflineDataHelperService } from 'src/app/services/offlineDataHelper/offline-data-helper.service';





@Component({
  selector: 'app-default-addresses',
  templateUrl: './default-addresses.component.html',
  styleUrls: ['./default-addresses.component.scss']
})
export class DefaultAddressesComponent extends PageBaseService {
  componentID: string = "defaultaddresses";
  @Input() recordID: string;
  @Input() instanceID: string;
  public relationshipID: string;
  AddressesProperties: DefaultAddressesComponentProperties;
  AddressType: EnumerationModel[];
  isVisible: Boolean = false;
  lstAddresses: AddressTypes[]=[];
  @ViewChild("addressForm") addressForm: DxFormComponent;
  okAlertBtnTxt: string = "";
  successAlertTitle: string = "";

  constructor(private alertService: AlertService, private enumerationHelperService: EnumerationHelperService, private contactService: ContactDetailsService, private localStorageService: LocalStorageService, public gridHelperService: GridHelperService, private _router: Router, private _enc: EncryptionService
    , public translateService: TranslateService, private offlineDataHelper: OfflineDataHelperService) {
    super(_router, _enc);
  }
  ngOnInit() {

    this.translateService.get('OkButtonText').subscribe((translated: string) => {
      this.okAlertBtnTxt = translated;
    });

    this.translateService.get('SuccessTitle').subscribe((translated: string) => {
      this.successAlertTitle = translated;
    });

    this.AddressesProperties = <DefaultAddressesComponentProperties>this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.defaultAddressesComponentProperties, [this.instanceID]) ?? new DefaultAddressesComponentProperties();
    this.getComponentProperties(false);

  }
  expandCollaspeComponent() {
    this.isVisible = !this.isVisible;
  }

  getComponentProperties(IsUnauthorized: boolean) {
    if(this.offlineDataHelper.isOnline){
     var ComponentUrl = "";
      if (IsUnauthorized) {
        ComponentUrl = "api/ComponentPropertiesUnauthorized/GetComponentPropertiesData?componentID=";
      }
      else {
        ComponentUrl = "api/ComponentProperties/GetComponentPropertiesData?componentID=";
      }
      if (this.AddressesProperties && this.AddressesProperties.RelationshipID != Guid.EMPTY) {
        this.relationshipID = this.AddressesProperties.RelationshipID;
        this.BindDetails();
  
      } else {
        this.contactService.getComponentProperties(ComponentUrl + this.componentID + "&instanceID=" + this.instanceID).then(response => {
          this.setComponentProperties(response.Properties);
          this.relationshipID = this.AddressesProperties.RelationshipID;
          this.BindDetails();
        });
      }
    }
  }
  setComponentProperties(componentProperties) {
    var gridProperties = JSON.parse(componentProperties);
    gridProperties.GridProperties.forEach(componentProperty => {
      switch (componentProperty.key.toLowerCase()) {
        case "title":
          this.AddressesProperties.Title = componentProperty.value;
          break;
        case "relationshipid":
          this.AddressesProperties.RelationshipID = componentProperty.value;
          break;
        case "componentminheight":
          this.AddressesProperties.ComponentMinHeight = componentProperty.value;
          break;
        case "componentmaxheight":
          this.AddressesProperties.ComponentMaxHeight = componentProperty.value;
          break;
        case "availableaddresses":
          this.AddressesProperties.AvailableAddresses = componentProperty.value;
          break;
        case "mode":
          this.AddressesProperties.Mode = componentProperty.value;
          break;
      }
    });
    this.localStorageService.SetLocal(EnumHelper.LocalStorageKey.gridComponentProperties, JSON.stringify(this.AddressesProperties), [this.instanceID])
  }
  BindDetails() {
    this.AddressType = <EnumerationModel[]>this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.enumerationList, ["Address Type"]);
    if (this.AddressType && this.AddressType.length > 0) {
      this.BindContactAddresses();
    }
    else {
      this.enumerationHelperService.GetEnumerationListByTypeDescription('Address Type').then(response => {
        this.AddressType = response;
        this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.enumerationList, response, ["Address Type"]);
        if (this.AddressType && this.AddressType.length > 0) {
          this.BindContactAddresses();
        }
      });
    }
  }
  BindContactAddresses() {
    this.contactService.getAddresses(this.recordID, this.AddressesProperties.RelationshipID,this.AddressesProperties.AvailableAddresses).then(response => {
      const addressTypes = this.AddressType.map(x => x.Value);
      var ValidAddressTypes = this.AddressesProperties.AvailableAddresses.split(',').filter(item =>
        addressTypes.includes(item)
      );
      ValidAddressTypes.forEach(item => {
        if (response?.filter(x => x.AddressType === item)) {
          var add = new AddressTypes;
          add.AddressType = item;
          add.Value = response?.filter(x => x.AddressType === item)[0]?.Value;
          this.lstAddresses.push(add);
        }
        else {
          var add = new AddressTypes;
          add.AddressType = item;
          add.Value = '';
          this.lstAddresses.push(add);
        }     
      });
    });
  }
  onSubmit(e) {
       this.contactService.postAddresses(this.recordID, this.AddressesProperties.RelationshipID, this.lstAddresses).then(
      response => {
        this.translateService.get('UpdateSuccessMessage').subscribe((translated: string) => {
          this.alertService.showAlertPopup(this.successAlertTitle,translated, 'fa-solid fa-check',this.okAlertBtnTxt);
        });
        
      });

  }


}

