<app-breadcrumb [ComponentObjectID]="ComponentObjectID"></app-breadcrumb>

<div class="row webpartrow">
    <div class="col-lg-6 webpartcol screen-50 m-b-30">
        <div class="webpart-card notif-rec-doc-single-notif-view">
            <div class="notif_row">
                <div class="notif_col_right">
                    <app-notification-view #notificationDetail [showDetailButton]="false"
                        [suscribeToRibbonCommandTransitionAction]="true"></app-notification-view>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-6 webpartcol screen-50">
        <app-ribbon-control #NotificationRecipientRibbonControl *ngIf="parameterSet" instanceID="NotificationRecipientDocumentRibbonControl"
            [parentRecordID]="this.recordID" [relationshipRecordID]="this.relationshipRecordID"
            [newID]="this.newID"></app-ribbon-control>
    </div>
</div>