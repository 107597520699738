import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';
import { flush } from '@angular/core/testing';
import { EventType, Router } from '@angular/router';

import { DxPopupComponent } from 'devextreme-angular';
import { DxDropDownBoxComponent, DxDropDownBoxTypes } from 'devextreme-angular/ui/drop-down-box';
import CustomStore from 'devextreme/data/custom_store';
import { lastValueFrom } from 'rxjs';
import { RecordSearchComponentProperties } from 'src/app/models/RecordSearchComponentProperties';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { EnumHelper } from 'src/app/util/enum-helper';
import { environment } from 'src/environments/environment';
import { GridComponent } from '../grid/grid.component';
import dxDropDownBox from 'devextreme/ui/drop_down_box';
import { Guid } from 'guid-typescript';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { EncryptionService } from 'src/app/encryption.service';

@Component({
  selector: 'app-record-search',
  templateUrl: './record-search.component.html',
  styleUrls: ['./record-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordSearchComponent extends PageBaseService implements OnInit{
  @ViewChild("recordSearchPopup", { static: false }) recordSearchPopup: DxPopupComponent;
  @ViewChild('ddlGrid', { static: false }) ddlGrid: GridComponent;
  @ViewChild('DDBox', { static: false }) ddBox: DxDropDownBoxComponent
  @Input() instanceID: string = '';
  @Input() ddlGridInstanceID: string = '';
  @Output() customActions = new EventEmitter<any>();
  isGridBoxOpened = false;
  componentID: string = 'recordsearch';
  displayExpr = ({ LookupValue, Description }) => LookupValue + " " + Description;

  okAlertBtnTxt: string ="";
  errorAlertTitle: string = "";
  noSelectedRecordMsg: string = "";
  componentProperties: RecordSearchComponentProperties;

  constructor( private auth:AuthService, private translateService: TranslateService, private alertService: AlertService, private ref: ChangeDetectorRef, private httpClient: HttpClient, private localStorageService: LocalStorageService,private _router: Router, private _enc: EncryptionService) {
    super(_router, _enc);
  }

  ngOnInit(): void {
    this.translateService.get('OkButtonText').subscribe((translated: string) => {
      this.okAlertBtnTxt = translated;
    });

    this.translateService.get('warningText').subscribe((translated: string) => {
      this.errorAlertTitle = translated;
    });

    this.translateService.get('RecordSearchNoSelectedRecordMsg').subscribe((translated: string) => {
      this.noSelectedRecordMsg = translated;
    });
    this.componentProperties = <RecordSearchComponentProperties>this.localStorageService.
      GetLocalStorage(EnumHelper.LocalStorageKey.recordSearchComponentProperties, [this.instanceID])
      ?? new RecordSearchComponentProperties();

    if (this.componentProperties.LabelText.length === 0) {
      this.getComponentProperties();
    }
  }

  openPopup() {
    this.recordSearchPopup.visible = true;
  }

  closePopup() {
    this.recordSearchPopup.visible = false;
    this.ddBox.value = null;
  }

  async continueAction() {

    if (this.ddBox.value == null) {
      this.alertService.showAlertPopup(this.errorAlertTitle,
        this.noSelectedRecordMsg, 'fa-solid fa-triangle-exclamation', this.okAlertBtnTxt);
      return;
    } else {
      var recordID = Guid.create().toString();
      var parentID = this.ddBox.value[0].RecordID;
      var relatedParentRecordID = await this.GetKeyValueFromQueryString("RecordID");

      if (this.componentProperties.IsCustomAction) {
        this.customActions.emit({ "RecordID": recordID, "ParentID": parentID })
      }
      else {
        this.redirectToPath(recordID, parentID, relatedParentRecordID);
      }

    }
  }

 async redirectToPath(recordID: string, parentID: string,relatedParentRecordID:string='') {
    var redirectURL: string = this.componentProperties.NavigateToURL ?? '';
    if (redirectURL.length > 0 && this.ddBox.value != null && this.ddBox.value.length > 0) {

      if (redirectURL.includes("?")) {
        redirectURL += "&" + this.componentProperties.DDLObjectName + "ID=" + parentID;
      } else {
        redirectURL += "?" + this.componentProperties.DDLObjectName + "ID=" + parentID;
      }

      if (this.componentProperties.NavigateWithNewMode) {
        if (redirectURL.includes("?")) {
          redirectURL += "&newid=" + recordID ;
        } else {
          redirectURL += "?newid=" + recordID
        }
        if (this.componentProperties.DDLObjectName.toLowerCase() == 'asset') 
          {
          if(!this.IsNullorEmpty(relatedParentRecordID))
          {
            redirectURL +=  "&RelatedParentRecordID=" + relatedParentRecordID ;
          }
          if(!this.IsNullorEmpty(this.componentProperties.RelationshipID))
          {
            redirectURL +=  "&RelationshipID="+ this.componentProperties.RelationshipID;
          }
      }
      } else {
        if (this.componentProperties.DDLObjectName.toLowerCase() === 'questionnaire') {
          if (redirectURL.includes("?")) {
            redirectURL += "&recordID=" + recordID;
          } else {
            redirectURL += "?recordID=" + recordID;
          }
        }
      }

      if (this.componentProperties.NavigateWithReturnLink) {
        if (redirectURL.includes("?")) {
          redirectURL += "&ReturnEnabled=true";
        } else {
          redirectURL += "?ReturnEnabled=true";
        }
        redirectURL+= "&ReturnURL=";
        var decryptUrl:string;
         await this.DecryptUrl(window.location.href).then(result=>
          decryptUrl=result.split('&ReturnUrlEnabled=true')[0]
        );
        redirectURL+=decryptUrl;
      }
      if(!redirectURL.includes("lobas.web/page")){
        if(redirectURL.includes("?")){
          var mainul=redirectURL.substring(0, redirectURL.indexOf("?"));
          var querystring=redirectURL.substring(redirectURL.indexOf("?")+1);
          this.navigateTo(mainul,querystring);
        }
        else{
           this._router.navigateByUrl(redirectURL);
        }
      }
      else{
        window.location.href = redirectURL;
      }
      
     
      
    }
  }

  

  rowClick(ev: any) {
    this.ddBox.dataSource = this.ddlGrid.dataSource;
    let data = ev.data;
    this.ddBox.value = [ev.data];
  }

  setValues() {
    this.ddBox.valueExpr = "RecordID";
    this.ddBox.displayExpr = this.displayExpr;
    this.ddBox.deferRendering = false;
    this.ddBox.placeholder = 'Select a value...';
    if(this.ddBox.dataSource){
      this.ddBox.dataSource = this.ddlGrid.dataSource;
    }
  }

  onGridBoxOptionChanged(e: DxDropDownBoxTypes.OptionChangedEvent) {
    if (e.name === 'value') {
      this.isGridBoxOpened = false;
      this.ref.detectChanges();
    }
  }

  onHiding() {
    this.ddBox.value = null;
  }

  getComponentProperties() {
    var ComponentUrl = "api/ComponentProperties/GetComponentPropertiesData?componentID=";
    var result = lastValueFrom(
      this.httpClient.get(environment.apiUrl + ComponentUrl + this.componentID + "&instanceID=" + this.instanceID)
    ).then((response: any) => {
      this.setComponentProperties(response.Properties);
    });
  }

  setComponentProperties(componentProperties) {
    var componentProperties = JSON.parse(componentProperties);
    componentProperties.RecordSearchProperties.forEach(componentProperty => {
      switch (componentProperty.key.toLowerCase()) {
        case "headertext":
          this.componentProperties.HeaderText = componentProperty.value;
          break;
        case "labeltext":
          this.componentProperties.LabelText = componentProperty.value;
          break;
        case "navigatetourl":
          this.componentProperties.NavigateToURL = componentProperty.value;
          break;
        case "navigatewithnewmode":
          if (componentProperty.value.toLowerCase() == "false")
            this.componentProperties.NavigateWithNewMode = false;
          else
            this.componentProperties.NavigateWithNewMode = true;
          break;
        case "navigatewithreturnlink":
          if (componentProperty.value.toLowerCase() == "false")
            this.componentProperties.NavigateWithReturnLink = false;
          else
            this.componentProperties.NavigateWithReturnLink = true;
          break;
        case "numberofsecondsfordefaultsearch":
          this.componentProperties.NumberOfSecondsForDefaultSearch = componentProperty.value;
          break;
        case "ddlobjectname":
          this.componentProperties.DDLObjectName = componentProperty.value;
          break;
        case "iscustomaction":
          this.componentProperties.IsCustomAction = componentProperty.value;
          break;
        case "relationshipid":
          this.componentProperties.RelationshipID = componentProperty.value;
      }
    });
    this.localStorageService.SetLocal(EnumHelper.LocalStorageKey.recordSearchComponentProperties, JSON.stringify(this.componentProperties), [this.instanceID]);
  }


}
