import { Injectable } from '@angular/core';
import { EnumerationModel } from 'src/app/models/EnumerationModel';
import { AlertService } from '../alertService/alert.service';
import { RecordSearchComponentProperties } from 'src/app/models/RecordSearchComponentProperties';
import { BulkTransitionComponentProperties } from 'src/app/models/BulkTransitionComponentProperties';
import { Guid } from 'guid-typescript';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { concat, forkJoin, lastValueFrom, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ResolveEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BulkTransitionService {
  _selectedRecords: string[];
  _enumerationData: { key: string, values: EnumerationModel[] }[];
  transitionAPIEndpoint: string = ''
  constructor(private alertService: AlertService, private httpClient: HttpClient) { }
  callback = null;
  successCount: number = 0;
  failedCount: number = 0;
  totalCount:number = 0;


  SetSelectedRecordsAndBindTransitionDDLs(selectedRecords: string[], enumerationData: { key: string, values: EnumerationModel[] }[]) {
    if (selectedRecords && selectedRecords.length > 0) {
      this._selectedRecords = selectedRecords;
      this._enumerationData = enumerationData;
    } else {
      this.alertService.showAlertPopup("Error", "Kindly select atleast one record", "error", "Ok");
    }
  }




  UpdateBulkTransitions(objectID: string, statusEnumerationFieldName: string, fromEnumeration: any, toEnumeration: any,
    selectedRecords: Array<any>, transitionAPIEndpoint: string, afterUpdateTransitionCompleted?): Array<Observable<string>> {
    this.successCount = 0;
    this.failedCount = 0;
    this.totalCount=0;
    this.callback = afterUpdateTransitionCompleted;
    var requests = new Array<Observable<string>>();
    selectedRecords.filter(x => x).forEach(async (currentRecord) => {
      if (currentRecord[statusEnumerationFieldName].toLowerCase() == fromEnumeration.EnumerationId.toLowerCase()) {
        this.totalCount++;
        requests.push(this.UpdateTransition(objectID, statusEnumerationFieldName, toEnumeration.EnumerationID, currentRecord.RecordID, transitionAPIEndpoint));        
      }
    });
    this.callback(this.totalCount, this.successCount, this.failedCount, false);
    forkJoin(requests).subscribe(allResults => {
      if (this.callback != null) {
        this.callback(this.totalCount, this.successCount, this.failedCount, true);
      }
    }
    );
    return requests;
  }

  UpdateTransition(objectID: string, statusEnumerationFieldName: string, toEnumerationID: string,
    recordID: string, transitionAPIEndpoint: string) {
    this.transitionAPIEndpoint = environment.apiUrl + transitionAPIEndpoint + '/' + recordID;

    var changeBody = '{"RecordID":"' + recordID + '","' + statusEnumerationFieldName + '":"' + toEnumerationID + '"}';
    if(this.transitionAPIEndpoint.toLowerCase().indexOf("updatenew") > 0){
      changeBody= JSON.parse(changeBody)
      var relationshipDict:any={};
      relationshipDict.FieldValues = changeBody;
      changeBody=relationshipDict;
    }
    return this.httpClient.put(this.transitionAPIEndpoint, changeBody,
      {
        headers: {
          'objectID': objectID
        }, responseType: 'text'
      }).pipe(tap(response => {
        if (this.callback != null) {
          this.successCount++;
          this.callback(this.totalCount, this.successCount, this.failedCount, false);
        }
      }));
  }


  BindEnumerations(bulkTransitionComponentProperties: BulkTransitionComponentProperties) { }

  BindTransitions() {
  }

}
