<app-breadcrumb></app-breadcrumb>

<div class="row webpartrow list-control list-control-withbtn">
    <div class="col-lg-12 m-b-30">
        <ng-container>
        <dx-tab-panel (onTitleClick)="onTab1ItemClick($event)"  [swipeEnabled]="false" [animationEnabled]="false" 
        [elementAttr]="{ class: 'custom_tabpanel' }">
        <dxi-item title="{{'Active'|translate}}">
            <app-grid [IsUnauthorizedPage]="false" instanceID="ProjectGrid"></app-grid>
        </dxi-item>
        <dxi-item title="{{'InActive'|translate}}">
            <app-grid [IsUnauthorizedPage]="false" instanceID="InActiveprojectgrid"></app-grid>
        </dxi-item>
        <dxi-item title="{{'Deleted'|translate}}">
            <app-grid [IsUnauthorizedPage]="false" instanceID="DeletedProjectGrid"></app-grid>
        </dxi-item>
        </dx-tab-panel>
    </ng-container>
    </div>