
//***************************This code has been generated using LOBAS Templates 4.0.23.0  

import { Component, ViewChild } from '@angular/core';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { GridComponent } from 'src/app/Components/common/grid/grid.component';
import { EncryptionService } from 'src/app/encryption.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { AssignworkitemPopupComponent } from '../../popup/assignworkitem-popup/assignworkitem-popup.component';

@Component({
  selector: 'app-workitem-detail',
  templateUrl: './workitem-detail.component.html',
  styleUrls: ['./workitem-detail.component.scss']
})
export class workitemdetailcomponent extends PageBaseService{
  @ViewChild('WorkItemPopup' ) workItemPopup: AssignworkitemPopupComponent
  @ViewChild('relatedWorkItem' ) relatedWorkItem: GridComponent
  componentObjectID: string;
  
  
  constructor(private eventQueue: LobasEventQueueService,private _router: Router, private _enc: EncryptionService, private alertService: AlertService, private translateService: TranslateService) {
    super(_router, _enc);
    this.componentObjectID = "9579d64b-3e97-4ac9-a255-8357bd44d157";
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }

  gridCustomActions(data: any) {
    switch (data.CommandObject.Command.toLowerCase()) {
      case "cancel":
      case "cancelall":
          try {
            var redirectURL = data.CommandObject.Definition?.VisibilityOption[0]?.RedirectURL ?? "";
            if (redirectURL.length === 0) {
              redirectURL = this._router.getCurrentNavigation().previousNavigation.finalUrl.toString();
            }
            this._router.navigateByUrl(redirectURL);
          } catch (Ex) {
            this._router.navigateByUrl('app-home');
          }
          break;
      case "assignworkitems":
        this.workItemPopup.openPopup();
        break;
      default:
        this.translateService.get('CustomActionYetToImplemented', { title: data.CommandObject.Name }).subscribe((text: string) => {
          this.alertService.showAlertPopup(this.translateService.instant('InfoTitle'), text,
            'info', this.translateService.instant('OkButtonText'));
        });
        break;
    }
  }

  refreshGrid(){
    this.relatedWorkItem.dataGrid.instance.refresh();
  }
}

