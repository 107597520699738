import { Component } from '@angular/core';

@Component({
  selector: 'app-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss']
})
export class HtmlEditorComponent {
  isVisible: Boolean = false;
  expandCollaspeComponent() {
    this.isVisible = !this.isVisible;
  }
}
