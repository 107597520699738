import { Component, Input } from '@angular/core';
declare function CreateLocationSelectorObject(txtLocationGoogleApi, txtWhatThreeWordLocation, AutoSuggestID, isNavigateOnChangeLocation, Control): any;
@Component({
  selector: 'app-location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.scss']
})
export class LocationSelectorComponent {

  @Input() ControlName: string;
  @Input() isNavigateOnChangeLocation: string = 'false';
  txtLocationGoogleApi: string = '';
  AutoSuggestID: string = '';
  txtWhatThreeWordLocation: string = '';
  icrosshairs: string = '';
  ngOnInit() {
    this.UpdateControlIDs();
    
  }

  UpdateControlIDs() {
    this.txtLocationGoogleApi = 'txtLocationGoogleApi_' + this.ControlName;
    this.AutoSuggestID = 'AutoSuggestID_' + this.ControlName;
    this.txtWhatThreeWordLocation = 'txtWhatThreeWordLocation_' + this.ControlName;
    this.icrosshairs = 'icrosshairs_' + this.ControlName;
  }

  ngAfterViewInit() {
    CreateLocationSelectorObject(this.txtLocationGoogleApi, this.txtWhatThreeWordLocation, this.AutoSuggestID, this.isNavigateOnChangeLocation, this.icrosshairs);
  }
}


