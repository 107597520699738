<div class="webpart-card">
    <div class="webpart-cardbody" id="gridContainer">
        <div>
            <!-- Swiper -->
            <div class="shift-swiper swiper mySwiperr">
                <div class="swiper-wrapper" >
                    <div class="swiper-slide" *ngFor="let shiftObj of shiftCarouselDataset">
                        <div class="swiper-card" [ngStyle]="{'border-color': shiftObj.EnumStatusColour}" (click)="cardClick(shiftObj)">
                            <div class="shift-swiper-heading">
                                <span>{{shiftObj.ShiftAppointmentSubject}}</span>
                            </div>
                            <div class="shift-swiper-body">
                                <p>{{DateFormat(shiftObj.StartDate)}}</p>
                                <p>{{DateFormat(shiftObj.EndDate)}}</p>
                                <p>{{shiftObj.CustomerName}}</p>
                            </div>
                            <div class="shift-swiper-footer" [ngStyle]="{'background-color': shiftObj.EnumStatusColour}" >
                                {{shiftObj.ShiftAppointmentResourceRelationStatus}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-pagination"></div>
            </div>
        </div>
    </div>
</div>