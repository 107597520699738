import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/encryption.service';
import { CookieService } from 'ngx-cookie-service';
import { Guid } from 'guid-typescript';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';

@Component({
  selector: 'app-job-setup',
  templateUrl: './job-setup.component.html',
  styleUrls: ['./job-setup.component.scss']
})

export class JobSetupComponent extends PageBaseService{
  ComponentObjectID: string;
  bigbuttonRedirectUrl: string;
  recordID: string = Guid.EMPTY;
  relationshipRecordID: string = Guid.EMPTY;
  parameterSet: Boolean = false;
  newID: string = Guid.EMPTY;
  loggedinUserID: string = ''
  constructor(private _router: Router, private _enc: EncryptionService,private eventQueue: LobasEventQueueService, private translateService: TranslateService, private alertService: AlertService, private auth: AuthService) {
    super(_router, _enc);
    this.loggedinUserID = this.auth.getGuidUserID() ?? '';
    this.ComponentObjectID = '5EF72874-C6A0-4B0B-8FA1-B69335C783E3';
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }
  ngOnInit() {
    this.getAllQueryStrings();
  }
  getAllQueryStrings() {
    this.GetKeyValueFromQueryString('recordID').then((recordID => {
      this.recordID = recordID;
      this.GetKeyValueFromQueryString('relationshipRecordID').then((relationshipRecordID => {
        this.relationshipRecordID = relationshipRecordID;
        this.GetKeyValueFromQueryString('newID').then((newID => {
          this.newID = newID;
          this.GetKeyValueFromQueryString('newID').then((newID => {
            this.newID = newID;
            this.parameterSet = true;
          }))
        }))
      }));
    }));
  }
  async gridCustomActions(data: any) {
    switch (data.CommandObject.Command.toLowerCase()) {
      default:
        this.translateService.get('CustomActionYetToImplemented', { title: data.CommandObject.Name }).subscribe((text: string) => {
          this.alertService.showAlertPopup(this.translateService.instant('InfoTitle'), text,
            'fa-solid fa-circle-info', this.translateService.instant('OkButtonText'));
        });
        break;
    }
  }
  
}



