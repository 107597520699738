<app-breadcrumb></app-breadcrumb>

<div class="row webpartrow notification_component list-control" [ngClass]="this.isViewMobile? 'mobile-noti-mode': ''">
    <div class="col-lg-12">
        <dx-tab-panel [swipeEnabled]="false" [(selectedIndex)]="tab1Index" (onTitleClick)="onTab1ItemClick($event)"  [animationEnabled]="false" [elementAttr]="{ class: 'custom_tabpanel' }">
            <dxi-item title="Unread">

                <div class="webpart-card" [ngClass]="this.isVisible ? 'wbprt-clsd' : ''">
                    <div class="webpart-cardbody-notif">
                        <div class="webpart-card-header" [hidden]="!isViewMobile"
                            [ngClass]="this.isVisible ? 'webpart-card-closed' : 'webpart-card-opened'">
                            <div class="webpart-card-header-title">
                                <div class="not_det_heading">Details</div>
                            </div>
                            <div class="webpart-card-header-action">
                                <dx-button [elementAttr]="{ class: 'hide_dsk'}" hint="Close" (onClick)="closeDetailComponent()"
                                    icon="fa-regular fa-xmark"></dx-button>
                            </div>
                        </div>
                        <div [hidden]="isVisible">
                            <div class="notif_row">
                                <div class="notif_col_left">
                                    <app-grid #grdMyNotifications_Unread [IsUnauthorizedPage]="false" [isGridview]="false" [applyAdaptivePriority]="false"
                                        (gridCustomActions)="NotificationgridCustomActions($event)"
                                        instanceID="MyNotifications_Unread" [externalItemTemplate]="customItemTemplate"
                                        templateName="externalItemTemplate"></app-grid>
                                </div>
                                <div class="notif_col_right">
                                    <app-notification-view #detailUnread></app-notification-view>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </dxi-item>
            <dxi-item title="Outstanding">
                <div class="webpart-card" [ngClass]="this.isVisible2 ? 'wbprt-clsd' : ''">
                    <div class="webpart-cardbody-notif">
                        <div class="webpart-card-header"   [hidden]="!isViewMobile"
                            [ngClass]="this.isVisible2 ? 'webpart-card-closed' : 'webpart-card-opened'">
                            <div class="webpart-card-header-action">
                                <dx-button [elementAttr]="{ class: 'hide_dsk'}" hint="Close" (onClick)="closeDetailComponent2()"
                                    icon="fa-regular fa-xmark"></dx-button>
                            </div>
                        </div>

                        <div [hidden]="isVisible2">
                            <div class="notif_row">
                                <div class="notif_col_left">
                                    <app-grid #grdMyNotifications_Outstanding [IsUnauthorizedPage]="false" [isGridview]="false" [applyAdaptivePriority]="false"
                                        (gridCustomActions)="NotificationgridCustomActions($event)"
                                        instanceID="MyNotifications_Outstanding"
                                        [externalItemTemplate]="customItemTemplate"
                                        templateName="externalItemTemplate"></app-grid>
                                </div>
                                <div class="notif_col_right">
                                    <app-notification-view #detailOutstanding></app-notification-view>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </dxi-item>
            <dxi-item title="All">
                <div class="webpart-card" [ngClass]="this.isVisible3 ? 'wbprt-clsd' : ''">
                    <div class="webpart-cardbody-notif">
                        <div class="webpart-card-header"   [hidden]="!isViewMobile"
                            [ngClass]="this.isVisible3 ? 'webpart-card-closed' : 'webpart-card-opened'">
                            
                            <div class="webpart-card-header-action" >
                                <dx-button [elementAttr]="{ class: 'hide_dsk'}" hint="Close" (onClick)="closeDetailComponent3()"
                                    icon="fa-regular fa-xmark"></dx-button>
                            </div>
                        </div>

                        <div [hidden]="isVisible3">
                            <div class="notif_row">
                                <div class="notif_col_left">
                                    <app-grid #grdMyNotifications_All [IsUnauthorizedPage]="false" [isGridview]="false" [applyAdaptivePriority]="false"
                                        (gridCustomActions)="NotificationgridCustomActions($event)"
                                        instanceID="MyNotifications_All" [externalItemTemplate]="customItemTemplate"
                                        templateName="externalItemTemplate"></app-grid>
                                </div>
                                <div class="notif_col_right">
                                    <app-notification-view #detailAll></app-notification-view>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </dxi-item>
        </dx-tab-panel>
    </div>
</div>

<ng-template #customItemTemplate let-item="item" let-listCardColumn="listCardColumn"
    let-burgerMenuActions="burgerMenuActions" let-gridProperties="gridProperties" let-isGridview="isGridview"
    let-burgerMenuPossiton="burgerMenuPossiton">
    <div class="notification_list_box_col" (click)="onCellClick($event,item)">

        <div class="notification_head"
            [ngClass]="item.data['NotificationStatus_EnumID'] === '00000000-0000-0001-4100-000000000006' || item.data['NotificationStatus_EnumID'] === '00000000-0000-0001-4100-000000000004' ? 'notification-bg' : '' ">
            <div class="notification_head_left" [innerHTML]="getUserImage(item)">                
            </div>
            <div class="notification_head_right">
                <div class="notification_head_right_top">
                    <div class="notification_username">
                        {{formatData(item.data["FromUsersName"], 'varchar') }}
                    </div>
                    <div class="notification_time">
                        {{formatData(item.data["RecordCreatedDate"], 'Datetime') }}
                    </div>
                </div>
                <div class="notification_title">
                    {{formatData(item.data["Subject"], 'varchar') }}
                </div>
                <div class="notification_description" [innerHTML]="item.data['MessagePayload']">                    
                </div>
            </div>
        </div>
    </div>
</ng-template>