import { Component } from '@angular/core';

@Component({
  selector: 'app-distance-calculation-engine-header',
  templateUrl: './distance-calculation-engine-header.component.html',
  styleUrls: ['./distance-calculation-engine-header.component.scss']
})
export class DistanceCalculationEngineHeaderComponent {

}
