import { HttpClient, HttpXhrBackend } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { catchError } from 'rxjs';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { BrandingService } from 'src/app/services/branding/branding.service';
import { environment } from 'src/environments/environment';
import { FormsModule } from '@angular/forms';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { EncryptionService } from 'src/app/encryption.service';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent extends PageBaseService {
  @ViewChild('OTP1') OPT1Element: ElementRef;
  @ViewChild('OTP2') OPT2Element: ElementRef;
  @ViewChild('OTP3') OPT3Element: ElementRef;
  @ViewChild('OTP4') OPT4Element: ElementRef;
  @ViewChild('OTP5') OPT5Element: ElementRef;
  @ViewChild('OTP6') OPT6Element: ElementRef;
  httpClient: HttpClient = new HttpClient(new HttpXhrBackend({
    build: () => new XMLHttpRequest()
  }));
  isRobot: boolean = false;
  IsEmailVerification: boolean = false;
  IsLinkUsed: boolean = false;
  userEmailVerification: UserEmailVerification;
  message: string = '';
  messageHeader: string = '';
  VerificationCode: string = '';
  EmailVerificationContactID: string = '';
  EmailVerificationUserName: string = '';
  EmailVerificationEmailAddress: string = '';
  isRobotValidation: boolean = false;
  OTP1: string = "";
  OTP2: string = "";
  OTP3: string = "";
  OTP4: string = "";
  OTP5: string = "";
  OTP6: string = "";
  constructor(private eventQueue: LobasEventQueueService,public brandingService: BrandingService, private _router: Router, private _enc: EncryptionService, private route: ActivatedRoute,
    private alertService: AlertService, public cookieService: CookieService) {
    super(_router, _enc);
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, false));
  }

  async ngOnInit() {
    this.userEmailVerification = await this.GetUserDetails();

    this.VerificationCode = this.userEmailVerification.VerificationCode;
    this.EmailVerificationContactID = this.userEmailVerification.ContactID;
    this.EmailVerificationUserName = this.userEmailVerification.Username;
    this.EmailVerificationEmailAddress = this.userEmailVerification.EmailAddress;
    if (this.userEmailVerification.IsUserApproved) {
      this.IsLinkUsed = true;
      this.IsEmailVerification = false;
      this.message = "This link has already been used to register a user. Please contact support.";
      this.messageHeader = "statrack";
    }
    else {
      this.IsLinkUsed = false;
      this.IsEmailVerification = true;
    }

  }

  GetUserDetails(): Promise<UserEmailVerification> {
    return new Promise(async (resolve, reject) => {
      var addressID;
      addressID = await this.GetKeyValueFromQueryString('addressid')
      this.httpClient.get(environment.apiUrl + "api/Authenticate/GetUserDetailsForEmailVerification/" + addressID)
        .subscribe((response) => {
          resolve(<UserEmailVerification>response);
        });
    });
  }

  async SendNewCode() {    
    if (this.isRobot) {
      var addressID;
      addressID = await this.GetKeyValueFromQueryString('addressid');
      var Username = this.EmailVerificationUserName;
      var UserEmail = this.EmailVerificationEmailAddress;
      var baseUrl = window.location.origin;
      await this.httpClient.post<any>(environment.apiUrl + 'api/Authenticate/SendNewVerificationCode', { addressID, Username, UserEmail, baseUrl })
        .subscribe((response) => {
          if (response != null) {
            this.IsLinkUsed = true;
            this.IsEmailVerification = false;
            this.message = "A new Verfication Code has been sent";
            this.messageHeader = "Success";
          }
          catchError((err: any) => { this.alertService.showAlertPopup('Error', err.error.Message, 'fa-solid fa-xmark', 'OK'); throw err })
        });
    }
    else {
      this.isRobotValidation = true;
    }
  }

  onSubmit = function (event) {
    event.preventDefault();
    if (this.isRobot) {
      var UserInputverificationCode = "";
      if (this.OTP1 != null && this.OTP2 != null && this.OTP3 != null && this.OTP4 != null && this.OTP5 != null && this.OTP6 != null) {
        UserInputverificationCode = this.OTP1 + this.OTP2 + this.OTP3 + this.OTP4 + this.OTP5 + this.OTP6;
        this.Verify(this.VerificationCode, UserInputverificationCode);
      }
    }
    else {
      this.isRobotValidation = true;
    }
  }


  onValueChanged(event) {
    if (!this.isRobot) {
      this.isRobotValidation = true;
    }
    else{
      this.isRobotValidation = false;
    }
  }

  Cancel() {
    this._router.navigateByUrl("AngularUI/login")
  }


  async Verify(VerificationCode, UserInputverificationCode) {

    if (VerificationCode == UserInputverificationCode) {
      var addressID;
      addressID = await this.GetKeyValueFromQueryString('addressid');
      var contactRecordID = this.EmailVerificationContactID;
      var Username = this.EmailVerificationUserName;
      await this.httpClient.post<any>(environment.OldapiUrl + 'api/Account/ApproveUserAfterEmailVerification', { Username })
        .subscribe((response) => {
          if (response != null) {
            this.httpClient.post<any>(environment.apiUrl + 'api/Authenticate/ApproveUserAfterEmailVerification/', { contactRecordID, addressID, Username })
              .subscribe((response) => {
                this.IsLinkUsed = true;
                this.IsEmailVerification = false;
                this.message = "You have successfully verified your details and you can login now.";
                this.messageHeader = "Account Verified";
                catchError((err: any) => { this.alertService.showAlertPopup('Error', err.error.Message, 'fa-solid fa-xmark', 'OK'); throw err })
              });
          }
          catchError((err: any) => { this.alertService.showAlertPopup('Error', err.error.Message, 'fa-solid fa-xmark', 'OK'); throw err })
        });
    }
    else {
      this.isRobot = false;
      this.alertService.showAlertPopup('Error', "Entered verification code did not match. Kindly try again or re-generate Code.", 'fa-solid fa-xmark', 'OK')
    }
  }



  OTP1keyup(event: any) {
    if (event.target.value.length == 1) {
      this.OPT2Element.nativeElement.focus();
      this.OTP1 = event.target.value;
    }
    else {
      this.OTP1 = "";
    }
  }

  OTP2keyup(event: any) {
    if (event.target.value.length == 1) {
      this.OPT3Element.nativeElement.focus();
      this.OTP2 = event.target.value;
    }
    else {
      this.OPT1Element.nativeElement.focus();
      this.OTP2 = "";
    }
  }

  OTP3keyup(event: any) {
    if (event.target.value.length == 1) {
      this.OPT4Element.nativeElement.focus();
      this.OTP3 = event.target.value;
    }
    else {
      this.OPT2Element.nativeElement.focus();
      this.OTP3 = "";
    }
  }

  OTP4keyup(event: any) {
    if (event.target.value.length == 1) {
      this.OPT5Element.nativeElement.focus();
      this.OTP4 = event.target.value;
    }
    else {
      this.OPT3Element.nativeElement.focus();
      this.OTP4 = "";
    }
  }
  OTP5keyup(event: any) {
    if (event.target.value.length == 1) {
      this.OPT6Element.nativeElement.focus();
      this.OTP5 = event.target.value;
    }
    else {
      this.OPT4Element.nativeElement.focus();
      this.OTP5 = "";
    }
  }

  OTP6keyup(event: any) {
    if (event.target.value.length == 1) {
      this.OTP6 = event.target.value;
    }
    else {
      this.OPT5Element.nativeElement.focus();
      this.OTP6 = "";
    }
  }
}

export class UserEmailVerification {
  public Username: string;
  public VerificationCode: string;
  public IsUserApproved: boolean;
  public EmailAddress: string;
  public ContactID: string;
}

