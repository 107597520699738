import { Component } from '@angular/core';

@Component({
  selector: 'app-distance-manager',
  templateUrl: './distance-manager.component.html',
  styleUrls: ['./distance-manager.component.scss']
})
export class DistanceManagerComponent {

}
