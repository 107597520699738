<app-breadcrumb></app-breadcrumb>
<div class="row">
    <div class="col-lg-12 list-control list-control-withbtn">
        <dx-tab-panel [selectedIndex]="this.tab1Index" (onTitleClick)="onTab1ItemClick($event)"  [swipeEnabled]="false" [elementAttr]="{ class: 'custom_tabpanel' }">
            <dxi-item [visible]="hideTabByUserRole" title="Calendar">
                <app-scheduler #Scheduler></app-scheduler>
            </dxi-item>
            <dxi-item title="Working Elsewhere">
                <app-grid #myWorkingElsewhereGridCalendar [IsUnauthorizedPage]="false" [parentRecordId]="this.userID"
                    instanceID="myWorkingElsewhereGridCalendar"></app-grid>
            </dxi-item>
        </dx-tab-panel>
    </div>
</div>