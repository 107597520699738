import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import dxDataGrid from 'devextreme/ui/data_grid';
import { EncryptionService } from 'src/app/encryption.service';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { EnumHelper } from 'src/app/util/enum-helper';
import { GridComponent } from '../common/grid/grid.component';
import { SchedulerComponent } from '../common/scheduler/scheduler.component';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent extends PageBaseService {
  isVisible: Boolean = false;
  userID: string = '';
  hideTabByUserRole: boolean = true;
  tab1Index: number = 0;
  @ViewChild("myWorkingElsewhereGridCalendar", { static: true }) dataGrid!: GridComponent
  @ViewChild("Scheduler", { static: true }) scheduler!: SchedulerComponent
  
  constructor(private eventQueue: LobasEventQueueService, private auth: AuthService, private localStorageService: LocalStorageService, private _router: Router, private _enc: EncryptionService) {
    super(_router, _enc);
    this.userID = this.auth.getGuidUserID() ?? '';
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }
  async ngOnInit() {
    var userRoles = <string[]>JSON.parse(this.localStorageService.GetLocal(EnumHelper.LocalStorageKey.userRoles));
    if (userRoles.length == 1 && userRoles[0].toLowerCase() === 'user') {
      this.hideTabByUserRole = false;
      this.tab1Index = 1;
    } else {
      this.tab1Index = this.GetTabIndex("tab1Index", this.localStorageService);
    }
  }
  onTab1ItemClick(ev: any) {
    this.SetTabIndex("tab1Index", ev?.itemIndex, this.localStorageService);
    if(ev.itemData.title.toLowerCase() == 'working elsewhere'){
      var inst = this.dataGrid
      this.dataGrid.dataGrid.instance.refresh();
    }else if(ev.itemData.title.toLowerCase() == 'calendar'){
      this.scheduler.ngOnInit();
    }
  }

  expandCollaspeComponent() {
    this.isVisible = !this.isVisible;
  }
}