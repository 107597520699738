<dx-popup #DocumentUploadPopupUp [wrapperAttr]="{class: 'lobas-popup lobas-form-popup' }" [showTitle]="true"
    title="{{this.title}}" [resizeEnabled]="true" [dragEnabled]="false" [hideOnOutsideClick]="false"
    [showCloseButton]="true">

    <form id="UploadDocumentForm" method="post">
        <dx-validation-group #UploadDocumentFormValidationGroup>

        <div class="form-row">
            <div class="field_group" *ngIf="this.ShowName">
                <div class="field_label required-label-asterisk">Name</div>
                <div class="field_control">
                    <dx-text-box [(value)]="name">
                        <dx-validator >
                            <dxi-validation-rule  type="required" message="Name is required"></dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
            </div>
            <div class="field_group" *ngIf="this.ShowRecordStatus">
                <div class="field_label required-label-asterisk">Record Status</div>
                <div class="field_control">
                    <dx-select-box displayExpr="Value" valueExpr="EnumerationId" [dataSource]="recordStatusList"
                        [inputAttr]="{ 'aria-label': 'EnumerationId' }" [(value)]="recordDataObjectStatus_EnumID"
                        [(text)]="txtrecordDataObjectStatus_EnumID" [searchMode]="searchModeOption"
                        [searchExpr]="searchExprOption" [searchTimeout]="searchTimeoutOption"
                        [minSearchLength]="minSearchLengthOption" [searchEnabled]="true"
                        [showDataBeforeSearch]="showDataBeforeSearchOption">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Status is required"></dxi-validation-rule>
                        </dx-validator>
                    </dx-select-box>
                </div>
            </div>
            <div class="field_group" *ngIf="this.ShowVersion">
                <div class="field_label required-label-asterisk">Version</div>
                <div class="field_control">
                    <dx-text-box [(value)]="version">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Version is required"></dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
            </div>
            <div class="field_group" *ngIf="ShowType">
                <div class="field_label required-label-asterisk">Type</div>
                <div class="field_control">
                    <dx-select-box displayExpr="Value" valueExpr="EnumerationId"
                        [dataSource]="documentTypeDataSourceList" [inputAttr]="{ 'aria-label': 'EnumerationId' }"
                        [(value)]="ddType_EnumID" [searchMode]="searchModeOption" [searchExpr]="searchExprOption"
                        [searchTimeout]="searchTimeoutOption" [minSearchLength]="minSearchLengthOption"
                        [searchEnabled]="true" [showDataBeforeSearch]="showDataBeforeSearchOption"
                        (onSelectionChanged)="selectionChanged($event)">
                        <dx-validator>
                            <dxi-validation-rule type="required"
                                message="Document Type is required"></dxi-validation-rule>
                        </dx-validator>
                    </dx-select-box>
                </div>
            </div>
            <div class="field_group" *ngIf="this.ShowUploadFile">
                <div class="field_label required-label-asterisk">Select</div>
                <div class="field_control file_uploader_custom_control">
                    <dx-file-uploader #fileUploader (onValueChanged)="onValueChanged($event)" [readOnly]="this.fileReadOnlyMode ? true:false" name='myFile'
                        uploadUrl="{{this.docSaveApiUrl}}" [multiple]="false" accept="*" uploadMode="instantly"
                        selectButtonText="Select file" selectButtonIcon="fa-home"
                        uploadedMessage="File uploaded successfully." [maxFileSize]="maxFileSize"
                        (onUploadError)="onUploadError($event)" (onInitialized)="($event)"
                        [allowedFileExtensions]="this.allowedFileExtensions" [minFileSize]="1024"
                        allowCanceling:true
                        [(value)]="documentValue" invalidFileExtensionMessage="{{invalidFileExtensionMessage}}"
                        invalidMaxFileSizeMessage="{{invalidMaxFileSizeMessage}}"
                        invalidMinFileSizeMessage="{{invalidMinFileSizeMessage}}"
                        (onUploaded)="onUploadedCompleted($event)" (onValueChanged)="addParameter($event)"
                        (onUploadStarted)="onUploadStarted($event)"
                        >
                    </dx-file-uploader>
                    <span *ngIf="this.isEditpopup">{{documentUploadModel.Path}}</span>
                </div>
            </div>
            <div class="field_group" *ngIf="this.ShowDescription">
                <div class="field_label required-label-asterisk">Description</div>
                <div class="field_control">
                    
                    <dx-text-area [height]="90" [(value)]="description" 
                    [inputAttr]="{ 'aria-label': 'Notes' }">
                    <dx-validator>
                        <dxi-validation-rule type="required"
                            message="Description is required"></dxi-validation-rule>
                    </dx-validator>
                </dx-text-area>
                </div>
            </div>
           
            
            <div class="field_group" *ngIf="this.ShowValidFrom">
                <div class="field_label">Valid From</div>
                <div class="field_control">

                    <dx-date-box [value]="validFrom" type="datetime" [inputAttr]="{ 'aria-label': 'Date And Time' }"
                        (onValueChanged)="validFromValueChanged($event)"></dx-date-box>
                </div>
            </div>
            <div class="field_group" *ngIf="this.ShowValidTo">
                <div class="field_label">Valid To</div>
                <div class="field_control">
                    <dx-date-box (onValueChanged)="validToValueChanged($event)" [value]="validTo" type="datetime"
                        [inputAttr]="{ 'aria-label': 'Date And Time' }"></dx-date-box>
                </div>
            </div>
            <div class="field_group " *ngIf="this.ShowMarkASPublic">
                <div class="field_label">Mark As Public</div>
                <div class="field_control">
                    <dx-check-box [(value)]="markAsPublic"></dx-check-box>
                </div>
            </div>
        </div>
    <div class="form-btn-outer">
        <dx-button id="btnCancel" (onClick)="Cancel($event)" text="Cancel">
        </dx-button>
        <dx-button #btnSave id="btnSave" (onClick)="Save($event)" text="Save">
        </dx-button>
    </div>
    </dx-validation-group>
    </form>

</dx-popup>
<app-validation-popup #documentUploadValidationpopup></app-validation-popup>