import { Component, Input,ViewChild } from '@angular/core';
import { Model } from "survey-core";
import { QuestionnaireResponseService } from 'src/app/services/questionnaire-response/questionnaire-response.service';
import { QuestionnaireResponseWithRelatedItemsModel } from 'src/app/models/QuestionnaireResponseWithRelatedItemsModel';
import { DxPopupComponent } from 'devextreme-angular';



@Component({
  selector: 'app-questionnaire-response-viewer',
  templateUrl: './questionnaire-response-viewer.component.html',
  styleUrls: ['./questionnaire-response-viewer.component.scss']
})
export class QuestionnaireResponseViewerComponent {
  @Input() recordID;

  surveyModel: Model;
  questionnaireHelper: QuestionnaireResponseService;
  questionnaireResponse: QuestionnaireResponseWithRelatedItemsModel;
  survey: any;
  isVisible: boolean = false;
  isFullscreenWebpart: boolean = false
  isVisibledivInfoIcon = true;
  QuestionnaireResponseStatus: string;
  @ViewChild("questionnaireResponseViewerpopup", { static: false }) questionnaireResponseViewerpopup: DxPopupComponent
  constructor(public questionnaireResponseService: QuestionnaireResponseService,) {
    this.questionnaireHelper = questionnaireResponseService;
    this.questionnaireResponse = new QuestionnaireResponseWithRelatedItemsModel;
  }

  Bindpopup() {
    this.questionnaireResponseService.GetQuestionnaireRespons(this.recordID).then(QuestionnaireResponse => {
      this.questionnaireResponse = QuestionnaireResponse;
      this.questionnaireResponseService.ReplaceJsonVariables(this.questionnaireResponse).then(questions => {
          this.questionnaireResponseService.createSurveyModal(this.recordID, questions, this.questionnaireResponse.JSON, 'view', this.QuestionnaireResponseStatus).then(survey => {
            this.surveyModel = survey;
            this.questionnaireResponseViewerpopup.visible=true;
        });
      });
    });
  }
  showPopup(selectedrecordId:string) { 
    this.recordID =selectedrecordId;
    this.Bindpopup();
 }
}
