export class EnumerationModel {
    public EnumerationId: string;
    public EnumerationTypeId: string;
    public ExtendedAttributes: string;
    public Value: string;
    public Description: string;
    public DisplayOrder: number | null;
    public CreatedDate: Date;
    public Created_UserID: string;
    public UpdatedDate: Date | null;
    public Updated_UserID: string | null;
    public Active: boolean;
    public Icon: string;
    public Token: string | null;
    public Colour: string;
}