import { Component, Input, Output, EventEmitter, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ComponentInfo } from 'src/app/models/ComponentInfo';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { Router } from '@angular/router';
import { Guid } from 'guid-typescript';
import { CookieService } from 'ngx-cookie-service';
import { EncryptionService } from 'src/app/encryption.service';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { DocumentAssestsViewerPopupComponent } from '../../common/document-assests-viewer-popup/document-assests-viewer-popup.component';
import { DocumentUploadPopupComponent } from '../../common/document-upload-popup/document-upload-popup.component';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { AppEvent } from 'src/app/models/AppEvent';
import { AppEventType } from 'src/app/enums/appEventType';
import { GridComponent } from '../../common/grid/grid.component';
import { MapComponent } from '../../common/map/map.component';
import { ObjectRecordModel } from 'src/app/models/ObjectRecordModel';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { EnumHelper } from 'src/app/util/enum-helper';
import { CloseCall } from 'src/app/models/CloseCall';
import { ObjectCommandModel } from 'src/app/models/ObjectCommandModel';
import { Subscription } from 'rxjs';
import { SendCloseCallReportPopupComponent } from '../../common/send-close-call-report-popup/send-close-call-report-popup.component';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { ComponentSettingsModel } from 'src/app/models/ComponentSettingsModel';

@Component({
  selector: 'app-close-call-details',
  templateUrl: './close-call-details.component.html',
  styleUrls: ['./close-call-details.component.scss']
})
export class CloseCallDetailsComponent extends PageBaseService implements OnInit, OnDestroy {
  @ViewChild("closecallDocViewPopup", { static: false }) closecallDocViewPopup: DocumentAssestsViewerPopupComponent
  @ViewChild("closecallDocUploadPopup", { static: false }) closecallDocUploadPopup: DocumentUploadPopupComponent
  @ViewChild("grdCloseCallDocument", { static: true }) grdCloseCallDocument: GridComponent;
  @ViewChild("closecallDetailMap", { static: false }) closecallDetailMap: MapComponent;
  @ViewChild("grdCloseCallDetails", { static: false }) grdCloseCallDetails: GridComponent
  @ViewChild("closeCallReport", { static: false }) closeCallReport: SendCloseCallReportPopupComponent
  
  isCustomOnInit:boolean=false;
  jobStartDate:Date= new Date();
  isVisible: boolean = false;
  recordID: string = Guid.EMPTY;
  relationshipRecordID: string = Guid.EMPTY;
  newID: string = Guid.EMPTY;
  parameterSet: Boolean = false;
  closecalldetails = new ComponentInfo();
  ComponentObjectID: string;
  RelationshipID: string;
  jobID: string = Guid.EMPTY;
  loggedinUserID: string = Guid.EMPTY;
  IsEditMode: boolean = true;
  bigbuttonRedirectUrl: string;
  isSetUrl: boolean = false;
  ribbonSubscription: Subscription;
  performRibbonCommandOperation: Subscription;
  NotificationId: string;
  okAlertBtnTxt: string = "";
  successAlertTitle: string = "";

  constructor(private localStorageService: LocalStorageService, private auth: AuthService, private httpClient: HttpClient, private eventQueue: LobasEventQueueService, private translateService: TranslateService, private alertService: AlertService, private location: Location, private _router: Router, private _enc: EncryptionService, private cookieService: CookieService,private notificationService: NotificationService) {
    super(_router, _enc);
    this.ComponentObjectID = '256EE4E0-3B90-43D7-A6C2-CD6C89ABA647';
    this.loggedinUserID = this.auth.getGuidUserID() ?? '';
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
    this.eventQueue.on(AppEventType.ActionUpdateSuccess).subscribe(event => this.RefreshDocumentControls(event.payload));
    this.closecallDetailsPageURL().then((response) => {
      this.bigbuttonRedirectUrl = response;
      this.isSetUrl = true;
    });
  }
  RefreshDocumentControls(payload: any): void {
    this.grdCloseCallDocument.dataGrid.instance.refresh();
  }

  ngOnInit() {
    this.translateService.get('OkButtonText').subscribe((translated: string) => {
      this.okAlertBtnTxt = translated;
    });

    this.translateService.get('SuccessTitle').subscribe((translated: string) => {
      this.successAlertTitle = translated;
    });

    this.getAllQueryStrings();
    this.ribbonSubscription =  this.eventQueue.on(AppEventType.RibbonTransitionClick).
      subscribe(event => this.onTransitionBtnClick(event.payload));

    this.performRibbonCommandOperation = this.eventQueue.on(AppEventType.PerformRibbonCommandOperation).
      subscribe(event => this.onRibbonCommandClick(event.payload));
  }

  ngOnDestroy() {
    if(this.ribbonSubscription) {
      this.ribbonSubscription.unsubscribe();
    }
    if(this.performRibbonCommandOperation) {
      this.performRibbonCommandOperation.unsubscribe();
    }
  }


  async validateChildControls() {
    var isValid: Boolean = false;
    return new Promise<Boolean>(async (resolve, reject) => {
      isValid = await this.grdCloseCallDetails?.isValidFormData();
      resolve(isValid);
      reject(false);
    });
  }


  async onRibbonCommandClick(command: ObjectCommandModel) {
    var isValid: Boolean = true;
    if (command.Definition.PerformValidations) {
      isValid = await this.validateChildControls();
    }
    if (isValid) {
      this.closecallDetailMap.onSaveAllClicked().then(response => {
        this.grdCloseCallDetails.commandAction(command).then(response => {
          if (response === true) {
            this.alertService.showAlertPopup(this.successAlertTitle,
              this.translateService.instant('UpdateSuccessMessage'), 'fa-solid fa-check',
              this.okAlertBtnTxt);
          }
        });
      });
    }
  }
  

  async onTransitionBtnClick(data: any) {
    var isValid = await this.validateChildControls();
    if (isValid) {
      this.closecallDetailMap.onSaveAllClicked().then(response => {
        this.grdCloseCallDetails.onRibbonTransitionClick(data).then(response => {
          if (response === true) {
            this.alertService.showAlertPopup(this.successAlertTitle,
              this.translateService.instant('UpdateSuccessMessage'), 'fa-solid fa-check',
              this.okAlertBtnTxt);
          }
        });
      });  
    }
  }

  async closecallDetailsPageURL() {
    var recordID = await this.GetKeyValueFromQueryString('RecordID');
    return "/Lobas.Web/Pages/CloseCall/CloseCallDetails.aspx?RecordID=" + recordID;
  }
  expandCollaspeComponent() {
    this.isVisible = !this.isVisible;
  }
  getAllQueryStrings() {
    this.GetKeyValueFromQueryString('recordID').then((recordID => {
      this.recordID = recordID;

      this.GetKeyValueFromQueryString('NRID').then((notificationId => {
        this.NotificationId = notificationId;
        if(this.NotificationId != "" && this.NotificationId != '' && this.NotificationId != null&& this.NotificationId != undefined ){
          this.notificationService.notificationUserAuthorised(this.NotificationId,this.loggedinUserID);
        }
      }));

      this.GetKeyValueFromQueryString('relationshipRecordID').then((relationshipRecordID => {
        this.relationshipRecordID = relationshipRecordID;
        this.GetKeyValueFromQueryString('newID').then((newID => {
          this.newID = newID;
          this.GetKeyValueFromQueryString('jobID').then((JobID => {
            if (JobID && JobID.length > 0 && JobID != Guid.EMPTY) {
              this.jobID = JobID;
              this.parameterSet = true;
              this.setComponentMode();
            }
            else {
              this.getJobIDFromRelationship();
            }
          }))
        }))
      }));
    }));
  }
  getJobIDFromRelationship() {
    let RelationshipID = 'E6AE7763-8657-4E5D-A897-3EC770DD2889';
    this.httpClient.get<ObjectRecordModel>(environment.apiUrl + 'api/ObjectRecord/GetRelatedRecordsByRecordId/' + this.ComponentObjectID + '/' + RelationshipID + '/' + this.recordID)
      .subscribe((response: ObjectRecordModel) => {
        if (response && response[0]?.RecordID) {
          this.jobID = response[0]?.RecordID;
        }
        this.parameterSet = true;
        this.setComponentMode();

      });
  }
  getRecordID(): string {
    return this.recordID !== Guid.EMPTY && this.recordID.length > 0 ? this.recordID : this.newID;
  }
  setComponentMode() {
    var roles = this.localStorageService.GetLocal(EnumHelper.LocalStorageKey.userRoles);
    if (this.recordID !== Guid.EMPTY && this.recordID.length > 0 && !roles.includes('Close Call Manager')) {
      this.httpClient.get<CloseCall>(environment.apiUrl + "api/CloseCall/Get/" + this.recordID)
        .subscribe((response: CloseCall) => {
          if (response && response.CloseCallStatus_EnumID
            && response.CloseCallStatus_EnumID.toLowerCase() !== '461cf8dc-b359-4b17-925b-68734d6315f0'.toLowerCase()
            && response.CloseCallStatus_EnumID.toLowerCase() !== 'DEBCBB23-362B-4BDD-BE91-ADB62C552143'.toLowerCase()) {
            this.IsEditMode = false;
            this.grdCloseCallDetails.ChangeModeOfControls("View");
          }
        });
    }
   else if(this.jobID !==Guid.EMPTY && this.jobID.length>0)
    {
      this.GetCustomJob(this.jobID);
      this.isCustomOnInit = true;
    }
  }
CustomOnInitNewRow(eventData: { event: any,  ComponentSettings: ComponentSettingsModel  })
  {
    //alert(1);
    eventData.ComponentSettings.UIFields.forEach(col => {
      if (col.Definition.DefaultValue && col.Definition.DefaultValue.length > 0) {
        if(col.FieldName.toUpperCase() == "EVENTDATETIME" && this.jobStartDate){          
          eventData.event.data[col.FieldName] = (this.jobStartDate).toString();
        }
        else{
          eventData.event.data[col.FieldName] = col.Definition.DefaultValue;
        }
      }
    });
  }
  GetCustomJob( recordID: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.apiUrl + 'api/Job/GetCustomModel/' + recordID )
        .subscribe((response:any) => {
          
          if(response.StartDate)
          {
            this.jobStartDate = response.StartDate;
          }
          
          resolve(<any>response);
        });
    });
  }
  async gridCustomActions(data: any) {
    switch (data.CommandObject.Command.toLowerCase()) {
      case "cancel":
      case "cancelall":
        try {
          var redirectURL = await this.GetReturnURL();
          if(this.IsNullorEmpty(redirectURL))
          {          
            redirectURL = data.CommandObject.Definition?.VisibilityOption[0]?.RedirectURL ?? "";
            if (redirectURL.length === 0) {
              redirectURL = this._router.getCurrentNavigation().previousNavigation.finalUrl.toString();
            }      
            this._router.navigateByUrl(redirectURL);    
          }
          else
          {
            // window.location.href =redirectURL;
            if(redirectURL.includes("/") && redirectURL.includes("AngularUI")) 
          {
            
            var url =/[^/]*$/.exec(redirectURL)[0]
            this._router.navigateByUrl(url);
          }   
        }  
            
          } catch (Ex) {
            this._router.navigateByUrl('app-home');
          }
          break;
      case "sendclosecallreport":
        if(this.recordID)
        {
          this.closeCallReport.showPopup(this.recordID)
        }          
        break;
      default:
        this.translateService.get('CustomActionYetToImplemented', { title: data.CommandObject.Name }).subscribe((text: string) => {

          var InfoTitle:string="";
          this.translateService.get('InfoTitle').subscribe((translated: string) => {
            InfoTitle = translated;
          });
          var OkButtonText:string="";
          this.translateService.get('OkButtonText').subscribe((translated: string) => {
            OkButtonText = translated;
          });
          this.alertService.showAlertPopup(InfoTitle, text,'fa-solid fa-circle-info', OkButtonText);
        });
        break;
    }

  }

  DocumentgridCustomActions(data: any) {
    if (data != undefined && data != null) {
      switch (data.CommandObject.Command.toLowerCase()) {
        case "view":
        case "viewdocument":
          this.closecallDocViewPopup.showDocumentAssetViewerPopup(true, data.SelectedRecords[0], "viewDocument", null);
          break;
        case "uploadpopup":
          if (data.CommandObject.Name.toUpperCase() == "EDIT") {
            this.closecallDocUploadPopup.OpenUploadPopup(data.SelectedRecords[0], "editpopup", this.grdCloseCallDocument, this.closecallDetailMap);
          }
          else {
            this.closecallDocUploadPopup.OpenUploadPopup(null, "newpopup", this.grdCloseCallDocument, this.closecallDetailMap);
          }
          break;
        case "deleteupload":
          var RelationshipID = "";
          if (this.grdCloseCallDocument.gridProperties != null && this.grdCloseCallDocument.gridProperties != undefined) {
            RelationshipID = this.grdCloseCallDocument.gridProperties.RelationshipID;
          }
          this.closecallDocUploadPopup.DeleteUploadDocument(data.SelectedRecords[0], RelationshipID, this.grdCloseCallDocument);
          break;
        case "setpinfromimage":
          this.closecallDocUploadPopup.SetImageLocation(data.SelectedRecords[0], this.closecallDetailMap);
          break;
        case "downloaddocument":
          this.closecallDocUploadPopup.downloadFile(data.SelectedRecords[0]);
          break;
      }
    }

  }

}
