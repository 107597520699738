import { AppEventType } from '../enums/appEventType';
import { FieldSettings } from './FieldSettings';


export class AddressPopUpComponentProperties {
    Title: string = ''
    AllowedRoles:string[] = []
    RelationshipID:string = ''
    HideWebPartHeader: boolean = false
    BindOnRelationshipOnly:string="false"
    FieldSettings: FieldSettings 
    AllowedAddressTypes:string=''
}

export class GetContactAddressByAddressType {
  AddressByAddressType: string = null;
}
export class AddressModel {
  RecordID: string = null;
  AddressType_EnumID: string = null;
  AddressPurpose_EnumID: string = null;
  W3W: string = '';
  PostalCode: string = '';
  CountryCode_EnumID: string = null;
  AddressData: string = '';
  Latitude: string = '';
  Longitude: string = '';
  VerificationCode: string = null;
  Default: boolean = null;
  ExtendedAttributes: string = '';
  RecordCreatedDate: Date = null;
  RecordUpdatedDate: Date = null;
  RecordUpdated_UserID: string = null;
  RecordCreated_UserID: string = null;
  CreatedUserIcon: string = '';
  UpdatedUserIcon: string = '';
  CreatedUsersName: string = '';
  UpdatedUsersName: string = '';
  RecordTenancyID: string = null;
  RecordLookupValue: string = '';
  RecordDescription: string = '';
  RecordDataObjectStatus_EnumID: string = null;
}

export class MapLocationImageModel 
{
  ParentRecordID: string = '';
  RelationshipRecordID : string='';
  Lat: string = '';
  Lng: string = '';
  Zoom: string = '';
}