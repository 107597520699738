import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { DxoPopupComponent } from 'devextreme-angular/ui/nested';
import { ContactDetailsService, ContactDetails } from 'src/app/services/contact-details/contact-details.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact-details-popup',
  templateUrl: './contact-details-popup.component.html',
  styleUrls: ['./contact-details-popup.component.scss']
})
export class ContactDetailsPopupComponent {
  popupVisible: boolean = false;
  contactDetails: ContactDetails;
  positionOf: string;
  UserInitials: string = "";
  @ViewChild("contactDetailsPopup", { static: false }) contactDetailsPopup: DxoPopupComponent


  constructor(private contactDetailsService: ContactDetailsService) {
    this.contactDetails = new ContactDetails();
  }
  showPopup(contactId: string, position: string) {
    if (contactId && contactId.length > 0) {
      this.positionOf = position;
      this.loadContactDetails(contactId);
      document.getElementsByClassName('content-wrapper')[0].classList.add("overflow_none");
    }

  }

  popup_hiding(e) {
    document.getElementsByClassName('content-wrapper')[0].classList.remove("overflow_none");
  }
  closePopup(): void {
    this.contactDetailsPopup.visible = false;
  }

  private loadContactDetails(contactId: string): void {
    this.contactDetailsService.getContactDetails(contactId).then(
      (data: ContactDetails) => {
        this.contactDetails = data;
        this.UserInitials = this.getInitials(this.contactDetails.UserName).toString();
        this.contactDetailsPopup.visible = true;
      },
      (error) => {
        console.error('Failed to fetch contact details:', error);
      }
    );
  }

  getImagePath(): string {
    return environment.azureStorageURL + 'Thumbnails/' + this.contactDetails.RecordID + '_' + this.contactDetails?.Path.split('.').slice(0, -1).join('.') + '-s.jpg';
  }
  getInitials(username: string): String {
    let strInitials: string = "";
    if (username.length > 1) {
      let arrName: string[] = username.split(" ");
      if (arrName.length > 1 && arrName[1]) {
        strInitials = (arrName[0][0] + arrName[1][0]).toUpperCase();
      }
    }
    return strInitials;
  }

}