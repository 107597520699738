<!-- <app-breadcrumb ></app-breadcrumb> -->

<div class="m-b-30">
    <div class="row">
        <div class="col-lg-12">
            <app-shift-carousel></app-shift-carousel>
        </div>
    </div>
</div>
<!-- <div class="m-b-30">
    <div class="row">
        <div class="col-lg-12">
            <app-notification-status-carousel></app-notification-status-carousel>
        </div>
    </div>
</div> -->
<div class="m-b-30">
    <div class="row webpartrow">
        <div class="col-lg-6 webpartcol screen-50">
            <div class="m-b-30">
                <app-security-questions></app-security-questions>
            </div>
            <div class="m-b-30">
                <app-map mapDivId="MapView" name="Location" mode="view"></app-map>
            </div>
            <div class="m-b-30">
                <app-change-password></app-change-password>
            </div>

        </div>
        <div class="col-lg-6 webpartcol screen-50">
            <div class="m-b-30">
                <app-user-account-details></app-user-account-details>
            </div>
            <div class="m-b-30">
                <app-html-editor></app-html-editor>
            </div>
            <div class="m-b-30">
                <app-chat [recordID]="this.loggedinUserID" [loggedinUserID]=this.loggedinUserID instanceID='contactDetails_Notes'></app-chat>
            </div>
            <div class="m-b-30">
                <app-big-button></app-big-button>
                <app-card-slider></app-card-slider>
            </div>
        </div>
    </div>
</div>