
<!-- ***************************This code has been generated using LOBAS Templates 4.0.23.0  -->

<app-breadcrumb [ComponentObjectID]="componentObjectID"></app-breadcrumb>

<div class="row webpartrow">
    <div class="col-lg-12 webpartcol screen-100 m-b-30">
        <app-grid #workitemDetail (gridCustomActions)="gridCustomActions($event)" [IsUnauthorizedPage]="false"
        instanceID="workitemDetail"></app-grid>
    </div>
    <div class="col-lg-12 webpartcol screen-100">
        <app-grid #relatedWorkItem (gridCustomActions)="gridCustomActions($event)" [IsUnauthorizedPage]="false"
        instanceID="workItemsDetails_RelatedWorkItemGrid"></app-grid>
    </div>
    <app-assignworkitem-popup #WorkItemPopup instanceID="assignWorkitemPopup"  (GridRefreshAction)="refreshGrid()"></app-assignworkitem-popup>
</div>