import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ObjectService {

  constructor(private httpClient: HttpClient) { }


  getObjectDataFromRecordID(recordID:any){
      return this.httpClient.get(environment.apiUrl + "api/Object/Get/" + recordID);
  }

  getExportedData(objectName:any, schema:any,objectID:any,exportData:any){
    return this.httpClient.get(environment.apiUrl + "api/Object/Export/" + objectName+'/'+schema+'/'+objectID+'/'+exportData);
  }
}
