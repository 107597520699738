import { Component, ViewChild } from '@angular/core';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { RecordRelationshipBindingToolComponent } from '../../common/record-relationship-binding-tool/record-relationship-binding-tool.component';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { GridComponent } from '../../common/grid/grid.component';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/encryption.service';
import { RelationshipRecordModel } from 'src/app/models/RelationshipRecordModel';
import { TradeDetailsService } from 'src/app/services/trade-details/trade-details.service';
;


@Component({
  selector: 'app-trade-details',
  templateUrl: './trade-details.component.html',
  styleUrls: ['./trade-details.component.scss']
})
export class TradeDetailsComponent extends PageBaseService{
  
  ComponentObjectID: string;
  @ViewChild("recordrelationshipbindingtool", { static: false }) recordrelationshipcomponent: RecordRelationshipBindingToolComponent
  @ViewChild("grdTradeDetails_Worker", { static: false }) grdTradeWorker: GridComponent


  constructor(private eventQueue: LobasEventQueueService, private alertService: AlertService, private translateService: TranslateService,private _router: Router, private _enc: EncryptionService, private tradeDetailService:TradeDetailsService) {
    super(_router, _enc);
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
    this.ComponentObjectID = 'DEA08C66-A375-4EE0-B3F1-4E28DE246477';
  }
  gridCustomActions(data: any) {
    switch (data.CommandObject.Command.toLowerCase()) {
      case "addworkertraderelationship":
        this.recordrelationshipcomponent.openPopup();
        break;
      case "cancel":
      case "cancelall":
          try {
            var redirectURL = data.CommandObject.Definition?.VisibilityOption[0]?.RedirectURL ?? "";
            if (redirectURL.length === 0) {
              redirectURL = this._router.getCurrentNavigation().previousNavigation.finalUrl.toString();
            }
            this._router.navigateByUrl(redirectURL);
          } catch (Ex) {
            this._router.navigateByUrl('app-home');
          }
          break;
      default:
        this.translateService.get('CustomActionYetToImplemented', { title: data.CommandObject.Name }).subscribe((text: string) => {
          this.alertService.showAlertPopup(this.translateService.instant('InfoTitle'), text,
            'info', this.translateService.instant('OkButtonText'));
        });
        break;
    }
  }
  RelationshipRecordActions(refreshGrid: boolean) {
    if (refreshGrid) {
      this.grdTradeWorker.refreshGrid(false);
    }
  }

  rowClickGrid(e:any){
    let relationshipModel = new RelationshipRecordModel();
    relationshipModel.Object1_RecordID = e.data.Object1_RecordID;
    relationshipModel.Object2_RecordID = e.data.Object2_RecordID;
    relationshipModel.RelationshipID = e.data.RelationshipID;
    this.tradeDetailService.UnAssignWorkers(relationshipModel).subscribe(result=>
    {
      this.grdTradeWorker.refreshGrid(false);
    });
 
  }

}
