<div class="customloader" id="customloader"></div>
<div class="webpart-card" *ngIf="changePasswordControlVisibility">
    <div class="webpart-cardbody">
        <div class="webpart-card-header" [ngClass]="isVisible ? 'webpart-card-closed' : 'webpart-card-open'">
            <div class="webpart-card-header-title">
                <span>Change Password</span>
            </div>
            <div class="webpart-card-header-action">
                <dx-button (onClick)="expandCollaspeComponent()"
                    [icon]="isVisible ? 'fa-regular fa-angle-up' : 'fa-regular fa-angle-down'"></dx-button>
            </div>
        </div>

        <div [hidden]="isVisible">

            <form method="post">
                <dx-validation-group #changePassword>
                    <div class="form">
                        <div class="form-group-row">
                            <div class="field_group">
                                <div class="field_label required-label-asterisk">New Password</div>
                                <div class="field_control">
                                    <dx-text-box mode="password" [(value)]="password"
                                        [inputAttr]="{ 'aria-label': 'New Password' }">
                                        <dx-validator>
                                            <dxi-validation-rule type="required"
                                                message="New Password is required"></dxi-validation-rule>
                                            <dxi-validation-rule type="pattern"
                                                pattern="^(?! )(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&amp;*-.+ ])(?=.*?[0-9]).{6,}[^\s]$"
                                                message="Password must have at least 6 characters with 1 uppercase, 1 lowercase, 1 numeric and 1 special character eg: #?!@$%^&*-.+ Do not use space character at the start or end of your Password.">
                                            </dxi-validation-rule>
                                        </dx-validator>
                                    </dx-text-box>
                                </div>
                                <div class="paswd_str_mtr">
                                    <password-strength-meter [password]="password"></password-strength-meter>
                                </div>
                            </div>
                            <div class="field_group">
                                <div class="field_label required-label-asterisk">Confirm Password</div>
                                <div class="field_control">
                                    <dx-text-box mode="password" [(value)]="confirmPassword"
                                        [inputAttr]="{ 'aria-label': 'Confirm Password' }">
                                        <dx-validator>
                                            <dxi-validation-rule type="required"
                                                message="Confirm Password is required"></dxi-validation-rule>
                                            <dxi-validation-rule type="compare" [comparisonTarget]="passwordComparison"
                                                message="Password and Confirm Password fields must match."></dxi-validation-rule>
                                        </dx-validator>
                                    </dx-text-box>
                                </div>
                            </div>
                            <div class="field_group">
                                <div class="field_label required-label-asterisk">Current Password</div>
                                <div class="field_control">
                                    <dx-text-box mode="password" [(value)]="currentPassword"
                                        [inputAttr]="{ 'aria-label': 'Password' }">
                                        <dx-validator>
                                            <dxi-validation-rule type="required"
                                                message="Current Password is required"></dxi-validation-rule>
                                        </dx-validator>
                                    </dx-text-box>
                                </div>
                            </div>
                        </div>
                        <div class="form-btn-outer">
                            <dx-button text="Save" (onClick)="Submit($event)" [useSubmitBehavior]="true">
                            </dx-button>
                        </div>
                    </div>
                </dx-validation-group>
            </form>

        </div>

    </div>
</div>
<app-validation-popup #validationpopup></app-validation-popup>