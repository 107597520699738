<dx-popup [showTitle]="true" [title]="this.componentProperties.HeadTitle"
    [wrapperAttr]="{class: 'lobas-popup lobas-form-popup assign_contact' }" #recordRelationshipBindingTool
    [showTitle]="true" [resizeEnabled]="false" [dragEnabled]="false" [hideOnOutsideClick]="false"
    [showCloseButton]="true">
    <form method="post">
        <dx-validation-group #recordRelationshipGroup>
            <div class="form">
                <div class="form-row">
                    <div class="field_group">
                        <div class="field_label required-label-asterisk">{{componentProperties.Title}}</div>
                        <div class="field_control">
                            <div class="dx-field">
                                <div class="">
                                    <dx-drop-down-box #DDBox [(opened)]="isGridBoxOpened"
                                        [inputAttr]="{ 'aria-label': 'Owner' }" [showClearButton]="true"
                                        (onOptionChanged)="onGridBoxOptionChanged($event)">
                                        <dx-validator>
                                            <dxi-validation-rule type="required" [message]="requiredMessage">
                                            </dxi-validation-rule>
                                        </dx-validator>

                                        <div *dxTemplate="let data of 'content'" class="lobas-rec-src-dropdown-grid">
                                            <app-grid [applyAdaptivePriority]="false" [isWebpartHeaderHidden]="true"
                                                [showFilterRow]="true" [IsUnauthorizedPage]="false" [isRowClick]="true"
                                                (isCustomStoreLoaded)="setValues()" (rowClick)="rowClick($event)" [instanceID]="ddlGridInstanceID"
                                                #ddlGrid></app-grid>
                                        </div>

                                    </dx-drop-down-box>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="field_group" [hidden]="isDateHidden">
                        <div
                            [ngClass]="componentProperties.MandatoryStartDate ? 'field_label required-label-asterisk' : 'field_label'">
                            Valid From</div>
                        <div class="field_control">
                            <div class="dx-field-value">
                                <dx-date-box #dxStartDate [value]="recordRelationshipBindingModel.StartDate"
                                    type="datetime">
                                    <dx-validator>
                                        <dxi-validation-rule type="custom"   [reevaluate]="true" message="The Valid From is Required"
                                            [validationCallback]="validateStartDateRequired">
                                        </dxi-validation-rule>
                                        <dxi-validation-rule type="custom"  [reevaluate]="true"
                                            message="Valid From date cannot be after Valid To date"
                                            [validationCallback]="validateStartDateLessThanEndDate">
                                        </dxi-validation-rule>
                                        <dxi-validation-rule type="custom"  [reevaluate]="true"
                                            message="Valid From can not overlap with selected record's Dates!"
                                            [validationCallback]="validateStartDateCannotOverlap">
                                        </dxi-validation-rule>
                                    </dx-validator>
                                </dx-date-box>
                            </div>
                        </div>
                    </div>
                    <div class="field_group" [hidden]="isDateHidden">
                        <div
                            [ngClass]="componentProperties.MandatoryEndDate ? 'field_label required-label-asterisk' : 'field_label'">
                            Valid To</div>
                        <div class="field_control">
                            <div class="dx-field-value">
                                <dx-date-box #dxEndDate [value]="recordRelationshipBindingModel.EndDate" type="datetime">
                                    <dx-validator>
                                        <dxi-validation-rule type="custom"  [reevaluate]="true" message="The Valid To is Required"
                                            [validationCallback]="validateEndDateRequired">
                                        </dxi-validation-rule>
                                        <dxi-validation-rule type="custom"   [reevaluate]="true"
                                            message="Valid To date cannot be before Valid From date"
                                            [validationCallback]="validateStartDateLessThanEndDate">
                                        </dxi-validation-rule>
                                        <dxi-validation-rule type="custom"  [reevaluate]="true"
                                            message="Valid To date can not overlap with selected record's Dates!"
                                            [validationCallback]="validateEndDateCannotOverlap">
                                        </dxi-validation-rule>
                                    </dx-validator>
                                </dx-date-box>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-btn-outer">
                    <dx-button text="Cancel" (onClick)="closePopup()">
                    </dx-button>
                    <dx-button text="Save" (onClick)="Submit($event)" [useSubmitBehavior]="true">
                    </dx-button>
                </div>
            </div>
        </dx-validation-group>
    </form>

    <app-validation-popup #validationpopup></app-validation-popup>
</dx-popup>