export class QuestionnaireResponseModel {
    RecordID: string;
    QuestionnaireResponseStatus_EnumID: string;
    QuestioneeTargetReached_EnumID: string;
    QuestionerTargetReached_EnumID: string;
    ReferenceNumber: string;
    StartedDate: Date | null;
    JSON: string;
    Score: number | null;
    ExtendedAttributes: string;
    RecordCreatedDate: Date | null;
    RecordUpdatedDate: Date | null;
    RecordUpdated_UserID: string | null;
    RecordCreated_UserID: string | null;
    CreatedUserIcon: string;
    UpdatedUserIcon: string;
    CreatedUsersName: string;
    UpdatedUsersName: string;
    RecordTenancyID: string;
    RecordLookupValue: string;
    RecordDescription: string;
    RecordDataObjectStatus_EnumID: string | null;
    RecordStartDate: Date | null;
    RecordEndDate: Date | null;
  }
