import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpHelperService {
  hostName:string = window.location.hostname;
  constructor(private http: HttpClient) { }

  sendRequest(url: string, method = 'GET', data: any = {}): any {
    let result: any;

    switch (method.toUpperCase()) {
      case 'GET':
        result = this.http.get(url);
        break;
      case 'PUT':
        result = this.http.put(url, data.values);
        break;
      case 'POST':
        if (data.values && !data.values.length) {
          result = this.http.post(url, data.values);
        }
        break;
      case 'DELETE':
        url = url + '/' + data.values;
        result = this.http.delete(url);
        break;
      case 'OFLINESYNC':
        result = this.http.post(url, data);
    }
    return lastValueFrom(result)
      .then((data: any) => (method === 'GET' ? data.data : data))
      .catch((e) => {
        
      });
  }
}
