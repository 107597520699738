import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';
import { flush } from '@angular/core/testing';
import { EventType, Router } from '@angular/router';

import { DxDateBoxComponent, DxPopupComponent, DxValidationGroupComponent } from 'devextreme-angular';
import { DxDropDownBoxComponent, DxDropDownBoxTypes } from 'devextreme-angular/ui/drop-down-box';
import CustomStore from 'devextreme/data/custom_store';
import { lastValueFrom } from 'rxjs';
import { RecordSearchComponentProperties } from 'src/app/models/RecordSearchComponentProperties';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { EnumHelper } from 'src/app/util/enum-helper';
import { environment } from 'src/environments/environment';
import { GridComponent } from '../grid/grid.component';
import dxDropDownBox from 'devextreme/ui/drop_down_box';
import { Guid } from 'guid-typescript';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { EncryptionService } from 'src/app/encryption.service';
import { RecordRelationshipBindingModel } from 'src/app/models/RecordRelationshipBindingModel';
import { RecordRelationshipBindingComponentProperties } from 'src/app/models/RecordRelationshipBindingComponentProperties';
import { RecordRelationshipBindingToolService } from 'src/app/services/recordRelationshipBindingToolServices/record-relationship-binding-tool.service';
import { ComponentPropertiesService } from 'src/app/services/componentProperties/component-properties.service';
import { ValidationPopupComponent } from '../validation-popup/validation-popup.component';
import { OfflineDataHelperService } from 'src/app/services/offlineDataHelper/offline-data-helper.service';


@Component({
  selector: 'app-record-relationship-binding-tool',
  templateUrl: './record-relationship-binding-tool.component.html',
  styleUrls: ['./record-relationship-binding-tool.component.scss']
})
export class RecordRelationshipBindingToolComponent extends PageBaseService implements OnInit {

  @ViewChild("recordRelationshipBindingTool", { static: false }) recordRelationshipBindingTool: DxPopupComponent;
  @ViewChild('ddlGrid', { static: false }) ddlGrid: GridComponent;
  @ViewChild('dxStartDate', { static: false }) dxStartDate: DxDateBoxComponent;
  @ViewChild('dxEndDate', { static: false }) dxEndDate: DxDateBoxComponent;
  @ViewChild('DDBox', { static: false }) ddBox: DxDropDownBoxComponent
  @ViewChild("validationpopup") validationpopup: ValidationPopupComponent;
  PrimaryRecordID: string;
  primaryRecordStartDate: Date = new Date();
  primaryRecordEndDate: Date = new Date();
  isGridBoxOpened = false;
  componentID: string = 'recordrelationshipbinding';
  componentProperties: RecordRelationshipBindingComponentProperties;
  recordRelationshipBindingModel: RecordRelationshipBindingModel = new RecordRelationshipBindingModel();
  @ViewChild('recordRelationshipGroup', { static: false }) recordRelationshipBindGroup: DxValidationGroupComponent
  isDateHidden: boolean;
  @Input() instanceID: string = '';
  @Input() ddlGridInstanceID: string = '';
  @Input() recordObjectId: string = '';
  requiredMessage:string = "Required";

  @Output() RelationshipRecordActions = new EventEmitter<any>();
  displayExpr = ({ LookupValue, Description }) => LookupValue + " " + Description;



  constructor(private el: ElementRef, private auth: AuthService, private translateService: TranslateService, private alertService: AlertService, 
    private ref: ChangeDetectorRef, private httpClient: HttpClient, private localStorageService: LocalStorageService, private _router: Router, private _enc: EncryptionService, 
    private recRelationbshipBindingTool: RecordRelationshipBindingToolService, private componentPropertyData: ComponentPropertiesService, private offlineDataHelper: OfflineDataHelperService) {
    super(_router, _enc);


  }

 ngOnInit() {
    this.componentProperties = <RecordRelationshipBindingComponentProperties>this.localStorageService.
      GetLocalStorage(EnumHelper.LocalStorageKey.recordSearchComponentProperties, [this.instanceID])
      ?? new RecordRelationshipBindingComponentProperties();
    this.setPrimaryRecordID();
    this.getComponentProperties();
   
  }

  initModel() {
    this.recordRelationshipBindingModel.RelationshipName = this.componentProperties.RelationshipName;
    this.recordRelationshipBindingModel.PointInTimeValidations = this.componentProperties.PointInTimeValidations;    
    this.recordRelationshipBindingModel.MandatoryStartDate = this.componentProperties.MandatoryStartDate;
    this.recordRelationshipBindingModel.MandatoryEndDate = this.componentProperties.MandatoryEndDate;
    this.recordRelationshipBindingModel.AllowDuplicates = this.componentProperties.AllowDuplicates;
  }

  setPrimaryRecordID() {
    this.GetKeyValueFromQueryString('recordID').then((recordID => {
      this.recordRelationshipBindingModel.Object1_RecordID  = recordID;
    }));
  }

  setStartDateAndEndDate(){
    this.isDateHidden = true;
    if(this.componentProperties && this.componentProperties.PointInTime){
      this.isDateHidden = false;
    }
  }

  openPopup() {
    this.dxStartDate.instance.reset();  
    this.dxEndDate.instance.reset(); 
    this.recordRelationshipBindingTool.visible = true;
  }

  closePopup() {
    this.recordRelationshipBindingTool.visible = false;
    this.ddBox.value = null;
  }

 async getComponentProperties() {
  if(this.offlineDataHelper.isOnline){
    this.componentPropertyData.getComponentPropertiesData(this.componentID, this.instanceID).subscribe((response: any) => {
      this.setComponentProperties(response.Properties);
      this.setStartDateAndEndDate();
      this.initModel();
      this.requiredMessage = this.componentProperties.Title + "  is Required";
    });
  }
  }

  setComponentProperties(componentProperties) {
    var componentProperties = JSON.parse(componentProperties);
    componentProperties.RelationshipBindingProperties.forEach(componentProperty => {
      switch (componentProperty.key.toLowerCase()) {
        case "relationshipname":
          this.componentProperties.RelationshipName = componentProperty.value;
          break;
        case "title":
          this.componentProperties.Title = componentProperty.value;
          break;
        case "headtitle":
            this.componentProperties.HeadTitle = componentProperty.value;
            break;
        case "pointintime":
          this.componentProperties.PointInTime = this.convertToBool(componentProperty.value);
          break;
        case "mandatorystartdate":
          this.componentProperties.MandatoryStartDate = this.convertToBool(componentProperty.value);
          break;
        case "mandatoryenddate":
          this.componentProperties.MandatoryEndDate = this.convertToBool(componentProperty.value);
          break;
        case "pointintimevalidations":
          this.componentProperties.PointInTimeValidations = componentProperty.value;
          break;
        case "allowduplicates":
          this.componentProperties.AllowDuplicates = this.convertToBool(componentProperty.value);
          break;
        case "filter":
          this.componentProperties.Filter = componentProperty.value;
          break;
        case "displayexpr":
          this.componentProperties.displayExpr = componentProperty.value.split(',');
          break;
      }
    });
  }

  Submit(e: any) {
    const { isValid } = this.recordRelationshipBindGroup.instance.validate()
    if (isValid) {
      this.saveAction();      
    }
    else{
      const brokenRules = e.validationGroup.validate().brokenRules;           
        this.validationpopup.showPopup(brokenRules.map((rule: any) => {
          return "<p>" + rule.message + "</p>"
        }).join(""));
    }
  }

  saveAction() {
    this.recordRelationshipBindingModel.Object2_RecordID = this.ddlGrid.selectedRecordID;
    this.recordRelationshipBindingModel.StartDate = new Date(this.dxStartDate.value);
    if(this.dxEndDate.value!=undefined){
      this.recordRelationshipBindingModel.EndDate = new Date(this.dxEndDate.value);
    }
    
    var response = this.recRelationbshipBindingTool.insertInRelationshipBindingRecord(this.recordRelationshipBindingModel).subscribe((response) => {
      this.closePopup()

      this.alertService.showAlertPopup('Success', "Record Inserted Successfully", 'fa-solid fa-check', 'OK').then(response => {
        this.RelationshipRecordActions.emit(true);
      }
      );
    },
      (error) => {
        console.log(error)
        this.alertService.showAlertPopup('Error',error.error.Message, 'fa-solid fa-xmark', 'OK');
      })
  }


  onGridBoxOptionChanged(e: DxDropDownBoxTypes.OptionChangedEvent) {
    if (e.name === 'value') {
      this.isGridBoxOpened = false;
      this.ref.detectChanges();
    }
  }


  rowClick(ev: any) {
    this.ddBox.dataSource = this.ddlGrid.dataSource;
    this.ddBox.value = [ev.data];
    this.primaryRecordStartDate = ev.data.StartDate;
    this.primaryRecordEndDate = ev.data.EndDate;
  }
  setValues() {
    this.ddBox.valueExpr = "RecordID";
    this.ddBox.deferRendering = false;
    if (this.componentProperties.displayExpr && this.componentProperties.displayExpr.length > 0) {
      this.ddBox.displayExpr = (item) => {
        let value = '';
        this.componentProperties.displayExpr.forEach(displayColumn => {
          if(item[displayColumn]){
              value += item[displayColumn] + " ";
            }
        });
        return value.trim(); 
      };
    } else {
  
      this.ddBox.displayExpr = this.displayExpr;
    }
    this.ddBox.placeholder = 'Select a value...';
    if (this.ddBox.dataSource) {
      this.ddBox.dataSource = this.ddlGrid.dataSource;
    }
  }

  validateStartDateLessThanEndDate=(e) =>{
    if(this.componentProperties.PointInTime) {      
        if(new Date(this.dxStartDate.value) >= new Date(this.dxEndDate.value)) {
          return false;  
        }      
      }
    return true;
  }

  validateStartDateRequired=(e) => {
    if(this.componentProperties.PointInTime) {      
      if(this.componentProperties.MandatoryStartDate && (this.dxStartDate.value ==null || this.dxStartDate.value == "")) { 
        return false;
      }
    }
    return true;
  }
  validateEndDateRequired=(e) => {
    if(this.componentProperties.PointInTime) {      
      if(this.componentProperties.MandatoryEndDate && (this.dxEndDate.value ==null || this.dxEndDate.value == "")) { 
        return false;
      }
    }
    return true;
  }

  validateStartDateCannotOverlap=(e) => {
      if(this.componentProperties.PointInTimeValidations.toUpperCase()=="DATESCANNOTOVERLAP") {      
        if(this.primaryRecordStartDate !=null && this.primaryRecordEndDate != null) {
          if(new Date(this.dxStartDate.value) < new Date(this.primaryRecordStartDate)
            || new Date(this.dxStartDate.value) > new Date(this.primaryRecordEndDate)
        ) {
            return false;  
          }
        }
      }
    return true;
  }

  validateEndDateCannotOverlap=(e) => {
    if(this.componentProperties.PointInTimeValidations.toUpperCase()=="DATESCANNOTOVERLAP") {      
      if(this.primaryRecordStartDate !=null && this.primaryRecordEndDate != null) {
        if( new Date(this.dxEndDate.value) < new Date(this.primaryRecordStartDate)
          || new Date(this.dxEndDate.value) > new Date(this.primaryRecordEndDate)
      ) {
          return false;  
        }
      }
    }
  return true;
}

  convertToBool(str: string){
    if(str.toLowerCase()=='true'){
      return true;
    }else if(str.toLowerCase()=='false'){
      return false;
    }
    return false;
  }
}