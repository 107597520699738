import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { EncryptionService } from 'src/app/encryption.service';
import { CardSliderComponentProperties, SlideValues } from 'src/app/models/CardSliderComponentProperties';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { EnumHelper } from 'src/app/util/enum-helper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-card-slider',
  templateUrl: './card-slider.component.html',
  styleUrls: ['./card-slider.component.scss']
})
export class CardSliderComponent extends PageBaseService {

  componentID: string = 'cardslider'
  @Input() instanceID: string = '';
  componentProperties: CardSliderComponentProperties;
  slideDataSource: SlideValues[] = [];
  slideElement: SlideValues;

  constructor(private _router: Router, private _enc: EncryptionService,private localStorageService: LocalStorageService,private auth: AuthService, private httpClient: HttpClient) {
    super(_router, _enc);
  }


  ngOnInit(): void {
    this.componentProperties = <CardSliderComponentProperties>this.localStorageService.
      GetLocalStorage(EnumHelper.LocalStorageKey.cardSliderComponentProperties, [this.instanceID]) ?? new CardSliderComponentProperties();

      
    if (this.componentProperties.Title.length === 0) {
      this.getComponentProperties();
    }
    else {
      this.SetDataSource(this.componentProperties,this.httpClient);
    }
    

  }

  getComponentProperties() {
    var ComponentUrl = "";
    ComponentUrl = "api/ComponentProperties/GetComponentPropertiesData?componentID=";
    var result = lastValueFrom(
      this.httpClient.get(environment.apiUrl + ComponentUrl + this.componentID + "&instanceID=" + this.instanceID)
    ).then((response: any) => {
      this.setComponentProperties(response.Properties);
    }).catch((ex)=>{
      console.log(JSON.stringify(ex));
    });;
  }

  setComponentProperties(componentProperties) {
    var componentProperties = JSON.parse(componentProperties);
    componentProperties.CardSliderProperties.forEach(componentProperty => {
      switch (componentProperty.key.toLowerCase()) {
        case "title":
          this.componentProperties.Title = componentProperty.value;
          break;
        case "allowedroles":
          this.componentProperties.AllowedRoles = componentProperty.value;
          break;
        case "refreshinterval":
          this.componentProperties.RefreshInterval = componentProperty.value;
          break;
        case "slidedetails":
          this.componentProperties.SlideDetails = JSON.parse(componentProperty.value)
          break;
      }
    });
    this.localStorageService.SetLocal(EnumHelper.LocalStorageKey.cardSliderComponentProperties, JSON.stringify(this.componentProperties), [this.instanceID])
    this.SetDataSource(this.componentProperties,this.httpClient);
  }

  CardSliderClick(url) {
    if(url.includes("?"))
      {
        this.navigateTo(url.split('?')[0],url.split('?')[1])
      }
      else{
        this.navigate(url)
      }
  }

  SetDataSource(componentProperties: CardSliderComponentProperties, httpClient: HttpClient) {  
    if (componentProperties && componentProperties.SlideDetails) {
      componentProperties.SlideDetails.Slides.forEach(sld => {
        if (sld.RecordID) {
          var result = lastValueFrom(
            httpClient.get(environment.apiUrl + 'api/Queries/GetQueriesRecordCount/' + sld.RecordID)
          ).then((response: any) => {
            if(response && response[0])
              {
                this.slideElement = new SlideValues();
                this.slideElement.Name = sld.SlideName;
                this.slideElement.Count = response[0].RecordCount;
                this.slideElement.URL = sld.URL;
                this.slideElement.BackgroundColor = sld.BackgroundColor;
                this.slideElement.Color = sld.Color;
                this.slideElement.BorderColor = sld.BorderColor;
                this.slideElement.Icon = sld.Icon;
                this.slideDataSource.push(this.slideElement);
              }            
          }).catch((ex)=>{
            console.log(JSON.stringify(ex));
          });
        }
      });
    }
  }
}
