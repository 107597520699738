<app-breadcrumb></app-breadcrumb>
<div class="row webpartrow">
    <div class="col-lg-12">
        <div class="closecall-dash-head-filter">
            <div class="form-group">
                <div class="field_label">Start Work</div>
                <div class="field_control">
                    <div class="inp-outer">
                        <input type="datetime-local" value="2024-06-15T20:00" class="form-control" />
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="field_label">Finish Work</div>
                <div class="field_control">
                    <div class="inp-outer">
                        <input type="datetime-local" value="2024-06-16T08:00" class="form-control" />
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="field_label">Time Series</div>
                <div class="field_control">
                    <div class="inp-outer">
                        <input type="text" class="form-control" />
                    </div>
                    <i class="fa-sharp fa-regular fa-filters"></i>
                </div>
            </div>
            <div class="form-group">
                <div class="field_label">Collection 1</div>
                <div class="field_control">
                    <div class="inp-outer">
                        <select class="form-control">
                            <option selected>Ben Evans</option>
                            <option>NP</option>
                            <option>All</option>
                        </select>
                        <i class="fa-sharp fa-solid fa-caret-down"></i>
                    </div>
                    <i class="fa-sharp fa-regular fa-filters"></i>
                </div>
            </div>
            <div class="form-group">
                <div class="field_label">Collection 2</div>
                <div class="field_control">
                    <div class="inp-outer">
                        <select class="form-control">
                            <option>Ben Evans</option>
                            <option selected>NP</option>
                            <option>All</option>
                        </select>
                        <i class="fa-sharp fa-solid fa-caret-down"></i>
                    </div>
                    <i class="fa-sharp fa-regular fa-filters"></i>
                </div>
            </div>
            <div class="form-group">
                <div class="field_label">Collection 3</div>
                <div class="field_control">
                    <div class="inp-outer">
                        <select class="form-control">
                            <option>Ben Evans</option>
                            <option>NP</option>
                            <option selected>All</option>
                        </select>
                        <i class="fa-sharp fa-solid fa-caret-down"></i>
                    </div>
                    <i class="fa-sharp fa-regular fa-filters"></i>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row webpartrow">
    <div class="col-lg-6">
        <div class="m-b-30">
            <div class="webpart-card">
                <div class="webpart-cardbody">
                    <div class="webpart-card-header"
                        [ngClass]="this.isVisible ? 'webpart-card-closed' : 'webpart-card-opened'">
                        <div class="webpart-card-header-title">
                            <span>Close Calls Submitted</span>
                        </div>
                        <div class="webpart-card-header-action">
                            <dx-button (onClick)="expandCollaspeComponent()"
                                [icon]="isVisible ? 'fa-regular fa-angle-up' : 'fa-regular fa-angle-down'"></dx-button>
                        </div>
                    </div>
                    <div [hidden]="isVisible">
                        <div class="graph-row">
                            <div class="graph-col-4">
                                <dx-circular-gauge id="gauge" [value]="90">
                                    <dxo-scale [startValue]="10" [endValue]="100" [tickInterval]="10">
                                        <dxo-label [useRangeColors]="false" [visible]="false"></dxo-label>
                                    </dxo-scale>
                                    <dxo-range-container palette="pastel">
                                        <dxi-range [startValue]="0" [endValue]="19" color="#cc0001"></dxi-range>
                                        <dxi-range [startValue]="20" [endValue]="49" color="#fed966"></dxi-range>
                                        <dxi-range [startValue]="50" [endValue]="100" color="#80e352"></dxi-range>
                                    </dxo-range-container>
                                    <dxo-title text="Collection 1" verticalAlignment="bottom">
                                        <dxo-font [size]="24"></dxo-font>
                                    </dxo-title>
                                </dx-circular-gauge>
                            </div>
                            <div class="graph-col-4">
                                <dx-circular-gauge id="gauge2" [value]="80">
                                    <dxo-scale [startValue]="10" [endValue]="100" [tickInterval]="10">
                                        <dxo-label [useRangeColors]="false" [visible]="false"></dxo-label>
                                    </dxo-scale>
                                    <dxo-range-container palette="pastel">
                                        <dxi-range [startValue]="0" [endValue]="19" color="#cc0001"></dxi-range>
                                        <dxi-range [startValue]="20" [endValue]="49" color="#fed966"></dxi-range>
                                        <dxi-range [startValue]="50" [endValue]="100" color="#80e352"></dxi-range>
                                    </dxo-range-container>
                                    <dxo-title text="Collection 2" verticalAlignment="bottom">
                                        <dxo-font [size]="24"></dxo-font>
                                    </dxo-title>
                                </dx-circular-gauge>
                            </div>
                            <div class="graph-col-4">
                                <dx-circular-gauge id="gauge3" [value]="70">
                                    <dxo-scale [startValue]="10" [endValue]="100" [tickInterval]="10">
                                        <dxo-label [useRangeColors]="false" [visible]="false"></dxo-label>
                                    </dxo-scale>
                                    <dxo-range-container palette="pastel">
                                        <dxi-range [startValue]="0" [endValue]="19" color="#cc0001"></dxi-range>
                                        <dxi-range [startValue]="20" [endValue]="49" color="#fed966"></dxi-range>
                                        <dxi-range [startValue]="50" [endValue]="100" color="#80e352"></dxi-range>
                                    </dxo-range-container>
                                    <dxo-title text="Collection 3" verticalAlignment="bottom">
                                        <dxo-font [size]="24"></dxo-font>
                                    </dxo-title>
                                </dx-circular-gauge>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="m-b-30">
            <div class="webpart-card">
                <div class="webpart-cardbody">
                    <div class="webpart-card-header"
                        [ngClass]="this.isVisible2 ? 'webpart-card-closed' : 'webpart-card-opened'">
                        <div class="webpart-card-header-title">
                            <span>Close Calls Submitted</span>
                        </div>
                        <div class="webpart-card-header-action">
                            <dx-button (onClick)="expandCollaspeComponent2()"
                                [icon]="isVisible2 ? 'fa-regular fa-angle-up' : 'fa-regular fa-angle-down'"></dx-button>
                        </div>
                    </div>
                    <div [hidden]="isVisible2">
                        <div id="chart-demo">
                            <dx-chart palette="Violet" [dataSource]="closecallInfo">
                                <dxi-series *ngFor="let closecall of closecallSources" [valueField]="closecall.value"
                                    [name]="closecall.name" [color]="closecall.color">
                                </dxi-series>
                                <dxo-common-series-settings #seriesSettings argumentField="month">
                                </dxo-common-series-settings>
                                <dxo-argument-axis [valueMarginsEnabled]="false" discreteAxisDivisionMode="crossLabels">
                                    <dxo-grid [visible]="true"></dxo-grid>
                                </dxo-argument-axis>
                                <dxo-legend verticalAlignment="bottom" horizontalAlignment="center"
                                    itemTextPosition="bottom">
                                </dxo-legend>
                            </dx-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-6">
        <div class="m-b-30">
            <div class="webpart-card">
                <div class="webpart-cardbody">
                    <div class="webpart-card-header"
                        [ngClass]="this.isVisible3 ? 'webpart-card-closed' : 'webpart-card-opened'">
                        <div class="webpart-card-header-title">
                            <span>Worst Perfomers</span>
                        </div>
                        <div class="webpart-card-header-action">
                            <dx-button hint="Refresh" icon="fa-regular fa-arrows-rotate"></dx-button>
                            <dx-button hint="Column Chooser" icon="fa-regular far fa-columns"></dx-button>
                            <dx-button hint="Export Options" icon="fa-regular fa-file-export"></dx-button>
                            <dx-button hint="Filter Builder" icon="fa-regular far fa-filter"></dx-button>
                            <dx-button hint="Grid View" icon="fa-regular fa-border-all"></dx-button>
                            <dx-button (onClick)="expandCollaspeComponent3()"
                                [icon]="isVisible3 ? 'fa-regular fa-angle-up' : 'fa-regular fa-angle-down'"></dx-button>
                        </div>
                    </div>
                    <div [hidden]="isVisible3">
                        <dx-data-grid #dataGrid [showColumnHeaders]="true" class="close-call-dash-grid lobas-grid"
                            [dataSource]="dtsrcBest" [columnHidingEnabled]="true" [showBorders]="false"
                            [remoteOperations]="true" keyExpr="RecordId">
                            <dxo-load-panel [enabled]="false" indicatorSrc="/" [shading]="false" [showPane]="true"
                                text="">
                            </dxo-load-panel>
                            <dxo-filter-row [visible]="true"></dxo-filter-row>
                            <dxi-column dataField="Name" cssClass="lobas-grid-td"></dxi-column>
                            <dxi-column width="200px" dataField="Raised" cssClass="lobas-grid-td"></dxi-column>
                            <dxi-column [width]="200" dataField="Rating" cssClass="lobas-grid-td"
                                cellTemplate="cellTemplate"></dxi-column>
                            <div *dxTemplate="let data of 'cellTemplate'" class="grid-td-star-rating">
                                {{data.value}}
                            </div>
                        </dx-data-grid>
                        <div class="grid-footer">
                            <div class="grid-footer-left">
                                <p>
                                    <span>Items: <strong>4</strong></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="m-b-30">
            <div class="webpart-card">
                <div class="webpart-cardbody">
                    <div class="webpart-card-header"
                        [ngClass]="this.isVisible4 ? 'webpart-card-closed' : 'webpart-card-opened'">
                        <div class="webpart-card-header-title">
                            <span>Best Perfomers</span>
                        </div>
                        <div class="webpart-card-header-action">
                            <dx-button hint="Refresh" icon="fa-regular fa-arrows-rotate"></dx-button>
                            <dx-button hint="Column Chooser" icon="fa-regular far fa-columns"></dx-button>
                            <dx-button hint="Export Options" icon="fa-regular fa-file-export"></dx-button>
                            <dx-button hint="Filter Builder" icon="fa-regular far fa-filter"></dx-button>
                            <dx-button hint="Grid View" icon="fa-regular fa-border-all"></dx-button>
                            <dx-button (onClick)="expandCollaspeComponent4()"
                                [icon]="isVisible4 ? 'fa-regular fa-angle-up' : 'fa-regular fa-angle-down'"></dx-button>
                        </div>
                    </div>
                    <div [hidden]="isVisible4">
                        <dx-data-grid #dataGrid [showColumnHeaders]="true" class="close-call-dash-grid lobas-grid"
                            [dataSource]="dtsrcWorst" [columnHidingEnabled]="true" [showBorders]="false"
                            [remoteOperations]="true" keyExpr="RecordId">
                            <dxo-load-panel [enabled]="false" indicatorSrc="/" [shading]="false" text="">
                            </dxo-load-panel>
                            <dxo-filter-row [visible]="true"></dxo-filter-row>
                            <dxi-column dataField="Name" cssClass="lobas-grid-td"></dxi-column>
                            <dxi-column width="200px" dataField="Raised" cssClass="lobas-grid-td"></dxi-column>
                            <dxi-column [width]="200" dataField="Rating" cssClass="lobas-grid-td"
                                cellTemplate="cellTemplate"></dxi-column>
                            <div *dxTemplate="let data of 'cellTemplate'" class="grid-td-star-rating">
                                {{data.value}}
                            </div>
                        </dx-data-grid>
                        <div class="grid-footer">
                            <div class="grid-footer-left">
                                <p>
                                    <span>Items: <strong>4</strong></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>