
<dx-popup [showTitle]="true" title="Assign Work Item"
    [wrapperAttr]="{class: 'lobas-popup lobas-form-popup assign_contact' }" #AssignWorkItem
    [showTitle]="true" [resizeEnabled]="false" [dragEnabled]="false" [hideOnOutsideClick]="false"
    [showCloseButton]="true">

    <div class="m-b-30">
        <app-grid *ngIf="instanceID != undefined" #assignworkitemGrid  [gridProperties]="instanceID"
        [IsUnauthorizedPage]="false" [instanceID]="instanceID" [isRowClick]="true" (rowClick)="rowClickGrid($event)"></app-grid>
    </div>
</dx-popup>