import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DxValidationGroupComponent } from 'devextreme-angular';
import { SimplifiedSearchMode } from 'devextreme/common';
import { Guid } from 'guid-typescript';
import { catchError } from 'rxjs';
import { EncryptionService } from 'src/app/encryption.service';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { OfflineDataHelperService } from 'src/app/services/offlineDataHelper/offline-data-helper.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-security-questions',
  templateUrl: './security-questions.component.html',
  styleUrls: ['./security-questions.component.scss']
})
export class SecurityQuestionsComponent extends PageBaseService {
  @Input() IsShowHeader:boolean=true;
  @Output() SaveSuccessfully: EventEmitter<boolean> = new EventEmitter();
  isVisible: Boolean = false;
  securityQuestionList: QuestionModel[] = [];
  @ViewChild('securityQuestion', {static: false}) securityQuestionValidationGroup: DxValidationGroupComponent
  // securityQuestionAnswerList: { key: number, values: string }[];
  securityQuestion: { key: number, values: string }
  applicationSecurityQuestionCount: number;
  searchModeOption: SimplifiedSearchMode = 'contains';
  searchExprOption = 'Value';
  searchTimeoutOption = 200;
  minSearchLengthOption = 0;
  showDataBeforeSearchOption = true;
  //questionAnswerList: QuestionAnswerModel[]=[];
  SecurityQuestionVisibility: boolean = true;
  //questionAnswer: QuestionAnswerModel;
  counterArray: Array<number>[];

  currentPassword: string;
  securityQuestiondataSource: SecurityQuestionAnswerModel[] = [];
  SecurityQuestionAnswer: SecurityQuestionAnswerModel;
  saveSecurityQuestionAnswerList: SaveSecurityQuestionAnswer[] = []
  saveSecurityQuestionAnswerModel: SaveSecurityQuestionAnswerModel;
  constructor(private translateService: TranslateService,private _router: Router, private _enc: EncryptionService, private auth: AuthService, private httpClient: HttpClient, private alertService: AlertService, private offlineDataHelper: OfflineDataHelperService ) {
    super(_router, _enc);
  }

  expandCollaspeComponent() {
    this.isVisible = !this.isVisible;
  }

  async ngOnInit() {
    var userID = this.auth.getGuidUserID() ?? '';
    var queryStringRecordID = await this.GetKeyValueFromQueryString("RecordID");
    if ((queryStringRecordID && queryStringRecordID.toLowerCase() != userID.toLowerCase()) && window.location.pathname.toLowerCase().includes("people-details")) {
      this.SecurityQuestionVisibility = false;
    }
    //this.GetSecurityQuestions();
    this.applicationSecurityQuestionCount = environment.securityQuestionCount;;
    for (let i = 0; i < this.applicationSecurityQuestionCount; i++) {
      this.SecurityQuestionAnswer = new SecurityQuestionAnswerModel();
      this.SecurityQuestionAnswer.securityQuestionList = this.GetSecurityQuestions()
      this.SecurityQuestionAnswer.QuestionValue = null;
      this.SecurityQuestionAnswer.QuestionTextValue = null;
      this.SecurityQuestionAnswer.AnswerValue = null;
      this.securityQuestiondataSource.push(this.SecurityQuestionAnswer);
    }
    if(this.offlineDataHelper.isOnline){
      this.GetSecurityQuestionAndAnswers(this.auth.getGuidUserID() ?? '')
      this.counterArray = new Array(this.applicationSecurityQuestionCount);
      this.UpdateQuestionDataSource();
    }
  }
  UpdateQuestionDataSource() {
    var questionsNotToInnclude = [];

    this.securityQuestiondataSource.forEach(obj=>{
      if(obj.QuestionValue)
      {
        questionsNotToInnclude.push(obj.QuestionValue);  
      }      
    })

    this.securityQuestiondataSource.forEach(obj=>{      
      var questionList = this.GetSecurityQuestions();      
      obj.securityQuestionList = questionList.filter(ele=> !questionsNotToInnclude.includes(ele.QuestionNumber)   || (obj.QuestionValue && obj.QuestionValue == ele.QuestionNumber)); 
    })
  }

  

  GetQuestionNumber(QuestionValue: string) {
    var questionNumber = this.securityQuestionList.filter(x =>  x.Question.toLowerCase() == QuestionValue.toLowerCase() )[0].QuestionNumber;
    if (questionNumber > 0) {
      return questionNumber;
    }
    else {
      return 0;
    }
  }

  GetSecurityQuestions() {
    this.securityQuestionList = []
    this.securityQuestionList.push({ QuestionNumber:1, Question: "What primary school did you attend?" });
    this.securityQuestionList.push({ QuestionNumber:2, Question: "In what town or city did you meet your spouse/partner?" });
    this.securityQuestionList.push({ QuestionNumber:3, Question: "What is the middle name of your oldest child?" });
    this.securityQuestionList.push({ QuestionNumber:4, Question: "What was your favorite place to visit as a child?" });
    this.securityQuestionList.push({ QuestionNumber:5, Question: "What was the make of your first car?" });
    this.securityQuestionList.push({ QuestionNumber:6, Question: "What is your favorite movie?" });
    this.securityQuestionList.push({ QuestionNumber:7, Question: "What street did you grow up on?" });
    this.securityQuestionList.push({ QuestionNumber:8, Question: "What is the name of your favorite pet?" });
    this.securityQuestionList.push({ QuestionNumber:9, Question: "In what city were you born?" });
    this.securityQuestionList.push({ QuestionNumber:10, Question: "What secondary school did you attend?" });
    this.securityQuestionList.push({ QuestionNumber:11, Question: "What is your mother's maiden name?" });
    this.securityQuestionList.push({ QuestionNumber:12, Question: "Who was your childhood hero?" });
    this.securityQuestionList.push({ QuestionNumber:13, Question: "What is the middle name of your oldest child?" });
    this.securityQuestionList.push({ QuestionNumber:14, Question: "In what city or town did your mother and father meet?" });
    this.securityQuestionList.push({ QuestionNumber:15, Question: "In what city or town was your first job?" });
    this.securityQuestionList.push({ QuestionNumber:16, Question: "What town was your mother born?" });
    this.securityQuestionList.push({ QuestionNumber:17, Question: "What was your childhood nick name?" });
    this.securityQuestionList.push({ QuestionNumber:18, Question: "Who is your favourite actor?" });
    this.securityQuestionList.push({ QuestionNumber:19, Question: "What is your favourite fruit?" });
    this.securityQuestionList.push({ QuestionNumber:20, Question: "What is the name of your favorite childhood friend?" });
    return this.securityQuestionList;
  }

  QuestionSelectionChanged(e: any, index, obj: SecurityQuestionAnswerModel) {
    if (obj && obj.securityQuestionList) {
      obj.QuestionValue = e.selectedItem.QuestionNumber;
      obj.QuestionTextValue = e.selectedItem.Question;
    }
    
    
  }
  onValueChanged(e:any)
  {
    
    this.UpdateQuestionDataSource();
  }
  onAnswerValueChange(e: any, index, obj: SecurityQuestionAnswerModel) {
    if (obj && obj.securityQuestionList) {
      obj.AnswerValue = e.component._changedValue;
    }
  }
  SaveSecurityQuestionAndAnswer(e: any) {

    var validationmessage = "";
    const { isValid } = this.securityQuestionValidationGroup.instance.validate();
    if (!isValid) {
    }
    else{
      this.saveAnswers();
    }
  }




  GetSecurityQuestionAndAnswers(UserID) {
    var userid = this.auth.getGuidUserID() ?? '';
    this.httpClient.get<any>(environment.apiUrl + 'api/aspnet_SecurityQuestion/GetAspnetSecurityQuestion/' + UserID)
      .subscribe((response) => {

        if (response) {
          if (response.length <= this.applicationSecurityQuestionCount) {
            var index = 0;
            response.forEach(obj => {
              this.securityQuestiondataSource[index].QuestionValue = this.GetQuestionNumber(response[index].Question);
              this.securityQuestiondataSource[index].QuestionTextValue = response[index].Question;
              this.securityQuestiondataSource[index].AnswerValue = response[index].Answer;
              index = index + 1;
            })
          }
          else
          {
            var index = 0;
            this.securityQuestiondataSource.forEach(obj => {
              this.securityQuestiondataSource[index].QuestionValue = this.GetQuestionNumber(response[index].Question);
              this.securityQuestiondataSource[index].QuestionTextValue = response[index].Question;
              this.securityQuestiondataSource[index].AnswerValue = response[index].Answer;
              index = index + 1;
            })
          }

        }
      });

  }

  saveAnswers() {
    this.saveSecurityQuestionAnswerList = []
    this.securityQuestiondataSource.forEach(x => {
      this.saveSecurityQuestionAnswerList.push({ AnswerValue: x.AnswerValue, QuestionTextValue: x.QuestionTextValue });
    })
    this.saveSecurityQuestionAnswerModel = new SaveSecurityQuestionAnswerModel();
    this.saveSecurityQuestionAnswerModel.saveSecurityQuestionAnswerList = this.saveSecurityQuestionAnswerList;
    this.saveSecurityQuestionAnswerModel.password = this.currentPassword;
    this.saveSecurityQuestionAnswerModel.UserID = this.auth.getGuidUserID() ?? '';
    this.httpClient.post<any>(environment.apiUrl + 'api/aspnet_SecurityQuestion/SaveSecurityQuestionAndAnswer', JSON.stringify(this.saveSecurityQuestionAnswerModel))
      .subscribe((response) => {
        this.alertService.showAlertPopup('Success', 'Security question and answer saved successfully.', 'fa-solid fa-check', 'OK');
        this.SaveSuccessfully.emit(true);
        catchError((err: any) => {
          this.alertService.showAlertPopup('Error', err.error.Message, 'fa-solid fa-xmark', 'OK'); throw err
        })
      });
  }
}


export class SecurityQuestionAnswerModel {
  public AnswerValue: string;
  public QuestionTextValue: string;
  public QuestionValue: number;
  public securityQuestionList: QuestionModel[];
}

export class QuestionModel {
  public Question: string;
  public QuestionNumber: number;
}
export class SaveSecurityQuestionAnswerModel {
  public saveSecurityQuestionAnswerList: SaveSecurityQuestionAnswer[];
  public password: string;
  public UserID: string;
}
export class SaveSecurityQuestionAnswer {
  public AnswerValue: string;
  public QuestionTextValue: string;
}