<app-breadcrumb></app-breadcrumb>

<div class="row webpartrow">
    <div class="col-lg-12">
        <dx-tab-panel [(selectedIndex)]="tab1Index" (onTitleClick)="onTab1ItemClick($event)"  [swipeEnabled]="false" [animationEnabled]="false" [elementAttr]="{ class: 'custom_tabpanel' }">
            <dxi-item title="Review">
                <div class="list-control">
                <app-grid #grdMyDocuments_Review [IsUnauthorizedPage]="false"
                    (gridCustomActions)="DocumentgridCustomActions($event)" [isRowClick]="true" (rowClick)="rowClickDistributedGrid($event)" instanceID="MyDocuments_Review"></app-grid></div>
            </dxi-item>
            <dxi-item title="Distributed">
                <div class="list-control">
                <app-grid #grdMyDocuments_Distributed [IsUnauthorizedPage]="false"
                    (gridCustomActions)="DocumentgridCustomActions($event)"  [isRowClick]="true" (rowClick)="rowClickDistributedGrid($event)"  instanceID="MyDocuments_Distributed" ></app-grid></div>
            </dxi-item>
            <dxi-item title="Public">
                <div class="list-control list-control-withbtn">
                <app-grid #grdMyDocuments_Public [IsUnauthorizedPage]="false"
                (gridCustomActions)="DocumentgridCustomActions($event)" instanceID="MyDocuments_Public"></app-grid></div>
            </dxi-item>
        </dx-tab-panel>
        <app-document-assests-viewer-popup #DocumentDocViewPopup instanceID="MyDocuments_DocumentAssetViewerPopup"
            ></app-document-assests-viewer-popup>
        <app-document-upload-popup #DocumentDocUploadPopup instanceID="MyDocuments_DocumentUploadPopup"
            ></app-document-upload-popup>
    </div>
</div>