<app-breadcrumb></app-breadcrumb>
<div class="row webpartrow">
    <dx-tab-panel [(selectedIndex)]="tab1Index" (onTitleClick)="onTab1ItemClick($event)"  [animationEnabled]="false" [elementAttr]="{ class: 'custom_tabpanel' }">
            <dxi-item [visible]="isactiveTimeSeriesTabVisible"  title="{{'active'|translate}}">
                <app-grid  instanceID="activeTimeSeriesGrid" (isGridHidden)="hideActiveTimeSeriesTabVisible($event)" [IsUnauthorizedPage]="false"></app-grid>    
            </dxi-item>
            <dxi-item [visible]="isinactiveTimeSeriesTabVisible" title="{{'inActive'|translate}}">
                <app-grid instanceID="inactiveTimeSeriesGrid" (isGridHidden)="hideInActiveTimeSeriesTabVisible($event)"  [IsUnauthorizedPage]="false" ></app-grid>
            </dxi-item>
            <dxi-item [visible]="isdeletedTimeSeriesTabVisible" title="{{'deleted'|translate}}">
                <app-grid instanceID="deletedTimeSeriesGrid" (isGridHidden)="hideDeletedTimeSeriesTabVisible($event)" [IsUnauthorizedPage]="false"></app-grid>
            </dxi-item>
        </dx-tab-panel>
</div>