import { HttpClient, HttpFeatureKind } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ObjectCommandModel, RibbonControlResponse, TransitionModel } from 'src/app/models/ObjectCommandModel';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { environment } from 'src/environments/environment';
import { Guid } from 'guid-typescript';
import { Observable, Subscription, lastValueFrom } from 'rxjs';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { AppEvent } from 'src/app/models/AppEvent';
import { AppEventType } from 'src/app/enums/appEventType';
import { DxFormComponent, DxPopupComponent } from 'devextreme-angular';
import { ValidationPopupComponent } from '../validation-popup/validation-popup.component';
import { NoteModel } from 'src/app/models/NoteModel';
import dxForm from 'devextreme/ui/form';
import { EncryptionService } from 'src/app/encryption.service';
import { RibbonCommandDetailResponseModel } from 'src/app/models/RibbonCommandDetailResponseModel';
import { SignalRService } from 'src/app/services/signalr-service/signalr-service.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-ribbon-control',
  templateUrl: './ribbon-control.component.html',
  styleUrls: ['./ribbon-control.component.scss']
})

export class RibbonControlComponent extends PageBaseService  implements OnInit , OnDestroy {
  @ViewChild("customNotePopup",{static:false}) CustomNotePopup:DxPopupComponent;
  @ViewChild("validationpopup") validationpopup: ValidationPopupComponent;
  @ViewChild("customNoteForm") customNoteForm:dxForm  
  emptyGUID: string = Guid.EMPTY;
  componentID: string = "ribboncontrol";
  @Input() instanceID: string;
  @Input() parentRecordID: string;
  @Input() relationshipRecordID: string;
  @Input() newID: string;
  ribbonControlResponse: RibbonControlResponse;
  ParentEnumerationDescriptionTxt: string = '';
  isRibbonVisible: Boolean = false;
  isTransitionsLoaded: boolean = false;
  transitionTypeName:string = '';
  transitionNote:string = '';
  customNotePopupLbltxt:string = '';
  isMandatoryNote:boolean = false;
  isComponentVisible: boolean = false;
  transitionAPIEndpoint:string = ''
  changeBody:any = ''
  currentTranstion: TransitionModel = new TransitionModel();
  currentTranstionIndex: any = -1;
  saveNotesApiEndpoint:string = ''
  ribbonCommandDetailResponse: RibbonCommandDetailResponseModel = new RibbonCommandDetailResponseModel();
  okBtnTxt: string = "Ok";
  @Input() IsEditMode:boolean=true;
  ribbonSubscription: Subscription;
  @Input() loadOnInit:boolean=true;
  public hideTransitionAndCommands:boolean = false;

  constructor( private signalRService: SignalRService,private route: ActivatedRoute, private localStorageService: LocalStorageService, private _router: Router, private _enc: EncryptionService, private httpClient: HttpClient,
    public translateService: TranslateService, private eventQueue: LobasEventQueueService, private alertService: AlertService) {
      super(_router, _enc);
  }


  ngOnInit() {
    
    this.translateService.get('OkButtonText').subscribe((translated: string) => {
    this.okBtnTxt = translated;
    });
    if(this.loadOnInit) {
      this.bindEnumerationTransitions();
    }
    this.ribbonSubscription = this.eventQueue.on(AppEventType.PerformRibbonTransitionOperation).subscribe((payload=>{
      if(payload && payload.payload){
        this.ribbonCommandDetailResponse = payload.payload;
      }
      this.performTransitionAction();
    }));    

    this.ribbonSubscription = this.eventQueue.on(AppEventType.RefreshRibbonTransitionOnCommandUpdate).subscribe((payload=>{
      var ribbonPayload;
      if(payload && payload.payload){
        ribbonPayload = payload.payload;
      }
      this.parentRecordID = ribbonPayload.RecordID;
      this.bindEnumerationTransitions();
    })); 
  }

  ngOnDestroy() {
    if(this.ribbonSubscription) {
      this.ribbonSubscription.unsubscribe();
    }
  }

  public bindEnumerationTransitions() {
    if (this.parentRecordID == '') {
      this.parentRecordID = Guid.EMPTY;
    }
    if (this.relationshipRecordID == '') {
      this.relationshipRecordID = Guid.EMPTY;
    }
    if (this.newID == '') {
      this.newID = Guid.EMPTY;
    }

    var url = "api/EnumerationTransition/GetChildEnumTransitionsAndRelatedCommands/" + this.componentID + "/" + this.instanceID + "/" + this.parentRecordID + '/' + this.relationshipRecordID + '/' + this.newID;
    var response = lastValueFrom(
      this.httpClient.get(environment.apiUrl + url)
    ).then((value => {
      this.isTransitionsLoaded = true;
      this.ribbonControlResponse = <RibbonControlResponse>value;      
      if(this.newID == Guid.EMPTY && this.parentRecordID.length>0 && this.parentRecordID != Guid.EMPTY ){
        this.ribbonControlResponse.Commands.forEach(x=>{
            if(x.Name.toLowerCase() === 'save as draft'){
              x.Name = 'Save';
            }
        })
      }else{
        this.parentRecordID = this.newID;
      }
      this.ParentEnumerationDescriptionTxt = this.setParentEnumerationDescriptionTxt();
    }));
  }

  setParentEnumerationDescriptionTxt(): string {
    var titleTxt: string = '';
    this.transitionTypeName = this.ribbonControlResponse.RibbonCtrlProperties.FieldLabelPrefixText ?? '';
    if (this.transitionTypeName.length > 0 && this.ribbonControlResponse.ParentEnumerationDescription.length > 0) {
      titleTxt = this.transitionTypeName + ' is ' + this.ribbonControlResponse.ParentEnumerationDescription;
    }
    else if (this.transitionTypeName.length > 0 && this.ribbonControlResponse.ParentEnumerationDescription.length <= 0) {
      titleTxt = this.transitionTypeName + ' is not applicable';
    }
    else if (this.transitionTypeName.length <= 0 && this.ribbonControlResponse.ParentEnumerationDescription.length > 0) {
      this.transitionTypeName = this.ribbonControlResponse.RibbonCtrlProperties.EnumerationFieldName.replace('_EnumID', '').split(/(?=[A-Z])/).join(' ');
      titleTxt =  this.transitionTypeName + ' is ' + this.ribbonControlResponse.ParentEnumerationDescription;
    }
    else {
      titleTxt = '';
      this.isComponentVisible = true;
    }
    return titleTxt;
  }


  commandAction(command: ObjectCommandModel) {
    if(command && command.Definition && command.Definition.WarningPopUpMessage)
    {
      this.translateService.get('ConfirmationMessage', { command: command.Command }).subscribe((text: string) => 
      {
        var Icon =""
        if(command.Definition.WarningPopUpIcon)
          {
            Icon = "<i class='fa-solid "+command.Definition.WarningPopUpIcon+"'></i>";
          }
          else
          {
            Icon = "<i class='fa-solid fa-question'></i>";
          }

          
          var YesButtonText:string="";
          this.translateService.get('YesButtonText').subscribe((translated: string) => {
            YesButtonText = translated;
          });


          var NoButtonText:string="";
          this.translateService.get('NoButtonText').subscribe((translated: string) => {
            NoButtonText = translated;
          });

        Swal.fire({
          title: command.Definition.WarningPopUpTitle,
          text: command.Definition.WarningPopUpMessage,
          iconHtml: Icon,
          confirmButtonText: YesButtonText,
          showCancelButton: true,
          cancelButtonText: NoButtonText,
          allowOutsideClick : false
        }).then((response: any) => {
          if (response.isConfirmed) {
            this.eventQueue.dispatch(new AppEvent(AppEventType.PerformRibbonCommandOperation, command));
          }
        });
      });
    }
    else
    {
      this.eventQueue.dispatch(new AppEvent(AppEventType.PerformRibbonCommandOperation, command));
    }
   
  }

  transitionAction(transition: TransitionModel) {    
    if(transition.ExtendedAttributes)
      {
        var parser = new DOMParser();
        var xmlDoc = parser.parseFromString(transition.ExtendedAttributes, "text/xml");
        if(xmlDoc.getElementsByTagName("ExtendedAttributesObjectCollection")
          && xmlDoc.getElementsByTagName("ExtendedAttributesObjectCollection")[0] 
          && xmlDoc.getElementsByTagName("ExtendedAttributesObjectCollection")[0].getElementsByTagName("ExtendedAttributeEntity")[0]
          && xmlDoc.getElementsByTagName("ExtendedAttributesObjectCollection")[0].getElementsByTagName("ExtendedAttributeEntity")[0].attributes["Type"]
          && xmlDoc.getElementsByTagName("ExtendedAttributesObjectCollection")[0].getElementsByTagName("ExtendedAttributeEntity")[0].attributes["Type"].nodeValue
          && xmlDoc.getElementsByTagName("ExtendedAttributesObjectCollection")[0].getElementsByTagName("ExtendedAttributeEntity")[0].attributes["Type"].nodeValue.toLowerCase() =="lobas.extendedattributes.warningmessageokcancel"
        )
        {
          var Title = xmlDoc.getElementsByTagName("ExtendedAttributesObjectCollection")[0].getElementsByTagName("ExtendedAttributeEntity")[0].getElementsByTagName("WarningMessageOKCancel")[0].getElementsByTagName("Title")[0].firstChild.nodeValue.toString();
          var PopUpMessage = xmlDoc.getElementsByTagName("ExtendedAttributesObjectCollection")[0].getElementsByTagName("ExtendedAttributeEntity")[0].getElementsByTagName("WarningMessageOKCancel")[0].getElementsByTagName("Message")[0].firstChild.nodeValue.toString();
          var Icon = xmlDoc.getElementsByTagName("ExtendedAttributesObjectCollection")[0].getElementsByTagName("ExtendedAttributeEntity")[0].getElementsByTagName("WarningMessageOKCancel")[0].getElementsByTagName("Icon")[0].firstChild.nodeValue.toString();
          

          var YesButtonText:string="";
          this.translateService.get('YesButtonText').subscribe((translated: string) => {
            YesButtonText = translated;
          });


          var NoButtonText:string="";
          this.translateService.get('NoButtonText').subscribe((translated: string) => {
            NoButtonText = translated;
          });

          Swal.fire({
            title: Title,
            text: PopUpMessage,
            iconHtml: "<i class='fa-solid fa-question'></i>",
            confirmButtonText: YesButtonText,
            showCancelButton: true,
            cancelButtonText: NoButtonText,
            allowOutsideClick : false
          }).then((response: any) => {
            if (response.isConfirmed) {
              this.currentTranstion = transition;
              this.eventQueue.dispatch(new AppEvent(AppEventType.RibbonTransitionClick, { transition }));
            }
          });
      
        }
        else{
          this.currentTranstion = transition;
          this.eventQueue.dispatch(new AppEvent(AppEventType.RibbonTransitionClick, { transition }));
        }
      }
      else
      {
        this.currentTranstion = transition;
        this.eventQueue.dispatch(new AppEvent(AppEventType.RibbonTransitionClick, { transition }));
      }
  }

  performTransitionAction(){
    if (this.ribbonControlResponse && this.ribbonControlResponse.RibbonCtrlProperties && this.ribbonControlResponse.RibbonCtrlProperties.ObjectID.length >0) {
      var fieldName = this.ribbonControlResponse.RibbonCtrlProperties.EnumerationFieldName;
      this.changeBody =  '{"' + fieldName  + '" : "' + this.currentTranstion.EnumerationID + '"}';
      this.transitionAPIEndpoint = environment.apiUrl + this.ribbonControlResponse.RibbonCtrlProperties.TransitionEndPoint + '/' + this.parentRecordID;
      this.saveNotesApiEndpoint = environment.apiUrl + this.ribbonControlResponse.RibbonCtrlProperties.NoteApiEndPoint 
      + '/' + this.parentRecordID + '/' +  this.ribbonControlResponse.RibbonCtrlProperties.NoteRelationshipID;
      if(this.ribbonControlResponse.RibbonCtrlProperties.NoteRelationshipID 
        &&
        this.ribbonControlResponse.RibbonCtrlProperties.NoteRelationshipID.length>0 
        &&
        this.ribbonControlResponse.RibbonCtrlProperties.NoteRelationshipID != Guid.EMPTY       
      ){      
        if(this.ribbonControlResponse.Transitions.length >0){
          this.saveNotesAndUpdateTransition(); 
        }
            
      }else{
        this.updateTransition();
      }
    }
  }

  showAlertAndRedirectToCustomPageIfSave(){
    if(this.ribbonCommandDetailResponse &&  this.ribbonCommandDetailResponse.RecordID && this.ribbonCommandDetailResponse.RecordID.length > 0){
      
      this.alertService.showConfirmationAlert('Success', this.transitionTypeName + ' updated successfully','fa-solid fa-check',false).then((response: any) => {
        if (response.isConfirmed) {          
          this.route.url.subscribe(async (event)=> {
            var jobID= await this.GetKeyValueFromQueryString("JobID");
            var retUrl = await this.GetReturnURL();
            var retUrlEnabled = await this.GetKeyValueFromQueryString("ReturnUrlEnabled");
            if(retUrl && retUrlEnabled && retUrlEnabled.toLowerCase()==="true")
              {
                this.RedirectToEncryptUrl(retUrl);
              }
            else{
                if(jobID)
                {
                    this.RedirectToEncryptUrl(event[0].path+'?RecordID='+ this.ribbonCommandDetailResponse.RecordID+'&JobID='+jobID);            
                }
                else{
                  this.RedirectToEncryptUrl(event[0].path+'?RecordID='+ this.ribbonCommandDetailResponse.RecordID);
                }
            }
            
          });
        }
      });
      
    }else{
      this.alertService.showAlertPopup('Success', this.transitionTypeName + ' updated successfully', 'fa-solid fa-check', 'OK');
    }
  }

  showAlertAndRedirectIfSave(){
    if(this.ribbonCommandDetailResponse &&  this.ribbonCommandDetailResponse.updateNewIDWithRecordIDInURL && this.ribbonCommandDetailResponse.NewID && this.ribbonCommandDetailResponse.NewID.length > 0){
      
      this.alertService.showConfirmationAlert('Success', this.transitionTypeName + ' updated successfully','fa-solid fa-check',false).then((response: any) => {
        if (response.isConfirmed) {          
          this.route.url.subscribe(async (event)=> {
            var retUrl = await this.GetReturnURL();
            var retUrlEnabled = await this.GetKeyValueFromQueryString("ReturnUrlEnabled");
            if(retUrl && retUrlEnabled && retUrlEnabled.toLowerCase()==="true")
              {
                this.RedirectToEncryptUrl(retUrl);
              }
            else{
            this.RedirectToEncryptUrl(event[0].path+'?RecordID='+ this.ribbonCommandDetailResponse.NewID);
          }
          });
        }
      });
      
    }else{
      this.alertService.showAlertPopup('Success', this.transitionTypeName + ' updated successfully', 'fa-solid fa-check', 'OK');
    }
  }
  cancelNotePopup(){
    this.CustomNotePopup.visible = false;
    this.transitionNote = '';  
  }
  updateTransition(e:any = null){
    this.changeBody= JSON.parse(this.changeBody)
    if(this.checkValidation(e)){
      if(this.transitionAPIEndpoint.indexOf("updatenew") > 0){
        var relationshipDict:any={};
        relationshipDict.FieldValues = this.changeBody;
        this.changeBody=relationshipDict;
      }
    var result = lastValueFrom(
      this.httpClient.put(this.transitionAPIEndpoint, this.changeBody, 
      { headers: {
        'objectID': this.ribbonControlResponse.RibbonCtrlProperties.ObjectID
      },responseType: 'text' }
      )).then((response: any) => {
        if(this.ribbonControlResponse.RibbonCtrlProperties.NoteRelationshipID 
          &&
          this.ribbonControlResponse.RibbonCtrlProperties.NoteRelationshipID.length >0 
          &&
          this.ribbonControlResponse.RibbonCtrlProperties.NoteRelationshipID != Guid.EMPTY
        ){
          //Save Notes relationship
           this.signalRService.sendMessage(this.getNoteModel(),  this.parentRecordID, this.ribbonControlResponse.RibbonCtrlProperties.NoteRelationshipID)
           if(this.CustomNotePopup.visible)
            {
              this.transitionNote = this.defaultNote(); 
              this.signalRService.sendMessage(this.getNoteModel(),  this.parentRecordID, this.ribbonControlResponse.RibbonCtrlProperties.NoteRelationshipID)
            }
           this.CustomNotePopup.visible = false;
           if(this.ribbonControlResponse?.RibbonCtrlProperties?.ObjectID?.toUpperCase() =="E8548C49-1CC3-4D3F-8CE7-EAB74B1EE593"
               ||(this.ribbonControlResponse?.RibbonCtrlProperties?.ObjectID?.toUpperCase() =="256EE4E0-3B90-43D7-A6C2-CD6C89ABA647" &&
                this.newID===Guid.EMPTY))
                {
                 this.showAlertAndRedirectToCustomPageIfSave()
                }
               else{
                  this.showAlertAndRedirectIfSave();
                }
                this.transitionNote = ''; 
                this.bindEnumerationTransitions();        
        }else{
          if(this.ribbonControlResponse?.RibbonCtrlProperties?.ObjectID?.toUpperCase() =="E8548C49-1CC3-4D3F-8CE7-EAB74B1EE593")
            {
              this.showAlertAndRedirectToCustomPageIfSave()
            }
            else{
              this.showAlertAndRedirectIfSave();
            }
          this.bindEnumerationTransitions();    
        }
      });
    }
  }

  defaultNote():string{
    var fieldLabeltxt:string = '';
    fieldLabeltxt = this.transitionTypeName + ' transitioned from ' + 
    this.ribbonControlResponse.Transitions[0].ParentEnumerationDescription + ' to ' 
    + this.currentTranstion.Description;
    
    return fieldLabeltxt;
  }

  saveNotesAndUpdateTransition(){
    if(this.currentTranstion.TransitionTemplate_EnumID && this.currentTranstion.TransitionTemplate_EnumID.toString() != Guid.EMPTY){
        if(this.currentTranstion.TransitionTemplate_EnumID.toString() == '00000000-0000-0001-4300-000000000002'){
              this.isMandatoryNote = true;
        }
        this.customNotePopupLbltxt = "The current status of '" + this.ribbonControlResponse.ParentEnumerationDescription + "' will change to '" + this.currentTranstion.Description + "'";
        this.CustomNotePopup.visible = true;
    }else{
      this.transitionNote = this.defaultNote();
      this.updateTransition();
    }    
  }

  openRibbonControl() {
    this.isRibbonVisible = !this.isRibbonVisible;
  }

  getNoteModel():NoteModel{
    var note = new NoteModel();
    note.RecordID = Guid.create().toString();
    note.NoteType_EnumID = '5E100908-B8DC-48E7-B746-86404CEB023C';
    note.Note = this.transitionNote;

    return note;
  }

  checkValidation(e:any):Boolean{  
      var validationmessage = ""; 
      var isValid:Boolean = false;   
      if(e && e.validationGroup){      
        isValid = e.validationGroup?.validate().isValid;
        if (!isValid) {
          const brokenRules = e.validationGroup.validate().brokenRules;
          validationmessage = brokenRules.map((rule: any) => {
            return "<p>" + rule.message + "</p>"
          }).join("");
          this.validationpopup.showPopup(validationmessage);        
        }
      }else{
        isValid = true;
      }
      return isValid;
      }
  }