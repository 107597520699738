<div class="container">
    <div class="form-control">
        <div class="d-flex">
         <div class="justify-content-start">
            <div class="d-flex">
                <div class="m-2">EA</div>
                <div class="m-2">
                    UNFILLED
                </div>
            </div>
         </div>

        
        <div class="d-flex">
            <div class="justify-content-end">
                <div class="d-flex">
                <div class="m-2">
                    <dx-date-box >
                    </dx-date-box>
                </div>
                <div class="m-2">
                    <dx-date-box>
                    </dx-date-box>
                </div>
            </div>
            </div>
        </div>    
    </div>
    </div>
</div>
