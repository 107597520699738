export  class ColumnDefaultWidth{

    getColumnDefaultWidth (overrideUIType: string,): number {
    let width = 0;
    switch (overrideUIType.toUpperCase()) {
      case 'ICON':
      case 'USERICON':
        width = 44;
        break;
      case 'DATE':
        width = 200;
        break;
      case 'DATETIME':
        width = 190;
        break;
      case 'CHECKBOX':
        width = 100;
        break;
      case 'COMBOBOX':
      case 'ENUMERATIONCOMBOBOX':
        width = 150;
        break;
      case 'DECIMALINPUTBOX':
        width = 100;
        break;
      case 'UICOLOR':
      case 'TIME':
        width = 110;
        break;
    }

    return width;
}
  getColumnDefaultMinWidth (overrideUIType: string,): number {
    let width;
    switch (overrideUIType.toUpperCase()) {
      case 'ICON':
      case 'USERICON':
        width = 44;
        break;
      case 'DATE':
        width = 200;
        break;
      case 'DATETIME':
        width = 200;
        break;
      case 'CHECKBOX':
        width = 100;
        break;
      case 'COMBOBOX':
      case 'ENUMERATIONCOMBOBOX':
        width = 100;
        break;
      case 'DECIMALINPUTBOX':
        width = 100;
        break;
      case 'UICOLOR':
      case 'TIME':
        width = 100;
        break;
    }
    return width;
  }
}