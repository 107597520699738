<div class="webpart-card">
    <div class="webpart-cardbody">
        <div class="webpart-card-header" [ngClass]="this.isVisible ? 'webpart-card-closed' : 'webpart-card-opened'">
            <div class="webpart-card-header-title">
                <span>Links</span>
            </div>
            <div class="webpart-card-header-action">
                <dx-button (onClick)="expandCollaspeComponent()"
                    [icon]="isVisible ? 'fa-regular fa-angle-up' : 'fa-regular fa-angle-down'"></dx-button>
            </div>
        </div>

        <div [hidden]="isVisible">
            <div class="contact_link_body">
                <p><strong>iOS Users:</strong> Tap and hold on links to show.</p>
                <p>Don't know your vehicle emmisions? Fint out <a href="https://vehicleenquiry.service.gov.uk/" target="_blank">here</a></p>
                <p>Need to update your personal details? Contact HR on 03301194450 or email <a
                        href="mailto:hr@rtggroup.co.uk?subject=Update%20Personal%20Details%20Request" target="_blank">hr@rtggroup.co.uk</a></p>
                <p>Request annual leave email <a href="mailto:hr@rtggroup.co.uk?subject=Leave%20Request" target="_blank">hr@rtggroup.co.uk</a></p>
                <p>Need to report faulty equipment? Contact Stores on 03301194450 or email <a
                        href="mailto:stores@rtggroup.co.uk?subject=Faulty%20Equipment" target="_blank">stores@rtggroup.co.uk</a></p>
                <p>Zest (Worker Health & Wellbeing Assistance Tool) - <a href="https://app.mypossibleself.com/" target="_blank">Login here</a></p>
                <p>Drive IQ (Fleet Driver Assistance Tool) - <a href="https://app.driveiq.com/check" target="_blank">Login here</a></p>
            </div>
        </div>

    </div>
</div>