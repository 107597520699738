import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/encryption.service';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { NotificationViewComponent } from '../notification-view/notification-view.component';
import { Guid } from 'guid-typescript';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { RibbonControlComponent } from '../common/ribbon-control/ribbon-control.component';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Component({
  selector: 'app-notification-recipient-details',
  templateUrl: './notification-recipient-details.component.html',
  styleUrls: ['./notification-recipient-details.component.scss']
})
export class NotificationRecipientDetailsComponent extends PageBaseService {

  ComponentObjectID: string;


  recordID: string = Guid.EMPTY;
  relationshipRecordID: string = Guid.EMPTY;
  newID: string = Guid.EMPTY;
  parameterSet: Boolean = false;
  RelationshipID: string;

  notificationID: string;
  NotificationQueryStringId :string;
  loggedinUserID: string = '';
  @ViewChild("notificationDetail", { static: true }) notificationDetail: NotificationViewComponent
  @ViewChild("NotificationRecipientRibbonControl", { static: false }) NotificationRecipientRibbonControl: RibbonControlComponent


  constructor(private auth: AuthService, private eventQueue: LobasEventQueueService,private notificationService: NotificationService, private _router: Router, private _enc: EncryptionService, private httpClient: HttpClient) {
    super(_router, _enc);
    this.ComponentObjectID = '7F103B80-4EB5-45F5-97FF-BFC67C04CA6B';
    this.loggedinUserID = this.auth.getGuidUserID() ?? '';
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }
  ngOnInit() {
    this.getAllQueryStrings();
  }
  getAllQueryStrings() {
    this.GetKeyValueFromQueryString('RecordID').then((recordID => {
      this.recordID = recordID;
      this.GetKeyValueFromQueryString('NRID').then((notificationId => {
        this.NotificationQueryStringId = notificationId;
       
      this.relationshipRecordID = Guid.EMPTY;
      this.newID = Guid.EMPTY;
      this.parameterSet = true;
      this.notificationDetail.BindDataWithRelatedRecords('90476D69-8F5E-4DA2-B5E8-962588D9FE94', this.recordID, '7F103B80-4EB5-45F5-97FF-BFC67C04CA6B');
      this.RedirectToActionURL(this.recordID);
    }));
    }));
  }

  RedirectToActionURL(recordID: string) {
    this.httpClient.get<any>(environment.apiUrl + 'api/NotificationRecipient/GetNotificationRecipientByRelationshipIdAndRecordID/D70AB205-4698-4E02-B107-A1D8849F4645/' + recordID)
      .subscribe((response) => {
        if (response) {
          var notificationRecipient;
          response.forEach(element => {
            if (element.Object1_RecordID.toLowerCase() == recordID.toLowerCase() && element.Object2_RecordID.toLowerCase() == this.loggedinUserID.toLowerCase()) {
              notificationRecipient = element;
            }
          });
          if (notificationRecipient) {            
            if (notificationRecipient.ActionURL != null) {
              if (!notificationRecipient.ActionURL.toLowerCase().includes(window.location.pathname.toLowerCase())) {
                if(this.NotificationQueryStringId != "" && this.NotificationQueryStringId != '' && this.NotificationQueryStringId != null&& this.NotificationQueryStringId != undefined ){
                  this.updateNotificationReadStatus(this.NotificationQueryStringId, notificationRecipient.NotificationStatus_EnumID);
                }
                this.RedirectToEncryptUrl(notificationRecipient.ActionURL);
              }
              else if (recordID && !notificationRecipient.ActionURL.toLowerCase().includes("recordid=" + recordID.toLowerCase())) {
                if(this.NotificationQueryStringId != "" && this.NotificationQueryStringId != '' && this.NotificationQueryStringId != null&& this.NotificationQueryStringId != undefined ){
                  this.updateNotificationReadStatus(this.NotificationQueryStringId, notificationRecipient.NotificationStatus_EnumID);
                }
                this.RedirectToEncryptUrl(notificationRecipient.ActionURL);
              }
              else  {
                this.updateNotificationReadStatus(recordID, notificationRecipient.NotificationStatus_EnumID);
              }
            }
          }
          else {
            this._router.navigateByUrl('unauthorised');
          }
        }
        else {
          this._router.navigateByUrl('unauthorised');
        }
        

      });
  }

  updateNotificationReadStatus(recordID:string, notificationStatus_EnumID: string) {
    if (recordID != "" && recordID != '' && recordID != null && recordID != undefined) {
    if(notificationStatus_EnumID.toLowerCase() === '00000000-0000-0001-4100-000000000004' || notificationStatus_EnumID.toLowerCase() === '00000000-0000-0001-4100-000000000004' ){
      this.notificationService.updateNotificationReadStatus(recordID).then((resp)=>{
        this.NotificationRecipientRibbonControl.bindEnumerationTransitions();  
      });
    }
  }
  }
}
 