export const workerSelection = [{
    "ID": 1,
    "Status": "Available",
    "Name": "Darren East",
    "Fatigue": "icongreen fa-regular fa-thumbs-up",
    "Hrs Rem": 120,
    "Distance (miles)": 45,
    "Time (mins)": 55,
}, {
    "ID": 2,
    "Status": "Available",
    "Name": "Jack Morris",
    "Fatigue": "icongreen fa-regular fa-thumbs-up",
    "Hrs Rem": 91,
    "Distance (miles)": 26,
    "Time (mins)": 35,
}, {
    "ID": 3,
    "Status": "Available",
    "Name": "Simon Porter",
    "Fatigue": "icongreen fa-regular fa-thumbs-up",
    "Hrs Rem": 0,
    "Distance (miles)": 10,
    "Time (mins)": 20,
},
{
    "ID": 4,
    "Status": "Available",
    "Name": "Bill Taylor",
    "Fatigue": "iconyellow fa-regular fa-triangle-exclamation",
    "Hrs Rem": 120,
    "Distance (miles)": 45,
    "Time (mins)": 55,
},
{
    "ID": 5,
    "Status": "Available",
    "Name": "Stu Stead",
    "Fatigue": "iconred fa-regular fa-thumbs-down",
    "Hrs Rem": 400,
    "Distance (miles)": 10,
    "Time (mins)": 20,
},
{
    "ID": 6,
    "Status": "Available",
    "Name": "Tim Winter",
    "Fatigue": "iconred fa-regular fa-thumbs-down",
    "Hrs Rem": 40,
    "Distance (miles)": 26,
    "Time (mins)": 35,
},
{
    "ID": 7,
    "Status": "Unavailable",
    "Name": "Bill fergus",
    "Fatigue": "icongreen fa-regular fa-thumbs-up",
    "Hrs Rem": 91,
    "Distance (miles)": 2,
    "Time (mins)": 10,
},
{
    "ID": 8,
    "Status": "Unavailable",
    "Name": "Holly Ford",
    "Fatigue": "icongreen fa-regular fa-thumbs-up",
    "Hrs Rem": 0,
    "Distance (miles)": 11,
    "Time (mins)": 15,
},
{
    "ID": 9,
    "Status": "Unavailable",
    "Name": "Simon Heading",
    "Fatigue": "icongreen fa-regular fa-thumbs-up",
    "Hrs Rem": 3,
    "Distance (miles)": 2,
    "Time (mins)": 10,
},
{
    "ID": 10,
    "Status": "Unavailable",
    "Name": "Shah Pir",
    "Fatigue": "iconyellow fa-regular fa-triangle-exclamation",
    "Hrs Rem": 5,
    "Distance (miles)": 45,
    "Time (mins)": 55,
},
{
    "ID": 11,
    "Status": "Unavailable",
    "Name": "Rich Richards",
    "Fatigue": "iconred fa-regular fa-thumbs-down",
    "Hrs Rem": 0,
    "Distance (miles)": 100,
    "Time (mins)": 130,
},
{
    "ID": 12,
    "Status": "Unavailable",
    "Name": "Zo Zhi",
    "Fatigue": "iconred fa-regular fa-thumbs-down",
    "Hrs Rem": 91,
    "Distance (miles)": 300,
    "Time (mins)": 240,
},]

export const shiftPlanner = [{
    "ID": 1,
    "Trade": "COSS",
    "Workers": "Steve Jones",
    "Actual Start": "19/06/2024 22:00",
    "Actual Finish": "19/06/2024 01:00",
    "Hold": "",
    "Person Team": "4 Person Team",
}, {
    "ID": 2,
    "Trade": "AP",
    "Workers": "Unfilled",
    "Actual Start": "19/06/2024 22:00",
    "Actual Finish": "19/06/2024 01:00",
    "Hold": "",
    "Person Team": "4 Person Team",
}, {
    "ID": 3,
    "Trade": "EA",
    "Workers": "Ken Naylor",
    "Actual Start": "19/06/2024 22:00",
    "Actual Finish": "19/06/2024 01:00",
    "Hold": "",
    "Person Team": "4 Person Team",
}, {
    "ID": 4,
    "Trade": "NP",
    "Workers": "Unfilled",
    "Actual Start": "19/06/2024 22:00",
    "Actual Finish": "19/06/2024 01:00",
    "Hold": "",
    "Person Team": "4 Person Team",
}, {
    "ID": 5,
    "Trade": "EA",
    "Workers": "Unfilled",
    "Actual Start": "19/06/2024 22:00",
    "Actual Finish": "19/06/2024 01:00",
    "Hold": "",
    "Person Team": "2 Additional Person Team",
}, {
    "ID": 6,
    "Trade": "EA",
    "Workers": "Unfilled",
    "Actual Start": "19/06/2024 22:00",
    "Actual Finish": "19/06/2024 01:00",
    "Hold": "",
    "Person Team": "2 Additional Person Team",
} ]