<div class="webpart-card">
    <div class="webpart-cardbody">
        <div class="webpart-card-header" [ngClass]="isVisible ? 'webpart-card-closed' : 'webpart-card-open'">
            <div class="webpart-card-header-title">
                <span>{{this.AddressesProperties.Title}}</span>
            </div>
            <div class="webpart-card-header-action">
                <dx-button (onClick)="expandCollaspeComponent()"
                    [icon]="isVisible ? 'fa-regular fa-angle-up' : 'fa-regular fa-angle-down'"></dx-button>
            </div>
        </div>
        <div [hidden]="isVisible">
            <form method="post">
                <div class="form">
                    <div class="form-group-row">
                        <div class="field_group" *ngFor='let add of lstAddresses;'>
                            <div class="field_label">{{add.AddressType}}</div>
                            <div class="field_control">
                                <ng-container
                                    *ngIf="this.AddressesProperties.Mode.toLowerCase() === 'edit'; else viewMode">
                                    <!-- Edit mode: Render dx-text-box -->
                                    <dx-text-box [value]="add.Value"
                                        (onValueChanged)="add.Value = $event.value"></dx-text-box>
                                </ng-container>

                                <ng-template #viewMode>
                                    <!-- View mode: Render div -->
                                    <div *ngIf="add.AddressType === 'Mobile Number' || add.AddressType === 'Telephone Number' ; else text">
                                        <strong><a href="tel:+{{ add.Value }}">{{ add.Value }}</a></strong>
                                      </div>
                                      <ng-template #text>
                                        <strong>{{ add.Value }}</strong>
                                      </ng-template>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div class="form-btn-outer" *ngIf="this.AddressesProperties.Mode.toLowerCase() === 'edit'">
                        <dx-button text="Submit" (onClick)="onSubmit($event)">
                        </dx-button>
                    </div>
                </div>
            </form>
        </div>
    </div>