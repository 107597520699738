import { Component, ViewChild } from '@angular/core';
import { DxValidationGroupComponent } from 'devextreme-angular';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BreadcrumbService, ObjectRecordDetails } from 'src/app/services/breadcrumb/breadcrumb.service';
import { ValidationPopupComponent } from '../validation-popup/validation-popup.component';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss']
})
export class SendEmailComponent {
  message: string = ""
  recipients: string = ""
  subject: string = ""
  priorityList: string[] = ["Low", "Normal", "High"];
  priorityTextValue: string = ""
  mailData: MailData;
  objectRecordDetails: ObjectRecordDetails = null;
  @ViewChild('securityQuestion', {static: false}) sendEmailValidationGroup: DxValidationGroupComponent
  @ViewChild("sendEmailValidationpopup") validationpopup: ValidationPopupComponent;
  constructor(private breadcrumbService: BreadcrumbService, private auth: AuthService) {

  }
  async BindData(recordID, objectID, objectName,priorityText=null) {

    if (!this.objectRecordDetails) {
      this.objectRecordDetails = await this.breadcrumbService.GetObjectRecordDetails(this.auth.getGuidToken() ?? '', recordID, objectID);
    }
    var subject = objectName;
    //subject = Regex.Replace(subject, "([a-z])([A-Z])", "$1 $2");
    subject += " Reference " + this.objectRecordDetails.ObjectDescription;
    this.subject = subject;
    this.priorityTextValue = priorityText
  }

  IsValid(e:any):boolean
  {
    var validationmessage = "";
    const { isValid } = this.sendEmailValidationGroup.instance.validate();
    if (!isValid) {
      const brokenRules = this.sendEmailValidationGroup.instance.validate().brokenRules;
      validationmessage = brokenRules.map((rule: any) => {
        return "<p>" + rule.message + "</p>"
      }).join("");
    }

    if (isValid)
    {
      return true;
    }
    else{
      this.validationpopup.showPopup(validationmessage);
      return false;
    }
  }

  GetMailFromData(MailBody: string = "") {
    var Priority = this.priorityTextValue == null ? "Normal" : this.priorityTextValue;
    this.mailData = new MailData();
    this.mailData.Priority = Priority;
    this.mailData.MailBody = MailBody + "<br><br>" + this.message + "<br><br>";
    this.mailData.MailTo = this.recipients;
    this.mailData.Subject = this.subject;
    return this.mailData;
  }
  ClearForm() {
    this.recipients = "";;
    this.subject = "";
    this.message = "";
  }
}

export class MailData {
  public Priority: string = ''
  public Subject: string = ''
  public MailTo: string = ''
  public MailBody: string = ''
}
