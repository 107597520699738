import { Component, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DxPopupComponent } from 'devextreme-angular';

@Component({
  selector: 'app-security-questions-popup',
  templateUrl: './security-questions-popup.component.html',
  styleUrls: ['./security-questions-popup.component.scss']
})
export class SecurityQuestionsPopupComponent {

  @ViewChild("SecurityQuestionPopupUp", { static: true }) SecurityQuestionPopupUp: DxPopupComponent;
  @Input() IsShowPopUp: boolean;
  IsShowHeader: boolean = false;


  constructor(private translateService: TranslateService)
  {
    
  }

  ngOnInit() {
    if (this.IsShowPopUp) {
      this.ShowPopUp(true)
    }
  }

  ShowPopUp(Show:boolean) {
    if(Show)
      {
        this.SecurityQuestionPopupUp.visible = true;
      }
      else
      {
        this.SecurityQuestionPopupUp.visible = false;
      }
    
  }

  SaveSuccessfully(event: boolean) {
    if (event) {
      this.SecurityQuestionPopupUp.visible = false;
    }
  }
}
