import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent } from 'src/app/Components/common/grid/grid.component';
import { EncryptionService } from 'src/app/encryption.service';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { ComponentInfo } from 'src/app/models/ComponentInfo';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent extends PageBaseService{
 // @ViewChild("grdProjectGrid", { static: true }) grdProject!: GridComponent;
 isActiveTabVisible: boolean =false;
 isInActiveTabVisible:boolean=false;
 isDeletedTabVisible:boolean=false;

 tab1Index:number=0;
 isInitComplete: boolean=false;
 ProjectGrid= new ComponentInfo();
  ComponentObjectID :string = 'C7671C7A-67E0-4128-8444-16A05076FAE8';
  constructor(private eventQueue:LobasEventQueueService, private _router: Router, private _enc: EncryptionService, private localStorageService: LocalStorageService) {
    super(_router, _enc);  
    this.ComponentObjectID = 'C7671C7A-67E0-4128-8444-16A05076FAE8';
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }
  async ngOnInit()
  {
    var tabNumber = await this.GetKeyValueFromQueryString("TabControl");
    if (tabNumber) {
      this.tab1Index = Number(tabNumber) - 1;
      this.SetTabIndex("tab1Index", this.tab1Index, this.localStorageService);
    }
    else {
      this.tab1Index = this.GetTabIndex("tab1Index", this.localStorageService);
      this.SetTabIndex("tab1Index", this.tab1Index, this.localStorageService);
    }
      this.isInitComplete = true;
     var filterByQuerystring = await this.GetKeyValueFromQueryString('FilterBy');
  }
 

  hideActiveTab(isGridHidden) {
    this.isActiveTabVisible = !isGridHidden;
  }

  hideInActiveTab(isGridHidden) {
    this.isInActiveTabVisible = !isGridHidden;
  } 

  onTab1ItemClick(ev: any) 
  {    
    this.SetTabIndex("tab1Index",ev?.itemIndex,this.localStorageService);
  }
}

