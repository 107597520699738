<app-breadcrumb></app-breadcrumb>

<app-send-close-call-report-popup #closeCallReport></app-send-close-call-report-popup>

<div class="row webpartrow list-control">
    <div class="col-lg-12 m-b-30">
        <ng-container *ngIf="!!isInitComplete">
        <dx-tab-panel [(selectedIndex)]="tab1Index" (onTitleClick)="onTab1ItemClick($event)"  [swipeEnabled]="false" [animationEnabled]="false" 
            [elementAttr]="{ class: 'custom_tabpanel' }">
            <dxi-item [visible]="isMyClosecallTabVisible" #tabCloseCall title="My">
                <app-grid #grdCloseCall (gridCustomActions)="gridCustomActions($event)"
                    [filterExpressionsArrayByQueryString]="filterExpressionsArrayByQueryString"
                    [suscribeToRibbonCommandTransitionAction]="true" [gridProperties]="myclosecallgrid"
                    (isGridHidden)="hidecloseCallTab($event)" [IsUnauthorizedPage]="false"
                    instanceID="myclosecallgrid"></app-grid>
            </dxi-item>
            <dxi-item [visible]="isOpenClosecallTabVisible" #tabOpenCloseCall title="Open">
                <app-grid #grdAllClosecall (gridCustomActions)="gridCustomActions($event)" [IsUnauthorizedPage]="false"
                    instanceID="openclosecallgrid" (isGridHidden)="hideOpenClosecallTab($event)"></app-grid>
            </dxi-item>
            <dxi-item [visible]="isAllClosecallTabVisible" #tabAll title="All">
                <app-grid #grdAllClosecall (gridCustomActions)="gridCustomActions($event)" [IsUnauthorizedPage]="false"
                    instanceID="allclosecallgrid" (isGridHidden)="hideAllClosecallTab($event)"></app-grid>
            </dxi-item>
            <dxi-item [visible]="isDeletedClosecallTabVisible" #tabAll title="Deleted">
                <app-grid #grdDeletedClosecall (gridCustomActions)="gridCustomActions($event)"
                    [IsUnauthorizedPage]="false" instanceID="deletedclosecallgrid"
                    (isGridHidden)="hideDeletedClosecallTab($event)"></app-grid>
            </dxi-item>
        </dx-tab-panel>
    </ng-container>
    </div>
</div>