<app-breadcrumb [ComponentObjectID]="ComponentObjectID"></app-breadcrumb>

<div class="row webpartrow">
    <div class="col-lg-12 webpartcol screen-100">
        <div class="m-b-30">
            <app-questionnaire-response-editor [recordID]="recordID" *ngIf="parameterSet" [userRoles]="userRoles"
                [loggedinUserID]="this.loggedinUserID"></app-questionnaire-response-editor>
        </div>
    </div>
    <!-- <div class="m-b-30">
        <app-ribbon-control  *ngIf="parameterSet"  instanceID="QuestionnaireResponseRibbonControl"
                    [parentRecordID]="this.recordID" [relationshipRecordID]="this.relationshipRecordID"
                    [newID]="this.newID"></app-ribbon-control>
    </div> -->
    <div class="m-b-30">
        <app-chat *ngIf="parameterSet" [recordID]="recordID" [loggedinUserID]=this.loggedinUserID
            instanceID='QuestionnaireResponse_Note'></app-chat>
    </div>
</div>