<!-- ***************************This code has been generated using LOBAS Templates 4.0.23.0  -->

<app-breadcrumb [ComponentObjectID]="componentObjectID"></app-breadcrumb>

<div class="row webpartrow">
    <div class="col-lg-12 webpartcol screen-100">
        <div class="m-b-30">
            <app-grid #assetDetail (gridCustomActions)="gridCustomActions($event)"
                [executeUpdateTransitionStatusFromRibbonCtrl]="true" [suscribeToRibbonCommandTransitionAction]="true"
                [IsUnauthorizedPage]="false" instanceID="assetDetail"></app-grid>
        </div>
    </div>
</div>
<div class="row webpartrow">
    <div class="col-lg-6 webpartcol screen-50">
        <div class="m-b-30">
            <app-extended-attributes #fleetDetails *ngIf="this.parameterSet" instanceID="fleetDetails"
                [recordID]="recordID"></app-extended-attributes>
        </div>
    </div>
    <div class="col-lg-6 webpartcol screen-50">
        <div class="m-b-30">
            <app-grid #grdassetdetDocumentGrid (gridCustomActions)="DocumentgridCustomActions($event)" [IsUnauthorizedPage]="false" instanceID="AssetDetail_CalibrationCertificatesDocumentGrid"></app-grid>
        </div>
        <div class="m-b-30">
            <app-grid [IsUnauthorizedPage]="false" #grdAssetDocument instanceID="AssetDetail_AssetDocumentGrid" (gridCustomActions)="DocumentgridCustomActions($event)"></app-grid>
            <app-document-assests-viewer-popup instanceID="assetDetails_DocumentAssetViewerPopup" #assetDocViewPopup></app-document-assests-viewer-popup>
            <app-document-upload-popup instanceID="assetDetails_DocumentUploadPopUp" #assetDocUploadPopup></app-document-upload-popup>
        </div>
        <div class="m-b-30">
            <app-chat *ngIf="parameterSet" [recordID]="this.recordID" instanceID='assetDetails_Notes'></app-chat>
        </div>
    </div>
</div>
<div class="row webpartrow">
    <div class="col-lg-12 webpartcol screen-100 m-b-30">
        <app-grid #assetTestGrid (gridCustomActions)="gridCustomActions($event)" [IsUnauthorizedPage]="false"
            instanceID="AssetDetails_AssetTestGrid"></app-grid>
    </div>
    <div class="col-lg-6 offset-lg-6 webpartcol screen-50">
        <div class="m-b-30">
            <app-ribbon-control *ngIf="parameterSet" instanceID="AssetRibbonControl" [parentRecordID]="this.recordID"
                [relationshipRecordID]="this.relationshipRecordID" [newID]="this.newID"></app-ribbon-control>
        </div>
    </div>
</div>