import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../localStorage/local-storage.service';
import { EnumHelper } from 'src/app/util/enum-helper';
import { EnumerationModel } from 'src/app/models/EnumerationModel';
import { ComponentPropertiesService } from '../componentProperties/component-properties.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../alertService/alert.service';
import { AppointmentModel } from 'src/app/models/AppointmentModel';

@Injectable({
  providedIn: 'root'
})
export class SchedulerService {
  Filter: any;
  relationshipValues: {[key: string]: string} = {};
  constructor(private httpClient: HttpClient, private localStorageService: LocalStorageService, public translateService: TranslateService, public alertService: AlertService) { }

  async getappointEventypeResources() {
    var enumerationTypeDescription = "Appointment Event type";
    return this.GetEnumerationListByTypeDescription(enumerationTypeDescription);
  }

  GetEnumerationListByTypeDescription(enumerationType: string): PromiseLike<EnumerationModel[]> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.apiUrl + "api/EnumerationUnAuthorize/GetEnumerationListByTypeDescription/" + enumerationType)
        .subscribe((response) => {
          resolve(<EnumerationModel[]>response);
        });
    });
  }

  getCurrentUsersAppointments(filter) {
    var getURL = environment.apiUrl + "api/appointment/getAppointmentListByContactID";
    var putURL = '';
    var deleteURL = '';
    return this.customStore(getURL, putURL, deleteURL, filter);
  }

  customStore(getURL, putURL, deleteURL, customfilter) {
    var httpClient = this.httpClient;
    var localStorage = this.localStorageService;
    return new CustomStore({
      key: "RecordID",
      byKey: (key) => {
        return key;
      },
      async load(loadOptions) {
        let params: HttpParams = new HttpParams();
        [
          'skip',
          'take',
          'sort',
          'filter',
          'totalSummary',
          'group',
          'groupSummary'
        ].forEach((i) => {
          if (i == 'filter') {
            var filter = customfilter;
            if (filter != null) {
              if (!(filter[0] instanceof Array)) {
                var array = filter;
                filter = [];
                filter[0] = array;
              }
              var customFillter = [];
              customFillter[customFillter.length] = filter;
            }
            loadOptions[i] = customFillter;
          }
          if (i in loadOptions && loadOptions[i] !== undefined && loadOptions[i] !== null && loadOptions[i] !== '') { params = params.set(i, JSON.stringify(loadOptions[i])); }
        });
        if (getURL.length > 0) {
          var response = lastValueFrom(httpClient.get(
            getURL
            , { params }))
            .then((data: any) => (
              {
                data: data.data ?? data,
                totalCount: data.totalCount,
                summary: data.summary,
                groupCount: data.groupCount,
              }),
              error => { })
            .finally(() => {
            });
          return response;
        }
      },
    });
  }

  timeZones: any[] = [
    { name: 'Morocco Standard Time', value: 'Africa/Casablanca' }
    , { name: 'GMT Standard Time', value: 'Europe/London' }
    , { name: 'Greenwich Standard Time', value: 'Africa/Monrovia' }
    , { name: 'W. Europe Standard Time', value: 'Europe/Berlin' }
    , { name: 'Central Europe Standard Time', value: 'Europe/Prague' }
    , { name: 'Romance Standard Time', value: 'Europe/Madrid' }
    , { name: 'Central European Standard Time', value: 'Europe/Belgrade' }
    , { name: 'W. Central Africa Standard Time', value: 'Africa/Lagos' }
    , { name: 'Jordan Standard Time', value: 'Asia/Amman' }
    , { name: 'GTB Standard Time', value: 'Europe/Bucharest' }
    , { name: 'Middle East Standard Time', value: 'Asia/Beirut' }
    , { name: 'Egypt Standard Time', value: 'Africa/Cairo' }
    , { name: 'South Africa Standard Time', value: 'Africa/Harare' }
    , { name: 'FLE Standard Time', value: 'Europe/Helsinki' }
    , { name: 'Israel Standard Time', value: 'Asia/Jerusalem' }
    , { name: 'Belarus Standard Time', value: 'Europe/Minsk' }
    , { name: 'Namibia Standard Time', value: 'Africa/Windhoek' }
    , { name: 'Arabic Standard Time', value: 'Asia/Baghdad' }
    , { name: 'Arab Standard Time', value: 'Asia/Kuwait' }
    , { name: 'Russian Standard Time', value: 'Europe/Moscow' }
    , { name: 'E. Africa Standard Time', value: 'Africa/Nairobi' }
    , { name: 'Georgian Standard Time', value: 'Asia/Tbilisi' }
    , { name: 'Iran Standard Time', value: 'Asia/Tehran' }
    , { name: 'Arabian Standard Time', value: 'Asia/Dubai' }
    , { name: 'Azerbaijan Standard Time', value: 'Asia/Baku' }
    , { name: 'Mauritius Standard Time', value: 'Indian/Mauritius' }
    , { name: 'Caucasus Standard Time', value: 'Asia/Yerevan' }
    , { name: 'Afghanistan Standard Time', value: 'Asia/Kabul' }
    , { name: 'Ekaterinburg Standard Time', value: 'Asia/Yekaterinburg' }
    , { name: 'Pakistan Standard Time', value: 'Asia/Karachi' }
    , { name: 'West Asia Standard Time', value: 'Asia/Tashkent' }
    , { name: 'India Standard Time', value: 'Asia/Calcutta' }
    , { name: 'Nepal Standard Time', value: 'Asia/Kathmandu' }
    , { name: 'N. Central Asia Standard Time', value: 'Asia/Almaty' }
    , { name: 'Central Asia Standard Time', value: 'Asia/Dhaka' }
    , { name: 'Sri Lanka Standard Time', value: 'Asia/Colombo' }
    , { name: 'Myanmar Standard Time', value: 'Asia/Yangon' }
    , { name: 'SE Asia Standard Time', value: 'Asia/Jakarta' }
    , { name: 'North Asia Standard Time', value: 'Asia/Krasnoyarsk' }
    , { name: 'China Standard Time', value: 'Asia/Shanghai' }
    , { name: 'North Asia East Standard Time', value: 'Asia/Ulaanbaatar' }
    , { name: 'Singapore Standard Time', value: 'Asia/Singapore' }
    , { name: 'W. Australia Standard Time', value: 'Australia/Perth' }
    , { name: 'Taipei Standard Time', value: 'Asia/Taipei' }
    , { name: 'Tokyo Standard Time', value: 'Asia/Tokyo' }
    , { name: 'Korea Standard Time', value: 'Asia/Seoul' }
    , { name: 'Yakutsk Standard Time', value: 'Asia/Yakutsk' }
    , { name: 'Cen. Australia Standard Time', value: 'Australia/Adelaide' }
    , { name: 'AUS Central Standard Time', value: 'Australia/Darwin' }
    , { name: 'E. Australia Standard Time', value: 'Australia/Brisbane' }
    , { name: 'AUS Eastern Standard Time', value: 'Australia/Sydney' }
    , { name: 'West Pacific Standard Time', value: 'Pacific/Guam' }
    , { name: 'Tasmania Standard Time', value: 'Australia/Hobart' }
    , { name: 'Vladivostok Standard Time', value: 'Asia/Vladivostok' }
    , { name: 'Magadan Standard Time', value: 'Asia/Magadan' }
    , { name: 'New Zealand Standard Time', value: 'Pacific/Auckland' }
    , { name: 'Fiji Standard Time', value: 'Pacific/Fiji' }
    , { name: 'Russia Time Zone 11', value: 'Asia/Kamchatka' }
    , { name: 'Tonga Standard Time', value: 'Pacific/Tongatapu' }
    , { name: 'Azores Standard Time', value: 'Atlantic/Azores' }
    , { name: 'Cape Verde Standard Time', value: 'Atlantic/Cape_Verde' }
    , { name: 'Mid-Atlantic Standard Time', value: 'America/Noronha' }
    , { name: 'E. South America Standard Time', value: 'America/Argentina/Buenos_Aires' }
    , { name: 'Argentina Standard Time', value: 'America/Argentina/Buenos_Aires' }
    , { name: 'SA Western Standard Time', value: 'America/Argentina/Buenos_Aires' }
    , { name: 'Greenland Standard Time', value: 'America/Nuuk' }
    , { name: 'Montevideo Standard Time', value: 'America/Montevideo' }
    , { name: 'Newfoundland Standard Time', value: 'Canada/Newfoundland' }
    , { name: 'Paraguay Standard Time', value: 'America/Asuncion' }
    , { name: 'Atlantic Standard Time', value: 'Canada/Atlantic' }
    , { name: 'SA Western Standard Time', value: 'America/Argentina/San_Juan' }
    , { name: 'SA Western Standard Time', value: 'America/Manaus' }
    , { name: 'Pacific SA Standard Time', value: 'America/Santiago' }
    , { name: 'Venezuela Standard Time', value: 'America/Caracas' }
    , { name: 'SA Pacific Standard Time', value: 'America/Bogota' }
    , { name: 'Eastern Standard Time', value: 'US/Eastern' }
    , { name: 'US Eastern Standard Time', value: 'America/Indiana/Indianapolis' }
    , { name: 'Central America Standard Time', value: 'US/Central' }
    , { name: 'Central Standard Time', value: 'US/Central' }
    , { name: 'Central Standard Time (Mexico)', value: 'America/Mexico_City' }
    , { name: 'Central Standard Time (Mexico)', value: 'Canada/Saskatchewan' }
    , { name: 'US Mountain Standard Time', value: 'US/Arizona' }
    , { name: 'Mountain Standard Time (Mexico)', value: 'America/Mazatlan' }
    , { name: 'Mountain Standard Time', value: 'US/Mountain' }
    , { name: 'Pacific Standard Time', value: 'US/Pacific' }
    , { name: 'Pacific Standard Time (Mexico)', value: 'America/Tijuana' }
    , { name: 'Alaskan Standard Time', value: 'US/Alaska' }
    , { name: 'Hawaiian Standard Time', value: 'US/Hawaii' }
    , { name: 'Samoa Standard Time', value: 'US/Samoa' }
    , { name: 'Dateline Standard Time', value: 'Etc/GMT+12' }
  ];

  getTimezoneName(timezoneName: string): string {
    if (this.timeZones.filter(x => x.name == timezoneName).length > 0) {
      return this.timeZones.filter(x => x.name == timezoneName)[0].value;
    }
    else {
      return '';
    }
  }

  addAppointmentWithRelationship(appointment: AppointmentModel, parentID: string, relationshipID: string) {
    var relationshipDict: any = {};
    
    relationshipDict.FieldValues = appointment;
    this.relationshipValues["ParentRecordID"] = parentID;
     this.relationshipValues["RelationshipID"] = relationshipID;
     relationshipDict.RelationshipValues = this.relationshipValues
    return this.httpClient.post(environment.apiUrl + "api/Appointment/insertnew/" , relationshipDict, { responseType: "text" }
    );
  }

  updateAppointment(appointment: AppointmentModel, recordID: string) {
    var relationshipDict: any = {};
    relationshipDict.FieldValues = appointment;

    return this.httpClient.put(environment.apiUrl + 'api/Appointment/Updatenew/' + recordID,
      relationshipDict
      , {
        headers: {
          'objectID': 'AF614F3A-2830-410C-AE6D-9699388E7DA0'
        },
        responseType: "text"
      }
    )
  }


  getObjectCommandWithPermission(ObjectId :string){
   return this.httpClient.get(environment.apiUrl + "api/ObjectCommand/GetByObjectID/" + ObjectId);
  }

}



