import { Injectable } from "@angular/core";
import { Network } from "@capacitor/network";
import { AlertService } from "../alertService/alert.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { lastValueFrom } from "rxjs";
import { OfflineDataHelperService, actionQueueDB } from "./offline-data-helper.service";
import { CookieService } from "ngx-cookie-service";
import { Guid } from "guid-typescript";
import { SyncOfflineChangeNotificationModel } from "src/app/models/SyncOfflineChangeNotificationModel"; 
import { TranslateService } from "@ngx-translate/core";
import { LobasEventQueueService } from "../lobasEventQueueService/lobas.event.queueservice";
import { AppEventType } from "src/app/enums/appEventType";
import { AppEvent } from "src/app/models/AppEvent";

@Injectable({
    providedIn:
        'root'
})
export class ConnectionStateService {
    public isOnline: Boolean = true;
    isSyncExecuted: boolean = false;
    tokenID: string;
    batchId: Guid;


    constructor(private httpClient: HttpClient, private cookieService: CookieService, 
        private translateService: TranslateService, 
        private eventQueue: LobasEventQueueService, private alertService: AlertService
        ,private db: OfflineDataHelperService) {
        Network.addListener("networkStatusChange", status => { this.notifyConnectionChange() });
        this.tokenID = cookieService.get('TokenID');
    }

    notifyConnectionChange() {
        Network.getStatus().then(changedStatus => {
            if (changedStatus.connected) {
                this.syncChanges();
                this.isOnline = true;
            } else {
                this.translateService.get('DeviceOffline').subscribe((translated: string) => {
                    this.alertService.showMiniAlerts(translated, "fa-solid fa-xmark", 2000);
                });                
                this.isOnline = false;
                this.isSyncExecuted = false;
                this.eventQueue.dispatch(new AppEvent(AppEventType.ConnectionStateChanged, "NotConnected"));
            }
        });
    }

    async syncChanges() {
        if (!this.isSyncExecuted) {
            this.isSyncExecuted = true;
            var offlineChanges = await this.db.actionQueueDB.filter(x => x.IsConflicted === false).toArray();

            this.translateService.get('DeviceOnline').subscribe(async (translated: string) => {
                await this.alertService.showMiniAlerts(translated, "fa-solid fa-check", 2000);
            });
            this.translateService.get('InitiateOfflineChangeAsync').subscribe(async (translated: string) => {
                await this.alertService.showMiniAlerts(translated, "fa-solid fa-triangle-exclamation", 2000);
            });
            if (offlineChanges.length > 0) {
                this.batchId = Guid.create();
                for (const index in offlineChanges) {
                    var currentChange = offlineChanges[index];
                    this.makeHttpRequest(currentChange);
                }
            } else {
                this.translateService.get('NoChangeSync').subscribe(async (translated: string) => {
                    await this.alertService.showMiniAlerts(translated, "fa-solid fa-check", 2000);
                });                
            }
        }
    }

    makeHttpRequest(currentChange: actionQueueDB) {
        var relationshipDict:any = {};
        relationshipDict.FieldValues = currentChange.Data;
        switch (currentChange.HttpType.toLocaleLowerCase()) {
            case "delete":
                lastValueFrom(
                    this.httpClient.delete(environment.apiUrl + currentChange.APIActionEndPoint,
                        {
                            body: currentChange.Data,
                            headers: {
                                'localChangeDate': currentChange.ChangeTime.toJSON(),
                                'objectRecordID': currentChange.RecordID,
                                'tokenID': this.tokenID,
                                'httpType': currentChange.HttpType,
                                'objectID':currentChange.ObjectID
                            },
                            responseType: 'text'
                        },
                    )).then((response: any) => {
                        this.updateRecordPostSync(true, currentChange.RecordID);
                    }).catch((error) => {
                        this.updateRecordPostSync(false, currentChange.RecordID);
                    });
                break;
            case "post":
                
                lastValueFrom(
                    this.httpClient.post(environment.apiUrl + currentChange.APIActionEndPoint,
                        relationshipDict,
                        {
                            headers: {
                                'localChangeDate': currentChange.ChangeTime.toJSON(),
                                'objectRecordID': currentChange.RecordID,
                                'tokenID': this.tokenID,
                                'httpType': currentChange.HttpType,
                                'objectID':currentChange.ObjectID
                            },
                            responseType: 'text'
                        }
                    )).then((response: any) => {
                        this.updateRecordPostSync(true, currentChange.RecordID);
                    }).catch((error) => {
                        this.updateRecordPostSync(false, currentChange.RecordID);
                    });
                break;
            case "put":
                lastValueFrom(
                    this.httpClient.put(environment.apiUrl + currentChange.APIActionEndPoint + currentChange.RecordID,
                        relationshipDict,
                        {
                            headers: {
                                'localChangeDate': currentChange.ChangeTime.toJSON(),
                                'objectRecordID': currentChange.RecordID,
                                'tokenID': this.tokenID,
                                'httpType': currentChange.HttpType,
                                'objectID':currentChange.ObjectID
                            },
                            responseType: 'text'
                        }
                    )).then((response: any) => {
                        this.updateRecordPostSync(true, currentChange.RecordID);
                    }).catch((error) => {
                        this.updateRecordPostSync(false, currentChange.RecordID);
                    });
                break;
        }
    }

    async updateRecordPostSync(IsSuccess: boolean, key: string) {
        if (IsSuccess) {
            this.db.actionQueueDB.delete(key);
        } else {
            this.db.actionQueueDB.where('RecordID').equals(key).modify({ IsConflicted: true });
        }
        var offlineChanges = await this.db.actionQueueDB.toArray();
        if (offlineChanges.filter(x => x.IsConflicted === true).length > 0
            && offlineChanges.filter(x => x.IsConflicted === false).length == 0) {
            lastValueFrom(
                this.httpClient.post(environment.apiUrl + 'api/Notification/SendNotification',this.getNotificationObject())
            ).then((response) => {
                    this.db.actionQueueDB.clear();
            }).catch(err=>{   err });
        }

        if (offlineChanges.filter(x => x.IsConflicted === false).length == 0) {
            this.eventQueue.dispatch(new AppEvent(AppEventType.ConnectionStateChanged, "Connected"));
        }
    }


    getNotificationObject():SyncOfflineChangeNotificationModel{        
        var OfflineChangeNotProcessed:string="";
        this.translateService.get('OfflineChangeNotProcessed').subscribe((translated: string) => {
            OfflineChangeNotProcessed = translated;
        });
        var ErrorDuringOfflineChange:string="";
        this.translateService.get('ErrorDuringOfflineChange').subscribe((translated: string) => {
            ErrorDuringOfflineChange = translated;
        });
        var notification:SyncOfflineChangeNotificationModel= new SyncOfflineChangeNotificationModel();
        notification.ActionUrl = window.location.hostname,
        notification.ChaseDate = new Date(),
        notification.ContactIDs = this.cookieService.get('UserID'),
        notification.Message = OfflineChangeNotProcessed
        notification.Subject = ErrorDuringOfflineChange
        return notification;
    }
}