<app-breadcrumb></app-breadcrumb>

<div class="row webpartrow">
    <div class="col-lg-12 webpartcol screen-100">
        <div class="m-b-30">
            <div class="webpart-card">
                <div class="webpart-cardbody">
                    <div class="webpart-card-header" [ngClass]="isVisible ? 'webpart-card-closed' : 'webpart-card-open'">
                        <div class="webpart-card-header-title">
                            <span>Appointment Details</span>
                        </div>
                        <div class="webpart-card-header-action">
                            <dx-button (onClick)="expandCollaspeComponent()"
                                [icon]="isVisible ? 'fa-regular fa-angle-up' : 'fa-regular fa-angle-down'"></dx-button>
                        </div>
                    </div>
                    <div [hidden]="isVisible">
                        <app-webpartplaceholder></app-webpartplaceholder>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>