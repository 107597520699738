export class CardSliderComponentProperties {
    Title: string = '';
    AllowedRoles: string = '';
    RefreshInterval:number;
    SlideDetails: SlideDetails;
}

export class SlideDetails {
    Slides: Slides[];
}

export class Slides {                    
    SlideName: string;
    RecordID: string;
    Icon: string;
    Color: string;
    BackgroundColor: string;
    BorderColor: string;
    URL:string;
}

export class SlideValues {                    
    Name: string;
    Count:number;
    Icon: string;
    Color: string;
    BackgroundColor: string;
    BorderColor: string;
    URL:string;
}