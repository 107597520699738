<app-breadcrumb></app-breadcrumb>
<div class="row webpartrow">
    <div class="col-lg-12">
        <div class="webpart-card">
            <div class="webpart-cardbody">
                <div class="webpart-card-header" [ngClass]="this.isVisible ? 'webpart-card-closed' : 'webpart-card-opened'">
                    <div class="webpart-card-header-title">
                        <span>Asset List</span>
                    </div>
                    <div class="webpart-card-header-action">
                        <dx-button (onClick)="expandCollaspeComponent()"
                            [icon]="isVisible ? 'fa-regular fa-angle-up' : 'fa-regular fa-angle-down'"></dx-button>
                    </div>
                </div>
                <div [hidden]="isVisible">
                    <app-webpartplaceholder></app-webpartplaceholder>
                </div>
            </div>
        </div>
    </div>
</div>