<div class="login-background"><img src="{{brandingService.GetSplashScreen()}}" /></div>
<div class="login-container">
    <div class="card my-auto shadow">
        <div class="card-body">
            <div class="logo-head ">
                <img class="login_logo" src="{{brandingService.GetDesktopLogo()}}" />
            </div>
            <div style="height: 5px;"></div>
            <p class="text-center"><span class="unauth_page_span">Sorry, you do not have access to this page.</span>
                Please contact your System Administrator for <span class="unauth_page_span unauth_page_span_bottom"> further assistance.</span>
            </p>
            <div class="text-center">
                <dx-button (onClick)="redirectToHome()" text="Continue"></dx-button>
            </div>
        </div>
    </div>

</div>