import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddressModel, GetContactAddressByAddressType, MapLocationImageModel } from 'src/app/models/Address';
import { environment } from 'src/environments/environment';
import { HttpHelperService } from '../httpHelper/http-helper.service';
import { AuthService } from '../auth/auth.service';
import { AlertService } from '../alertService/alert.service';
import { catchError } from 'rxjs';
import { LobasEventQueueService } from '../lobasEventQueueService/lobas.event.queueservice';
import { AppEvent } from 'src/app/models/AppEvent';
import { AppEventType } from 'src/app/enums/appEventType';
import { RelationshipRecordModel } from 'src/app/models/RelationshipRecordModel';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(private httpClient: HttpClient, private httpHelper: HttpHelperService, private authService: AuthService, private alertService: AlertService) { }
  
  GetAddressByRecordID( recordID: string): Promise<AddressModel> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.apiUrl + 'api/Address/Get/' + recordID )
        .subscribe((response) => {
          console.log(<AddressModel>response)
          resolve(<AddressModel>response);
        });
    });
  }

  SetDefaultAddress(RecordID: string,eventQueue:LobasEventQueueService, refreshGrid: boolean,ShowMessage:boolean=false) {
    this.httpClient.put<boolean>(environment.apiUrl + 'api/Address/SetDefaultAddress/' + RecordID.toString(), {})
      .subscribe((response) => {
        if(refreshGrid)
        {
          eventQueue.dispatch(new AppEvent(AppEventType.ActionUpdateSuccess, true));
        }
        if(ShowMessage)
        {
            this.alertService.showAlertPopup('Success', "Set default successfully.", 'Success', 'OK'); 
        }  
        catchError((err: any) => {
          this.alertService.showAlertPopup('Error', err.error.Message, 'fa-solid fa-xmark', 'OK'); throw err
        })
      });
  }

  GetAddresssList(RelationshipID: string, Object1_RecordID: string, AddressTypeID: string): Promise<AddressModel[]> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.apiUrl + 'api/Address/GetAddressByRelationshipIdAndRecordID/' + RelationshipID + '/' + Object1_RecordID + '/' + AddressTypeID)
        .subscribe((response) => {
          resolve(<AddressModel[]>response);
        });
    });
  }

  GetAddressByRelationShipAndAddressType(userID: string, addressType: string, RelationshipID: string, tenancyID: string): Promise<GetContactAddressByAddressType> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.apiUrl + 'api/Address/GetAddressByRelationShipAndAddressType/' + RelationshipID + '/' + addressType + '/' + userID + '/' + tenancyID)
        .subscribe((response) => {
          resolve(<GetContactAddressByAddressType>response);
        });
    });
  }


  UpdateAddrress(addressModel: AddressModel, RecordID: string,eventQueue:LobasEventQueueService, ShowMessageonUpdate: boolean = null, Default: boolean=false,refreshGrid:boolean=false) {
    this.httpClient.put<any>(environment.apiUrl + 'api/Address/Update/' + RecordID.toString(), JSON.stringify(addressModel)
    )
      .subscribe((response) => {
        if(Default)
        {
          this.SetDefaultAddress(addressModel.RecordID,eventQueue,refreshGrid);
        } 
        else if(refreshGrid)
        {
          eventQueue.dispatch(new AppEvent(AppEventType.ActionUpdateSuccess, true));
        }
        
        if(ShowMessageonUpdate)
        {
            this.alertService.showAlertPopup('Success', "Address updated successfully.", 'Success', 'OK'); 
        }        
        catchError((err: any) => {
          this.alertService.showAlertPopup('Error', err.error.Message, 'fa-solid fa-xmark', 'OK'); throw err
        })
        
      });
      
  }

  InsertAddressWithRelationship(addressModel: AddressModel, parentID: string, relationshipID: string,eventQueue:LobasEventQueueService, ShowMessageonInsert: boolean = null, Default: boolean=false,refreshGrid:boolean=false) {
    this.httpClient.post<any>(environment.apiUrl + 'api/Address/InsertWithRelationship/' + parentID.toString() + '/' + relationshipID.toString(), JSON.stringify(addressModel)
    )
      .subscribe((response) => {
        if(Default)
        {
          this.SetDefaultAddress(addressModel.RecordID,eventQueue,refreshGrid);
        } 
        else if(refreshGrid)
        {
          eventQueue.dispatch(new AppEvent(AppEventType.ActionUpdateSuccess, true));
        }

        if(ShowMessageonInsert)
        {
            this.alertService.showAlertPopup('Success', "Address saved successfully.", 'Success', 'OK'); 
        }  
        
        catchError((err: any) => {
          this.alertService.showAlertPopup('Error', err.error.Message, 'fa-solid fa-xmark', 'OK'); throw err
        })
      });
  }

  InsertAddress(addressModel: AddressModel, eventQueue:LobasEventQueueService,ShowMessageonInsert: boolean = null, Default: boolean=false,refreshGrid:boolean=false) {
    this.httpClient.post<any>(environment.apiUrl + 'api/Address/insert', JSON.stringify(addressModel)
    )
      .subscribe((response) => {

        if(ShowMessageonInsert)
        {
            this.alertService.showAlertPopup('Success', "Address saved successfully.", 'Success', 'OK'); 
        }  
        if(refreshGrid)
        {
          eventQueue.dispatch(new AppEvent(AppEventType.ActionUpdateSuccess, true));
        }
        catchError((err: any) => {
          this.alertService.showAlertPopup('Error', err.error.Message, 'fa-solid fa-xmark', 'OK'); throw err
        })
      });
  }
  InsertUpdateAddressJobRelationshipRecord(relationshipModel: RelationshipRecordModel):Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.post<any>(environment.apiUrl + 'api/RelationshipRecord/InsertUpdateAddressJobRelationshipRecord', JSON.stringify(relationshipModel))
      .subscribe((response) => {
        resolve(<any>response);
      });
    });
  }
  InsertCompanyAndJobAddress(CustomerID:string,addressModel: AddressModel,JobID:string ,ShowMessageonInsert: boolean = false) {
    return new Promise((resolve, reject) => {
    this.httpClient.post<any>(environment.apiUrl + 'api/Address/InsertCompanyAndJobAddress/'+CustomerID +'/'+JobID, JSON.stringify(addressModel))
    .subscribe((response) => {
      resolve(<any>response);
      });
    });
   
  }

}
