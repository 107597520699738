import { Injectable, resolveForwardRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EnumerationModel } from 'src/app/models/EnumerationModel';
import { QuestionnaireResponseWithRelatedItemsModel } from 'src/app/models/QuestionnaireResponseWithRelatedItemsModel';
import { Model, setLicenseKey, Serializer } from "survey-core";
import { formatDate } from '@angular/common';
import { lastValueFrom } from 'rxjs';
import { QuestionnaireResponseModel } from 'src/app/models/QuestionnaireResponseModel';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../alertService/alert.service';
import { BrandingService } from '../branding/branding.service';
import { SignalRService } from '../signalr-service/signalr-service.service';
import { NoteModel } from 'src/app/models/NoteModel';
import { Guid } from 'guid-typescript';
import { PageBaseService } from '../pageBase/page-base.service';
import { EncryptionService } from 'src/app/encryption.service';
import { Router } from '@angular/router';
import { elements } from 'chart.js';


@Injectable({
    providedIn: 'root'
})
export class QuestionnaireResponseService extends PageBaseService  {
    textFieldName;
    valueFieldName='RecordID';
    timeoutId;
    totalScore: number = 0;
    brandingLogoPath;
    surveyModel: Model;
    branding: BrandingService;
    okAlertBtnTxt: string = "Ok";
    successAlertTitle: string = "Success";
    errorAlertTitle: string = "warning";
    notAllowedAlertPopupTitle = "Not Allowed";

    constructor(private _router: Router, private _enc: EncryptionService,private signalRService: SignalRService, private httpClient: HttpClient, public translateService: TranslateService, private alertService: AlertService,
        public brandingService: BrandingService) {   
        super(_router, _enc);
            this.branding = brandingService; 
                       
            this.translateService.get('OkButtonText').subscribe((translated: string) => {
            this.okAlertBtnTxt = translated;
            });
            this.translateService.get('SuccessTitle').subscribe((translated: string) => {
            this.successAlertTitle = translated;
            });
            this.translateService.get('warningText').subscribe((translated: string) => {
            this.errorAlertTitle = translated;
            });
            this.translateService.get('NotAllowedTitle').subscribe((translated: string) => {
            this.notAllowedAlertPopupTitle = translated;
            });
        }

    GetEnumerationListByTypeDescription(enumerationType: string): PromiseLike<EnumerationModel[]> {
        return new Promise((resolve, reject) => {
            this.httpClient.get(environment.apiUrl + "api/EnumerationUnAuthorize/GetEnumerationListByTypeDescription/" + enumerationType)
                .subscribe((response) => {
                    resolve(<EnumerationModel[]>response);
                });
        });
    }
    async getValueForEnumerationId(data: EnumerationModel[], EnumID: string): Promise<string> {
        const filteredResults = data.filter(x => x.EnumerationId.toLowerCase() === EnumID.toLowerCase());
        return filteredResults.length > 0 ? filteredResults[0].Value : '';
    }
    async GetEnumerationIDByTypeValue(data: EnumerationModel[], value: string): Promise<string> {
        const filteredResults = data.filter(x => x.Value.toLowerCase() === value.toLowerCase());
        return filteredResults.length > 0 ? filteredResults[0].EnumerationId : '';
    }


    GetQuestionnaireRespons(recordID: string): PromiseLike<QuestionnaireResponseWithRelatedItemsModel> {
        return new Promise((resolve, reject) => {
            lastValueFrom(this.httpClient.get<QuestionnaireResponseWithRelatedItemsModel>(environment.apiUrl + "api/QuestionnaireResponse/GetQuestionnaireResponseWithRelatedItems/" + recordID))
                .then((response) => {
                    resolve(response);
                });
        });
    }
    ReplaceJsonVariables(questionnaireResponse: QuestionnaireResponseWithRelatedItemsModel): PromiseLike<string> {
        this.brandingLogoPath = this.branding.GetTransparentLogo().toString();
        const locale = localStorage.getItem('UserLanguage');
        var JSONString = questionnaireResponse.QuestionnaireJSON;
        JSONString = JSONString.replace(/<%=QuestionnaireResponseReferenceNumber%>/g, questionnaireResponse.ReferenceNumber);
        JSONString = JSONString.replace(/<%=QuestionnaireResponseRecordID%>/g, questionnaireResponse.RecordID);
        JSONString = JSONString.replace(/<%=QuestionneeName%>/g, questionnaireResponse.QuestionneeName);
        JSONString = JSONString.replace(/<%=QuestionnaireResponseCreatedDate%>/g, formatDate(questionnaireResponse.RecordCreatedDate.toString(), 'short', locale));
        JSONString = JSONString.replace(/<%=QuestionnaireName%>/g, this.replaceSpecialChars(questionnaireResponse.QuestionnaireName));
        JSONString = JSONString.replace(/<%=QuestionnaireDescription%>/g, this.replaceSpecialChars(questionnaireResponse.QuestionnaireDescription));
        JSONString = JSONString.replace(/<%=QuestionnaireVersion%>/g, questionnaireResponse.QuestionnaireVersion);
        JSONString = JSONString.replace(/<%=BrandingLogoPath%>/g, this.brandingLogoPath);
        return new Promise((resolve, reject) => { resolve(JSONString) });
    }


    replaceSpecialChars(value: string): string {
        // value = value.replace(/\\/g, '\\\\');
        // value = value.replace(/"/g, '\\"');
        // value = value.replace(/\b/g, '\\b');
        // value = value.replace(/\t/g, '\\t');
        // value = value.replace(/\n/g, '\\n');
        // value = value.replace(/\f/g, '\\f');
        // value = value.replace(/\r/g, '\\r');
        return value;
    }

    createSurveyModal(recordID: string, questions: string, answer: string, mode: string, ResponseStatus: string, FileName = 'SurveyResult'): PromiseLike<Model> {
        var survey = new Model(questions);
        survey.data = JSON.parse(answer);
        setLicenseKey(
            "OGY2NTliNzktYjAwZi00NmI2LWI2MmQtMDFlZGNiZmQ4ZmIyJmRvbWFpbnM6YmFyZGVsLmNvLnVrLHN0YXRyYWNrLmNvLnVrOzE9MjAyNS0wMi0xOSwyPTIwMjUtMDItMTksND0yMDI1LTAyLTE5"
        );
        Serializer.addProperty("itemvalue", {
            name: "score:number"
        });
        Serializer.getProperty("file", "storeDataAsText").defaultValue = false;
        survey.onGetChoiceDisplayValue.add((_, options) => {
            if (options.question.getType() === "dropdown" && options.question.jsonObj.customDataSource) {
                if (options.question.jsonObj.customNameField) {
                    this.textFieldName = options.question.jsonObj.customNameField;
                }
                const datasource = options.question.jsonObj.customDataSource;
                const url = environment.apiUrl + 'api/' + datasource.replace('Post', '').replace('DDL', '') + '/' + options.question.jsonObj.customDataSource;
                var parameters = JSON.stringify({
                    'RecordID': options.values[0],
                    'Skip': 0,
                    'Take': 0,
                    'TypeEnumValue': "",
                    'Filter': ""
                })
                lastValueFrom(this.httpClient.post<any>(url, parameters))
                    .then((data) => {
                        options.setItems(data.map((x) => this.GetDisplayFieldValue(x, this.textFieldName)));
                    });
            }
        });

        if (mode.toLowerCase() === 'edit') {
            survey.focusFirstQuestionAutomatic = false;
            survey.onChoicesLazyLoad.add((_, options) => {
                if (options.question.getType() === "dropdown" && options.question.jsonObj.customDataSource) {
                    if (options.question.jsonObj.customNameField) {
                        this.textFieldName = options.question.jsonObj.customNameField;
                    }
                    if (options.question.jsonObj.customValueField) {
                        this.valueFieldName = options.question.jsonObj.customValueField;
                    }
                    var FilterValue = "";
                    var customFilterField = "";
                    var TotalRecordCount = 0;
                    if (options.question.jsonObj.customFilterField) {
                        customFilterField = options.question.jsonObj.customFilterField;
                    }
                    FilterValue = options.filter;
                    const datasource =  options.question.jsonObj.customDataSource;
                    const url = environment.apiUrl + 'api/' + datasource.replace('Post', '').replace('DDL', '') + '/' + options.question.jsonObj.customDataSource;

                    var parameters = JSON.stringify({
                        'RecordID': "00000000-0000-0000-0000-000000000000",
                        'Skip': options.skip,
                        'Take': options.take,
                        'TypeEnumValue': customFilterField,
                        'Filter': FilterValue
                    })

                    var resetOptions = true;
                    clearTimeout(this.timeoutId);

                    this.timeoutId = setTimeout(() => {
                        resetOptions = false;
                        lastValueFrom(this.httpClient.post<any>(url, parameters))
                            .then((data) => {
                                if (data && data.length > 0) {
                                    TotalRecordCount = data[0].TotalRecordCount
                                }
                                options.setItems(data.map((x) => this.formatJSON(x, this.textFieldName, this.valueFieldName)), TotalRecordCount);
                            });
                    }, 1000);

                    if (resetOptions) {
                        options.setItems([], 0);
                    }

                }
            });

            survey.onCompleting.add((sender) => {
                const plainData = sender.getPlainData({
                    calculations: [{ propertyName: "score" }]
                });
                const totalScore = this.calculateTotalScore(plainData, survey);
                this.submitQuestionnaireResponse(survey, recordID, sender.data, totalScore, ResponseStatus).then(response=>{
                    var ExitClick = document.querySelector('#spnQRExit')
                    if (ExitClick) {
                      ExitClick.addEventListener("click", async () => {
                        this.GetReturnURL().then(returnUrl => {
                            window.location.href = returnUrl;
                        });
                      });
                    }
                }
                )
            });

            survey.onUploadFiles.add((_, options) => {
                this.processUploadFiles(options, survey, recordID);
            });

            survey.onClearFiles.add(async (_, options) => {

                if (!options.value || options.value.length === 0)
                    return options.callback("success");
                if (!options.fileName && !!options.value) {
                    var TotalFileCount = 0;
                    for (const item of options.value) {
                        this.deleteFile(item.content, options).then(result => {
                            if (TotalFileCount === options.value.length) {
                                this.saveQuestionnaireResponse(recordID, survey, this.totalScore, false);
                                TotalFileCount++;
                            }
                        });
                    }
                } else {
                    const fileToRemove = options.value.find(
                        (item) => item.name === options.fileName
                    );
                    if (fileToRemove) {
                        this.deleteFile(fileToRemove.content, options).then(result => {
                            this.saveQuestionnaireResponse(recordID, survey, this.totalScore, false);
                        });

                    } else {
                        console.error(`File with name ${options.fileName} is not found`);
                    }
                }
            });
            survey.completeText = "Submit";
            survey.showCompletedPage = false;

            survey.addNavigationItem({
                id: "Save As Draft",
                title: "Save As Draft",
                action: () => this.saveQuestionnaireResponse(recordID, survey, this.totalScore, true),
                css: "",
                innerCss: ""
            });
        }

        if (mode.toLowerCase() !== 'edit' && mode.toLowerCase() !== 'exportresult') {
            survey.mode = "display";

        }
        return new Promise((resolve, reject) => { resolve(survey) });
    }
    processUploadFiles(options, survey, recordID) {
        let TotalFileCount = options.files.length;
        let responsecounter = 0;
        let SaveResponse = () => {
            this.saveQuestionnaireResponse(recordID, survey, this.totalScore, false)
        }
        let UploadFile = async (fileName, base64): Promise<string> => {
            return new Promise((resolve, reject) => {
                this.uploadFileToAzure(fileName, base64, recordID)
                    .then(result => {
                        resolve(result)
                    }).catch(error=>  {
                         reject(error)
                    });
            });
        }
        options.files.forEach((file) => {
            if (file) {
                var filereader = new FileReader();
                filereader.readAsDataURL(file);
                filereader.onload = async function (evt) {
                    var base64 = evt.target.result;
                    var fileName = file.name.replaceAll("/", "").replaceAll(" ", "");
                    UploadFile(fileName, base64).then(result => {
                        if (result) {
                            options.callback(
                                options.files.filter(x => x.name.toLowerCase() === file.name.toLowerCase()).map((file) => {
                                    return {
                                        file: file,
                                        content: environment.apiUrl + "api/QuestionnaireResponse/GetQRDocument/" + result
                                    };
                                })
                            );
                            responsecounter++;
                            if (TotalFileCount === responsecounter) {
                                SaveResponse();
                            }
                        }
                    }).catch(error=>  {
                        console.log(error.message);
                        options.callback([], ["Some Error occured."]);
                   });
                }
            }
        });
    }
    async deleteFile(fileURL, options): Promise<string> {
        const name = fileURL.split("/GetQRDocument/")[1];
        return new Promise((resolve, reject) => {
            lastValueFrom(this.httpClient.delete(environment.apiUrl + "api/QuestionnaireResponse/DeleteQRDocument/" + name,
                {
                    responseType: "text"
                }
            )).then(response => {
                resolve(response);
                options.callback("success");

            }).catch(Error => {
                reject(Error);
                options.callback("error");

            })
        });


    }
    async uploadFileToAzure(fileName, base64, RecordID): Promise<string> {
        var FileUpload = {
            Name: fileName,
            Content: base64
        };
        return new Promise((resolve, reject) => {
            lastValueFrom(this.httpClient.post(environment.apiUrl + "api/QuestionnaireResponse/InsertQRDocument/" + RecordID, FileUpload,
                {
                    responseType: "text"
                }
            )).then(response => {
                resolve(response);

            }).catch(Error => {
                reject(Error);

            })
        });
    }

    getquestionnaireResponseModel(recordID: string, response: string, totalScore: number) {
        var questionnaireResponse = new QuestionnaireResponseModel();
        questionnaireResponse.RecordID = recordID;
        questionnaireResponse.Score = totalScore;
        questionnaireResponse.JSON = JSON.stringify(response);
        return questionnaireResponse;
    }


    async getSurveyDataModel(recordID: string,survey:any,totalScore: number):Promise<any>{
        var response = survey.data;
        var insertObject: any = {};
        var relationshipValues: {[key: string]: string} = {};
        var relationshipdata=survey.getAllQuestions().filter(x=>x.getType()=='dropdown');
        var relationshipId="";
        var Object2_RecordID='';
        var ObjectName='';
        ObjectName=relationshipdata[0].jsonObj.customDataSource.replace('Post','').replace('DDL','');
        var questionName = relationshipdata[0].jsonObj.name;
        Object2_RecordID = response[questionName];
        var questionnaireResponse =  this.getquestionnaireResponseModel(recordID, response, totalScore);
        insertObject.FieldValues = questionnaireResponse;
        insertObject.FieldValues[ObjectName] = Object2_RecordID;
            return new Promise((resolve,reject)=>{
                if(relationshipdata.length>0 && relationshipdata[0].jsonObj.customRelationshipName){
                    var relationshipName =   relationshipdata[0].jsonObj.customRelationshipName;
                     this.httpClient.get(environment.apiUrl +'api/Relationship/GetRelationshipID/'+ relationshipName)
                    .subscribe((response: any) => {
                        relationshipId=  response.RecordID
                        relationshipValues[ObjectName]= relationshipId;
                        insertObject.RelationshipValues = relationshipValues;
                        resolve(insertObject);
                    });
                    
                }
                else
                {
                    resolve(insertObject);
                } 
            })
    }

    async saveQuestionnaireResponse(recordID: string, survey: any, totalScore: number, showSuccessPopup: boolean) 
    {
        var insertObject =  await this.getSurveyDataModel(recordID, survey, totalScore);
            lastValueFrom(this.httpClient.put(environment.apiUrl + 'api/QuestionnaireResponse/updatenew/' + recordID,
                insertObject
                , {
                    headers: {
                        'objectID': '7690416F-6319-4A05-94E9-EE539AF7FB7D'
                    },
                    responseType: "text"
                }
            )).then(response => {
                if (showSuccessPopup) {
                    this.translateService.get('UpdateSuccessMessage').subscribe((translated: string) => {
                        this.alertService.showAlertPopup(this.successAlertTitle, translated, 'fa-solid fa-check', this.okAlertBtnTxt);
                    });
    
                }
            });

    }   

    async submitQuestionnaireResponse(survey: any, recordID: string, response: string, totalScore: number, ResponseStatus: string): Promise<string> {
        var insertObject =  await this.getSurveyDataModel(recordID, survey, totalScore);
            insertObject.FieldValues.QuestionnaireResponseStatus_EnumID = '00000000-0000-0001-5300-000000000004';
    
        return new Promise((resolve, reject) => {
            lastValueFrom(this.httpClient.put(environment.apiUrl + 'api/QuestionnaireResponse/Updatenew/' + recordID,
                insertObject, {
                headers: {
                    'objectID': '7690416F-6319-4A05-94E9-EE539AF7FB7D'
                },
                responseType: "text"
            }
            )).then(response => {
                this.signalRService.sendMessage(this.GetNoteModel(ResponseStatus, 'Submitted'), recordID, 'FE0F6C9B-F4BC-4416-B534-802ADF3AD4CF')
                survey.showCompletedPage = true;
                resolve(response);
            }).catch(Error => {
                reject(Error);

            });
        });
    }
    async UpdateQuestionnaireResponse(recordID: string, Status_EnumID: string, OldStatus: string, NewStatus: string): Promise<string> {
        var questionnaireResponse = new QuestionnaireResponseModel();
        questionnaireResponse.RecordID = recordID;
        questionnaireResponse.QuestionnaireResponseStatus_EnumID = Status_EnumID;
        var insertObject: any = {};
        insertObject.FieldValues = questionnaireResponse;
        return new Promise((resolve, reject) => {
            lastValueFrom(this.httpClient.put(environment.apiUrl + 'api/QuestionnaireResponse/Updatenew/' + recordID,
                insertObject, {
                responseType: "text"
            }
            )).then(response => {
                this.signalRService.sendMessage(this.GetNoteModel(OldStatus, NewStatus), recordID, 'FE0F6C9B-F4BC-4416-B534-802ADF3AD4CF')
                resolve(response);
            }).catch(Error => {
                reject(Error);

            });
        });

    }
    GetNoteModel(OldStatus: string, NewStatus: string) {
        var note = new NoteModel();
        note.RecordID = Guid.create().toString();
        note.NoteType_EnumID = '5E100908-B8DC-48E7-B746-86404CEB023C';
        note.Note = 'QuestionnaireResponseStatus Transitioned from ' + OldStatus + ' to ' + NewStatus;
        return note;

    }

    GetDisplayFieldValue(sourceObj, textFieldName) {
        var returnValue = "";
        for (var key in sourceObj) {
            if (key = textFieldName) {
                returnValue = sourceObj[key];
            }
        }
        return returnValue;
    }
    formatJSON(sourceObj, textFieldName, valueFieldName) {
        const obj = {};
        for (var key in sourceObj) {
            if (key = textFieldName) {
                obj["text"] = sourceObj[key];
            }
            if (key = valueFieldName) {
                obj["value"] = sourceObj[key];
            }
        }
        return obj;
    }

    calculateTotalScore(data: any[], _survey: any): number {
        let totalScore = 0;
        data.forEach((item) => {
          const question = _survey.getQuestionByValueName(item.name);
          if (question) {
            const qValue = item.value;
            if (question?.choices) {
              question.choices.forEach((choice: any) => {
                if (this.isValueSet(choice.value, qValue)) {
                  if (choice?.score) {
                    totalScore += +choice.score;
                  }
                        }
                    });
                }
            }
        });
        return totalScore;
      }
      
    
      isValueSet(choicesValue: any, questionValue: any): boolean {
        if (Array.isArray(questionValue)) {
          return questionValue.indexOf(choicesValue) > -1;
        }
        return choicesValue == questionValue;
      }

}















