import { HttpClient } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DxPopupComponent } from 'devextreme-angular';
import { SimplifiedSearchMode } from 'devextreme/common';
import { Guid } from 'guid-typescript';
import { CookieService } from 'ngx-cookie-service';
import { EncryptionService } from 'src/app/encryption.service';
import { AddressModel, AddressPopUpComponentProperties } from 'src/app/models/Address';
import { EnumerationModel } from 'src/app/models/EnumerationModel';
import { AddressService } from 'src/app/services/address/address.service';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { MapService } from 'src/app/services/map/map.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { EnumHelper } from 'src/app/util/enum-helper';
import { environment } from 'src/environments/environment';
import { ValidationPopupComponent } from '../validation-popup/validation-popup.component';
import dxTextArea from 'devextreme/ui/text_area';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { OfflineDataHelperService } from 'src/app/services/offlineDataHelper/offline-data-helper.service';

@Component({
  selector: 'app-address-popup',
  templateUrl: './address-popup.component.html',
  styleUrls: ['./address-popup.component.scss']
})
export class AddressPopupComponent extends PageBaseService {
  @Input() instanceID: string;
  searchModeOption: SimplifiedSearchMode = 'contains';
  searchExprOption = 'Value';
  searchTimeoutOption = 200;
  minSearchLengthOption = 0;
  showDataBeforeSearchOption = false;
  addressPopupProperties: AddressPopUpComponentProperties;
  CompleteaddresstypeList: EnumerationModel[] = [];
  addresstypeList: EnumerationModel[] = [];
  addressCategoryList: EnumerationModel[] = [];
  addressCountryList: EnumerationModel[] = [];
  componentID: string = 'addresspopup'
  ShowAddressType_EnumID: Boolean = false;
  addressType_EnumID: string = '';
  txtaddressType_EnumID: string = '';
  ShowAddressPurpose_EnumID: Boolean = false;
  addressPurpose_EnumID: string = '';
  txtaddressPurpose_EnumID: string = '';
  ShowW3W: Boolean = false;
  W3W: string = '';
  ShowPostalCode: Boolean = false;
  PostalCode: string = '';
  ShowCountryCode_EnumID: Boolean = false;
  txtCountryCode_EnumID: string = '';
  CountryCode_EnumID: string = '';
  ShowAddressData: Boolean = false;
  addressData: string = '';
  //ShowLatitude: Boolean = false;
  Latitude: string = '';
  //ShowLongitude: Boolean = false;
  Longitude: string = '';
  ShowDefault: Boolean = false;
  Default: boolean = false;
  CurrentAction: any;
  title: string = ''
  addressRecord: AddressModel = null;
  @ViewChild("addressPopupUp", { static: false }) addressPopupUp: DxPopupComponent;
  @ViewChild("addressValidationpopup") validationpopup: ValidationPopupComponent;
  relationshipID: string;
  CurrentrecordID: string;
  AddressDataLabel:string='Data'
  addressDataRegExPattern:string='';
  addressDataValidationMessage:string='';
  addressDataRegularExpressionApplied:boolean=false;
  constructor(private eventQueue: LobasEventQueueService,private addressservice: AddressService, public translateService: TranslateService, private cookieService: CookieService, private alertService: AlertService, private httpClient: HttpClient, private localStorageService: LocalStorageService, private _router: Router, private _enc: EncryptionService, private offlineDataHelper: OfflineDataHelperService ) {
    super(_router, _enc);
    this.UpdateProperties();
  }

  async ngOnInit() {
    if(this.offlineDataHelper.isOnline){
    this.addressPopupProperties = <AddressPopUpComponentProperties>this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.addressPopupComponentProperties, [this.instanceID]);
    
    if (this.addressPopupProperties == null || this.addressPopupProperties == undefined) {
      this.addressPopupProperties = new AddressPopUpComponentProperties();
      this.GetComponentProperties();
    }
    this.UpdateProperties();
    }

  }


  async UpdateProperties() {
    this.CompleteaddresstypeList = this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.enumerationList, ["Address Type"]);
    this.addressCategoryList = this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.enumerationList, ["Address Purpose"]);
    this.addressCountryList = this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.enumerationList, ["Country Code"]);
    if (this.CompleteaddresstypeList == null || this.CompleteaddresstypeList == undefined) {
      await this.GetEnumerationListByTypeDescription("Address Type", this.CompleteaddresstypeList, "Address Type");

    }
    if (this.addressCategoryList == null || this.addressCategoryList == undefined) {
      await this.GetEnumerationListByTypeDescription("Address Purpose", this.addressCategoryList, "Address Purpose");

    }

    if (this.addressCountryList == null || this.addressCountryList == undefined) {
      await this.GetEnumerationListByTypeDescription("Country Code", this.addressCountryList, "Country Code");
    }
  }

  OpenPopup(recordID: string, action: string) {
    this.clearProperties();
    this.UpdateProperties();
    this.SetComponentFieldSettingsAndProperties(this.addressPopupProperties, recordID, action);
  }
  clearProperties() {
    this.addressRecord = new AddressModel();
    this.addressType_EnumID = null;
      this.addressPurpose_EnumID = null;
      this.W3W = null;
      this.PostalCode = null;
      this.CountryCode_EnumID = null;
      this.addressData = null;
      this.Latitude = null;
      this.Longitude = null;
      this.Default = false;
  }
  async selectionChanged(e: any) {
    this.addressDataRegularExpressionApplied = false;  
    this.SetVisibilityControls(this.addressType_EnumID);
    this.setValidation(this.addressType_EnumID);

  }
  SetVisibilityControls(addressType_EnumID: string) {

    switch(this.GetDocumentType(addressType_EnumID).toLowerCase())
    {
      case "postal":
        this.AddressDataLabel ="Address";
        this.ShowHideControls(true,true,true,true,true,true,true); 
        break;
      default:
        this.AddressDataLabel ="Data";
        this.ShowHideControls(true,true,false,false,false,true,true);   
        
        break;
      
    }       
  }

  ShowHideControls(ShowAddressType_EnumID:boolean,ShowAddressPurpose_EnumID:boolean,ShowW3W:boolean,ShowPostalCode:boolean,ShowCountryCode_EnumID:boolean,ShowAddressData:boolean,ShowDefault:boolean) {
    this.ShowAddressType_EnumID = ShowAddressType_EnumID ;
    this.ShowAddressPurpose_EnumID = ShowAddressPurpose_EnumID ;
    this.ShowW3W = ShowW3W;
    this.ShowPostalCode = ShowPostalCode; 
    this.ShowCountryCode_EnumID = ShowCountryCode_EnumID; 
    this.ShowAddressData = ShowAddressData; 
    this.ShowDefault = ShowDefault; 
  }
  

  GetDocumentType(addressType_EnumID: string)
  {
    var addressTypeName =''
    if (addressType_EnumID != null && addressType_EnumID.toLowerCase() != Guid.EMPTY.toLowerCase()) {
      this.CompleteaddresstypeList.forEach(y => {
        if (y.EnumerationId.trim().toLowerCase() == addressType_EnumID.toLowerCase()) {
          addressTypeName = y.Value;
        }
      })
    }
    return addressTypeName;
  }
  setValidation(addressType_EnumID: string) {
    if (addressType_EnumID != null && addressType_EnumID.toLowerCase() != Guid.EMPTY.toLowerCase()) {
      var addressDataValidations = "";
      this.CompleteaddresstypeList.forEach(y => {
        if (y.EnumerationId.trim().toLowerCase() == addressType_EnumID.toLowerCase()) {
          addressDataValidations = y.ExtendedAttributes;
        }
      })
      if (!this.IsNullorEmpty(addressDataValidations)) {
        var parser = new DOMParser();
        var xmlDoc = parser.parseFromString(addressDataValidations, "text/xml");
        var regExpression:string = xmlDoc.getElementsByTagName("RegularExpressionCollection")[0].attributes["Value"].nodeValue; 
        regExpression = regExpression.replaceAll("\\","\\");
        this.addressDataRegExPattern = regExpression;//"https?:\\/\\/(www\\.)?[a-zA-Z0-9\\-]+\\.[a-zA-Z]{2,}(\\.[a-zA-Z]{2,})?([\\/\\w\\-._~:\\/?#[\\]@!$&\'()*+,;=]*)?";
        this.addressDataValidationMessage =xmlDoc.getElementsByTagName("RegularExpressionCollection")[0].attributes["ErrorDescription"].nodeValue;
        this.addressDataRegularExpressionApplied = true;        
      } 
      else{
        this.addressDataRegularExpressionApplied = false;;       
      }     
    }
  }

  SetComponentFieldSettingsAndProperties(addressPopupProperties: AddressPopUpComponentProperties, recordID: string, action: string) {
    var Field = addressPopupProperties.FieldSettings.Items;
    Field = Field.sort((a, b) => a.VisibleIndex - b.VisibleIndex);
    Field.forEach(x => {
      if (x.IsVisible) {
        if (x.IsVisible) {
          if (x.FieldName.toLowerCase() == "addresstype_enumid") { this.ShowAddressType_EnumID = true }
          if (x.FieldName.toLowerCase() == "addresspurpose_enumid") { this.ShowAddressPurpose_EnumID = true }
          if (x.FieldName.toLowerCase() == "w3w") { this.ShowW3W = true }
          if (x.FieldName.toLowerCase() == "postalcode") { this.ShowPostalCode = true }
          if (x.FieldName.toLowerCase() == "countrycode_enumid") { this.ShowCountryCode_EnumID = true }
          if (x.FieldName.toLowerCase() == "addressdata") { this.ShowAddressData = true }
          // if (x.FieldName.toLowerCase() == "latitude") { this.ShowLatitude = true }
          // if (x.FieldName.toLowerCase() == "longitude") { this.ShowLongitude = true }
          if (x.FieldName.toLowerCase() == "default") { this.ShowDefault = true }
        }
      }
    });
    this.relationshipID = addressPopupProperties.RelationshipID;
    this.title = addressPopupProperties.Title;
    if(!this.IsNullorEmpty(this.addressPopupProperties.AllowedAddressTypes))
    {
       var validDocument = this.addressPopupProperties.AllowedAddressTypes.toUpperCase().split(',').map(s => s.trim())
      this.addresstypeList = this.CompleteaddresstypeList.filter(x => x.EnumerationId.toUpperCase() == validDocument.filter(y => y.trim() == x.EnumerationId.toUpperCase())[0]);
    }
    else
    {
      this.addresstypeList = this.CompleteaddresstypeList;
      
    }
    this.PerformAction(recordID, action);
  }

  async PerformAction(recordID: string, action: string) {
    this.CurrentrecordID = recordID;
    switch (action.toLowerCase()) {
      case "newpopup":
        this.NewPopUp();
        break;
      case "editpopup":
        if (!this.IsNullorEmpty(recordID) && recordID != Guid.EMPTY) {
          this.CurrentAction = "editpopup";
          this.EditPopUp(recordID);
        }
        break;
    }
  }

  NewPopUp()
  {
    this.CurrentAction = "newpopup";
    this.title = "Add Address";
    this.addressPopupUp.visible = true;
  }

  async EditPopUp(recordID) {
    this.CurrentAction = "editpopup";
    this.title = "Edit Address";
    this.addressPopupUp.visible = true;
    this.addressRecord = await this.addressservice.GetAddressByRecordID(recordID);
    if (this.addressRecord) {
      this.addressType_EnumID = this.addressRecord.AddressType_EnumID;
      this.addressPurpose_EnumID = this.addressRecord.AddressPurpose_EnumID;
      this.W3W = this.addressRecord.W3W;
      this.PostalCode = this.addressRecord.PostalCode;
      this.CountryCode_EnumID = this.addressRecord.CountryCode_EnumID;
      this.addressData = this.addressRecord.AddressData;
      this.Latitude = this.addressRecord.Latitude;
      this.Longitude = this.addressRecord.Longitude;

      this.Default = this.addressRecord.Default;
    }
  }
  async Save(e: any) {
    var validationmessage = "";
    const { isValid } = e.validationGroup.validate();
    if (!isValid) {
      const brokenRules = e.validationGroup.validate().brokenRules;
      validationmessage = brokenRules.map((rule: any) => {
        return "<p>" + rule.message + "</p>"
      }).join("");
    }

    if ((this.CurrentAction.toLowerCase() == "newpopup" || this.CurrentAction.toLowerCase() == "editpopup")) {
      var parentRecordID  = await this.GetKeyValueFromQueryString("RecordID");
      this.addressRecord = new AddressModel();
      this.addressRecord.RecordID = Guid.create().toString();
      this.addressRecord.AddressType_EnumID = this.addressType_EnumID;
      this.addressRecord.AddressPurpose_EnumID = this.addressPurpose_EnumID;
      this.addressRecord.CountryCode_EnumID = this.CountryCode_EnumID;
      this.addressRecord.Latitude = this.Latitude;
      this.addressRecord.Longitude = this.Longitude;
      this.addressRecord.PostalCode = this.PostalCode;
      this.addressRecord.Default = this.Default;
      this.addressRecord.W3W = this.W3W;
      this.addressRecord.AddressData = this.addressData;
      this.addressRecord.RecordDataObjectStatus_EnumID = "00000000-0000-0001-3500-000000000002";
    }

    if(isValid)
    {
      if(this.CurrentAction.toLowerCase() == "newpopup")
      {
        if(this.relationshipID)
        {
          await this.addressservice.InsertAddressWithRelationship(this.addressRecord,parentRecordID,this.relationshipID,this.eventQueue,true,this.Default,true);
        }
        else{
          await this.addressservice.InsertAddress(this.addressRecord,this.eventQueue,true,this.Default,true);
        }
        
        this.addressPopupUp.visible = false;
      }
      else
      {
        this.addressRecord.RecordID = this.CurrentrecordID;
        await this.addressservice.UpdateAddrress(this.addressRecord,this.addressRecord.RecordID,this.eventQueue,true,this.Default,true);
        this.addressPopupUp.visible = false;
      }

    }
    else{
      this.validationpopup.showPopup(validationmessage);
    }

  }
  Cancel(e: any) {
    this.addressPopupUp.visible = false;
  }
  GetComponentProperties() {

    var ComponentUrl = "";
    ComponentUrl = "api/ComponentProperties/GetComponentPropertiesData?componentID=";
    this.httpClient.get(environment.apiUrl + ComponentUrl + this.componentID + "&instanceID=" + this.instanceID)
      .subscribe((response: any) => {
        this.setComponentProperties(response.Properties);
      });

  }

  setComponentProperties(componentProperties) {
    var addressPopup = JSON.parse(componentProperties);
    addressPopup.AddressPopupProperties.forEach(componentProperty => {
      switch (componentProperty.key.toLowerCase()) {
        case "title":
          this.addressPopupProperties.Title = componentProperty.value;
          break;
        case "relationshipid":
          this.addressPopupProperties.RelationshipID = componentProperty.value;
          break;
        case "bindonrelationshiponly":
          this.addressPopupProperties.BindOnRelationshipOnly = componentProperty.value;
          break;
        case "allowedroles":
          this.addressPopupProperties.AllowedRoles = componentProperty.value.split(',').map(x => x.toLowerCase());;
          break;
        case "fieldsettings":
          this.addressPopupProperties.FieldSettings = JSON.parse(componentProperty.value);
          break;
        case "allowedaddresstypes":
          this.addressPopupProperties.AllowedAddressTypes = componentProperty.value;
          break;
      }
    });
    this.localStorageService.SetLocal(EnumHelper.LocalStorageKey.addressPopupComponentProperties, JSON.stringify(this.addressPopupProperties), [this.instanceID])
  }

  GetEnumerationListByTypeDescription(enumerationType: string, recordStatusList: EnumerationModel[], key: string) {

    this.httpClient.get(environment.apiUrl + "api/EnumerationUnAuthorize/GetEnumerationListByTypeDescription/" + enumerationType)
      .subscribe((response) => {
        this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.enumerationList, <EnumerationModel[]>response, [key]);
      });

  }

}