<div class="customloader" id="customloader"></div>
<div class="webpart-card">
    <div class="webpart-cardbody">
        <div class="webpart-card-header" [ngClass]="isVisible ? 'webpart-card-closed' : 'webpart-card-open'">
            <div class="webpart-card-header-title">
                <span>{{name}}</span>
            </div>
            <div class="webpart-card-header-action">
                <dx-button (onClick)="expandCollaspeComponent()"
                    [icon]="isVisible ? 'fa-regular fa-angle-up' : 'fa-regular fa-angle-down'"></dx-button>
            </div>
        </div>

        <div [hidden]="isVisible">
            <div class="map-control-body">
                <div class="map-control-header" *ngIf="!isViewMode">
                    <div class="map-control-header-row">
                        <div class="field_group"  *ngIf="isSetMode && !isSelectLocation">
                            <div class="field_label" *ngIf="savedLocation" >Saved Location</div>
                            <div class="field_control" *ngIf="savedLocation" >
                                <div class="saved_location_input" id="lblSavedLocationValue">{{savedLocation}}</div>
                                <!-- <dx-text-box [(value)]="savedLocation" id="lblSavedLocationValue" [readOnly]="true"
                                    [inputAttr]="{ 'aria-label': 'Saved Location' }"></dx-text-box> -->
                            </div>
                        </div>
                        <div class="field_group"  *ngIf="isSelectLocation">
                            <div class="field_label required-label-asterisk">Select Job Location</div>
                            <div class="field_control">
                                <dx-select-box #ddldelectLocation valueExpr="RecordID" displayExpr="AddressData" [inputAttr]="{ 'aria-label': 'BrandingMode' }" 
                                [dataSource]="dataSourceCustomerJobLocation"  [(ngModel)]="this.SelectedValue" (onValueChanged)="onValueChanged($event)"></dx-select-box>
                            </div>
                        </div>
                        <div class="field_group"  *ngIf="isSetMode">
                            <div class="field_label required-label-asterisk">Search</div>
                            <div class="field_control">
                                <app-location-selector isNavigateOnChangeLocation="false" ControlName="Set"></app-location-selector>
                            </div>
                        </div>
                        <div class="field_group" *ngIf="isNavigateMode">
                            <div class="field_label required-label-asterisk">Source</div>
                            <div class="field_control">
                                <app-location-selector ControlName="Source" isNavigateOnChangeLocation="true"></app-location-selector>
                            </div>
                        </div>
                        <div class="field_group" *ngIf="isNavigateMode">
                            <div class="field_label required-label-asterisk">Destination</div>
                            <div class="field_control">
                                <app-location-selector ControlName="Destination" isNavigateOnChangeLocation="true"></app-location-selector>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="map-iframe">
                    <div id="wrapper" style="clear: both;">
                        <div id="map" class="map"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-validation-popup #validationpopup></app-validation-popup>