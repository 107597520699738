import { Component, ViewChild } from '@angular/core';
import { Location } from '@angular/common'
import { AlertService } from 'src/app/services/alertService/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ComponentInfo } from 'src/app/models/ComponentInfo';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { AppEvent } from 'src/app/models/AppEvent';
import { AppEventType } from 'src/app/enums/appEventType';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/encryption.service';
import { GridComponent } from '../../common/grid/grid.component';
import { SendCloseCallReportPopupComponent } from '../../common/send-close-call-report-popup/send-close-call-report-popup.component';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
@Component({
  selector: 'app-close-call',
  templateUrl: './close-call.component.html',
  styleUrls: ['./close-call.component.scss']
})
export class CloseCallComponent extends PageBaseService{
  
  ComponentObjectID :string;
  myclosecallgrid= new ComponentInfo();
  filterExpressionsArrayByQueryString:any;
  activeGrid: GridComponent = null;
  isMyClosecallTabVisible: boolean =false;
  isOpenClosecallTabVisible: boolean =false;  
  isAllClosecallTabVisible: boolean=false;
  isDeletedClosecallTabVisible: boolean=false;
  isInitComplete: boolean=false;
  @ViewChild("grdCloseCall", { static: true }) grdCloseCall!: GridComponent;
  @ViewChild("closeCallReport", { static: false }) closeCallReport: SendCloseCallReportPopupComponent
  tab1Index:number=0;
  constructor(private localStorageService: LocalStorageService,private eventQueue:LobasEventQueueService, private location: Location,private alertService: AlertService, private translateService: TranslateService ,private _router: Router, private _enc: EncryptionService) {
    super(_router, _enc);
    this.ComponentObjectID = '256EE4E0-3B90-43D7-A6C2-CD6C89ABA647';
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }

  gridCustomActions(data: any) {
    switch (data.CommandObject.Command.toLowerCase()) { 
      case "gridsendclosecallreport":
          this.closeCallReport.showPopup(data.SelectedRecords[0])     
        break;
      default:
        this.translateService.get('CustomActionYetToImplemented', { title: data.CommandObject.Name }).subscribe((text: string) => {
          var InfoTitle:string="";
          var OkButtonText:string="";
          this.translateService.get('InfoTitle').subscribe((translated: string) => {
            InfoTitle = translated;
          });
          this.translateService.get('OkButtonText').subscribe((translated: string) => {
            OkButtonText = translated;
          });
          this.alertService.showAlertPopup(InfoTitle, text, 'fa-solid fa-circle-info', OkButtonText);
        });
        break;
    }
    
  }

  async ngOnInit()
  {
    var tabNumber = await this.GetKeyValueFromQueryString("TabControl");
    if (tabNumber) {
      this.tab1Index = Number(tabNumber) - 1;
      this.SetTabIndex("tab1Index", this.tab1Index, this.localStorageService);
    }
    else {
      this.tab1Index = this.GetTabIndex("tab1Index", this.localStorageService);
      this.SetTabIndex("tab1Index", this.tab1Index, this.localStorageService);
    }

    this.activeGrid = this.grdCloseCall;
     var filterByQuerystring = await this.GetKeyValueFromQueryString('FilterBy');
    if(filterByQuerystring)
    {
      this.UpdateFilterExpressionsByQueryString(filterByQuerystring,'myclosecallgrid');
    }
      this.isInitComplete = true;
  }
  onTab1ItemClick(ev: any) 
  {    
    this.SetTabIndex("tab1Index",ev?.itemIndex,this.localStorageService);
  }

  hidecloseCallTab(isGridHidden) {
    this.isMyClosecallTabVisible = !isGridHidden;
  }

  hideOpenClosecallTab(isGridHidden) {
    this.isOpenClosecallTabVisible = !isGridHidden;
  } 
  
  hideAllClosecallTab(isGridHidden) {
    this.isAllClosecallTabVisible = !isGridHidden;
  }

  hideDeletedClosecallTab(isGridHidden) {
    this.isDeletedClosecallTabVisible = !isGridHidden;
  }
  UpdateFilterExpressionsByQueryString(queryStringFilterBy:string,instanceName) { 
    
    this.filterExpressionsArrayByQueryString = [];
      if(queryStringFilterBy.toLowerCase()==="yourclosecallsonhold" && instanceName.toLowerCase()=="myclosecallgrid")
      {        
        this.filterExpressionsArrayByQueryString.push(  ['CloseCallStatus_EnumID', '=', 'c78c2601-d141-475c-b7d9-9d7b14c895af']) 
      }
      else if(queryStringFilterBy.toLowerCase()==="yourclosecallsunsubmitted" && instanceName.toLowerCase()=="myclosecallgrid")
      {       
        this.filterExpressionsArrayByQueryString.push(  ['CloseCallStatus_EnumID', '=', '461cf8dc-b359-4b17-925b-68734d6315f0'])
        
      }
      else if(queryStringFilterBy.toLowerCase()==="yourclosecallsthismonth" && instanceName.toLowerCase()=="myclosecallgrid")
      {
        var currentDate = new Date( );
        var beginingOfMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() ,1);        
        this.filterExpressionsArrayByQueryString.push(['RecordCreatedDate', '>=', beginingOfMonthDate])
      }     
    }

}
