<dx-popup [showTitle]="true" (onHiding)="onHiding()"
    [wrapperAttr]="{class: 'ribcmd_popup lobas-popup lobas-form-popup' }" [title]="this.componentProperties.HeaderText"
    #recordSearchPopup [showTitle]="true" [resizeEnabled]="false" [dragEnabled]="false" [hideOnOutsideClick]="false"
    [showCloseButton]="true">
    <div class="form-row">
        <div class="field_group">
            <div class="field_label required-label-asterisk">{{this.componentProperties.LabelText}}</div>
            <div class="field_control">
                <div class="dx-field">
                    <div class="">
                        <dx-drop-down-box #DDBox [(opened)]="isGridBoxOpened"
                            [inputAttr]="{ 'aria-label': 'Owner' }" [showClearButton]="true"
                            (onOptionChanged)="onGridBoxOptionChanged($event)">
                            <div *dxTemplate="let data of 'content'" class="lobas-rec-src-dropdown-grid">
                                <app-grid [applyAdaptivePriority]="false" 
                                    [isWebpartHeaderHidden]="true" [showFilterRow]="true" [IsUnauthorizedPage]="false"
                                    [isRowClick]="true" (rowClick)="rowClick($event)"
                                    (isCustomStoreLoaded)="setValues()" [instanceID]="ddlGridInstanceID"
                                    #ddlGrid></app-grid>
                            </div>

                        </dx-drop-down-box>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-btn-outer">
        <dx-button text="Cancel" (onClick)="closePopup()">
        </dx-button>
        <dx-button text="Continue" (onClick)="continueAction()">
        </dx-button>
    </div>
</dx-popup>