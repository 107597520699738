import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShiftRequirementService {
  constructor(private httpClient: HttpClient) { }

  DeleteGroup(APIActionEndPoint: string, records: Array<string>, objectID: string,JobID:string,) :Promise<any>{
    var recordsToDelete: any = null;
    recordsToDelete = { "SelectedRecords": records, "ObjectID": objectID};
    return new Promise((resolve, reject) => {
      this.httpClient.delete(environment.apiUrl + APIActionEndPoint+JobID,
        {
          body: recordsToDelete,
          responseType: "text",
          headers: {
            'objectID': objectID
          }
        }).subscribe(response => {
        resolve(<any>response)
      });
    });
  }
  InsertShiftRequirementRecordFromJobWorkItemPicker(JobID: string,WorkItemID:string ,objectID: string) :Promise<any>
  {
    return new Promise((resolve, reject) => {
      var requestData: any = {};
      requestData.JobID = JobID;
      requestData.WorkItemID = WorkItemID;
      this.httpClient.post(environment.apiUrl + 'api/ShiftRequirement/InsertShiftRequirementRecordFromJobWorkItemPicker', JSON.stringify(requestData),
      {
         responseType: "text", 
         headers: {
          'objectID': objectID
        }

      }).subscribe(response => {
        resolve(<any>response)
      });
    });
  }
  changedPlannedTime(JobID: string,SelectedRecords:Array<string> ,objectID: string,StartDate:Date,EndDate:Date) :Promise<any>
  {
    return new Promise((resolve, reject) => {
      var requestData: any = {};
      requestData.JobID = JobID;
      requestData.SelectedRecords = SelectedRecords;
      requestData.StartDate = StartDate;
      requestData.EndDate = EndDate;
      this.httpClient.post(environment.apiUrl + 'api/ShiftRequirement/ChangedPlannedTime', JSON.stringify(requestData),
      {
         responseType: "text", 
         headers: {
          'objectID': objectID
        }

      }).subscribe(response => {
        resolve(<any>response)
      });
    });
  }
  GetJobRecord(JobID: string) :Promise<any>
  {
    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.apiUrl + 'api/Job/Get/'+JobID, 
      {
         responseType: "text", 
         headers: {
          'objectID': '5EF72874-C6A0-4B0B-8FA1-B69335C783E3'
        }

      }).subscribe(response => {
        resolve(<any>response)
      });
    });
  }  
}
    



