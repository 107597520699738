import { Component, OnInit } from '@angular/core';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { Guid } from 'guid-typescript';
import { EncryptionService } from 'src/app/encryption.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { QuestionnaireResponseService } from 'src/app/services/questionnaire-response/questionnaire-response.service';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { EnumHelper } from 'src/app/util/enum-helper';

@Component({
  selector: 'app-questionnaire-response-details',
  templateUrl: './questionnaire-response-details.component.html',
  styleUrls: ['./questionnaire-response-details.component.scss']
})
export class QuestionnaireResponseDetailsComponent extends PageBaseService implements OnInit {

  isVisible2: boolean = false;
  ComponentObjectID: string;
  recordID: string = Guid.EMPTY;
  relationshipRecordID: string = Guid.EMPTY;
  newID: string = Guid.EMPTY;
  parameterSet: Boolean = false;
  loggedinUserID: string = ''
  parameterSetUserID: boolean = false;
  userRoles: string[];
  constructor(private localStorageService: LocalStorageService, public questionnaireResponseService: QuestionnaireResponseService, private eventQueue: LobasEventQueueService, private _router: Router, private auth: AuthService, private _enc: EncryptionService) {
    super(_router, _enc);
    this.ComponentObjectID="7690416F-6319-4A05-94E9-EE539AF7FB7D";
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
    this.loggedinUserID = this.auth.getGuidUserID() ?? '';
    this.userRoles = <string[]>JSON.parse(this.localStorageService.GetLocal(EnumHelper.LocalStorageKey.userRoles));
  }

  expandCollaspe2Component() {
    this.isVisible2 = !this.isVisible2;
  }
  ngOnInit() {
    this.getAllQueryStrings();
   
  }
  getAllQueryStrings() {
    this.GetKeyValueFromQueryString('recordID').then((recordID => {
      this.recordID = recordID;
     this.UpdateQuestionnaireResponseStatus();
    }));
  }

  UpdateQuestionnaireResponseStatus() {
    this.questionnaireResponseService.GetQuestionnaireRespons(this.recordID).then(QuestionnaireResponse => {
      if (QuestionnaireResponse.QuestionneeContactID.toLowerCase() === this.loggedinUserID.toLowerCase()) {
        this.questionnaireResponseService.GetEnumerationListByTypeDescription('QuestionnaireResponseStatus').then(data => {
          this.questionnaireResponseService.getValueForEnumerationId(data, QuestionnaireResponse.QuestionnaireResponseStatus_EnumID).then(ResponseStatus => {
            if (ResponseStatus.toLowerCase() == "distributed" || ResponseStatus.toLowerCase() == "self-service") {
              this.questionnaireResponseService.GetEnumerationIDByTypeValue(data, "In Progress").then(Status_EnumID => {
                this.questionnaireResponseService.UpdateQuestionnaireResponse(this.recordID, Status_EnumID, ResponseStatus, 'In Progress').then(
                  response => {
                    this.parameterSet = true;
                  });
              });
            }
            else{
              this.parameterSet = true;
            }
          });
        });
      }
      else if(this.userRoles.some(x => x.toLowerCase() == "document manager")) {
        this.parameterSet = true;
      }
      else {
        this._router.navigateByUrl('unauthorised');
      }
    });
  }
}





