<!-- ***************************This code has been generated using LOBAS Templates 4.0.23.0  -->

<app-breadcrumb [ComponentObjectID]="componentObjectID"></app-breadcrumb>


<dx-tab-panel [(selectedIndex)]="tab1Index" (onTitleClick)="onTab1ItemClick($event)" [swipeEnabled]="false"
            [elementAttr]="{ class: 'custom_tabpanel' }">
    <dxi-item title="Details">
        <div class="row">
            <div class="col-lg-6">
                <app-grid #objectDetail (gridCustomActions)="gridCustomActions($event)" [IsUnauthorizedPage]="false" instanceID="objectDetail"></app-grid>
            </div>
            <div class="col-lg-6">
                <app-objectattributes-grid></app-objectattributes-grid>
            </div>
        </div>

    </dxi-item>
    <dxi-item title="Relations">
        <app-relationship-grid></app-relationship-grid>
    </dxi-item>
    <dxi-item title="UILayout">
        <div class="row">
            <div class="col-lg-6">
                <div class="m-b-30">
                    <app-uilayout-grid></app-uilayout-grid>
                </div>
                <div>
                    <app-uilayoutfield-grid></app-uilayoutfield-grid>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="m-b-30">
                    <app-objectcommandassign-grid></app-objectcommandassign-grid>
                </div>
            </div>
        </div>
       
    </dxi-item>
    <dxi-item title="Commands">
        <div class="row">
            <div class="col-lg-6">
                <div class="m-b-30">
                    <app-objectcommand-grid></app-objectcommand-grid>
                </div>
                <div>
                    <app-uilayoutassignlist-grid></app-uilayoutassignlist-grid>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="m-b-30">
                    
                </div>
            </div>
        </div>
    </dxi-item>
    <dxi-item title="Datasets">
        <app-objectdataset-grid></app-objectdataset-grid>
    </dxi-item>
    <dxi-item title="Transformations">
        <app-datatransform-grid></app-datatransform-grid>
    </dxi-item>
    <dxi-item title="T4Definitions">
        <app-objectt4-grid></app-objectt4-grid>
    </dxi-item>
    <dxi-item title="Data">
        <app-objectrecord-grid></app-objectrecord-grid> 
    </dxi-item>
</dx-tab-panel>