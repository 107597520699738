import { Injectable } from "@angular/core";
import { FieldSettings } from "./FieldSettings";

@Injectable({
    providedIn: 'root',
})

export class ExtendedAttributesComponentProperties {
    ObjectName: string = '';
    Title: string = '';
    FormatAlignment:string = 'LeftToRight'; //"RightToLeft"
    ObjectID:string = '';
    HideSaveButton:boolean = false;
    FieldSettings: FieldSettings = undefined;
}


