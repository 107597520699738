<div class="row webpartrow">

    <div class="col-lg-12">
        <div class="m-b-30">
            <app-assistance-oncall></app-assistance-oncall>
        </div>
        <div class="m-b-30">
            <app-shift-carousel></app-shift-carousel>
        </div>
    </div>

    <div class="col-lg-12">
        <div class="m-b-30">
            <div class="bigbtntaskcard_outer">
                <app-card-slider instanceID="ShiftReportDueSliderCard_Home"></app-card-slider>
                <app-card-slider instanceID="OverDueShiftReportSliderCard_Home"></app-card-slider>
                <app-card-slider instanceID="YourClosecallsThisMonthSliderCard_Home"></app-card-slider>
                <app-card-slider instanceID="YourCloseCallsUnsubmittedSliderCard_Home"></app-card-slider>
                <app-card-slider instanceID="UnreadNotificationsSliderCard_Home"></app-card-slider>
                <app-card-slider instanceID="OutStandingActionsSliderCard_Home"></app-card-slider>

                <app-big-button instanceID="newAssetTestBigButton_Home"
                    (customBigButtonAction)="this.openAssetTestRecordSearchPopup()"></app-big-button>
                <app-big-button instanceID="newQustionnaireResponseBigButton_Home"
                    (customBigButtonAction)="this.openQuestionnaireResponseRecordSearchPopup()"></app-big-button>
                <app-big-button instanceID="newjobRecordSearchBigButton_Home"
                    (customBigButtonAction)="this.openJobRecordSearchPopup()"></app-big-button>

            </div>
        </div>
        <div>
            <app-record-search instanceID="assetRecordSearch" ddlGridInstanceID="assetRecordSearchDDLGrid"
                #assetRecordSearchPopup></app-record-search>
            <app-record-search instanceID="questionnaireRecordSearch"
                ddlGridInstanceID="QuestionnaireRecordSearchDDLGrid"
                (customActions)="createQuestionnaireAndRedirect($event)"
                #questionnaireRecordSearchPopup></app-record-search>
            <app-record-search instanceID="jobRecordSearch" ddlGridInstanceID="jobRecordSearchDDLGrid"
                #jobRecordSearchPopup></app-record-search>
        </div>
    </div>
</div>
<app-security-questions-popup #SecurityQuestionPopupUpComponent
    [IsShowPopUp]="IsShowSecurityQuestionPopup"></app-security-questions-popup>
<script type="text/javascript">
    "serviceWorker" in navigator && window.addEventListener("load", function () { navigator.serviceWorker.register("../../assets/js/pwabuilder-sw.js").then(e => console.log("service worker registered")).catch(e => console.log("service worker not registered", e)) });
</script>