<div class="customloader" id="customloader"></div>
<div class="webpart-card" [ngClass]="isFullscreenWebpart?'webpart-card-fullscreen':''">
    <div class="webpart-cardbody">
        <div class="webpart-card-header" [ngClass]="isVisible ? 'webpart-card-closed' : 'webpart-card-open'">
            <div class="webpart-card-header-title">
                <span>{{this.documentViewerProperties.Title}}</span>
            </div>
            <div class="webpart-card-header-action">
                <dx-button (onClick)="fullscreenWebpart()"
                    [icon]="isFullscreenWebpart ? 'fa-regular fa-window-restore' : 'fa-regular fa-window-maximize'"></dx-button>
                <dx-button (onClick)="expandCollaspeComponent()" [hidden]="isFullscreenWebpart? true:false" [icon]="isVisible ? 'fa-regular fa-angle-up' : 'fa-regular fa-angle-down'"></dx-button>
            </div>
        </div>
        <div [hidden]="isVisible" class="webpart-scroll">
            <div *ngIf="isDocViewer">
                <ngx-doc-viewer [viewerUrl]="documentSRC" [url]="documentSRC" viewer="url"></ngx-doc-viewer>
            </div>
            <div class="single-image-viewer" *ngIf="isImage">
                <img src="{{documentSRC}}" />
            </div>
            <div *ngIf="isPdf && isIOS" class="webpart-scroll">
                <div> 
                  <strong>iOS Users:</strong> To close PDF, swipe from left.
                  </div>
                 <a  href="{{documentSRC}}">Launch Document</a>  
             </div>
        </div>
    </div>
</div>