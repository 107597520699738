import { Component, OnInit, Input } from '@angular/core';
import { Model } from "survey-core";
import { QuestionnaireResponseService } from 'src/app/services/questionnaire-response/questionnaire-response.service';
import { QuestionnaireResponseWithRelatedItemsModel } from 'src/app/models/QuestionnaireResponseWithRelatedItemsModel';
import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/encryption.service';

@Component({
  selector: 'app-questionnaire-response-editor',
  templateUrl: './questionnaire-response-editor.component.html',
  styleUrls: ['./questionnaire-response-editor.component.scss']
})
export class QuestionnaireResponseEditorComponent  implements OnInit {
  @Input() recordID;
  @Input() loggedinUserID: string;
  @Input() userRoles: string[];
  surveyModel: Model;
  questionnaireHelper: QuestionnaireResponseService;
  questionnaireResponse: QuestionnaireResponseWithRelatedItemsModel;
  survey: any;
  isVisible: boolean = false;
  isFullscreenWebpart: boolean = false
  isVisibledivInfoIcon = true;
  QuestionnaireResponseStatus: string;
  constructor(public questionnaireResponseService: QuestionnaireResponseService) {
    this.questionnaireHelper = questionnaireResponseService;
    this.questionnaireResponse = new QuestionnaireResponseWithRelatedItemsModel;
  }

  expandCollaspeComponent() {
    this.isVisible = !this.isVisible;
  }
  expandCollaspeWebpart() {
    this.isFullscreenWebpart = !this.isFullscreenWebpart
  }
  async ngOnInit() {
    this.questionnaireResponseService.GetQuestionnaireRespons(this.recordID).then(QuestionnaireResponse => {
      this.questionnaireResponse = QuestionnaireResponse;
      this.questionnaireResponseService.ReplaceJsonVariables(this.questionnaireResponse).then(questions => {
        this.getMode().then(mode => {
          this.questionnaireResponseService.createSurveyModal(this.recordID, questions, this.questionnaireResponse.JSON, mode, this.QuestionnaireResponseStatus).then(survey => {
            this.surveyModel = survey;
          });
        });
      });
    });
    if (window.innerWidth < 768) {
      this.expandCollaspeWebpart()
    }
  }


  async getMode(): Promise<string> {
    var data = await this.questionnaireResponseService.GetEnumerationListByTypeDescription('QuestionnaireResponseStatus');
    this.QuestionnaireResponseStatus = await this.questionnaireResponseService.getValueForEnumerationId(data, this.questionnaireResponse.QuestionnaireResponseStatus_EnumID);
    if ((this.QuestionnaireResponseStatus && this.QuestionnaireResponseStatus.toLowerCase() === 'in progress' ||
      this.QuestionnaireResponseStatus.toLowerCase() === 'self-service' ||
      this.QuestionnaireResponseStatus.toLowerCase() === 'distributed') &&
      this.questionnaireResponse.QuestionneeContactID.toLowerCase() === this.loggedinUserID.toLowerCase()) {
      return 'edit';
    } else if (this.QuestionnaireResponseStatus && this.questionnaireResponse.QuestionerCanEditResponses &&
      this.userRoles?.some(x => x.toLowerCase() === "document manager") &&
      (this.QuestionnaireResponseStatus.toLowerCase() === 'in progress' ||
        this.QuestionnaireResponseStatus.toLowerCase() === 'submitted')) {
      return 'edit';
    } else {
      return 'view';
    }
  }
  
  InfoIconClick() {
    var divInfoIcon = document.querySelector('#divInfoIcon')
    if (divInfoIcon) {
      let html = '<li>Response Status</li>';
      html += `<li><b>${this.QuestionnaireResponseStatus}</br></b></li>`;
      divInfoIcon.innerHTML = `<ul>${html}</ul>`;
      this.isVisibledivInfoIcon = !this.isVisibledivInfoIcon;
    }
  }
}


