import { Component, OnInit, HostListener, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { BrandingService } from './services/branding/branding.service';
import { LocaleService } from './services/locale/locale.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from './services/localStorage/local-storage.service';
import { EnumHelper } from './util/enum-helper';
import { CookieService } from 'ngx-cookie-service';
import config from 'devextreme/core/config';
import { environment } from 'src/environments/environment';
import { LobasEventQueueService } from './services/lobasEventQueueService/lobas.event.queueservice';
import { AppEventType } from './enums/appEventType';
import { AuthService } from './services/auth/auth.service';


config({ licenseKey: environment.devextreemeLicenseKey })

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'statrack';
  showHead: boolean = false;
  increaseNumber: number = 0;
  lastScrollTop: number = 0;
  lastValue: number = 1.00;

  constructor(private eventQueue: LobasEventQueueService, private router: Router, public brandingHelper: BrandingService, private localeService: LocaleService,
    public translate: TranslateService, private localStorageService: LocalStorageService, 
    private cookieService: CookieService, private changeDetector: ChangeDetectorRef,
    private authService:AuthService) {
    var languagesList = ['en-IN', 'en-US', 'en', 'en-GB']
    translate.addLangs(languagesList);
    translate.setDefaultLang('en-GB');
    var cultureInfo = cookieService.get("CultureInfo");
    translate.use((cookieService.get("CultureInfo") == '' || !languagesList.includes(cultureInfo)) ? 'en-GB' : cookieService.get("CultureInfo"));
    this.eventQueue.on(AppEventType.ShowHideHeader).subscribe(event => this.SetShowHeader(event.payload));
  }

  SetShowHeader(payload: boolean): void {
    if (this.showHead != payload) {
      this.showHead = payload;
      this.ShowHeader(this.router, this.cookieService);
    }
  }


  ShowHeader(router: Router, cookieService: CookieService) {
    var currentURL = window.location.href.toString();
    router.events.forEach((event) => {
      if (currentURL.length === 0 || currentURL.includes("register") ||  currentURL.includes("logout") || currentURL.includes("login") || currentURL.includes("email-verification") || currentURL.includes("reset-password")) {
        this.showHead = false;
      }
      else {
        this.showHead = true;
      }
    });
  }
  async ngOnInit() {    
    if (!this.brandingHelper.ActiveBranding && this.brandingHelper.ActiveBranding.RecordID == '00000000-0000-0000-0000-000000000000') {
      this.brandingHelper.ActiveBranding = await this.brandingHelper.GetBranding();
    }
    this.brandingHelper.LoadActiveBrandingCSS();
    const html = document.getElementsByTagName('html')[0];
    html.setAttribute("data-theme", this.localStorageService.GetLocal(EnumHelper.LocalStorageKey.themeName));
    await this.authService.validateToken();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }



  //private timeoutID: any;

  //  @HostListener('window:scroll', ['$event'])
  //scrollHandler(event) {


  // if (this.timeoutID) {
  //   clearTimeout(this.timeoutID);
  // }
  // Delay execution for e.g. 300ms 
  //   this.timeoutID= setTimeout(() => {
  //     let offset = event.target.scrollTop;
  //     if (event.target.scrollTop > this.lastScrollTop) { //scroll down
  //       this.lastValue = parseFloat((this.lastValue + 0.002).toFixed(3));
  //     }
  //     else { //scroll up
  //       this.lastValue = parseFloat((this.lastValue - 0.002).toFixed(3));
  //     }

  //     this.lastValue = this.lastValue < 1 ? 1 : this.lastValue;
  //     document.getElementById("parallax").style.backgroundPositionY = '-' + offset * 0.1 + "px";

  //     this.lastScrollTop = event.target.scrollTop;
  // }, 300);
  //}
}
