
//***************************This code has been generated using LOBAS Templates 4.0.23.0  

import { Component } from '@angular/core';
import { AppEventType } from 'src/app/enums/appEventType';
import { AppEvent } from 'src/app/models/AppEvent';
import { ComponentInfo } from 'src/app/models/ComponentInfo';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';

@Component({
  selector: 'app-fatiguemetricresults-grid',
  templateUrl: './fatiguemetricresults-grid.component.html',
  styleUrls: ['./fatiguemetricresults-grid.component.scss']
})
export class fatiguemetricresultsgridcomponent {
  componentObjectID: string;

  constructor(private eventQueue: LobasEventQueueService) {
    //super(_router, _enc);
    this.componentObjectID = '565aaa68-48bd-4dff-ae72-b6d61a095a2b';
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }
  gridCustomActions(data: any) {
    if (data != undefined && data != null) {
      switch (data.CommandObject.Command.toLowerCase()) {
        case "CustomActionHere":
          //Execution code.
          break;
      }
    }
  }
}