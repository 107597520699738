export class RecordRelationshipBindingComponentProperties {
    RelationshipName:string = '';
    Title:string='';
    HeadTitle:string='';
    PointInTime:boolean=false;
    MandatoryStartDate: boolean = false;
    MandatoryEndDate: boolean = false;
    PointInTimeValidations: string  = '';
    DatesMustBeSequential: boolean = false;
    AllowDuplicates: boolean = false;
    Filter:[]=null;
    displayExpr:[]=null;
}