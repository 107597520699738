import { Component } from '@angular/core';

@Component({
  selector: 'app-notification-status-carousel',
  templateUrl: './notification-status-carousel.component.html',
  styleUrls: ['./notification-status-carousel.component.scss']
})
export class NotificationStatusCarouselComponent {
  isVisible: Boolean = false;
  expandCollaspeComponent() {
    this.isVisible = !this.isVisible;
  }
}
