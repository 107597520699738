import { Observable, catchError, first, of, throwError } from 'rxjs';
import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { HttpHelperService } from '../httpHelper/http-helper.service';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { EnumHelper } from 'src/app/util/enum-helper';
import { AlertService } from '../alertService/alert.service';
import { environment } from 'src/environments/environment';
import { DeviceHelper } from 'src/app/util/device-Helper';
import { Device } from '@capacitor/device';
import { Guid } from 'guid-typescript';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private alertService: AlertService, private localStorageService: LocalStorageService,
    private router: Router, private http: HttpClient, private httpHelper: HttpHelperService,
    private cookieService: CookieService, public translateService: TranslateService) { }

  setToken(data:any): void {
    var expiry = new Date(data.tokenExpiry);
    this.cookieService.set("CultureInfo", data.language, expiry, "/");
    this.cookieService.set("CurrentTimeZone",data.timeZone, expiry, "/");
    this.cookieService.set("BearerToken", data.bearerToken, expiry, "/");
    this.cookieService.set("TenancyID", data.tenancyID, expiry, "/");
    this.cookieService.set("UserID", data.userID, expiry, "/");
    this.setCookie("TokenID", "TokenID=" + data.oldToken, expiry, '/');
    var expiryDate  = new Date(new Date().setMonth(new Date().getMonth() + 12))
    this.cookieService.set("MobileDeviceID", data.mobileDeviceID, expiryDate, "/",'',);
    this.cookieService.set("BrandingMode", data.brandingMode, expiry, "/");
    
    this.localStorageService.SetLocal(EnumHelper.LocalStorageKey.userRoles, JSON.stringify(data.roles));
    localStorage.setItem("ApiUrl", JSON.stringify(environment.apiUrl));
  }

  setCookie(cname,cvalue,exdays, path) {
    let expires = "expires=" + exdays.toUTCString();
    cvalue = cvalue;
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=" + path;
  }
  

  resetDeviceID(){
    if(this.cookieService.check("MobileDeviceID")){
     var deviceID = this.cookieService.get("MobileDeviceID");
     var expiryDate  = new Date(new Date().setMonth(new Date().getMonth() + 12))
      this.cookieService.set("MobileDeviceID", deviceID, expiryDate, "/",'',);
    }
  }

  getBearerToken(): string | null {
    return this.cookieService.get("BearerToken");
  }

  getGuidToken(): string | null {
    var token:string = this.cookieService.get("TokenID");
    if(token.includes("=")){
      token = token.split("=")[1];
    }
    return token ;
  }

  getGuidUserID(): string | null {
    return this.cookieService.get("UserID");
  }

  getGuidTenancyID(): string | null {
    return this.cookieService.get("TenancyID");
  }

  isLoggedIn() {
    if ((this.getBearerToken() !== null && this.getBearerToken() !== "") && (this.getGuidToken() !== null && this.getGuidToken() !== "")) {
      return true;
    }
    else {
      this.localStorageService.clearAllLocalStorageAndCookies();
      return false;
    }
  }

  logout() {
    this.http.get(environment.apiUrl + 'api/token/logout', { responseType: "text" }).subscribe((response) => {
      this.localStorageService.clearAllLocalStorageAndCookies(true);
    });
  }

  login({ userName, password }: any) {
    var isMobileDevice = false;
    var deviceID = null;
    if(DeviceHelper.isMobileDevice() || DeviceHelper.isTabletDevice()){
      isMobileDevice = true;
      deviceID = this.cookieService.get("MobileDeviceID").length > 0 ? this.cookieService.get("MobileDeviceID") :  Guid.create().toString();;        
      if(deviceID.includes("=")){
        deviceID = deviceID.split("=")[1];
      }
    }

    return this.http.post<any>(environment.apiUrl + 'api/Authenticate/login/' + environment.hostName, { userName, password, isMobileDevice, deviceID }).pipe(
      catchError((err: any) => { 
        var ErrorTitle:string="";
        var OkButtonText:string="";
        this.translateService.get('ErrorTitle').subscribe((translated: string) => {
          ErrorTitle = translated;
        });
        this.translateService.get('OkButtonText').subscribe((translated: string) => {
          OkButtonText = translated;
        });
        this.alertService.showAlertPopup(ErrorTitle, err.error.Message, 'fa-solid fa-xmark', OkButtonText); throw err })
    );
  }

  async validateToken() {
      var result = null;
      var token = this.getGuidToken();
      if(token!=undefined && token!="" && token!=null) {
      result =  await this.http.get(environment.apiUrl + "api/AuthenticateAuthorized/ValidateToken")
          .toPromise()
          .then(response => {
            return response;
          })
          .catch(ex => {
            if(ex && ex.status) {
              if(ex.status == "401") {
                this.localStorageService.clearAllLocalStorageAndCookies(true, true);
              }
            }
          });
      }
      return result;
  }
}
