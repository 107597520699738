export class AppointmentModel {
    RecordID: string='00000000-0000-0000-0000-000000000000'; 
    Subject: string='';  
    RecordStartDate: Date=new Date(); 
    AppointmentEventType_EnumID: string='00000000-0000-0000-0000-000000000000';
    RecordEndDate: Date=new Date();
    Notes: string='';
    ReminderInfo: string='';
    Customer_CompanyID: string='00000000-0000-0000-0000-000000000000';
    AppointmentEventStatus_EnumID: string='00000000-0000-0000-0000-000000000000';
    isAllDayEvent: boolean=false;
    ExtendedAttributes: string='';
    Label_EnumID: string='00000000-0000-0000-0000-000000000000';
    RecurrenceInfo: string='';
    Status_EnumID: string='00000000-0000-0000-0000-000000000000';
    BreakTime: Date=new Date();
    Description: string='';
    RecordCreatedDate: Date=new Date();
    RecordUpdatedDate: Date=new Date();
    RecordUpdated_UserID: string='00000000-0000-0000-0000-000000000000';
    RecordCreated_UserID: string='00000000-0000-0000-0000-000000000000';
    CreatedUserIcon: string='';
    UpdatedUserIcon: string='';
    CreatedUsersName: string='';
    UpdatedUsersName: string='';
    RecordTenancyID: string='00000000-0000-0000-0000-000000000000';
    RecordLookupValue: string='';
    RecordDescription: string='';
    RecordDataObjectStatus_EnumID: string='00000000-0000-0000-0000-000000000000';
    Url:string='';
}