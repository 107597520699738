import { Component, ElementRef, OnInit, ViewChild, forwardRef } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { EnumHelper } from 'src/app/util/enum-helper';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserAccountDetails } from 'src/app/models/UserAccountDetails';
import { ActivatedRoute, Router } from '@angular/router'
import { EnumerationModel } from 'src/app/models/EnumerationModel';
import { DxDataGridComponent, DxPopupComponent, DxTextBoxComponent, DxButtonModule, DxValidationGroupComponent } from 'devextreme-angular';
import { SingleMultipleAllOrNone } from 'devextreme-angular/common';
import { DxListModule, DxListTypes } from 'devextreme-angular/ui/list'
import dxList from 'devextreme/ui/list';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { TranslateService } from '@ngx-translate/core';
import dxButton from 'devextreme/ui/button';
import { CookieService } from 'ngx-cookie-service';
import dxTextBox from 'devextreme/ui/text_box';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { EncryptionService } from 'src/app/encryption.service';
import ValidationGroup from "devextreme/ui/data_grid";
import { NoteModel } from 'src/app/models/NoteModel';
import { Guid } from 'guid-typescript';
import { SignalRService } from 'src/app/services/signalr-service/signalr-service.service';
import { OfflineDataHelperService } from 'src/app/services/offlineDataHelper/offline-data-helper.service';

@Component({
  selector: 'app-user-account-details',
  templateUrl: './user-account-details.component.html',
  styleUrls: ['./user-account-details.component.scss']
})
export class UserAccountDetailsComponent extends PageBaseService implements OnInit {
  isVisible: Boolean = false;
  themeMode: string = '';
  dataSourceBrandingMode: any[] = [];
  lookupDataSource: { key: string, values: EnumerationModel[] }[] = [];
  userdetails: UserAccountDetails;
  roles: string[] = [];
  selectAllModeValue: DxListTypes.SelectAllMode = 'page';
  selectionModeValue: SingleMultipleAllOrNone = 'all';
  selectedKeys: string[] = [];
  selectByClick = false;
  IsVisiblebtnAddEditRole: boolean = false;
  userRoles: string;
  recordID: string;
  ishideUserApproved: boolean = true;
  ishideAccountLocked: boolean = true;
  saveNotesApiEndpoint: string;
  oldAccountLockedValue: boolean;
  okAlertBtnTxt: string = "Ok";
  successAlertTitle: string = "Success";
  errorAlertTitle: string = "warning";
  validationGroupInstance: ValidationGroup;
  IsVisiblebtnSave: boolean = true;
  hidedivOverlay: boolean = true;
  @ViewChild("addEditUserRoles", { static: false }) addEditUserRoles: DxPopupComponent
  @ViewChild("ddlrolesList", { static: false }) ddlrolesList: dxList
  @ViewChild("btnAddEditRole", { static: false }) btnAddEditRole: dxButton
  @ViewChild("txtAddRoles", { static: false }) txtAddRoles: DxTextBoxComponent
  @ViewChild('roleGroup', { static: false }) validationGroup: DxValidationGroupComponent
  @ViewChild("btnSave", { static: false }) btnSave: dxButton

  constructor(private signalRService: SignalRService, private _enc: EncryptionService, private cookieService: CookieService, private localStorageService: LocalStorageService, private httpClient: HttpClient, private _router: Router, private route: ActivatedRoute, private alertService: AlertService, private translate: TranslateService, private offlineDataHelper: OfflineDataHelperService) {
    super(_router, _enc)
    this.userdetails = new UserAccountDetails();
  }
  async ngOnInit() {    
    this.translate.get('OkButtonText').subscribe((translated: string) => {
    this.okAlertBtnTxt = translated;
    });
    this.translate.get('SuccessTitle').subscribe((translated: string) => {
    this.successAlertTitle = translated;
    });
    this.translate.get('warningText').subscribe((translated: string) => {
    this.errorAlertTitle = translated;
    });

    if(this.offlineDataHelper.isOnline){
      this.recordID = await this.GetKeyValueFromQueryString('RecordID');
      this.bindUserDetails();
      this.fetchBrandingMode();
      this.fetchAllRoles();
    }
  }
  bindUserDetails() {
    this.userdetails.UserRoles = null;
    if(this.recordID && this.recordID!=''){
    lastValueFrom(this.httpClient.get<UserAccountDetails>(environment.apiUrl + "api/Contact/GetUserAccountDetails/" + this.recordID))
      .then((response: UserAccountDetails) => {
        if (response && response.UserID != Guid.EMPTY) {
          var user = response;
          this.fetchUserRoles().then(response => {
            var userRoles = response;
            this.userdetails.UserID = user.UserID,
              this.userdetails.UserName = user.UserName,
              this.userdetails.UserApproved = user.UserApproved,
              this.userdetails.AccountLocked = user.AccountLocked,
              this.userdetails.BrandingMode = user.BrandingMode
            this.oldAccountLockedValue = this.userdetails.AccountLocked;
            for (var i in userRoles) {
              if (this.userdetails.UserRoles === null) {
                this.userdetails.UserRoles = userRoles[i];
              }
              else {
                this.userdetails.UserRoles += "," + userRoles[i];
              }
            }
            this.HideShowButton();
          });
        }
        else {
          this.IsVisiblebtnAddEditRole = false;
          this.IsVisiblebtnSave = false;
        }
      }).finally(() => {
      });
    }
  }

  async fetchAllRoles() {
    const data = await lastValueFrom(
      this.httpClient.get<string[]>(environment.apiUrl + "api/AspNetRole/roles")
    );
    this.roles = data;
  }
  async fetchBrandingMode() {
    this.httpClient.get<EnumerationModel[]>(environment.apiUrl + "api/Enumeration/GetEnumerationListByTypeDescription/Branding Mode")
      .subscribe((response) => {
        this.dataSourceBrandingMode = response;
      });
  }

  fetchUserRoles(): PromiseLike<string[]> {
    return new Promise((resolve, reject) => {
      this.httpClient.get<string[]>(environment.apiUrl + "api/AspNetRole/GetUserRole/" + this.recordID)
        .subscribe((response) => {
          resolve(<string[]>response);
        });
    });
  }
  async onSelectionChanged(e: any) {
    this.hidedivOverlay=false;
    if (this.selectByClick) {
      if (e.addedItems != undefined && e.addedItems != null && e.addedItems != '') {
        let roles = e.addedItems;
       
        this.httpClient.post(environment.apiUrl + "api/AspNetRole/AddUserRoles/" + this.recordID + "/" + roles, null)
          .subscribe((response) => {
            this.bindUserRoles();
            if (this.recordID.toLowerCase() === this.cookieService.get("UserID").toLowerCase()) {
              this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.userRoles, this.selectedKeys);
              this.localStorageService.ClearLocalStorageWithKey(EnumHelper.LocalStorageKey.jsonMenu)
            }
            this.hidedivOverlay=true;
            this.alertService.showAlertPopup('User roles', 'User roles Successfully updated', 'fa-solid fa-check', this.okAlertBtnTxt)
          });
      }
      else if (e.removedItems !== undefined && e.removedItems !== null && e.removedItems !== '') {
        let roles: string = e.removedItems;
        if (roles !== '') {
          lastValueFrom(this.httpClient.delete(environment.apiUrl + "api/AspNetRole/DeleteUserRoles/" + this.recordID + "/" + roles))
            .then((response) => {
              if (this.recordID.toLowerCase() === this.cookieService.get("UserID").toLowerCase()) {
                this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.userRoles, this.selectedKeys);
                this.localStorageService.ClearLocalStorageWithKey(EnumHelper.LocalStorageKey.jsonMenu)
              }
              this.bindUserRoles();
              this.hidedivOverlay=true;
              this.alertService.showAlertPopup('User roles', 'User roles Successfully updated', 'fa-solid fa-check', this.okAlertBtnTxt)
            });
        }
      }
    }
    else{
      this.selectByClick=true;
      this.hidedivOverlay=true;
    }
  }

  openRoleRermissionPopup() {
    this.selectByClick = false;
    if(this.selectedKeys.length>0){
      this.selectByClick = true;
    }
      this.fetchUserRoles().then(response => {
        if (response.length > 0) {
          this.selectedKeys = []; 
          for (var i in response) {
            this.selectedKeys.push(response[i]);
          }
        }
        if(response.length == 0){
          this.selectByClick = true;
        }
        this.addEditUserRoles.visible = true; 
      }); 
      
  }
  expandCollaspeComponent() {
    this.isVisible = !this.isVisible;
  }
  HideShowButton() {
    this.IsVisiblebtnAddEditRole = false;
    this.ishideUserApproved = true;
    this.ishideAccountLocked = true;
    var userRoles = <string[]>JSON.parse(this.localStorageService.GetLocal(EnumHelper.LocalStorageKey.userRoles));
    if (userRoles?.find(x => x.toLowerCase() === "security administrator")) {
      this.IsVisiblebtnAddEditRole = true;
      this.ishideAccountLocked = false;
      this.ishideUserApproved = false;
    }
  }

  PopupClose() {
    this.addEditUserRoles.visible = false;
  }
  updateUserDetails() {
    lastValueFrom(this.httpClient.post(environment.apiUrl + "api/Contact/UpdateUserAccount/", this.userdetails, {
      responseType: "text"
    }
    )).then((response) => {
      if (this.oldAccountLockedValue !== this.userdetails.AccountLocked) {
        var note = new NoteModel();
        note.RecordID = Guid.create().toString();
        note.NoteType_EnumID = '5E100908-B8DC-48E7-B746-86404CEB023C';
        note.Note = this.userdetails.AccountLocked ? "Account Locked" : "Account un-locked";
        this.signalRService.sendMessage(note, this.recordID, '115F52F8-0509-449A-8EB1-750DB2ECCBA7')
        this.setUserBranding();
      }
      else {
        this.setUserBranding();
      }

    }).finally(() => {

    });
  }
  setUserBranding() {
    var UpdateSuccessMessage:string="";
    this.translate.get('UpdateSuccessMessage').subscribe((translated: string) => {
      UpdateSuccessMessage = translated;
    });
    if (this.recordID.toLowerCase() === this.cookieService.get("UserID").toLowerCase()) {
      var brandingMode = this.getValueForEnumerationId(this.userdetails.BrandingMode);
      this.cookieService.set("BrandingMode", brandingMode);
      this.alertService.showAlertPopup(this.successAlertTitle,UpdateSuccessMessage, 'fa-solid fa-check',this.okAlertBtnTxt).then((response: any) => {
          window.location.href = window.location.href;
        });
    }
    else {
      this.alertService.showAlertPopup(this.successAlertTitle,UpdateSuccessMessage, 'fa-solid fa-check',this.okAlertBtnTxt)
    }
  }

  bindUserRoles() {
    this.userdetails.UserRoles = null;
    this.fetchUserRoles().then(response => {
      var userRoles = response;
      for (var i in userRoles) {
        if (this.userdetails.UserRoles === null) {
          this.userdetails.UserRoles = userRoles[i];
        }
        else {
          this.userdetails.UserRoles += "," + userRoles[i];
        }
      }
    });
  }
  getValueForEnumerationId(EnumerationId: string): any {
    const filteredResults = this.dataSourceBrandingMode.filter(x => x.EnumerationId === EnumerationId);
    return filteredResults.length > 0 ? filteredResults[0].Value : '';
  }
}
