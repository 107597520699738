import { EnumHelper } from "./enum-helper";

export class CommonHelper {
    public static isNullOrEmpty(val: any) {
        return typeof val == 'undefined' || !val;
    }

    //to check list of obj
    public static isEmptyObject(obj: any): boolean {
        return obj == null || typeof obj == undefined || typeof obj == 'undefined' || (Object.keys(obj).length === 0 && (obj.constructor === Object || obj.constructor === Array))
    }

    //sortBy: [property_name1, property_name2]
    public static sort(objArray: any[], sortBy: string[], sortByDateProp?: string, order: EnumHelper.SortOder = EnumHelper.SortOder.Asc) {
        if (this.isEmptyObject(objArray) || (this.isEmptyObject(sortBy) && this.isNullOrEmpty(sortByDateProp)))
            return objArray;
        if (this.isNullOrEmpty(order))
            order = EnumHelper.SortOder.Asc;

        if (CommonHelper.isNullOrEmpty(sortByDateProp))     //normal sort
        {
            return objArray.sort((n1, n2) => {
                const n1Key = sortBy.map(k => n1[k]).join('|');
                const n2Key = sortBy.map(k => n2[k]).join('|');

                return n1Key.localeCompare(n2Key) * order;
            });
        }
        else {  //datetime sort
            if (order == EnumHelper.SortOder.Desc) {
                return objArray.sort(function (a, b) {
                    return <any>new Date(b[sortByDateProp]) - <any>new Date(a[sortByDateProp]);
                });
            }
            else {
                return objArray.sort(function (a, b) {
                    return <any>new Date(a[sortByDateProp]) - <any>new Date(b[sortByDateProp]);
                });
            }
        }
    }
}