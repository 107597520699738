<dx-popup [showTitle]="true" [title]="popuptitle" #gridMenuPopup [height]="400" [width]="400" [dragEnabled]="false"
    [hideOnOutsideClick]="false" [showCloseButton]="true" [wrapperAttr]="{ class: isExportOptionsPopup? 'grid-menu-popup-export grid-menu-popup lobas-popup':'grid-menu-popup-filter grid-menu-popup lobas-popup' }">
    <div class="gridtoolsmenu_content_item" id="DesignerFilterBuilder" *ngIf="isFilterBuilderPopup">
        <div class="filter_gridtoolsmenu_body gridtoolsmenu_content_body">
            <div class="filter_search_box">
                <div class="field_group">
                    <div class="field_label">Search</div>
                    <div class="field_control">
                        <i class="fa-regular fa-magnifying-glass"></i>
                        <dx-text-box #searchBox [(value)]="searchPanelText"></dx-text-box>
                    </div>
                </div>
            </div>
            <div class="advanced_filter_search_box" id="divFilterBuilder" [hidden]="hideFilterBuilder">
                <div class="field_group">
                    <div class="field_label">Advanced Search</div>
                    <div class="advanced_filter_search_box_border">
                        <dx-filter-builder [fields]="filterBuilderFields" [(value)]="filter"> </dx-filter-builder>
                    </div>
                </div>
            </div>
        </div>
        <div class="gridtoolsmenu_btn_outer">
            <dx-check-box id="cbFilterRow" text="Show Filter Row on grid" (onValueChanged)="onValueChanged($event)"
                [value]="isShowFilterRow"></dx-check-box>
            <dx-button text="Apply" type="normal" (onClick)="ApplyFilter()">
            </dx-button>
        </div>
    </div>
    <div class="gridtoolsmenu_content_item" id="DesignerExportOptions" *ngIf="isExportOptionsPopup">
        <div #divExportOptions id="divExportOptions">
            <p>Select export format</p>
            <div class="exportbtn_outer">
                <dx-button (onClick)="ExportClick('csv')" icon="fa-regular fa-file-csv"
                    hint="Export to CSV"></dx-button>

                <dx-button (onClick)="ExportClick('pdf')" icon="fa-regular fa-file-pdf"
                    hint="Export to PDF"></dx-button>

                <dx-button (onClick)="ExportClick('xlsx')" icon="fa-regular fa-file-xls"
                    hint="Export to Excel"></dx-button>
            </div>
        </div>
    </div>
</dx-popup>