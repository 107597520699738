import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHelperService } from '../httpHelper/http-helper.service';
import { environment } from 'src/environments/environment';
import { EnumerationModel } from 'src/app/models/EnumerationModel';
import { UILayoutFieldModel } from 'src/app/models/UILayoutFieldModel';
import { forkJoin, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnumerationHelperService {
  constructor(private httpClient: HttpClient, private httpHelper: HttpHelperService) { }

  GetEnumerationListByTypeDescription(enumerationType: string): PromiseLike<EnumerationModel[]> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.apiUrl + "api/EnumerationUnAuthorize/GetEnumerationListByTypeDescription/" + enumerationType)
        .subscribe((response) => {
          resolve(<EnumerationModel[]>response);
        });
    });
  }

  bulkEnumerationTypeData(enumerationTypeDescriptionList:string[] ) {
    var lookupDataSource: { key: string, values: EnumerationModel[] }[] = [];
    // const listEnumColumnValues = UIFields
    //   .filter(x => x.OverrideUIType.toLowerCase() === "enumerationcombobox" || x.OverrideUIType.toLowerCase() === "enumeration")
    //   .map(x => x.Definition.EnumerationTypeDescription);

    if (enumerationTypeDescriptionList?.length > 0) {
      const requests = enumerationTypeDescriptionList.map(item =>
        this.httpClient.get(environment.apiUrl + "api/Enumeration/GetEnumerationListByTypeDescription/" + item?.replace('/', '_'))
      );
     return forkJoin(requests).pipe(
        map((responses: any[]) => {
          responses.forEach((response, index) => {
            lookupDataSource.push({ key: enumerationTypeDescriptionList[index], values: response });
          });
          return lookupDataSource;
        }));
      // .subscribe((lookupData) => {
      //   // this.localStorageService.SetLocalStorage(EnumHelper.LocalStorageKey.enumerationList, lookupData, [this.instanceID]);
      //   // this.getAllRelationShipDataSource(IsUnauthorized);
      // });
    }else{
      return null;
    }

  }
}
