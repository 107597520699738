
import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { HttpHelperService } from '../httpHelper/http-helper.service';
import { environment } from 'src/environments/environment';
import { SyncOfflineChangeNotificationModel } from 'src/app/models/SyncOfflineChangeNotificationModel';
import { CancelNotificationRecipientModel, CancelNotificationRecipientModelList, RemindNotificationRecipientModel, RemindNotificationRecipientModelList } from 'src/app/models/NotificationRecipientModel';
import { Guid } from 'guid-typescript';
import { catchError } from 'rxjs';
import { lastValueFrom } from 'rxjs';
import { AlertService } from '../alertService/alert.service';
import { GridComponent } from 'src/app/Components/common/grid/grid.component';
import { Router } from '@angular/router';
import { LocalStorageService } from '../localStorage/local-storage.service';
import { EnumHelper } from 'src/app/util/enum-helper';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private httpClient: HttpClient,private localStorageService: LocalStorageService,  private httpHelper: HttpHelperService,private router: Router, private alertService: AlertService) { 
    
  }


  remindNotification(data: any, allRows: any): Promise<any> {

    var remindNotificationRecipientModelList: RemindNotificationRecipientModel []=[];
    var remindList: RemindNotificationRecipientModelList;
    // Loop through all rows and get the value for the specified column
    
    data.SelectedRecords.forEach(element => {
      
      var remindNotificationRecipientModel = new RemindNotificationRecipientModel();
      remindNotificationRecipientModel.ContactID = this.GetColumnValue(element,allRows,"ContactID");
      remindNotificationRecipientModel.Name = this.GetColumnValue(element,allRows,"Name");
      remindNotificationRecipientModel.Subject = this.GetColumnValue(element,allRows,"subject");
      remindNotificationRecipientModel.RecordID = element;
      remindNotificationRecipientModel.UrlDomain = window.location.host;
      remindNotificationRecipientModelList.push(remindNotificationRecipientModel);
    });
    remindList = new RemindNotificationRecipientModelList();
    remindList.RemindNotificationRecipientList = remindNotificationRecipientModelList;
    return new Promise<string>((resolve) => {
    this.httpClient.post<any>(environment.apiUrl + "api/NotificationRecipient/RemindNotification/", JSON.stringify(remindList))
      .subscribe((response) => {
        this.alertService.showAlertPopup('Success', response.responseMessage, 'fa-solid fa-check', 'OK');
        catchError((err: any) => {
          this.alertService.showAlertPopup('Error', err.error.Message, 'fa-solid fa-xmark', 'OK'); throw err
        })
      });
    });
  }

  GetColumnValue(recorid:string,allRows:any,columnName:string)
  {
    var value="";

    if(recorid && allRows && columnName)
    {
      var recordIDRow = allRows.filter(x=> x.data["RecordID"].toLowerCase()==recorid.toLowerCase());
      if(recordIDRow)
      {
        if(recordIDRow[0] && recordIDRow[0].data && recordIDRow[0].data[columnName])
        {
          value = recordIDRow[0].data[columnName];
        }
      }
    }
    
  

    return value;
  }

  updatestatusNotificationRecipientcancel(data: any, ContactDetails_DistributedDocuments: GridComponent): Promise<any> {

    var cancelNotificationRecipientModelList: CancelNotificationRecipientModel []=[];
    var cancelList: CancelNotificationRecipientModelList;
    data.SelectedRecords.forEach(element => {
      
      var cancelNotificationRecipientModel = new CancelNotificationRecipientModel();
      cancelNotificationRecipientModel.RecordID = element;
      cancelNotificationRecipientModelList.push(cancelNotificationRecipientModel);
    });
    cancelList = new CancelNotificationRecipientModelList();
    cancelList.cancelNotificationRecipientModels = cancelNotificationRecipientModelList;
    return new Promise<string>((resolve) => {
    this.httpClient.post<any>(environment.apiUrl + "api/NotificationRecipient/UpdatestatusNotificationRecipientcancel/", JSON.stringify(cancelList))
      .subscribe((response) => {
        this.alertService.showAlertPopup('Success', response.responseMessage, 'fa-solid fa-check', 'OK');
        ContactDetails_DistributedDocuments.dataGrid.instance.refresh();
        catchError((err: any) => {
          this.alertService.showAlertPopup('Error', err.error.Message, 'fa-solid fa-xmark', 'OK'); throw err
        })
      });
    });
  }

  async updateNotificationReadStatus(NotificationRecipientID: any) {
    var values = [];
    values.push('7F103B80-4EB5-45F5-97FF-BFC67C04CA6B');
   
    
        var recordID = NotificationRecipientID;
        var objectData: any = {};
        objectData.RecordID = recordID;
        objectData.NotificationStatus_EnumID = "00000000-0000-0001-4100-000000000005";
        var url = environment.apiUrl + "api/NotificationRecipient/update/" + recordID;
    return lastValueFrom(this.httpClient.put(url, objectData,
          {
            headers: {
              'objectID': values
            },
            responseType: "text"
          }
        )).then(response => {
        })
          .catch((ex) => { throw ex.message });
    
  }

  async notificationUserAuthorised(recordID: string, loggedinUserID: string) {
    var isSuperUser = false;

    var userRoles = this.localStorageService.GetLocal(EnumHelper.LocalStorageKey.userRoles);
    if (userRoles.toUpperCase().includes('SUPERUSER')) {
      isSuperUser = true;
    }
    await this.httpClient.get<any>(environment.apiUrl + 'api/NotificationRecipient/GetNotificationRecipientByRelationshipIdAndRecordID/D70AB205-4698-4E02-B107-A1D8849F4645/' + recordID)
      .subscribe((response) => {
        if (response) {
          var notificationRecipient;
          var isMarkAsRead = false;
          response.forEach(element => {
            if (element.Object1_RecordID.toLowerCase() == recordID.toLowerCase() && element.Object2_RecordID.toLowerCase() == loggedinUserID) {
              isMarkAsRead = true;
              notificationRecipient = element;
            }
          });
          if (isMarkAsRead) {            
            this.updateNotificationReadStatus(recordID);
          }
          else if(response && isSuperUser ){
          }
          else {
            this.router.navigateByUrl('unauthorised');
          }
        }
        else {
          this.router.navigateByUrl('unauthorised');
        }
      });
  }
}


