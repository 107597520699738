import { Component } from '@angular/core';
import { LobasEventQueueService } from 'src/app/services/lobasEventQueueService/lobas.event.queueservice';
import { AppEvent } from 'src/app/models/AppEvent';
import { AppEventType } from 'src/app/enums/appEventType';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {
  isVisible: Boolean = true;
  version = environment.version;
  environmentName = environment.environmentName;
  currentYear = (new Date()).getFullYear();
  copyRight = "© Statrack Limited";
  expandCollaspeComponent() {
    this.isVisible = !this.isVisible
  }
  add_To_HomeScreen() {
    CallPrompt()
  }
  constructor(private eventQueue: LobasEventQueueService,
  ) {
    this.eventQueue.dispatch(new AppEvent(AppEventType.ShowHideHeader, true));
  }
}
// Install app from webpage.
var deferredPrompt = null;
window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();
  deferredPrompt = e;
});

function CallPrompt() {
  if (deferredPrompt != null && deferredPrompt != undefined) {
    deferredPrompt.prompt();
    const { outcome } = deferredPrompt.userChoice;
    if (outcome === 'accepted') {
      deferredPrompt = null;
    }
  }
}
