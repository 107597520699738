<div class="webpart-card">
    <div class="webpart-cardbody">
        <div class="webpart-card-header" [ngClass]="isVisible ? 'webpart-card-closed' : 'webpart-card-open'">
            <div class="webpart-card-header-title">
                <span>Attendance</span>
            </div>
            <div class="webpart-card-header-action">
                <dx-button hint="Info" icon="fa-regular fa-info-circle"></dx-button>
                <dx-button (onClick)="expandCollaspeComponent()"
                    [icon]="isVisible ? 'fa-regular fa-angle-up' : 'fa-regular fa-angle-down'"></dx-button>
            </div>
        </div>
        <div [hidden]="isVisible">
            <div class="time_recording_box">
                <i class="fa-solid fa-triangle-exclamation"></i>
                <p>Worker did not attend.</p>
            </div>
        </div>
    </div>
</div>