<dx-popup #addressPopupUp [wrapperAttr]="{class: 'lobas-popup lobas-form-popup' }" [showTitle]="true"
    title="{{this.title}}" [resizeEnabled]="true" [dragEnabled]="false" [hideOnOutsideClick]="false"
    [showCloseButton]="true">

    <form id="AddressPopUpForm" method="post">
        <div class="form-row">
          
            <div class="field_group" *ngIf="this.ShowAddressType_EnumID">
                <div class="field_label required-label-asterisk">Type</div>
                <div class="field_control">
                    <dx-select-box displayExpr="Value" valueExpr="EnumerationId" [dataSource]="addresstypeList"
                        [inputAttr]="{ 'aria-label': 'EnumerationId' }" [(value)]="addressType_EnumID"
                        [(text)]="txtaddressType_EnumID" [searchMode]="searchModeOption"
                        [searchExpr]="searchExprOption" [searchTimeout]="searchTimeoutOption"
                        [minSearchLength]="minSearchLengthOption" [searchEnabled]="true"
                        [showDataBeforeSearch]="showDataBeforeSearchOption"  (onSelectionChanged)="selectionChanged($event)" >
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Type is required"></dxi-validation-rule>
                        </dx-validator>
                    </dx-select-box>
                </div>
            </div>
            <div class="field_group" *ngIf="this.ShowAddressPurpose_EnumID">
                <div class="field_label required-label-asterisk">Category</div>
                <div class="field_control">
                    <dx-select-box displayExpr="Value" valueExpr="EnumerationId" [dataSource]="addressCategoryList"
                        [inputAttr]="{ 'aria-label': 'EnumerationId' }" [(value)]="addressPurpose_EnumID"
                        [(text)]="txtaddressPurpose_EnumID" [searchMode]="searchModeOption"
                        [searchExpr]="searchExprOption" [searchTimeout]="searchTimeoutOption"
                        [minSearchLength]="minSearchLengthOption" [searchEnabled]="true"
                        [showDataBeforeSearch]="showDataBeforeSearchOption">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Category is required"></dxi-validation-rule>
                        </dx-validator>
                    </dx-select-box>
                </div>
            </div>
            <div class="field_group" *ngIf="this.ShowAddressData">
                <div class="field_label required-label-asterisk">{{AddressDataLabel}}</div>
                <div class="field_control">
                    
                    <dx-text-area [height]="90" [(value)]="addressData" 
                    [inputAttr]="{ 'aria-label': 'Notes' }">
                    <dx-validator>
                        <dxi-validation-rule type="required"
                            message="Address is required"></dxi-validation-rule>
                        <dxi-validation-rule *ngIf="addressDataRegularExpressionApplied"
                        type="pattern"
                        [pattern]="addressDataRegExPattern"
                        [message]="addressDataValidationMessage">
                        </dxi-validation-rule>
                    </dx-validator>
                </dx-text-area>
                </div>
            </div>
            

            <div class="field_group" *ngIf="this.ShowPostalCode">
                <div class="field_label">PostCode</div>
                <div class="field_control">
                    <dx-text-box [(value)]="PostalCode">
                        
                    </dx-text-box>
                </div>
            </div>
            
            <div class="field_group" *ngIf="this.ShowCountryCode_EnumID">
                <div class="field_label required-label-asterisk">Country</div>
                <div class="field_control">
                    <dx-select-box displayExpr="Description" valueExpr="EnumerationId" [dataSource]="addressCountryList"
                        [inputAttr]="{ 'aria-label': 'EnumerationId' }" [(value)]="CountryCode_EnumID"
                        [(text)]="txtCountryCode_EnumID" [searchMode]="searchModeOption"
                        [searchExpr]="searchExprOption" [searchTimeout]="searchTimeoutOption"
                        [minSearchLength]="minSearchLengthOption" [searchEnabled]="true"
                        [showDataBeforeSearch]="showDataBeforeSearchOption">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Type is required"></dxi-validation-rule>
                        </dx-validator>
                    </dx-select-box>
                </div>
            </div>
            <div class="field_group" *ngIf="this.ShowW3W">
                <div class="field_label">What3Words</div>
                <div class="field_control">
                    <dx-text-box [(value)]="W3W">                        
                    </dx-text-box>
                </div>
            
            </div>
            
            <div class="field_group " *ngIf="this.ShowDefault">
                <div class="field_label">Set as default?</div>
                <div class="field_control">
                    <dx-check-box [(value)]="Default"></dx-check-box>
                </div>
            </div>
        </div>
    </form>

    <div class="form-btn-outer">
        <dx-button id="btnCancel" (onClick)="Cancel($event)" text="Cancel">
        </dx-button>
        <dx-button #btnSave id="btnSave" (onClick)="Save($event)" text="Save">
        </dx-button>
    </div>

</dx-popup>
<app-validation-popup #addressValidationpopup></app-validation-popup>