<app-breadcrumb></app-breadcrumb>
<button class="gobackbtn" type="button" (click)="goBackToPrevPage()"><i class="fa-solid fa-chevron-left"></i>
    Back</button>

<div class="row">
    <div class="col-lg-12">
        <dx-tab-panel [(selectedIndex)]="tab1Index" (onTitleClick)="onTab1ItemClick($event)"  [swipeEnabled]="false" [animationEnabled]="false" [elementAttr]="{ class: 'custom_tabpanel' }">
            <dxi-item [visible]="isActiveTabVisible" #tabActive title="{{'active'|translate}}">
                <app-grid #grdActivePeople [IsUnauthorizedPage]="false" instanceID="activePeopleGrid"
                    [externalItemTemplate]="customItemTemplate" templateName="externalItemTemplate" [isRowClick]="true"
                    (rowClick)="rowClickActiveGrid($event)" (gridCustomActions)="gridCustomActions($event)"></app-grid>

            </dxi-item>
            <dxi-item [visible]="isInactiveTabVisible" #tabInactive title="{{'inActive'|translate}}">
                <app-grid #grdInactivePeople [IsUnauthorizedPage]="false" instanceID="inactivePeopleGrid"
                    [externalItemTemplate]="customItemTemplate" templateName="externalItemTemplate"></app-grid>
            </dxi-item>
        </dx-tab-panel>
    </div>
</div>

<ng-template #customItemTemplate let-item="item" let-listCardColumn="listCardColumn"
    let-burgerMenuActions="burgerMenuActions" let-gridProperties="gridProperties" let-isGridview="isGridview"
    let-burgerMenuPossiton="burgerMenuPossiton">
    <div class="contactcard_box">
        <div class="contactcard_popup_box">
            <h3>{{formatData(item.data["FirstName"], 'varchar') }} {{formatData(item.data["LastName"], 'varchar') }}</h3>
            <div class="contactcard_popup_image text-center">
                <div [hidden]='!isUserImageAvailable(item.data["RecordID"])'>
                    <img class="contactcard_popup_image_img" src='{{this.getUserImage(item.data["RecordID"], item.data["FirstName"]  + item.data["LastName"])}}' alt="Image" />
                </div>
                <div [hidden]='isUserImageAvailable(item.data["RecordID"])' class="grid-user-icons">
                    {{this.getInitials(item.data["FirstName"] + ' ' + item.data["LastName"])}}</div>
            </div>
            <div class="contactcard_popup_content">
                <h4>
                    <div class="iconouter"><i class="fa-light fa-phone"></i></div><a href=tel:1234567890>{{formatData(item.data["MobileNumber"], 'varchar') }}</a>
                </h4>
                <h4>
                    <div class="iconouter"><i class="fa-light fa-envelope"></i></div><a
                        href=mailto:test@gmail.com>{{formatData(item.data["EmailAddress"], 'varchar') }}</a>
                </h4>
                <h4>
                    <div class="iconouter"><img src="/assets/images/sentinel.png" /></div><span>{{formatData(item.data["Sentinel"], 'varchar') }}</span>
                </h4>
            </div>
        </div>
    </div>
</ng-template>