<footer>
    <div class="footer-row">
        <div class="footer-col-left">
            <p>{{startYear}}-{{currentYear}} &copy; Bardel Limited</p>
        </div>
        <div class="footer-col-right">
            <ul>
                <li><a href="/assets/privacy-policy/bardel-privacy-policy.pdf" download="">Privacy & Cookies</a></li>
                <li><a href="http://www.bardel.co.uk" target="_blank">About</a></li>
                <li><span class="version_number">STAT 4.0</span></li>
                <li><span>TEST</span></li>
            </ul>
        </div>
    </div>
</footer>