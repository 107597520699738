export const environment = {
  production: true,
  apiUrl: 'https://wa-stat4multi-api-test-uks.azurewebsites.net/',
  brandingURL : 'https://test.statrack.co.uk/azstorage/branding/lobas4.0/',
  azureStorageURL : 'https://test.statrack.co.uk/azstorage/',
  lobasDesktopAppBaseURL : "https://test.statrack.co.uk/",
  OldapiUrl: 'https://test.statrack.co.uk/lobasapi/',
  hostName: window.location.hostname,
  version: '4.0.0.196',
  environmentName: 'TEST',
  devextreemeLicenseKey : 'ewogICJmb3JtYXQiOiAxLAogICJjdXN0b21lcklkIjogImRmZDhiZWEyLTllYWQtNGNiZS04NjNkLTMzYTdiODMzOWI2ZCIsCiAgIm1heFZlcnNpb25BbGxvd2VkIjogMjMyCn0=.mtSkna/+oUDg5GuDutAagKWz17Rtwxmm0B7AcCciI7H4wz/CEqO3wlNJ3L41HJ0qnvxMn/E8nLFPeFnPj4mJnYbDevW81cudij+0tCypo3zjoWARm5bm5ph9SJsAOL9Nq0FFoQ==',
  securityQuestionCount:3
};