import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { BigButtonComponentProperties } from 'src/app/models/BigButtonComponentProperties';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { EnumHelper } from 'src/app/util/enum-helper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-big-button',
  templateUrl: './big-button.component.html',
  styleUrls: ['./big-button.component.scss']
})
export class BigButtonComponent implements OnInit {
  selectedSize: string = "";
  isUserHavePermissionToViewbutton: boolean = false;
  componentProperties: BigButtonComponentProperties;
  selectedStyle: string
  componentID: string = 'bigButton'
  @Input() instanceID: string = '';
  @Output() customBigButtonAction = new EventEmitter();
  //URL and allowed roles are just here for quick fix legacy redirect.
  @Input() url: string = '';
  @Input() allowRoles: string = '';
  constructor(private localStorageService: LocalStorageService, private httpClient: HttpClient) {
    this.selectedStyle = 'style1';
  }

  performBigButtonAction() {
    if (this.componentProperties.IsCustomAction) {
      this.customBigButtonAction.emit();
    } else {
      window.open(this.componentProperties.RedirectToURL ?? this.url);
    }
  }

  ngOnInit(): void {

    this.componentProperties = <BigButtonComponentProperties>this.localStorageService.
      GetLocalStorage(EnumHelper.LocalStorageKey.bigButtonComponentProperties, [this.instanceID]) ?? new BigButtonComponentProperties();;
    if (this.url.length == 0) {
      if (this.componentProperties.Title.length === 0) {
        this.getComponentProperties();
      } else {
        this.setStyleAndSize();
        this.checkPermission();
      }
    }else{
      this.setStyleAndSize();
      this.checkPermission();
    }
  }

  setStyleAndSize() {
    switch (this.componentProperties.ButtonStyle) {
      case 'style1':
        this.selectedStyle = 'big-button-style-1';
        break;
      case 'style2':
        this.selectedStyle = 'big-button-style-2';
        break;
      case 'style3':
        this.selectedStyle = 'big-button-style-3';
        break;
    }
    switch (this.componentProperties.ButtonSize) {
      case 's':
        this.selectedSize = 'small-big-button';
        break;
      case 'm':
        this.selectedSize = 'medium-big-button';
        break;
      case 'l':
        this.selectedSize = 'large-big-button';
        break;
    }
  }

  checkPermission() {
    var allowedRoles = this.componentProperties.AllowedRoles?.split(',').map(x => x.toLowerCase());
    if (this.allowRoles.length > 0) {
      allowedRoles = this.allowRoles.split(',').map(x => x.toLowerCase());
    }

    var userRoles = <string[]>JSON.parse(this.localStorageService.GetLocal(EnumHelper.LocalStorageKey.userRoles));
    if (userRoles) {
      if (allowedRoles && allowedRoles.length > 0 && allowedRoles[0].length > 0) {
        userRoles.forEach(val => {
          if (allowedRoles.findIndex(y => y === val.toLowerCase()) != -1) {
            this.isUserHavePermissionToViewbutton = true;
          }
        });
      }
      else {
        this.isUserHavePermissionToViewbutton = true;
      }
    }

  }

  getComponentProperties() {
    var ComponentUrl = "";
    ComponentUrl = "api/ComponentProperties/GetComponentPropertiesData?componentID=";
    var result = lastValueFrom(
      this.httpClient.get(environment.apiUrl + ComponentUrl + this.componentID + "&instanceID=" + this.instanceID)
    ).then((response: any) => {
      this.setComponentProperties(response.Properties);
    });
  }

  setComponentProperties(componentProperties) {
    var componentProperties = JSON.parse(componentProperties);
    componentProperties.BigButtonProperties.forEach(componentProperty => {
      switch (componentProperty.key.toLowerCase()) {
        case "title":
          this.componentProperties.Title = componentProperty.value;
          break;
        case "icon":
          this.componentProperties.Icon = componentProperty.value;
          break;
        case "allowedroles":
          this.componentProperties.AllowedRoles = componentProperty.value;
          break;
        case "buttonsize":
          this.componentProperties.ButtonSize = componentProperty.value;
          break;
        case "buttonstyle":
          this.componentProperties.ButtonStyle = componentProperty.value;
          break;
        case "redirecttourl":
          this.componentProperties.RedirectToURL = componentProperty.value;
          break;
        case "iscustomaction":
          if (componentProperty.value && componentProperty.value.toLowerCase() == "true")
            this.componentProperties.IsCustomAction = true;
          else
            this.componentProperties.IsCustomAction = false;
          break;
      }
    });
    this.setStyleAndSize();
    this.checkPermission();
    this.localStorageService.SetLocal(EnumHelper.LocalStorageKey.bigButtonComponentProperties, JSON.stringify(this.componentProperties), [this.instanceID])

  }
}
