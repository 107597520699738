import { Component, Input, ViewChild } from '@angular/core';
import { EnumHelper } from 'src/app/util/enum-helper';
import { Guid } from 'guid-typescript';
import { DefaultAddressesComponentProperties, ExternalReferencesComponentProperties } from 'src/app/models/GridComponentProperties';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { GridHelperService } from 'src/app/services/gridHelper/grid-helper.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/encryption.service';
import { PageBaseService } from 'src/app/services/pageBase/page-base.service';
import { AddressTypes, ContactDetailsService } from 'src/app/services/contact-details/contact-details.service';
import { EnumerationHelperService } from 'src/app/services/enumerationhelper/enumeration-helper.service';
import { EnumerationModel } from 'src/app/models/EnumerationModel';
import { DxFormComponent } from 'devextreme-angular';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { OfflineDataHelperService } from 'src/app/services/offlineDataHelper/offline-data-helper.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-external-references',
  templateUrl: './external-references.component.html',
  styleUrls: ['./external-references.component.scss']
})
export class ExternalReferencesComponent extends PageBaseService {
  componentID: string = "externalreferences";
  @Input() recordID: string;
  @Input() instanceID: string;
  public relationshipID: string;
  ExternalReferencesProperties: ExternalReferencesComponentProperties;
  externalReferenceDataSourceList: DataSourceListModel[] = [];
  ExternalReferencesType: EnumerationModel[];
  isVisible: Boolean = false;
  lstExternalReferences: ExternalReferenceKeyValue[] = [];
  okAlertBtnTxt: string = "";
  successAlertTitle: string = "";

  constructor(private httpClient: HttpClient, private alertService: AlertService, private enumerationHelperService: EnumerationHelperService, private contactService: ContactDetailsService, private localStorageService: LocalStorageService, public gridHelperService: GridHelperService, private _router: Router, private _enc: EncryptionService
    , public translateService: TranslateService, private offlineDataHelper: OfflineDataHelperService) {
    super(_router, _enc);
  }
  async ngOnInit() {

    this.translateService.get('OkButtonText').subscribe((translated: string) => {
      this.okAlertBtnTxt = translated;
    });

    this.translateService.get('SuccessTitle').subscribe((translated: string) => {
      this.successAlertTitle = translated;
    });

    this.ExternalReferencesProperties = <ExternalReferencesComponentProperties>this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.externalReferencesComponentProperties, [this.instanceID]);
    this.externalReferenceDataSourceList = <DataSourceListModel[]>this.localStorageService.GetLocalStorage(EnumHelper.LocalStorageKey.externalReferenceDataSourceList);


    if (!this.ExternalReferencesProperties) {
      this.ExternalReferencesProperties = new ExternalReferencesComponentProperties();
      this.getComponentProperties(false);
    }
    else {
      this.getComponentProperties(false);
    }

    if (!this.externalReferenceDataSourceList) {
      this.externalReferenceDataSourceList = await this.GetDataSourceList();
    }

  }
  expandCollaspeComponent() {
    this.isVisible = !this.isVisible;
  }

  getComponentProperties(IsUnauthorized: boolean) {
    if (this.offlineDataHelper.isOnline) {
      var ComponentUrl = "";
      if (IsUnauthorized) {
        ComponentUrl = "api/ComponentPropertiesUnauthorized/GetComponentPropertiesData?componentID=";
      }
      else {
        ComponentUrl = "api/ComponentProperties/GetComponentPropertiesData?componentID=";
      }
      if (this.ExternalReferencesProperties && this.ExternalReferencesProperties.RelationshipID != Guid.EMPTY) {
        this.relationshipID = this.ExternalReferencesProperties.RelationshipID;
        this.BindExternalReference();

      } else {
        this.contactService.getComponentProperties(ComponentUrl + this.componentID + "&instanceID=" + this.instanceID).then(response => {
          this.setComponentProperties(response.Properties);
          this.relationshipID = this.ExternalReferencesProperties.RelationshipID;
          this.BindExternalReference();
        });
      }
    }
  }
  setComponentProperties(componentProperties) {
    var gridProperties = JSON.parse(componentProperties);
    gridProperties.ExternalReferencesProperties.forEach(componentProperty => {
      switch (componentProperty.key.toLowerCase()) {
        case "title":
          this.ExternalReferencesProperties.Title = componentProperty.value;
          break;
        case "relationshipid":
          this.ExternalReferencesProperties.RelationshipID = componentProperty.value;
          break;
        case "componentminheight":
          this.ExternalReferencesProperties.ComponentMinHeight = componentProperty.value;
          break;
        case "componentmaxheight":
          this.ExternalReferencesProperties.ComponentMaxHeight = componentProperty.value;
          break;
        case "availabledatasource":
          this.ExternalReferencesProperties.AvailableDataSource = componentProperty.value;
          break;
        case "mode":
          this.ExternalReferencesProperties.Mode = componentProperty.value;
          var roles = this.localStorageService.GetLocal(EnumHelper.LocalStorageKey.userRoles);
          if(roles.toLowerCase().includes('hr manager'))
          {
            this.ExternalReferencesProperties.Mode = "edit";
          }
          else
          {
            this.ExternalReferencesProperties.Mode = "view";
          }
          break;
        case "objectid":
          this.ExternalReferencesProperties.ObjectID = componentProperty.value;
          break;
      }
    });
    this.localStorageService.SetLocal(EnumHelper.LocalStorageKey.externalReferencesComponentProperties, JSON.stringify(this.ExternalReferencesProperties), [this.instanceID])
  }

  async BindExternalReference() {
    if (!this.externalReferenceDataSourceList) {
      this.externalReferenceDataSourceList = await this.GetDataSourceList();
    }
    this.GetDataSourceListValues(this.ExternalReferencesProperties.RelationshipID, this.recordID, this.ExternalReferencesProperties.AvailableDataSource).then(response => {
      const AllValidExternalReferenceDataSourceList = this.externalReferenceDataSourceList.map(x => x.Name);
      var finalValidExternalReferenceDataSourceList = this.ExternalReferencesProperties.AvailableDataSource.split(',').filter(item =>
        AllValidExternalReferenceDataSourceList.includes(item)
      );

      var datasourceValueslist = <DataSourceValuesModel[]>response;

      finalValidExternalReferenceDataSourceList.forEach(element => {
        if (datasourceValueslist.filter(item => item.DataSourceName.toLowerCase() == element.toLowerCase())[0]) {
          var add = new ExternalReferenceKeyValue;
          add.Key = element;
          add.Value = datasourceValueslist.filter(item => item.DataSourceName.toLowerCase() == element.toLowerCase())[0].DataSourceValue;
          this.lstExternalReferences.push(add);
        }
        else {
          var add = new ExternalReferenceKeyValue;
          add.Key = element;
          add.Value = '';
          this.lstExternalReferences.push(add);
        }
      });
    });
  }
  onSubmit(e) {
    this.UpdateExternalReferences(this.recordID, this.ExternalReferencesProperties.RelationshipID,this.ExternalReferencesProperties.ObjectID, this.lstExternalReferences).then(
      response => {
        this.translateService.get('UpdateSuccessMessage').subscribe((translated: string) => {
          this.alertService.showAlertPopup(this.successAlertTitle, translated, 'fa-solid fa-check', this.okAlertBtnTxt);
        });

      });

  }


  UpdateExternalReferences(recordId: string, relationshipID: string, objectID:string,lstExternalReferences: ExternalReferenceKeyValue[]): Promise<any> {

    var dataSourceListValuesModels = [];
    lstExternalReferences.forEach(element => {
      var dataSourceValuesModel = new  DataSourceValuesModel(); 
      dataSourceValuesModel.DataSourceName = element.Key;
      dataSourceValuesModel.DataSourceValue = element.Value;

      dataSourceListValuesModels.push(dataSourceValuesModel)

    })

    return new Promise<string>((resolve) => {
      this.httpClient.post(environment.apiUrl + "api/ExternalReference/UpdateExternalReferencesDataSourceValue/" + recordId + "/" + relationshipID+"/"+objectID, JSON.stringify(dataSourceListValuesModels)
        
      )
        .subscribe((response) => {
          resolve(<any>response);
        })
    });
  }

  GetDataSourceList(): Promise<DataSourceListModel[]> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.apiUrl + 'api/ExternalReference/GetDataSourceList')
        .subscribe((response) => {
          this.externalReferenceDataSourceList = <DataSourceListModel[]>response;
          this.localStorageService.SetLocal(EnumHelper.LocalStorageKey.externalReferenceDataSourceList, JSON.stringify(this.externalReferenceDataSourceList))
          resolve(<DataSourceListModel[]>response);
        });
    });
  }

  GetDataSourceListValues(RelationshipID: string, RecordID: string, DataSourceList: string): Promise<DataSourceValuesModel[]> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.apiUrl + 'api/ExternalReference/GetDataSourceListValues/' + RecordID + '/' + RelationshipID + '/' + DataSourceList)
        .subscribe((response) => {
          resolve(<DataSourceValuesModel[]>response);
        });
    });
  }

}

export class ExternalReferenceKeyValue {
  Key: string;
  Value: string | null;
}


export class DataSourceListModel {
  RecordID: string;
  Name: string | null;

}
export class DataSourceValuesModel {
  DataSourceName: string;
  DataSourceValue: string | null;
}

